<template>
  <MPButton
    class="filter-button"
    :class="cssClasses"
    @click="$emit('click', $event)"
    size="medium"
  >
    <i
      class="icon-image"
      v-if="icon"
      :style="{ backgroundImage: `url(${icon})` }"
    ></i>
    <i
      class="icon-image icon-image--active"
      v-if="iconActive"
      :style="{ backgroundImage: `url(${iconActive})` }"
    ></i>
    <small v-if="text || $slots.text" class="filter-button-text">
      <slot name="text">
        {{ text }}
      </slot>
    </small>
  </MPButton>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    icon: {
      type: String,
      required: false,
    },
    iconActive: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      default: 'white',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssClasses() {
      let classes = {
        'filter-button--active': this.isActive,
      }
      classes[`filter-button--${this.color}`] = true

      return classes
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0;
  padding: 10px 0;
  font-size: rem(14);
  line-height: rem(18);
  min-height: 40px;
  padding: 0;

  &.filter-button--active {
    background-color: $clr-navy;
    color: $white;
  }
  &.filter-button--active:hover {
    color: $white;
  }
}

.filter-button--white {
  background-color: white;
  border: 1px solid white;
}

.filter-button--white-outline {
  border: 1px solid #d5d6d7;
  background-color: white;
  &:hover {
    color: #747e8b;
  }
}

.filter-button--grey {
  background-color: #eeeeee;
}

.filter-button-text {
  text-transform: capitalize;
}

.icon-image {
  width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 2px;
}

.icon-image--active {
  display: none;
}

.filter-button--active {
  .icon-image {
    display: none;
  }
  .icon-image--active {
    display: block;
  }
}
</style>
