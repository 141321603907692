<template>
  <div class="loader">
    <MPWhiskLoadingAnimation />
    <span class="text">Loading recipes</span>
  </div>
</template>

<script>
import { MPWhiskLoadingAnimation } from '@/components/common'

export default {
  name: 'RecipeBoxLoader',
  components: {
    MPWhiskLoadingAnimation,
  },
}
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
  margin: 1rem 0 0;
}

.text {
  display: block;
}

.loader-img {
  max-height: 300px;
}
</style>
