<template>
  <header>
    <MPModal class="image-modal" :show.sync="showImageModal" :show-x="true">
      <div>
        <img :src="image" />
      </div>
    </MPModal>
    <div
      class="the-header-image"
      :style="styles"
      @click="showImageModal = true"
    ></div>
    <slot />
  </header>
</template>

<script>
export default {
  components: {},
  props: {
    image: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showImageModal: false,
    }
  },
  computed: {
    styles() {
      return this.image ? { 'background-image': `url(${this.image})` } : {}
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  position: relative;
}

.the-header-image {
  position: relative;
  height: 50vw;
  background-size: cover;
  background-position: center;
  background-color: #e5e5e5;
  margin-bottom: 40px;
  cursor: pointer;
  @include media-breakpoint-up(md) {
    height: 440px;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(51, 51, 51, 0) 40%
    );
  }
}

.header-close {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 10px;
  width: 35px;
  height: 35px;
  background-color: transparent;
  background-image: url('~@/assets/icons/dashboard-remove-white.svg');
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;

  @include media-breakpoint-up(md) {
    border-radius: 50%;
    top: 55px;
    right: 20px;
    width: 60px;
    height: 60px;
    background: #fff url('~@/assets/icons/dashboard-remove-gray.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    pointer-events: auto;
  }
}
</style>

<style lang="scss">
.image-modal {
  display: flex;
  align-items: center;
  background-color: black;
  .modal-dialog {
    max-width: 1000px;
  }
  .mp-modal-content {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;
    @include media-breakpoint-down(sm) {
      min-height: 100vh;
      background-color: black;
    }
  }
}
</style>
