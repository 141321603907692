<template>
  <div id="recipe-modal" ref="recipe-modal">
    <RecipeView />
  </div>
</template>

<script>
import RecipeView from '@/views/RecipeView'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default {
  components: {
    RecipeView,
  },
  mounted() {
    disableBodyScroll(this.$refs['recipe-modal'])
  },
  beforeDestroy() {
    enableBodyScroll(this.$refs['recipe-modal'])
  },
}
</script>

<style lang="scss" scoped>
#recipe-modal {
  position: fixed;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background-color: $white;
}
</style>
