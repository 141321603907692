<template>
  <div class="side-card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RecipeSideCard',
}
</script>

<style lang="scss" scoped>
.light-green {
  border-color: $light-grey-green;
}
</style>
