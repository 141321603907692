<template>
  <h4 class="recipe-ingredient-subtitle">
    <slot></slot>
  </h4>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
