import { events } from '../../../utils/mixpanel.events'
import * as dashboard from './actionUtils/dashboard'
import * as general from './actionUtils/general'

export const ANALYTICS_EVENTS_HANDLERS = {
  [events.dashboard.view]: dashboard.trackDashboardViewMounted,
  [events.dashboard.recipe.added]: [
    dashboard.getRecipeById,
    dashboard.getRecipeRatingById,
    dashboard.getPlanNameType,
  ],
  [events.dashboard.recipe.swapped]: [
    dashboard.getRecipeById,
    dashboard.getRecipeRatingById,
    dashboard.getPlanNameType,
  ],
  [events.dashboard.recipe.removed]: [
    dashboard.getRecipeByMealId,
    dashboard.getRecipeRatingById,
    dashboard.getPlanNameType,
  ],
  [events.weekendPrep.view]: general.getPlanNameTypeAfterMount,
  [events.weekendPrep.savePrepNote]: general.getPlanNameTypeAfterMount,
  [events.weekendPrep.changeSpeedPrepItem]: general.getPlanNameTypeAfterMount,
  [events.groceryList.view]: general.getPlanNameTypeAfterMount,
  [events.groceryList.buyAmazonFresh]: general.getPlanNameTypeAfterMount,
  [events.recipe.addedFrom.recipeBox]: [
    dashboard.getRecipeById,
    dashboard.getRecipeRatingById,
    dashboard.getPlanNameType,
  ],
  [events.recipe.rated]: [dashboard.getRecipeById],
  [events.recipe.sendFeedback]: [dashboard.getRecipeById],
  [events.recipe.favorited]: [dashboard.getRecipeById],
  [events.recipe.printed]: [dashboard.getRecipeById],
  [events.personalRecipe.delete]: [dashboard.getRecipeById],
}
