<template>
  <div class="login-form">
    <div class="login-container">
      <MPAlert
        :text="loginError"
        :is-dismissable="true"
        @dismiss="dismissError()"
      ></MPAlert>

      <h2 class="mt-0" v-if="title" data-spec="login-account">{{ title }}</h2>

      <form @submit.prevent="login" class="row mt-4 mt-md-5">
        <div class="top-column col-12 col-sm-12 col-md-6">
          <EmailInput v-model="email" data-spec="login-email" />

          <PasswordInput
            v-model="password"
            class="mt-4"
            data-spec="login-password"
          />

          <MPButton
            type="submit"
            color="primary"
            size="large-register"
            class="mt-4"
            data-spec="login-submit"
          >
            Log in with email
          </MPButton>

          <div class="forgot-password mt-2">
            <span class="link small" @click="goToPasswordReset">
              <i>Forgot password?</i>
            </span>
          </div>
        </div>

        <div class="col-12 pt-2 pb-2 d-md-none">
          <MPHorizontalDivider text="or"></MPHorizontalDivider>
        </div>

        <div class="bottom-column col-12 col-sm-12 col-md-6">
          <div class="social-buttons-wrapper">
            <MPGoogleButton
              @onLogin="loginOnGoogle"
              @onFail="loginOnGoogleFailed"
              class="col-12"
            >
              Sign in with Google
            </MPGoogleButton>

            <MPAppleButton @click="loginWithApple" class="col-12 mt-4">
              Sign in with Apple
            </MPAppleButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  MPAlert,
  MPHorizontalDivider,
  MPGoogleButton,
  MPAppleButton,
  MPButton,
} from '@/components/common'
import EmailInput from '@/components/credentials/EmailInput'
import PasswordInput from '@/components/credentials/PasswordInput'
import credentialsHandler from '@/handlers/CredentialsHandler'
import LoadingMixin from '@/mixins/LoadingMixin'

export default {
  mixins: [LoadingMixin],
  components: {
    MPAlert,
    MPHorizontalDivider,
    MPGoogleButton,
    MPAppleButton,
    MPButton,
    EmailInput,
    PasswordInput,
  },
  name: 'LoginForm',
  props: {
    title: {
      type: String,
      default: 'Log in to your account',
    },
  },
  data() {
    return {
      email: '',
      password: '',
      loginError: '',
    }
  },
  methods: {
    login() {
      this.loginError = ''
      this.setLoading(true)
      credentialsHandler
        .handleLogin(
          this.$store,
          window.location.href,
          this.email,
          this.password
        )
        .then(
          () => {
            this.setLoading(false)
            this.$emit('onLoggedIn')
          },
          (error) => {
            this.setLoading(false)
            if (error.detail) {
              switch (error.code) {
                case 481:
                case 483:
                  this.loginError = `You do not appear to have an account.${
                    this.noSignUp
                      ? ''
                      : ` Please <b-link to='/register/'>sign up</b-link>.`
                  }`
                  break
                case 484:
                  this.loginError = `You joined with Facebook. Please try logging in with Facebook instead.`
                  break
                case 486:
                  this.loginError = `You joined with Google. Please try logging in with Google instead.`
                  break
                case 428:
                  this.$router.push({
                    path: '/signup',
                    query: {
                      email: encodeURIComponent(this.email),
                    },
                  })
                  break
                case 429:
                  this.loginError = `You have not completed the registration and your password is wrong.${
                    this.noSignUp
                      ? ''
                      : ` Please sign up again <b-link to='/register/'>here</b-link>.`
                  }`
                  break
                case 485:
                  this.loginError = `Please enter a correct email or password. If you have forgotten your password you can reset it <b-link :to="{path: '/password/reset', query: { email: '${encodeURIComponent(
                    this.email
                  )}' }}">here</b-link>.`
                  break
                case 430:
                  this.loginError = `Please enter a correct password. If you have forgotten your password you can reset it <b-link :to="{path: '/password/reset', query: { email: '${encodeURIComponent(
                    this.email
                  )}' }}">here</b-link>.`
                  break
                default:
                  this.loginError = error.detail
                  break
              }
            } else {
              this.loginError = `Oops, looks like something went wrong. <a  href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
            }
          }
        )
    },
    loginOnFacebook() {
      this.setLoading(true)
      credentialsHandler.handleFBLogin(this.$store, window.location.href).then(
        () => {
          this.setLoading(false)
          this.$emit('onLoggedIn')
        },
        (error) => {
          this.setLoading(false)
          if (error.detail) {
            switch (error.code) {
              case 401:
                this.loginError = `error.detail, ${
                  this.noSignUp
                    ? ''
                    : ` Please sign up again <b-link to='/signup'>here</b-link>.`
                }`
                break
              case 428:
                this.loginError = `It looks like you tried to join before, but did not complete all the steps.${
                  this.noSignUp
                    ? ''
                    : ` Please sign up again <b-link to='/signup'>here</b-link>.`
                }`
                break
              default:
                if (
                  error.detail ==
                  'You do not appear to have an account. Try signing up first.'
                ) {
                  this.loginError = `You do not appear to have an account. ${
                    this.noSignUp
                      ? ''
                      : ` Please <b-link to='/register/'>sign up</b-link>.`
                  }`
                } else {
                  this.loginError = error.detail
                }
                break
            }
          } else if (error && typeof error == 'string') {
            //this.loginError = `${error}. <a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
          } else {
            //this.loginError = `Oops, looks like something went wrong. <a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
          }
        }
      )
    },
    loginOnGoogleFailed(error) {
      if (error && error.error === 'popup_closed_by_user') return
      this.loginError = error
    },
    loginOnGoogle(googleUser) {
      this.setLoading(true)
      credentialsHandler
        .handleGoogleLogin(this.$store, window.location.href, googleUser)
        .then(
          () => {
            this.setLoading(false)
            this.$emit('onLoggedIn')
          },
          (error) => {
            this.setLoading(false)
            if (error.detail) {
              switch (error.code) {
                case 428:
                  this.loginError = `It looks like you tried to join before, but did not complete all the steps.${
                    this.noSignUp
                      ? ''
                      : ` Please sign up again <b-link to='/signup'>here</b-link>.`
                  }`
                  break
                default:
                  if (
                    error.detail ==
                    'You do not appear to have an account. Try signing up first.'
                  ) {
                    this.loginError = `You do not appear to have an account. ${
                      this.noSignUp
                        ? ''
                        : ` Please <b-link to='/register/'>sign up</b-link>.`
                    }`
                  } else {
                    this.loginError = error.detail
                  }
                  break
              }
            } else if (error && typeof error == 'string') {
              //this.loginError = `${error}. <a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
            } else {
              //this.loginError = `Oops, looks like something went wrong. <a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
            }
          }
        )
    },
    loginWithApple() {
      const provider = new this.$firebase.auth.OAuthProvider('apple.com')
      provider.addScope('email')
      provider.addScope('name')
      this.$firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          this.setLoading(true)
          credentialsHandler
            .handleAppleLogin(this.$store, window.location.href, result)
            .then(
              () => {
                this.setLoading(false)
                this.$emit('onLoggedIn')
              },
              (error) => {
                this.setLoading(false)
                if (error.detail) {
                  switch (error.code) {
                    case 428:
                      this.loginError = `It looks like you tried to join before, but did not complete all the steps.${
                        this.noSignUp
                          ? ''
                          : ` Please sign up again <b-link to='/signup'>here</b-link>.`
                      }`
                      break
                    default:
                      if (
                        error.detail ==
                        'You do not appear to have an account. Try signing up first.'
                      ) {
                        this.loginError = `You do not appear to have an account. ${
                          this.noSignUp
                            ? ''
                            : ` Please <b-link to='/register/'>sign up</b-link>.`
                        }`
                      } else {
                        this.loginError = error.detail
                      }
                      break
                  }
                } else if (error && typeof error == 'string') {
                  //this.loginError = `${error}. <a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
                } else {
                  //this.loginError = `Oops, looks like something went wrong. <a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
                }
              }
            )
            .catch((error) => {
              this.loginError = error.message
            })
        })
        .catch((error) => {
          this.loginError = error.message
        })
    },
    loginWithMagicLink(magicLink) {
      this.setLoading(true)
      credentialsHandler
        .handleMagicLinkLogin(this.$store, window.location.href, magicLink)
        .then(
          () => {
            this.setLoading(false)
            this.$emit('onLoggedIn')
          },
          (error) => {
            this.setLoading(false)
            if (error.detail) {
              switch (error.code) {
                case 428:
                  this.loginError = `It looks like you tried to join before, but did not complete all the steps.${
                    this.noSignUp
                      ? ''
                      : `Please sign up again <b-link to='/signup'>here</b-link>.`
                  }`
                  break
                case 431:
                  this.email = error.email
                  this.loginError = `Your magic link has expired. Click <b-link :to="{path: '/password/reset', query: { email: '${encodeURIComponent(
                    this.email
                  )}' }}">here</b-link> to send a new one.`
                  break
                default:
                  this.loginError = error.detail
                  break
              }
            } else if (error && typeof error == 'string') {
              //this.loginError = `${error}. <a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
              console.error(error)
            } else {
              //this.loginError = `Oops, looks like something went wrong. <a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
            }
          }
        )
    },
    dismissError() {
      this.loginError = ''
    },
    goToPasswordReset() {
      this.$router.push({
        path: '/password/reset',
        query: { ...this.$route.query, email: encodeURIComponent(this.email) },
      })
    },
  },
  mounted() {
    credentialsHandler.getFbSdk()
    this.noSignUp = this.$routeQuery.get('nosignup') === 'true'

    const magicLink = this.$routeQuery.get('magic_link')
    if (magicLink) {
      this.loginWithMagicLink(magicLink)
    }
  },
}
</script>

<style lang="scss" scoped>
.login-container {
  text-align: center;
  max-width: 710px;
  margin: 0 auto;
}

.top-column {
  @include media-breakpoint-up(md) {
    padding-right: 30px;
  }
}

.social-buttons-wrapper {
  @include media-breakpoint-up(md) {
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      left: -30px;
      top: 0;
      width: 1px;
      background-color: $clr-navy-15;
    }
    &:after {
      content: 'or';
      display: block;
      position: absolute;
      width: 20px;
      left: -40px;
      top: 50%;
      background-color: $clr-navy-3;
      color: $clr-navy-50;
      height: 50px;
      line-height: 50px;
      margin-top: -25px;
      text-align: center;
    }
  }
}

.bottom-column {
  @include media-breakpoint-up(md) {
    padding-left: 30px;
  }
}
</style>
