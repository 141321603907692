/**
 * This class should handle any business logic between UI and Store/API.
 * It MUST NOT contain any platform (web vs mobile) specific logic or data (e.g. references to window).
 * It is used by both web and in the future also mobile apps.
 */
import apiSettings from '@/api/settings'
import { events } from '@/utils/mixpanel.events'

export default {
  extendTrial: extendTrial,
}

function extendTrial(store, intercom, token = null) {
  return new Promise((resolve, reject) => {
    apiSettings.extendTrial(token).then(
      () => {
        Promise.all([
          store.dispatch('user/refreshUserData', {}, { root: true }),
          store.dispatch('headers/setUserExpired', false, { root: true }),
          store.dispatch('globals/setIsMealPlannerLocked', false, {
            root: true,
          }),
          store.dispatch(
            'globals/setMealPlanData',
            { mealPlan: 'sample' },
            { root: true }
          ),
          store.dispatch('RecipeBox/refresh', {}, { root: true }),
          store.dispatch('dashboard/refreshMealPlans', {}, { root: true }),
          store.dispatch('dashboard/refreshDashboard'),
        ]).then(
          () => {
            intercom.trackEvent('TRIAL_EXTENDED')
            store.dispatch('analytics/track', {
              eventName: events.trial.extended,
            })
            resolve()
          },
          () => reject()
        )
      },
      () => {
        reject()
      }
    )
  })
}
