let FullStory = new Promise((resolve) => {
  let interval = setInterval(
    () => {
      if (window.FS) {
        resolve(window.FS)
        clearInterval(interval)
      }
    },
    200,
    50
  )
})

export default FullStory
