export default {
  // https://stackoverflow.com/a/4819886/947531
  isTouchDevice() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
    var mq = function (query) {
      return window.matchMedia(query).matches
    }

    if (
      'ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch)
    ) {
      return true
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
    return mq(query)
  },
  isMobile() {
    let ret = {}
    const ua = window.navigator.userAgent
    ret.Android = !!ua.match(/Android/i)
    ret.BlackBerry = !!ua.match(/BlackBerry/i)
    ret.iPhone = !!ua.match(/iPhone|iPod/i)
    ret.iPad = !!ua.match(/iPad/i)
    ret.iOS = ret.iPhone || ret.iPad
    ret.Opera = !!ua.match(/Opera Mini/i)
    ret.Webkit = !!ua.match(/WebKit/i)
    ret.Windows = !!ua.match(/IEMobile/i)
    ret.iOSSafari = ret.iOS && ret.Webkit && !ua.match(/CriOS/i)
    ret.any =
      ret.Android || ret.BlackBerry || ret.iOS || ret.Opera || ret.Windows
    return ret
  },
}
