<template>
  <div
    class="title-card"
    :class="{
      'title-card--large': data.type === 'large',
    }"
  >
    <span class="title">
      {{ data.title }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.title-card {
  color: #414a4d;
  margin-top: 0;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 0;
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
}

.title-card--large {
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
  }
}
</style>
