import _ from 'lodash'
import moment from 'moment'

function getDashboardLoadingListItem() {
  return {
    componentName: 'dashboard-plan-loading-item',
  }
}

function getDashboardExampleListItem() {
  return {
    componentName: 'dashboard-plan-example-item',
  }
}

function getDashboardEmptyListItem() {
  return {
    componentName: 'dashboard-plan-empty-item',
    isDraggable: true,
  }
}

function toDashboardListItem(meal) {
  let { recipe, expirationWarning, weekendPrepWarning } = meal
  return {
    recipeId: recipe.id,
    componentName: 'dashboard-plan-item',
    isDraggable: true,
    title: recipe.title,
    thumb: recipe.thumbMedium,
    thumbTagType: getThumbTagType(recipe),
    thumbTagText: getThumbTagType(recipe),
    mealTypeText: getMealType(recipe),
    subtitle: getListItemSubtitle(recipe),
    isLeftover: meal.leftover,
    id: meal.id,
    mealType: meal.mealType,
    isSwapOutDisabled: false,
    isRemoveDisabled: false,
    rescheduleDescription: weekendPrepWarning || expirationWarning,
    hasWeekendPrep: meal.hasWeekendPrep,
    expirationWarning: expirationWarning,
    weekendPrepWarning: weekendPrepWarning,
    defaultPrepTime: recipe.defaultPrepTime,
    communityRating: recipe.communityRating,
    ratingCount: recipe.ratingCount,
  }
}

function getListItemSubtitle(recipe) {
  return recipe.subtitle.length ? recipe.subtitle : null
}

function getThumbTagType(recipe) {
  if (recipe.isBreakfast) {
    return 'breakfast'
  } else if (recipe.isDessert) {
    return 'dessert'
  } else if (recipe.isSnack) {
    return 'snack'
  }
}

function getMealType(recipe) {
  if (recipe.isBreakfast) {
    return 'breakfast'
  } else if (recipe.isSnack) {
    return 'snack'
  } else if (recipe.isDessert) {
    return 'dessert'
  } else if (recipe.isSide) {
    return 'side'
  }
  return 'lunches & dinners'
}

function getDashboardList(actionStatus = 'enabled') {
  return {
    items: [],
    actionStatus: actionStatus,
  }
}

function recipesReflow(recipes) {
  return _.orderBy(recipes, ['day', 'mealType'], ['asc', 'asc'])
}

function addListItemToDashboardPlan(item, listNumber, plan) {
  plan[listNumber].items.push(item)
}

function getDescription({ mealsNumber, snacksNumber } = {}) {
  if (mealsNumber == null) {
    return 'Loading your plan...'
  } else if (mealsNumber + snacksNumber) {
    let items = [
      [mealsNumber, 'recipe'],
      [snacksNumber, 'snack'],
    ]
      .filter(([number]) => number)
      .map(([number, item]) => `${number} ${item}${number === 1 ? '' : 's'}`)
      .map((text) => `<strong>${text}</strong>`)
      .join(' and ')

    return `You're making ${items} this week. When ready, check out your <router-link to="/grocery-list">grocery list</router-link> and optional <router-link to="/prep-ahead">weekend prep</router-link>.`
  } else {
    return 'You do not currently have any recipes in your plan. Add some above to get started.'
  }
}

// function getRescheduleDescription(meal) {
//   let { pastExpiryDays, day } = meal
//   if (pastExpiryDays > 0) {
//     return `To keep leftovers fresh we'd recommend scheduling your
//     leftover meals on day ${day + 1 - pastExpiryDays} or earlier.`
//   }
// }

function getMealPlansItem(mealPlan) {
  let date = moment(mealPlan.startDate)
  return {
    title: mealPlan.title,
    label: mealPlan.label,
    shortTitle: mealPlan.isSample ? null : date.format('MMM'),
    shortSubTitle: mealPlan.isSample ? null : date.format('DD'),
    value: mealPlan.id,
    userWeeklyPlanId: mealPlan.userWeeklyPlanId,
    isLocked: mealPlan.isLocked,
    isCustom: false,
    isDisabled: true,
    isSample: mealPlan.isSample,
    isNext: mealPlan.isNext,
    isCurrent: mealPlan.isCurrent,
    group: mealPlan.startDate.substring(0, 7),
    category: mealPlan.category,
  }
}

function getMealPlansCustomItem(mealPlan) {
  return {
    title: mealPlan.title,
    label: mealPlan.label,
    shortTitle: null,
    shortSubTitle: null,
    value: 'custom',
    userWeeklyPlanId: mealPlan.id,
    isLocked: mealPlan.isLocked,
    isCustom: true,
    isDisabled: true,
    isNext: false,
    isCurrent: false,
    isSample: false,
    group: 'custom',
  }
}

function getMealPlansEmptyItem() {
  return {
    title: 'Loading plans...',
    isDisabled: true,
    isCustom: false,
  }
}

function getRecipesTypesNumbers(meals) {
  let recipes = meals
    .filter(({ leftover, inPlan }) => inPlan && !leftover)
    .map(({ recipe }) => recipe)
  let snacksNumber = recipes.filter(
    ({ isSnack, isDessert }) => isSnack || isDessert
  ).length

  return {
    mealsNumber: recipes.length - snacksNumber,
    snacksNumber: snacksNumber,
  }
}

export default {
  getLoadingDashboardPlan: (listsNumber) => {
    let plan = Array.from({ length: listsNumber }, () =>
      getDashboardList('disabled')
    )
    plan.forEach((list) => {
      list.items = Array.from({ length: 3 }, () =>
        getDashboardLoadingListItem()
      )
    })
    return plan
  },
  getEmptyDashboardPlan: (listsNumber) =>
    Array.from({ length: listsNumber }, () => getDashboardList('enabled')),
  getDashboardList,
  toDashboardListItem,
  getDashboardEmptyListItem,
  getDashboardLoadingListItem,
  getDashboardExampleListItem,
  recipesReflow,
  addListItemToDashboardPlan,
  getDescription,
  getRecipesTypesNumbers,
  getMealPlansItem,
  getMealPlansCustomItem,
  getMealPlansEmptyItem,
}
