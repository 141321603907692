import env from '@/environment'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

firebase.initializeApp(env.firebaseConfig)

const FirebasePlugin = {
  install(Vue) {
    Vue.prototype.$firebase = firebase
  },
}

export default FirebasePlugin
