<template>
  <div id="user-subscribed-modal">
    <b-modal
      v-if="visible"
      ref="userSubscribedModal"
      dialog-class="user-subscribed-modal"
      hide-footer
      centered
      :no-close-on-esc="false"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      :hide-header="true"
    >
      <section class="joining-meal-planner-panel">
        <div class="joining-meal-planner-content">
          <a class="close-modal" @click.prevent="close()">
            <Close class="close-icon" @click="close()" />
          </a>
          <h1>Welcome!</h1>

          <p>
            Thank you for joining Forks Meal Planner! You will receive delicious
            new meal plans and exclusive new recipes every Thursday.
          </p>

          <p>
            Check out your account messenger for extra welcome info shortly.
          </p>

          <p>In fact, we have your first meal plan ready to go. Enjoy!</p>
          <button
            @click="close()"
            class="btn-primary joining-meal-planner-btn"
            aria-label="Close"
            data-spec="get-started"
          >
            Get Started!
          </button>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { Close } from '@/components/common/MPIcons'
export default {
  name: 'UserSubscribedModal',
  components: {
    Close,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    close() {
      this.$nextTick(() => this.$refs.userSubscribedModal.hide())
    },
  },
  watch: {
    visible(isVisible) {
      if (isVisible) {
        this.$nextTick(() => this.$refs.userSubscribedModal.show())
      }
    },
  },
}
</script>

<style lang="scss">
#user-subscribed-modal {
  $become-member-bg: '~@/assets/images/sample-plan/welcome_bg.jpg';

  $content-width: 75%;
  $content-width-mobile: 100%;

  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .modal-content {
    background-image: url($become-member-bg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    h1 {
      display: block !important;
      font-size: 2.5rem;
      text-transform: uppercase;
      margin: 0;
      height: inherit;
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 8px;
      text-align: left;
      color: $text-muted;
    }
  }

  .joining-meal-planner-panel {
    width: $content-width-mobile;
    margin: 0 auto 10px;
  }

  .joining-meal-planner-content {
    background-color: white;
    padding: 30px;
    text-align: center;
    position: relative;
  }

  .joining-meal-planner-btn {
    width: 100%;
    font-size: 20px !important;
    margin-bottom: 0;
    position: initial !important;
    top: initial !important;
    right: initial !important;
    margin-top: 30px;
  }

  // Media query
  @include media-breakpoint-up(md) {
    .modal-dialog {
      max-width: 600px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }

    .modal-body {
      padding: 60px 30px;
      margin-top: 16px;
    }

    .modal-content {
      h1 {
        font-size: 3rem;
      }
    }

    .joining-meal-planner-panel {
      width: $content-width;
    }
  }
}
</style>
