<template>
  <button
    class="btn"
    @click="onClick"
    :class="`btn-${color} ${computedSize} ${classes}`"
    :type="type"
    v-bind="$attrs"
    :data-spec="dataSpec"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'MPButton',
  props: {
    type: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: 'none',
    },
    size: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    dataSpec: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClick(event) {
      this.$emit('click', event)
    },
  },
  computed: {
    computedSize() {
      return this.size ? `btn-${this.size}` : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  margin: 0;
  min-height: 40px;
  outline: none;
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
}

.btn-orange {
  color: #ffffff;
  background-color: #ff8e44;
  transition: all 0.1s linear;
  &:hover {
    color: #ffffff;
    background-color: #f06926;
  }
  &:disabled {
    border: none;
    &:hover {
      border: none;
    }
  }
}

.btn-red {
  color: #fff;
  background-color: $pale-red;
  border-color: $pale-red;
  &:hover {
    background-color: $pale-red-hover;
    border-color: $pale-red-hover;
    color: #fff;
  }
  &:disabled {
    background-color: $light-grey;
    border: none;
    &:hover {
      background-color: $light-grey;
      border: none;
    }
  }
}

.btn-white {
  background-color: $white;
  border-color: #dedede;
  &:hover {
    border-color: $clr-water;
  }
}

.btn-green {
  color: #ffffff;
  background-color: #94c562;
  transition: all 0.1s linear;
  &:hover {
    color: #ffffff;
    background-color: #709948;
  }
  &:disabled {
    background-color: $light-grey;
    border: none;
    &:hover {
      background-color: $light-grey;
      border: none;
    }
  }
}

.btn-grey {
  background-color: #dedede;
}

.btn-grey-outline {
  border: solid 1px $clr-navy-50;
  transition: all 0.1s linear;
  &:hover {
    border-color: $clr-water;
    color: $clr-water;
  }
  &:disabled {
    border-color: $light-grey;
    color: $light-grey;
    &:hover {
      border-color: $light-grey;
      color: $light-grey;
    }
  }
}

.btn-grey:hover {
  background-color: #dedede;
}

.btn-red-outline {
  color: #db3f37;
  border-color: #db3f37;
  background-color: #ffffff;
}

.btn-grey-orange {
  color: white;
  background-color: #a8acb1;
  &:hover {
    color: white;
    background-color: #ff8e44;
  }
}

.btn-fb {
  color: white;
  background-color: #3b5998;
}

.btn-fb:hover {
  color: white;
  background-color: #2d4373;
}

.btn-settings {
  color: #f06926;
  background-color: transparent;
  border-color: #dedede;
  border: 1px solid;
  @include media-breakpoint-up(md) {
    color: $grey;
    background-color: #ffffff;
    border-color: #dedede;
    &:hover {
      border-color: #aacb89;
    }
  }
}

.btn-settings-action {
  @extend .btn-orange;
  height: 38px;
}

.btn-medium {
  min-height: 38px;
}

.btn-large {
  min-height: 50px;
  font-size: $h4-font-size;
}

.btn-large-register {
  height: 50px;
  font-weight: bold;
  font-size: $h4-font-size;
  letter-spacing: 0.5px;
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 345px;
  }
}

.btn-full-width {
  width: 100%;
}

.btn-60 {
  min-height: 60px;
  font-weight: 500;
  font-size: 1rem;
}
.btn-15 {
  min-height: 15px;
  font-weight: 400;
  font-size: 0.8rem;
}

.btn-dark {
  border: none;
  background-color: $grey;
  &:hover {
    background-color: #404852;
  }

  &:disabled {
    background-color: $light-grey;
    border: none;
    &:hover {
      background-color: $light-grey;
      border: none;
    }
  }
}
.btn-red {
  background-color: $red;
}

.btn-white-outline {
  border: 1px solid $white;
  color: $white;
  border-radius: 0;
}

.btn-badge {
  display: flex;
  border-radius: 25px;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  margin-left: 5px;
  margin-bottom: 5px;
}
</style>
