<template>
  <div class="testimonial-container">
    <div class="quote-avatar-container">
      <img
        class="quote-avatar"
        :src="require('@/assets/images/premium-quote-avatar@2x.png')"
      />
    </div>
    <div class="quote-text-container">
      <div class="quote d-md-none" v-html="quoteMobile" />
      <div class="quote d-none d-md-flex" v-html="quote" />
      <div class="quote-author">— Lori L, Adrian, MI</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quoteMobile: `“To me this is the most successful plan to eat and be healthy. The meals are very quick to put together and really tasty. Without this plan I would not be successful at following a whole-food, plant-based diet. I'm now much more confident putting a dish together... I don't want to eat any other way!”`,
      quote: `“Without this plan I would not be successful at following a whole-food, plant-based diet. I'm now much more confident putting a dish together... I don't want to eat any other way!”`,
    }
  },
}
</script>

<style lang="scss" scoped>
.testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}
.quote {
  font-family: $font-family-serif;
  display: inline-block;
  line-height: 1.63;
  letter-spacing: 0.5px;
}

.quote-avatar-container {
  width: 77px;
  align-self: flex-start;
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    margin: 0 14px 0 0;
    width: 71px;
  }
}

.quote-avatar {
  width: 71px;
  height: 71px;
  margin-bottom: 17px;
}

.quote-text-container {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    width: calc(100% - 71px - 14px);
  }
}

.quote-author {
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: $success;
  font-weight: bold;
  margin-top: 30px;
  text-transform: uppercase;
  @include media-breakpoint-up(md) {
    margin-top: 15px;
  }
}
</style>
