<template>
  <div class="password-reset">
    <CredentialsHeader>
      <template v-slot:center>
        <div class="sign-in-header-logo">
          <img src="@/assets/images/fok-mp-new-logo.svg" />
        </div>
      </template>
    </CredentialsHeader>
    <div class="password-change-form credential-wrapper">
      <MPAlert
        :text="errorMessage"
        type="error"
        :is-dismissable="true"
        @dismiss="dismissError()"
      ></MPAlert>
      <h1>Set a Password to Claim your Gift</h1>
      <p class="text-center">
        You're only 2 steps away from accessing FORKS Meal Planner!
        <b>First</b>, please set a password. <b>Second</b>, log in with that
        password and your email.
      </p>
      <form @submit.prevent="checkEmail">
        <PasswordInput v-model="password" />
        <MPButton type="submit" color="primary" size="large" classes="col-12">
          Continue to Login
        </MPButton>
      </form>
    </div>
  </div>
</template>

<script>
import { MPAlert, MPButton } from '@/components/common'
import PasswordInput from '@/components/credentials/PasswordInput'
import apiCheckout from '@/api/checkout'
import LoadingMixin from '@/mixins/LoadingMixin'
import NotificationsMixin from '@/mixins/NotificationsMixin'
import CredentialsHeader from '../CredentialsHeader.vue'

export default {
  mixins: [LoadingMixin, NotificationsMixin],
  components: {
    MPAlert,
    MPButton,
    PasswordInput,
    CredentialsHeader,
  },
  name: 'PasswordChangeForm',
  data() {
    return {
      password: '',
      errorMessage: '',
    }
  },
  methods: {
    checkEmail() {
      this.setLoading(true)
      this.errorMessage = ''
      apiCheckout
        .resetPassword({
          new_password: this.password,
          uid: this.$route.params.uid,
          token: this.$route.params.token,
        })
        .then((response) => {
          const email = response.email
          this.$analytics.track('mp changed password', {
            email: email,
          })
          this.$router.replace('/login')
          this.createInfoNotification({
            text: 'Password set sucessfully',
            closeAfter: 10,
            color: 'primary',
            full: true,
          })
        })
        .catch(() => {
          this.setLoading(false)
          this.errorMessage = `Oops, looks like something went wrong. <a  href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
    dismissError() {
      this.errorMessage = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.password-reset {
  @include signup-bg;
}
.header-text {
  font-size: $h4-font-size;
}
.credential-wrapper {
  max-width: 510px;
  margin: 0 auto;
  padding: 64px 20px 20px;
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
}
.sign-in-header-logo {
  display: flex;
  width: 290px;

  img {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    width: 180px;
  }
}
.password-change-form {
  h1 {
    text-align: center;
    margin: 2rem 0;
  }
  .material-input {
    margin-bottom: 1rem;
  }
}
.forgot-password {
  font-size: 12px;
  font-weight: 400;
  margin-top: 1rem;
  text-align: center;
}
</style>
