import * as types from '../mutation-types'

const baseState = {
  isVisible: false,
  color: '',
  icon: '',
  hasHtml: false,
  text: '',
  html: null,
  isAction: false,
  actionText: '',
  closeActionText: '',
  isFull: false,
  action: null,
  closeAction: null,
  closeTimout: null,
}

const state = { ...baseState }

const getters = {
  visible: (state) => state.isVisible,
  actions: (state) => ({
    isAction: state.isAction,
    actionText: state.actionText,
    closeActionText: state.closeActionText,
    action: state.action ? state.action : () => {},
    closeAction: state.closeAction ? state.closeAction : () => {},
  }),
  text: (state) => state.text,
  html: (state) => ({
    hasHtml: state.hasHtml,
    component: state.html,
  }),
  icon: (state) => state.icon,
  color: (state) => state.color,
  full: (state) => state.full,
}

const actions = {
  setActionNotification({ commit, dispatch }, notification) {
    commit(types.SET_VISIBLE_NOTIFICATION)
    commit(types.SET_ACTION_NOTIFICATION, notification.action)
    commit(types.SET_COLOR_NOTIFICATION, notification.color)
    if (notification.full) {
      commit(types.SET_IS_FULL, notification.full)
    }
    if (notification.icon) {
      commit(types.SET_ICON_NOTIFICATION, notification.icon)
    }
    if (notification.html) {
      commit(types.SET_HTML_NOTIFICATION, notification.html)
    } else {
      commit(types.SET_TEXT_NOTIFICATION, notification.text)
    }
    if (notification.closeAfter) {
      dispatch('closeNotificationIn', notification.closeAfter)
    }
  },
  setInfoNotification({ commit, dispatch }, notification) {
    commit(types.SET_VISIBLE_NOTIFICATION)
    commit(types.SET_COLOR_NOTIFICATION, notification.color)
    if (notification.full) {
      commit(types.SET_IS_FULL, notification.full)
    }
    if (notification.icon) {
      commit(types.SET_ICON_NOTIFICATION, notification.icon)
    }
    if (notification.html) {
      commit(types.SET_HTML_NOTIFICATION, notification.html)
    } else {
      commit(types.SET_TEXT_NOTIFICATION, notification.text)
    }
    if (notification.closeAfter) {
      dispatch('closeNotificationIn', notification.closeAfter)
    }
  },
  clearNotification({ commit }) {
    commit(types.CLEAR_NOTIFICATION_TIMEOUT)
    commit(types.RESET_NOTIFICATION)
  },
  closeNotificationIn({ commit, dispatch }, time) {
    commit(types.SET_NOTIFICATION_TIMEOUT, () =>
      setTimeout(() => {
        dispatch('clearNotification')
      }, time * 1000)
    )
  },
  cancelTimeout({ commit }) {
    commit(types.CLEAR_NOTIFICATION_TIMEOUT)
  },
}

const mutations = {
  [types.SET_VISIBLE_NOTIFICATION](state) {
    state.isVisible = true
  },
  [types.HIDE_NOTIFICATION](state) {
    state.isVisible = false
  },
  [types.SET_ICON_NOTIFICATION](state, icon) {
    state.icon = icon
  },
  [types.SET_TEXT_NOTIFICATION](state, text) {
    state.text = text
  },
  [types.SET_HTML_NOTIFICATION](state, component) {
    state.hasHtml = true
    state.html = component
  },
  [types.SET_CLOSE_NOTIFICATION](state) {
    state.isAction = false
  },
  [types.SET_ACTION_NOTIFICATION](state, options) {
    state.isAction = true
    state.actionText = options.actionText
    state.closeActionText = options.closeActionText
    state.action = options.action
    state.closeAction = options.closeAction
  },
  [types.SET_NOTIFICATION_TIMEOUT](state, timeout) {
    clearTimeout(state.closeTimout)
    state.closeTimout = timeout()
  },
  [types.CLEAR_NOTIFICATION_TIMEOUT](state) {
    clearTimeout(state.closeTimout)
  },
  [types.SET_COLOR_NOTIFICATION](state, color) {
    state.color = color
  },
  [types.SET_IS_FULL](state, full) {
    state.full = full
  },
  // eslint-disable-next-line no-unused-vars
  [types.RESET_NOTIFICATION](state) {
    state.isVisible = false
    state.color = ''
    state.icon = ''
    state.hasHtml = false
    state.text = ''
    state.html = null
    state.isAction = false
    state.actionText = ''
    state.closeActionText = ''
    state.action = null
    state.full = false
    state.closeAction = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
