<template>
  <div class="text-item">
    <span class="text-item-text" v-html="data.text"></span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.text-item {
  text-align: center;
  font-size: 0.875rem;
  margin: 1rem;
}
.text-item-text {
  display: block;
  color: #727e8c;
}
</style>
