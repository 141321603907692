<template>
  <header>
    <div class="container">
      <div class="file-upload">
        <input
          @change="onChange"
          name="custom-recipe-image"
          type="file"
          id="custom-recipe-image-header"
          class="d-none"
        />
        <label
          for="custom-recipe-image-header"
          class="drag-drop__container p-4"
          @dragover.prevent
          @drop="onDrop"
        >
          <img v-if="image" :src="image" class="w-100" />
          <div
            v-else
            class="d-flex flex-column align-items-center align-content-around justify-content-center h-75"
          >
            <img src="@/assets/icons/recipe-template-upload.svg" />
            <div class="drag-drop__text mt-3">
              <span>Make me pretty.</span>
              <br />
              Drag and drop images here
              <br />
              <small class="drag-drop__text--small">
                or
                <u>upload</u> from computer (optional)
              </small>
            </div>
          </div>
        </label>
        <div class="w-100 text-danger" v-text="info" />
      </div>
      <div
        class="header-image"
        :style="`background-image: url(${placeholderImage})`"
      ></div>
      <slot />
    </div>
  </header>
</template>

<script>
import UploadImageMixin from '@/mixins/UploadImageMixin'
export default {
  name: 'RecipeHeaderImageUpload',
  mixins: [UploadImageMixin],
  props: {
    placeholderImage: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
header {
  position: relative;
  background: rgba(216, 216, 216, 0.5)
    url('~@/assets/images/custom-recipe-background.png') no-repeat center;
  background-size: cover;
}
.header-image {
  height: 50vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include media-breakpoint-up(md) {
    display: none;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(51, 51, 51, 0) 40%
    );
  }
}
.file-upload {
  display: none;
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.header-close {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 10px;
  width: 35px;
  height: 35px;
  background-color: transparent;
  background-image: url('~@/assets/icons/dashboard-remove-white.svg');
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;

  @include media-breakpoint-up(md) {
    border-radius: 50%;
    top: 55px;
    right: 20px;
    width: 60px;
    height: 60px;
    background: #fff url('~@/assets/icons/dashboard-remove-gray.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    pointer-events: auto;
  }
}
.drag-drop {
  &__container {
    border: 2px $clr-navy-50 dashed;
    overflow-y: scroll;
    width: 100%;
    position: relative;
    margin: 1rem;
    @include media-breakpoint-up(md) {
      height: 490px;
      margin: 1.875rem;
    }
  }
  &__text {
    font-size: rem(24);
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: center;

    span {
      font-size: rem(18);
      text-transform: uppercase;
    }

    &--small {
      text-transform: none;
      font-weight: 400;
      font-size: 1rem;
    }
  }
}
</style>
