import 'url-search-params-polyfill'

const getURLQueryParameters = {
  get(key) {
    const query = new URLSearchParams(window.location.search)
    return query.get(key)
  },
}

const RouteQueryPlugin = {
  install(Vue) {
    Vue.prototype.$routeQuery = getURLQueryParameters
  },
}

export default RouteQueryPlugin
