import { saveAs } from 'file-saver'

function isEdge() {
  return navigator.userAgent.indexOf('Edge') > -1
}

function isIE() {
  return (
    navigator.userAgent.indexOf('MSIE') > -1 ||
    navigator.userAgent.indexOf('Trident') > -1
  )
}

function isMobileCheck() {
  return navigator.userAgent.match(/Mobile/i)
}

function isAndroid() {
  return navigator.userAgent.match(/Android/i)
}

function isIOS() {
  return (
    navigator.userAgent.match(/Safari/i) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1
  )
}

function downloadAsFile(fileUrl, fileName) {
  let a = document.createElement('a')
  a.style.display = 'none'
  a.href = fileUrl
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  setTimeout(function () {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(a.href)
  }, 1000)
}

export function printFile(blob, fileUrl, fileName) {
  if (isIOS() || isAndroid()) {
    saveAs(blob, fileName)
    return
  }

  let iframe = document.createElement('iframe')
  document.body.appendChild(iframe)

  iframe.style.display = 'none'
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus()
      iframe.contentWindow.print()
    }, 500)
  }
  iframe.src = fileUrl
}
