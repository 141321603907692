<template>
  <div class="mp-collapse" :class="variant">
    <div class="mp-collapse-header" @click="toggleIsOpen">
      <span class="mp-collapse-title">{{ title }}</span>
      <span
        v-if="description"
        class="mp-collapse-description"
        :class="{ 'mp-collapse-description--open': isOpen }"
      >
        {{ description }}
      </span>
      <i
        class="mp-collapse-icon"
        :class="{ 'mp-collapse-icon--open': isOpen }"
      ></i>
    </div>
    <transition name="slide-fade">
      <div class="mp-collapse-content" v-if="isOpen">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    isOpenOnMount: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'white',
    },
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen
      this.$nextTick(() => {
        if (this.isOpen) {
          const { offsetTop, offsetHeight } = this.$el
          this.$emit('from-top-on-open', offsetTop + offsetHeight)
        }
      })
    },
  },
  data() {
    return {
      isOpen: true,
    }
  },
  beforeMount() {
    this.isOpen = this.isOpenOnMount
  },
}
</script>

<style lang="scss" scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
  max-height: 100%;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-15px);
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
}

.mp-collapse {
  margin-top: 20px;
}

.white {
  background-color: $white;
}

.transparent {
  background-color: $transparent;
}

.mp-collapse-header {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 38px;
  padding: 10px;
}

.mp-collapse-title {
  font-size: 1rem;
  font-weight: 500;
  flex: 1;
}

.mp-collapse-description {
  font-size: 12px;
  margin-right: 15px;
  text-transform: capitalize;
  &--open {
    display: none;
  }
}

.mp-collapse-icon {
  width: 12px;
  height: 12px;
  background-image: url('~@/assets/icons/collapse.svg');
  transform: rotate(180deg);
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s;
}

.mp-collapse-icon--open {
  transform: rotate(0deg);
}

.mp-collapse-content {
  padding: 0 10px 10px;
}
</style>
