var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isLoggedIn && _vm.promotionBannerVisible && _vm.affiliateOfferDiscount < 0)?_c('MPNotification',{staticClass:"full",attrs:{"type":"error","title":_vm.getOfferErrorTitle}}):_vm._e(),(
      !_vm.isLoggedIn &&
      _vm.promotionBannerVisible &&
      (_vm.affiliateOfferDiscount > 0 || _vm.alwaysDisplayOfferBanner)
    )?_c('MPNotification',{staticClass:"full",attrs:{"type":"offer","title":_vm.getOfferTitle}}):_vm._e(),(
      _vm.alertMemberBannerVisible && _vm.promotionInfo.userDiscountApplied === false
    )?_c('MPNotification',{staticClass:"full-with-navbar add-lr-padding",attrs:{"type":"error","image":require('@/assets/icons/error.svg'),"showCloseButton":true},on:{"onCloseActionSelect":function($event){return _vm.setAlertMemberBannerVisible(false)}}},[_c('span',[_vm._v(_vm._s(_vm.getOfferErrorTitle)+" Please "),_c('MPWhiteLink',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.onContact.apply(null, arguments)}}},[_vm._v("contact")]),_vm._v(" our support team if you have questions.")],1)]):_vm._e(),(
      _vm.alertMemberBannerVisible &&
      !!_vm.promotionInfo.userDiscountApplied &&
      _vm.promotionInfo.userDiscountPercent > 0
    )?_c('MPNotification',{staticClass:"full-with-navbar add-lr-padding",attrs:{"type":"offer","image":require('@/assets/icons/check-green.svg'),"showCloseButton":true},on:{"onCloseActionSelect":function($event){return _vm.setAlertMemberBannerVisible(false)}}},[(_vm.promotionInfo.userDiscountPopUpMessage)?_c('span',[_vm._v(" "+_vm._s(_vm.promotionInfo.userDiscountPopUpMessage)+" ")]):_c('span',[_vm._v("Discount of "+_vm._s(_vm.promotionInfo.userDiscountPercent)+"% was successfully applied to your account.")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }