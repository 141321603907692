<template>
  <div class="register-select-meals-form mt-4 mx-md-auto mx-2" v-show="show">
    <h2>Would you like us to schedule breakfasts?</h2>
    <p class="mt-3 mt-md-4">You can always change this later.</p>
    <div class="meals-form-container mt-4 mt-md-5">
      <MPSelectBox
        class="meals-option"
        title="Yes, schedule breakfasts"
        :image="require('@/assets/icons/onboarding-breakfast.svg')"
        :isSelected="breakfastOptin"
        @click="breakfastSelected()"
        data-spec="schedule-breakfasts"
      ></MPSelectBox>
      <MPSelectBox
        class="meals-option"
        title="No, I’ll handle it myself"
        :image="require('@/assets/icons/onboarding-lunch.svg')"
        :isSelected="!breakfastOptin"
        @click="noBreakfastSelected()"
      ></MPSelectBox>
    </div>
    <ButtonNext class="next-button" @click="next()" data-spec="next-button" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonNext from './ButtonNext'
import { MPSelectBox } from '@/components/common'
import ExternalServices from '@/externalServices'

export default {
  name: 'RegisterSelectMealsForm',
  components: {
    MPSelectBox,
    ButtonNext,
  },
  computed: {
    ...mapGetters({
      breakfastOptin: 'registration/breakfastOptin',
    }),
  },
  data() {
    return {
      show: false,
      reverseForm: true,
      showMostPopularTag: true,
    }
  },
  methods: {
    next() {
      this.$emit('onMealsSelected')
    },
    noBreakfastSelected() {
      this.$store.dispatch('registration/setBreakfastOptin', false)
      this.$store.dispatch('registration/setSnackOptin', true)
      this.$store.dispatch('registration/setDessertOptin', true)
    },
    breakfastSelected() {
      this.$store.dispatch('registration/setBreakfastOptin', true)
      this.$store.dispatch('registration/setSnackOptin', false)
      this.$store.dispatch('registration/setDessertOptin', false)
    },
  },
  mounted() {
    setTimeout(() => {
      this.show = true
      if (ExternalServices.ABTests.registrationSelectMealsReverse) {
        this.breakfastSelected()
      }

      this.showMostPopularTag =
        ExternalServices.ABTests.showRegistrationMostPopularTag
    }, 250)
  },
}
</script>

<style lang="scss" scoped>
.register-select-meals-form {
  text-align: center;
  max-width: 710px;
  margin: 0 auto;
}

.meals-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.meals-option {
  flex-direction: column;
  margin: 0;
  & + .meals-option {
    margin: 12px 0 0;
  }

  ::v-deep .title {
    margin: 0;
    padding: 0;
    font-size: $font-size-small;
  }

  @include media-breakpoint-up(md) {
    max-height: 165px;
    max-width: 165px;
    padding: 2.5rem 1.6rem;
    ::v-deep .title {
      font-size: 1rem;
    }

    & + .meals-option {
      margin: 0 0 0 24px;
    }
  }
}

.select-box--selected {
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 41, 64, 0.2);
  border: solid 2px $clr-water;
}

.next-button {
  margin-top: 51px;
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
}
</style>
