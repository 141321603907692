<template>
  <div class="register" v-show="show">
    <CredentialsHeader class="registration-header">
      <template v-slot:left>
        <HeaderLogo v-if="showLeftLogo" />
        <h1
          v-if="showBack"
          class="registration-back-button"
          @click="goBack()"
        ></h1>
      </template>
      <template v-slot:center>
        <HeaderLogo v-if="showCenterLogo" />
        <RegistrationSteps
          v-if="showRegistrationSteps"
          :steps="steps"
          :currentRegistrationStep="currentRegistrationStep"
          @onStepChanged="onStepChanged"
        ></RegistrationSteps>
      </template>
      <template v-slot:right>
        <div class="header-text d-none d-md-block">
          <span class="text">
            Already have an account?
            <b-link @click="goToSignIn()">Sign in</b-link>
          </span>
        </div>
        <div class="header-text d-block d-md-none">
          <b-link @click="goToSignIn()">Sign in</b-link>
        </div> </template
      >ro
    </CredentialsHeader>
    <transition name="fade">
      <div class="credential-wrapper">
        <router-view
          @onServingsSelected="onServingsSelected"
          @onIntolerancesSelected="onIntolerancesSelected"
          @onMealsSelected="onMealsSelected"
          @onRegister="onRegister"
          @onFacebookRegister="onFacebookRegister"
          @onGoogleRegister="onGoogleRegister"
          @onGoogleRegisterFailed="onGoogleRegisterFailed"
          @onAppleRegister="onAppleRegister"
        ></router-view>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CredentialsHeader from '@/components/credentials/CredentialsHeader'
import HeaderLogo from '@/components/credentials/HeaderLogo'
import RegistrationSteps from '@/components/credentials/register/RegistrationSteps'
import credentialsHandler from '@/handlers/CredentialsHandler'
import LoadingMixin from '@/mixins/LoadingMixin'
import ExternalServices from '@/externalServices'
import { hideIntercomWidget } from '@/utils/intercom'
import DetectDeviceMixin from '@/mixins/DetectDeviceMixin'
import env from '@/environment'
import { getFbSdk } from '@/handlers/FBHandler'
import { events } from '@/utils/mixpanel.events'

export default {
  mixins: [LoadingMixin, DetectDeviceMixin],
  name: 'PaywallRegister',
  data() {
    return {
      show: false,
      showStepsOnFirstStep: false,
    }
  },
  components: {
    CredentialsHeader,
    HeaderLogo,
    RegistrationSteps,
  },
  computed: {
    ...mapGetters({
      steps: 'registration/registrationSteps',
      currentRegistrationStep: 'registration/currentRegistrationStep',
      getRegistrationStepsToResume: 'registration/getRegistrationStepsToResume',
      adultServings: 'registration/adultServings',
      childServings: 'registration/childServings',
      soyIntolerance: 'registration/soyIntolerance',
      glutenIntolerance: 'registration/glutenIntolerance',
      nutsIntolerance: 'registration/nutsIntolerance',
      breakfastOptin: 'registration/breakfastOptin',
      lunchOptin: 'registration/lunchOptin',
      dinnerOptin: 'registration/dinnerOptin',
      snackOptin: 'registration/snackOptin',
      dessertOptin: 'registration/dessertOptin',
      additionalIntolerances: 'registration/additionalIntolerances',
      email: 'registration/email',
      fullName: 'registration/fullName',
      password: 'registration/password',
      generalEmailsOptin: 'registration/generalEmailsOptin',
      loginURL: 'globals/loginURL',
    }),
    isInitialRegistrationStep() {
      return this.$route.path.match(/(register\/?$|register\/1$)/)
    },
    showLeftLogo() {
      return this.isInitialRegistrationStep && this.showStepsOnFirstStep
    },
    showCenterLogo() {
      return this.isInitialRegistrationStep && !this.showStepsOnFirstStep
    },
    showBack() {
      return !this.isInitialRegistrationStep
    },
    showRegistrationSteps() {
      return !this.isInitialRegistrationStep || this.showStepsOnFirstStep
    },
  },
  methods: {
    goToSignIn() {
      this.$analytics.track('mp sign in click')
      this.$router.push(this.loginURL)
    },
    goBack() {
      this.$store.dispatch('registration/previousRegistrationStep')
    },
    goToNextStep() {
      this.$store.dispatch('registration/setRegistrationStepDone')
      this.$store.dispatch('registration/nextRegistrationStep')
    },
    onServingsSelected() {
      this.$analytics.track(events.register.servingPage.nextClicked)
      this.goToNextStep()
    },
    onIntolerancesSelected() {
      this.$analytics.track(events.register.allergiesPage.nextClicked)
      this.goToNextStep()
    },
    onMealsSelected() {
      this.$analytics.track(events.register.scheduledMeals.nextClicked)
      this.goToNextStep()
    },
    onStepChanged(stepIndex) {
      this.$store.dispatch('registration/setRegistrationStep', stepIndex)
    },
    onRegister() {
      this.$store.dispatch('registration/setRegistrationError', '')
      this.$store.dispatch('registration/setRegistrationType', 'email')
      this.$router.push('/payment')
    },
    onGoogleRegisterFailed(error) {
      if (error && error.error === 'popup_closed_by_user') return
      this.$store.dispatch('registration/setRegistrationError', error)
    },
    onGoogleRegister(googleUser) {
      this.$store.dispatch('registration/setRegistrationError', '')
      this.$store.dispatch('registration/setRegistrationType', 'google')
      this.$store.dispatch('registration/setGoogleUserInfo', googleUser)
      this.$router.push('/payment')
    },
    onAppleRegister() {
      this.$store.dispatch('registration/setRegistrationError', '')
      const provider = new this.$firebase.auth.OAuthProvider('apple.com')
      provider.addScope('email')
      provider.addScope('name')
      this.$firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          this.$store.dispatch('registration/setRegistrationType', 'apple')
          this.$store.dispatch('registration/setAppleUserInfo', result)
          this.$router.push('/payment')
        })
        .catch((error) => {
          this.$store.dispatch(
            'registration/setRegistrationError',
            error.message
          )
        })
    },
    onFacebookRegister() {
      this.$store.dispatch('registration/setRegistrationError', '')
      const { appId, version } = env.facebook
      getFbSdk({ appId, version }).then((FB) => {
        FB.login(
          (response) => {
            if (response.authResponse) {
              return FB.api(
                '/me',
                { fields: 'id,name,email,picture' },
                (response) => {
                  if (!response || response.error) {
                    return
                  }
                  this.$store.dispatch(
                    'registration/setRegistrationType',
                    'facebook'
                  )
                  this.$store.dispatch(
                    'registration/setFacebookUserInfo',
                    response
                  )
                  this.$router.push('/payment')
                }
              )
            } else {
              this.$store.dispatch(
                'registration/setRegistrationError',
                'User cancelled registration or did not fully authorize.'
              )
            }
          },
          { scope: 'public_profile, email' }
        )
      })
    },
  },
  watch: {
    currentRegistrationStep: {
      handler: function (value) {
        const parentUrl = this.$route.matched[0]
        this.$router.push({
          path: `${parentUrl.path}/${this.steps[value - 1].path}`,
          query: this.$route.query,
        })
      },
      immediate: true,
    },
    $route: {
      handler: function (to) {
        let urlStep = parseInt(to.path.match(/\d+/).join(''))

        if (this.currentRegistrationStep !== urlStep) {
          this.onStepChanged(urlStep)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    setTimeout(() => {
      hideIntercomWidget(true)
    }, 1000)
    credentialsHandler.getFbSdk()

    const stepsToResume = this.getRegistrationStepsToResume
    if (stepsToResume) {
      const goToStep = stepsToResume[0]
      if (goToStep) {
        this.$store.dispatch('registration/setRegistrationStep', goToStep.index)
      }
      if (goToStep.index === 1)
        this.$analytics.track(events.register.goalsPage.view)
    } else {
      this.$analytics.track(events.register.goalsPage.view)
    }

    if (this.$routeQuery.get('email')) {
      this.$store.dispatch(
        'registration/setUserEmail',
        decodeURIComponent(this.$routeQuery.get('email'))
      )
    }

    setTimeout(() => {
      this.show = true
      this.showStepsOnFirstStep = ExternalServices.ABTests.showStepsOnFirstStep
    }, 250)
  },
  destroyed() {
    if (!this.isMobile().any) {
      hideIntercomWidget(false)
    }
  },
}
</script>

<style lang="scss" scoped>
.registration-header {
  background-color: white;
  height: 5rem;
  width: 100%;
  @include media-breakpoint-down(sm) {
    height: 4rem;
  }
}
.header-text {
  font-size: 12px;
  font-weight: 400;
}
.credential-wrapper {
  padding: 64px 20px 20px;
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
}
.registration-back-button {
  width: 16px;
  height: 9px;
  background: url('~@/assets/icons/icon-arrow-back-orange.svg') no-repeat center;
  background-position-y: 20px;
  cursor: pointer;
  padding: 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
