import store from '@/store'

export function handleError(error) {
  if (!error.response) {
    store.dispatch('globals/setConnectionError', true, { root: true })
    return
  }
  const errorCode = error.response.status
  switch (errorCode) {
    case 409:
      store.dispatch('globals/showMealPlanChangeModal', null, { root: true })
      break
    case 404:
      store.dispatch('globals/setNoPlanError', 'Plan does not exist')
      break
    default:
      break
  }
  if (errorCode >= 500) {
    store.dispatch('globals/setServerError', error.response.status, {
      root: true,
    })
  }
}
