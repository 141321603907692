<template>
  <div class="register-select-intolerances-form mt-4 mx-md-auto mx-2">
    <h2>
      Are you allergic to nuts,<br />
      gluten or soy?
    </h2>
    <div class="intolerances-form-container mt-4 mt-md-5">
      <MPSelectBox
        class="select-button"
        title="No"
        :isSelected="!hasIntolerances"
        @click="setHasNoIntolerances()"
        data-spec="no"
      ></MPSelectBox>
      <MPSelectBox
        class="select-button"
        title="Yes"
        :isSelected="hasIntolerances"
        @click="setHasIntolerances()"
        data-spec="yes"
      ></MPSelectBox>
    </div>
    <div class="mt-4 mt-md-5" v-if="hasIntolerances">
      <h4 class="mb-4">Which intolerances should we be aware of?</h4>
      <div class="intolerances-form-container">
        <MPSelectBox
          title="Soy"
          class="select-button"
          :image="require('@/assets/icons/onboarding-soy.svg')"
          :isSelected="soyIntolerance"
          @click="
            $store.dispatch('registration/setSoyIntolerance', !soyIntolerance)
          "
        ></MPSelectBox>
        <MPSelectBox
          title="Gluten"
          class="select-button"
          :image="require('@/assets/icons/onboarding-gluten.svg')"
          :isSelected="glutenIntolerance"
          data-spec="gluten"
          @click="
            $store.dispatch(
              'registration/setGlutenIntolerance',
              !glutenIntolerance
            )
          "
        ></MPSelectBox>
        <MPSelectBox
          title="Nuts"
          class="select-button"
          :image="require('@/assets/icons/onboarding-nuts.svg')"
          :isSelected="nutsIntolerance"
          @click="
            $store.dispatch('registration/setNutsIntolerance', !nutsIntolerance)
          "
        ></MPSelectBox>
      </div>
      <p class="intolerances-text mt-4 px-4 px-md-5">
        Meal plans only support the intolerances listed above, but it's possible
        to exclude other ingredients (e.g. mushrooms) when searching for
        recipes.
      </p>
    </div>
    <ButtonNext class="next-button" @click="next()" data-spec="next-button" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonNext from './ButtonNext'
import { MPSelectBox } from '@/components/common'
import { EVENTS } from '@/events/analytics'

export default {
  name: 'RegisterSelectIntolerancesForm',
  components: {
    ButtonNext,
    MPSelectBox,
  },
  computed: {
    ...mapGetters({
      hasIntolerances: 'registration/hasIntolerances',
      soyIntolerance: 'registration/soyIntolerance',
      nutsIntolerance: 'registration/nutsIntolerance',
      glutenIntolerance: 'registration/glutenIntolerance',
    }),
  },
  methods: {
    next() {
      this.$emit('onIntolerancesSelected')
    },
    setHasNoIntolerances() {
      this.$store.dispatch('registration/setHasIntolerances', false)
      this.$store.dispatch('registration/setSoyIntolerance', false)
      this.$store.dispatch('registration/setNutsIntolerance', false)
      this.$store.dispatch('registration/setGlutenIntolerance', false)
    },
    setHasIntolerances() {
      this.$store.dispatch('registration/setHasIntolerances', true)
    },
  },
  mounted() {
    this.$posthog.sendEvent(EVENTS.registration.step3)
  },
}
</script>

<style lang="scss" scoped>
.register-select-intolerances-form {
  text-align: center;
  max-width: 710px;
  margin: 0 auto;
}

.intolerances-form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.select-button {
  flex-direction: column;
  margin: 0;
  & + .select-button {
    margin: 12px 0 0;
  }

  ::v-deep .title {
    margin: 0;
    padding: 0;
    font-size: $font-size-small;
  }

  @include media-breakpoint-up(md) {
    max-height: 165px;
    max-width: 165px;
    padding: 2.5rem 1.6rem;
    ::v-deep .title {
      font-size: 1rem;
    }

    & + .select-button {
      margin: 0 0 0 24px;
    }
  }
}

.select-box--selected {
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 41, 64, 0.2);
  border: solid 2px $clr-water;
}

.next-button {
  margin-top: 51px;
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
}
</style>
