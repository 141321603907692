import { render, staticRenderFns } from "./AddCustomRecipeIngredients.vue?vue&type=template&id=3e3bc718&scoped=true"
import script from "./AddCustomRecipeIngredients.vue?vue&type=script&lang=js"
export * from "./AddCustomRecipeIngredients.vue?vue&type=script&lang=js"
import style0 from "./AddCustomRecipeIngredients.vue?vue&type=style&index=0&id=3e3bc718&prod&lang=scss&scoped=true"
import style1 from "./AddCustomRecipeIngredients.vue?vue&type=style&index=1&id=3e3bc718&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e3bc718",
  null
  
)

export default component.exports