<template>
  <div class="disqus-dropdown" :id="dropDownUniqueId">
    <img
      src="@/assets/icons/dashboard-hover-dots-inactive.svg"
      alt=""
      class="float-right px-2 py-1 dropdown-image"
      :id="dropDownImageUniqueId"
      @mouseleave="mouseleave"
    />
    <b-popover
      :target="dropDownImageUniqueId"
      triggers="click"
      offset="-60"
      placement="bottomleft"
      :show.sync="isDropDownVisible"
      :container="dropDownUniqueId"
    >
      <div @mouseover="mouseover" @mouseleave="mouseleave" @click="mouseleave">
        <slot />
      </div>
    </b-popover>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'DisqusDropDown',
  data() {
    return {
      isDropDownVisible: false,
    }
  },
  computed: {
    dropDownUniqueId() {
      return `disqus-dropdown-${this._uid}`
    },
    dropDownImageUniqueId() {
      return `disqus-image-dropdown-${this._uid}`
    },
  },
  methods: {
    mouseover() {
      this.setDropDownVisibility(true)
    },
    mouseleave() {
      this.setDropDownVisibility(false)
    },
    setDropDownVisibility: _.debounce(function (state) {
      this.isDropDownVisible = state
    }, 100),
  },
}
</script>

<style lang="scss">
.disqus-dropdown {
  .dropdown-image {
    cursor: pointer;
  }
  .popover {
    border: none;
  }
  .popover-body {
    box-shadow: 0 0 4px 0 #b2bbc6;
    border-radius: 3px;
    padding: 0;
  }
  ul {
    list-style-type: none;
  }
  li {
    padding: 16px 80px 0px 11px;
    &:last-child {
      padding-bottom: 16px;
    }
  }
}
</style>
