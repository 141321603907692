<template>
  <div id="add-custom-recipe-steps">
    <AddCustomRecipeHtmlEditor v-model="recipePrepDescription" />
    <aside class="disclaimer">
      We recommend that you
      <b>avoid entering specific quantities of ingredients, or liquids</b> when
      entering preparation steps as those can not be scaled with the recipe.
    </aside>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AddCustomRecipeHtmlEditor from './AddCustomRecipeHtmlEditor'
export default {
  name: 'AddCustomRecipeSteps',
  components: {
    AddCustomRecipeHtmlEditor,
  },
  props: {
    tabScope: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('AddCustomRecipe', ['formData']),
    recipePrepDescription: {
      get() {
        return this.formData && this.formData.recipePrepDescription
          ? this.formData.recipePrepDescription
          : ''
      },
      set(value) {
        this.$store.commit('AddCustomRecipe/UPDATE_FORM_DATA', {
          recipePrepDescription: value,
        })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
#add-custom-recipe-steps {
  padding: 0 30px 10px 30px;
}
.disclaimer {
  font-size: rem(14);
  color: $clr-navy-70;
  padding: 10px 0 0;
}
</style>
