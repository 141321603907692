<template>
  <div
    class="premium-content-notice-modal-bg"
    :class="`premium-content-notice-modal-bg--${type}`"
  >
    <div class="premium-content-notice-notice">
      <div class="premium-content-notice-notice-header">
        <img
          class="premium-content-notice-image"
          :src="require('@/assets/images/lock-banner-header.png')"
        />
      </div>
      <div
        class="premium-content-container"
        v-if="!isActiveUser && !userExpired"
      >
        <h3 class="premium-content-notice-notice-title">
          Members-only meal plan
        </h3>
        <div class="premium-content-notice-notice-text">
          Try premium membership to access this meal plan, plus grocery lists,
          weekend prep and hundreds more recipes!
        </div>
        <div class="guarantee">
          <span class="money-back">
            <Guarantee width="19" />
            30-day money-back guarantee
          </span>
          <!-- <span class="guarantee-separator">|</span>
          <span class="cancel-anytime">Cancel anytime</span> -->
        </div>
        <div class="premium-content-notice-notice-links">
          <p>
            <a
              href
              class="premium-content-notice-notice-link"
              @click.prevent="goToPremium({ tag: 'roadblock' })"
            >
              <button class="btn btn-primary membership-button">
                Try Premium Membership
              </button>
            </a>
          </p>
        </div>
      </div>
      <div class="premium-content-container" v-if="isActiveUser">
        <h3 class="premium-content-notice-notice-title">
          Premium Members Only
        </h3>
        <div class="premium-content-notice-notice-text">
          Restore premium membership to access weekly meal plans and delicious
          new recipes!
        </div>
        <div class="guarantee">
          <span class="money-back">
            <Guarantee width="19" />
            30-day money-back guarantee
          </span>
        </div>
        <div class="premium-content-notice-notice-links">
          <p>
            <a
              class="premium-content-notice-notice-link"
              @click="goToPremium({ tag: 'roadblock' })"
            >
              <button class="btn btn-primary membership-button">
                View Upgrade Options
              </button>
            </a>
          </p>
        </div>
      </div>
      <div
        class="premium-content-container"
        v-if="!isActiveUser && userExpired"
      >
        <h3 class="premium-content-notice-notice-title">
          Oh no! <br />
          Your account needs attention.
        </h3>
        <div class="premium-content-notice-notice-text">
          It appears your Meal Planner access has expired. Start or renew a
          subscription to enjoy personalized meal plans and more, with our
          money-back guarantee.
        </div>
        <div class="guarantee">
          <span class="money-back">
            <Guarantee width="19" />
            30-day money-back guarantee
          </span>
        </div>
        <div class="premium-content-notice-notice-links">
          <p>
            <a
              class="premium-content-notice-notice-link"
              @click="goToPremium({ tag: 'roadblock' })"
            >
              <button class="btn btn-primary membership-button">
                Try Premium Membership
              </button>
            </a>
          </p>
          <p class="return-link" v-if="extendedTrial">
            Join with confidence. Risk free, 30-day guarantee.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TrialHandler from '@/handlers/TrialHandler'
import LoadingMixin from '@/mixins/LoadingMixin'
import { Guarantee } from '@/components/common/MPIcons'

export default {
  components: {
    Guarantee,
  },
  name: 'PremiumContentLock',
  mixins: [LoadingMixin],
  props: {
    eventName: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'sidebar',
      validator: (value) => ['sidebar', 'full'].includes(value),
    },
  },
  computed: {
    ...mapGetters({
      isActiveUser: 'user/isActive',
      isMealPlannerLocked: 'globals/isMealPlannerLocked',
      isDashboardLocked: 'dashboard/isDashboardLocked',
      userDiscount: 'user/planDiscount',
      userExpired: 'headers/userExpired',
      trialExpiresIn: 'headers/expiresIn',
      extendedTrial: 'headers/extendedTrial',
      permissions: 'user/permissions',
      extendTrialToken: 'headers/extendTrialToken',
    }),
  },
  methods: {
    goToPremium(query) {
      const eventProperties = {}
      eventProperties.location = 'PremiumContentLock'
      this.$analytics.track('mp upgrade clicked', eventProperties)
      this.$router.push({ path: '/reactivate', query: query })
    },
    changeToSampleMealPlan() {
      this.$store.dispatch('dashboard/switchToSampleMealPlan', { root: true })
      this.$router.push('/plan')
    },
    extendTrial() {
      this.setLoading(true)
      TrialHandler.extendTrial(this.$store, this.$intercom).then(() => {
        this.setLoading(false)
        this.$router.push('/plan')
      })
    },
  },
  mounted() {
    if (this.eventName) {
      this.$analytics.track(this.eventName)
    }
    if (this.extendTrialToken) {
      const token = this.extendTrialToken
      this.$store.dispatch('headers/setExtendTrialToken', null)
      this.setLoading(true)
      TrialHandler.extendTrial(this.$store, this.$intercom, token)
        .then(() => {
          this.setLoading(false)
          window.location = '/extend-trial/success'
        })
        .catch(() => {
          const message = {
            text: 'There has been an error with extending your trial. Please contact support for assistance.',
            type: 'error',
          }
          this.$store.dispatch('headers/setExtendTrialMessage', message)
          this.setLoading(false)
          this.$router.push('/plan')
        })
    }
  },
}
</script>

<style lang="scss" scoped>
// Variables
$sidebar-width: 310px; //used to positon the become membor modal off from the sidebar
$sidebar-width-tablet: 100px; //used to positon the become membor modal off from the sidebar
// =======

.premium-content-notice-modal-bg {
  display: flex;
  justify-content: center;
  margin-bottom: 44px; //height of mobile menu
  width: 100%;
  z-index: 14;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  align-items: flex-start;
  height: 100%;
}

.premium-content-notice-notice {
  text-align: center;
  background-color: $clr-navy-5;
  width: 310px;
  position: relative;
  z-index: 15;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.premium-content-notice-image {
  height: 196px;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    height: 155px;
  }
}

.premium-content-container {
  background-color: $clr-navy-5;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.premium-content-notice-notice-title {
  display: block;
  padding: 5px;
  margin-top: 5px;
  font-size: rem(38);
  line-height: rem(42);

  @include media-breakpoint-down(sm) {
    font-size: rem(24);
    line-height: rem(28);
  }
}

.premium-content-notice-notice-text {
  padding: 0 30px;
  margin-bottom: 17px;
  font-size: $font-size-small;
  line-height: 1.33;
  max-width: 500px;

  @include media-breakpoint-down(sm) {
    font-size: rem(18);
    line-height: rem(24);
  }
}

.premium-content-notice-notice-links {
  margin: 15px 0;
  @include media-breakpoint-down(sm) {
    margin: 0;
    p {
      margin-bottom: 25px;
    }
  }
}

.membership-button {
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

.guarantee {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 1.44;
  font-weight: bold;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  @include media-breakpoint-down(sm) {
    font-size: rem(18);
    margin-bottom: 25px;
  }
}

.money-back {
  text-align: center;
}

.guarantee-separator {
  content: '|';
  margin-left: 10px;
  margin-right: 10px;
  display: none;

  @include media-breakpoint-up(md) {
    display: inline;
  }
}

.cancel-anytime {
  display: block;
  margin-top: 12px;

  @include media-breakpoint-up(md) {
    display: inline;
    margin-top: 0;
  }
}

@media only screen and (min-height: 660px) {
  .premium-content-notice-notice-text,
  .premium-content-notice-notice-links {
    display: block;
  }
}

// Media query
@include media-breakpoint-up(md) {
  .premium-content-notice-notice {
    text-align: center;
    background-color: $clr-navy-5;
    width: 697px;
    padding: 30px 0;
  }

  .premium-content-notice-modal-bg {
    width: auto;
    background: rgba(0, 0, 0, 0.7);
    align-items: center;
    padding: 0;
    left: 0;
    &--sidebar {
      @include media-breakpoint-up(lg) {
        left: $sidebar-width;
      }
    }
  }

  .premium-content-notice-notice-text {
    font-size: $h4-font-size;
  }

  .premium-content-notice-notice-title {
    padding: 0;
    margin-bottom: 20px;
  }

  .premium-content-notice-notice-links {
    > p {
      padding: 0;
      margin: 10px 0 0 0;
    }
  }

  .premium-content-notice-modal {
    max-width: 600px;
    bottom: initial;
    padding: 60px 30px;
    height: auto;
    min-height: auto;
    position: relative;

    &:before {
      opacity: 0.2;
    }
  }

  .premium-content-notice-notice-icon {
    width: 55px;
  }
}
</style>
