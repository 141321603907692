import axios from 'axios'
import environment from '@/environment'
import responseInterceptors from './ResponseInterceptors'

let CancelToken = axios.CancelToken

const api = axios.create({
  baseURL: environment.baseUrl,
})

function setupResponseInterceptors() {
  api.interceptors.response.use(
    (response) => {
      Object.keys(responseInterceptors).forEach((key) => {
        const fn = responseInterceptors[key]
        if (response.headers[key]) {
          fn(response.headers[key].trim())
        }
      })
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}

function setupRequestInterceptors() {
  api.interceptors.request.use((config) => {
    config.headers['X-Platform'] = 'web'
    return config
  })
}

function addErrorInterceptorCallback(callback) {
  api.interceptors.response.use(null, (error) => {
    if (error.status) {
      const status = error.response.status
      const method = error.response.config.method
      if (status >= 500 || status === 409 || method === 'get') callback(error)
    } else if (!error.__CANCEL__) {
      // network error + don't show error for canceled requests
      callback(error)
    }

    return Promise.reject(error)
  })
}

export default {
  /**
   * The cancellation of the promise will throw the error that needs to be catch.
   */
  get(url, params = {}, cancelExecutor, responseType, disableNoCache) {
    if (!disableNoCache) {
      params.noCache = new Date().getTime()
    }
    let config = {
      params: params,
      responseType: responseType ? responseType : 'json',
    }

    if (cancelExecutor) config.cancelToken = new CancelToken(cancelExecutor)

    return api.get(url, config)
  },
  post(url, config = {}, cancelExecutor) {
    if (cancelExecutor) config.cancelToken = new CancelToken(cancelExecutor)
    return api.post(url, config)
  },
  delete(url, config = {}, cancelExecutor) {
    if (cancelExecutor) config.cancelToken = new CancelToken(cancelExecutor)
    return api.delete(url, config)
  },
  save(url, config = {}, cancelExecutor) {
    if (cancelExecutor) config.cancelToken = new CancelToken(cancelExecutor)
    return api.create(url, config)
  },
  setAccessToken(accessToken, type = 'Token') {
    api.defaults.headers.common['Authorization'] = accessToken
      ? `${type} ${accessToken}`
      : undefined
  },
  addErrorCallback: addErrorInterceptorCallback,
  setupResponseInterceptors,
  setupRequestInterceptors,
}
