<template>
  <div
    id="the-recipe-box"
    :class="{
      'the-recipe-box--add-recipe': isAddRecipeBox,
    }"
  >
    <router-view></router-view>
    <FiltersSideBar
      :is-open="showFiltering"
      @close="onFiltersClose"
      @clearfilters="clearSearch"
    />
    <!-- mobile header -->
    <MPMobileHeader
      title="Recipe Box"
      :icon-image="require('@/assets/icons/recipe-box-header-icon.svg')"
      :isActiveUser="isActiveUser"
      @upgrade="goToPremium({ tag: 'navbar' })"
    />
    <header class="recipe-box-header">
      <div class="add-recipe-header" v-if="isAddRecipeBox">
        <span class="add-recipe-header-title"> Select a recipe to add </span>
        <span
          @click="closeAddRecipeBoxModal"
          class="add-recipe-header-close"
        ></span>
      </div>
      <div class="search-header-mobile">
        <MobileSearchWrapper
          @clear="clearSearch"
          :open="showTour"
          :inputText="q"
          class="tour-step-1-mobile"
        >
          <MPInput
            id="mobile-search"
            type="search"
            ref="mobileSearchInput"
            placeholder="Search by recipe title"
            v-model="q"
            @input="onSearchTextChange"
            @keyup.enter.prevent="onMobileEnter"
            autocomplete="off"
          />
        </MobileSearchWrapper>
        <div class="d-flex">
          <FilterButton
            class="more-filters-button px-2"
            :icon="require('@/assets/icons/filtering/filter-navy.svg')"
            :icon-active="require('@/assets/icons/filtering/filter-white.svg')"
            color="white-outline"
            @click="showFiltering = true"
            slot="text"
          >
            <span slot="text" class="more-filters">
              Filter By Ingredients & More
            </span>
          </FilterButton>
          <FilterButton
            class="fav-button d-md-flex px-3 ml-1"
            :icon="favFilter.iconSrc"
            :icon-active="favFilter.activeIconSrc"
            color="white-outline"
            :is-active="filtersApplied[FILTERS_TYPE.ONLY_FAVORITES]"
            @click="onFilterByFavorite"
          />
        </div>
      </div>
      <div class="search-header">
        <div
          class="container search-header-container d-flex justify-content-between"
        >
          <div class="search-input-group d-none d-md-block">
            <i class="search-input-container-icon"></i>
            <MPInput
              class="search-input tour-step-1"
              placeholder="Search by recipe title"
              ref="desktopSearchInput"
              type="text"
              v-model="q"
              @input="
                (newSearchText) => {
                  onSearchTextChange(newSearchText)
                  onDesktopSearchChange(newSearchText)
                }
              "
              @focus="setIsSearchInputFocus(true)"
              @blur="setIsSearchInputFocus(false)"
            />
            <span
              v-if="q.length > 0"
              class="search-input-container search-input-container-clear"
              @click="clearSearch"
            >
            </span>
            <div
              v-if="q.length === 0"
              class="search-input-container search-input-container-info"
              @click="showTour"
              tabindex="0"
              style="outline: none"
              @focus="setIsSearchInputFocus(true)"
              @blur="setIsSearchInputFocus(false)"
            >
              &nbsp;
            </div>
          </div>
          <!-- <div class="d-flex"> -->
          <FilterButton
            class="fav-button d-md-flex d-none px-3 mx-3"
            :icon="favFilter.iconSrc"
            :icon-active="favFilter.activeIconSrc"
            color="white-outline"
            :is-active="filtersApplied[FILTERS_TYPE.ONLY_FAVORITES]"
            @click="onFilterByFavorite"
          />
          <FilterButton
            class="more-filters-button px-2"
            :icon="require('@/assets/icons/filtering/filter-navy.svg')"
            :icon-active="require('@/assets/icons/filtering/filter-white.svg')"
            color="white-outline"
            @click="showFiltering = true"
            slot="text"
          >
            <span slot="text" class="more-filters">
              Filter By Ingredients & More
            </span>
          </FilterButton>
          <!-- </div> -->
        </div>
      </div>
      <div class="container actions-container">
        <div class="row">
          <!-- Preferences Labels -->
          <div class="col-12 text-center">
            <p class="d-inline-block text-right preferences-text">
              {{ intolerancesText }}
            </p>
            <router-link
              to="/settings/preferences"
              title="Preferences"
              id="menu-nav-user-account"
              class="router-link col-5 col-md-3 preferences-text"
            >
              <span>Update your preferences</span>
            </router-link>
          </div>

          <div class="col-12 text-center">
            <div class="clear-all" v-if="totalFiltersApplied > 0">
              <span class="link" @click="clearFiltering">
                Clear All Filters
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div
      class="container content-container"
      id="recipesWrapper"
      v-infinite-scroll="loadMore"
      infinite-scroll-distance="900"
    >
      <MPWhiskLoadingAnimation v-if="isLoading" />
      <span
        v-if="isAddRecipeBox"
        class="add-recipe-header-close"
        @click="closeAddRecipeBoxModal()"
      ></span>

      <!-- This is for search results plus filters -->
      <div class="col-12 text-center">
        <div>
          <p class="recipe-list-title">{{ primaryLabel }}</p>
        </div>
      </div>
      <div
        v-if="primaryRecipes.length > 0"
        class="row px-md-3 list"
        :class="{ 'list--search-opened': showMobileSearch }"
      >
        <div
          class="column col-12"
          :class="{
            'col-sm-6 col-md-4 col-xl-4 recipe-card-column':
              item.componentType === 'recipe-card',
            'title-column': item.componentType === 'recipe-title-card',
            'search-results-column':
              item.componentType === 'search-results-recipe-box-item',
          }"
          v-for="item in primaryRecipes"
          :key="item.componentId"
          :id="`recipe-${item.id}`"
        >
          <Component
            @onCardClick="onCardClick"
            @clearFiltering="clearFiltering"
            @addRecipe="addRecipe"
            @upgrade="upgrade"
            :is="item.componentType"
            :data="item"
            v-if="!item.showClearFilter"
          />
        </div>
      </div>

      <!-- This is the regular result page -->
      <div
        v-if="allRecipeBoxItems.length > 0"
        class="row px-md-3 list"
        :class="{ 'list--search-opened': showMobileSearch }"
      >
        <div class="col-12 text-center" v-if="generalLabel">
          <p class="recipe-list-title">{{ generalLabel }}</p>
        </div>
        <!--
          We are using `:is` for optimization purpose (look for Higher-Order Components)
          v-for is excepting components:
          - RecipeCard
          - RecipeTitleCard
          - ResultsDescriptionItem
          - NoFavoritesBoxItem
          - NoPersonalRecipesItem
          - TextRecipeBoxItem
          - RecipeBoxLoader
        -->
        <div
          class="column col-12"
          :class="{
            'col-sm-6 col-md-4 col-xl-4 recipe-card-column':
              item.componentType === 'recipe-card',
            'title-column': item.componentType === 'recipe-title-card',
            'search-results-column':
              item.componentType === 'search-results-recipe-box-item',
          }"
          v-for="item in allRecipeBoxItems"
          :key="item.componentId"
          :id="`recipe-${item.id}`"
        >
          <Component
            @onCardClick="onCardClick"
            @clearFiltering="clearFiltering"
            @addRecipe="addRecipe"
            @upgrade="upgrade"
            :is="item.componentType"
            :data="item"
            v-if="!item.showClearFilter"
          />
        </div>
        <ScrollToTopButton />
      </div>
      <div
        v-if="recipesFound === 0 && totalPrimaryRecipesFound === 0"
        class="p-5 text-center"
      >
        <h4>Recipes Not Found</h4>
      </div>
    </div>
    <MPTour
      name="RecipeBoxFiltersTour"
      :steps="tourSteps"
      :callbacks="tourCallbacks"
      :options="getTourOptions()"
    ></MPTour>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import RecipeCard from './RecipeCard.vue'
import RecipeTitleCard from './RecipeTitleCard.vue'
import menuItemsData from './menuitems/menuItemsData.js'
import RecipeToggleDisabled from './RecipeToggleDisabled.vue'
import RecipeFilterToggle from './RecipeFilterToggle.vue'
import ResultsDescriptionItem from './ResultsDescriptionItem.vue'
import NoFavoritesBoxItem from './NoFavoritesBoxItem.vue'
import NoPersonalRecipesItem from './NoPersonalRecipesItem.vue'
import TextRecipeBoxItem from './TextRecipeBoxItem.vue'
import MobileSearchWrapper from './MobileSearchWrapper.vue'
import SearchButton from './SearchButton.vue'
import RecipeBoxLoader from './RecipeBoxLoader.vue'
import ScrollToTopButton from './ScrollToTopButton.vue'
import FilterButton from './FilterButton.vue'
import Instruction from './popovers/Instruction.vue'
import FiltersSideBar, { types } from './sideBar'
import { MPWhiskLoadingAnimation } from '@/components/common'
import { events } from '@/utils/mixpanel.events'
import DetectDeviceMixin from '@/mixins/DetectDeviceMixin'
import MPTour from '../tour/MPTour.vue'

const { FILTERS_TYPE } = types

export default {
  mixins: [DetectDeviceMixin],
  components: {
    RecipeCard,
    RecipeTitleCard,
    RecipeToggleDisabled,
    RecipeFilterToggle,
    ResultsDescriptionItem,
    NoFavoritesBoxItem,
    NoPersonalRecipesItem,
    TextRecipeBoxItem,
    MobileSearchWrapper,
    SearchButton,
    RecipeBoxLoader,
    ScrollToTopButton,
    FiltersSideBar,
    FilterButton,
    Instruction,
    MPWhiskLoadingAnimation,
    MPTour,
  },
  computed: {
    ...mapGetters({
      allRecipeBoxItems: 'RecipeBox/recipeBoxs',
      primaryRecipes: 'RecipeBox/primaryRecipes',
      isLoading: 'RecipeBox/isLoading',
      isActiveUser: 'user/isActive',
      intolerances: 'user/intolerances',
      searchTextState: 'RecipeBox/searchText',
      recipeQueryStatus: 'RecipeBox/recipeQueryStatus',
      hasMorePages: 'RecipeBox/recipeHasMorePages',
      currentPage: 'RecipeBox/recipePage',
      filtersApplied: 'RecipeBox/filtersApplied',
      recipesFound: 'RecipeBox/recipesFound',
      totalFiltersApplied: 'RecipeBox/totalFiltersApplied',
      primaryCount: 'RecipeBox/primaryCount',
      generalCount: 'RecipeBox/generalCount',
      primaryLabel: 'RecipeBox/primaryLabel',
      generalLabel: 'RecipeBox/generalLabel',
    }),
    totalPrimaryRecipesFound() {
      return this.primaryRecipes.length
    },
    showRecipeBoxHeader() {
      return !this.showMobileSearch
    },
    intolerancesText() {
      let message = ''
      const intolerancesList = this.intolerances
        .filter((i) => i.isSelected && i.key != 'none')
        .map((i) => i.key.replace('Intolerance', ''))
      if (intolerancesList.length === 0) {
        message = 'All recipes shown are based on your preferences'
      } else if (intolerancesList.length === 3) {
        const [first, ...list] = intolerancesList
        message = `All recipes shown are ${first}, ${list.join(' and ')} free`
      } else {
        message = `All recipes shown are ${intolerancesList.join(' and ')} free`
      }
      return message
    },
    /**
     * Label text shown in depends of results and filters/serach applied
     */
    groupLabelsIntro() {
      const baseLabel = `${this.totalPrimaryRecipesFound} results found for `
      let secondaryBaseLabel = `${this.recipesFound} results found for `
      let label = {
        contentForPrimary: '',
        contentForSecondary: '',
        showClearFilter: false,
      }

      if (this.page === 1 && this.totalPrimaryRecipesFound > 0) {
        secondaryBaseLabel = 'Another ' + secondaryBaseLabel
      }

      if (this.searchTextState && this.totalFiltersApplied > 0) {
        label.contentForPrimary =
          baseLabel + `"${this.searchTextState}" and selected filters`
        label.contentForSecondary =
          secondaryBaseLabel +
          `${this.splitSearchTextInWords()} with filtering turned out`
        label.showClearFilter = true
      }

      if (this.searchTextState && this.totalFiltersApplied === 0) {
        label.contentForPrimary = baseLabel + `"${this.searchTextState}"`
        label.contentForSecondary =
          secondaryBaseLabel + `${this.splitSearchTextInWords()}`
      }

      if (!this.searchTextState && this.totalFiltersApplied > 0) {
        label.contentForPrimary = baseLabel + 'selected filters'
        label.contentForSecondary =
          secondaryBaseLabel + 'any of filters applied'
        label.showClearFilter = true
      }

      return label
    },
  },
  props: {
    isAddRecipeBox: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSearchButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const tourSteps = () => {
      const step1View = `
        <div class="font-weight-bold text-center h4">Search tips</div>
        <ul class="p-2 step-1-content">
          <li>
            <p>
              Enter any keyword(s) to search by recipe title. For instance, if you're
              searching for all salads, enter <b>salad</b>.
            </p>
          </li>
          <li>
            <p>
              If you're seeking a specific recipe, enter multiple keywords to narrow
              the results (i.e. <b>stuffed acorn squash</b>).
            </p>
          </li>
          <li>
            <p>
              The top primary section will match <b>all</b> keywords entered. The
              bottom secondary section will show recipes with <b>any/some</b> of the
              individual keywords.
            </p>
          </li>
        </ul>
      `

      const step2view = `
        <div class="font-weight-bold text-center h4">Ingredients filter tips</div>
          <ul class="p-2 step-2-content">
            <li>
              <p>
                Enter any ingredient keyword in the dropdown menu to filter recipes by
                ingredient. Click the option you wish to use.
              </p>
            </li>
            <li>
              <p>
                If you wish bring up recipes <b>with</b> the ingredient, highlight the <b>green
                plus (+)</b> icon to sort by recipes that <b>include</b> the ingredient.
              </p>
            </li>
            <li>
              <p>
                If you wish to bring up recipes <b>without</b> the ingredient, highlight the
                <b>red minus (-)</b> icon to sort by recipes that <b>exclude</b> the ingredient.
              </p>
            </li>
            <li>
              <p>
                You may have better success with general ingredients in some cases and
                specific ingredients in others (i.e. it is recommended to search “russet
                potato” or “Yukon Gold potato” instead of “potato,” though a search of
                “tomato” will work well).
              </p>
            </li>
          </ul>
      `

      const desktopStep1 = {
        target: '.tour-step-1',
        content: step1View,
        params: {
          enableScrolling: false,
          placement: 'bottom',
          boundariesElement: 'viewport',
          modifiers: {
            preventOverflow: {
              // without this setting, popper is constrained within the navbar
              escapeWithReference: true,
            },
          },
        },
        before: (type) =>
          new Promise((resolve, reject) => {
            try {
              if (type === 'previous') {
                this.showFiltering = false
              }
              this.$refs.desktopSearchInput.$el.focus()
              resolve()
            } catch (e) {
              reject(e)
            }
          }),
      }
      const desktopStep2 = {
        target: '.tour-step-2',
        content: step2view,
        params: {
          enableScrolling: false,
          // boundariesElement: 'viewport',
          placement: 'bottom',
          modifiers: {
            preventOverflow: {
              // without this setting, popper is constrained within the navbar
              escapeWithReference: true,
            },
          },
        },
        before: () =>
          new Promise((resolve, reject) => {
            try {
              if (this.showFiltering) {
                return resolve()
              }
              this.showFiltering = true
              setTimeout(() => {
                resolve()
              }, 600)
            } catch (err) {
              reject(err)
            }
          }),
      }

      if (this.isMobileResolution()) {
        desktopStep1.target = '.tour-step-1-mobile'
        delete desktopStep2.params
      }

      return [desktopStep1, desktopStep2]
    }

    return {
      q: '',
      showFiltering: false,
      showMobileSearch: false,
      sortByItems: menuItemsData.getSortByItems(),
      favFilter: menuItemsData
        .getFilterByTypeItems()
        .find((f) => f.propertyName === 'favorite'),
      personalFilter: menuItemsData
        .getFilterByTypeItems()
        .find((f) => f.propertyName === 'is_custom'),
      recipeBoxItemsShowNum: 20,
      loadMoreNum: 30,
      applyFilteringButtonEnabled: false,
      isSearchInputFocus: false,
      recipesLoaded: false,
      tourSteps: tourSteps(),
      tourCallbacks: {
        onStop: () => {
          this.$store.dispatch('user/finishTour', 'recipe_box_tour_done', {
            root: true,
          })
        },
        onStart: () => {},
        onSkip: () => {},
        onFinish: () => {
          if (this.showFiltering) {
            this.showFiltering = false
          }
        },
      },
      FILTERS_TYPE,
    }
  },

  methods: {
    splitSearchTextInWords() {
      let words = this.searchTextState.split(' ')
      words = words.map((w) => `"${w}"`)
      // Object.values(this.searchTextState.split(' ')).forEach(w => words )

      return words.join(' or ')
    },
    showTour: _.debounce(function () {
      this.$tours['RecipeBoxFiltersTour'].start()
    }, 50),
    onCardClick(recipeUrl) {
      this.showFiltering = false
      this.$parent.onRecipeSelect(recipeUrl)
    },
    closeAddRecipeBoxModal() {
      this.$parent.closeAddRecipeBoxModal()
    },
    onSearchTextChange: function () {
      this.getRecipes(true)
    },
    clearFiltering() {
      this.$store.dispatch('analytics/track', {
        eventName: events.filters.clear,
      })
      this.$store.commit('RecipeBox/SET_SELECTED_FILTERS_TO_DEFAULT')
      this.clearSearch()
    },
    setShowMobileSearch(isShown) {
      this.showMobileSearch = isShown
    },
    onDesktopSearchChange(text = '') {
      this.setShowMobileSearch(text.length)
    },
    clearSearch() {
      this.q = ''
      this.getRecipes(true)
    },
    toggleOpenMobileSearch(isOpen) {
      isOpen = typeof isOpen === 'boolean' ? isOpen : !this.showMobileSearch
      if (isOpen) {
        this.setShowMobileSearch(true)
        this.$nextTick(() => {
          this.$refs.mobileSearchInput.focus()
        })

        // Start event for opening keyboard on iOS.
        let e = new Event('touchstart')
        document.getElementById('mobile-search').dispatchEvent(e)
      } else {
        this.clearSearch()
        this.setShowMobileSearch(false)
      }
    },
    addRecipe(recipeId) {
      this.$emit('add-recipe', recipeId)
    },
    upgrade() {
      this.$emit('upgrade')
    },

    /**
     * Infinite Scroll Handler
     *
     * @see https://github.com/ElemeFE/vue-infinite-scroll
     * @return {void}
     */
    loadMore() {
      if (['loading', 'loadingPages'].includes(this.recipeQueryStatus)) {
        return
      }

      if (this.hasMorePages) {
        this.getRecipes()
      }
    },
    resetRecipeBoxSetting() {
      this.$store.commit('RecipeBox/SET_IS_ADD_RECIPE_BOX', this.isAddRecipeBox)
      this.$store.commit('RecipeBox/SET_SHOW_PLAN_DISABLED', false)
      this.$store.dispatch('RecipeBox/refresh')

      // this.searchText = ''
      // this.$store.dispatch('RecipeBox/searchRecipes', '')

      this.recipeBoxItemsShowNum = 20
    },
    onMobileEnter() {
      this.$refs.mobileSearchInput.blur()
    },
    onFilterByFavorite() {
      const filtersApplied = this.filtersApplied
      this.$analytics.track('favorites')
      filtersApplied[FILTERS_TYPE.ONLY_FAVORITES] =
        !filtersApplied[FILTERS_TYPE.ONLY_FAVORITES]

      this.$store.commit('RecipeBox/REFRESH_RECIPES_CACHE')
      this.$store.commit(
        'RecipeBox/UPDATE_RECIPE_FILTERS_SELECTED',
        filtersApplied
      )

      this.getRecipes(true)
    },
    // Add timeout for Clear icon click
    setIsSearchInputFocus: _.debounce(function (isFocus) {
      this.isSearchInputFocus = isFocus
    }, 200),
    goToPremium(query) {
      if (this.isActiveUser) return
      const eventProperties = {}
      eventProperties.location = 'SideNavbar'
      this.$analytics.track('mp upgrade clicked', eventProperties)
      this.$router.push({ path: '/reactivate', query: query })
    },

    /**
     * Get all recipes from api applying filters
     *
     * @param {string} query
     * @param {boolean} reset
     * @return {void}
     */
    getRecipes(reset = false) {
      if (reset) {
        this.$store.dispatch('RecipeBox/setRecipeQueryPage', 1)
      }

      this.$store.dispatch('RecipeBox/getRecipes', {
        page: this.currentPage,
        searchText: this.q,
        filters: this.filtersApplied,
      })
    },
    getTourOptions() {
      if (this.tourSteps.length > 1) {
        return { buttonStop: 'Got it!' }
      }

      return {
        labels: { buttonStop: 'Got it!' },
      }
    },
    onFiltersClose() {
      this.showFiltering = false
      if (this.$tours['RecipeBoxFiltersTour'].currentStep === 1) {
        this.$tours['RecipeBoxFiltersTour'].finish()
      }
    },
  },

  mounted() {
    this.resetRecipeBoxSetting()
    // Focus mobile search input on iOS and display keyboard.
    let mobileSearchEl = document.getElementById('mobile-search')
    mobileSearchEl.addEventListener('touchstart', function () {
      this.focus()
    })

    this.q = this.searchTextState
  },

  unmounted() {
    this.$tours['RecipeBoxFiltersTour'].finish()
  },

  watch: {
    currentPage() {
      if (this.currentPage === 1) {
        window.scroll({ top: 0, left: 0 })
      }
    },
    '$store.state.RecipeBox.searchText'() {
      if (this.searchTextState == null) {
        this.q = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.mobile-header {
  background-color: $white;
  padding: 2rem 1rem 0;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  ::v-deep img {
    display: none;
  }

  ::v-deep .title {
    font-weight: 700;
    font-size: 2rem;
    color: $body-color;
    text-transform: uppercase;
    margin-top: 0;
    line-height: 1;
  }

  ::v-deep .btn-primary {
    font-size: rem(14);
    width: 85px;
    height: 25px;
    padding: 0;
    margin-left: 1rem;
    margin-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    ::v-deep .title {
      font-size: rem(38);
      line-height: rem(42);
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.title {
  padding-top: 1.4rem;
  padding-left: 0rem;
  font-size: 1.4rem;
  text-align: right;
}

.title-mobile {
  padding-top: 1.4rem;
  padding-left: 0px;
  font-size: 1.4rem;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.router-link {
  color: $clr-navy;
  text-transform: uppercase;
  text-decoration: underline;
  text-align: center;
  padding-top: 25px;
}

.content-container {
  border: none;
  min-height: 100vh;
  padding-bottom: 44px;
  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 0;
    padding-left: 7px;
  }
}

.column {
  border-top: solid 1px #e5e6e7;

  @include media-breakpoint-up(md) {
    border: none;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.add-recipe-header {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.add-recipe-header-title {
  font-weight: 500;
  font-size: 20px;
}

.add-recipe-header-close {
  position: fixed;
  right: 2rem;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $clr-navy;
  background-image: url('~@/assets/icons/close-add-recipe.svg');
  background-position: center center;
  z-index: 3;
  top: 0.75rem;
  cursor: pointer;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.mobile-search-container {
  position: absolute; // Hide input with position absolute for successfully displaying iOS keyboard on focus.
  top: -1000px;
  z-index: 13;
  background: white;
}

.mobile-search-container--show {
  position: static;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

// Header
.recipe-box-header {
  position: sticky;
  top: 0;
  z-index: 1;

  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $white;

  @include media-breakpoint-up(md) {
    padding-top: 10px;
    padding-bottom: 2px;
  }
}

.filters-container {
  box-shadow: 0 5px 10px #00000014;
  border-bottom: 1px solid #d9dfe3;
  padding-bottom: 20px;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.toggle-btn {
  width: 100%;
  background-color: $white;
  color: $clr-navy;
  border: 1px solid $clr-navy-50;

  @include media-breakpoint-up(md) {
    width: 254px;
  }
}

// Search
.search-header {
  background-color: #ffffff;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.search-header-mobile {
  display: none;

  @include media-breakpoint-down(sm) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 10px;
  }
}

.search-header-container {
  display: flex;
  @include media-breakpoint-up(lg) {
    margin-left: 0;
  }
}

.search-input-group {
  position: relative;
  max-width: 500px;
  flex-grow: 1;
}

$searchInputLeftSpace: 45px;
$searchInputRightSpace: 17px + (10px) * 2;

.search-input {
  padding-left: $searchInputLeftSpace;
  padding-right: $searchInputRightSpace;
  font-size: rem(18);
  font-weight: 400;
  height: 46px;

  border: 0;
  border-bottom: 1px solid $clr-navy-15;
  font-size: 1rem;
  color: $text-muted;
  border-radius: 0;

  /* clears the 'X' from Internet Explorer */
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.search-input-container-icon {
  position: absolute;
  left: 0;
  background-image: url('~@/assets/icons/search.svg');
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  width: $searchInputLeftSpace;
  bottom: 0;
  top: 0;
}

.search-input-container {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(0, -50%);
  background-position: center center;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  background-color: #f2f2f2;
  cursor: pointer;
  &-clear {
    background-image: url('~@/assets/icons/clear-search.svg');
    background-size: 25px;
  }
  &-info {
    background-image: url('~@/assets/icons/info.svg');
    background-repeat: no-repeat;
  }
}
.more-filters-button {
  display: flex;
  white-space: nowrap;
  font-size: rem(14);
  line-height: 1;
  align-items: center;
  flex-grow: 1;
  color: $body-color;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  &:hover {
    background-color: $clr-navy-5;
    color: $clr-navy;
  }
}

.more-filters-icon {
  display: inline-block;
  background-image: url('~@/assets/icons/combined-shape.svg');
  background-repeat: no-repeat;
  width: 11px;
  height: 11px;
  margin-right: 5px;
}

// Actions
.actions-container {
  display: flex;
  padding: 20px 30px 10px;

  .favorite {
    max-height: rem(40);

    .icon-image {
      width: rem(25);
    }
  }

  .actions-button {
    margin-left: 4px;
    max-height: rem(40);
    flex: 3;
  }

  .clear-all {
    padding-top: 10px;
    font-size: 1.2rem;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: auto;
    flex-direction: column;
    padding: 0 20px;

    .row,
    .d-flex {
      flex-wrap: wrap;
      width: 100%;
      margin: 0;
    }

    .actions-button {
      flex: 3;
      margin-left: 0;
    }

    .favorite {
      flex: 1;
      margin-right: 10px;
    }

    .clear-all {
      .link {
        left: 0;
        width: 100%;
      }
    }
  }

  @include media-breakpoint-up(md) {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 0;
  }
}

.fav-button {
  max-width: 60px;
  flex-shrink: 0;

  &:hover {
    background-color: $clr-navy-5;
    color: $clr-navy;
  }
  ::v-deep .icon-image {
    background-size: 20px auto;
  }
}

.title-column {
  border-top: none;
  @include media-breakpoint-down(sm) {
    background-color: #f8f8fa;
    z-index: 1;
    position: sticky;
    position: -webkit-sticky;
    top: 46px;
  }
}

.list--search-opened {
  .title-column {
    @include media-breakpoint-down(sm) {
      top: 46px + 47px;
    }
  }
}

.recipe-card-column {
  @include media-breakpoint-down(sm) {
    background-color: white;
  }
}

.search-results-column {
  border-top: none;
}

.list {
  z-index: -1;
  @include media-breakpoint-up(md) {
    padding-bottom: 4rem; // Add extra space for "Show more" button
  }
}

// filters button
.more-filters {
  min-height: 60px;
  text-transform: uppercase;
}

.more-filters-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: $green;
  color: white;
  border-radius: 50%;
  top: -15px;
  right: -20px;
  width: 20px;
  height: 20px;
}

.the-recipe-box--add-recipe {
  .recipe-box-header {
    width: 100%;
    .search-header-container {
      padding-top: 0;
    }
  }
}

.preferences-text {
  &:nth-child(1) {
    padding-right: 0;
    line-height: 25px;
    font-size: 1.1rem;
  }

  &:nth-child(2) {
    color: $clr-water;
    font-size: 0.9rem;
    padding-right: 0;
  }
}
</style>

<style lang="scss">
#the-recipe-box {
  background-color: $white;
  margin-right: 400px;

  @include media-breakpoint-down(lg) {
    margin-right: 0px;
  }

  .btn-recipe-box.btn {
    background-color: rgba(white, 0.1);
    color: white;
    padding: 0.4375rem 0.875rem;
    text-align: center;
    margin: 0;
    text-align: left;
    box-shadow: none;
    height: 40px;
    font-size: 0.875rem;
    width: 100%;
    &:after {
      content: none;
    }
    @include media-breakpoint-up(md) {
      border: solid 1px #e5e5e5;
      color: #747e8b;
      background-color: white;
    }
    &[aria-expanded='true'] {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  .search-input-container-info {
    z-index: 1;
    .popover {
      min-width: 400px;
      width: 400px;
      max-width: 400px;
      margin-top: 20px;
    }
  }

  .recipe-list-title {
    font-size: 1.2rem;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }

  .step-2-content {
    font-size: 12px;
    line-height: 1.2;

    @include media-breakpoint-up(sm) {
      font-size: 14px;
    }
  }

  .step-2-content p {
    margin-bottom: 10px;
  }

  .step-1-content {
    font-size: 14px;
    line-height: 1.2;

    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }
  }

  .step-1-content p {
    margin-bottom: 10px;
  }
}
</style>
