<template>
  <div class="recipe-ingredient position-relative">
    <div class="row no-gutters">
      <div class="col">
        <span class="recipe-ingredient-title" v-html="title" />
        <SpeedPrepIconComponent
          v-if="isSpeedPrep"
          tooltip-button-text="Got it"
          class="speed-prep"
        >
          <template slot="content">
            This ingredient can be prepared in advance for the week ahead to
            save save time. You can find the full list of items to prepare in
            Weekend Prep.
          </template>
        </SpeedPrepIconComponent>
        <span>&nbsp;</span>
        <span
          class="recipe-ingredient-description text-muted small"
          v-html="description"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import SpeedPrepIconComponent from '@/components/weekendprep/SpeedPrepIconComponent'
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    isSpeedPrep: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    SpeedPrepIconComponent,
  },
}
</script>

<style lang="scss" scoped>
.recipe-ingredient {
  margin-bottom: 0.8rem;
  &:last-child {
    margin-bottom: 1.25rem;
  }
}

.recipe-ingredient-description {
  display: inline-block;
}
.speed-prep {
  display: inline-block;
}
</style>
<style lang="scss">
.recipe-ingredient {
  .speed-prep-icon {
    margin-top: 8px;
    margin-left: 2px;
    @include media-breakpoint-up(md) {
      margin-top: 5px;
    }
  }
}
</style>
