<template>
  <ul class="m-0 the-rating" :class="{ 'the-rating--locked': isLocked }">
    <li
      class="star"
      v-for="(starType, index) in stars"
      :key="index"
      @click="$emit('rate', index + 1)"
      :class="starType"
    ></li>
  </ul>
</template>

<script>
export default {
  props: {
    userRating: {
      type: Number,
      default: null,
    },
    communityRating: {
      type: Number,
      default: null,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stars() {
      const rating = this.userRating || this.communityRating
      const isUserRating = this.userRating ? true : false

      return [0, 1, 2, 3, 4].map((item, index) => {
        // Change to array fill when migrating
        let starType = 'star--none'
        if (rating >= index + 0.5) starType = 'star--half'
        if (rating >= index + 1) starType = 'star--whole'
        if (rating === 0 || rating == null) starType = 'star--unrated'
        if (rating && rating > 0) {
          starType += ' '
          starType += isUserRating ? 'star--green' : 'star--navy'
        }
        return starType
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.the-rating {
  display: flex;
  list-style-type: none;
  align-items: center;
  &:hover .star {
    background-image: url('~@/assets/icons/rating-star-selected.svg');
  }
  &:hover .star--green {
    background-image: url('~@/assets/icons/rating-star-selected.svg');
  }
  &:hover .star--navy {
    background-image: url('~@/assets/icons/rating-star-selected.svg');
  }
  &.the-rating--locked:hover .star {
    background-image: url('~@/assets/icons/rating-star-inactive.svg');
    cursor: default;
  }
}

.star {
  width: 24px + 4px;
  height: 24px;
  // pre load all images
  background-image: url('~@/assets/icons/rating-star-inactive.svg'),
    url('~@/assets/icons/rating-star-active.svg'),
    url('~@/assets/icons/rating-star-selected.svg'),
    url('~@/assets/icons/rating-star-half.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 24px auto, 0, 0, 0;
  cursor: pointer;
  &:first-child {
    width: 24px + 2px;
    background-position-x: left;
  }
  &:last-child {
    width: 24px + 2px;
    background-position-x: right;
  }
  &:hover ~ .star {
    background-image: url('~@/assets/icons/rating-star-inactive.svg');
  }
  &:hover ~ .star.star--unrated {
    background-image: url('~@/assets/icons/recipe-unrated-star.svg');
  }
}

.star--unrated {
  background-image: url('~@/assets/icons/recipe-unrated-star.svg');
}

.star--whole {
  &.star--navy {
    background-image: url('~@/assets/icons/rating-star-active.svg');
  }
  &.star--green {
    background-image: url('~@/assets/icons/rating-star-selected.svg');
  }
}

.star--half {
  &.star--navy {
    background-image: url('~@/assets/icons/rating-star-half.svg');
  }
}
</style>
