<template>
  <div id="add-custom-recipe-info">
    <div class="recipe-title-form-goup">
      <MPInput
        placeholder="Recipe title*"
        type="text"
        size="lg"
        v-model="recipeTitle"
        name="title"
        :data-vv-scope="tabScope"
        v-validate="'required'"
        :class="{ invalid: errors.has(`${tabScope}.title`) }"
        :max-lenght="45"
      />
      <div class="recipe-title-length" v-text="recipeTitleLength"></div>
      <div class="error-message">
        <span v-if="errors.has(`${tabScope}.title`)"> Enter recipe title </span>
      </div>
    </div>
    <div class="row unit-row">
      <div class="col-12 col-md-2">
        <div class="row">
          <MPInput
            placeholder="Time (min)"
            type="text"
            size="lg"
            v-model="recipePrepTime"
            name="prepTime"
            v-validate="'numeric|min_value:0'"
            :data-vv-scope="tabScope"
            :class="{ invalid: errors.has(`${tabScope}.prepTime`) }"
          />
          <div class="error-message">
            <span v-if="errors.has(`${tabScope}.prepTime`)">
              Prep time is invalid
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md">
        <div class="row">
          <AddCustomRecipeServingSize
            v-model="servingSize"
            :data-vv-scope="tabScope"
            v-validate:servingSize="'required'"
            :class="{ invalid: errors.has(`${tabScope}.servings`) }"
            name="servings"
          />
          <div class="error-message">
            <span v-if="errors.has(`${tabScope}.servings`)">
              Enter servings
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md">
        <div class="row">
          <AddCustomRecipeDropDown
            :options="categoryList"
            place-holder="Category*"
            text-field="name"
            value-field="id"
            variant="default"
            v-model="category"
            v-validate:category="'required'"
            :class="{ invalid: errors.has(`${tabScope}.category`) }"
            name="category"
            :data-vv-scope="tabScope"
          />
          <div class="error-message">
            <span v-if="errors.has(`${tabScope}.category`)">
              Enter category
            </span>
          </div>
        </div>
      </div>
    </div>
    <AddCustomRecipeFileUpload v-model="recipeImage" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AddCustomRecipeDropDown from './AddCustomRecipeDropDown'
import AddCustomRecipeServingSize from './AddCustomRecipeServingSize'
import AddCustomRecipeFileUpload from './AddCustomRecipeFileUpload'

export default {
  name: 'AddCustomRecipeInfo',
  components: {
    AddCustomRecipeDropDown,
    AddCustomRecipeServingSize,
    AddCustomRecipeFileUpload,
  },
  props: {
    tabScope: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('AddCustomRecipe', ['formData']),
    ...mapState('recipe', ['categoryList']),
    recipeTitleLength() {
      return `${this.recipeTitle ? this.recipeTitle.length : 0}/45`
    },
    recipeTitle: {
      get() {
        return this.formData && this.formData.recipeTitle
          ? this.formData.recipeTitle
          : null
      },
      set(value) {
        this.$store.commit('AddCustomRecipe/UPDATE_FORM_DATA', {
          recipeTitle: value,
        })
      },
    },
    recipePrepTime: {
      get() {
        return this.formData && this.formData.recipePrepTime
          ? this.formData.recipePrepTime
          : null
      },
      set(value) {
        this.$store.commit('AddCustomRecipe/UPDATE_FORM_DATA', {
          recipePrepTime: value,
        })
      },
    },
    servingSize: {
      get() {
        return this.formData && this.formData.recipeServing
          ? {
              servingsNumber: this.formData.recipeServing,
              isBatchRecipe: this.formData.batchRecipe,
            }
          : null
      },
      set(value) {
        if (value) {
          this.$store.commit('AddCustomRecipe/UPDATE_FORM_DATA', {
            recipeServing: value.servingsNumber,
            batchRecipe: value.isBatchRecipe,
          })
        } else {
          this.$store.commit('AddCustomRecipe/UPDATE_FORM_DATA', {
            recipeServing: null,
            batchRecipe: null,
          })
        }
      },
    },
    category: {
      get() {
        return this.formData && this.formData.category
          ? this.formData.category
          : null
      },
      set(value) {
        this.$store.commit('AddCustomRecipe/UPDATE_FORM_DATA', {
          category: value,
        })
      },
    },
    recipeImage: {
      get() {
        return this.formData && this.formData.recipeImage
          ? this.formData.recipeImage
          : null
      },
      set(value) {
        this.$store.commit('AddCustomRecipe/UPDATE_FORM_DATA', {
          recipeImage: value,
        })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
#add-custom-recipe-info {
  padding: 0 30px 10px 30px;

  @include media-breakpoint-down(md) {
    padding: 0 10px 10px;
  }

  > .row {
    margin-left: -5px;
    margin-right: -5px;

    > .col {
      padding-left: 5px;
      padding-right: 5px;

      &:first-of-type {
        max-width: 127px;
      }
    }
  }
}
.unit-row {
  padding: 0 5px;
}
.recipe-title-form-goup {
  position: relative;
}
.recipe-title-length {
  position: absolute;
  right: 15px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: right;
  color: $iron;
  top: 17px;
}
</style>
