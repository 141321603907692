<template>
  <MPModal :show.sync="showModal" :show-x="true" bgColor="white">
    <MPOverlay :show="!!planStatus" type="light" />
    <div class="px-0 px-md-2 py-3">
      <div class="text-center">
        <h2 class="list-title">
          {{ title }}
          <div class="title-badge">
            <MPPopover v-if="!isDashboardLocked">
              <span class="title-badge-icon" slot="button"> i </span>
              <div slot="popover" class="title-badge-popover">
                To add recipes to a different meal plan change your plan from
                the sidebar.
              </div>
            </MPPopover>
          </div>
        </h2>
      </div>
      <div class="plan">
        <AddToPlan
          :plan="dashboardPlan"
          @remove-meal="removeMeal"
          @add-or-swap-meal="addOrSwapMeal"
        />
      </div>
    </div>
  </MPModal>
</template>

<script>
import { mapGetters } from 'vuex'
import AddToPlan from './AddToPlan'
import { EventBus } from '@/events/EventBus.js'
import { events } from '@/utils/mixpanel.events'

export default {
  components: { AddToPlan },
  data: () => ({}),
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      recipe: 'recipe/recipe',
      isDashboardLocked: 'dashboard/isDashboardLocked',
      defaultMealPlan: 'user/defaultMealPlan',
    }),
    dashboardPlan() {
      return this.$store.getters[this.strategy.dashboardPlan]
    },
    planStatus() {
      return this.$store.getters[this.strategy.planStatus]
    },
    lastUndoAction() {
      return this.$store.getters[this.strategy.lastUndoAction]
    },
    title() {
      return this.$store.getters[this.strategy.title]
    },
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
  },
  methods: {
    addOrSwapMeal(mealType, day) {
      this.$intercom.trackEvent('RECIPE_BOX_SWAPPED')
      const payload = {
        eventName: events.recipe.addedFrom.recipeBox,
        params: {
          recipe_id: this.recipe.id,
        },
      }
      this.$store.dispatch('analytics/track', payload)
      this.$store
        .dispatch(this.strategy.queueAddOrSwapMeal, {
          recipeId: this.recipe.id,
          day,
          mealType,
        })
        .then(() => {
          this.emitUndoAction()
          this.$store.commit('dashboard/SET_SWAP_ACTION', null)
          EventBus.$emit('planModified')
        })
      this.showModal = false
    },
    removeMeal(mealId) {
      this.$store.dispatch(this.strategy.queueRemoveMeal, mealId).then(() => {
        this.emitUndoAction()
        this.$store.commit('dashboard/SET_SWAP_ACTION', null)
        EventBus.$emit('planModified')
      })
      this.showModal = false
    },
    emitUndoAction() {
      const { action, actionData, description } = this.lastUndoAction
      this.$emit('undo-action', {
        dispatchAction: `${this.strategy.dashboardModal}/${action}`,
        actionData,
        description,
      })
      EventBus.$emit('planModified')
    },
  },
  beforeCreate() {
    const isLocked = this.$store.getters['dashboard/isDashboardLocked']

    if (isLocked) {
      this.strategy = {
        dashboardModal: 'LocalDashboard',
        dashboardPlan: 'LocalDashboard/recipeDashboardPlan',
        planStatus: 'LocalDashboard/planStatus',
        title: 'LocalDashboard/title',
        queueAddOrSwapMeal: 'LocalDashboard/addOrSwapMeal',
        queueRemoveMeal: 'LocalDashboard/removeMeal',
        lastUndoAction: 'LocalDashboard/lastUndoAction',
      }
    } else {
      this.strategy = {
        dashboardModal: 'dashboard',
        dashboardPlan: 'dashboard/recipeDashboardPlan',
        planStatus: 'dashboard/planStatus',
        title: 'dashboard/title',
        queueAddOrSwapMeal: 'dashboard/queueAddOrSwapMeal',
        queueRemoveMeal: 'dashboard/queueRemoveMeal',
        lastUndoAction: 'dashboard/lastUndoAction',
      }
    }
  },
  mounted() {
    if (this.isDashboardLocked) {
      this.$store.dispatch('LocalDashboard/loadSampleMealPlan')
    }
  },
}
</script>

<style lang="scss" scoped>
.list-title {
  text-transform: uppercase;
  font-size: 1.7rem;
}

.title-badge {
  position: absolute;
  top: 0;
  right: -12px;
}

.title-badge-icon {
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
  background-color: #efefef;
  width: 12px;
  height: 11px;
  display: block;
  text-align: center;
  line-height: 12px;
  cursor: pointer;
}

.title-badge-popover {
  min-width: 200px;
  padding: 0.5rem;
}
</style>
