<template>
  <bModal
    size="lg"
    hide-footer
    hide-header
    centered
    class="text-entry-modal"
    v-model="showLocal"
  >
    <span class="title">
      {{ title }}
    </span>
    <span class="description">
      {{ description }}
    </span>
    <div class="text-input-group">
      <MPInput
        v-if="showLocal"
        :placeholder="placeholder"
        type="text"
        size="xl"
        v-model="text"
        name="title"
        :focus="true"
        ref="textInput"
      />
    </div>
    <div class="buttons-group">
      <button class="modal-button dismiss-button" @click="onDismiss">
        Cancel
      </button>
      <button class="btn btn-primary confirm-button" @click="onConfirm">
        {{ confirmButtonText }}
      </button>
    </div>
  </bModal>
</template>

<script>
import Vue from 'vue'
import MPInput from '@/components/common/MPInput'
export default {
  components: { MPInput },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    defaultValue: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    confirmButtonText: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      showLocal: false,
      text: '',
    }
  },
  methods: {
    onDismiss() {
      this.showLocal = false
    },
    onConfirm() {
      this.$emit('onConfirm', this.text)
    },
    fixIpadTouch() {
      let node = this.$refs.textInput.getTextInput()
      if (!node) return
      node.focus()
      node.ontouchend = function () {
        if (document.activeElement === node) {
          window.focus()
          setTimeout(function () {
            node.focus()
          }, 0)
        }
      }
    },
  },
  watch: {
    showLocal() {
      this.$emit('update:show', this.showLocal)
    },
    show() {
      this.showLocal = this.show
      this.text = this.defaultValue
      if (this.show) {
        Vue.nextTick().then(() => this.fixIpadTouch())
      }
    },
  },
  mounted() {
    this.showLocal = this.show
  },
}
</script>

<style lang="scss" scoped>
.title {
  display: block;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  color: #404852;
  margin-bottom: 13px;
}

.description {
  display: block;
  text-align: center;
  margin-bottom: 15px;
  padding: 0.5rem 2rem;
}

.text-input-group {
  margin: 0 2rem 2rem 2rem;

  @include media-breakpoint-down(sm) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  #text-input {
    font-size: 20px;
    margin-bottom: 1rem;
    line-height: 100%;
    min-height: 50px;
    width: 100%;
  }
}

.buttons-group {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(186, 186, 186, 0.5);
}

.modal-button {
  font-size: 1rem;
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 0.6px;
  margin: 0;
  padding: 0.75rem 1.5rem;
  text-align: center;
  + .modal-button {
    margin-left: 15px;
  }
}

.dismiss-button {
  background-color: #ffffff;
  color: #404852;
  border: none;
}

.confirm-button {
  margin: 0;
}
</style>

<style lang="scss">
.text-entry-modal {
  .modal-dialog {
    height: 100%;
    @include media-breakpoint-down(sm) {
      margin: 0;
      min-width: 100%;
    }
    @include media-breakpoint-up(md) {
      height: auto;
    }
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
    @include media-breakpoint-up(md) {
      height: auto;
      border-radius: 3px;
    }
  }

  .modal-body {
    padding-bottom: 0;
  }
}

.modal-open {
  overflow: hidden !important;
}
</style>
