<template>
  <div class="account-deletion-content text-center">
    <h3 class="d-block d-md-none header-title">Account Deletion</h3>
    <div class="content-wrapper">
      <Warning width="46" class="warning-icon" />
      <h3 class="d-none d-md-block header-title">
        Are you sure you want to delete your account?
      </h3>
      <span class="instructions d-block">
        Type <strong>DELETE</strong> in the field below to confirm account
        deletion
      </span>
      <div class="delete-account-form row no-gutters">
        <div class="col-5 col-sm">
          <input
            class="delete-account-input form-control"
            type="text"
            v-model="deleteAccountInput"
          />
        </div>
        <div class="col-7 col-sm btn-container">
          <MPButton
            class="btn btn-danger delete-account-confirm"
            @click="deleteUser"
            :disabled="isDeleteDisabled"
          >
            Delete Account
          </MPButton>
        </div>
      </div>
      <span class="delete-info">
        This action will immediately and irrevocably delete your Forks Meal
        Planner account.
      </span>
    </div>
  </div>
</template>

<script>
import { Warning } from '@/components/common/MPIcons'

export default {
  components: {
    Warning,
  },
  data() {
    return {
      deleteAccountInput: null,
    }
  },
  computed: {
    isDeleteDisabled() {
      if (!this.deleteAccountInput) return true
      return this.deleteAccountInput.trim().toUpperCase() !== 'DELETE'
    },
    color() {
      return this.isDeleteDisabled ? 'grey' : 'red'
    },
  },
  methods: {
    deleteUser() {
      this.$emit('delete-user')
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  margin-top: 20px;
  letter-spacing: 0.79px;
  font-size: rem(38);
  line-height: rem(42);
  @include media-breakpoint-only(md) {
    font-size: rem(26);
    line-height: rem(30);
    margin-top: 0;
  }
  @include media-breakpoint-down(sm) {
    font-size: rem(20);
    line-height: rem(26);
    margin-top: 0;
  }
}

.content-wrapper {
  @include media-breakpoint-down(sm) {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
  }
}

.warning-icon {
  margin-top: 20px;
  @include media-breakpoint-only(md) {
    margin-bottom: 20px;
  }
  @include media-breakpoint-down(sm) {
    margin: 0 auto;
    margin-bottom: 0;
  }
}

.instructions {
  margin-top: 19px;

  @include media-breakpoint-down(sm) {
    margin-top: 28px;
  }
}

.delete-account-form {
  display: flex;
  margin-top: 19px;

  @include media-breakpoint-down(sm) {
    margin-top: 28px;
  }
}

.delete-account-input {
  width: 100%;
  border-radius: 0;
  height: 47px;
}

.btn-container {
  text-align: right;
}

.delete-account-confirm {
  flex: 1;
  margin-left: 1rem;
  min-width: 150px;
  text-transform: uppercase;
  @include media-breakpoint-down(sm) {
    margin-left: 7px;
    padding: 9px;
    width: 100%;
  }
}

.delete-info {
  display: inline-block;
  font-size: $font-size-small;
  margin-top: 28px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  align-self: flex-end;
}
</style>
