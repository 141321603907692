<template>
  <MPButton class="f-button" color="fb" size="large" @click="$emit('click')">
    <i class="icon"></i>{{ text }}
  </MPButton>
</template>

<script>
import MPButton from '@/components/common/MPButton'
export default {
  components: {
    MPButton,
  },
  name: 'MPFacebookButton',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.f-button {
  background: #3b5998;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  float: left;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  outline: none;
  padding: 1.125rem 1rem 1.1875rem 6px;
  text-align: center;
  text-transform: none;
  position: relative;
  width: 100%;

  .icon {
    background: url('~@/assets/icons/f.svg') center no-repeat;
    background-size: 14px 26px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 60px;
    border-right: 1px solid rgba($black, 0.1);
  }

  &:hover {
    background-color: darken(#3b5998, 10%);
    color: white;
  }
}
</style>
