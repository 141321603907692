import { categories } from '@/components/constants'

const MEAL_PLAN_TYPE = {
  SAMPLE: 'sample',
  CUSTOM: 'custom',
  FEATURED: 'featured',
  CURRENT: 'current',
}

const getPlanClasificationType = (plan) => {
  if (plan.isSample) {
    return MEAL_PLAN_TYPE.SAMPLE
  } else if (plan.category === categories.promotional) {
    return MEAL_PLAN_TYPE.FEATURED
  } else if (plan.isCurrent) {
    return MEAL_PLAN_TYPE.CURRENT
  } else {
    return MEAL_PLAN_TYPE.CUSTOM
  }
}

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const getMealplanFromStore = (rootGetters, mealPlan) =>
  rootGetters['dashboard/mealPlanItems'].find((item) => item.value === mealPlan)

export const getMealPlanType = async ({ rootGetters, dispatch }) => {
  await Promise.resolve(dispatch('dashboard/getDashboard', '', { root: true }))
  let mealPlan = rootGetters['globals/mealPlan'] || MEAL_PLAN_TYPE.SAMPLE

  if (!Object.values(MEAL_PLAN_TYPE).includes(mealPlan)) {
    let found = getMealplanFromStore(rootGetters, mealPlan)

    while (found === undefined) {
      await sleep(500)
      found = getMealplanFromStore(rootGetters, mealPlan)
    }

    mealPlan = getPlanClasificationType(found)
  }

  return mealPlan
}

export const getMealPlanId = ({ rootGetters }) => {
  const mealPlan = rootGetters['globals/mealPlan'] || MEAL_PLAN_TYPE.SAMPLE
  console.info(mealPlan)

  return mealPlan
}

export const getPlanNameTypeAfterMount = async (
  { rootGetters, dispatch },
  payload
) => {
  const plan_name = await getMealPlanType({ rootGetters, dispatch })

  return { ...payload, params: { plan_name, ...payload.params } }
}
