export default {
  en: {
    test: 'no campaign running',
    day: 'day | days',
    Serving: 'Serving | Servings',
  },
  'en-campaign': {
    test: 'test campaign',
  },
}
