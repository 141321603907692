<template>
  <div
    class="step"
    :class="{
      'step--disabled': disabled,
      'step--active': active,
    }"
    @click="onClick"
  >
    <div class="step-number" :class="stepNumberClass">{{ number }}</div>
    <div
      class="step-title"
      :class="{
        'step-title-active': active,
      }"
      v-text="text"
    />
  </div>
</template>

<script>
export default {
  name: 'AddCustomRecipeStepIcon',
  props: {
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: true },
    number: { type: Number, default: 1 },
    text: { type: String, default: '' },
  },
  computed: {
    stepNumberClass() {
      return `step-number-${this.number}`
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  flex-flow: column;
  align-items: center;
  align-items: center;
  justify-content: center;
  width: 90px;
  cursor: pointer;
}
.step-number {
  width: 27px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
  color: $white;
  background-color: $clr-water;
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 700;
  background-image: url('~@/assets/icons/tick-circle.svg');
  color: transparent;
}
.step-title {
  font-size: rem(14);
  letter-spacing: 0.5px;
  margin-top: 7px;
  white-space: nowrap;

  @include media-breakpoint-down(xs) {
    visibility: hidden;
  }
}
.step--disabled {
  cursor: not-allowed;
  .step-number {
    background-color: $clr-navy-15;
    color: $clr-navy-70;
    background-image: none;
  }
  .step-title {
    color: $text-muted;
  }
}
.step--active {
  cursor: pointer;
  .step-number {
    color: $white;
    background-color: $clr-navy;
    background-image: none;
  }
}
.step-title-active {
  @include media-breakpoint-down(xs) {
    font-size: rem(12);
    visibility: visible;
  }
}
</style>
