import store from '@/store'

const responseInterceptors = {
  'x-userstate': (value) => store.dispatch('headers/setUserState', value),
  'x-planchange': (value) => store.dispatch('headers/setPlanChange', value),
  'x-userexpired': (value) =>
    store.dispatch('headers/setUserExpired', value.toLowerCase() == 'true'),
  'x-expiresin': (value) => store.dispatch('headers/setExpiresIn', value),
  'x-extendedtrial': (value) =>
    store.dispatch('headers/setExtendedTrial', value.toLowerCase() == 'true'),
  'x-version': (value) => store.dispatch('headers/setReleaseVersion', value),
  'payment-late': (value) =>
    store.dispatch('headers/setPaymentLate', value.toLowerCase() == 'true'),
  'x-messages': (value) =>
    store.dispatch('headers/setMessagesCount', parseInt(value)),
  'x-ccrequired': (value) =>
    store.dispatch('headers/setCreditCardRequired', value),
}

export default responseInterceptors
