import api from '.'
import environment from '@/environment'

const BACKEND_API_URL = environment.baseUrl

export default {
  async getCurrentRecipeThread(identifier) {
    try {
      const res = await api.post(
        `${BACKEND_API_URL}disqus/get-current-recipe-thread/`,
        { identifier: identifier }
      )
      return res.data.response
    } catch (err) {
      throw handleError(err)
    }
  },
  async createCurrentRecipeThread(title, identifier) {
    var data = {
      title: title,
      identifier: identifier,
    }
    try {
      const res = await api.post(`${BACKEND_API_URL}disqus/openthread/`, data)
      return res.data.response
    } catch (err) {
      throw handleError(err)
    }
  },
  async getPosts(cursor, currentRecipeThreadId) {
    try {
      const res = await api.post(
        `${BACKEND_API_URL}disqus/get-posts/${currentRecipeThreadId}/`,
        { cursor: cursor }
      )
      return res.data
    } catch (err) {
      throw handleError(err)
    }
  },
  async createPost(message, currentRecipeThreadId) {
    try {
      const res = await api.post(
        `${BACKEND_API_URL}disqus/create-post/${currentRecipeThreadId}/`,
        { message: encodeURIComponent(message) }
      )
      return res.data.response
    } catch (err) {
      throw handleError(err)
    }
  },
  async editPost(message, postId, username) {
    try {
      var data = {
        message: encodeURIComponent(message),
        user_name: username,
      }
      const res = await api.post(
        `${BACKEND_API_URL}disqus/edit/${postId}/`,
        data
      )
      return res.data.response
    } catch (err) {
      throw handleError(err)
    }
  },
  async deletePost(postId, username) {
    try {
      var data = {
        user_name: username,
      }
      const res = await api.post(
        `${BACKEND_API_URL}disqus/delete/${postId}/`,
        data
      )
      return res.data.response
    } catch (err) {
      throw handleError(err)
    }
  },
  async votePost(vote, postId) {
    try {
      const res = await api.post(
        `${BACKEND_API_URL}disqus/vote-post/${postId}/`,
        { vote: vote }
      )
      return res.data.response
    } catch (err) {
      throw handleError(err)
    }
  },
  async reportPost(reason, postId) {
    try {
      const res = await api.post(
        `${BACKEND_API_URL}disqus/report-post/${postId}/`,
        { reason: reason }
      )
      return res.data.response
    } catch (err) {
      throw handleError(err)
    }
  },
  async replayToPost(message, currentRecipeThreadId, parentId) {
    try {
      const res = await api.post(
        `${BACKEND_API_URL}disqus/reply-to-post/${currentRecipeThreadId}/${parentId}/`,
        { message: encodeURIComponent(message) }
      )
      return res.data.response
    } catch (err) {
      throw handleError(err)
    }
  },
  async getUserPosts(userId, cursor) {
    try {
      const res = await api.post(
        `${BACKEND_API_URL}disqus/get-user-posts/${userId}/`,
        { cursor: cursor }
      )
      return res.data
    } catch (err) {
      throw handleError(err)
    }
  },
  async getUserDetails() {
    try {
      const res = await api.post(`${BACKEND_API_URL}disqus/get-user-details/`)
      return res.data.response
    } catch (err) {
      throw handleError(err)
    }
  },
}
function handleError(err) {
  return {
    code: err.response.data.code,
    message: getErrorMeaning(err.response.data.code),
    serverMessage: err.response.data.response,
  }
}
function getErrorMeaning(code) {
  switch (code) {
    case 0:
      return 'Success'
    case 1:
      return 'Endpoint not valid'
    case 2:
      return 'Missing or invalid argument'
    case 3:
      return 'Endpoint resource not valid'
    case 4:
      return 'You must be authenticated to perform this action'
    case 5:
      return 'Invalid API key'
    case 6:
      return 'Invalid API version'
    case 7:
      return 'You cannot access this resource using %(request_method)s'
    case 8:
      return 'A requested object was not found'
    case 9:
      return 'You cannot access this resource using your %(access_type)s API key'
    case 10:
      return 'This operation is not supported'
    case 11:
      return 'Your API key is not valid on this domain'
    case 12:
      return 'This application does not have enough privileges to access this resource'
    case 13:
      return 'You have exceeded the rate limit for this resource'
    case 14:
      return 'You have exceeded the rate limit for your account'
    case 15:
      return 'There was internal server error while processing your request'
    case 16:
      return 'Your request timed out'
    case 17:
      return 'The authenticated user does not have access to this feature'
    case 18:
      return 'The authorization signature you passed was not valid'
    case 19:
      return 'You must re-submit this request with a response to the captcha challenge'
    case 20:
      return 'The API is currently undergoing maintenance, and your changes were saved, but will not be visible yet.'
    case 21:
      return 'The API is currently undergoing maintenance, and your changes could not be saved.'
    case 22:
      return 'You do not have the appropriate permissions to access this resource'
    case 23:
      return 'You must be authenticated and verified by the forum owner to perform this action'
    case 24:
      return 'You have exceeded the maximum number of creations for this resource'
  }
}
