import camelizeKeys from './camelizeKeys'
import api from './index'

const GET_PLANS = 'chargebee/configuration'
const CREATE_HOSTED = 'chargebee/checkout/create'
const UPDATE_HOSTED = 'chargebee/checkout/update'
const CREATE_SUBSCRIPTION = 'chargebee/checkout/process'
const CREATE_PORTAL = 'chargebee/portal-session'
const CREATE_CANCEL = 'chargebee/checkout/pre-cancel'
const CREATE_GIFT = 'chargebee/gift/new'
const GET_GIFT_PLANS = 'chargebee/gift/configuration'
const CLAIM_GIFT = 'chargebee/gift/claim'
const SET_PASSWORD = 'chargebee/gift/reset-password'
const CREATE_SUB_NO_PORTAL = 'chargebee/subscription/create'

export default {
  getCheckout: (id) =>
    api
      .get(GET_PLANS, id ? { config_id: id } : {})
      .then((res) => camelizeKeys(res.data)),
  createHostedPage: (params) =>
    api.post(CREATE_HOSTED, params).then((res) => res.data),
  updateHostedPage: (params) =>
    api.post(UPDATE_HOSTED, params).then((res) => res.data),
  giftablePlans: (id = null) =>
    api
      .get(GET_GIFT_PLANS, id ? { config_id: id } : {})
      .then((res) => camelizeKeys(res.data)),
  createGiftHostedPage: (params) =>
    api.post(CREATE_GIFT, params).then((res) => res.data),
  claimGift: (params) =>
    api
      .post(CLAIM_GIFT, params)
      .then((res) => camelizeKeys(res.data))
      .catch((err) => {
        throw new Error(err)
      }),
  resetPassword: (params) =>
    api
      .post(SET_PASSWORD, params)
      .then((res) => camelizeKeys(res.data))
      .catch((err) => {
        throw new Error(err)
      }),
  createSubscriptionNoPortal: (params) =>
    api
      .post(CREATE_SUB_NO_PORTAL, params)
      .then((res) => camelizeKeys(res.data))
      .catch((err) => {
        throw new Error(err)
      }),
  createSubscriptionUser: (params) =>
    api.post(CREATE_SUBSCRIPTION, params).then((res) => camelizeKeys(res.data)),
  createPortal: () => api.post(CREATE_PORTAL).then((res) => res.data),
  createCancel: () => api.post(CREATE_CANCEL).then((res) => res.data),
}
