<!--
  IMPORTANT
  Because of feature that enables dragging recipe to ADD DAY button.
  That have to add item instantly in new list. Is everything a little more complex.
  We add an extra hidden list to plan that is shown ones user drop item on add button.
-->
<template>
  <div id="dashboard-plan" ref="dashboardPlan">
    <!--
      Expect components:
        - DashboardPlanItem
        - DashboardPlanLoadingItem
        - DashboardPlanExampleItem
        - DashboardPlanEmptyItem
    -->
    <div
      class="list"
      v-for="(list, listIndex) in planLocalDisplayed"
      :key="listIndex"
      @mousedown="onMouseDown"
      @touchstart="onTouchStart"
    >
      <span class="list-title"> DAY {{ listIndex + 1 }} </span>
      <div
        class="draggable-wrapper"
        :class="{ 'draggable-wrapper--dragging': movingId }"
      >
        <Draggable
          v-model="list.items"
          :options="{
            group: 'list',
            animation: 150,
            delay: isTouchDevice ? 500 : 0,
            draggable: '.draggable-item',
          }"
          @end="onEnd"
          @start="onStart"
          :data-id="listIndex"
          class="draggable"
          :force-fallback="true"
          :scroll-sensitivity="200"
        >
          <component
            :class="{ 'draggable-item': item.isDraggable }"
            :is="item.componentName"
            v-for="(item, itemIndex) in list.items"
            :key="item.id"
            :data="item"
            @onOpenItemClick="onOpenItemClick"
            @onSwapOutItemClick="onSwapOutItemClick"
            @onRemoveItemClick="onRemoveItemClick"
            @addRecipe="addRecipe(listIndex, itemIndex)"
          ></component>
        </Draggable>
        <div
          class="drop-text"
          v-if="
            !list.items.length ||
            (list.items.length === 1 &&
              movingId &&
              movingItemListId === listIndex)
          "
          @click="addRecipe(listIndex, list.items.length)"
        >
          <span> Drag and drop a recipe here, or <u>add</u> a recipe. </span>
        </div>
        <a
          href
          class="add-recipe"
          @click.prevent="addRecipe(listIndex, list.items.length)"
          v-if="list.actionStatus !== 'hidden'"
        >
          + ADD RECIPE
        </a>
      </div>
    </div>
    <div class="add-list-wrapper" v-show="showAddList">
      <Draggable
        class="add-list"
        v-if="planLocal[addListNumberIndex]"
        v-model="planLocal[addListNumberIndex].items"
        group="list"
        :animation="150"
        @end="onEnd"
        @start="onStart"
        :data-id="addListNumberIndex"
      >
      </Draggable>
      <span
        class="add-list-text"
        :class="{ 'add-list-text--on-dragging': movingId }"
        @click="addDay"
      >
        <span
          >Drag and drop a recipe here, or <br /><span class="add-day-text"
            >+ add day {{ addListNumber }}</span
          ></span
        >
      </span>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import DashboardPlanItem from './DashboardPlanItem'
import DashboardPlanLoadingItem from './DashboardPlanLoadingItem'
import DashboardPlanEmptyItem from './DashboardPlanEmptyItem'
import DashboardPlanExampleItem from './DashboardPlanExampleItem'
import DashboardBuilder from './DashboardBuilder'
import DetectDeviceMixin from '@/mixins/DetectDeviceMixin'

let scrollingPromise

export default {
  mixins: [DetectDeviceMixin],
  components: {
    Draggable,
    DashboardPlanItem,
    DashboardPlanLoadingItem,
    DashboardPlanExampleItem,
    DashboardPlanEmptyItem,
  },
  props: {
    plan: {
      type: Array,
      required: true,
    },
    maxLists: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      onMouseDownClientY: null,
      movedElementStartPosition: null,
      movingId: null,
      movingItemListId: null,
      planLocal: [],
    }
  },
  computed: {
    showAddList() {
      return (
        this.planLocal[this.addListNumberIndex] &&
        this.addListNumberIndex < this.maxLists
      )
    },
    addListNumber() {
      // represents the last and hidden list
      return this.planLocal.length
    },
    addListNumberIndex() {
      return this.planLocal.length - 1
    },
    planLocalDisplayed() {
      return this.planLocal.slice(0, this.addListNumberIndex)
    },
  },
  methods: {
    onOpenItemClick(mealId) {
      this.$emit('onOpenItemClick', mealId)
    },
    onSwapOutItemClick(mealId) {
      this.$emit('onSwapOutItemClick', mealId)
    },
    addRecipe(listIndex, itemIndex) {
      this.$emit('onAddRecipeClick', { listIndex, itemIndex })
    },
    onRemoveItemClick(mealId) {
      let position = this.planLocal.reduce((position, list, listIndex) => {
        let itemIndex = list.items.findIndex(({ id }) => id === mealId)
        if (itemIndex > -1) return { list: listIndex, index: itemIndex }
        return position
      }, {})
      this.planLocal[position.list].items.splice(position.index, 1)
      this.$emit('onRemoveItemClick', mealId)
    },
    onStart(e) {
      // You get wrong ID on end because of reusing elements in v-for
      this.movedElementStartPosition = e.item.getBoundingClientRect()
      this.movingId = parseInt(e.item.getAttribute('data-id'))
      this.movingItemListId = parseInt(
        e.item.parentElement.getAttribute('data-id')
      )
    },
    onEnd(e) {
      let fromListId = parseInt(e.from.getAttribute('data-id'))
      let toListId = parseInt(e.to.getAttribute('data-id'))
      if (fromListId != toListId || e.oldIndex != e.newIndex) {
        this.$emit('onMoveTo', {
          id: this.movingId,
          list: toListId,
          index: e.newIndex,
        })
      }

      if (toListId === this.addListNumberIndex) {
        this.planLocal.push(DashboardBuilder.getDashboardList())
      }

      this.movedElementStartPosition = null
      this.movingId = null
      this.movingItemListId = null
      clearInterval(scrollingPromise)
    },
    onMouseDown(event) {
      let clientY = getClientY(event)
      this.onMouseDownClientY = clientY
      this.previousClientY = clientY
    },
    onTouchStart(event) {
      let clientY = event.touches[0].clientY
      this.onMouseDownClientY = clientY
      this.previousClientY = clientY
    },
    addDay() {
      this.$emit('onAddDayClick', this.addListNumber)
    },
    planToLocalPlan() {
      let plan = [...this.plan]
      plan.push(DashboardBuilder.getDashboardList())
      this.planLocal = plan
    },
  },
  watch: {
    plan: {
      immediate: true,
      handler: 'planToLocalPlan',
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollElement = this.$refs.dashboardPlan
    })
  },
}

function getClientY(event) {
  return event.type === 'touchmove' ? event.touches[0].clientY : event.clientY
}
</script>

<style lang="scss" scoped>
.list + .list {
  margin-top: 15px;
}

.list-title {
  font-weight: bold;
  font-size: $h4-font-size;
  display: block;
  user-select: none;
  background-color: $clr-navy-5;
  padding: 10px 0 10px 1rem;
  line-height: 1.13;
  letter-spacing: 0.5px;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

.add-recipe {
  // Make add recipe droppable.
  position: absolute;
  font-weight: bold;
  bottom: 0px;
  text-align: center;
  display: inline-block;
  padding: 10px 15px;
  transform: translate(-50%, 0);
  left: 50%;
  user-select: none;
}

.draggable-wrapper {
  position: relative;
}

.draggable {
  min-height: 152px;
  padding: 0 0 57px;
}

.draggable-wrapper--dragging {
  .drop-text,
  .add-recipe {
    pointer-events: none;
  }
}

.drop-text {
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  color: $clr-navy-50;
  font-weight: 500;
  min-height: 80px;
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed $clr-navy-50;
  text-align: center;
  padding: 10px;
  height: 70px;
  background-color: $clr-navy-3;
  @include media-breakpoint-down(sm) {
    left: 15px;
    right: 15px;
  }
}
.add-list-wrapper {
  position: relative;
  margin-top: 15px;
}

// add-day
.add-list {
  min-height: 110px;
}

.add-list-text {
  @extend .drop-text;
  &--on-dragging {
    pointer-events: none;
  }
  .add-day-text {
    color: $body-color;
    font-weight: bold;
    text-transform: uppercase;
  }
}
</style>
