import posthog from 'posthog-js'
import environment from '@/environment'
import { EVENTS } from '@/events/analytics'

const posthogEvents = new Map()
posthogEvents.set(EVENTS.registration.step1, 'user registration step 1')
posthogEvents.set(EVENTS.registration.step2, 'user registration step 2')
posthogEvents.set(EVENTS.registration.step3, 'user registration step 3')
posthogEvents.set(EVENTS.registration.step4, 'user registration step 4')
posthogEvents.set(EVENTS.registration.step5, 'user registration step 5')
posthogEvents.set(EVENTS.registration.step6, 'user registration step 6')
posthogEvents.set(EVENTS.registration.createAccount, 'user create account')
posthogEvents.set(EVENTS.subscription.create, 'user create subscription')
posthogEvents.set(
  EVENTS.subscription.reactivate,
  'user reactivate subscription'
)
posthogEvents.set(EVENTS.subscription.initCancel, 'user start cancellation')
posthogEvents.set(EVENTS.recipe.favorite, 'user mark as favorite')
posthogEvents.set(EVENTS.plan.visit, 'user visit plan page')
posthogEvents.set(EVENTS.recipeBox.visit, 'user visit recipe box page')
posthogEvents.set(EVENTS.groceryList.visit, 'user visit grocey list page')
posthogEvents.set(EVENTS.weekendPrep.visit, 'user visit weekend prep page')
posthogEvents.set(EVENTS.personalRecipes.visit, 'user visit personal recipes page')
posthogEvents.set(EVENTS.personalRecipes.created, 'user creates personal recipe')
posthogEvents.set(EVENTS.personalRecipes.edited, 'user edites personal recipe')

posthogEvents.set('$pageview', '$pageview')

const Instance = {
  phInstance: null,
  mapppedEvents: null,
  sendEvent(eventName, properties) {
    if (posthogEvents.has(eventName)) {
      const isObjectProps = typeof properties === 'object'
      this.phInstance.capture(
        posthogEvents.get(eventName),
        isObjectProps ? { ...properties } : properties
      )
    }
  },
}

function init(properties) {
  Instance.phInstance = posthog.init(environment.postHogKey, { ...properties })
  return Instance
}

const PosthogInstance = {
  install(Vue) {
    Vue.prototype.$posthog = init({
      api_host: 'https://us.i.posthog.com',
      person_profiles: 'identified_only',
      capture_pageview: false,
    })
  },
}

export default PosthogInstance
