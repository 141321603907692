<template>
  <div class="mp-servings-selector">
    <div class="img-container">
      <i :class="`img ${type}`"></i>
    </div>
    <div class="text-sm">
      {{ titleText }}
    </div>
    <div class="adjust-group">
      <button
        class="adjust-portions"
        @click="$emit('decreased')"
        :data-spec="dataSpecDec"
      >
        <Minus />
      </button>
      <span class="selected-portions">{{ servings }}</span>
      <button
        class="adjust-portions adjust-portions--with-border"
        @click="$emit('increased')"
        :data-spec="dataSpecInc"
      >
        <Plus width="24" />
      </button>
    </div>
  </div>
</template>

<script>
import { Plus, Minus } from '@/components/common/MPIcons'
export default {
  name: 'MPServingsSelector',
  components: {
    Plus,
    Minus,
  },
  props: {
    servings: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: 'adult', // adult/child,
      required: false,
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
    dataSpecInc: {
      type: String,
      default: '',
    },
    dataSpecDec: {
      type: String,
      default: '',
    },
  },
  computed: {
    titleText() {
      if (this.text) {
        return this.text
      }
      switch (this.type) {
        case 'child':
          return '1/2 portions'
        case 'adult':
          return 'Full portions'
      }

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-servings-selector {
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px $clr-navy-15;
  border-radius: 4px;
  @include media-breakpoint-up(md) {
    padding: 1rem 1rem;
    margin-top: 0;
  }

  .img-container {
    position: relative;
    min-width: 58px;
    min-height: 58px;
    margin-bottom: 13px;

    i {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 100%;
      height: 100%;

      &.adult {
        background: url('~@/assets/icons/onboarding-adult.svg') no-repeat center;
      }

      &.child {
        background: url('~@/assets/icons/onboarding-child.svg') no-repeat center;
      }
    }
  }

  .adjust-group {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    max-width: 160px;
    margin-top: 13px;

    @include media-breakpoint-down(sm) {
      max-width: 140px;
    }
  }

  button.adjust-portions {
    border-radius: 50%;
    width: 33px;
    height: 33px;
    padding: 0;
    margin: 5px;

    background-color: $white;
    color: $clr-water;

    &.adjust-portions--with-border {
      border: solid 1px $clr-navy-15;
    }
  }

  .selected-portions {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    text-align: center;
    padding-top: 0;
    min-width: 2rem;
    margin: 0 10px;
  }

  @include media-breakpoint-down(sm) {
    margin: 5px auto;
    padding: 6px;
    flex: 1;

    .img-container {
      position: relative;
      min-width: 60px;
      min-height: 60px;

      i {
        width: 45px;

        &.adult {
          background-size: 45px 52px;
        }

        &.child {
          background-size: 45px 52px;
          background-position-y: 10px;
        }
      }
    }

    button.adjust-portions {
      border-radius: 50%;
      width: 33px;
      height: 33px;
      padding: 0;
      margin: 5px;
      font-size: 14px;
      margin-top: 6px;

      &.sign {
        font-size: 20px;
      }
    }

    .selected-portions {
      min-width: 1rem;
      font-size: 13px;
      line-height: 1.77;
    }
  }
}
</style>
