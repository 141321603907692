<template>
  <RecipeBase id="recipe" @close="closeRecipe">
    <div slot="header">
      <RecipeHeader :image="recipe.image">
        <div class="d-lg-none container">
          <RecipeTopSection />
          <div
            class="d-md-none servings-text-section text-serif"
            v-if="recipe.servingsText"
            v-html="recipe.servingsText"
          ></div>
          <div
            class="d-md-none text-serif"
            v-if="recipe.description"
            v-html="recipe.description"
          ></div>
        </div>
      </RecipeHeader>
    </div>
    <template slot="left-content">
      <div class="d-none d-lg-flex">
        <RecipeTopSection />
      </div>
      <div
        class="d-none d-md-block servings-text-section"
        v-if="recipe.servingsText"
        v-html="recipe.servingsText"
      ></div>
      <div
        class="d-none d-md-block text-serif"
        v-if="recipe.description"
        v-html="recipe.description"
      ></div>
      <div v-for="(stepGroup, index) in recipe.stepsGroups" :key="index">
        <RecipeSubtitle v-if="stepGroup.title">
          {{ stepGroup.title }}
        </RecipeSubtitle>
        <RecipeStep
          v-for="step in stepGroup.items"
          :key="step.number"
          :number="step.number"
          :title="step.title"
          :description="step.description"
          :is-speed-prep="step.isSpeedPrep"
        />
      </div>
      <div class="storage-tip" v-if="recipe.suggestedServingForPrint">
        <div
          class="storage-tip-content text-muted text-sm"
          v-html="recipe.suggestedServingForPrint"
        ></div>
      </div>

      <div class="storage-tip" v-if="recipe.storageTip">
        <div
          class="storage-tip-content text-muted text-sm"
          v-html="recipe.storageTip"
        ></div>
      </div>

      <div class="storage-tip" v-if="recipe.freezeTips.length">
        <div class="storage-tip-content text-muted text-sm">
          Freezing:
          <span v-for="(tip, $index) in recipe.freezeTips" :key="$index">
            <MPPopover class="d-inline-block" :show-on-hover="false">
              <span class="link" slot="button">
                {{ tip.title }}
              </span>
              <div class="p-2" slot="popover">
                <h4 class="tip-title">{{ tip.title }}</h4>
                <p v-html="tip.content"></p>
              </div>
            </MPPopover>
          </span>
        </div>
      </div>

      <div class="storage-tip" v-if="recipe.reheatTips.length">
        <div class="storage-tip-content text-muted text-sm">
          Reheating:
          <span
            class="storage-tip-item"
            v-for="(tip, $index) in recipe.reheatTips"
            :key="$index"
          >
            <MPPopover class="d-inline-block" :show-on-hover="false">
              <span class="link" slot="button">
                {{ tip.title }}
              </span>
              <div class="p-2" slot="popover">
                <h4 class="tip-title">{{ tip.title }}</h4>
                <p class="tip-content" v-html="tip.content"></p>
              </div>
            </MPPopover>
          </span>
        </div>
      </div>
      <div
        class="credits col-12 small"
        v-if="recipe.credits"
        v-html="recipe.credits"
      ></div>
    </template>
    <template slot="right-content">
      <RecipeSideCard v-if="useNutritionalTracking">
        <RecipeSubtitle> NUTRITIONAL INFORMATION </RecipeSubtitle>
        <RecipeNutritionalInfo
          :data="recipe.nutritionalInfo"
          :suggestedServing="recipe.suggestedServing"
        />
      </RecipeSideCard>
      <!-- INGREDIENTS -->
      <RecipeSideCard>
        <RecipeSubtitle> INGREDIENTS </RecipeSubtitle>
        <p class="speed-prep-note small text-muted">
          (<Knife class="knife-icon" width="18" /> marks weekend prep items)
        </p>
        <div
          v-for="(ingredientGroup, key) in recipe.ingredientsGroup"
          :key="key"
        >
          <RecipeIngredientTitle v-if="key">
            {{ key }}
          </RecipeIngredientTitle>
          <RecipeIngredient
            v-for="(ingredient, index) in ingredientGroup"
            :key="index"
            :title="ingredient.title"
            :description="ingredient.description"
            :is-speed-prep="ingredient.isSpeedPrep"
          />
        </div>
      </RecipeSideCard>
      <!-- EQUIPMENT -->
      <RecipeSideCard v-if="recipe.equipment">
        <RecipeSubtitle> YOU'LL NEED </RecipeSubtitle>
        <span v-html="recipe.equipment" class="text-serif" />
      </RecipeSideCard>
      <!-- TIPS -->
      <RecipeSideCard v-if="recipe.tips.length" class="light-green">
        <RecipeSubtitle> RECIPE TIPS </RecipeSubtitle>
        <span
          class="mb-3 d-block text-serif"
          v-for="(tip, index) in recipe.tips"
          :key="index"
          v-html="tip"
        ></span>
      </RecipeSideCard>
    </template>
    <div slot="footer" class="row mt-5">
      <hr />
      <RecipeNotes
        v-if="!isAdmin"
        :notes="recipe.notes"
        @save-note="saveRecipeNote"
      />
      <RecipeAdminNotes
        v-if="isAdmin"
        :notes="recipe.adminNotes"
        :feedbacks="recipe.feedbacks"
      />
      <hr />
      <DisqusComments />
      <DisqusSideBar />
    </div>
  </RecipeBase>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import RecipeHeader from './RecipeHeader'
import RecipeStep from './RecipeStep'
import RecipeSubtitle from './RecipeSubtitle'
import RecipeIngredient from './RecipeIngredient'
import RecipeIngredientTitle from './RecipeIngredientTitle'
import RecipeNotes from './RecipeNotes'
import RecipeAdminNotes from './RecipeAdminNotes'
import RecipeTopSection from './RecipeTopSection'
import RecipeNutritionalInfo from './RecipeNutritionalInfo'

import RecipeBase from './RecipeBase'
import RecipeSideCard from './RecipeSideCard'
import RouterMixin from '@/mixins/RouterMixin'
import DisqusComments from './disqus/DisqusComments'
import DisqusSideBar from './disqus/DisqusSideBar'

import { Knife } from '@/components/common/MPIcons'

import { events } from '@/utils/mixpanel.events'

export default {
  components: {
    RecipeHeader,
    RecipeStep,
    RecipeSubtitle,
    RecipeIngredient,
    RecipeIngredientTitle,
    RecipeNotes,
    RecipeAdminNotes,
    RecipeBase,
    RecipeSideCard,
    DisqusComments,
    DisqusSideBar,
    Knife,
    RecipeTopSection,
    RecipeNutritionalInfo,
  },
  data() {
    return {
      showRecipeFeedbackModal: false,
      showAddToPlanModal: false,
      undoAction: null,
      undoActionTimeout: null,
    }
  },
  computed: {
    ...mapGetters({
      recipe: 'recipe/recipe',
      previousRoute: 'globals/previousRoute',
      numberOfRecipeComments: 'Disqus/commentsCount',
      isAdmin: 'user/isAdmin',
      useNutritionalTracking: 'user/useNutritionalTracking',
    }),
    ...mapState('Disqus', ['isSideBarVisible']),
  },
  mixins: [RouterMixin],
  watch: {
    isSideBarVisible: {
      handler: function (value) {
        if (value) {
          document.body.classList.add('prevent-background-from-scrolling')
        } else {
          document.body.classList.remove('prevent-background-from-scrolling')
        }
      },
    },
  },
  methods: {
    toggleFavorite() {
      this.$store.dispatch('recipe/toggleFavorite')
      this.$intercom.trackEvent('FAVORITED')
      const payload = {
        eventName: events.recipe.favorited,
        params: {
          recipe_id: this.recipe.id,
          action: this.recipe.isFavorite ? 'favorite' : 'unfavorite',
        },
      }
      this.$store.dispatch('analytics/track', payload)
    },
    rateRecipe(rate) {
      this.showRecipeFeedbackModal = true
      this.$store.dispatch('recipe/rateRecipe', rate)
      const payload = {
        eventName: events.recipe.rated,
        params: {
          recipe_id: this.recipe.id,
          recipe_rating: rate,
        },
      }
      this.$store.dispatch('analytics/track', payload)
    },
    closeRecipe() {
      this.goBackAuthenticated()
    },
    saveRecipeNote(note) {
      this.$store.dispatch('recipe/saveNote', note)
    },
    goToComments() {
      document.getElementById('disqus-comments').scrollIntoView()
    },
    setUndoAction(undoAction) {
      clearTimeout(this.undoActionTimeout)
      this.undoAction = undoAction
      this.undoActionTimeout = setTimeout(() => {
        this.undoAction = null
      }, 40000)
    },
    undoActionApply() {
      const { dispatchAction, actionData } = this.undoAction
      this.$store.dispatch(dispatchAction, actionData)
      this.undoAction = null
    },
  },
  mounted() {
    this.$intercom.trackEvent('RECIPE_VIEWED')
  },
}
</script>

<style lang="scss" scoped>
.recipe-left-content {
  .recipe-subtitle {
    margin-bottom: 24px;
    &:before {
      content: '';
      display: block;
      width: 60px;
      height: 4px;
      background-color: $body-color;
      margin-bottom: 25px;
    }
  }
}
.recipe-right-content {
  .recipe-ingredient-subtitle {
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 26px;
    margin-bottom: 8px;
  }
}
.recipe-header-line {
  display: inline-block;
  width: 1px;
  height: 21px;
  opacity: 0.3;
  border: solid 0.5px #616161;
  margin: 0 1rem;
}

.servings-text-section {
  font-size: 0.75rem;
  border-bottom: 2px solid #e5e5e5;
  margin-bottom: 1rem;
}

.credits {
  margin: 1rem 0;

  @include media-breakpoint-up(lg) {
    padding-left: 1.875rem;
  }
  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

.storage-tip {
  display: flex;
  margin-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    padding-left: 1.875rem;
  }
}

.tip-title {
  text-align: center;
}

.storage-tip-item {
  padding-right: 5px;
  & + .storage-tip-item {
    padding-left: 5px;
    border-left: 1px solid $clr-navy-15;
  }
}

.storage-tip-content {
  flex: 1;
  padding: 0;
}
</style>

<style lang="scss">
.prevent-background-from-scrolling {
  overflow: hidden;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
}

#recipe {
  .storage-tip-content p {
    margin-bottom: 20px;
  }
  .storage-tip-content p:only-child {
    margin-bottom: 0;
  }
}

.knife-icon {
  color: $primary;
}
.speed-prep-note {
  margin-top: -10px;
}

.add-btn-wrapper {
  text-align: right;
}

.add-btn {
  margin-bottom: 0;
}
</style>
