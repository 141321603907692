<template>
  <div>
    <span
      >Hmmm…..it looks like there is a problem with this link.
      <br />
      <b>Please try clicking the original link again</b>
      and if the problem persists
      <MPWhiteLink href @click.prevent="openIntercom()">contact us</MPWhiteLink>
      and our Customer Success team will be happy to assist.
    </span>
  </div>
</template>

<script>
import { MPWhiteLink } from '@/components/common'

export default {
  components: { MPWhiteLink },
  methods: {
    openIntercom() {
      this.$intercom.show()
    },
  },
}
</script>
