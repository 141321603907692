<template>
  <div>
    <RegisterSelectMealsForm v-on="$listeners" v-if="showSelectMealsForm" />
    <RegisterSelectAllMealsForm v-on="$listeners" v-if="showAllMealsForm" />
  </div>
</template>

<script>
import ExternalServices from '@/externalServices'
import RegisterSelectMealsForm from '@/components/credentials/register/RegisterSelectMealsForm'
import RegisterSelectAllMealsForm from '@/components/credentials/register/RegisterSelectAllMealsForm'
import { EVENTS } from '@/events/analytics'

export default {
  components: {
    RegisterSelectMealsForm,
    RegisterSelectAllMealsForm,
  },
  data() {
    return {
      showAllMealsForm: false,
      showSelectMealsForm: false,
    }
  },
  beforeCreate() {
    setTimeout(() => {
      let showAll = ExternalServices.ABTests.showAllMealTypesOption
      this.showAllMealsForm = showAll
      this.showSelectMealsForm = !showAll
    }, 200)
  },
  mounted() {
    this.$posthog.sendEvent(EVENTS.registration.step4)
  },
}
</script>
