<template>
  <MPButton
    class="f-button"
    color="white"
    size="large"
    @click="$emit('click')"
    :data-spec="dataSpec"
  >
    <div class="button-content">
      <img :src="require('@/assets/icons/f.svg')" class="icon" />
      <span class="text"><slot></slot></span>
    </div>
  </MPButton>
</template>

<script>
import MPButton from '@/components/common/MPButton'

export default {
  components: {
    MPButton,
  },
  name: 'MPCompactFacebookButton',
  props: {
    dataSpec: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.f-button {
  max-height: 50px;
  background: $white;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
  position: relative;
  width: 100%;
  border: solid 1px #39579a;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 26px;
  margin-right: 12px;
  @include media-breakpoint-down(sm) {
    margin-left: auto;
  }
}

.text {
  @include media-breakpoint-down(sm) {
    margin-right: auto;
  }
}
</style>
