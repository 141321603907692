import { render, staticRenderFns } from "./TheRecipeLoading.vue?vue&type=template&id=2f64829a&scoped=true"
import script from "./TheRecipeLoading.vue?vue&type=script&lang=js"
export * from "./TheRecipeLoading.vue?vue&type=script&lang=js"
import style0 from "./TheRecipeLoading.vue?vue&type=style&index=0&id=2f64829a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f64829a",
  null
  
)

export default component.exports