<template>
  <div
    :id="`mp-popover-${id}`"
    class="mp-popover"
    :class="`mp-popover--${color}`"
    :data-id="id"
  >
    <div
      class="mp-popover-button"
      v-bind:class="{ disabled: disabled }"
      :id="`mp-popover-button-${id}`"
      @click="onPopoverButtonClick()"
      @mouseover="mouseover()"
      @mouseleave="mouseleave()"
    >
      <slot name="button"></slot>
    </div>
    <bPopover
      v-if="!disabled"
      :target="`mp-popover-button-${id}`"
      triggers="click"
      :show.sync="popoverShow"
      :placement="placement"
      :container="`mp-popover-${id}`"
      :offset="offset"
    >
      <div
        @mouseover="mouseover()"
        @mouseleave="mouseleave()"
        @click="popoverClick()"
      >
        <div class="options-list-extra"></div>
        <slot name="popover"></slot>
      </div>
    </bPopover>
    <div
      v-if="dimBackground && popoverShow"
      class="modal-backdrop fade show"
      @click.stop="setPopoverShow(false)"
    ></div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'MPPopover',
  props: {
    show: {},
    hideOnClick: {
      type: Boolean,
      default: true,
    },
    hideOnMouseLeave: {
      type: Boolean,
      default: true,
    },
    hideOnClickOutside: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'bottomleft',
    },
    offset: {
      type: String,
      default: '0',
    },
    showOnHover: {
      type: Boolean,
      default: true,
    },
    showOnClick: {
      type: Boolean,
      default: false,
    },
    dimBackground: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'white',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: null,
      showLocal: false,
    }
  },
  computed: {
    popoverShow: {
      get() {
        if (this.show === undefined) {
          return this.showLocal
        } else return this.show
      },
      set(value) {
        if (this.show === undefined) {
          this.showLocal = value
        } else this.$emit('update:show', value)
      },
    },
  },
  methods: {
    setPopoverShow() {},
    onPopoverButtonClick() {
      if (this.showOnClick) this.setPopoverShow(true)
    },
    mouseover() {
      if (this.showOnHover) this.setPopoverShow(true)
    },
    mouseleave() {
      if (this.hideOnMouseLeave) this.setPopoverShow(false)
    },
    popoverClick() {
      if (this.hideOnClick) this.setPopoverShow(false)
    },
    onClickOutside() {
      if (this.hideOnClickOutside) this.setPopoverShow(false)
    },
    addOnClickListener() {
      this.removeOnClickListener()
      this.onClickListener = (event) => {
        let isInsidePopover = event.target.closest(`#mp-popover-${this.id}`)
        if (!isInsidePopover) this.onClickOutside()
      }
      window.addEventListener('click', this.onClickListener)
    },
    removeOnClickListener() {
      if (this.onClickListener) {
        window.removeEventListener('click', this.onClickListener)
        this.onClickListener = null
      }
    },
  },
  watch: {
    popoverShow(isShow) {
      isShow ? this.addOnClickListener() : this.removeOnClickListener()
    },
  },
  destroyed() {
    this.removeOnClickListener()
  },
  mounted() {
    this.setPopoverShow = _.debounce(function (popoverShow) {
      this.popoverShow = popoverShow
    }, 100)
    this.id = this._uid
  },
}
</script>

<style lang="scss" scoped>
.mp-popover-button {
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.mp-popover-extra {
  position: absolute;
  height: 0.5rem;
  width: 100%;
  top: -0.55rem;
  left: 0;
}
</style>

<style lang="scss">
.mp-popover {
  &.sortable-ghost {
    opacity: 0;
  }
  .popover-body {
    padding: 0;
  }
  &--green {
    .bs-popover-top .arrow::after {
      border-top-color: #eef5e7;
    }

    .bs-popover-bottom .arrow::after {
      border-bottom-color: #eef5e7;
    }

    .popover {
      background-color: #eef5e7;
    }
  }
  &--red {
    .bs-popover-top .arrow::after {
      border-top-color: #fa6e6a;
    }

    .bs-popover-bottom .arrow::after {
      border-bottom-color: #fa6e6a;
    }

    .popover {
      background-color: #fa6e6a;
    }

    .popover-body {
      color: #fff;
    }
  }
  &--white {
    .popover {
      border: 1px solid #e5e5e5;
      outline: none;
      margin-top: 0.55rem;
      background-color: #fff;
    }
    .bs-popover-top .arrow::after {
      border-top-color: #fff;
    }

    .bs-popover-bottom .arrow::after {
      border-bottom-color: #fff;
    }
  }
}
</style>
