<template>
  <div class="recipe-notes col-12">
    <h3 class="font-weight-bold">Personal Notes</h3>
    <div class="media mt-4 mb-5">
      <span class="author-image d-none d-md-inline">
        <MaleIcon width="50" height="50" />
      </span>
      <div class="media-body">
        <div
          v-for="(note, $index) in notes"
          :key="$index"
          class="position-relative"
        >
          <PostEditor
            v-model="note.text"
            place-holder="Add a private note about this recipe…"
            action-text="Save note"
            @onActionClick="saveNote(note)"
            :show-icon="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostEditor from './PostEditor'
import { MaleIcon } from '@/components/common/MPIcons'

export default {
  components: {
    PostEditor,
    MaleIcon,
  },
  props: {
    notes: {
      default: () => [{ text: '' }],
    },
  },
  data() {
    return {
      localNotes: [],
    }
  },
  methods: {
    saveNote(note) {
      this.$emit('save-note', { ...note })
    },
    resetLocalNotes() {
      this.localNotes = this.notes.map((n) => ({ ...{ n } }))
    },
  },
  watch: {
    notes: {
      handler: function () {
        this.resetLocalNotes()
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.recipe-notes {
  padding: 0 15px;
}

.author-image {
  display: inline-block;
  border-radius: 50%;
  margin-right: 1.4375rem;
  color: $clr-navy-50;
  margin-top: 3px;
}
</style>
