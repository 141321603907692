<template>
  <div class="password-change-form">
    <MPAlert
      :text="errorMessage"
      type="error"
      :is-dismissable="true"
      @dismiss="dismissError()"
    ></MPAlert>
    <h1>Change your password</h1>
    <p class="text-center">Please enter your new password below.</p>
    <form @submit.prevent="checkEmail">
      <PasswordInput v-model="password" />
      <MPButton type="submit" color="primary" size="large" classes="col-12">
        Update password
      </MPButton>
    </form>
  </div>
</template>

<script>
import { MPAlert, MPButton } from '@/components/common'
import PasswordInput from '@/components/credentials/PasswordInput'
import credentialsHandler from '@/handlers/CredentialsHandler'
import LoadingMixin from '@/mixins/LoadingMixin'

export default {
  mixins: [LoadingMixin],
  components: {
    MPAlert,
    MPButton,
    PasswordInput,
  },
  name: 'PasswordChangeForm',
  data() {
    return {
      password: '',
      errorMessage: '',
    }
  },
  methods: {
    checkEmail() {
      this.setLoading(true)
      this.errorMessage = ''
      credentialsHandler
        .changePassword(
          this.$route.params.uid,
          this.$route.params.token,
          this.password
        )
        .then((response) => {
          const email = response.email
          this.$analytics.track('mp changed password', {
            email: email,
          })
          credentialsHandler
            .handleLogin(
              this.$store,
              window.location.href,
              email,
              this.password
            )
            .then(
              () => {
                this.setLoading(false)
                this.$emit('onLoggedIn')
              },
              (err) => {
                // TODO error handling
                console.error('Login failed: ', err)
                this.setLoading(false)
              }
            )
        })
        .catch(() => {
          this.setLoading(false)
          this.errorMessage = `Oops, looks like something went wrong. <a  href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
        })
    },
    dismissError() {
      this.errorMessage = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.password-change-form {
  h1 {
    text-align: center;
    margin: 2rem 0;
  }
  .material-input {
    margin-bottom: 1rem;
  }
}
.forgot-password {
  font-size: 12px;
  font-weight: 400;
  margin-top: 1rem;
  text-align: center;
}
</style>
