import apiSettings from '@/api/settings.js'
import * as types from '../mutation-types'
import moment from 'moment'

// initial state
const state = {
  info: null,
  commissions: null,
  commissionsDate: null,
  isLoading: false,
}

const stateTypes = ['pending', 'qualified', 'processing', 'paid', 'cancelled']

// getters
const getters = {
  commissions: (store) => {
    if (store.commissions) {
      return store.commissions.map((c) => ({
        date: moment(c.dateCreated, 'mm/dd'),
        userName: c.userName,
        isNew: c.sequence == 1,
        stateDisplay: c.stateDisplay,
        stateType: stateTypes[c.state],
        type: c.type,
        amount: c.amount,
        sequence: c.sequence,
      }))
    }
    return []
  },
  isLoading: (state) => state.isLoading,
}

// actions
const actions = {
  refresh({ dispatch }) {
    return dispatch('getInfo')
  },
  getInfo({ commit }) {
    commit(types.SET_LOADING, true)
    return apiSettings
      .getAffiliateInfo()
      .then((info) => {
        commit(types.SET_AFFILIATE_INFO, info)
        commit(types.SET_LOADING, false)
      })
      .catch(() => commit(types.SET_LOADING, false))
  },
  getCommissions({ commit }, date) {
    commit(types.SET_COMMISSIONS_DATE, date)
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return apiSettings
      .getAffiliateCommissions(month, year)
      .then(({ commissions }) =>
        commit(types.SET_AFFILIATE_COMMISSIONS, commissions)
      )
  },
}

// mutations
const mutations = {
  [types.SET_COMMISSIONS_DATE](state, date) {
    state.commissionsDate = date
  },
  [types.SET_AFFILIATE_INFO](state, info) {
    state.info = info
  },
  [types.SET_AFFILIATE_COMMISSIONS](state, commissions) {
    state.commissions = commissions
  },
  [types.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
