<template>
  <MPPopover
    :hide-on-mouse-leave="false"
    :show-on-hover="false"
    :hide-on-click="false"
  >
    <div class="serving-button" slot="button">
      <Servings class="recipe-action-icon icon-servings mr-2" width="25" />
      <span class="serving-text">
        <span v-html="recipe.servings"></span>
        {{ $tc('Serving', recipe.servings) }}
      </span>
    </div>
    <div class="serving-popover" slot="popover">
      <h5 class="serving-popover-title h4">
        {{ adjustServings.title }}
      </h5>
      <div
        class="serving-action"
        v-for="(serving, $index) in adjustServings.servings"
        :key="$index"
      >
        <span class="serving-action-label">
          {{ serving.label }}
        </span>
        <button
          class="serving-action-button serving-action-button--minus"
          @click="decrease($index)"
          v-show="canDecrease(localServings[$index])"
        >
          –
        </button>
        <MPPopover v-show="!canDecrease(localServings[$index])">
          <span
            class="serving-action-button serving-action-button--minus serving-action-button--disable"
            slot="button"
          ></span>
          <div slot="popover" class="p-2">
            To remove a recipe, please do so from the meal planning dashboard.
          </div>
        </MPPopover>
        <span
          class="serving-action-text"
          v-html="toFraction(localServings[$index])"
        ></span>
        <button
          class="serving-action-button serving-action-button--plus"
          @click="increase($index)"
        >
          +
        </button>
      </div>
      <span
        class="serving-action-description"
        v-if="adjustServings.description"
      >
        {{ adjustServings.description }}
      </span>
      <div class="serving-apply-wrapper">
        <MPButton
          :disabled="!areServingsChanged"
          class="serving-apply"
          color="primary"
          size="full-width"
          @click="save"
        >
          Apply Servings
        </MPButton>
      </div>
    </div>
  </MPPopover>
</template>

<script>
import IngredientHelpers from '@/IngredientHelpers'
import * as _ from 'lodash'
import { Servings } from '@/components/common/MPIcons'

export default {
  data() {
    return {
      localServings: [],
    }
  },
  components: {
    Servings,
  },
  props: ['recipe', 'adjustServings'],
  methods: {
    save() {
      this.$emit('save', this.localServings)
    },
    canDecrease(servings) {
      return servings > this.adjustServings.minimumBatchServings
    },
    toFraction(servings) {
      return IngredientHelpers.toFraction(servings)
    },
    decrease(key) {
      const newVal =
        this.localServings[key] - this.adjustServings.minimumBatchServings

      this.$set(this.localServings, key, newVal)
    },
    increase(key) {
      const newVal =
        this.localServings[key] + this.adjustServings.minimumBatchServings
      this.$set(this.localServings, key, newVal)
    },
    areServingsChanged() {
      const changedServings = this.adjustServings.servings
        ? this.adjustServings.servings.map((s) => s.value)
        : null
      return !!this.recipe && !_.isEqual(this.localServings, changedServings)
    },
  },
  watch: {
    adjustServings: {
      handler: function () {
        this.localServings = this.adjustServings.servings
          ? this.adjustServings.servings.map((s) => s.value)
          : []
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.serving-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  @include media-breakpoint-up(md) {
    height: 60px;
  }
}

.serving-text {
  &::after {
    border: inset 5px;
    content: '';
    height: 0;
    width: 0;
    border-color: $link-color transparent transparent transparent;
    border-top-style: solid;
    display: inline-block;
    margin-left: 0.2rem;
    position: relative;
    top: 3px;
  }
}

.serving-button:hover {
  .serving-icon {
    background-image: url('~@/assets/icons/recipe-servings-hover.svg');
  }
  .serving-text {
    color: $link-hover-color;
    &::after {
      border-color: $link-hover-color transparent transparent transparent;
    }
  }
}

.serving-popover {
  padding: 0;
  min-width: 220px;
}

.serving-popover-title {
  padding-bottom: 0.7rem;
  margin: 0;
  text-align: center;
  padding: 15px;
}

.serving-action {
  display: flex;
  padding: 13px 15px;
}

.serving-action-label {
  flex: 1;
  padding-right: 0.7rem;
}

.serving-action-description {
  display: block;
  padding-top: 0.5rem;
  font-size: rem(12);
}

.serving-action-button {
  display: inline-block;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $link-color;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  margin: 0;
  text-align: center;
  &:hover {
    background-color: $link-hover-color;
  }
}
.serving-action-button--plus {
  line-height: 27px;
}

.serving-action-button--disable {
  opacity: 0.3;
}

.serving-action-text {
  width: 40px;
  text-align: center;
}

.serving-apply-wrapper {
  margin-top: 20px;
  padding: 15px;
  border-top: 1px solid $clr-navy-15;
}
</style>
