import api from './'
import camelizeKeys from './camelizeKeys'

const TRACK_AFF_CLICK = '/core/track-aff-click/'
const ADD_TO_CAMPAIGN = '/core/add-to-campaign/'
const CHECK_AFFILIATE_CODE = '/core/check-aff-code/'
const CHECK_PROMO_OFFER_CODE = '/core/check-promo-code/'
const UNSUBSCRIBE_FROM_WEEKLY_EMAILS = '/core/unsubscribe-from-weekly-emails/'
const RESUBSCRIBE_TO_WEEKLY_EMAILS = '/core/resubscribe-to-weekly-emails/'
const UNSUBSCRIBE_FROM_FEATURE_EMAILS =
  '/core/unsubscribe-from-features-emails/'
const RESUBSCRIBE_TO_FEATURE_EMAILS = '/core/resubscribe-to-features-emails/'
const UNSUBSCRIBE_FROM_GENERAL_EMAILS = '/core/unsubscribe-from-general-emails/'
const RESUBSCRIBE_TO_GENERAL_EMAILS = '/core/resubscribe-to-general-emails/'
const SUBSCRIPTION_INFO_FROM_TOKEN = '/core/subscription-info-from-token/'
const PHYSICIANS_LIST = '/core/physicians-list/'
const ADD_PHYSICIAN = '/core/add-physician/'

export default {
  trackAffiliateLinkClick(code, valid) {
    const payload = {
      ref: code,
      valid: valid,
    }
    return api.post(TRACK_AFF_CLICK, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  checkAffiliateCode(code) {
    const payload = {
      ref: code,
    }
    return api.post(CHECK_AFFILIATE_CODE, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  checkPromoOfferCode(code) {
    return api.post(CHECK_PROMO_OFFER_CODE, { offer: code }).then((res) => {
      return res.data
    })
  },
  addToCampaign(campaignCode) {
    const payload = {
      campaign: campaignCode,
    }
    return api.post(ADD_TO_CAMPAIGN, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  unsubscribeWeeklyEmails(token) {
    return api.get(`${UNSUBSCRIBE_FROM_WEEKLY_EMAILS}${token}`).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  resubscribeWeeklyEmails(token) {
    return api.get(`${RESUBSCRIBE_TO_WEEKLY_EMAILS}${token}`).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  unsubscribeFeaturesEmails(token) {
    return api.get(`${UNSUBSCRIBE_FROM_FEATURE_EMAILS}${token}`).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  resubscribeFeaturesEmails(token) {
    return api.get(`${RESUBSCRIBE_TO_FEATURE_EMAILS}${token}`).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  unsubscribeGeneralEmails(token) {
    return api.get(`${UNSUBSCRIBE_FROM_GENERAL_EMAILS}${token}`).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  resubscribeGeneralEmails(token) {
    return api.get(`${RESUBSCRIBE_TO_GENERAL_EMAILS}${token}`).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  subscriptionInfo(token) {
    return api.get(`${SUBSCRIPTION_INFO_FROM_TOKEN}${token}`).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  physiciansList() {
    return api.post(PHYSICIANS_LIST).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  addPhysician(payload) {
    return api.post(ADD_PHYSICIAN, payload).then((res) => {
      return camelizeKeys(res.data)
    })
  },
}
