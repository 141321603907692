import api from './'
import camelizeKeys from './camelizeKeys'

const LOGIN_URL = 'credential/login/'
const LOGIN_WITH_MAGIC_LINK_URL = 'credential/login-magic-link/'
const REGISTER_URL = 'credential/create-user/'
const FACEBOOK_LOGIN_URL = 'credential/facebook_login/'
const FACEBOOK_REGISTER_URL = 'credential/facebook_register/'
const GOOGLE_LOGIN_URL = 'credential/google_login/'
const APPLE_LOGIN_URL = 'credential/apple_login/'
const GOOGLE_REGISTER_URL = 'credential/google_register/'
const APPLE_REGISTER_URL = 'credential/apple_register/'
const CAN_CREATE_ACCOUNT_URL = 'credential/can-create-account/'
// const NEW_RELEASE_SEEN = 'credential/new-release-seen'
const SAVE_PREFERENCES = 'credential/save-preferences/'
const ACTIVATE_PLAN = 'credential/activate-plan/'

const USER_DATA_URL = 'credential/user_data/'
const USER_MESSAGES_URL = 'credential/user-messages/'
// const USER_INFO_URL = "credential/info/";
const DELETE_USER_ACCOUNT_URL = 'credential/delete-user-account/'
const CHECK_EMAIL_ALREADY_EXISTS = 'core/check-email-already-exists/'
const VERIFY_EMAIL = 'credential/verify-email/'
const VERIFY_FACEBOOK_ID = 'credential/verify-facebook-id/'
const RESET_PASSWORD = 'core/reset-password/'
const CHANGE_PASSWORD = 'core/reset-password-confirm/'
const REGISTER_NEW_USER = 'credential/register'

export default {
  getUserData(sync = false) {
    const options = Object.create(null)

    if (sync) {
      options.sync = sync
    }

    return api.post(USER_DATA_URL, options).then((res) => {
      return camelizeKeys(res.data.user)
    })
  },
  getUserMessages() {
    return api.get(USER_MESSAGES_URL).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  deleteUser() {
    return api.post(DELETE_USER_ACCOUNT_URL)
  },
  login(email, password, affiliateRefCode, campaign) {
    return api
      .post(LOGIN_URL, {
        email: email,
        password: password,
        aff_ref: affiliateRefCode,
        campaign: campaign,
      })
      .then((res) => camelizeKeys(res.data))
  },
  loginWithMagicLink(magicLink, affiliateRefCode, campaign) {
    return api
      .post(LOGIN_WITH_MAGIC_LINK_URL, {
        magic_link: magicLink,
        aff_ref: affiliateRefCode,
        campaign: campaign,
      })
      .then((res) => camelizeKeys(res.data))
  },
  facebookLogin(facebookUserId, facebookUserEmail, affiliateRefCode, campaign) {
    return api
      .post(FACEBOOK_LOGIN_URL, {
        facebook_user_id: facebookUserId,
        facebook_user_email: facebookUserEmail,
        aff_ref: affiliateRefCode,
        campaign: campaign,
      })
      .then((res) => camelizeKeys(res.data))
  },
  facebookRegister(
    email,
    facebookId,
    fullName,
    photoUrl,
    affiliateRefCode,
    impactPartnerId,
    ambassadorId,
    campaign,
    abGroup,
    giftCode,
    adultServings,
    childServings,
    additionalIntolerances,
    soyIntolerance,
    nutsIntolerance,
    glutenIntolerance,
    breakfastOptin,
    lunchOptin,
    dinnerOptin,
    snackOptin,
    dessertOptin,
    token,
    plan,
    userAgent,
    browserIp
  ) {
    return api
      .post(FACEBOOK_REGISTER_URL, {
        email: email,
        facebook_id: facebookId,
        full_name: fullName,
        photo: photoUrl,
        aff_ref: affiliateRefCode,
        irpid: impactPartnerId,
        mbsy: ambassadorId,
        campaign: campaign,
        abgroup: abGroup,
        gift_code: giftCode,
        full_servings: adultServings,
        half_servings: childServings,
        additional_intolerances: additionalIntolerances,
        soy_intolerance: soyIntolerance,
        nut_intolerance: nutsIntolerance,
        gluten_intolerance: glutenIntolerance,
        breakfast_opt_in: breakfastOptin,
        lunch_opt_in: lunchOptin,
        dinner_opt_in: dinnerOptin,
        snack_opt_in: snackOptin,
        dessert_opt_in: dessertOptin,
        token: token,
        plan: plan,
        user_agent: userAgent,
        browser_ip: browserIp,
      })
      .then((res) => camelizeKeys(res.data))
  },
  googleLogin(
    googleUserId,
    googleUserEmail,
    accessToken,
    affiliateRefCode,
    campaign
  ) {
    return api
      .post(GOOGLE_LOGIN_URL, {
        google_user_id: googleUserId,
        google_user_email: googleUserEmail,
        access_token: accessToken,
        aff_ref: affiliateRefCode,
        campaign: campaign,
      })
      .then((res) => camelizeKeys(res.data))
  },
  appleLogin(
    uid,
    email,
    fullName,
    accessToken,
    idToken,
    affiliateRefCode,
    campaign
  ) {
    const data = {
      apple_user_id: uid,
      apple_user_email: email,
      full_name: fullName,
      access_token: accessToken,
      id_token: idToken,
      aff_ref: affiliateRefCode,
      campaign: campaign,
    }
    return api.post(APPLE_LOGIN_URL, data).then((res) => camelizeKeys(res.data))
  },
  googleRegister(
    email,
    googleId,
    fullName,
    photoUrl,
    affiliateRefCode,
    impactPartnerId,
    ambassadorId,
    campaign,
    abGroup,
    giftCode,
    adultServings,
    childServings,
    additionalIntolerances,
    soyIntolerance,
    nutsIntolerance,
    glutenIntolerance,
    breakfastOptin,
    lunchOptin,
    dinnerOptin,
    snackOptin,
    dessertOptin,
    token,
    plan,
    userAgent,
    browserIp
  ) {
    return api
      .post(GOOGLE_REGISTER_URL, {
        email: email,
        google_id: googleId,
        full_name: fullName,
        photo: photoUrl,
        aff_ref: affiliateRefCode,
        irpid: impactPartnerId,
        mbsy: ambassadorId,
        campaign: campaign,
        abgroup: abGroup,
        gift_code: giftCode,
        full_servings: adultServings,
        half_servings: childServings,
        additional_intolerances: additionalIntolerances,
        soy_intolerance: soyIntolerance,
        nut_intolerance: nutsIntolerance,
        gluten_intolerance: glutenIntolerance,
        breakfast_opt_in: breakfastOptin,
        lunch_opt_in: lunchOptin,
        dinner_opt_in: dinnerOptin,
        snack_opt_in: snackOptin,
        dessert_opt_in: dessertOptin,
        token: token,
        plan: plan,
        user_agent: userAgent,
        browser_ip: browserIp,
      })
      .then((res) => camelizeKeys(res.data))
  },
  appleRegister(
    email,
    appleId,
    fullName,
    photoUrl,
    affiliateRefCode,
    impactPartnerId,
    ambassadorId,
    campaign,
    abGroup,
    giftCode,
    adultServings,
    childServings,
    additionalIntolerances,
    soyIntolerance,
    nutsIntolerance,
    glutenIntolerance,
    breakfastOptin,
    lunchOptin,
    dinnerOptin,
    snackOptin,
    dessertOptin,
    token,
    plan,
    userAgent,
    browserIp
  ) {
    return api
      .post(APPLE_REGISTER_URL, {
        email: email,
        apple_id: appleId,
        full_name: fullName,
        photo: photoUrl,
        aff_ref: affiliateRefCode,
        irpid: impactPartnerId,
        mbsy: ambassadorId,
        campaign: campaign,
        abgroup: abGroup,
        gift_code: giftCode,
        full_servings: adultServings,
        half_servings: childServings,
        additional_intolerances: additionalIntolerances,
        soy_intolerance: soyIntolerance,
        nut_intolerance: nutsIntolerance,
        gluten_intolerance: glutenIntolerance,
        breakfast_opt_in: breakfastOptin,
        lunch_opt_in: lunchOptin,
        dinner_opt_in: dinnerOptin,
        snack_opt_in: snackOptin,
        dessert_opt_in: dessertOptin,
        token: token,
        plan: plan,
        user_agent: userAgent,
        browser_ip: browserIp,
      })
      .then((res) => camelizeKeys(res.data))
  },
  register(params) {
    return api.post(REGISTER_URL, params).then((res) => camelizeKeys(res.data))
  },
  savePreferences(
    email,
    fullServings,
    halfServings,
    additionalIntolerances,
    generalEmailsOptin,
    soyIntolerance,
    nutsIntolerance,
    glutenIntolerance,
    breakfastOptin,
    lunchOptin,
    dinnerOptin,
    snackOptin,
    dessertOptin,
    pressureCookerOptin
  ) {
    const userPreferences = {
      full_servings: fullServings,
      half_servings: halfServings,
      additional_intolerances: additionalIntolerances,
      general_emails: generalEmailsOptin,
      soy_intolerance: soyIntolerance,
      nut_intolerance: nutsIntolerance,
      gluten_intolerance: glutenIntolerance,
      breakfast_opt_in: breakfastOptin,
      lunch_opt_in: lunchOptin,
      dinner_opt_in: dinnerOptin,
      snack_opt_in: snackOptin,
      dessert_opt_in: dessertOptin,
      use_pressure_cooker: pressureCookerOptin,
    }
    return api
      .post(SAVE_PREFERENCES, {
        email: email,
        user_preferences: userPreferences,
      })
      .then((res) => camelizeKeys(res.data))
  },
  activatePlan(email, giftCode) {
    return api
      .post(ACTIVATE_PLAN, { email: email, gift_code: giftCode })
      .then((res) => camelizeKeys(res.data))
  },
  checkEmailExists(email) {
    return api
      .get(CHECK_EMAIL_ALREADY_EXISTS, { email: email })
      .then((res) => camelizeKeys(res.data))
  },
  verifyEmail(email) {
    return api
      .post(VERIFY_EMAIL, { email: email })
      .then((res) => camelizeKeys(res.data))
  },
  canCreateAccount(email) {
    return api
      .post(CAN_CREATE_ACCOUNT_URL, { email: email })
      .then((res) => camelizeKeys(res.data))
  },
  resetPassword(email) {
    return api
      .post(RESET_PASSWORD, { email: email })
      .then((res) => camelizeKeys(res.data))
  },
  changePassword(uid, token, password) {
    return api
      .post(CHANGE_PASSWORD, { uid: uid, token: token, new_password: password })
      .then((res) => camelizeKeys(res.data))
  },
  logout() {
    api.setAccessToken(null)
    localStorage.removeItem('applied-filters')
  },
  async verifyFacebookId(facebook_id) {
    try {
      const response = await api.post(VERIFY_FACEBOOK_ID, {
        facebook_id: facebook_id,
      })
      return response
    } catch (error) {
      const { data } = error.response
      throw data
    }
  },
  async createFreeUser(full_name, email, password, suscribe, preferences) {
    try {
      const response = await api.post(REGISTER_NEW_USER, {
        full_name,
        email,
        password,
        suscribe,
        preferences,
      })

      return response
    } catch (error) {
      throw error.response
    }
  },
}
