<template>
  <div class="step">
    <span v-if="number" class="step-number">{{ number }}</span>
    <div class="d-flex align-items-top">
      <span v-if="title" class="step-title">{{ title }}</span>
      <SpeedPrepIconComponent
        v-if="isSpeedPrep"
        tooltip-button-text="Got it"
        class="ml-1"
      >
        <template slot="content">
          This step can be prepared in advance for the week ahead to save time.
          You can find the instructions in Weekend Prep
        </template>
      </SpeedPrepIconComponent>
    </div>
    <span
      v-if="description"
      class="step-description text-serif"
      :class="{ 'step-description--no-title': !title }"
      v-html="description"
    ></span>
  </div>
</template>

<script>
import SpeedPrepIconComponent from '@/components/weekendprep/SpeedPrepIconComponent'
export default {
  components: { SpeedPrepIconComponent },
  props: {
    number: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    isSpeedPrep: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 0 0 20px 0;
  @include media-breakpoint-up(lg) {
    padding-left: 1.875rem;
  }
}

.step-number {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  width: 21px;
  height: 21px;

  position: absolute;
  left: 0;
  background-color: $body-color;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  @include media-breakpoint-down(md) {
    width: 32px;
    height: 32px;
    font-size: 1rem;
  }
}

.step-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: rem(8);
  text-transform: uppercase;
  @include media-breakpoint-down(md) {
    margin-left: 42px;
    line-height: 32px;
    margin-bottom: rem(12);
  }
}

.step-description--no-title {
  margin-top: -3px;
  @include media-breakpoint-down(md) {
    margin-left: 42px;
    margin-top: 2px;
  }
}
</style>
<style lang="scss">
.step {
  .speed-prep-icon {
    margin-top: 3px;
  }
}
</style>
