var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",class:{
    'card--add-recipe': !!_vm.recipe.addRecipeStatus,
  },on:{"click":function($event){$event.stopPropagation();return _vm.onClick(_vm.recipe.url, $event)}}},[_c('div',{staticClass:"top-container"},[_c('div',{staticClass:"image-content",class:{ 'image-content--lock': _vm.recipe.isLocked },style:({ 'background-image': `url(${_vm.recipe.thumb})` })}),(_vm.showNewBatch)?_c('span',{staticClass:"badge-new"},[_vm._v(" NEW ")]):_vm._e(),(!_vm.recipe.isLocked)?_c('span',{staticClass:"card-badge card-badge--favorite",class:{
        'card-badge--red-heart': _vm.recipe.isFavorite,
        'card-badge--gray-heart': !_vm.recipe.isFavorite,
      },on:{"click":function($event){$event.stopPropagation();return _vm.onFavoriteToggleClick.apply(null, arguments)}}}):_vm._e(),(_vm.recipe.isLocked)?_c('span',{staticClass:"lock-badge card-badge--lock"}):_vm._e(),(_vm.recipe.isCustom)?_c('span',{staticClass:"user-recipe-icon"}):_vm._e(),(_vm.recipe.isBonusRecipe)?_c('span',{staticClass:"badge-bonus"}):_vm._e()]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card-top mb-md-2"},[_c('div',{staticClass:"title-container"},[_c('span',{staticClass:"category d-none d-md-block"},[_vm._v(" "+_vm._s(_vm.recipe.category)+" ")]),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.recipe.title)+" ")]),(_vm.recipe.subtitle)?_c('span',{staticClass:"sub-title text-truncate"},[_vm._v(" "+_vm._s(_vm.recipe.subtitle)+" ")]):_vm._e()]),_c('span',{staticClass:"card-badge card-badge--info d-md-none",class:{
          'card-badge--red-heart': _vm.recipe.isFavorite,
          'card-badge--gray-heart': !_vm.recipe.isFxavorite,
          'card-badge--disable': _vm.recipe.isLocked,
        },on:{"click":function($event){$event.stopPropagation();return _vm.onFavoriteToggleClick.apply(null, arguments)}}})]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"info-left"},[_c('div',{staticClass:"info-rating-wrapper"},[(_vm.recipe.rating && !_vm.recipe.isLocked)?_c('span',{staticClass:"star"}):_vm._e(),(_vm.recipe.rating && !_vm.recipe.isLocked)?_c('span',{staticClass:"info-rating"},[_vm._v(" "+_vm._s(_vm.recipe.generalRating)+" ")]):_vm._e(),(_vm.recipe.rating && !_vm.recipe.isLocked)?_c('span',{staticClass:"info-rating-count"},[_vm._v(" ("+_vm._s(_vm.recipe.ratingCount)+") ")]):_vm._e()]),_c('span',{staticClass:"category text-truncate mr-2 d-md-none"},[_vm._v(" "+_vm._s(_vm.recipe.category)+" ")]),_c('div',{staticClass:"info-right"},[_c('span',{staticClass:"clock"}),_c('span',{staticClass:"prepare-time text-truncate"},[_vm._v(" "+_vm._s(_vm.recipe.prepTime)+" ")]),_c('span',{staticClass:"prepare-time prepare-time--small text-truncate"},[_vm._v(" "+_vm._s(_vm.recipe.prepTimeSmall)+" ")]),(_vm.recipe.pressureCooker)?_c('span',{staticClass:"pressure-cooker-icon"}):_vm._e()])]),_c('RecipeActionButton',{attrs:{"status":_vm.recipe.addRecipeStatus},on:{"addRecipe":_vm.addRecipe,"upgrade":_vm.upgrade}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }