function getDefaultTagValues() {
  return {
    included: [],
    excluded: [],
  }
}

function getDefaultFilterValues() {
  return {
    included: '',
    excluded: '',
  }
}

function getDefaultSortByItem() {
  return {
    name: 'Newest first',
    sortAction: 'newest-first',
    default: true,
  }
}

function getSortByItems() {
  return [
    getDefaultSortByItem(),
    {
      name: 'Oldest first',
      sortAction: 'oldest-first',
    },
    {
      name: 'Prep time',
      sortAction: 'prep-time',
    },
    {
      name: 'Highest rated',
      sortAction: 'highest-rated',
    },
    {
      name: 'Category',
      sortAction: 'category',
    },
    {
      name: 'Alphabetical',
      sortAction: 'alphabetical',
    },
  ]
}

function getMealTypeItems(recipeCategories = []) {
  return [
    ...recipeCategories.map((category) => ({
      ...category,
      name: category.name,
      propertyName: category.id,
    })),
  ]
}

function getFilterByItems() {
  return [
    {
      name: 'Kid Approved',
      propertyName: 'kid_approved',
      propertyValue: true,
      filterAction: 'kid-approved',
    },
    {
      name: 'No weekend prep',
      propertyName: 'has_weekend_prep',
      propertyValue: false,
      filterAction: 'has-weekend-prep',
    },
  ]
}

function getFilterByTypeItems() {
  return [
    {
      name: 'My Favorites',
      propertyName: 'favorite',
      propertyValue: true,
      filterAction: 'is-favorite',
      iconSrc: require('@/assets/icons/filtering/favorite-copy.svg'),
      activeIconSrc: require('@/assets/icons/filtering/favorite-active.svg'),
      trackName: 'mp favorites view',
    },
    {
      name: 'Personal Recipes',
      propertyName: 'is_custom',
      propertyValue: true,
      filterAction: 'is-personal',
      iconSrc: require('@/assets/icons/chef.png'),
      activeIconSrc: require('@/assets/icons/chef-active.png'),
    },
  ]
}

function getIntervalList(
  minValue = { value: 0, name: '' },
  maxValue = { value: 100, name: '' },
  interval = 10,
  getName
) {
  const values = []

  for (let i = minValue.value; i <= maxValue.value; i = i + interval) {
    values.push({ value: i, name: getName({ maxValue, minValue, value: i }) })
  }

  return values
}

function getPrepTimeItems() {
  return getIntervalList(
    { value: 10 },
    { value: 60 },
    { value: 10 },
    ({ value }) => `${value} minutes`
  )
}

function getCaloriesValues() {
  const sliderValues = {
    minValue: { value: 300, name: '300 or less' },
    maxValue: { value: 700, name: '700 or more' },
    interval: 25,
  }

  const getCaloriesSliderNames = ({ maxValue, minValue, value }) =>
    value == minValue.value
      ? minValue.name
      : maxValue.value == value
      ? maxValue.name
      : `up to ${value}`

  const values = getIntervalList(
    sliderValues.minValue,
    sliderValues.maxValue,
    sliderValues.interval,
    getCaloriesSliderNames
  )

  return {
    ...sliderValues,
    values,
  }
}

function getNumberOfIngredientsItems() {
  return [3, 7, 9, 12, 15].map((value) => ({
    name: `${value} ingredients`,
    value: value,
  }))
}

function getRatingItems() {
  return [
    {
      name: 'Any Rating',
      value: 1,
    },
    {
      name: '4+',
      value: 4,
      icon: require('@/assets/icons/filtering/ratings-4.svg'),
      iconActive: require('@/assets/icons/filtering/ratings-4-active.svg'),
    },
    {
      name: '3+',
      value: 3,
      icon: require('@/assets/icons/filtering/ratings-3.svg'),
      iconActive: require('@/assets/icons/filtering/ratings-3-active.svg'),
    },
    {
      name: '2+',
      value: 2,
      icon: require('@/assets/icons/filtering/ratings-2.svg'),
      iconActive: require('@/assets/icons/filtering/ratings-2-active.svg'),
    },
  ]
}

export default {
  getDefaultFilterByTypeItem: () => ({}),
  getFilterByTypeItems,
  getDefaultTagValues,
  getDefaultFilterByItem: () => ({}),
  getDefaultSortByItem,
  getSortByItems,
  getMealTypeItems,
  getFilterByItems,
  getPrepTimeItems,
  getDefaultFilterValues,
  getNumberOfIngredientsItems,
  getRatingItems,
  prepTimeDefault: 120,
  filterByRatingDefault: 0,
  ingredientsCountDefault: 20,
  caloriesDefault: (() => {
    const caloriesList = getCaloriesValues().values
    return caloriesList[caloriesList.length - 1]
  })(),
  getCaloriesValues,
}
