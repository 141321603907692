<template>
  <article class="lea">
    <!--    <header class="lea-header">-->
    <!--      <div class="row">-->
    <!--        <div class="col lea-header-content">-->
    <!--          <a class="lea-logo">-->
    <!--            <img-->
    <!--              class="lea-logo-img"-->
    <!--              :src="require('@/assets/icons/forks-meal-planner-logo.svg')"-->
    <!--            />-->
    <!--          </a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </header>-->
    <!--    <section class="lea-title-section">-->
    <!--      <img-->
    <!--        class="lea-plate-l"-->
    <!--        :src="require('@/assets/images/landing-side-plate-l@2x.png')"-->
    <!--      />-->
    <!--      <div class="container">-->
    <!--        <div class="row">-->
    <!--          <div class="col lea-title-section-content">-->
    <!--            <span class="lea-title">-->
    <!--              A whole-food, plant-based diet helped Eric Adams manage his health-->
    <!--              and diabetes, it can help you too!-->
    <!--            </span>-->
    <!--            <span class="lea-subtitle">-->
    <!--              Forks Meal Planner gives you all the tools you need to put-->
    <!--              delicious, healthful meals on the table in 35 minutes or less.-->
    <!--            </span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="lea-features lea-features&#45;&#45;top row d-none d-md-flex">-->
    <!--          <div-->
    <!--            class="lea-feature col-md-4"-->
    <!--            :class="{ '`lea-feature&#45;&#45;${feature.id}`': feature.id }"-->
    <!--            v-for="feature in featuresWithPhotos"-->
    <!--            :key="feature.index"-->
    <!--          >-->
    <!--            <div class="lea-feature-img">-->
    <!--              <img :src="feature.img" />-->
    <!--            </div>-->
    <!--            <span class="lea-feature-title" v-html="feature.title"></span>-->
    <!--            <span-->
    <!--              class="lea-feature-description"-->
    <!--              v-html="feature.description"-->
    <!--            ></span>-->
    <!--          </div>-->
    <!--          <img-->
    <!--            class="lea-plate-r"-->
    <!--            :src="require('@/assets/images/landing-side-plate-r@2x.png')"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
    <!--    <section class="lea-form-section">-->
    <!--      <div class="row">-->
    <!--        <div class="col">-->
    <!--          <RegisterCredentialsForm-->
    <!--            @onRegister="onRegister"-->
    <!--            @onFacebookRegister="onFacebookRegister"-->
    <!--          />-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
    <!--    <section class="lea-feature-selection d-flex d-md-none">-->
    <!--      <div class="container">-->
    <!--        <div class="lea-features row">-->
    <!--          <div-->
    <!--            class="lea-feature col-md-4"-->
    <!--            :class="{ '`lea-feature&#45;&#45;${feature.id}`': feature.id }"-->
    <!--            v-for="feature in featuresWithPhotos"-->
    <!--            :key="feature.index"-->
    <!--          >-->
    <!--            <img class="lea-feature-img" :src="feature.img" />-->
    <!--            <span class="lea-feature-title" v-html="feature.title"></span>-->
    <!--            <span-->
    <!--              class="lea-feature-description"-->
    <!--              v-html="feature.description"-->
    <!--            ></span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
    <!--    <section class="lea-quote-selection col">-->
    <!--      <div class="container">-->
    <!--        <div class="row lea-quote-selection-container">-->
    <!--          <div class="col-12 col-md-6 lea-quote-selection-l">-->
    <!--            <img :src="require('@/assets/images/landing-app.png')" />-->
    <!--          </div>-->
    <!--          <div class="col-12 col-md-6 lea-quote-selection-r">-->
    <!--            <div class="lea-quote">-->
    <!--              <span class="lea-quote-text">-->
    <!--                I have been impressed with every single recipe that I have made-->
    <!--                thus far. They are easy to prepare and (this is the best part)-->
    <!--                my husband and 5 year old daughter LOVE the meals! Win win!-->
    <!--                Thank you!-->
    <!--              </span>-->
    <!--              <div class="lea-quote-avatar-container">-->
    <!--                <div>-->
    <!--                  <img-->
    <!--                    class="lea-quote-avatar-img"-->
    <!--                    :src="require('@/assets/images/landing-avatar-dna@2x.png')"-->
    <!--                  />-->
    <!--                </div>-->
    <!--                <span class="lea-quote-avatar">-->
    <!--                  Darci N., Arizona-->
    <!--                </span>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </section>-->
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
// import RegisterCredentialsForm from '@/components/credentials/register/RegisterCredentialsForm'
import credentialsHandler from '@/handlers/CredentialsHandler'
import LoadingMixin from '@/mixins/LoadingMixin'
import env from '@/environment'
import { getFbSdk } from '@/handlers/FBHandler'
import { events } from '@/utils/mixpanel.events'

export default {
  mixins: [LoadingMixin],
  components: {
    // RegisterCredentialsForm
  },
  data() {
    return {
      featuresWithPhotos: [
        {
          img: require('@/assets/images/landing-tasty-meals@2x.png'),
          title: 'TASTY, EASY MEALS',
          description:
            'Receive a mouthwatering meal plan from our chefs weekly. Nut, soy, and gluten intolerances supported.',
          index: 1,
        },
        {
          img: require('@/assets/images/landing-weekend-prep@2x.png'),
          title: 'WEEKEND PREP & GROCERY LIST',
          description:
            'Save hours each week with batch cooking instructions and grocery lists for the week ahead.',
          index: 2,
        },
        {
          id: 'designed-around',
          img: require('@/assets/images/landing-flexible@2x.png'),
          title: 'DESIGNED AROUND YOU',
          description:
            'Friends coming over? Add or remove servings and meals. Your grocery list and prep update automatically.',
          index: 3,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      email: 'registration/email',
      fullName: 'registration/fullName',
      password: 'registration/password',
      generalEmailsOptin: 'registration/generalEmailsOptin',
    }),
  },
  methods: {
    onRegister() {
      this.setLoading(true)
      credentialsHandler
        .handleEmailRegistration(
          this.$store,
          this.email,
          this.fullName,
          this.password,
          this.generalEmailsOptin
        )
        .then(
          () => {
            this.$analytics.track('mp squeeze page account created')
            this.setLoading(false)
            this.$router.replace('/signup')
          },
          () => {
            this.setLoading(false)
          }
        )
    },
    onFacebookRegister() {
      this.setLoading(true)
      const { appId, version } = env.facebook
      getFbSdk({ appId, version }).then((FB) => {
        FB.login(
          (response) => {
            if (response.authResponse) {
              return FB.api(
                '/me',
                { fields: 'id,name,email,picture' },
                (response) => {
                  if (!response || response.error) {
                    return
                  }
                  credentialsHandler
                    .handleFBRegistration(this.$store, response)
                    .then(
                      () => {
                        this.$intercom.trackEvent('FACEBOOK_CONNECT')
                        this.$analytics.track('mp squeeze page account created')
                        this.setLoading(false)
                        this.$router.push('/signup')
                      },
                      (reason) => {
                        this.setLoading(false)
                        if (reason.data && reason.data.detail) {
                          if (
                            reason.data.detail === 'Email already registered.'
                          ) {
                            this.$store.dispatch(
                              'registration/setRegistrationError',
                              `You already have an account. Please <b-link to="${this.loginURL}">log in</b-link>.`
                            )
                          } else {
                            this.$store.dispatch(
                              'registration/setRegistrationError',
                              reason.data.detail
                            )
                          }
                        } else {
                          this.setLoading(false)
                          // usually happens when user closes the window
                          if (
                            reason !==
                            'User cancelled registration or did not fully authorize.'
                          ) {
                            this.$store.dispatch(
                              'registration/setRegistrationError',
                              `Oops, looks like something went wrong. <a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`
                            )
                          }
                        }
                      }
                    )
                }
              )
            } else {
              this.setLoading(false)
              // this.$store.dispatch(
              //   'registration/setRegistrationError',
              //   'User cancelled registration or did not fully authorize.'
              // )
            }
          },
          { scope: 'public_profile, email' }
        )
      })
    },
  },
  mounted() {
    this.$store.dispatch('analytics/track', {
      eventName: events.foksqueezepage.view,
    })
    window.location = 'https://forksoverknives.com/meal-planner/'
    credentialsHandler.getFbSdk()
  },
}
</script>

<style lang="scss" scoped>
// header
.lea-header {
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(116, 126, 139, 0.39);
  position: relative;
  z-index: 1;
}

.lea-header-content {
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.lea-logo {
  max-width: 240px;
  cursor: pointer;
  @include media-breakpoint-up(md) {
    max-width: 309px;
  }
}

.lea-logo-img {
  user-select: none;
}

.lea-plate-l {
  display: none;
  @media only screen and (min-width: 1400px) {
    display: block;
    width: 234px;
    position: absolute;
    left: 0;
    top: -30px;
  }
}

.lea-plate-r {
  display: none;
  @media only screen and (min-width: 1400px) {
    display: block;
    width: 147px;
    position: absolute;
    right: 0;
    z-index: 1;
    bottom: -180px;
  }
}

// title section
.lea-title-section {
  padding: 32px 0;
  background-color: #ffffff;
  position: relative;
}

.lea-title-section-content {
  @include media-breakpoint-up(md) {
    float: none;
    max-width: 690px;
    margin: 0 auto;
  }
}

.lea-title {
  display: block;
  text-align: center;
  font-size: 21px;
  line-height: 1.4;
  font-weight: 500;
  @include media-breakpoint-up(md) {
    font-size: 26px;
  }
}

.lea-subtitle {
  padding-top: 16px;
  display: block;
  text-align: center;
  font-size: 18px;
  line-height: 1.4;
  @include media-breakpoint-up(md) {
    font-size: 21px;
  }
}

// form section
.lea-form-section {
  padding: 28px 0 50px;
  background-color: #f7f9fb;
  position: relative;
}

.lea-form-section-content {
  @include media-breakpoint-up(md) {
    float: none;
    max-width: 445px;
    margin: 0 auto;
  }
}

.lea-form-section::before {
  content: '';
  left: 0;
  width: 96px;
  height: 74px;
  display: block;
  position: absolute;
  background: url('~@/assets/images/landing-leafs.png') no-repeat center;
  background-size: 96px 74px;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: -83px;
  @include media-breakpoint-up(md) {
    top: 10px;
  }
}

.lea-form-title {
  display: block;
  text-align: center;
  font-size: 23px;
  line-height: 1.4;
  font-weight: 500;
  @include media-breakpoint-up(md) {
    font-size: 26px;
  }
}

.button.update.lea-form-submit {
  text-transform: none;
  font-size: 13px;
  margin: 0;
  padding: 1rem 1.5rem 1.0625rem 1.5rem;
  font-weight: 500;
  .lea-form-submit-italic {
    font-style: italic;
  }
}

.button.f-button.lea-form-facebook {
  font-weight: 500;
  font-size: 1rem;
  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }
}

// feature selection
.lea-feature-selection {
  padding-top: 62px;
  background: #ffffff;
}

.lea-features--top {
  margin-top: 52px;
  margin-bottom: 77px;
}

.lea-feature {
  text-align: center;
}

.lea-feature + .lea-feature {
  margin-top: 24px;
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}

.lea-feature-img {
  max-width: 300px;
  display: block;
  margin: 0 auto;
}

.lea-feature--designed-around {
  .lea-feature-title {
    position: relative;
    display: inline-block;
  }

  .lea-feature-title::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: -5px;
    background: url('~@/assets/icons/landing-title-underline.svg') no-repeat
      center;
    width: 37px;
    height: 7px;
  }
}

.lea-feature-title {
  display: block;
  text-align: center;
  margin-top: 16px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: bold;
  color: #9fc975;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

.lea-feature-description {
  display: block;
  text-align: center;
  margin-top: 8px;
  font-size: 18px;
  color: #404852;
  line-height: 1.4;
  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

// quote selection
.lea-quote-selection {
  display: flex;
  flex-direction: column;
  padding: 52px 0 90px;
  background: #ffffff;
}

.lea-quote-selection-container {
  @include media-breakpoint-up(md) {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.lea-quote-selection-l {
  @include media-breakpoint-up(md) {
    max-width: 886px;
  }
}

.lea-quote-selection-r {
  margin-top: 52px;
  @include media-breakpoint-up(md) {
    max-width: 460px;
    flex: 1 0 460px;
  }
}

.lea-quote {
  padding-left: 54px;
  position: relative;
}

.lea-quote::before {
  content: '';
  left: 0;
  top: -4px;
  width: 40px;
  height: 30px;
  display: block;
  position: absolute;
  background: url('~@/assets/images/landing-quotation-mark@2x.png') no-repeat
    center;
  background-size: 36px 32px;
}

.lea-quote-text {
  font-size: 18px;
  line-height: 1.4;
  color: #404852;
  @include media-breakpoint-up(md) {
    font-size: 20px;
  }
}

.lea-quote-avatar-container {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.lea-quote-avatar-img {
  height: 74px;
  margin-right: 13px;
}

.lea-quote-avatar {
  line-height: 1.4;
  font-size: 21px;
  font-weight: 500;
  color: #94c663;
}
</style>
