<template>
  <div id="edit-recipe-ingredient-modal">
    <MPModal :show.sync="showModal" :close-on-backdrop="false">
      <div
        class="row border-bottom justify-content-between px-3 pb-3 align-items-center"
      >
        <h3 class="edit-modal-title m-0 p-0">Edit Ingredient</h3>
        <img src="@/assets/icons/close.svg" @click="showModal = false" />
      </div>
      <transition name="fade">
        <bAlert
          :show="warning.show"
          variant="warning"
          class="mx-4 mt-4 text-center"
        >
          <b>Warning:</b> {{ warning.message }}
        </bAlert>
      </transition>
      <div class="row no-gutters mx-4 mt-4" @keydown="onKeyDown">
        <div class="col mr-1">
          <MPInput
            placeholder="Quantity*"
            type="text"
            size="lg"
            v-model="quantityFraction"
            :name="`quantity`"
            :data-vv-scope="tabScope"
            v-validate="quantityValidation"
            :class="{ invalid: hasQuantityErrors }"
            data-vv-as="Quantity"
          />
          <div class="error-message">
            <span v-if="hasQuantityErrors">{{ quantityError }}</span>
          </div>
        </div>
        <div class="col ml-1">
          <MPAutoSuggest
            class="select-unit"
            :strict-mode="false"
            :suggestions="unitList"
            place-holder="Unit*"
            search-param="name"
            show-param="name"
            v-model="unit"
            :name="`unit`"
            :data-vv-scope="tabScope"
            v-validate:unit="unitValidation"
            :class="{ invalid: hasUnitErrors }"
            data-vv-as="Unit"
          />
          <div class="error-message">
            <span v-if="hasUnitErrors">{{ unitError }}</span>
          </div>
        </div>
      </div>
      <div class="row no-gutters mx-4" @keydown="onKeyDown">
        <div class="col">
          <MPAutoSuggest
            :strict-mode="false"
            :suggestions="ingredientList"
            place-holder="Ingredient*"
            :search-param="quantity > 1 ? 'pluralName' : 'singularName'"
            :show-param="quantity > 1 ? 'pluralName' : 'singularName'"
            v-model="ingredient"
            :name="`ingredient`"
            :data-vv-scope="tabScope"
            v-validate:ingredient="ingredientValidation"
            :class="{ invalid: hasIngredientErrors }"
            data-vv-as="Ingredient"
          />
          <div class="error-message">
            <span v-if="hasIngredientErrors">{{ ingredientError }}</span>
          </div>
        </div>
      </div>
      <div class="row no-gutters mx-4" @keydown="onKeyDown">
        <div class="col">
          <MPAutoSuggest
            :strict-mode="false"
            :suggestions="prepDescriptionList"
            place-holder="Preparation style*"
            search-param="text"
            show-param="text"
            v-model="prepDescription"
            :name="`prepDescription`"
            :data-vv-scope="tabScope"
            v-validate:prepDescription="prepDescriptionValidation"
            :class="{ invalid: hasPrepDescriptionErrors }"
            class="text-ellipsis"
            data-vv-as="Preparation style"
          />
          <div class="error-message">
            <span v-if="hasPrepDescriptionErrors">
              {{ prepDescriptionError }}
            </span>
          </div>
        </div>
      </div>
      <div class="row no-gutters mx-4 mb-3">
        <MPButton
          class="col m-0"
          color="primary"
          size="lg"
          @click="update()"
          :disabled="hasErrors || !this.ingredient"
        >
          Update Ingredient
        </MPButton>
      </div>
    </MPModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AddEditRecipeIngredientsMixin from './AddEditRecipeIngredientsMixin'
import Fraction from 'fraction.js'

export default {
  name: 'AddCustomRecipeIngredientEditModal',
  mixins: [AddEditRecipeIngredientsMixin],
  props: {
    tabScope: {
      default: 'editModal',
    },
    show: {
      default: false,
    },
    recipeIngredient: {
      default: null,
    },
  },
  watch: {
    recipeIngredient: {
      handler() {
        if (this.recipeIngredient) {
          this.id = this.recipeIngredient.id
          this.quantity = this.recipeIngredient.quantity
          this.quantityFraction = this.recipeIngredient.quantity
            ? new Fraction(
                parseFloat(this.recipeIngredient.quantity)
              ).toFraction(true)
            : null
          this.unit = this.recipeIngredient.unit
          this.ingredient = this.recipeIngredient.ingredient
          this.prepDescription = this.recipeIngredient.prepDescription
          this.index = this.recipeIngredient.index
          this.isMetric = this.recipeIngredient.isMetric
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('recipe', [
      'unitList',
      'ingredientList',
      'prepDescriptionList',
    ]),
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
  },
  methods: {
    update() {
      return this.$validator.validate(`${this.tabScope}.*`).then((result) => {
        if (result) {
          this.$emit(
            'edit',
            this.createRecipeIngredientObject(this.unit && this.unit.isMetric)
          )
          this.showModal = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
#edit-recipe-ingredient-modal {
  .modal-dialog {
    min-width: 434px !important;
    width: 434px;
  }
  .modal-content {
    min-height: 375px !important;
  }

  @include media-breakpoint-down(xs) {
    .modal.show {
      padding: 0 !important;
    }
    .modal-dialog {
      max-width: 100% !important;
      min-width: auto !important;
    }
  }
}
</style>
