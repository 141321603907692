import environment from '@/environment'
import apiCheckout from '@/api/checkout'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    chargebee: null,
    portal: null,
  }),
  mounted() {
    if (!this.chargebee) {
      this.chargebee = window.Chargebee.init({
        site: environment.chargebee.site,
        enableFBQTracking: true,
        enableGTMTracking: true,
        iframeOnly: false,
      })
    }
  },
  computed: {
    ...mapGetters({
      portalSession: 'user/portalSession',
      billing: 'user/billing',
    }),
  },
  methods: {
    closeModals() {
      this.chargebee.closeAll()
    },
    openCheckout(
      cb,
      { onSuccess = () => {}, onError = () => {}, onClose = () => {} }
    ) {
      this.chargebee.openCheckout({
        hostedPage: async () => {
          return await cb()
        },
        success: (hostedPageId) => {
          onSuccess(hostedPageId)
          this.chargebee.closeAll()
        },
        error: (err) => onError(err),
        close: () => onClose(),
      })
    },
    newSubscription(data, { onSuccess, onError, onClose }) {
      this.openCheckout(() => apiCheckout.createHostedPage(data), {
        onSuccess,
        onError,
        onClose,
      })
    },
    updateSubscription(data, { onSuccess, onError, onClose }) {
      this.openCheckout(() => apiCheckout.updateHostedPage(data), {
        onSuccess,
        onError,
        onClose,
      })
    },
    claimGift(data, { onSuccess, onError, onClose }) {
      this.openCheckout(() => apiCheckout.claimGiftHostedPage(data), {
        onSuccess,
        onError,
        onClose,
      })
    },
    newGift(data, { onSuccess, onError, onClose }) {
      this.openCheckout(() => apiCheckout.createGiftHostedPage(data), {
        onSuccess,
        onError,
        onClose,
      })
    },
    async instancePortal() {
      this.chargebee.setPortalSession(() => {
        return apiCheckout.createPortal()
      })
    },
    async openPortalSection(type, params, callbacks) {
      if (!this.portal) {
        this.portal = this.chargebee.createChargebeePortal()
      }
      await this.instancePortal()

      this.portal.openSection(
        {
          sectionType: type,
          params,
        },
        {
          close: () => {
            if (callbacks.onClose) {
              callbacks.onClose()
            } else {
              this.chargebee.logout()
            }
          },
          subscriptionChanged: () => {
            if (callbacks.onSubChanged) {
              callbacks.onSubChanged()
            }
          },
          paymentSourceAdd: () => {
            if (callbacks.onSourceAdded) {
              callbacks.onSourceAdded()
            }
          },
          paymentSourceUpdate: () => {
            if (callbacks.onSourceUpdated) {
              callbacks.onSourceUpdated()
            }
          },
          paymentSourceRemove: () => {
            if (callbacks.onSourceRemoved) {
              callbacks.onSourceRemoved()
            }
          },
          scheduledCancellationRemoved: () => {
            if (callbacks.onRemovedCancellation) {
              callbacks.onRemovedCancellation()
            }
          },
        }
      )
    },
    changeSubscription(obj) {
      this.openPortalSection(
        window.Chargebee.getPortalSections().SUBSCRIPTION_DETAILS,
        {
          subscriptionId: this.billing.subscriptionId,
        },
        obj
      )
    },
    changePaymentMethod(obj) {
      this.openPortalSection(
        window.Chargebee.getPortalSections().PAYMENT_SOURCES,
        {
          subscriptionId: this.billing.subscriptionId,
        },
        obj
      )
    },
  },
}
