<template>
  <div>
    <span
      >Sorry, there was an error. Please try
      <MPWhiteLink href @click.prevent="reloadPage()">reloading</MPWhiteLink>
      the page or
      <MPWhiteLink href @click.prevent="openIntercom()">contact us</MPWhiteLink>
      if this keeps happening.</span
    >
  </div>
</template>

<script>
import { MPWhiteLink } from '@/components/common'

export default {
  components: { MPWhiteLink },
  methods: {
    reloadPage() {
      window.location.reload(true)
    },
    openIntercom() {
      this.$intercom.show()
    },
  },
}
</script>
