import * as types from '../mutation-types'
import api from '../../api/DisqusApi'
import Vue from 'vue'
import _ from 'lodash'

const state = {
  title: '',
  identifier: '',
  threadId: 0,
  posts: null,
  sideBarPosts: null,
  sideBarUser: null,
  isSideBarVisible: false,
  rawPosts: null,
  currentUser: null,
  disqusUser: null,
  loading: false,
  sideBarPage: null,
  notificationInfoMessage: '',
  notificationErrorMessage: '',
  lastRecipeId: '',
}
const getters = {
  posts: (state) => {
    return state.posts
  },
  avatar: (state) => {
    return state.currentUser && state.currentUser.photo && state.disqusUser
      ? state.disqusUser.avatar.permalink
      : require('@/assets/icons/recipe-default-avatar.svg')
  },
  loading: (state) => {
    return state.loading
  },
  sideBarPosts: (state) => {
    return state.sideBarPosts
  },
  commentsCount: (state) => {
    return state.posts ? state.posts.length : 0
  },
}
const actions = {
  async disqusLogin({ commit, rootState }) {
    commit(types.SET_CURRENT_USER, rootState.user)
    try {
      const userDetails = await api.getUserDetails()
      commit(types.SET_DISQUS_USER, userDetails)
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  async initDisqus({ commit, state, dispatch }, recipe) {
    commit(types.SET_DISQUS_THREAD_TITLE, recipe)
    commit(types.SET_DISQUS_THREAD_IDENTIFIER, recipe)
    commit(types.CLEAR_DISQUS_RAW_POSTS)
    commit(types.CLEAR_DISQUS_POSTS)
    try {
      await dispatch('disqusLogin')
      const currentRecipeThread = await api.getCurrentRecipeThread(
        state.identifier
      )
      if (currentRecipeThread.length === 1) {
        commit(types.SET_DISQUS_THREAD_ID, currentRecipeThread[0].id)
        return await dispatch('getPosts')
      } else {
        const newDisqusThread = await api.createCurrentRecipeThread(
          state.title,
          state.identifier
        )
        commit(types.SET_DISQUS_THREAD_ID, newDisqusThread.id)
        commit(types.STRUCTURE_DISQUS_RAW_POSTS)
      }
    } catch (err) {
      console.error(err)
      // Do not show error to the user if Disqus is not working.
      // commit(types.SET_ERROR_MESSAGE, err.message)
    }
  },
  async getPosts({ commit, state, dispatch }, cursor) {
    try {
      const isActive = state.currentUser.status == 'active'
      const posts = await api.getPosts(cursor, state.threadId)
      commit(types.SET_DISQUS_RAW_POSTS, posts.response)
      if (posts.cursor.hasNext === true && isActive) {
        return await dispatch('getPosts', posts.cursor.next)
      } else {
        commit(types.STRUCTURE_DISQUS_RAW_POSTS)
        commit(types.CLEAR_DISQUS_RAW_POSTS)
        commit(types.ORDER_POSTS_BY_POPULARITY)
      }
    } catch (err) {
      console.error(err)
      commit(types.SET_ERROR_MESSAGE, err.message)
      commit(types.SET_LOADING, false)
    }
  },
  async addPost({ commit, state }, message) {
    commit(types.SET_LOADING, true)
    try {
      const post = await api.createPost(message, state.threadId)
      commit(types.SET_LAST_RECIPE_ID, post.id)
      commit(types.ADD_DISQUS_POST, post)
      commit(types.ORDER_POSTS_BY_POPULARITY)
      commit(types.SET_LOADING, false)
      commit(types.SET_INFO_MESSAGE, 'Your comment has been posted!')
    } catch (err) {
      console.error(err)
      commit(types.SET_ERROR_MESSAGE, err.message)
      commit(types.SET_LOADING, false)
    }
  },
  async replyPost({ commit, state }, { message, parent }) {
    commit(types.SET_LOADING, true)
    try {
      const post = await api.replayToPost(message, state.threadId, parent)
      commit(types.ADD_DISQUS_POST, post)
      commit(types.ORDER_POSTS_BY_POPULARITY)
      commit(types.SET_LOADING, false)
      commit(types.SET_INFO_MESSAGE, 'Your reply has been posted!')
    } catch (err) {
      console.error(err)
      commit(types.SET_ERROR_MESSAGE, err.message)
      commit(types.SET_LOADING, false)
    }
  },
  async votePost({ commit, state }, { vote, post }) {
    commit(types.SET_LOADING, true)
    try {
      const res = await api.votePost(vote, post.id)
      if (post.isInSideBar) {
        commit(types.EDIT_SIDE_BAR_POST, {
          post: res.post,
          keys: ['likes', 'dislikes', 'likesDislikesDelta'],
        })
      }
      if (post.thread == state.threadId) {
        commit(types.EDIT_DISQUS_POST, {
          post: res.post,
          keys: ['likes', 'dislikes', 'likesDislikesDelta'],
        })
      }
      commit(types.ORDER_POSTS_BY_POPULARITY)
      commit(types.SET_LOADING, false)
      commit(types.SET_INFO_MESSAGE, 'Thank you for your vote!')
    } catch (err) {
      console.error(err)
      commit(types.SET_ERROR_MESSAGE, err.message)
      commit(types.SET_LOADING, false)
    }
  },
  async editPost({ commit, state }, { message, post }) {
    commit(types.SET_LOADING, true)
    try {
      const res = await api.editPost(
        message,
        post.id,
        state.disqusUser.username
      )
      if (post.isInSideBar) {
        commit(types.EDIT_SIDE_BAR_POST, { post: res, keys: ['message'] })
      }
      if (post.thread == state.threadId) {
        commit(types.EDIT_DISQUS_POST, { post: res, keys: ['message'] })
      }
      commit(types.SET_LOADING, false)
      commit(types.SET_INFO_MESSAGE, 'Your comment has been edited!')
    } catch (err) {
      console.error(err)
      commit(types.SET_ERROR_MESSAGE, err.message)
      commit(types.SET_LOADING, false)
    }
  },
  async deletePost({ commit, state }, post) {
    commit(types.SET_LOADING, true)
    try {
      const res = await api.deletePost(post.id, state.disqusUser.username)
      if (post.isInSideBar) {
        commit(types.DELETE_SIDE_BAR_POST, res[0])
      }
      if (post.thread == state.threadId) {
        commit(types.DELETE_DISQUS_POST, res[0])
      }
      commit(types.SET_LOADING, false)
      commit(types.SET_INFO_MESSAGE, 'Your post has been deleted!')
    } catch (err) {
      console.error(err)
      commit(types.SET_ERROR_MESSAGE, err.message)
      commit(types.SET_LOADING, false)
    }
  },
  async reportPost({ commit }, postId) {
    commit(types.SET_LOADING, true)
    try {
      await api.reportPost(2, postId)
      commit(types.SET_LOADING, false)
      commit(types.SET_INFO_MESSAGE, 'Thank you for your report!')
    } catch (err) {
      console.error(err)
      commit(types.SET_ERROR_MESSAGE, err.message)
      commit(types.SET_LOADING, false)
    }
  },
  async loadUserPosts({ commit }, { user, cursor }) {
    commit(types.CLEAR_SIDE_BAR_POSTS)
    const res = await api.getUserPosts(user.id, cursor)
    commit(types.SET_SIDE_BAR_PAGE, res.cursor)
    commit(types.ADD_SIDE_BAR_POSTS, res.response)
  },
  async openSideBarWithUserPosts({ commit, dispatch }, user) {
    commit(types.SET_SIDE_BAR_VISIBILITY, true)
    commit(types.SET_SIDE_BAR_POSTS_USER, user)
    await dispatch('loadUserPosts', { user, cursor: null })
  },
  async closeSideBar({ commit }) {
    commit(types.SET_SIDE_BAR_VISIBILITY, false)
    commit(types.CLEAR_SIDE_BAR)
  },
}
const mutations = {
  [types.SET_CURRENT_USER](state, user) {
    state.currentUser = {
      id: user.userData.id,
      name: user.profile.fullName,
      photo: user.profile.photo,
      status: user.userData.status,
    }
    state.currentUser = {
      ...state.currentUser,
    }
  },
  [types.SET_DISQUS_USER](state, disqusUser) {
    state.disqusUser = disqusUser
  },
  [types.SET_DISQUS_THREAD_TITLE](state, { title }) {
    state.title = encodeURIComponent(title)
  },
  [types.SET_DISQUS_THREAD_IDENTIFIER](state, { url }) {
    state.identifier = encodeURIComponent(url)
  },
  [types.SET_DISQUS_THREAD_ID](state, id) {
    state.threadId = id
  },
  [types.SET_LOADING](state, loading) {
    state.loading = loading
  },
  [types.SET_DISQUS_RAW_POSTS](state, posts) {
    state.rawPosts = state.rawPosts ? state.rawPosts : []
    posts.forEach((post) => {
      if (!checkShadowBan(post, state.disqusUser)) {
        state.rawPosts.push(post)
      }
    })
  },
  [types.STRUCTURE_DISQUS_RAW_POSTS](state) {
    state.posts = state.posts ? state.posts : []
    if (state.rawPosts) {
      for (let i = state.rawPosts.length - 1; i >= 0; i--) {
        addPostHelperFunction(state.rawPosts[i], state.posts, state.disqusUser)
      }
    }
  },
  [types.ADD_DISQUS_POST](state, post) {
    addPostHelperFunction(post, state.posts, state.disqusUser)
  },
  [types.EDIT_DISQUS_POST](state, { post, keys }) {
    const postObject = getPostObject(post, state.disqusUser)
    findPostAndUpdate(postObject, state.posts, keys)
  },
  [types.DELETE_DISQUS_POST](state, post) {
    const postObject = getPostObject(post, state.disqusUser)
    findPostAndDelete(postObject, state.posts)
  },
  [types.ORDER_POSTS_BY_POPULARITY](state) {
    orderByPopularity(state.posts)
  },
  [types.CLEAR_DISQUS_POSTS](state) {
    state.posts = null
  },
  [types.CLEAR_DISQUS_RAW_POSTS](state) {
    state.rawPosts = null
  },
  [types.SET_SIDE_BAR_VISIBILITY](state, isVisible) {
    state.isSideBarVisible = isVisible
  },
  [types.CLEAR_SIDE_BAR_POSTS](state) {
    state.sideBarPosts = null
  },
  [types.CLEAR_SIDE_BAR](state) {
    state.sideBarPosts = null
    state.sideBarUser = null
    state.sideBarPage = null
  },
  [types.SET_SIDE_BAR_POSTS_USER](state, user) {
    state.sideBarUser = user
  },
  [types.SET_SIDE_BAR_PAGE](state, page) {
    state.sideBarPage = page
  },
  [types.ADD_SIDE_BAR_POSTS](state, posts) {
    posts = _.map(posts, function (value) {
      return {
        ...getPostObject(value, state.disqusUser, true),
        ...{
          recipeUrl: value.thread.identifiers[0].substr(
            value.thread.identifiers[0].indexOf('/')
          ),
          threadName: value.thread.clean_title,
          thread: value.thread.id,
        },
      }
    })
    state.sideBarPosts = _.groupBy(posts, 'thread')
  },
  [types.EDIT_SIDE_BAR_POST](state, { post, keys }) {
    const thread = state.sideBarPosts[post.thread]
    const postToEdit = _.find(thread, function (p) {
      return p.id == post.id
    })
    keys.forEach((key) => {
      Vue.set(postToEdit, key, post[key])
    })
    Vue.set(state.sideBarPosts, post.thread, thread)
  },
  [types.DELETE_SIDE_BAR_POST](state, post) {
    const thread = state.sideBarPosts[post.thread]
    const postToEditIndex = _.findIndex(thread, function (p) {
      return p.id == post.id
    })
    thread.splice(postToEditIndex, 1)
    if (thread.length) {
      Vue.set(state.sideBarPosts, post.thread, thread)
    } else {
      Vue.delete(state.sideBarPosts, post.thread)
    }
  },
  [types.SET_INFO_MESSAGE](state, message) {
    state.notificationInfoMessage = message
    setTimeout(() => {
      state.notificationInfoMessage = ''
      state.lastRecipeId = ''
    }, 2000)
  },
  [types.SET_ERROR_MESSAGE](state, message) {
    state.notificationErrorMessage = message
    setTimeout(() => {
      state.notificationErrorMessage = ''
      state.lastRecipeId = ''
    }, 2000)
  },
  [types.SET_LAST_RECIPE_ID](state, postId) {
    state.lastRecipeId = postId
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

// Helper functions
function addPostHelperFunction(post, posts, disqusUser) {
  const postObject = getPostObject(post, disqusUser)
  if (!postObject.parent && posts.indexOf(postObject) === -1) {
    posts.push(postObject)
  } else if (post.parent) {
    findParentPost(posts, postObject)
  }
}
function findPostAndUpdate(post, posts, keys) {
  for (let i = 0; i < posts.length; i++) {
    if (post.id == posts[i].id) {
      keys.forEach((key) => {
        Vue.set(posts[i], key, post[key])
      })
      return
    } else {
      if (posts[i].child.length) {
        findPostAndUpdate(post, posts[i].child, keys)
      }
    }
  }
}
function findPostAndDelete(post, posts) {
  for (let i = 0; i < posts.length; i++) {
    if (post.id == posts[i].id) {
      posts.splice(i, 1)
      return
    } else {
      if (posts[i].child.length) {
        findPostAndDelete(post, posts[i].child)
      }
    }
  }
}

function getIsEditableProperty(editableUntil) {
  let editableDueDate = new Date(editableUntil)
  let now = Date.now()
  return now < editableDueDate
}

function getPostObject(post, { username }, isSideBar) {
  let isCurrentUserAuthor = username == post.author.username
  let obj = {
    id: post.id,
    author: {
      id: post.author.id,
      avatar: post.author.avatar.permalink,
      name: post.author.name,
      username: post.author.username,
    },
    parent: post.parent,
    createdAt: post.createdAt,
    message: post.raw_message,
    likes: post.likes,
    dislikes: post.dislikes,
    isCurrentUserAuthor: isCurrentUserAuthor,
    likesDislikesDelta: post.points,
    child: [],
    level: isSideBar ? 2 : 0,
    isInSideBar: isSideBar ? true : false,
    thread: post.thread,
    isEditable: getIsEditableProperty(post.editableUntil),
  }
  return obj
}
/**
 * @param  {Array} posts
 * @param  {Object} postObj
 * @description "Find parent post in posts and add child(postObj) to his parent.
 * This function is recursive, because post can have a reply, and reply can have another reply.
 * Level 2 is max depth and we are starting from 0"
 */
function findParentPost(posts, postObj) {
  for (let i = 0; i < posts.length; i++) {
    if (posts[i].id === postObj.parent.toString()) {
      postObj.level = posts[i].level + 1
      posts[i].child.push(postObj)
      return true
    } else {
      if (posts[i].child.length !== 0) {
        findParentPost(posts[i].child, postObj)
      }
    }
  }
}

function checkShadowBan(post, { username }) {
  let isCurrentUserAuthor = username == post.author.username
  if (isCurrentUserAuthor) {
    return false
  }
  return post.sb
}

function orderByPopularity(unorderedPosts) {
  let temp = null
  let n = unorderedPosts.length
  if (n === 1) {
    if (unorderedPosts[0].child.length) {
      orderByPopularity(unorderedPosts[0].child)
    } else {
      return
    }
  } else {
    for (let k = 0; k < n - 1; k++) {
      for (let i = 0; i < n - k - 1; i++) {
        if (unorderedPosts[i].child.length) {
          orderByPopularity(unorderedPosts[i].child)
        }
        if (
          unorderedPosts[i].likesDislikesDelta <
          unorderedPosts[i + 1].likesDislikesDelta
        ) {
          temp = unorderedPosts[i]
          Vue.set(unorderedPosts, i, unorderedPosts[i + 1])
          Vue.set(unorderedPosts, i + 1, temp)
        }
      }
    }
  }
}
