<template>
  <div
    :class="{ 'mp-radio--selected': isSelected }"
    class="mp-radio"
    @click="!isSelected ? $emit('click') : null"
  >
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'MPRadio',
  props: {
    label: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-radio {
  display: inline-block;
  height: 26px;
  width: 26px;
  background: url('~@/assets/icons/radio-deselected.svg') no-repeat center;
  cursor: pointer;
  span {
    margin-left: 2rem;
    line-height: 1.9;
  }
}

.mp-radio--selected {
  background: url('~@/assets/icons/radio-selected.svg') no-repeat center;
}
</style>
