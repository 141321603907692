<template>
  <MPModal class="BMmodal" :show.sync="showModal" :show-x="true">
    <div id="backgroundModal" class="px-2 px-md-4 py-md-4 py-5">
      <slot name="leftColumn"></slot>
      <slot name="rightColumn"></slot>
    </div>
  </MPModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onMount: {
      type: Function,
      default: () => null,
    },
  },
  name: 'BackgroundModal',
  computed: {
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
  },
  mounted() {
    this.onMount()
  },
}
</script>

<style lang="scss">
.BMmodal {
  .close-reveal-modal {
    border-radius: 50%;
    background-color: #ffffff;
    left: rem(17);
    top: 30px;

    @media (orientation: landscape) {
      left: auto;
      top: rem(20);
      right: rem(20);
    }
    @include media-breakpoint-up(md) {
      left: auto;
      right: rem(20);
    }
    @include media-breakpoint-up(lg) {
      left: rem(17);
      right: auto;
    }
  }

  .modal-dialog {
    min-width: 50%;

    @media (max-width: 739px) {
      max-width: 100%;
      width: 100%;
    }
  }
  .modal-content {
    background: #fff;
  }
}
</style>

<style lang="scss" scoped>
#backgroundModal {
  margin: -20px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: top center;
  background-image: url('~@/assets/images/copy-to-clipbard-view-container-mobile.png');
  background-size: cover;
  background-color: #fff;
  background-position-y: -17px;
  @media (orientation: landscape) {
    background-position-y: -40vw;
  }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url('~@/assets/images/copy-to-clipbard-view-container-mobile.png');
  }
  @include media-breakpoint-up(md) {
    background-position-y: -20px;
    background-size: cover;
    background-image: url('~@/assets/images/premium-view-container-tablet.jpg');
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url('~@/assets/images/copy-to-clipbard-view-container-mobile.png');
    }
  }
  @include media-breakpoint-up(lg) {
    background-position: top left;
    background-position-x: -120px;
    background-size: cover;
    background-image: url('~@/assets/images/premium-view-container.jpg');
    flex-direction: row;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url('~@/assets/images/premium-view-container@2x.jpg');
    }
  }
  @include media-breakpoint-up(xl) {
    background-position-x: 0;
  }
}
</style>
