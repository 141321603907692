<template>
  <RecipeBase id="custom-recipe" @close="closeRecipe">
    <div slot="header">
      <!-- Possible components: RecipeHeader {image}, RecipeHeaderImageUpload {model=recipeImage}  -->
      <component
        :is="headerComponent"
        v-model="recipeImage"
        :image="recipe.image"
        :placeholder-image="recipe.placeholderImage"
      />
    </div>
    <div slot="actions">
      <transition name="fade">
        <RecipeServingSizeInfo
          v-if="showServingsInfo"
          :servings="totalUserServings"
          class="mb-md-4"
          @ok="hideServingsHint"
          @dismiss="permanentlyHideServingsHint"
        />
      </transition>
      <div class="d-lg-none container">
        <RecipeTopSection
          :isCustom="true"
          @delete="deleteRecipe()"
          @edit="editRecipe(0)"
        />
      </div>
    </div>
    <div slot="left-content">
      <div class="d-none d-lg-flex">
        <RecipeTopSection
          :isCustom="true"
          @delete="deleteRecipe()"
          @edit="editRecipe(0)"
        />
      </div>
      <div
        class="servings-text-section"
        v-if="recipe.servingsText"
        v-html="recipe.servingsText"
      ></div>
      <RecipeSubtitle> PREPARATION STEPS </RecipeSubtitle>
      <div
        v-if="recipe.description"
        v-html="recipe.description"
        class="recipe-description"
      ></div>
      <div class="no-steps text-serif" v-else>
        <p><b>No preparation steps have been added.</b></p>

        <p>
          Adding preparation steps is simple! Just click “<button
            class="action-link"
            @click="editRecipe(2)"
          >
            Edit Recipe</button
          >” to go to the recipe upload template to add your own text.
        </p>
      </div>
    </div>
    <div slot="right-content">
      <!-- INGREDIENTS -->
      <RecipeSideCard>
        <RecipeSubtitle> INGREDIENTS </RecipeSubtitle>
        <div v-if="recipe.hasIngredients">
          <div
            v-for="(ingredientGroup, key) in recipe.ingredientsGroup"
            :key="key"
          >
            <RecipeIngredientTitle v-if="key">
              {{ key }}
            </RecipeIngredientTitle>
            <RecipeIngredient
              v-for="(ingredient, index) in ingredientGroup"
              :key="index"
              :title="ingredient.title"
              :description="ingredient.description"
              :is-speed-prep="ingredient.isSpeedPrep"
            />
          </div>
        </div>
        <div class="no-steps" v-else>
          <p><b>No ingedients have been added.</b></p>

          <p>
            Adding ingredients is simple! Just click “<button
              class="action-link"
              @click="editRecipe(1)"
            >
              Edit Recipe</button
            >” to go to the recipe upload template to add your own ingredients.
          </p>
        </div>
      </RecipeSideCard>
      <!-- EQUIPMENT -->
      <RecipeSideCard v-if="recipe.equipment">
        <RecipeSubtitle> YOU'LL NEED </RecipeSubtitle>
        <span v-html="recipe.equipment"></span>
      </RecipeSideCard>
      <!-- TIPS -->
      <RecipeSideCard v-if="hasTips" class="light-green">
        <RecipeSubtitle> RECIPE TIPS </RecipeSubtitle>
        <span
          class="mb-3 d-block"
          v-for="(tip, index) in recipe.tips"
          :key="index"
          v-html="tip"
        ></span>
      </RecipeSideCard>
    </div>
    <div slot="footer">
      <div class="credits" v-if="recipe.credits" v-html="recipe.credits"></div>
      <AddCustomRecipe />
      <RecipeDeleteConfirmModal v-model="showConfirmModal" :recipe="recipe" />
    </div>
  </RecipeBase>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import RecipeActions from './RecipeActions'
import RecipeHeader from './RecipeHeader'
import RecipeSubtitle from './RecipeSubtitle'
import RecipeIngredient from './RecipeIngredient'
import RecipeIngredientTitle from './RecipeIngredientTitle'

import RecipeBase from './RecipeBase'
import RecipeSideCard from './RecipeSideCard'
import RecipeHeaderActionButton from './RecipeHeaderActionButton'
import RouterMixin from '@/mixins/RouterMixin'
import RecipeDeleteConfirmModal from '@/components/recipe/RecipeDeleteConfirmModal'
import RecipeFavorite from '@/components/recipe/RecipeFavorite'
import RecipeEditContentContainer from './RecipeEditContentContainer'
import AddCustomRecipe from '@/components/addcustomrecipe/AddCustomRecipe'
import RecipeHeaderImageUpload from './RecipeHeaderImageUpload'
import RecipeServingSizeInfo from './RecipeServingSizeInfo'
import RatingStars from './RatingStars'
import RecipeTopSection from './RecipeTopSection'
import CustomRecipeActions from './CustomRecipeActions'
import NotificationsMixin from '@/mixins/NotificationsMixin'

import { events } from '@/utils/mixpanel.events'

export default {
  name: 'TheCustomRecipe',
  components: {
    RecipeActions,
    RecipeHeader,
    RecipeSubtitle,
    RecipeIngredient,
    RecipeIngredientTitle,
    RecipeBase,
    RecipeSideCard,
    RecipeHeaderActionButton,
    RecipeDeleteConfirmModal,
    RecipeFavorite,
    RecipeEditContentContainer,
    AddCustomRecipe,
    RecipeHeaderImageUpload,
    RecipeServingSizeInfo,
    RatingStars,
    RecipeTopSection,
    CustomRecipeActions,
  },
  mixins: [RouterMixin, NotificationsMixin],
  computed: {
    ...mapGetters({
      recipe: 'recipe/recipe',
      previousRoute: 'globals/previousRoute',
      editMode: 'AddCustomRecipe/editMode',
      showRecipeServingsTip: 'user/showRecipeServingsTip',
      totalUserServings: 'user/totalUserServings',
    }),
    headerComponent() {
      return this.recipe.image ? RecipeHeader : RecipeHeaderImageUpload
    },
    hasTips() {
      return this.recipe.tips && this.recipe.tips.length > 0
    },
  },
  data() {
    return {
      recipeImage: null,
      showConfirmModal: false,
      showServingsInfo: true,
    }
  },
  methods: {
    ...mapActions({
      setEditMode: 'AddCustomRecipe/setEditMode',
    }),
    rateRecipe(rate) {
      this.$store.dispatch('recipe/rateRecipe', rate).then(async () => {
        await this.$store.dispatch('RecipeBox/addRating', {
          id: this.recipe.id,
          rating: rate,
        })
      })
      const payload = {
        eventName: events.recipe.rated,
        params: {
          recipe_id: this.recipe.id,
          recipe_rating: rate,
        },
      }
      this.$store.dispatch('analytics/track', payload)
    },
    hideServingsHint() {
      this.showServingsInfo = false
    },
    permanentlyHideServingsHint() {
      this.showServingsInfo = false
      this.$store.dispatch('recipe/dismissServingsInfo').then(() => {
        this.$store.dispatch('user/refreshUserData')
      })
    },
    toggleFavorite() {
      this.$store.dispatch('recipe/toggleFavorite')
      this.$intercom.trackEvent('FAVORITED')
      const payload = {
        eventName: events.recipe.favorited,
        params: {
          recipe_id: this.recipe.id,
          action: this.recipe.isFavorite ? 'favorite' : 'unfavorite',
        },
      }
      this.$store.dispatch('analytics/track', payload)
    },
    async closeRecipe() {
      if (!this.editMode) {
        await this.createInfoNotification({
          text: 'Your personal recipe has been added',
          closeAfter: 5,
          color: 'navy',
        })
        this.setEditMode(true)
      }
      this.goBack()
    },
    editRecipe(step) {
      this.$store
        .dispatch('AddCustomRecipe/initAddCustomRecipeData', {
          userCustomRecipeId: this.recipe.id,
          step: step,
        })
        .then(() => {
          this.$store.commit('AddCustomRecipe/SET_MODAL_VISIBILITY', true)
        })
    },
    deleteRecipe() {
      this.showConfirmModal = true
    },
  },
  watch: {
    showRecipeServingsTip: {
      handler: function (value) {
        this.showServingsInfo = value
      },
      immediate: true,
    },
    'recipe.image'() {
      if (this.recipe.image) {
        this.recipeImage = null
      }
    },
    recipeImage() {
      if (this.recipeImage) {
        this.$store
          .dispatch('AddCustomRecipe/addOrUpdateRecipeImage', {
            image: this.recipeImage,
            recipeId: this.recipe.id,
          })
          .then((res) => {
            const newRecipe = this.recipe
            newRecipe.image = res
            this.$store.dispatch('RecipeBox/refresh')
            this.$store.dispatch('recipe/loadRecipe', {
              recipeId: this.recipe.id,
            }) // Refresh current recipe data
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-card {
  @include media-breakpoint-down(sm) {
    padding-top: 2rem;
  }
}
.recipe-header-line {
  display: inline-block;
  width: 1px;
  height: 21px;
  opacity: 0.3;
  border: solid 0.5px #616161;
  margin: 0 1rem;
}

.recipe-description {
  word-break: break-word;
}

.servings-text-section {
  font-size: 0.75rem;
  border-bottom: 2px solid #e5e5e5;
  margin-bottom: 1rem;
}

.recipe-info {
  font-size: 0.75rem;
  color: #757575;
}

.credits {
  color: #757575;
  font-size: 0.75rem;
  margin: 1rem 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.no-steps {
  margin-top: 21px;
}
.action-link {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  background: none;
  display: inline;
  padding: 0;
  color: $link-color;
  margin: 0;
  &:hover {
    color: $link-hover-color;
  }
}
</style>
