<template>
  <div class="container">
    <b-link class="btn-show-plan" @click="goToRegister()" :disabled="!!error"
      >Skip this
      <img
        :src="require('@/assets/icons/icons-chevron-right.svg')"
        class="icon"
    /></b-link>
  </div>
</template>

<script>
import { events } from '@/utils/mixpanel.events'
import { mapGetters } from 'vuex'
export default {
  name: 'ButtonSkip',
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  methods: {
    goToRegister() {
      this.$analytics.track(events.register.showMeMyMealPlan, {
        step: this.step,
      })
      this.$router.push('/register/5')
    },
  },
  computed: {
    ...mapGetters({
      error: 'globals/noPlanError',
    }),
  },
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  margin-top: rem(40);
  .btn-show-plan {
    font-family: $font-neuzeitgro;
    font-size: rem(16);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5;
    text-align: right;
    color: $success;
  }
}
</style>
