<template>
  <div class="position-relative">
    <div class="delete-icon" @click="deleteFile()" v-if="image"></div>
    <input
      @change="onChange"
      name="custom-recipe-image"
      type="file"
      id="custom-recipe-image"
      class="d-none"
    />
    <div
      class="drag-drop__container p-4 m-0 position-relative"
      :class="{ 'd-flex align-items-center justify-content-center': !image }"
      @dragover.prevent
      @drop="onDrop"
    >
      <img v-if="image" :src="image" alt class="w-100" />
      <div
        v-else
        class="d-flex flex-column align-items-center align-content-around"
      >
        <img src="@/assets/icons/add-recipe-upload.svg" />
        <div class="drag-drop__text mt-3">
          Drag and drop image here
          <br />
          <span>
            or
            <label for="custom-recipe-image">
              <u>upload</u>
              from computer (optional)
            </label>
          </span>
        </div>
      </div>
    </div>
    <div class="info">{{ info }}</div>

    <small class="disclaimer">* Required Field</small>
  </div>
</template>

<script>
import UploadImageMixin from '@/mixins/UploadImageMixin'
export default {
  name: 'AddCustomRecipeFileUpload',
  mixins: [UploadImageMixin],
}
</script>

<style lang="scss" scoped>
u {
  cursor: pointer;
}
.info {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: $text-gray;
}
.delete-icon {
  position: absolute;
  right: 40px;
  top: 40px;
  width: 35px;
  height: 35px;
  background: $grey url('~@/assets/icons/trash-can-white-icon.svg') no-repeat
    center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  &:hover {
    background-color: $dark-grey;
  }
}
.drag-drop {
  &__container {
    border: 2px #d8d8d8 dashed;
    height: 298px;
    overflow-y: scroll;
    width: 100%;
  }
  &__text {
    font-size: rem(18);
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: center;

    span {
      font-size: rem(16);
      font-weight: 500;
      color: $clr-navy-70;
    }
  }
}
small.disclaimer {
  font-size: rem(12);
  color: $text-muted;
  margin-top: 15px;
  display: block;
}
</style>
