export default {
  getOthersSearchResultsTextItem: getOthersSearchResultsTextItem,
  getTitleCard: getTitleCard,
  getNoFavoritesCard: getNoFavoritesCard,
  getNoPersonalRecipesCard: getNoPersonalRecipesCard,
  getRecipeCard: getRecipeCard,
  getLoadingItem: getLoadingItem,
  getToggleDisabledItem: getToggleDisabledItem,
  getRating: getRating,
  getCommunityRating: getCommunityRating,
}

function getOthersSearchResultsTextItem(searchText, otherItemsLength) {
  let resultText = getResultsText(otherItemsLength)
  return {
    text: `Another ${otherItemsLength} ${resultText} found for <b>${searchText}</b> with filtering turned off.`,
    componentType: 'text-recipe-box-item',
  }
}

function getTitleCard(title, type = 'normal', componentId) {
  return {
    title: title,
    type: type,
    componentType: 'recipe-title-card',
    componentId: componentId,
  }
}

function getNoFavoritesCard() {
  return {
    componentType: 'no-favorites-box-item',
    componentId: 'no-favorites-box-item',
  }
}

function getNoPersonalRecipesCard() {
  return {
    componentType: 'no-personal-recipes-item',
    componentId: 'no-personal-recipes-item',
  }
}

function getRecipeCard(rec, isAddRecipe, componentIdPrefix) {
  return {
    categoryId: rec.category_id,
    id: rec.id,
    title: rec.title,
    subtitle: rec.subtitle.length ? rec.subtitle : undefined,
    url: rec.url,
    thumb: rec.thumb_medium,
    isLocked: rec.is_locked,
    isNew: isNewRecipe(rec.go_live),
    isFavorite: rec.favorite,
    prepTime: `${rec.default_prep_time} min${rec.has_weekend_prep ? '*' : ''}`,
    prepTimeSmall: `${rec.default_prep_time}m`,
    pressureCooker: rec.is_pressure_cooker_recipe,
    rating: getRating(rec),
    generalRating: rec.general_rating,
    ratingCount: rec.rating_count,
    category: rec.category,
    categoryColor: rec.categoryColor,
    addRecipeStatus: isAddRecipe ? rec.addRecipeStatus : null,
    componentType: 'recipe-card',
    componentId: componentIdPrefix + rec.id,
    isCustom: rec.is_custom,
    isBonusRecipe: rec.is_bonus_recipe,
    manualToggleFavorite: rec.manualToggleFavorite,
  }
}

function getRating({ rating, rating_count, is_custom }) {
  let minNumberOfRatings = is_custom ? 0 : 3
  if (!rating || rating_count < minNumberOfRatings) {
    return null
  } else {
    return Math.ceil(rating * 2) / 2
  }
}

function getCommunityRating(rating) {
  return rating ? Math.ceil(rating * 2) / 2 : null
}

function isNewRecipe(dateNum) {
  let newDaysDuration = 30
  let millisecondsInDay = 1000 * 60 * 60 * 24
  return (
    new Date().getTime() - millisecondsInDay * newDaysDuration < dateNum * 1000
  )
}

function getLoadingItem() {
  return {
    componentType: 'recipe-box-loader',
    componentId: 'recipe-box-loader',
  }
}

function getToggleDisabledItem(isShow, disabledNum) {
  let text
  if (isShow) {
    text = `Hide ${disabledNum} unavailable recipes`
  } else {
    text = `+ ${disabledNum} unavailable recipes (show)`
  }
  return {
    text: text,
    isShow: isShow,
    componentType: 'recipe-toggle-disabled',
    componentId: 'recipe-toggle-disabled',
  }
}

function getResultsText(resultsLength) {
  return resultsLength == 1 ? 'result' : 'results'
}
