<template>
  <div class="mobile-search-wrapper">
    <i class="info-icon" @click="open"></i>
    <form @submit.prevent action class="input-wrapper">
      <!-- form tag for displaying "Search" for the return key -->
      <slot></slot>
    </form>
    <button
      class="clear-icon"
      v-if="showClear"
      v-on:click="onClearClick"
    ></button>
  </div>
</template>

<script>
// import Instruction from './popovers/Instruction.vue'

export default {
  props: {
    inputText: {
      type: String,
      required: true,
    },
    open: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      showSearchInfoModal: false,
    }
  },
  computed: {
    showClear() {
      return !!this.inputText.length
    },
  },
  methods: {
    onCloseClick() {
      this.$emit('close')
    },
    onClearClick() {
      this.$emit('clear')
    },
    setShowSearchInfoModal(value) {
      this.showSearchInfoModal = value
    },
  },
}
</script>
<style lang="scss">
.mobile-search-wrapper {
  .mp-modal .modal-content {
    border: none;
    border-radius: 4px;
    margin: 0 10px;
  }
}
</style>
<style lang="scss" scoped>
.mobile-search-wrapper {
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
}

.input-wrapper {
  flex: 1;
}

.search-icon {
  background-position: center center;
  background-image: url('~@/assets/icons/search-green.svg');
  width: 20px;
  background-repeat: no-repeat;
  height: 100%;
}

.close-icon {
  background-position: center center;
  background-image: url('~@/assets/icons/close-search-mobile.svg');
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;
}

.clear-icon {
  background-position: center center;
  background-image: url('~@/assets/icons/clear-search.svg');
  background-size: 23px;
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  background-color: #f2f2f2;
  margin: 0 6px;
  cursor: pointer;
}

.info-icon {
  background-position: center center;
  background-image: url('~@/assets/icons/info.svg');
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  background-color: #f2f2f2;
  margin: 0 6px;
  cursor: pointer;
}
</style>

<style lang="scss">
.mobile-search-wrapper {
  input {
    margin: 0;
    border: none;
    font-size: 1rem;
  }
  input::-webkit-search-cancel-button {
    // hide X icon in Chrome
    display: none;
  }
}
</style>
