<template>
  <div
    class="mp-select"
    :class="{
      'mp-select--selected': isSelected,
      'mp-select--green': color === 'green',
    }"
  ></div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'orange',
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-select {
  display: inline-block;
  height: 25px;
  width: 25px;
  background: url('~@/assets/icons/check-off.svg') no-repeat center;
  cursor: pointer;
  &--selected {
    background: url('~@/assets/icons/check-on.svg') no-repeat center;
  }
  &--selected.mp-select--green {
    background: url('~@/assets/icons/check-on-green.svg') no-repeat center;
  }
}
</style>
