<template>
  <div
    :class="{ 'd-none d-md-flex': !button.mobileText }"
    class="recipe-action-button-container"
    v-if="status"
  >
    <span
      :class="button.class"
      @click.stop="onClick()"
      class="recipe-action-button btn btn-primary"
    >
      <i class="add-icon" v-if="button.iconClass"></i>
      <span class="recipe-action-text">{{ button.text }}</span>
      <span class="mobile-text">{{ button.mobileText }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: false,
    },
  },
  methods: {
    onClick() {
      if (this.status === 'enabled') {
        this.$emit('addRecipe')
      } else if (this.status === 'locked') {
        this.$emit('upgrade')
      }
    },
    upgrade() {
      this.$emit('upgrade')
    },
  },
  computed: {
    button() {
      switch (this.status) {
        case 'enabled':
          return {
            text: 'Add to Plan',
            mobileText: 'Add',
            iconClass: 'add-icon',
            class: 'recipe-action-button--add',
          }
        case 'locked':
          return {
            text: 'Upgrade to Add',
            mobileText: null,
            iconClass: null,
            class: 'recipe-action-button--lock',
          }
        case 'disabled':
        default:
          return {
            text: 'Max Qty. Added',
            mobileText: 'Max',
            iconClass: null,
            class: 'recipe-action-button--disabled',
          }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.recipe-action-button-container {
  display: flex;
  @include media-breakpoint-up(md) {
    justify-content: center;
    position: static;
    width: 100%;
  }
}

.recipe-action-button {
  display: flex;
  height: 2rem;
  padding: 0 0.8rem;
  background-repeat: no-repeat;
  background-position: center center;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    height: 40px;
    min-width: 145px;
    font-size: 0.875rem;
    top: 1rem;
  }
}

.recipe-action-text {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.mobile-text {
  font-size: 0.875rem;
  margin-top: 2px; // yes, I know...
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.recipe-action-button--add {
  cursor: pointer;
}

.recipe-action-button--disabled {
  background-color: #cccccc;
  cursor: default;
}

.recipe-action-button--lock {
  background-color: $error;
  cursor: pointer;
}

.add-icon {
  display: inline-block;
  height: 0.875rem;
  width: 0.65rem;
  background-image: url('~@/assets/icons/add-to-plan.svg');
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 0.375rem;
}
</style>
