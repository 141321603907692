<template>
  <div id="settings-billing">
    <div class="d-flex flex-column justify-content-center mt-4 mt-md-0">
      <!-- Your Plan -->
      <SettingCard type="rows">
        <template slot="label"> Your Plan </template>
        <template slot="content">
          <span class="the-plan-title">
            {{ title }}
          </span>
          <span class="description">
            {{ externalName }}
          </span>
        </template>
      </SettingCard>
      <!-- Schedule -->
      <SettingCard type="rows">
        <template slot="label"> Schedule </template>
        <template slot="content">
          <span class="description">
            {{ scheduleText }}
          </span>
        </template>
        <template slot="actions-content">
          <MPButton class="btn btn-outline-success" @click="handleSubscribe">
            Subscribe
          </MPButton>
        </template>
      </SettingCard>
    </div>
  </div>
</template>

<script>
import SettingCard from '@/components/settings/SettingCard.vue'
import { Edit } from '@/components/common/MPIcons'

export default {
  name: 'SettingsBilling',
  components: {
    SettingCard,
    Edit,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    externalName: {
      required: true,
      type: String,
    },
    nextInvoice: {
      required: true,
      type: String,
    },
  },
  computed: {
    scheduleText() {
      return `Your gift subscription will end on ${this.nextInvoice}. To continue with Forks Meal Planner after that date purchase a subscription`
    },
  },
  methods: {
    handleSubscribe() {
      this.$emit('on-subscribe')
    },
  },
}
</script>

<style lang="scss" scoped>
.delete {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 1.125rem;
  line-height: 1.5;
}

.plan-not-editable {
  background-color: $clr-navy-5;
  padding: 1rem;
  text-align: left;
  color: $clr-navy-70;
  font-weight: 500;
}

.the-plan-title {
  font-weight: 500;
  display: block;
}

.card-info-wrapper {
  display: flex;
  align-items: center;
}

.card-info {
  color: $clr-navy-70;
  font-size: 0.875rem;
  padding-left: 0.5rem;
}

.plan-upsell {
  display: block;
  color: $clr-navy-70;
  font-size: 0.875rem;
}

.cancel-plan-title {
  font-size: 20px;
  font-weight: bold;
}

.button-upcase {
  text-transform: uppercase;
}

.action-button {
  padding: 0;
  text-align: right;
  svg {
    width: 28px;
    height: auto;
    vertical-align: bottom;
  }
}
</style>
