export const reasons = {
  success: 0,
  discountExpired: 1,
  anotherAffiliate: 2,
  notEligible: 3,
  alreadyPremium: 4,
  notFound: 5,
  existingDiscount: 6,
  offerCapReached: 7,
  alreadyMember: 8,
  inactive: 9,
}
