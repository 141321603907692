const defaultPromo = {
  title: 'ONE-TIME OFFER',
  header: 'Save 20% by joining today',
  description:
    'Subscribe today and get 20% off any plan. Click to activate this one-time offer, which is backed by our 30-day money-back guarantee',
  confirm: 'GET MY DISCOUNT',
  cancel: "No thanks, I'll pay full price",
}

export default defaultPromo
