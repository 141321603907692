<template>
  <div id="disqus-overlay">
    <h3 class="font-weight-bold">Comments</h3>
    <DisqusAddPost />
    <div class="dummy-posts">
      <div class="dummy-post" v-for="i in [1, 2, 3]" :key="i">
        <div class="author-image author-image--dummy"></div>
        <div class="col p-0 m-0">
          <div class="dummy-title"></div>
          <div class="dummy-text"></div>
        </div>
      </div>
    </div>
    <div class="overlay mt-4">
      <slot />
    </div>
  </div>
</template>

<script>
import DisqusAddPost from '@/components/recipe/disqus/DisqusAddPost'

export default {
  name: 'DisqusOverlay',
  components: { DisqusAddPost },
  methods: {
    goToPremium() {
      const eventProperties = {}
      eventProperties.location = 'DisqusOverlay'
      this.$analytics.track('mp upgrade clicked', eventProperties)
      this.$router.push('/reactivate')
    },
  },
}
</script>

<style lang="scss" scoped>
.upgrade-button {
  width: 200px;
  font-size: 1rem;
  font-weight: 500;
}
.dummy-posts {
  margin-top: 2rem;
}
.dummy-post {
  display: flex;
  height: 100%;
  margin-top: 2rem;
}
.dummy-title {
  width: 30%;
  height: 12px;
  background-color: #dde3e6;
  margin: 10px 0;
}
.dummy-text {
  width: 70%;
  height: 12px;
  background-color: #dde3e6;
  margin: 10px 0;
}
.lock-text {
  font-size: 1rem;
  letter-spacing: 0.4px;
  text-align: center;
  font-weight: 600;
  margin: 12px 0 24px;
  line-height: 1.5;
  color: #404852;
  width: 334px;
}
</style>
