<template>
  <div class="media mt-4" :id="postHtmlId">
    <img
      :src="post.author.avatar"
      class="author-image"
      :class="{ 'author-image--child': post.parent }"
    />
    <div class="media-body mt-1">
      <DisqusDropDown>
        <ul>
          <li v-if="showReport">
            <span class="dropdown-text" @click="reportPost(post.id)">
              Report
            </span>
          </li>
          <li v-if="showEdit">
            <span
              @click="isEditVisible = post.isEditable"
              class="dropdown-text"
              :class="{ disabled: !post.isEditable }"
            >
              Edit
            </span>
          </li>
          <li v-if="canBeDeleted">
            <span @click="deletePost(post)" class="dropdown-text">
              Delete
            </span>
          </li>
        </ul>
      </DisqusDropDown>
      <span
        v-text="post.author.name"
        class="author-name"
        @click="viewUserPosts(post.author)"
      ></span>
      <p v-if="!isEditVisible" v-html="post.message" class="mt-2"></p>
      <PostEditor
        v-else
        v-model="editMessage"
        place-holder="Edit a public comment..."
        action-text="Save post"
        :show-options="true"
        @onActionClick="editCurrentPost(editMessage, post)"
        @onCancelClick="isEditVisible = false"
        :required="true"
      />
      <div v-if="!isReplyVisible && !isEditVisible">
        <ul class="bottom-actions">
          <li>
            <span
              class="vote-number"
              v-text="post.likes"
              v-if="showLikes"
            ></span>
            <i class="arrow arrow-up" @click="voteOnPost(1, post)"></i>
          </li>
          <!--          <li>-->
          <!--            <span class="vote-number" v-if="showDislikes">-->
          <!--              -{{ post.dislikes }}-->
          <!--            </span>-->
          <!--            <i class="arrow arrow-down" @click="voteOnPost(-1, post)"></i>-->
          <!--          </li>-->
          <li v-if="showReply">
            <span class="reply-text" @click="isReplyVisible = true">Reply</span>
          </li>
        </ul>
      </div>
      <PostEditor
        v-if="isReplyVisible"
        v-model="replyMessage"
        place-holder="Add a public reply..."
        action-text="Post reply"
        :show-options="true"
        @onActionClick="replyOnPost(replyMessage, post.id)"
        @onCancelClick="isReplyVisible = false"
        :required="true"
      />
      <DisqusPostItem
        v-for="child in post.child"
        :key="child.id"
        :post="child"
      />
    </div>
  </div>
</template>

<script>
import PostEditor from '@/components/recipe/PostEditor'
import DisqusDropDown from '@/components/recipe/disqus/DisqusDropDown'
import { mapActions } from 'vuex'

export default {
  name: 'DisqusPostItem',
  props: {
    post: { type: Object, required: true },
  },
  components: { PostEditor, DisqusDropDown },
  data() {
    return {
      replyMessage: '',
      editMessage: '',
      isReplyVisible: false,
      isEditVisible: false,
    }
  },
  watch: {
    'post.message': {
      handler: function (value) {
        this.editMessage = value
      },
      immediate: true,
    },
  },
  computed: {
    canBeDeleted() {
      return this.post.isCurrentUserAuthor && this.post.child.length == 0
    },
    showReply() {
      return this.post.level < 2
    },
    showEdit() {
      return this.post.isCurrentUserAuthor
    },
    showReport() {
      return !this.post.isCurrentUserAuthor
    },
    showLikes() {
      return this.post.likes != 0
    },
    showDislikes() {
      return this.post.dislikes != 0
    },
    postHtmlId() {
      return `disuqs-post-${this.post.id}`
    },
  },
  methods: {
    ...mapActions('Disqus', [
      'replyPost',
      'votePost',
      'editPost',
      'deletePost',
      'reportPost',
      'openSideBarWithUserPosts',
    ]),
    replyOnPost(message, parent) {
      this.replyPost({ message, parent })
      this.isReplyVisible = false
      this.replyMessage = ''
    },
    voteOnPost(vote, post) {
      this.votePost({ vote, post })
    },
    editCurrentPost(message, post) {
      this.editPost({ message, post })
      this.isEditVisible = false
      this.replyMessage = ''
    },
    viewUserPosts(user) {
      this.openSideBarWithUserPosts(user)
    },
  },
}
</script>

<style lang="scss" scoped>
.bottom-actions {
  li {
    display: inline-flex;
    align-items: center;
    list-style: none;
    padding-right: 10px;
  }
}
.arrow {
  display: inline-block;
  width: 12px;
  height: 9px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.arrow-up {
  background: url('~@/assets/icons/comments-up-arrow.svg') no-repeat center;
}
.arrow-down {
  background: url('~@/assets/icons/comments-down-arrow.svg') no-repeat center;
}
.reply-text {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: $link-color;
  margin-left: 10px;
  &:hover {
    color: $link-hover-color;
    cursor: pointer;
  }
}
.dropdown-text {
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: $link-color;
  margin-left: 10px;
  &:hover {
    color: $link-hover-color;
    cursor: pointer;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}
.vote-number {
  color: $link-color;
  font-size: 14px;
  font-weight: 500;
  margin-right: 2px;
}
p {
  font-size: 1rem;
}
.author-name {
  font-weight: bold;
  transition: color 0.1s linear;
  &:hover {
    color: $link-hover-color;
    cursor: pointer;
  }
}
</style>
