<template>
  <div class="media mt-4">
    <img :src="avatar" class="author-image" />
    <div class="media-body">
      <PostEditor
        v-model="message"
        place-holder="Add a public comment... tell us what you loved, or leave a tip for other members."
        action-text="Post comment"
        @onActionClick="addNewPost()"
        :required="true"
        :remove-text-on-submit="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PostEditor from '@/components/recipe/PostEditor'

export default {
  name: 'DisqusAddPost',
  data() {
    return {
      message: '',
    }
  },
  components: {
    PostEditor,
  },
  computed: {
    ...mapGetters('Disqus', ['avatar']),
    ...mapGetters('user', ['permissions']),
  },
  methods: {
    ...mapActions('Disqus', ['addPost']),
    addNewPost() {
      if (this.permissions.canUseDisqusCommentingPlatform) {
        this.addPost(this.message)
      }
      this.message = null
    },
  },
}
</script>
