<template>
  <nav class="tab-bar mobile-tab-bar onboarding-recipebox-step-selector-mobile">
    <ul>
      <li class="dashboard-onboarding-step-1-selector-mobile">
        <b-link to="/plan" class="plan">
          <PlanDashboard class="nav-icon nav-icon--dashboard" />
          <span class="nav-text nav-text--plan">Plan</span>
        </b-link>
      </li>

      <li class="grocery-list-onboarding-step-1-selector-mobile">
        <b-link to="/grocery-list" class="grocery-list">
          <GroceryList class="nav-icon nav-icon--grocery-list" />
          Grocery List
        </b-link>
      </li>

      <li class="weekend-prep-onboarding-step-1-selector-mobile">
        <b-link to="/prep-ahead" class="weekend-prep">
          <WeekendPrep class="nav-icon nav-icon--weekend-prep" />
          Prep
        </b-link>
      </li>

      <li class="recipe-box-onboarding-step-1-selector-mobile">
        <b-link to="/recipe-box" class="recipe-box-link">
          <RecipeBox class="nav-icon nav-icon--recipe-box" />
          Recipe Box
        </b-link>
      </li>
      <li class="recipe-box-onboarding-step-1-selector-mobile">
        <b-link to="/personal-recipes" class="personal-recipe-link">
          <PrepOption class="nav-icon nav-icon--personal" />
          Personal
        </b-link>
      </li>
      <li>
        <b-link
          to="/settings/profile"
          class="settings onboarding-dashboard-step-4-mobile"
        >
          <Man class="nav-icon nav-icon--settings" />
          Settings
        </b-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import {
  PlanDashboard,
  GroceryList,
  WeekendPrep,
  RecipeBox,
  Man,
  PrepOption,
} from '@/components/common/MPIcons'

export default {
  name: 'BottomNavbar',
  components: {
    PlanDashboard,
    GroceryList,
    WeekendPrep,
    RecipeBox,
    PrepOption,
    Man,
  },
}
</script>

<style lang="scss" scoped>
$navbar-height: 65px;
.tab-bar {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 16;
  background: $white;
  height: $navbar-height;
  border-top: 1px solid $clr-navy-5;

  ul {
    width: 100%;
    margin: 0;
    display: inline-block;

    li {
      width: 16.5%;
      float: left;
      list-style: none;

      a {
        font-size: 1rem;
        text-align: center;
        display: block;
        width: 100%;
        height: $navbar-height;
        line-height: $navbar-height;
        display: block;
        color: $clr-navy-50;
        &.router-link-active,
        &:hover {
          background: $white;
          color: $link-color;
        }
        @include media-breakpoint-down(sm) {
          line-height: 1.5;
          font-size: 0.75rem;
        }

        @include media-breakpoint-down(xs) {
          font-size: 0.65rem;
        }

        .nav-icon {
          margin-right: 7px;
          margin-top: -3px;
          height: rem(23);
          width: rem(23);
          @include media-breakpoint-down(sm) {
            display: block;
            height: rem(20);
            width: rem(20);
            margin: 8px auto 5px;
          }
        }
        .nav-text--plan {
          @include media-breakpoint-down(sm) {
            margin-left: -5px;
          }
        }
      }
    }
  }
}

@supports (bottom: env(safe-area-inset-bottom)) {
  .tab-bar {
    height: calc(#{$navbar-height} + env(safe-area-inset-bottom));
    // https://github.com/webpack-contrib/sass-loader/issues/528#issuecomment-362259216
    padding-bottom: m#{a}x(4px, env(safe-area-inset-bottom));
  }
}

.mobile-tab-bar {
  position: fixed;
}
</style>
