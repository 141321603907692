<template>
  <div v-if="isLoading" class="d-flex justify-content-center mt-4 mt-md-0">
    <MPSpinner :withOverlay="false"></MPSpinner>
  </div>
  <div v-else class="settings-affiliate">
    <div class="main-infobox">
      <h3 class="m-0">{{ info.affName }} Referral Dashboard</h3>
      <span class="affiliate-text mt-3">
        Earn {{ info.affCommissionPercent }}% of every user’s subscription that
        signs up through your affiliate link for the first year! Share your
        unique referral link below to get started.
      </span>
      <div class="input-group mt-3">
        <MPInput
          type="text"
          size="lg"
          disable="true"
          v-model="info.affLink"
          readonly
          ref="affLink"
        />
        <div class="input-group-append">
          <MPButton color="orange" size="lg" @click="copyAffLink()">
            Copy
            <!-- checkmark-white -->
          </MPButton>
        </div>
      </div>
    </div>
    <div class="infobox-group mt-md-3">
      <div class="infobox infobox--big">
        <div class="title--big">Total Earnings</div>
        <div class="value--big">${{ info.totalEarnings }}</div>
      </div>
      <div class="infobox infobox--big">
        <div class="title--big">Pending Earnings</div>
        <div class="value--big">${{ info.pendingEarnings }}</div>
      </div>
    </div>
    <div class="infobox-group">
      <div class="infobox">
        <img src="@/assets/icons/affiliate-clicks.svg" alt="Unique clicks" />
        <div class="title mt-1">Unique clicks</div>
        <div class="value" v-text="info.validLinkClicks"></div>
      </div>
      <div class="infobox">
        <img src="@/assets/icons/affiliate-signups.svg" alt="Sign Ups" />
        <div class="title mt-1">Sign Ups</div>
        <div class="value" v-text="info.allUsersCount"></div>
      </div>
      <div class="infobox">
        <img src="@/assets/icons/affiliate-purchases.svg" alt="Purchases" />
        <div class="title mt-1">Purchases</div>
        <div class="value" v-text="info.payingUsersCount"></div>
      </div>
      <div class="infobox">
        <img
          src="@/assets/icons/affiliate-qualified.svg"
          alt="Qualified Subs"
        />
        <div class="title mt-1">Qualified Subs</div>
        <div class="value" v-text="info.qualifiedUsersCount"></div>
      </div>
    </div>
    <hr />
    <h3 class="text-center" style="font-weight: 500">Referral Commissions</h3>
    <div class="d-flex flex-column">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="date-addon">
            <img src="@/assets/icons/fi-calendar.svg" style="width: 21px" />
          </span>
        </div>
        <Datepicker
          :minimumView="'month'"
          :maximumView="'month'"
          :format="'MM/yyyy'"
          aria-label="Date"
          :bootstrap-styling="true"
          aria-describedby="date-addon"
          v-model="commissionsDate"
        ></Datepicker>
      </div>
      <div class="mt-1 mb-3 text-muted">Total: {{ commissions.length }}</div>
    </div>
    <div
      class="d-flex justify-content-center mt-4 mt-md-0"
      v-if="!areCommissionsLoaded"
    >
      <MPSpinner :withOverlay="false"></MPSpinner>
    </div>
    <div v-else class="table-responsive">
      <table class="table commissions-table">
        <thead>
          <tr>
            <th scope="col">Day</th>
            <th scope="col">User</th>
            <th scope="col">Status</th>
            <th scope="col">Membership</th>
            <th scope="col">Commission</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(commission, $index) in commissions" :key="$index">
            <td>{{ commission.date }}</td>
            <td>
              {{ commission.userName }}
              <i v-if="commission.sequence == 1" class="new-tag">NEW</i>
            </td>
            <td>
              <span
                class="commission-state-badge"
                :class="commission.stateType"
              >
                {{ commission.stateDisplay }}
              </span>
            </td>
            <td>{{ commission.type }}</td>
            <td>${{ commission.amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'SettingsAffiliate',
  components: { Datepicker },
  data() {
    return {
      commissionsDate: new Date(),
      areCommissionsLoaded: false,
    }
  },
  computed: {
    ...mapState('Affiliate', ['info']),
    ...mapGetters('Affiliate', ['commissions', 'isLoading']),
  },
  methods: {
    copyAffLink() {
      this.$refs.affLink.$el.select()
      document.execCommand('copy')
      document.getSelection().removeAllRanges()
    },
  },
  watch: {
    commissionsDate: {
      handler(value) {
        this.areCommissionsLoaded = false
        this.$store.dispatch('Affiliate/getCommissions', value).then(() => {
          this.areCommissionsLoaded = true
        })
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group-append {
  z-index: 0;
}
.new-tag {
  position: relative;
  top: 0;
  color: $pale-red;
  font-size: 0.5rem;
  top: -10px;
}
.infobox-group {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin: 0 -10px;
  flex-wrap: wrap;
}
.infobox {
  background-color: #fcfcfc;
  border: 1px solid rgba(229, 229, 229, 0.5);
  text-align: center;
  padding: 1rem;
  border-radius: 3px;
  margin: 10px;
  flex: 1 0 44%;
  &--big {
    flex: 1 0 50%;
  }
  .title {
    font-size: 0.75rem;
    &--big {
      font-size: 1rem;
    }
  }
  .value {
    font-size: 1.875rem;
    &--big {
      font-size: 2.5rem;
      color: #2bc756;
    }
  }
  @include media-breakpoint-up(md) {
    flex: 1 0 0;
  }
}
.main-infobox {
  text-align: center;
  border: 1px solid rgba($light-grey, 0.5);
  border-radius: 3px;
  background-color: #f5f6f7;
  padding: 1rem;
  margin-top: 1rem;
  @include media-breakpoint-up(md) {
    margin-top: 0;
    padding: 30px 70px;
  }
}

.affiliate-text {
  display: inline-block;
  color: $grey;
}

.commission-state-badge {
  display: block;
  padding: 5px 20px;
  text-transform: uppercase;
  border-radius: 3px;
  font-weight: 600;
  text-align: center;
  font-size: 0.75rem;
  color: white;

  &.pending {
    background-color: $lighter-light-grey;
    color: #747e8b;
  }

  &.cancelled {
    background-color: $pale-red;
  }

  &.qualified {
    background-color: $dusty-orange;
    opacity: 0.7;
  }

  &.processing {
    background-color: $pale-olive;
    opacity: 0.7;
  }

  &.paid {
    background-color: $pale-olive;
  }
}
</style>
<style lang="scss">
.settings-affiliate {
  .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover {
    border-color: transparent;
  }
  .vdp-datepicker__calendar .cell.selected {
    background: $orange;
  }
  .form-control {
    color: $grey;
    &:read-only {
      background: $white;
    }
  }
}
</style>
