import WeekendPrepApi from '@/api/WeekendPrepApi'
import * as types from '../mutation-types'
import _ from 'lodash'
import { toFraction, slugify } from '@/utils/conversions'
import Fraction from 'fraction.js'
import IngredientHelpers from '@/IngredientHelpers'
import Vue from 'vue'

const state = {
  weekendPrepRawData: {},
  weekendPrepData: [],
  speedPrep: {
    id: 0,
    cardClass: 'speed-prep',
    cardTitle: 'Speed Prep',
    speedPrepElements: [],
  },
  isLoading: false,
  weekendPrepNote: null,
}

const getters = {
  speedPrep: (state) => state.speedPrep,
  speedPrepElements: (state) => state.speedPrep.speedPrepElements,
  weekendPrep: (state) => state.weekendPrepData,
  isLoading: (state) => state.isLoading,
  weekendPrepOrdered: (state) => _.orderBy(state.weekendPrepData, 'id', 'asc'),
  weekendPrepNote: (state) => state.weekendPrepNote,
}

const actions = {
  initWeekendPrep({ dispatch, commit }) {
    commit(types.WEEKEND_PREP_DATA_LOADING, true)
    return Promise.all([
      dispatch('getSpeedPrepData'),
      dispatch('getWeekendPrepData'),
    ])
      .then(() => {
        commit(types.WEEKEND_PREP_DATA_LOADING, false)
      })
      .catch(() => {
        commit(types.WEEKEND_PREP_DATA_LOADING, false)
      })
  },
  getWeekendPrepData({ commit, rootGetters }) {
    const params = {
      user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
    }
    return weekendPrepEndpointsStrategy(rootGetters['globals/previewParams'])
      .getWeekendPrepData(
        rootGetters['globals/mealPlan'],
        params,
        rootGetters['globals/previewParams']
      )
      .then(
        (data) => {
          commit(types.SET_WEEKEND_PREP_DATA, data.weekendPrepSections)
          commit(types.SET_WEEKEND_PREP_NOTE, data.weekendPrepNote)
          return data
        },
        (error) => {
          return error
        }
      )
  },
  clearWeekendPrepData({ commit }) {
    commit(types.CLEAR_WEEKEND_PREP_DATA)
  },
  async setWeekendPrepNote({ commit, rootGetters }, text) {
    const userWeeklyPlan = rootGetters['globals/userWeeklyPlanId']
    try {
      const result = await WeekendPrepApi.setWeekendPrepNote(
        userWeeklyPlan,
        text
      )
      commit(types.SET_WEEKEND_PREP_NOTE, result.weekendPrepNote)
      return result
    } catch (err) {
      commit(types.SET_WEEKEND_PREP_NOTE, null)
      return err
    }
  },
  async getSpeedPrepData({ commit, rootGetters }) {
    const params = {
      user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
    }
    try {
      const res = await speedPrepEndpointsStrategy(
        rootGetters['globals/previewParams']
      ).getSpeedPrepData(
        rootGetters['globals/mealPlan'],
        params,
        rootGetters['globals/previewParams']
      )
      const data = buildSpeedPrep(res)
      commit(types.SET_SPEED_PREP_DATA, data)
      return res
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  async toggleExclude({ rootGetters }, { ingredient, isExcluded }) {
    try {
      return await WeekendPrepApi.toggleExclude(
        rootGetters['globals/userWeeklyPlanId'],
        { ...ingredient, isExcluded: isExcluded }
      )
    } catch (err) {
      console.log(err)
      throw err
    }
  },
}

const mutations = {
  [types.SET_WEEKEND_PREP_DATA](state, data) {
    state.weekendPrepRawData = data
    processRawData(state)
  },
  [types.CLEAR_WEEKEND_PREP_DATA](state) {
    state.weekendPrepRawData = {}
    processRawData(state)
  },
  [types.WEEKEND_PREP_DATA_LOADING](state, isLoading) {
    state.isLoading = isLoading
  },
  [types.SET_WEEKEND_PREP_NOTE](state, note) {
    state.weekendPrepNote = note
  },
  [types.SET_SPEED_PREP_DATA](state, data) {
    Vue.set(state.speedPrep, 'speedPrepElements', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

function buildSpeedPrep(data) {
  const speedPrepData = []
  if (!_.isEmpty(data)) {
    data.forEach((element) => {
      const name = [
        element.batchIngredient.attribute || '',
        element.quantity > 1
          ? element.batchIngredient.pluralName
          : element.batchIngredient.singularName,
      ]
        .filter((i) => i)
        .join(' ')
      element.completeQuantities = `${getCompleteIngredientQuantities(
        0,
        element.quantities
      )} ${name}`
      element.ingredients.map((ingredient) => {
        ingredient.completeQuantities = getCompleteIngredientQuantities(
          0,
          ingredient.quantities
        )
        ingredient.completeVolumes =
          ingredient.volumes.length && ingredient.volumes[0].quantity > 0
            ? ` (≈${getCompleteIngredientQuantities(0, ingredient.volumes)} ${
                ingredient.volumeDescription
              })`
            : ''
        return ingredient
      })
      speedPrepData.push(element)
    })
  }
  return speedPrepData
}

function weekendPrepEndpointsStrategy(previewParams) {
  if (previewParams)
    return {
      getWeekendPrepData: WeekendPrepApi.getWeekendPrepPreviewData,
    }
  else
    return {
      getWeekendPrepData: WeekendPrepApi.getWeekendPrepData,
    }
}

function speedPrepEndpointsStrategy(previewParams) {
  if (previewParams)
    return {
      getSpeedPrepData: WeekendPrepApi.getSpeedPrepPreviewData,
    }
  else
    return {
      getSpeedPrepData: WeekendPrepApi.getSpeedPrepData,
    }
}

function processRawData(state) {
  state.weekendPrepData = []
  for (const [key, value] of Object.entries(state.weekendPrepRawData)) {
    if (value.prepAhead && value.prepAhead.length > 0) {
      const snakeCaseKey = key.replace(/([A-Z])/g, ' $1').trim()
      const card = {
        id: value.order,
        cardClass: slugify(snakeCaseKey),
        cardTitle: snakeCaseKey.toLowerCase(),
        weekendPrepElements: [],
      }
      value.prepAhead.forEach((prepAhead, index) => {
        let weekendPrepElement
        if (prepAhead.isHeaderTemplate) {
          weekendPrepElement = buildForHeaderTemplate(prepAhead, index)
        } else if (prepAhead.isComponent) {
          weekendPrepElement = buildForComponent(prepAhead, index)
        } else {
          weekendPrepElement = buildForStandAlone(prepAhead, index)
        }
        card.weekendPrepElements.push(weekendPrepElement)
      })
      state.weekendPrepData.push(card)
    }
  }
}

// Helpers
function buildForHeaderTemplate(prepAhead, index) {
  let weekendPrepElement = {
    id: index,
    title: prepAhead.weekendPrepTitle,
    ingredients: buildIngredients(prepAhead),
    prepAhead: {
      toMake: '',
      description: prepAhead.text,
      //usedInText: `Makes ` + prepAhead.batchTotal + `. Used in:`,
      usedInText: `Used in:`,
      usedIn: buildUsedIn(prepAhead),
      store: prepAhead.storage,
    },
  }
  weekendPrepElement.prepAhead.usedIn.map(function (usedIn) {
    usedIn.nameSlug = usedIn.title.replace(/\s+/g, '-').toLowerCase()
  })
  return weekendPrepElement
}

function buildForComponent(prepAhead, index) {
  let weekendPrepElement = {
    id: index,
    title: prepAhead.weekendPrepTitle,
    ingredients: buildIngredients(prepAhead.component),
    prepAhead: {
      toMake: `To make the ${prepAhead.name.toLowerCase()}`,
      description: prepAhead.text,
      // usedInText:
      //   `Makes ` +
      //   prepAhead.batchTotal +
      //   ` ` +
      //   prepAhead.shortName +
      //   `. Used in:`,
      usedInText: `Used in:`,
      usedIn: buildUsedIn(prepAhead.component),
      store: prepAhead.storage,
    },
  }
  weekendPrepElement.prepAhead.usedIn.map(function (usedIn) {
    usedIn.nameSlug = usedIn.title.replace(/\s+/g, '-').toLowerCase()
  })
  return weekendPrepElement
}

function buildForStandAlone(prepAhead, index) {
  let weekendPrepElement = {
    id: index,
    title: prepAhead.recipe.weekendPrepTitle,
    ingredients: buildIngredients(prepAhead),
    prepAhead: {
      toMake: '',
      description: prepAhead.text,
      usedInText: 'Used in:',
      usedIn: buildUsedIn(prepAhead),
      store: prepAhead.storage,
    },
  }
  weekendPrepElement.prepAhead.usedIn.map(function (usedIn) {
    usedIn.nameSlug = usedIn.title.replace(/\s+/g, '-').toLowerCase()
  })
  return weekendPrepElement
}

function toFractionFilter(input) {
  let value = String(input)
  let number = value

  if (value && value.indexOf('.') != -1) {
    number = value.split('.')
    if (number.length > 1 && number[1].slice(-1) === '3') {
      number = String(value + '(' + 3 + ')')
    } else if (number.length > 1 && number[1].slice(-1) === '6') {
      // covers 0.66
      number = String(value + '(' + 6 + ')')
    } else if (
      number.length > 1 &&
      (number[1].slice(-2) === '67' || number[1].slice(-2) === '65')
    ) {
      // covers 0.67 cases -> 0.6(6)
      number = String(value.slice(0, -1) + '(' + 6 + ')')
    } else {
      number = value
    }
  }
  if (number) {
    let fraction = new Fraction(number)

    let splitedNumber = fraction.toFraction(true).split(' ')
    if (splitedNumber.length > 1) {
      let fractionedNumber = splitedNumber[1].split('/')
      fraction.html =
        splitedNumber[0] +
        ' <span class="fraction"><sup>' +
        fractionedNumber[0] +
        '</sup>&frasl;<sub>' +
        fractionedNumber[1] +
        '</sub></span>'
    } else {
      let fractionedNumber = splitedNumber[0].split('/')
      if (fractionedNumber.length === 2) {
        fraction.html =
          '<span class="fraction"><sup>' +
          fractionedNumber[0] +
          '</sup>&frasl;<sub>' +
          fractionedNumber[1] +
          '</sub></span>'
      } else {
        fraction.html = fraction.toFraction(true)
      }
    }

    return fraction
  } else {
    return input
  }
}

function buildVolume(ingredient, batchVolume) {
  if (!ingredient) {
    return ''
  }
  let volumeUnit = ingredient.defaultVolumeUnit || ''
  let quantity = toFractionFilter(batchVolume || ingredient['volume'] || '')
  let volumeDescription = ingredient['volumeDescription'] || ''

  let output = ''

  if (!volumeUnit && !quantity && !volumeDescription) {
    return ''
  }
  if (quantity) {
    output = quantity.html
    if (volumeUnit) {
      if (quantity > 1) {
        output = output + ' ' + volumeUnit.pluralName
      } else {
        output = output + ' ' + volumeUnit.name
      }
    }
  } else {
    if (volumeUnit) {
      if (quantity > 1) {
        output = output + volumeUnit.pluralName
      } else {
        output = output + volumeUnit.name
      }
    }
  }
  if (volumeDescription) {
    output = output + ' ' + volumeDescription
  }
  return '(≈' + output + ')'
}

function buildIngredients(prepAhead) {
  let ingredients = []
  if (prepAhead.recipe) {
    prepAhead.recipe.ingredients.forEach((ingredient) => {
      ingredients.push({
        id: ingredient.id,
        title:
          ingredient.attribute +
          ' ' +
          (ingredient.quantity > 1
            ? ingredient.ingredient.singularName
            : ingredient.ingredient.pluralName),
        prepInfo: ingredient.prepDescription + ' ' + buildVolume(ingredient),
        quantity: getCompleteIngredientQuantities(0, ingredient.quantities),
        speedPrep: ingredient.isSpeedPrep,
      })
    })
  } else if (prepAhead.ingredients) {
    prepAhead.ingredients.forEach((ingredient) => {
      ingredients.push({
        id: ingredient.id,
        title:
          ingredient.attribute +
          ' ' +
          (ingredient.quantity > 1
            ? ingredient.ingredient.singularName
            : ingredient.ingredient.pluralName),
        prepInfo: ingredient.prepDescription + ' ' + buildVolume(ingredient),
        quantity: getCompleteIngredientQuantities(0, ingredient.quantities),
        speedPrep: ingredient.isSpeedPrep,
      })
    })
  } else {
    const s = new Set()
    prepAhead.recipes.forEach((recipe) => {
      recipe.ingredients.forEach((ingredient) => {
        s.add(ingredient.prepDescription)
      })
    })
    ingredients = [
      {
        id: 0,
        title: '',
        prepInfo: [...s].join(','),
        quantity: prepAhead.batchTotal,
        speedPrep: false,
      },
    ]
  }
  return ingredients
}
function buildUsedIn(prepAhead) {
  let usedIn = []
  if (prepAhead.recipe) {
    usedIn = [
      {
        id: prepAhead.recipe.id,
        title: prepAhead.recipe.title,
        quantity: '',
      },
    ]
  } else {
    // Recipes
    prepAhead.recipes.forEach((recipe) => {
      if (recipe['ingredients'] && recipe.ingredients.length === 1) {
        usedIn.push({
          id: recipe.id,
          title: recipe.title,
          quantity: getCompleteIngredientQuantities(
            0,
            recipe.ingredients[0].quantities
          ),
          volume: IngredientHelpers.getVolume(recipe.ingredients[0]),
        })
      } else if (recipe['quantity']) {
        usedIn.push({
          id: recipe.id,
          title: recipe.title,
          quantity:
            toFraction(recipe.quantity) +
            (recipe.unit ? ' ' + recipe.unit : ''),
        })
      } else {
        usedIn.push({
          id: recipe.id,
          title: recipe.title,
          quantity: '',
        })
      }
    })
  }
  return usedIn
}

function getCompleteIngredientQuantities(minimumUnit, quantities) {
  let quantitiesString = ''
  if (!quantities) return quantitiesString
  let first = true
  if (minimumUnit > 0) {
    quantities.forEach(function (i, index, q) {
      let quantity = q[index]
      let qty = quantity.quantity
      if (qty > 0) {
        if (!first) quantitiesString += ' + '
        first = false
        let finalQty = toFraction(qty)
        let fraction = qty - Math.floor(qty)
        if (minimumUnit == 1.0) {
          finalQty = Math.ceil(qty)
        } else if (
          fraction > 0 &&
          fraction < minimumUnit &&
          minimumUnit > 0 &&
          minimumUnit < 1
        ) {
          finalQty = toFraction(Math.floor(qty) + minimumUnit)
        } else if (
          fraction > minimumUnit &&
          fraction < minimumUnit &&
          minimumUnit > 0 &&
          minimumUnit < 1
        ) {
          finalQty = Math.ceil(qty)
        }

        quantitiesString += '' + finalQty
        if (quantity.unit) {
          quantitiesString += ' ' + quantity.unit
          // if (!(qty.length > index+1 && qty[index+1].unit == quantity.unit)) {
          //   quantitiesString += ' ' + quantity.unit
          // }
        }
      }
    })
  } else {
    quantities.forEach(function (i, index, qty) {
      let quantity = qty[index]
      if (quantity.quantity > 0 && quantity.quantity % 1 == 0) {
        if (!first) quantitiesString += ' + '
        quantitiesString += '' + quantity.quantity
        first = false
      } else if (quantity.quantity > 0) {
        if (!first) quantitiesString += ' + '
        quantitiesString += toFraction(quantity.quantity)
        first = false
      }
      if (quantity.unit) {
        quantitiesString += ' ' + quantity.unit
        // if (!(qty.length > index+1 && qty[index+1].unit == quantity.unit)) {
        //   quantitiesString += ' ' + quantity.unit
        // }
      }
    })
  }
  return quantitiesString
}
