import api from './'
import camelizeKeys from './camelizeKeys'
import environment from '@/environment'

const DASHBOARD_URL = 'dashboard/'
const DASHBOARD_LATEST_URL = 'dashboard/latest/'
const DASHBOARD_RECIPES_URL = 'dashboard/recipes/'
const DASHBOARD_ADD_MEAL_URL = 'dashboard/add-meal/'
const DASHBOARD_REMOVE_MEAL_URL = 'dashboard/remove-meal/'
const DASHBOARD_MOVE_MEAL_URL = 'dashboard/move_recipe/'
const DASHBOARD_VALIDATE_VERSION_URL = 'dashboard/validate-meal-plan-version/'
const DASHBOARD_USER_PLAN_CLEAR_URL = 'dashboard/user-plan/clear/'
const DASHBOARD_USER_PLAN_RESET_URL = 'dashboard/user-plan/reset/'
const DASHBOARD_USER_PLAN_DELETE_URL = 'dashboard/user-plan/delete/'
const DASHBOARD_MEAL_PLANS_URL = 'dashboard/meal-plans/'
const DASHBOARD_PRINT_PDF_URL = 'dashboard/printed-files-as-pdf/'
const DASHBOARD_SAVE_AS_CUSTOM_PLAN_URL = 'dashboard/save-as-custom-plan/'
const DASHBOARD_RENAME_CUSTOM_PLAN_URL = 'dashboard/rename-custom-plan/'
const DASHBOARD_PREVIEW_URL = 'dashboard/preview/'
const DASHBOARD_PREVIEW_RECIPES_URL = 'dashboard/recipes/preview/'
const DASHBOARD_SWAP_ALL_RECIPE_INSTANCES_FROM_PLAN =
  'dashboard/swap-all-instances-of-recipe-on-plan/'
const DASHBOARD_SAVE_SHARED = 'dashboard/copy-shared-plan/'

export default {
  getDashboard({ type }, options, cancelExecutor) {
    return api
      .get(DASHBOARD_URL + type, options, cancelExecutor)
      .then((res) => {
        return { camelized: camelizeKeys(res.data), original: res.data }
      })
  },
  getDashboardLatest() {
    return api.get(DASHBOARD_LATEST_URL).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  getDashboardPreview(previewParams, options, cancelExecutor) {
    return api
      .get(
        `${DASHBOARD_PREVIEW_URL + previewParams.mealPlanId}/${previewParams.token
        }/`,
        {},
        cancelExecutor
      )
      .then((res) => {
        return camelizeKeys(res.data)
      })
  },
  getMeals({ type }, options, cancelExecutor) {
    return api
      .get(DASHBOARD_RECIPES_URL + type, options, cancelExecutor)
      .then((res) => {
        return camelizeKeys(res.data)
      })
  },
  getMealsPreview(previewParams, options, cancelExecutor) {
    return api
      .get(
        `${DASHBOARD_PREVIEW_RECIPES_URL + previewParams.mealPlanId}/${previewParams.token
        }/`,
        {},
        cancelExecutor
      )
      .then((res) => {
        return camelizeKeys(res.data)
      })
  },
  // meal_plan_type, recipe_id, day, meal_type, version, replace
  addMeal(options) {
    return api.post(DASHBOARD_ADD_MEAL_URL, options).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  swapAllRecipeInstancesFromPlan(options) {
    return api
      .post(DASHBOARD_SWAP_ALL_RECIPE_INSTANCES_FROM_PLAN, options)
      .then((res) => {
        return camelizeKeys(res.data)
      })
  },
  // meal_plan_type, recipe_id, day, meal_type, version
  removeMeal(options) {
    return api.post(DASHBOARD_REMOVE_MEAL_URL, options).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  // src_user_recipe_id, src_day, src_meal_type, dest_user_recipe_id, dest_day, dest_meal_type, version
  moveMeal(options) {
    return api.post(DASHBOARD_MOVE_MEAL_URL, options).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  clearDashboard(dashboardType, options) {
    return api.post(
      DASHBOARD_USER_PLAN_CLEAR_URL + dashboardType + '/',
      options
    )
  },
  resetDashboard(dashboardType, options) {
    return api.post(
      DASHBOARD_USER_PLAN_RESET_URL + dashboardType + '/',
      options
    )
  },
  deleteUserMealPlan(userWeeklyPlanId) {
    const data = {
      user_weekly_plan_id: userWeeklyPlanId,
    }
    return api.post(DASHBOARD_USER_PLAN_DELETE_URL, data)
  },
  saveAsCustomPlan(options) {
    return api.post(DASHBOARD_SAVE_AS_CUSTOM_PLAN_URL, options).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  renameCustomPlan(options) {
    return api.post(DASHBOARD_RENAME_CUSTOM_PLAN_URL, options).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  validateVersion(mealPlanType, version) {
    return api
      .post(DASHBOARD_VALIDATE_VERSION_URL, { version: version })
      .then((res) => {
        return camelizeKeys(res.data)
      })
  },
  getMealPlans() {
    return api.get(DASHBOARD_MEAL_PLANS_URL).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  getPdfFiles(
    mealPlan,
    userWeeklyPlanId,
    services,
    printRecipeImageOption,
    callback
  ) {
    return api
      .get(
        `${environment.printBaseUrl}${DASHBOARD_PRINT_PDF_URL}${mealPlan}/?services=${services}&printRecipeImage=${printRecipeImageOption}`,
        {
          user_weekly_plan_id: userWeeklyPlanId,
        },
        () => { },
        'arraybuffer'
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL || window.webkitURL
        const fileUrl = url.createObjectURL(blob)

        var res = {
          success: true,
          fileUrl: fileUrl,
          blob: blob,
        }

        callback(res)
      })
      .catch((err) => {
        var res = {
          success: false,
          message: err.detail,
          code: err.code,
        }
        callback(res)
      })
  },
  saveSharedAsCustom(id) {
    return api.post(DASHBOARD_SAVE_SHARED, {
      user_weekly_plan_id: id,
    }).then(res => {
      return camelizeKeys(res.data)
    })
  },
}
