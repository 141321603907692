export function loadExternalScript(src, async = true, element) {
  return new Promise(function (resolve) {
    const script = document.createElement('script')
    script.async = async
    script.src = src
    if (element) {
      element.appendChild(script)
    } else {
      const firstScript = document.getElementsByTagName('script')[0]
      firstScript.parentNode.insertBefore(script, firstScript)
    }
    script.onload = () => {
      resolve()
    }
  })
}

export function addScript(innerScript) {
  return new Promise(function (resolve) {
    const script = document.createElement('script')
    script.innerHTML = innerScript
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(script, firstScript)
    script.onload = () => {
      resolve()
    }
  })
}
