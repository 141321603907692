<template>
  <input
    :class="`form-control ${computedSize}`"
    :value="value"
    :type="type"
    @input="$emit('input', $event.target.value)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    :maxlength="maxLenght"
    ref="textInput"
    :autofocus="focus"
    :data-spec="dataSpec"
  />
</template>

<script>
export default {
  name: 'MPInput',
  props: {
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    value: {},
    maxLenght: {
      type: Number,
      default: 90,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    dataSpec: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedSize() {
      return this.size ? `form-control-${this.size}` : ''
    },
  },
  methods: {
    getTextInput() {
      return this.$refs.textInput
    },
  },
}
</script>

<style lang="scss" scoped>
.form-control {
  margin: 0;
  letter-spacing: 0.4px;
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;

  &:focus {
    box-shadow: none;
    border-color: $primary;
  }
  &-xl {
    font-size: 20px;
    height: 50px;
    @include media-breakpoint-down(sm) {
      font-size: 18px;
    }
  }
  &-lg {
    font-size: 16px;
    height: 50px;
  }
  &-sm {
    font-size: 12px;
    height: 30px;
  }
  &::placeholder {
    letter-spacing: 0.2px;
    color: $clr-navy-50;
  }
  &.invalid {
    border: 1px solid $error;
    animation: animate-border 5s linear 0s infinite alternate;
  }
}
</style>
