<template>
  <span
    class="mp-close-icon"
    :class="`mp-close-icon--${color}`"
    @click="$emit('click', $event)"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="size"
      :height="size"
      viewBox="0 0 15 15"
    >
      <g
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      >
        <path d="M12.944 1.93L2.224 12.65M12.944 12.65L2.224 1.93" />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'grey',
    },
    size: {
      type: Number,
      default: 15,
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-close-icon {
  cursor: pointer;
  &--grey {
    g {
      stroke: #747e8b;
    }
    &:hover g {
      stroke: #404852;
    }
  }
}
</style>
