import apiRecipes from '@/api/recipes'
import listItems from '@/components/recipebox/listItemsBuilder'
import menuItems from '@/components/recipebox/menuitems/menuItemsData'
import Vue from 'vue'

import * as types from './../mutation-types'
import axios from 'axios'
import _ from 'lodash'
import { types as componentTypes } from '@/components/recipebox/sideBar'
const { FILTERS_TYPE, COMPONENT_TYPES } = componentTypes

let controlRequest

function cancelRequest(c) {
  controlRequest = c
}

// initial state
const state = {
  allRecipesStatus: null,
  recipes: [],
  primaryRecipes: [],
  recipeBoxs: [],
  searchRecipesStatus: null,
  searchRecipes: null,
  isAlternativeResult: false, // when no exact match is found, results can come back as alternative result suggestions
  searchText: '',
  isAddRecipeBox: false,
  showPlanDisabled: false,
  localFavorites: {},
  categories: [],
  categoriesOrder: {},
  filters: getSavedFilters(),
  defaultFilters: getDefaultFilters(),
  currentMealPlan: [],
  isFiltering: false,
  recipePage: 1,
  recipeHasMorePages: true,
  recipeQueryStatus: null,
  recipesFound: 0,
  recipeFilters: {},
  filtersApplied: getDefaultFiltersApplied(),
  excludedRecipeIds: [],
  primaryCount: 0,
  primaryLabel: '',
  generalLabel: '',
  generalCount: 0,
  primaryAsGeneral: false,
}

// getters
const getters = {
  isLoading: (state) => state.allRecipesStatus != null,
  primaryRecipes: (state) => state.primaryRecipes,
  recipeQueryStatus: (state) => state.recipeQueryStatus,
  recipePage: (state) => state.recipePage,
  recipeHasMorePages: (state) => state.recipeHasMorePages,
  recipesFound: (state) => state.recipesFound,
  allRecipesFound: (state) => state.recipesFound + state.primaryRecipes.length,
  recipeBoxs: (state) => state.recipeBoxs,
  filterByRatingSelected: (state) => state.filtersApplied.byRating,
  recipeFilters: (state) => state.recipeFilters,
  filtersApplied: (state) => state.filtersApplied,
  searchText: (state) => state.searchText,
  primaryCount: (state) => state.primaryCount,
  generalCount: (state) => state.generalCount,
  primaryLabel: (state) => state.primaryLabel,
  generalLabel: (state) => state.generalLabel,
  caloriesValues: (state) => state.filtersApplied.caloriesValues,
  caloriesSelected: (state) => state.filtersApplied.calories,
  totalFiltersApplied: function (state) {
    let totalApplied = 0
    getAvailableFilters().forEach((f) => {
      const filters = state.filtersApplied[f]
      switch (f) {
        case FILTERS_TYPE.INGREDIENTS:
          totalApplied += filters.included.length + filters.excluded.length
          break
        case FILTERS_TYPE.CALORIES:
          if (filters !== COMPONENT_TYPES.CALORIES_DEFAULT) {
            totalApplied++
          }
          break
        case FILTERS_TYPE.PREP_TIME:
          if (filters !== COMPONENT_TYPES.PREP_DEFAULT) {
            totalApplied++
          }
          break
        case FILTERS_TYPE.RATING:
          if (filters !== COMPONENT_TYPES.RATING_DEFAULT) {
            totalApplied++
          }
          break
        default:
          if (filters) {
            totalApplied++
          }
          break
      }
    })

    return totalApplied
  },
  filteredRecipes: (state) => {
    if (state.recipeQueryStatus === 'loading') return []
    addCategoryDataToRecipes(state.recipeBoxs, state.categories)
    addCategoryDataToRecipes(state.primaryRecipes, state.categories)

    return [...state.primaryRecipes, ...state.recipes]
  },
  numberOfAllPlannerRecipes: (state) => {
    return [...state.primaryRecipes, ...state.recipes].filter(
      (r) => !r['is_custom']
    ).length
  },
  recipeCategories: (state) => state.categories,
  selectedTags: ({ filters }) => ({
    included: [
      ...filters.intolerances.included,
      ...filters.flavorProfile.included,
    ],
    excluded: [
      ...filters.intolerances.excluded,
      ...filters.flavorProfile.excluded,
    ],
  }),
  isAlternativeResult: (state) => state.isAlternativeResult,
  selectedTagFiltersSelected: ({ filtersApplied }) => {
    const included = []
    const excluded = []

    if (filtersApplied[FILTERS_TYPE.FLAVOR_PROFILE]) {
      included.push(filtersApplied[FILTERS_TYPE.FLAVOR_PROFILE])
    }
    if (filtersApplied[FILTERS_TYPE.FLAVOR_TYPE]) {
      included.push(filtersApplied[FILTERS_TYPE.FLAVOR_TYPE])
    }
    if (filtersApplied[FILTERS_TYPE.MEALTYPE]) {
      included.push(filtersApplied[FILTERS_TYPE.MEALTYPE])
    }
    if (filtersApplied[FILTERS_TYPE.TEMPERATURE_TYPE]) {
      included.push(filtersApplied[FILTERS_TYPE.TEMPERATURE_TYPE])
    }

    included.push(...filtersApplied[FILTERS_TYPE.INGREDIENTS].included)
    excluded.push(...filtersApplied[FILTERS_TYPE.INGREDIENTS].excluded)

    return {
      included,
      excluded,
    }
  },
  getPrimaryAsGeneral: (state) => state.primaryAsGeneral,
}

// actions
const actions = {
  refresh({ commit, dispatch }) {
    commit(types.SET_ALL_RECIPES_STATUS, 'loading')
    commit(types.SET_RECIPES_QUERY_STATUS, 'loading')

    return Promise.all([
      dispatch('getRecipes', {}),
      dispatch('getRecipeFilters'),
      dispatch('getCategories'),
    ]).then(() => {
      commit(types.SET_ALL_RECIPES_STATUS, null)
    })
  },

  /**
   * Get Recipes Action
   *
   * @param {object} commit
   * @param {object} options
   * @returns
   */
  async getRecipes(
    { commit, rootGetters, getters, state },
    { page = 1, searchText = '', filters = null }
  ) {
    commit(
      types.SET_RECIPES_QUERY_STATUS,
      page === 1 ? 'loading' : 'loadingPages'
    )
    commit(types.SET_ALL_RECIPES_STATUS, 'loading')

    if (!filters) {
      filters = getDefaultFiltersApplied()
    }

    let params = {
      page: page,
      q: searchText,
    }
    let tags = {
      included: [],
      excluded: [],
    }

    if (FILTERS_TYPE.INGREDIENTS in filters) {
      params['ingredients_in'] = filters[FILTERS_TYPE.INGREDIENTS].included
        .map((f) => f.id)
        .join(',')
      params['ingredients_not_in'] = filters[FILTERS_TYPE.INGREDIENTS].excluded
        .map((f) => f.id)
        .join(',')
    }

    // Tag filters
    ;[FILTERS_TYPE.FLAVOR_PROFILE, FILTERS_TYPE.INTOLERANCES].forEach((f) => {
      if (filters[f]) {
        tags['included'].push(filters[f].id)
      }
    })

    const flavorAndTemp = []
    ;[FILTERS_TYPE.FLAVOR_TYPE, FILTERS_TYPE.TEMPERATURE_TYPE].forEach((f) => {
      if (filters[f]) {
        flavorAndTemp.push(filters[f].id)
      }
    })

    params['flavor_type_in'] = flavorAndTemp.join(',')

    if (FILTERS_TYPE.MEALTYPE in filters && filters[FILTERS_TYPE.MEALTYPE]) {
      params['category_in'] = filters[FILTERS_TYPE.MEALTYPE].id
    }

    if (
      FILTERS_TYPE.KID_APPROVED in filters &&
      filters[FILTERS_TYPE.KID_APPROVED]
    ) {
      params['kid_approved'] = 'yes'
    }

    if (
      FILTERS_TYPE.NO_WEEKEND_PREP in filters &&
      filters[FILTERS_TYPE.NO_WEEKEND_PREP]
    ) {
      params['weekend_prep'] = 'yes'
    }

    if (
      FILTERS_TYPE.PREP_TIME in filters &&
      filters[FILTERS_TYPE.PREP_TIME] < COMPONENT_TYPES.PREP_MAX
    ) {
      params['prep_time'] = filters[FILTERS_TYPE.PREP_TIME]
    }

    if (
      FILTERS_TYPE.CALORIES in filters &&
      filters[FILTERS_TYPE.CALORIES] < COMPONENT_TYPES.CALORIES_MAX
    ) {
      params['calories'] = filters[FILTERS_TYPE.CALORIES]
    }

    if (FILTERS_TYPE.RATING in filters) {
      params['rating'] = filters[FILTERS_TYPE.RATING]
    }

    if (
      FILTERS_TYPE.ONLY_FAVORITES in filters &&
      filters[FILTERS_TYPE.ONLY_FAVORITES]
    ) {
      params['favorites_only'] = 'yes'
    }

    if (page > 1 && state.excludedRecipeIds.length > 0) {
      params['exclude_ids'] = state.excludedRecipeIds.join(',')
    }

    params['recipe_tags_in'] = tags.included.join(',')
    params['recipe_tags_not_in'] = tags.excluded.join(',')

    if (
      (getters['recipeQueryStatus'] || getters['isLoading']) &&
      controlRequest
    ) {
      controlRequest('cancelled request')
    }

    try {
      const response = await apiRecipes.getAllRecipes(params, cancelRequest)
      commit(types.SET_ALL_RECIPES_STATUS, null)
      commit(types.SET_RECIPES_QUERY_STATUS, null)
      commit(types.UPDATE_RECIPE_PAGE, page + 1)
      controlRequest = null

      if (!response) {
        return null
      }

      if (response.primaryResults.length > 0) {
        state.excludedRecipeIds.push(
          ...response.primaryResults.map((r) => r.id)
        )
      }

      let recipesFound = response.count
      commit(types.SET_RECIPES_HAS_MORE_PAGES, response.next !== null)

      const searchTypes = {
        recipes: response.recipes,
        primaryRecipes: response.primaryResults,
        searchText,
        isAlternativeResult: false,
        isFiltering: true,
        resetRecipes: page === 1,
        recipesFound,
        rootGetters,
        primaryLabel: response.primaryLabel,
        generalLabel: response.generalLabel,
      }

      if (typeof response.primaryCount === 'number') {
        searchTypes.primaryCount = response.primaryCount
      }

      if (typeof response.generalCount === 'number') {
        searchTypes.generalCount = response.generalCount
      }

      commit(types.SET_PRIMARY_AS_GENERAL, response.primaryAsGeneral)
      commit(types.RECEIVE_SEARCH_RECIPES, searchTypes)

      return response.recipes
    } catch (error) {
      if (axios.isCancel(error)) return null
      commit(types.SET_RECIPES_QUERY_STATUS, null)
      commit(types.SET_ALL_RECIPES_STATUS, null)
    }
  },

  /**
   * Get recipe filters.
   *
   * @param {object} options
   * @returns Promise
   */
  getRecipeFilters({ commit }) {
    return apiRecipes.getRecipeFilters().then((filters) => {
      commit(types.SET_RECIPE_FILTERS, filters)

      return filters
    })
  },

  /**
   * Define if recipes archive has more pages.
   *
   * @param {object} commit
   * @param {boolean} hasMorePages
   * @return {void}
   */
  setRecipesHasMorePages({ commit }, hasMorePages) {
    commit(types.SET_RECIPES_HAS_MORE_PAGES, hasMorePages)
  },

  /**
   * Update current page for recipe queries
   *
   * @param {object} commit
   * @param {int} page
   * @return {void}
   */
  setRecipeQueryPage({ commit }, page) {
    commit(types.UPDATE_RECIPE_PAGE, page)
  },
  getCategories({ commit }) {
    return apiRecipes.getCategories().then((categories) => {
      commit(types.RECEIVE_RECIPES_CATEGORIES, { categories })
      return categories
    })
  },
  addFilterByNewIngredientSelected({ commit }, ingredient) {
    commit(types.ADD_RECIPE_BOX_INGREDIENT_FILTER, ingredient)
  },
  toggleRecipeFavorite({ state, commit }, { recipeId, cancelExecutor }) {
    let recipe = state.localFavorites[recipeId]
    apiRecipes
      .updateFavorite(recipeId, recipe.isFavorite, cancelExecutor)
      .then(() => {
        if (recipe.primary) {
          commit(types.UPDATE_FAVORITE_PRIMARY_RECIPE, recipeId)
        } else {
          commit(types.UPDATE_FAVORITE_ALTERNATIVE_RECIPE, recipeId)
        }

        commit(types.UPDATED_FAVORITE, {
          recipeId,
          isFavorite: recipe,
        })
      })
  },
  setFilterTagSelected({ commit }, { filterType, filterValues }) {
    commit(types.SET_FILTER_TAG_SELECTED, { filterType, filterValues })
  },
  setByRatingSelected({ commit }, value) {
    commit(types.SET_FILTER_BY_RATING_SELECTED, value)
  },
  addRating({ commit }, obj) {
    commit(types.SET_RECIPE_RATING, obj)
  },
  setPrepTimeSelected({ commit }, value) {
    commit(types.SET_PREP_TIME_SELECTED, value)
  },
}

// mutations
const mutations = {
  [types.SET_RECIPE_RATING](state, { id, rating }) {
    const index = _.findIndex(state.recipes, (r) => r.id == id)
    Vue.set(state.recipes, index, {
      ...state.recipes[index],
      rating: rating,
      rating_count: 1,
    })
  },
  [types.SET_ALL_RECIPES_STATUS](state, status) {
    state.allRecipesStatus = status
  },
  [types.REFRESH_RECIPES_CACHE](state) {
    state.cache = {}
  },
  [types.RECEIVE_SEARCH_RECIPES](
    state,
    {
      recipes,
      primaryRecipes,
      searchText,
      isAlternativeResult,
      isFiltering,
      resetRecipes = false,
      recipesFound = 0,
      rootGetters,
      primaryCount = null,
      generalCount = null,
      primaryLabel = '',
      generalLabel = '',
    }
  ) {
    state.searchText = searchText
    state.isAlternativeResult = isAlternativeResult
    state.isFiltering = isFiltering
    state.recipesFound = recipesFound
    state.primaryLabel = primaryLabel
    state.generalLabel = generalLabel
    if (generalCount !== null) {
      state.generalCount = generalCount
    }
    if (primaryCount !== null) {
      state.primaryCount = primaryCount
    }

    recipes = addCategoryDataToRecipes(recipes, state.categories)
    recipes = addAddRecipePlanData(
      recipes,
      rootGetters['dashboard/meals'] || []
    )

    if (resetRecipes) {
      state.recipeBoxs = !recipes
        ? []
        : recipes.map((r) =>
            listItems.getRecipeCard(r, state.isAddRecipeBox, 'recipes-')
          )
    } else {
      state.recipeBoxs.push(
        ...recipes.map((r) =>
          listItems.getRecipeCard(r, state.isAddRecipeBox, 'recipes-')
        )
      )
    }

    if (state.isFiltering) {
      if (resetRecipes) {
        primaryRecipes = addCategoryDataToRecipes(
          primaryRecipes,
          state.categories
        )
        primaryRecipes = addAddRecipePlanData(
          primaryRecipes,
          rootGetters['dashboard/meals'] || []
        )
        state.primaryRecipes = primaryRecipes.map((r) =>
          listItems.getRecipeCard(r, state.isAddRecipeBox, 'recipes-')
        )
      }
    } else {
      state.primaryRecipes = []
    }
  },
  /**
   * It receive a filter object like the next one:
   *
   * {
   *  meal_types: [...], // this is the recipe category
   *  flavor_types: [...],
   *  intolerances: [...], // this is the commonly avoided
   *  flavor_profiles: [...]
   * }
   * @param {object} state the recipeBox state
   * @param {object} filters the recipe filters list
   */
  [types.SET_RECIPE_FILTERS](state, filters) {
    state.recipeFilters = { ...filters, ingredients: [] }
  },
  /**
   * It will update the filters applied. It will receive:
   * - filterType (ie: meal_type)
   * - action (can be included or excluded)
   * - filterValues (the filters updated)
   *
   * @param {object} state the recipeBox state
   * @param {object} params the update params.
   *
   * @return {void}
   */
  [types.UPDATE_RECIPE_INGREDIENTS_FILTERS_SELECTED](
    state,
    { filterType, filtersApplied }
  ) {
    state.filtersApplied[filterType] = filtersApplied
    updateLocalStorage('filters-applied', state.filtersApplied)
  },
  [types.UPDATE_RECIPE_FILTERS_SELECTED](state, filtersApplied) {
    state.filtersApplied = filtersApplied
    updateLocalStorage('filters-applied', state.filtersApplied)
  },
  [types.UPDATE_SEARCH_TEXT](state, searchText) {
    if (searchText === null) {
      searchText = ''
    }

    state.searchText = searchText
  },
  [types.SET_FILTER_BY_RATING_SELECTED](state, filterByRating) {
    state.filters.byRating = filterByRating
    updateLocalStorage('applied-filters', state.filters)
  },
  [types.SET_SEARCH_RECIPES_STATUS](state, status) {
    state.searchRecipesStatus = status
  },
  [types.SET_PREP_TIME_SELECTED](state, prepTime) {
    state.filtersApplied.prepTime = prepTime
    updateLocalStorage('applied-filters', state.filtersApplied)
  },
  [types.UPDATE_RECIPE_PAGE](state, page) {
    state.recipePage = page
  },
  [types.SET_RECIPES_HAS_MORE_PAGES](state, hasMorePages) {
    state.recipeHasMorePages = hasMorePages
  },
  [types.SET_RECIPES_QUERY_STATUS](state, status) {
    state.recipeQueryStatus = status
  },
  [types.RECEIVE_RECIPES_CATEGORIES](state, { categories }) {
    state.categories = categories
    state.categoriesOrder = categories.reduce((categoriesOrder, category) => {
      categoriesOrder[category.id] = category.order
      return categoriesOrder
    }, {})
  },
  [types.ADD_RECIPE_BOX_INGREDIENT_FILTER](state, ingredient) {
    state.recipeFilters.ingredients.push(ingredient)
  },
  [types.SET_SELECTED_FILTERS_TO_DEFAULT](state) {
    state.filtersApplied = _.cloneDeep(getDefaultFiltersApplied(true))
    state.searchRecipes = null
    state.searchText = ''
    state.recipeFilters.ingredients = []
    updateLocalStorage('applied-filters', state.filtersApplied)
  },
  [types.SET_DEFAULT_FILTER_BY_SELECTED](state, filterBySelected) {
    state.defaultFilters.filterBy = filterBySelected
  },
  [types.SET_DEFAULT_KID_APPROVED_SELECTED](state, kidApprovedSelected) {
    state.defaultFilters.kidApproved = kidApprovedSelected
  },
  [types.SET_DEFAULT_NO_WEEKEND_PREP_SELECTED](state, noWeekendPrepSelected) {
    state.defaultFilters.noWeekendPrep = noWeekendPrepSelected
  },
  [types.SET_DEFAULT_SORT_BY_SELECTED](state, sortBySelected) {
    state.defaultFilters.sortBy = sortBySelected
  },
  [types.SET_LOCAL_FAVORITE](state, { recipeId, isFavorite }) {
    let localFavorites = { ...state.localFavorites }
    localFavorites[recipeId] = isFavorite
    state.localFavorites = localFavorites
  },
  [types.TOGGLE_RECIPE_FAVORITE](state, recipeId) {
    let isFavorite
    if (state.localFavorites[recipeId]) {
      isFavorite = state.localFavorites[recipeId]
    } else {
      isFavorite = state.recipes.find(
        (recipe) => recipe.id === recipeId
      ).isFavorite
    }
    let localFavorites = { ...state.localFavorites }
    localFavorites[recipeId] = !isFavorite
    state.localFavorites = localFavorites
  },
  [types.UPDATED_FAVORITE](state, { recipeId }) {
    if (state.localFavorites[recipeId]) {
      delete state.localFavorites[recipeId]
    }
  },
  [types.SET_CURRENT_PLAN](state, plan) {
    state.currentMealPlan = plan
  },
  [types.SET_IS_ADD_RECIPE_BOX](state, isAddRecipeBox) {
    state.isAddRecipeBox = isAddRecipeBox
  },
  [types.SET_SHOW_PLAN_DISABLED](state, isShow) {
    state.showPlanDisabled = isShow
  },
  [types.UPDATE_FAVORITE_PRIMARY_RECIPE](state, recipeId) {
    const recipes = state.primaryRecipes
    for (let recipe of recipes) {
      if (recipe.id === recipeId) {
        recipe.isFavorite = !recipe.isFavorite
        break
      }
    }

    state.primaryRecipes = recipes
  },
  [types.UPDATE_FAVORITE_ALTERNATIVE_RECIPE](state, recipeId) {
    const recipes = state.recipeBoxs
    for (let recipe of recipes) {
      if (recipe.id === recipeId) {
        recipe.isFavorite = !recipe.isFavorite
        break
      }
    }

    state.recipeBoxs = recipes
  },
  [types.UPDATE_LOCAL_RECIPES](state, recipeId) {
    const isPrimary = state.primaryRecipes.find(
      (recipe) => recipe.id === recipeId
    )

    if (isPrimary) {
      state.localFavorites[recipeId] = { ...isPrimary, primary: true }
      return
    }

    const recipe = state.recipeBoxs.find((recipe) => recipe.id === recipeId)

    state.localFavorites[recipeId] = { ...recipe, primary: false }
  },
  [types.SET_PRIMARY_AS_GENERAL](state, value) {
    state.primaryAsGeneral = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

// Filters
function getDefaultFilters() {
  return {
    byType: menuItems.getDefaultFilterByTypeItem(),
    filterBy: menuItems.getDefaultFilterByItem(),
    sortBy: menuItems.getDefaultSortByItem(),
    kidApproved: menuItems.getDefaultFilterByItem(),
    noWeekendPrep: menuItems.getDefaultFilterByItem(),
    pressureCooker: menuItems.getDefaultFilterByItem(),
    prepTime: menuItems.prepTimeDefault,
    byRating: menuItems.filterByRatingDefault,
    ingredientsCount: menuItems.ingredientsCountDefault,
    ingredients: menuItems.getDefaultTagValues(),
    flavorProfile: menuItems.getDefaultTagValues(),
    mealType: menuItems.getDefaultTagValues(),
    intolerances: menuItems.getDefaultTagValues(),
    calories: menuItems.caloriesDefault,
    caloriesValues: menuItems.getCaloriesValues(),
  }
}

/**
 * Get available filters Keys
 *
 * @returns {array}
 */
function getAvailableFilters() {
  return [
    FILTERS_TYPE.MEALTYPE,
    FILTERS_TYPE.FLAVOR_PROFILE,
    FILTERS_TYPE.FLAVOR_TYPE,
    FILTERS_TYPE.TEMPERATURE_TYPE,
    FILTERS_TYPE.INGREDIENTS,
    FILTERS_TYPE.INTOLERANCES,
    FILTERS_TYPE.PREP_TIME,
    FILTERS_TYPE.ONLY_FAVORITES,
    FILTERS_TYPE.NO_WEEKEND_PREP,
    FILTERS_TYPE.KID_APPROVED,
    FILTERS_TYPE.CALORIES,
    FILTERS_TYPE.RATING,
  ]
}

/**
 * It will check if the current filters has
 * all the available filters.
 *
 * @param {object} filters
 * @returns {boolean}
 */
function validateFiltersVersion(filters = {}) {
  const availableFilters = getAvailableFilters()
  const diffFilters = Object.keys(filters).filter((k) =>
    availableFilters.includes(k)
  )

  return diffFilters.length === availableFilters.length
}

/**
 * It will return the filters saved on local storage OR
 * will return the default filters.
 *
 * @returns {object}
 */
function getDefaultFiltersApplied(clear = false) {
  if (clear) {
    localStorage.setItem('filters-applied', null)
  }

  let filtersApplied = JSON.parse(localStorage.getItem('filters-applied'))

  if (null !== filtersApplied && validateFiltersVersion(filtersApplied)) {
    return filtersApplied
  }

  localStorage.setItem('filters-applied', null)

  return {
    [FILTERS_TYPE.MEALTYPE]: null,
    [FILTERS_TYPE.FLAVOR_TYPE]: null,
    [FILTERS_TYPE.TEMPERATURE_TYPE]: null,
    [FILTERS_TYPE.FLAVOR_PROFILE]: null,
    [FILTERS_TYPE.INTOLERANCES]: null,
    [FILTERS_TYPE.INGREDIENTS]: menuItems.getDefaultTagValues(),
    [FILTERS_TYPE.PREP_TIME]: COMPONENT_TYPES.PREP_DEFAULT,
    [FILTERS_TYPE.CALORIES]: COMPONENT_TYPES.CALORIES_DEFAULT,
    [FILTERS_TYPE.RATING]: COMPONENT_TYPES.RATING_DEFAULT,
    [FILTERS_TYPE.ONLY_FAVORITES]: false,
    [FILTERS_TYPE.KID_APPROVED]: false,
    [FILTERS_TYPE.NO_WEEKEND_PREP]: false,
  }
}

// previous default filters applied
// function getDefaultFiltersApplied(clear = false) {
//   if (clear) {
//     localStorage.setItem('filters-applied', null)
//   }

//   let filtersApplied = JSON.parse(localStorage.getItem('filters-applied'))

//   if (null !== filtersApplied && validateFiltersVersion(filtersApplied)) {
//     return filtersApplied
//   }

//   localStorage.setItem('filters-applied', null)

//   return {
//     [FILTERS_TYPE.MEALTYPE]: menuItems.getDefaultTagValues(),
//     [FILTERS_TYPE.FLAVOR_TYPE]: menuItems.getDefaultTagValues(),
//     [FILTERS_TYPE.FLAVOR_PROFILE]: menuItems.getDefaultTagValues(),
//     [FILTERS_TYPE.INTOLERANCES]: menuItems.getDefaultTagValues(),
//     [FILTERS_TYPE.INGREDIENTS]: menuItems.getDefaultTagValues(),
//     [FILTERS_TYPE.PREP_TIME]: 120,
//     [FILTERS_TYPE.ONLY_FAVORITES]: false,
//     [FILTERS_TYPE.KID_APPROVED]: false,
//     [FILTERS_TYPE.NO_WEEKEND_PREP]: false
//   }
// }

function updateLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}

function getSavedFilters() {
  let filters = JSON.parse(localStorage.getItem('filters-applied'))
  if (null === filters) {
    return getDefaultFiltersApplied()
  } else {
    return filters
  }
}

// function getFilteredBy(recipes, filter) {
//   if (filter.propertyName) {
//     return recipes.filter(
//       recipe => recipe[filter.propertyName] === filter.propertyValue
//     )
//   } else return recipes
// }

// function getFilteredIncludedExcluded(recipes, { included, excluded }, type) {
//   if (included.length) {
//     recipes = _.union(
//       ...included.map(includedItem =>
//         getFilteredByTag(recipes, includedItem, type)
//       )
//     )
//   }

//   if (excluded.length) {
//     recipes = excluded.reduce(
//       (filteredRecipes, excludedItem) =>
//         getExcludedFilteredByTag(filteredRecipes, excludedItem, type),
//       recipes
//     )
//   }

//   return recipes
// }

// function getFilteredByTag(recipes, filter, type) {
//   if (filter.name) {
//     return recipes.filter(recipe => {
//       if (recipe[`${type}_tags`] && recipe[`${type}_tags`].length) {
//         return recipe[`${type}_tags`].find(({ name }) => name === filter.name)
//       }
//     })
//   } else return recipes
// }

// function getExcludedFilteredByTag(recipes, filter, type) {
//   if (filter.name) {
//     return recipes.reduce((pre, currRecipe) => {
//       //only adds if it meets the requirements
//       if (
//         !currRecipe[`${type}_tags`] ||
//         !currRecipe[`${type}_tags`].some(({ name }) => name === filter.name)
//       ) {
//         pre.push(currRecipe)
//       }
//       return pre
//     }, [])
//   } else return recipes
// }

// function getFilteredValue(recipes, key, value, maxValue) {
//   if (value >= maxValue) return recipes
//   return recipes.filter(recipe => recipe[key] <= value)
// }

// function getFilteredRating(recipes, rating, defaultRating) {
//   if (rating <= defaultRating) return recipes
//   return recipes.filter(recipe => listItems.getRating(recipe) >= rating)
// }

// function getSortedBy(recipes, filter) {
//   recipes = recipes.slice()
//   switch (filter.sortAction) {
//     case 'newest-first':
//       // return recipes.sort((recipeA, recipeB) => recipeB.go_live - recipeA.go_live)
//       // Put an unlocked recipe on top.
//       return recipes.sort((recipeA, recipeB) => {
//         let a = +!recipeA.is_locked + recipeA.go_live
//         let b = +!recipeB.is_locked + recipeB.go_live
//         return b - a
//       })
//     case 'oldest-first':
//       return recipes.sort(
//         (recipeA, recipeB) => recipeA.go_live - recipeB.go_live
//       )
//     case 'prep-time':
//       return recipes.sort(
//         (recipeA, recipeB) =>
//           recipeA.default_prep_time - recipeB.default_prep_time
//       )
//     case 'prep-time-excl-prep':
//       return recipes
//         .filter(recipe => !recipe.has_weekend_prep)
//         .sort(
//           (recipeA, recipeB) =>
//             recipeA.default_prep_time - recipeB.default_prep_time
//         )
//     case 'highest-rated':
//       return recipes.sort(
//         (recipeA, recipeB) =>
//           listItems.getRating(recipeB) - listItems.getRating(recipeA)
//       )
//     case 'category':
//       return recipes.sort((recipeA, recipeB) => {
//         return (
//           state.categoriesOrder[recipeA.category_id] -
//           state.categoriesOrder[recipeB.category_id]
//         )
//       })
//     case 'alphabetical':
//       return recipes.sort((recipeA, recipeB) => {
//         let titleA = recipeA.title.replace('"', '')
//         let titleB = recipeB.title.replace('"', '')
//         if (titleA < titleB) return -1
//         if (titleA > titleB) return 1
//         return 0
//       })
//     default:
//       return recipes
//   }
// }

// function getFilteredBySame(recipes, selectedRecipes) {
//   if (selectedRecipes) {
//     let selectedRecipesId = selectedRecipes.map(recipe => recipe.id)
//     return recipes.filter(recipe => selectedRecipesId.includes(recipe.id))
//   } else {
//     return recipes
//   }
// }

// function getNotIncludedRecipe(allRecipes, recipes) {
//   let recipesIds = recipes.map(recipe => recipe.id)
//   return allRecipes.filter(recipe => !recipesIds.includes(recipe.id))
// }

// function addFavorites(recipes, favorites) {
//   return recipes.map(recipe => {
//     if (favorites.hasOwnProperty(recipe.id))
//       recipe.favorite = favorites[recipe.id]
//     return recipe
//   })
// }

// Categories
function addCategoryDataToRecipes(recipes, categories) {
  const categoriesColor = categories.reduce((a, c) => {
    a[c.id] = c.color
    return a
  }, {})
  recipes.forEach((r) => (r.categoryColor = categoriesColor[r.category_id]))

  return recipes
}

// Add Recipe
function addAddRecipePlanData(recipes, plan) {
  let planRecipeIds = plan
    .filter(({ isCustom }) => !isCustom)
    .map(({ recipe }) => recipe)
    .map((r) => r.id)

  return recipes.map((recipe) => {
    if (planRecipeIds.includes(recipe.id)) {
      let plannedRecipe = plan.find((meal) => meal.recipe.id === recipe.id)
      recipe.inCurrentPlan = true
      recipe.addRecipeStatus = plannedRecipe.isDisabled ? 'disabled' : 'enabled'
    } else {
      recipe.inCurrentPlan = false
      recipe.addRecipeStatus = recipe.is_locked ? 'locked' : 'enabled'
    }
    return recipe
  })
}
