import { loadExternalScript, addScript } from './loadScript'
import Analytics from './analytics'
import AnalyticsPlugin from './AnalyticsPlugin'

let loadFomo
let triggerFomoLastState

const ExternalServices = {
  loadFomo(fomoUrlKey) {
    loadFomo = loadExternalScript(
      `https://load.fomo.com/api/v1/${fomoUrlKey}/load.js`
    )
    return loadFomo
  },
  switchFomoEnable(state) {
    triggerFomoLastState = state

    if (!loadFomo) this.loadFomo()

    loadFomo.then(() => {
      let trigger = triggerFomoLastState ? 'start' : 'stop'
      window.fomo.trigger(trigger)
    })
  },
  loadFullStory() {
    const script = `
      window['_fs_debug'] = false;
      window['_fs_host'] = 'fullstory.com';
      window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
      window['_fs_org'] = 'WWJW';
      window['_fs_namespace'] = 'FS';
      (function(m,n,e,t,l,o,g,y){
          if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');} return;}
          g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];
          o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;
          y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);
          g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};
          g.anonymize=function(){g.identify(!!0)};
          g.shutdown=function(){g("rec",!1)};g.restart=function(){g("rec",!0)};
          g.log = function(a,b){g("log",[a,b])};
          g.consent=function(a){g("consent",!arguments.length||a)};
          g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};
          g.clearUserCookie=function(){};
          g.setVars=function(n, p){g('setVars',[n,p]);};
          g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];
          if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};
          g._v="1.3.0";
      })(window,document,window['_fs_namespace'],'script','user');
    `
    addScript(script)
  },
  loadProfitWell() {
    const script = document.createElement('script')
    script.id = 'profitwell-js'
    script.setAttribute('data-pw-auth', '208d2d228f7a048d3382fca182f513b5')
    script.innerHTML = `(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
      a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
      s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
      })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');`
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(script, firstScript)
  },
  ABTests: {
    get hidePlanByType() {
      return window.hidePlanByType
    },
    get showBVersionCountdown() {
      return window.showBVersionCountdown
    },
    get showOnboardingTutorial() {
      return window.showOnboardingTutorial
    },
    get showAllMealTypesOption() {
      return !!window.showAllMealTypesOption
    },
    get showFullRegisterCredentialsForm() {
      return !!window.showFullRegisterCredentialsForm
    },
    get showSignUpWithEmail() {
      return window.showSignUpWithEmail
    },
    get ABTestRecipeBoxIcon() {
      return window.ABTestRecipeBoxIcon
    },
    get showStepsOnFirstStep() {
      return window.showStepsOnFirstStep
    },
    get registrationSelectMealsReverse() {
      return window.registrationSelectMealsReverse
    },
    get showRegistrationMostPopularTag() {
      return window.showRegistrationMostPopularTag
    },
    get registrationAdultServings() {
      return window.registrationAdultServings
    },
  },
}

export { ExternalServices as default, Analytics, AnalyticsPlugin }
