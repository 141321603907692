<template>
  <div class="row sc" :class="`sc--${type}`">
    <div
      class="p-0"
      :class="{
        'col-12': isFullWidth,
        'col-6': isEqualWidth,
        'col-8': !isEqualWidth,
      }"
    >
      <div class="sc-label h4">
        <slot name="label"></slot>
      </div>
      <div class="sc-content">
        <slot name="content"></slot>
      </div>
    </div>
    <div
      class="p-0 d-flex justify-contend-end flex-row"
      :class="{ 'col-4': !isEqualWidth, 'col-6': isEqualWidth }"
    >
      <div class="actions-content" v-if="hasActionsContentSlot">
        <slot name="actions-content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'columns',
    },
    isFullWidth: {
      default: false,
    },
    isEqualWidth: {
      default: false,
    },
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default
    },
    hasActionsContentSlot() {
      return !!this.$slots['actions-content']
    },
  },
}
</script>

<style lang="scss" scoped>
.sc {
  padding: 15px 0;
  margin: 0;
}

.sc-label {
  font-size: rem(18);
  margin-bottom: 15px;
}

.sc--rows,
.sc--rows-last {
  .sc-label {
    min-width: 100px;
  }
}

.sc--rows {
  border-bottom: 1px solid $clr-navy-15;
}

.sc--rows-last {
  @include media-breakpoint-down(sm) {
    border-bottom: 1px solid $clr-navy-15;
  }
}

.sc--rows-lg-last {
  @include media-breakpoint-up(lg) {
    border-bottom: none;
  }
}

.sc--rows-md-last {
  @include media-breakpoint-only(md) {
    border-bottom: 0;
  }
}

.sc-l {
  margin-bottom: 0.5rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.actions-content {
  width: 137px;
  margin-left: auto;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  @include media-breakpoint-up(md) {
    margin-top: 0;
    text-align: right;
  }

  button {
    width: 100%;
  }
}

.settings-panel-content {
  &--SettingsNotifications {
    .actions-content {
      width: 215px;
    }
  }
}
</style>
