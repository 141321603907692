import { mapGetters } from 'vuex'
import Fraction from 'fraction.js'
import _ from 'lodash'

import {
  buildUnitTestRegex,
  testIfUnitInValue,
  testIfNumberInValue,
} from '../../utils/AddCustomRecipeRegexUtils'

export default {
  data() {
    return {
      id: null,
      quantity: null,
      quantityFraction: null,
      unit: null,
      ingredient: null,
      prepDescription: null,
      index: null,
      changed: false,
      unitTestRegex: null,
    }
  },
  computed: {
    ...mapGetters('recipe', [
      'unitList',
      'ingredientList',
      'prepDescriptionList',
    ]),
    hasErrors() {
      return this.errors.items.length > 0
    },
    hasQuantityErrors() {
      return this.errors.has(`${this.tabScope}.quantity`)
    },
    quantityError() {
      return this.errors.first(`${this.tabScope}.quantity`)
    },
    hasUnitErrors() {
      return this.errors.has(`${this.tabScope}.unit`)
    },
    unitError() {
      return this.errors.first(`${this.tabScope}.unit`)
    },
    hasIngredientErrors() {
      return this.errors.has(`${this.tabScope}.ingredient`)
    },
    ingredientError() {
      return this.errors.first(`${this.tabScope}.ingredient`)
    },
    hasPrepDescriptionErrors() {
      return this.errors.has(`${this.tabScope}.prepDescription`)
    },
    prepDescriptionError() {
      return this.errors.first(`${this.tabScope}.prepDescription`)
    },
    quantityValidation() {
      return {
        required: !_.isEmpty(this.unit),
        regex: /^(\d+|\d*\s{0,1}(\d+\/\d+)|\d*[\\.,]\d+)?$/,
        maxQuantity: true,
      }
    },
    unitValidation() {
      return ''
    },
    ingredientValidation() {
      return {
        required: this.changed && !this.isFormEmpty,
      }
    },
    prepDescriptionValidation() {
      return ''
    },
    warning() {
      const isIngredientString = typeof this.ingredient == 'string'
      if (isIngredientString) {
        const testUnits = testIfUnitInValue(this.unitTestRegex, this.ingredient)
        const testNumbers = testIfNumberInValue(this.ingredient)
        if (testNumbers && testUnits) {
          return {
            show: true,
            message:
              "Make sure you're not entering quantities and units into ingredient field",
          }
        } else if (testNumbers) {
          return {
            show: true,
            message:
              "Make sure you're not entering quantities into ingredient field",
          }
        } else if (testUnits) {
          return {
            show: true,
            message:
              "Make sure you're not entering units into ingredient field",
          }
        }
        return {
          show: false,
          message: null,
        }
      }
      return {
        show: false,
        message: null,
      }
    },
    isFormEmpty() {
      return (
        !this.quantity &&
        _.isEmpty(this.unit) &&
        _.isEmpty(this.ingredient) &&
        _.isEmpty(this.prepDescription)
      )
    },
  },
  mounted() {
    const dict = {
      en: {
        messages: {
          required: (field) => `Enter ${field}`,
          max_value: (field, value) => `Maximum is ${value}`,
          regex: (field) => `${field} is invalid`,
          numeric: (field) => `${field} is invalid`,
        },
      },
    }
    this.$validator.localize(dict)
    this.$validator.localize('en')
    this.$validator.extend('isObject', {
      getMessage: (field) => `${field} is invalid`,
      validate: (value) => typeof value === 'object',
    })
    this.$validator.extend('maxQuantity', {
      getMessage: () => `Max is 9999`,
      validate: (value) => (value ? formatQuantity(value) <= 9999 : true),
    })
    this.unitTestRegex = buildUnitTestRegex(this.unitList, 'name')
  },
  methods: {
    clear() {
      this.quantity = null
      this.quantityFraction = null
      this.unit = null
      this.ingredient = null
      this.prepDescription = null
      this.index = null
      this.changed = false
      this.$validator.reset()
    },
    onKeyDown() {
      this.changed = true
    },
    createRecipeIngredientObject(isMetric) {
      let obj = {
        quantity: this.quantity,
        unit: this.unit,
        ingredient: this.ingredient,
        prepDescription: this.prepDescription,
        index: this.index,
        isMetric: isMetric,
      }
      return this.id ? { id: this.id, ...obj } : obj
    },
  },
  watch: {
    quantityFraction: {
      handler(value) {
        try {
          this.quantity = formatQuantity(value)
        } catch (err) {
          // This will happen if quantity isn't in the right shape for formating.
        }
      },
      immediate: true,
    },
  },
}
function formatQuantity(quantity) {
  if (!quantity) return null
  if (typeof quantity == 'string') {
    quantity = quantity.replace(',', '.')
    if (quantity.indexOf('/') > -1) {
      const f = new Fraction(quantity)
      quantity = (f.s * f.n) / f.d
    }
    return parseFloat(quantity)
  } else {
    return quantity
  }
}
