export default {
  start(user_email) {
    window.profitwell('start', { user_email })
  },
  cancel(subscription_id) {
    return window
      .profitwell('init_cancellation_flow', { subscription_id })
      .then((result) => {
        if (result.status === 'retained' || result.status === 'aborted') {
          return
        } else if (result.status === 'error') {
          throw new Error('Failed to cancel')
        }
        const {
          additionalFeedback: feedback,
          satisfactionInsight,
          cancelReason: reason,
          salvageAttemptResult,
          salvageAttemptIntended,
          salvageOfferResult,
          salvageAttemptUsed,
        } = result
        return {
          reason,
          feedback,
          satisfactionInsight,
          salvage: {
            attempt: {
              result: salvageAttemptResult,
              intended: salvageAttemptIntended,
              used: salvageAttemptUsed,
            },
            offer: salvageOfferResult,
          },
        }
      })
  },
}
