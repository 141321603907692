<template>
  <AddCustomRecipeConfirmModal :show="value" id="recipe-delete-confirm-modal">
    <div class="text-center mt-4">
      <b>Are you sure you want to delete this recipe?</b>
      <hr />
      <div class="d-flex px-4 mb-4 justify-content-between">
        <MPButton class="btn btn-link" size="lg" @click="no()">
          Cancel
        </MPButton>
        <MPButton @click="yes()" class="btn btn-danger ml-5"> Delete </MPButton>
      </div>
    </div>
  </AddCustomRecipeConfirmModal>
</template>

<script>
import { mapGetters } from 'vuex'
import AddCustomRecipeConfirmModal from '@/components/addcustomrecipe/AddCustomRecipeConfirmModal'
import RouterMixin from '@/mixins/RouterMixin'
import { events } from '@/utils/mixpanel.events'

export default {
  name: 'RecipeDeleteConfirmModal',
  computed: {
    ...mapGetters({
      recipe: 'recipe/recipe',
    }),
  },
  props: {
    value: {
      type: Boolean,
    },
    recipe: {
      type: Object,
    },
    numberOfBackSteps: {
      type: Number,
      default: 1,
    },
  },
  mixins: [RouterMixin],
  components: {
    AddCustomRecipeConfirmModal,
  },
  methods: {
    yes() {
      this.$emit('input', false)
      this.$store
        .dispatch('AddCustomRecipe/deleteCustomRecipe', this.recipe.id)
        .then(() => {
          const payload = {
            eventName: events.personalRecipe.delete,
            params: {
              recipe_id: this.recipe.id,
              racipe_rating: this.recipe.rating,
            },
          }
          this.$store.dispatch('analytics/track', payload)
          this.$store.dispatch('RecipeBox/refresh')
          setTimeout(() => {
            this.goBack(this.numberOfBackSteps)
          }, 100)
        })
    },
    no() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss">
#recipe-delete-confirm-modal {
  .modal-dialog {
    max-width: 600px;
  }
  .modal-content {
    min-height: 186px;
  }
}
</style>
