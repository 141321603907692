import { mapGetters, mapState } from 'vuex'
import { slugify } from '@/utils/conversions'

export default {
  computed: {
    ...mapGetters('globals', ['previousRoute']),
    ...mapState('AddCustomRecipe', ['dataChanged']),
  },
  methods: {
    goBack(steps = 1) {
      if (this.previousRoute === null || this.previousRoute.path === '/') {
        this.$router.push('/plan')
      } else {
        this.$router.go(steps * -1)
      }
    },
    goBackAuthenticated(steps = 1) {
      let backURL = this.$routeQuery.get('back')
      if (backURL) {
        this.$router.push(backURL)
      } else if (
        this.previousRoute === null ||
        this.previousRoute.path === '/' ||
        this.previousRoute.path === '/login' ||
        this.previousRoute.path === '/upgrade'
      ) {
        this.$router.push('/plan')
      } else {
        this.$router.go(steps * -1)
      }
    },
    onAddCustomRecipeReturnTo(save, recipe) {
      if (this.$route.path === '/recipe/add') {
        if (save) {
          this.$router.replace(`/recipe/${recipe.id}/${slugify(recipe.title)}`)
        } else {
          this.goBack()
        }
      } else {
        if (this.dataChanged && save) {
          this.$store.dispatch('AddCustomRecipe/updateUserGeneratedRecipeData')
        }
      }
    },
  },
}
