<template>
  <MPModal :show.sync="showModal">
    <form class="text-center">
      <span class="center"> Create Password </span>
      <MPMaterialInput
        class="mt-3"
        type="password"
        label="Enter password"
        v-model="password"
      />
      <MPButton
        class="mt-3"
        color="orange"
        size="large"
        @click="createPassword"
      >
        Create Password
      </MPButton>
    </form>
  </MPModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: null,
    }
  },
  computed: {
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
  },
  methods: {
    createPassword() {
      this.$store
        .dispatch('user/createPassword', this.password)
        .then(() => (this.showModal = false))
    },
  },
  watch: {
    showModal() {
      this.password = null
    },
  },
}
</script>
