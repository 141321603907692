<template>
  <MPMaterialInput
    id="full-name-input"
    type="text"
    v-model="inputValue"
    label="Full name"
    :data-spec="dataSpec"
  />
</template>

<script>
import { MPMaterialInput } from '@/components/common'

export default {
  name: 'FullNameInput',
  components: {
    MPMaterialInput,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    dataSpec: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
    }
  },
  watch: {
    inputValue() {
      this.$emit('input', this.inputValue)
    },
  },
}
</script>

<style lang="scss">
#full-name-input {
  animation-fill-mode: both;
}

@keyframes fullNameAnimation {
  to {
  }
}

#email-input:-webkit-autofill {
  animation-name: fullNameAnimation;
}
</style>
