<template>
  <div class="mp-toast">
    <transition name="fade">
      <div v-if="message" class="mp-toast-container">
        <div class="mp-toast-message">
          {{ message.text }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MPToast',
  props: {
    message: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-toast-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: fixed;
  top: 20px;
  left: 0;
  width: 100vw;
  @include media-breakpoint-up(md) {
    left: $navbar-width-medium;
    width: calc(100% - #{$navbar-width-medium});
  }
  @include media-breakpoint-up(lg) {
    left: $navbar-width-large;
    width: calc(100% - #{$navbar-width-large});
  }
}

.mp-toast-message {
  text-align: center;
  margin: 0.5rem;
  background-color: $green;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
