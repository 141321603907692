import { render, staticRenderFns } from "./MPAutoSuggest.vue?vue&type=template&id=616a665a"
import script from "./MPAutoSuggest.vue?vue&type=script&lang=js"
export * from "./MPAutoSuggest.vue?vue&type=script&lang=js"
import style0 from "./MPAutoSuggest.vue?vue&type=style&index=0&id=616a665a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports