<template>
  <div class="mp-steps-dots">
    <span
      v-for="(step, $index) in steps"
      :key="$index"
      class="indicator-bullet"
      :class="{
        active: $index === currentStepIndex,
        done: step.done,
        hide: step.hide,
      }"
      @click="onStepSelect($index)"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onStepSelect(stepIndex) {
      this.$emit('on-step-select', stepIndex)
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-steps-dots {
  display: inline-flex;
  align-self: center;
}

.indicator-bullet {
  background-image: url('~@/assets/icons/onboarding-inactive-dot.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 10px;

  &.done {
    background-image: url('~@/assets/icons/onboarding-complete-dot.svg');
    cursor: pointer;
  }

  &.active {
    background-image: url('~@/assets/icons/onboarding-active-dot.svg');
  }

  &.hide {
    display: none;
  }
}
</style>
