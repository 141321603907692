import api from './'
import camelizeKeys from './camelizeKeys'

const PLANS_URL = 'settings/plans2/'
const BILLING_PLAN_SIGNUP = 'settings/billing/plan/signup2/'
const BILLING_URL = 'settings/billing/web'
const BILLING_CHANGE_URL = 'settings/billing/plan/change/'
const BILLING_REACTIVATE_URL = 'settings/billing/plan/reactivate/'
const BILLING_CANCEL_URL = 'settings/billing/plan/cancel/'
const BILLING_CARD_CHANGE_URL = 'settings/billing/card/change/'

const PROFILE_URL = 'settings/profile/'
const PROFILE_PHOTO_CHANGE_URL = 'settings/profile/photo/change/'
const PROFILE_PHOTO_DELETE_URL = 'settings/profile/photo/delete/'
const PROFILE_NAME_CHANGE_URL = 'settings/profile/name/change/'
const PROFILE_EMAIL_CHANGE_URL = 'settings/profile/email/change/'
const PROFILE_PASSWORD_CHANGE_URL = 'settings/profile/password/change/'
const PROFILE_PASSWORD_CREATE_URL = 'settings/profile/password/create/'
const FACEBOOK_ID_URL = 'settings/facebook/id/'
const FACEBOOK_ID_DELETE_URL = 'settings/facebook/id/delete/'
const PREFERENCES_URL = 'settings/preferences/'
const PREFERENCES_CHANGE_URL = 'settings/preferences/change/'
const NOTIFICATIONS_URL = 'settings/notifications/'

const RECIPE_IMAGE_PRINT = 'settings/preferences/recipe-image-print/'
const RECIPE_TIPS_PRINT = 'settings/preferences/recipe-tips-print/'
const RECIPE_STORAGE_TIPS_PRINT =
  'settings/preferences/recipe-storage-tips-print/'
const RECIPE_FREEZING_TIPS_PRINT =
  'settings/preferences/recipe-freezing-tips-print/'
const RECIPE_REHEATING_TIPS_PRINT =
  'settings/preferences/recipe-reheating-tips-print/'
const RECIPE_QRCODES_PRINT = 'settings/preferences/recipe-qrcodes-print/'
const RECIPE_NOTES_PRINT = 'settings/preferences/recipe-notes-print/'
const RECIPE_NUTRITION_PRINT = 'settings/preferences/recipe-nutrition-print/'

const WEEKLY_PLAN_EMAIL_URL = 'settings/notifications/weeklyPlanEmail/'
const NEW_FEATURES_ANNOUNCEMENTS_URL =
  'settings/notifications/NewFeaturesAnnouncements/'
const GENERAL_EMAILS_URL = 'settings/notifications/general-emails/'

const UPSELL_PROMO_ACTIVATE = 'settings/upsell-promo-modal/activate/'
const UPSELL_PROMO_DISABLE = 'settings/upsell-promo-modal/disable/'

const AFF_INFO = 'settings/aff-info/'
const AFF_COMMISSIONS = 'settings/aff-commissions/'

const EXTEND_TRIAL = 'core/extend-trial/'
const EXTEND_TRIAL_WITH_TOKEN = 'core/extend-trial-with-token/'

const USE_AMAZON_FRESH = 'settings/profile/use-amazon-fresh/'
const USE_NUTRITIONAL_TRACKING = 'settings/profile/use-nutritional-tracking/'
const USE_METRIC_UNITS = 'settings/profile/use-metric-units/'
const USE_TEMPERATURE_CELSIUS = 'settings/profile/use-temperature-celsius/'
const SET_MOBILE_SHARE_AND_EARN_BANNER_VISIBLE =
  'settings/profile/set-earn-and-share-banner-mobile-visibility/'

const IS_SPEED_PREP_ENABLED =
  'settings/preferences/toggle-speed-prep-visibility/'

export default {
  getPlan(options) {
    return api
      .get(PLANS_URL, options)
      .then((res) => camelizeKeys(res.data))
      .then((data) => {
        let plans = data.plans.map((plan) => {
          plan.hasCoupon = data.hasCoupon
          return plan
        })
        return {
          plans: plans,
          discount: {
            discount: data.discount,
            expiryDate: data.discountExpiryDate,
            expiryDateS: data.discountExpiryDateS,
            headline: data.discountHeadline,
            isExpired: data.isDiscountExpired,
            promoAppliedBanner: data.promoAppliedBanner,
            promoBestValueLabel: data.promoBestValueLabel,
            promoButtonCtaLabel: data.promoButtonCtaLabel,
            promoPopupNotification: data.promoPopupNotification,
          },
          canShowUpsellPromoModal: data.canShowUpsellPromoModal,
          promoUpsell: data.upsellPromoModalData,
          hasCoupon: data.hasCoupon,
          coupon: data.coupon,
          planLabels: data.planLabels,
        }
      })
  },
  reactivatePlan(options) {
    return api.post(BILLING_REACTIVATE_URL, options)
  },
  cancelPlan() {
    return api.post(BILLING_CANCEL_URL)
  },
  activatePaidPlan(options) {
    return api
      .post(BILLING_PLAN_SIGNUP, options)
      .then((res) => camelizeKeys(res.data))
  },
  getBilling(options) {
    return api.get(BILLING_URL, options).then((res) => camelizeKeys(res.data))
  },
  changeBilling(options) {
    return api.post(BILLING_CHANGE_URL, options)
  },
  getProfile(options) {
    return api.get(PROFILE_URL, options).then((res) => camelizeKeys(res.data))
  },
  changeBillingCard(token) {
    return api.post(BILLING_CARD_CHANGE_URL, { token })
  },
  changeProfilePhoto(options) {
    return api
      .post(PROFILE_PHOTO_CHANGE_URL, options)
      .then((res) => camelizeKeys(res.data))
  },
  removeProfilePhoto() {
    return api.delete(PROFILE_PHOTO_DELETE_URL)
  },
  changeUserName(options) {
    return api.post(PROFILE_NAME_CHANGE_URL, options)
  },
  changeUserEmail(options) {
    return api.post(PROFILE_EMAIL_CHANGE_URL, options)
  },
  createPassword(password) {
    return api.post(PROFILE_PASSWORD_CREATE_URL, { new_password: password })
  },
  changePassword(oldPassword, newPassword) {
    return api.post(PROFILE_PASSWORD_CHANGE_URL, {
      password: newPassword,
      old_password: oldPassword,
    })
  },
  setFacebookID(facebookID) {
    return api.post(FACEBOOK_ID_URL, { facebook_id: facebookID })
  },
  deleteFacebookID() {
    return api.post(FACEBOOK_ID_DELETE_URL)
  },
  getPreferences() {
    return api.get(PREFERENCES_URL).then((res) => camelizeKeys(res.data))
  },
  changePreferences(options) {
    return api.post(PREFERENCES_CHANGE_URL, options)
  },
  savePrintRecipeImage(status) {
    return api.post(RECIPE_IMAGE_PRINT, { print_recipe_image: status })
  },
  savePrintRecipeTips(status) {
    return api.post(RECIPE_TIPS_PRINT, { print_recipe_tips: status })
  },
  savePrintRecipeStorageTips(status) {
    return api.post(RECIPE_STORAGE_TIPS_PRINT, {
      print_recipe_storage_tips: status,
    })
  },
  savePrintRecipeFreezingTips(status) {
    return api.post(RECIPE_FREEZING_TIPS_PRINT, {
      print_recipe_freezing_tips: status,
    })
  },
  savePrintRecipeReheatingTips(status) {
    return api.post(RECIPE_REHEATING_TIPS_PRINT, {
      print_recipe_reheating_tips: status,
    })
  },
  savePrintRecipeQrCodes(status) {
    return api.post(RECIPE_QRCODES_PRINT, { print_recipe_qr_codes: status })
  },
  savePrintRecipeNotes(status) {
    return api.post(RECIPE_NOTES_PRINT, { print_recipe_notes: status })
  },
  savePrintRecipeNutrition(status) {
    return api.post(RECIPE_NUTRITION_PRINT, { print_recipe_nutrition: status })
  },
  getNotifications() {
    return api.get(NOTIFICATIONS_URL).then((res) => camelizeKeys(res.data))
  },
  setWeeklyPlanEmail(toogleEmail) {
    return api.post(WEEKLY_PLAN_EMAIL_URL, { toogleEmail })
  },
  setNewFeaturesAnnouncements(toogle) {
    return api.post(NEW_FEATURES_ANNOUNCEMENTS_URL, { toogle })
  },
  setGeneralEmails(toggle) {
    return api.post(GENERAL_EMAILS_URL, { toggle })
  },
  activateUpsellPromoDiscount() {
    return api.post(UPSELL_PROMO_ACTIVATE, {})
  },
  deactivateUpsellPromoDiscount() {
    return api.post(UPSELL_PROMO_DISABLE, {})
  },
  extendTrial(token) {
    if (token) {
      return api.post(EXTEND_TRIAL_WITH_TOKEN, { token })
    }
    return api.post(EXTEND_TRIAL, {})
  },
  setUseAmazonFresh(useAmazonFresh) {
    return api.post(USE_AMAZON_FRESH, { can_use_amazon_fresh: useAmazonFresh })
  },
  setUseNutritionalTracking(useNutritionalTracking) {
    return api.post(USE_NUTRITIONAL_TRACKING, {
      can_use_nutritional_tracking: useNutritionalTracking,
    })
  },
  setUseMetricUnits(useMetricUnits) {
    return api.post(USE_METRIC_UNITS, { use_metric_units: useMetricUnits })
  },
  setUseTemperatureCelsius(useTemperatureCelsius) {
    return api.post(USE_TEMPERATURE_CELSIUS, {
      use_temperature_celsius: useTemperatureCelsius,
    })
  },
  setShareAndEarnMobileBannerVisible(visible) {
    return api.post(SET_MOBILE_SHARE_AND_EARN_BANNER_VISIBLE, {
      show_banner: visible,
    })
  },
  getAffiliateInfo() {
    return api.get(AFF_INFO).then((res) => camelizeKeys(res.data))
  },
  getAffiliateCommissions(month, year) {
    return api
      .get(`${AFF_COMMISSIONS + year}/${month}/`)
      .then((res) => camelizeKeys(res.data))
  },
  toggleSpeedPrepVisibility(value) {
    return api.post(IS_SPEED_PREP_ENABLED, {
      is_speed_prep_enabled: value,
    })
  },
}
