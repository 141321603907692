<template>
  <div class="recipe-admin-notes-post">
    <div class="media my-3">
      <span class="author-image" :style="styles"></span>
      <div class="media-body">
        <h4 v-if="title">{{ title }}</h4>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    avatar: {
      type: String,
    },
  },
  computed: {
    styles() {
      return this.avatar ? { backgroundImage: `url(${this.avatar})` } : {}
    },
  },
}
</script>

<style lang="scss" scoped>
.recipe-admin-notes-post {
  border-top: 1px solid #d5d6d7;
}

.author-image {
  display: inline-block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 1.4375rem;
  background: url(~@/assets/icons/recipe-default-avatar.svg) no-repeat center;
  background-size: cover;
}
</style>
