<template>
  <div>
    <!-- <MPOverlay
      class="overlay"
      :show="['loading', 'loadingPages'].includes(recipeQueryStatus)"
      type="light"
    /> -->
    <transition name="slide">
      <aside v-if="isOpen" class="filters-sidebar" ref="filters-sidebar">
        <header class="filters-sidebar-header">
          <div class="filters-sidebar-header-content">
            <span class="filters-clear">
              <span class="text-truncate" v-if="appliedFiltersDescription">
                {{ appliedFiltersDescription }}
              </span>
            </span>
          </div>
          <MPCloseIcon @click="onClose" />
        </header>
        <div class="filters-sidebar-content">
          <b-row class="mx-0 filters-header">
            <b-col cols="12" class="px-0 mb-3">
              <p class="filters-header-title text-center">
                Filter By Ingredients & More
              </p>
            </b-col>
            <b-col
              cols="12"
              class="px-0 mb-1"
              :class="{ 'd-none': !this.hasIncludedOrExcluded }"
            >
              <div class="filter-tags-included p-2 d-flex flex-wrap">
                <span class="filter-tags-text mr-1">Included:</span>
                <MPButton
                  color="dark"
                  classes="btn-badge"
                  size="15"
                  v-for="includedFilter in selectedTagFiltersSelected.included"
                  :key="includedFilter.name"
                  @click="removeFilterBy(includedFilter, 'included')"
                  :disabled="isLoading"
                >
                  <span class="filter-tags">{{ includedFilter.name }}</span>
                  <b-badge
                    pill
                    variant="light"
                    class="circle-badge"
                    :disabled="isLoading"
                  >
                    <span class="text-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="5"
                        stroke="currentColor"
                        class="close-icon"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                  </b-badge>
                </MPButton>
              </div>
              <div class="filter-tags-excluded p-2 d-flex flex-wrap">
                <span class="filter-tags-text mr-1">Excluded:</span>
                <MPButton
                  color="red"
                  classes="btn-badge"
                  size="15"
                  v-for="excludedFilter in selectedTagFiltersSelected.excluded"
                  :key="excludedFilter.name"
                  @click="removeFilterBy(excludedFilter, 'excluded')"
                  :disabled="isLoading"
                >
                  <span class="filter-tags">{{ excludedFilter.name }}</span>
                  <b-badge
                    pill
                    variant="light"
                    class="circle-badge"
                    :disabled="isLoading"
                  >
                    <span class="text-danger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="5"
                        stroke="currentColor"
                        class="close-icon"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </span>
                  </b-badge>
                </MPButton>
              </div>
            </b-col>
          </b-row>
          <hr class="sidebar-division" />
          <b-row class="mx-0 mb-2 filters-content">
            <b-col cols="12" class="px-0">
              <b-row class="mx-0 mb-0">
                <MPCollapse
                  title="INGREDIENTS"
                  :is-open-on-mount="true"
                  @from-top-on-open="fromTopOfCollapseOnOpen"
                  variant="transparent"
                >
                  <div class="mx-0 mb-2 mealtype">
                    <MPAutoSuggest
                      :strict-mode="false"
                      :suggestions="ingredientList"
                      place-holder="Include or exclude by ingredient"
                      search-param="singularName"
                      show-param="singularName"
                      return-param="singularName"
                      :name="`ingredient`"
                      data-vv-as="Ingredient"
                      @select="onFilterByNewIngredient"
                      clear-on-select
                      class="mb-3 tour-step-2"
                      :disabled="isLoading"
                    />
                    <b-row
                      class="mx-0 mb-2 mealtype"
                      v-for="filter in ingredientsItems"
                      :key="filter.name"
                    >
                      <b-button
                        class="mx-2 my-0 collapse-badge"
                        :class="
                          !selectedTagFiltersSelected.included.some(
                            (includedFilter) =>
                              includedFilter.name == filter.name
                          )
                            ? 'span-success text-success'
                            : 'span-success-selected'
                        "
                        @click="checkIngredientFiltered(filter)"
                      >
                      </b-button>
                      <b-button
                        class="mr-2 my-0 collapse-badge"
                        :class="
                          !selectedTagFiltersSelected.excluded.some(
                            (excludedFilter) =>
                              excludedFilter.name == filter.name
                          )
                            ? 'span-danger text-danger'
                            : 'span-danger-selected'
                        "
                        @click="checkIngredientFiltered(filter, 'excluded')"
                      >
                      </b-button>
                      <span class="font-weight-bold">{{ filter.name }}</span>
                    </b-row>
                  </div>
                </MPCollapse>
              </b-row>
              <b-row class="mx-0 mb-0">
                <MPCollapse
                  title="MEAL TYPE"
                  :is-open-on-mount="false"
                  @from-top-on-open="fromTopOfCollapseOnOpen"
                  variant="transparent"
                >
                  <b-form-radio-group
                    v-model="mealTypeControl"
                    name="mealRadioControl"
                    stacked
                  >
                    <b-form-radio
                      class="mx-0 mb-2 mealtype"
                      v-for="filter in recipeFilters.meal_types"
                      :aria-describedby="filter.name"
                      :key="filter.name"
                      :value="filter.id"
                      :disabled="isLoading"
                    >
                      <span class="font-weight-bold">{{ filter.name }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <div
                    v-if="mealTypeControl"
                    class="clear-filter-text"
                    :class="{ muted: isLoading }"
                    @click="clearType(FILTERS_TYPE.MEALTYPE)"
                  >
                    clear filter
                  </div>
                </MPCollapse>
              </b-row>
              <b-row class="mx-0 mb-0">
                <MPCollapse
                  title="TEMPERATURE"
                  :is-open-on-mount="false"
                  @from-top-on-open="fromTopOfCollapseOnOpen"
                  variant="transparent"
                >
                  <b-form-radio-group
                    v-model="temperatureControl"
                    name="temperatureRadioControl"
                    stacked
                  >
                    <b-form-radio
                      class="mx-0 mb-2 mealtype"
                      v-for="filter in separatedFlavors.temperature"
                      :id="filter.name"
                      :aria-describedby="filter.name"
                      :key="filter.name"
                      :value="filter.id"
                      :disabled="isLoading"
                    >
                      <span class="font-weight-bold">{{ filter.name }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <div
                    v-if="temperatureControl"
                    class="clear-filter-text"
                    :class="{ muted: isLoading }"
                    @click="clearType(FILTERS_TYPE.TEMPERATURE_TYPE)"
                  >
                    clear filter
                  </div>
                </MPCollapse>
                <MPCollapse
                  title="FLAVOR TYPE"
                  :is-open-on-mount="false"
                  @from-top-on-open="fromTopOfCollapseOnOpen"
                  variant="transparent"
                >
                  <b-form-radio-group
                    v-model="flavorControl"
                    name="flavorRadioControl"
                    stacked
                  >
                    <b-form-radio
                      class="mx-0 mb-2 mealtype"
                      v-for="filter in separatedFlavors.flavors"
                      :id="filter.name"
                      :aria-describedby="filter.name"
                      :key="filter.name"
                      :value="filter.id"
                      :disabled="isLoading"
                    >
                      <span class="font-weight-bold">{{ filter.name }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <div
                    v-if="flavorControl"
                    class="clear-filter-text"
                    :class="{ muted: isLoading }"
                    @click="clearType(FILTERS_TYPE.FLAVOR_TYPE)"
                  >
                    clear filter
                  </div>
                </MPCollapse>
              </b-row>
              <b-row class="mx-0 mb-0">
                <MPCollapse
                  title="CUISINE PROFILES"
                  :is-open-on-mount="false"
                  @from-top-on-open="fromTopOfCollapseOnOpen"
                  variant="transparent"
                >
                  <b-form-radio-group
                    v-model="cuisineControl"
                    name="cuisineRadioControl"
                    stacked
                  >
                    <b-form-radio
                      class="mx-0 mb-2 mealtype"
                      v-for="filter in recipeFilters.flavor_profiles"
                      :aria-describedby="filter.name"
                      :key="filter.name"
                      :value="filter.id"
                      :disabled="isLoading"
                    >
                      <span class="font-weight-bold">{{ filter.name }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                  <div
                    v-if="cuisineControl"
                    class="clear-filter-text"
                    :class="{ muted: isLoading }"
                    @click="clearType(FILTERS_TYPE.FLAVOR_PROFILE)"
                  >
                    clear filter
                  </div>
                </MPCollapse>
              </b-row>
              <b-row class="mx-0">
                <MPCollapse
                  title="ADDITIONAL FILTERS"
                  :is-open-on-mount="true"
                  @from-top-on-open="fromTopOfCollapseOnOpen"
                  variant="transparent"
                >
                  <span class="filter-title small">Rating</span>
                  <b-row class="mx-0 mb-3">
                    <b-col cols="9" offset="3">
                      <star-rating
                        :increment="COMPONENT_TYPES.RATING_INTERVAL"
                        :max-rating="COMPONENT_TYPES.RATING_MAX"
                        :star-size="20"
                        active-color="#002940"
                        :inline="true"
                        v-model="filterByRating"
                        :clearable="true"
                        :show-rating="false"
                        :read-only="isLoading"
                        @rating-selected="onByRatingSelected"
                      >
                      </star-rating>
                      <span class="text-clr-navy small" v-if="!filterByRating">
                        All
                      </span>
                      <span v-else class="text-clr-navy small">
                        {{ filterByRating === 5 ? '4.5' : filterByRating }}
                        <span v-if="filterByRating">+</span>
                      </span>
                    </b-col>
                  </b-row>
                  <span class="filter-title small">Duration</span>
                  <b-row class="mx-0 mb-3">
                    <b-col cols="12">
                      <RangeSlider
                        class="range-slider"
                        :min="COMPONENT_TYPES.PREP_MIN"
                        :max="COMPONENT_TYPES.PREP_MAX"
                        :height="2"
                        :dotSize="14"
                        :interval="COMPONENT_TYPES.PREP_INTERVAL"
                        :lazy="true"
                        :tooltip-formatter="
                          formatToolTip(COMPONENT_TYPES.PREP_MAX, 'min')
                        "
                        v-model="prepTime"
                        @change="onChangePrepTime"
                        :disabled="isLoading"
                      />
                    </b-col>
                    <b-col class="text-clr-navy text-center small">
                      <span v-if="prepTime === COMPONENT_TYPES.PREP_MAX">
                        All
                      </span>
                      <span v-else> Up to {{ prepTime }} minutes </span>
                    </b-col>
                  </b-row>
                  <span class="filter-title small">Calories</span>
                  <b-row class="mx-0 mb-3">
                    <b-col cols="12">
                      <RangeSlider
                        class="range-slider"
                        :min="COMPONENT_TYPES.CALORIES_MIN"
                        :max="COMPONENT_TYPES.CALORIES_MAX"
                        :height="2"
                        :dotSize="14"
                        :interval="COMPONENT_TYPES.CALORIES_INTERVAL"
                        :lazy="true"
                        :tooltip-formatter="
                          formatToolTip(COMPONENT_TYPES.CALORIES_MAX, 'cal')
                        "
                        v-model="calories"
                        @change="onChangeCaloriesCount"
                        :disabled="isLoading"
                      />
                    </b-col>
                    <b-col class="text-clr-navy text-center small">
                      <span v-if="calories === COMPONENT_TYPES.CALORIES_MAX">
                        All
                      </span>
                      <span v-else> {{ caloriesText }} </span>
                    </b-col>
                  </b-row>
                  <b-row class="mx-0 mb-3">
                    <b-col class="px-0">
                      <span
                        class="filter-title small"
                        @click="
                          onToggleCheckboxFilter(
                            FILTERS_TYPE.KID_APPROVED,
                            'Kid Approved'
                          )
                        "
                      >
                        Kid Approved
                      </span>
                    </b-col>
                    <b-col
                      class="px-0 text-right"
                      @click="
                        onToggleCheckboxFilter(
                          FILTERS_TYPE.KID_APPROVED,
                          'Kid Approved'
                        )
                      "
                      :disabled="isLoading"
                    >
                      <MPSelect
                        class="mr-2"
                        :is-selected="filtersApplied[FILTERS_TYPE.KID_APPROVED]"
                        color="green"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mx-0">
                    <b-col class="px-0">
                      <span
                        class="filter-title small"
                        @click="
                          onToggleCheckboxFilter(
                            FILTERS_TYPE.NO_WEEKEND_PREP,
                            'No Weekend Prep'
                          )
                        "
                      >
                        No weekend prep
                      </span>
                    </b-col>
                    <b-col
                      class="px-0 text-right"
                      @click="
                        onToggleCheckboxFilter(
                          FILTERS_TYPE.NO_WEEKEND_PREP,
                          'No Weekend Prep'
                        )
                      "
                    >
                      <MPSelect
                        class="mr-2"
                        :is-selected="
                          filtersApplied[FILTERS_TYPE.NO_WEEKEND_PREP]
                        "
                        color="green"
                      />
                    </b-col>
                  </b-row>
                </MPCollapse>
              </b-row>
            </b-col>
          </b-row>
          <div
            v-if="matchingFiltersLabel"
            class="d-flex justify-content-center align-items-center mb-2"
          >
            <hr class="sidebar-division flex-shrink-1" />
            <label class="flex-grow-1 text-nowrap mb-0 mx-1">{{
              matchingFiltersLabel
            }}</label>
            <hr class="sidebar-division flex-shrink-1" />
          </div>
          <hr v-else class="sidebar-division" />
          <b-row class="mx-0 filters-footer">
            <b-col cols="12" class="px-0">
              <b-button
                block
                variant="primary"
                size="lg"
                @click="onClose"
                class="mb-0"
              >
                <span>{{ showButtonLabel }}</span>
              </b-button>
            </b-col>
            <div class="w-100" v-if="totalFiltersApplied > 0">
              <b-col cols="12" class="px-0">
                <b-button
                  block
                  variant="link"
                  class="mb-0 clear-filters-button"
                  @click="clearFiltering"
                >
                  <u> Clear All Filters</u>
                </b-button>
              </b-col>
            </div>
          </b-row>
        </div>
      </aside>
    </transition>
  </div>
</template>

<script>
import { FILTERS_TYPE, COMPONENT_TYPES } from './filterSideBarConstants'
import StarRating from 'vue-star-rating'
import { mapGetters } from 'vuex'
import RangeSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { events } from '@/utils/mixpanel.events'
import MPButton from '@/components/common/MPButton.vue'

export default {
  components: {
    RangeSlider,
    StarRating,
    MPButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FILTERS_TYPE,
      COMPONENT_TYPES,
    }
  },
  computed: {
    ...mapGetters({
      allRecipeBoxItems: 'RecipeBox/recipeBoxs',
      primaryRecipes: 'RecipeBox/primaryRecipes',
      ingredientList: 'recipe/ingredientList',
      caloriesSelected: 'RecipeBox/caloriesSelected',
      caloriesValues: 'RecipeBox/caloriesValues',
      filterByRatingSelected: 'RecipeBox/filterByRatingSelected',
      searchText: 'RecipeBox/searchText',
      preferences: 'user/preferences',
      selectedTagFiltersSelected: 'RecipeBox/selectedTagFiltersSelected',
      selectedTags: 'RecipeBox/selectedTags',
      recipesFound: 'RecipeBox/recipesFound',
      allRecipesFound: 'RecipeBox/allRecipesFound',
      recipeQueryStatus: 'RecipeBox/recipeQueryStatus',
      recipeFilters: 'RecipeBox/recipeFilters',
      filtersApplied: 'RecipeBox/filtersApplied',
      totalFiltersApplied: 'RecipeBox/totalFiltersApplied',
      primaryCount: 'RecipeBox/primaryCount',
      generalCount: 'RecipeBox/generalCount',
      searchTextState: 'RecipeBox/searchText',
      isLoading: 'RecipeBox/isLoading',
      primaryAsGeneral: 'RecipeBox/getPrimaryAsGeneral',
    }),
    prepTime: {
      get() {
        return this.filtersApplied[FILTERS_TYPE.PREP_TIME]
      },
      set() {},
    },
    calories: {
      get() {
        return this.filtersApplied[FILTERS_TYPE.CALORIES]
      },
      set() {},
    },
    thereIsRecipes() {
      return !this.allRecipeBoxItems.length && !this.primaryRecipes.length
    },
    caloriesText() {
      return `${this.filtersApplied[FILTERS_TYPE.CALORIES]} or less`
    },
    separatedFlavors() {
      return {
        temperature: this.recipeFilters.flavor_types.filter((flavor) => {
          const flavorLower = flavor.name.toLowerCase()
          return flavorLower === 'hot' || flavorLower === 'cold'
        }),
        flavors: this.recipeFilters.flavor_types.filter((flavor) => {
          const flavorLower = flavor.name.toLowerCase()
          return flavorLower !== 'hot' && flavorLower !== 'cold'
        }),
      }
    },
    appliedFiltersDescription() {
      if (this.searchText && this.totalFiltersApplied > 0) {
        return `(${this.totalFiltersApplied}) | searching "${this.searchText}"`
      } else if (this.totalFiltersApplied > 0) {
        return `${this.totalFiltersApplied} Selected`
      } else if (this.searchText) {
        return `searching "${this.searchText}"`
      }
      return null
    },
    ingredientsItems() {
      return this.recipeFilters.ingredients
    },
    hasIncludedOrExcluded() {
      return (
        this.selectedTagFiltersSelected.included.length ||
        this.selectedTagFiltersSelected.excluded.length
      )
    },
    filterByRating: {
      get() {
        return this.filterByRatingSelected
      },
      set() {},
    },
    radioControlArray() {
      const list = Object.keys(this.radio_control).map((key) => {
        return [key, this.radio_control[key]]
      })
      return list
    },
    matchingFiltersLabel() {
      if (this.primaryAsGeneral) {
        return `${this.generalCount} recipes match`
      } else if (this.primaryCount) {
        if (this.primaryCount === 1) {
          return `${this.primaryCount} recipe matches`
        }
        return `${this.primaryCount} recipes match`
      } else {
        return ''
      }
    },
    showButtonLabel() {
      if (this.primaryAsGeneral) {
        return 'Show Recipes'
      } else if (
        this.primaryCount ||
        (this.totalFiltersApplied <= 1 && this.searchTextState.length === 0)
      ) {
        return 'Show Recipes'
      } else {
        return 'Show Similar Recipes'
      }
    },
    temperatureControl: {
      get() {
        const temperature =
          this.filtersApplied[this.FILTERS_TYPE.TEMPERATURE_TYPE]
        return temperature ? temperature.id : null
      },
      set(id) {
        this.toggleRadioFilter(
          id,
          this.separatedFlavors.temperature,
          FILTERS_TYPE.TEMPERATURE_TYPE
        )
      },
    },
    flavorControl: {
      get() {
        const flavor = this.filtersApplied[this.FILTERS_TYPE.FLAVOR_TYPE]
        return flavor ? flavor.id : null
      },
      set(id) {
        this.toggleRadioFilter(
          id,
          this.separatedFlavors.flavors,
          FILTERS_TYPE.FLAVOR_TYPE
        )
      },
    },
    mealTypeControl: {
      get() {
        const meal = this.filtersApplied[this.FILTERS_TYPE.MEALTYPE]
        return meal ? meal.id : null
      },
      set(id) {
        this.toggleRadioFilter(
          id,
          this.recipeFilters.meal_types,
          FILTERS_TYPE.MEALTYPE
        )
      },
    },
    cuisineControl: {
      get() {
        const cuisine = this.filtersApplied[this.FILTERS_TYPE.FLAVOR_PROFILE]
        return cuisine ? cuisine.id : null
      },
      set(id) {
        this.toggleRadioFilter(
          id,
          this.recipeFilters.flavor_profiles,
          FILTERS_TYPE.FLAVOR_PROFILE
        )
      },
    },
  },
  methods: {
    formatToolTip(max_val, append) {
      return (value) => {
        if (max_val === value) {
          return 'All'
        }

        return value + ' ' + append
      }
    },
    onClose() {
      this.$emit('close')
    },
    clearFiltering() {
      this.$store.dispatch('analytics/track', {
        eventName: events.filters.clear,
      })
      this.$store.commit('RecipeBox/SET_SELECTED_FILTERS_TO_DEFAULT')
      this.searchByFilters()
      this.$emit('clearfilters')
    },
    onFilterByNewIngredient(ingredient) {
      //Adds selected ingredient to the ingredients items
      if (
        !this.recipeFilters.ingredients.some((ing) => ing.name === ingredient)
      ) {
        ingredient = this.ingredientList.filter(
          (i) => i.singularName === ingredient
        )

        if (ingredient.length === 0) {
          return
        }

        ingredient = ingredient[0]
        this.$store.dispatch('RecipeBox/addFilterByNewIngredientSelected', {
          id: ingredient.id,
          name: ingredient.singularName,
          group: FILTERS_TYPE.INGREDIENTS,
        })
      }
    },
    onFlavorProfileSelected(filterItem) {
      this.$store.dispatch('RecipeBox/setFlavorProfileSelected', filterItem)
    },
    onByRatingSelected(value) {
      const filtersApplied = this.filtersApplied
      filtersApplied[FILTERS_TYPE.RATING] = value

      this.$store.dispatch('analytics/track', {
        eventName: events.filters.value,
        params: {
          filter: 'Rating',
          value,
        },
      })

      this.$store.commit(
        'RecipeBox/UPDATE_RECIPE_FILTERS_SELECTED',
        filtersApplied
      )

      this.searchByFilters()
    },
    onChangePrepTime(value) {
      this.$store.dispatch('RecipeBox/setPrepTimeSelected', value)
      this.searchByFilters()
    },
    onChangeCaloriesCount(value) {
      const filtersApplied = this.filtersApplied
      filtersApplied[this.FILTERS_TYPE.CALORIES] = value
      this.$store.dispatch('analytics/track', {
        eventName: events.filters.value,
        params: {
          filter: 'Calories',
          value: value,
        },
      })

      this.$store.commit(
        'RecipeBox/UPDATE_RECIPE_FILTERS_SELECTED',
        filtersApplied
      )

      this.searchByFilters()
    },
    onIngredientsCountSelected(value) {
      this.$store.dispatch('RecipeBox/setIngredientsCountSelected', value)
    },
    fromTopOfCollapseOnOpen(fromTop) {
      const { offsetHeight, scrollTop } = this.$refs['filters-sidebar']
      const minFromBottom = 80
      const maxFromTop = offsetHeight + scrollTop - minFromBottom
      if (fromTop > maxFromTop) {
        const scrollY = scrollTop + (fromTop - maxFromTop)
        this.$refs['filters-sidebar'].scrollTo({
          top: scrollY,
          behavior: 'smooth',
        })
      }
    },

    /**
     * Fires checkbox toogle.
     * It works for Kids Approved and No Weeknd Prep filters.
     *
     * @param {string} filterType
     * @param {string} label
     */
    onToggleCheckboxFilter(filterType, label) {
      if (this.isLoading) {
        return
      }

      const filtersApplied = this.filtersApplied
      filtersApplied[filterType] = !this.filtersApplied[filterType]
      // Dispatch event
      this.$store.dispatch('analytics/track', {
        eventName: events.filters.check,
        params: {
          filter: label,
          value: filtersApplied[filterType],
        },
      })

      // Update stored values
      this.$store.commit(
        'RecipeBox/UPDATE_RECIPE_FILTERS_SELECTED',
        filtersApplied
      )

      // Fire the search
      this.searchByFilters()
    },

    removeFilterBy(filter, action = 'included') {
      const filtersApplied = this.filtersApplied

      if (filter.filterType === this.FILTERS_TYPE.INGREDIENTS) {
        // Remove it by filtering the current filter.
        filtersApplied[filter.filterType][action] = this.filtersApplied[
          filter.filterType
        ][action].filter((f) => filter.id !== f.id)
      } else {
        filtersApplied[filter.filterType] = null
      }

      // Send tracking event
      this.$store.dispatch('analytics/track', {
        eventName: events.filters.remove,
        params: {
          type: filter.filterType,
          value: filter.name,
        },
      })

      // Update store
      this.$store.commit(
        'RecipeBox/UPDATE_RECIPE_FILTERS_SELECTED',
        filtersApplied
      )

      this.searchByFilters()
    },

    /**
     * check if the ingredient is used to filter.
     *
     * @param {object} ingredient the element to filter
     * @param {string} filterType delete?
     * @param {string} action included or excluded
     */
    checkIngredientFiltered(ingredient, action = 'included') {
      // get ingredients selected for this action
      const type = this.FILTERS_TYPE.INGREDIENTS
      const currentIngredients = this.filtersApplied[type][action]

      // if the filter is active in the actual action remove it and return
      if (currentIngredients.some((filter) => filter.id === ingredient.id)) {
        return this.removeIngredientFilter(ingredient, action)
      }

      // get ingredients selected for the oposite action action
      const opositeAction = action === 'included' ? 'excluded' : 'included'
      const opositeIngredients = this.filtersApplied[type][opositeAction]

      // if the ingredient is active in the opposite action remove it
      if (opositeIngredients.some((filter) => filter.id === ingredient.id)) {
        this.removeIngredientFilter(ingredient, opositeAction)
      }

      // add the ingredient for the current action
      return this.addIngredientFilter(ingredient, action)
    },

    /**
     * Add a ingredient
     *
     * @param {object} ingredient the element to filter
     * @param {string} filterType ie: meal_type, ingredients, etc...
     * @param {string} action can be included or excluded
     */
    addIngredientFilter(ingredient, action = 'included') {
      // Get the filters applied only for the specifi filter type
      const type = this.FILTERS_TYPE.INGREDIENTS
      let filtersApplied = this.filtersApplied[type]
      ingredient.filterType = type // Attach the type to the element to idenify it later.
      filtersApplied[action].push(ingredient) // Add new one.

      // Dispatch track event
      this.$store.dispatch('analytics/track', {
        eventName: events.filters[action],
        params: {
          type: type,
          value: ingredient.name,
          included: filtersApplied.included
            .map((value) => value.name)
            .join(','),
          excluded: filtersApplied.excluded
            .map((value) => value.name)
            .join(','),
        },
      })

      // Update the store.
      this.$store.commit(
        'RecipeBox/UPDATE_RECIPE_INGREDIENTS_FILTERS_SELECTED',
        {
          filterType: type,
          filtersApplied,
        }
      )

      // Dispatch the filter query.
      this.searchByFilters()
    },

    /**
     * Remove an ingredient
     *
     * @param {object} ingredient the element to filter
     * @param {string} filterType ie: meal_type, ingredients, etc...
     * @param {string} action can be included or excluded
     */
    removeIngredientFilter(ingredient, action = 'included') {
      // Get the filters applied based on the filterType
      const type = this.FILTERS_TYPE.INGREDIENTS
      let filtersApplied = this.filtersApplied[type]
      // Remove it by filtering the current filter.
      filtersApplied[action] = filtersApplied[action].filter(
        (f) => ingredient.id !== f.id
      )

      // Send tracking event
      this.$store.dispatch('analytics/track', {
        eventName: events.filters.remove,
        params: {
          type,
          value: ingredient.name,
        },
      })

      // Update store
      this.$store.commit(
        'RecipeBox/UPDATE_RECIPE_INGREDIENTS_FILTERS_SELECTED',
        {
          filterType: type,
          filtersApplied,
        }
      )

      // Dispatch the filter query
      this.searchByFilters()
    },

    /**
     * Dispatch the recipe search
     *
     * @return {void}
     */
    searchByFilters() {
      this.$store.dispatch('RecipeBox/getRecipes', {
        page: 1,
        searchText: this.searchText,
        filters: this.filtersApplied,
      })
    },

    toggleRadioFilter(filterId, list, type) {
      if (!filterId) {
        return
      }

      let selectedFilter = list.filter(({ id }) => id === filterId)[0]
      const filtersApplied = this.filtersApplied
      filtersApplied[type] = { ...selectedFilter, filterType: type }

      this.$store.dispatch('analytics/track', {
        eventName: events.filters.remove,
        params: {
          type,
          value: selectedFilter.name,
        },
      })

      this.$store.commit(
        'RecipeBox/UPDATE_RECIPE_FILTERS_SELECTED',
        filtersApplied
      )

      this.searchByFilters()
    },
    clearType(type) {
      if (this.isLoading) {
        return
      }

      const filtersApplied = this.filtersApplied
      filtersApplied[type] = null

      this.$store.commit(
        'RecipeBox/UPDATE_RECIPE_FILTERS_SELECTED',
        filtersApplied
      )

      this.searchByFilters()
    },
  },
  destroyed() {
    clearAllBodyScrollLocks()
  },
}
</script>

<style lang="scss" scoped>
.slide-leave-active,
.slide-enter-active {
  transition: 0.5s;
}
.slide-enter {
  transform: translate3d(0, 100%, 0);
  @include media-breakpoint-up(md) {
    transform: translate3d(100%, 0, 0);
  }
}
.slide-leave-to {
  transform: translate3d(0, 100%, 0);
  @include media-breakpoint-up(md) {
    transform: translate3d(100%, 0, 0);
  }
}

.filters-sidebar {
  box-shadow: 0px 2px 27px rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #eeeeee;
  position: fixed;
  top: 0px;
  bottom: 0;
  width: 100%;
  z-index: 99;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  @include media-breakpoint-up(md) {
    width: 400px;
    right: 0;
  }
}

.filters-sidebar-content {
  padding: 0 15px 20px;
  display: flex;
  max-height: 95vh;
  flex-flow: column;
  @include media-breakpoint-up(md) {
    padding: 20px 20px 20px;
  }
}

.filters-clear {
  display: flex;
  font-size: rem(13);
  color: #747e8b;
  .link {
    white-space: nowrap;
    padding-right: 4px;
    color: $body-color;
  }
}

.filters-sidebar-header {
  display: flex;
  position: sticky;
  top: 0;
  background-color: #eeeeee;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  z-index: 1;
  @include media-breakpoint-up(md) {
    padding: 0 20px;
  }
}

.filters-sidebar-header-content {
  flex: 1;
  overflow: hidden;
}

.filter-by-type-button {
  flex-direction: column;
  padding: rem(10) 0;

  ::v-deep .icon-image {
    width: 30px;
    height: 30px;
    background-size: contain;
    margin-bottom: 0;
  }

  &:first-child {
    ::v-deep .icon-image {
      width: 20px;
      height: 20px;
      margin-top: 3px;
      margin-bottom: 6px;
    }
  }

  + .filter-by-type-button {
    margin-left: 10px;
  }
}

.close-icon {
  display: block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  color: #747e8b;
  opacity: 1;
  cursor: pointer;
  &:hover {
    color: #404852;
  }
}

.buttons-group {
  display: flex;
  flex-wrap: wrap;
}

.buttons-group-desc {
  display: block;
  font-size: rem(12);
  color: $grey;
  font-weight: 500;
  padding-bottom: 10px;
}

.buttons-group-item {
  display: flex;
  width: 50%;
  padding-right: 5px;
  align-items: center;
  &:nth-child(1n + 3) {
    padding-top: 10px;
  }
  &:nth-child(even) {
    padding-right: 0;
    padding-left: 5px;
  }
}

.buttons-group-item-text {
  flex: 1;
  font-size: rem(12);
}

.close-button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: sticky;
  bottom: 20px;
}

.close-button {
  width: 100%;
  background-color: $success;
  font-size: rem(18);
  font-weight: 700;
  text-transform: uppercase;
}

// rating
.rating-button {
  flex-direction: row;

  &--2 {
    ::v-deep .icon-image {
      margin: 0 7px 0 0;
      width: 28px;
      height: 14px;
    }
  }
  &--3 {
    ::v-deep .icon-image {
      margin: 0 7px 0 0;
      width: 15px * 3;
      height: 14px;
    }
  }
  &--4 {
    ::v-deep .icon-image {
      margin: 0 7px 0 0;
      width: 15px * 4;
      height: 14px;
    }
  }
}

// range-slider
.range-slider {
  margin: 0 10px;
}

.prep-time-description-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 0.75rem;
  font-weight: 500;
  color: #747e8b;
}

// overlay
.overlay {
  z-index: 16;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.text-clr-navy {
  color: $clr-navy;
}

.text-clr-gray {
  color: #747e8b;
}

// horizontal rule style
.sidebar-division {
  width: 100%;
}

//filters-header
.filters-header {
  flex-shrink: 0;
  flex-grow: 1;

  &-title {
    text-transform: uppercase;
    text-decoration: underline;
    color: $clr-navy;
  }
}

//filters-content
.filters-content {
  flex-shrink: 1;
  flex-grow: 0;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
}

//filters-footer
.filters-footer {
  flex-shrink: 0;
  flex-grow: 1;
}

//filtering title
.filter-title {
  text-transform: uppercase;
  color: $clr-navy;
}

//filter tags

.filter-tags {
  line-height: 14px;
  margin-right: 4px;
}
.filter-tags-included {
  min-height: min-content;
  width: 100%;
  > .included-text {
    color: $clr-navy;
  }
}

.filter-tags-excluded {
  min-height: min-content;
  width: 100%;
  border-top: 1px dashed $iron;
  > .included-text {
    color: $clr-navy;
  }
}

.circle-badge {
  padding: 2px 3.3px;
}

//search input
.search-input-container-icon {
  position: absolute;
  left: 0;
  background-image: url('~@/assets/icons/search.svg');
  background-size: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  width: 45px;
  bottom: 0;
  top: 0;
}

.input-group-text {
  width: 45px;
  border: none;
  background-color: white;
}

.ingredient-search {
  border: none;
}

.ingredient-group {
  border: 1px solid $iron;

  &:focus-within {
    border: 1px solid $primary !important;
  }

  &:hover {
    border: 1px solid $clr-navy;
  }
}

//mealtype
.mealtype {
  line-height: 28px;
}

.collapse-badge {
  width: 25px;
  border-radius: 6px;
  border: solid 0.5px $iron;
  font-weight: 900;
  padding: 0px;
  font-size: 100%;
  padding-top: 4px;
  height: 25px;
}

.span-success {
  background: url('~@/assets/icons/add-symbol.svg') no-repeat center;
  background-color: $white;

  &:hover {
    background: url('~@/assets/icons/add-symbol-inactive.svg') no-repeat center;
    background-color: $primary;
    color: white !important;
  }

  &-selected {
    background: url('~@/assets/icons/add-symbol-inactive.svg') no-repeat center;
    background-color: $primary;
    color: white !important;

    &:hover {
      background-color: $success-hover;
      border: solid 0.5px $iron;
    }
  }
}

.span-danger {
  background: url('~@/assets/icons/minus-symbol.svg') no-repeat center;
  background-color: $white;

  &:hover {
    background: url('~@/assets/icons/minus-symbol-inactive.svg') no-repeat
      center;
    background-color: $danger;
    color: white !important;
  }

  &-selected {
    background: url('~@/assets/icons/minus-symbol-inactive.svg') no-repeat
      center;
    background-color: $danger;
    color: white !important;

    &:hover {
      background-color: $error-hover;
      border: solid 0.5px $iron;
    }
  }
}

.mp-collapse {
  width: 100%;
}

.close-icon {
  width: 10px;
  height: 10px;
  font-weight: bold;
  color: $danger;
}

//Clear Filters

.clear-filters-button {
  color: $clr-navy;
  font-size: 1.1rem;
  font-weight: bold;
}

.clear-filter-text {
  color: $primary;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $success-hover;
  }

  &.muted {
    cursor: default;
    color: #747e8b;
  }
}
</style>

<style lang="scss">
.range-slider {
  .vue-slider-rail {
    background-color: #999999;
  }

  .vue-slider-process {
    background-color: $primary;
  }

  .vue-slider-dot-tooltip-inner {
    background-color: $clr-navy;
    border-color: $clr-navy;
    font-size: 0.625rem;
    padding: 4px 6px;
  }

  .vue-slider-dot-handle {
    border: solid 1.5px $primary;
    box-shadow: none;
    background-color: $primary;
  }

  .vue-slider-dot-handle-focus {
    background-color: $clr-navy;
  }
}
</style>
