<template>
  <div>
    <button class="btn btn-link edit-recipe" @click="$emit('edit')">
      <Edit />
      <span class="ml-2">Edit</span>
    </button>
    <button class="btn btn-link delete-recipe ml-2" @click="$emit('delete')">
      <Bin />
      <span class="ml-1">Delete</span>
    </button>
  </div>
</template>

<script>
import { Bin, Edit } from '@/components/common/MPIcons'

export default {
  name: 'TheCustomRecipe',
  components: {
    Bin,
    Edit,
  },
}
</script>

<style lang="scss" scoped>
.edit-recipe,
.delete-recipe {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  svg {
    vertical-align: sub;
  }
}
.delete-recipe {
  color: $error;
  &:hover {
    color: $error-hover;
  }
}
</style>
