import api from './'
import camelizeKeys from './camelizeKeys'

const GET_WEEKEND_PREP_DATA = '/recipes/text-prep-ahead/'
const GET_WEEKEND_PREP_PREVIEW_DATA = '/recipes/preview-text-prep-ahead/'
const SET_WEEKEND_PREP_NOTE = '/dashboard/weekend-prep-note/'
const TOGGLE_EXCLUDE_SPEED_PREP = 'recipes/toggle-exclude-speed-prep/'
const GET_SPEED_PREP_DATA = 'recipes/speed-prep/'
const GET_SPEED_PREP_DATA_PREVIEW = 'recipes/preview-speed-prep/'

export default {
  getWeekendPrepData(plan, params) {
    var url = GET_WEEKEND_PREP_DATA + plan
    return api.get(url, params).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  // url(r'^preview-text-prep-ahead/(?P<meal_plan_id>\d+)/(?P<token>\w+)/(?P<intolerance>\w+)/$',
  getWeekendPrepPreviewData(plan, params, previewParams) {
    var url = `${GET_WEEKEND_PREP_PREVIEW_DATA}${previewParams.mealPlanId}/${previewParams.token}/${previewParams.intolerance}`
    return api.get(url, params).then((res) => {
      return camelizeKeys(res.data)
    })
  },
  async setWeekendPrepNote(userWeeklyPlan, text) {
    const url = `${SET_WEEKEND_PREP_NOTE}${userWeeklyPlan}/`
    const results = await api.post(url, { text: text })
    return camelizeKeys(results.data)
  },
  async toggleExclude(userWeeklyPlan, { ids, cids, isExcluded }) {
    const url = TOGGLE_EXCLUDE_SPEED_PREP
    return api.post(url, {
      ids,
      cids,
      is_excluded: isExcluded,
      user_weekly_plan_id: userWeeklyPlan,
    })
  },
  async getSpeedPrepData(mealPlan, params) {
    const url = `${GET_SPEED_PREP_DATA}${mealPlan}/`
    const res = await api.get(url, params)
    return camelizeKeys(res.data)
  },
  async getSpeedPrepPreviewData(mealPlan, params, previewParams) {
    const url = `${GET_SPEED_PREP_DATA_PREVIEW}${previewParams.mealPlanId}/${previewParams.token}/`
    const res = await api.get(url, params)
    return camelizeKeys(res.data)
  },
}
