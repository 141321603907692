<template>
  <MPPopover
    v-if="showTooltip"
    :show-on-hover="false"
    :show-on-click="true"
    class="speed-prep-popover"
    :hide-on-mouse-leave="false"
    placement=""
  >
    <template slot="button">
      <div class="speed-prep-icon">
        <Knife width="24" class="knife-icon" />
      </div>
    </template>
    <div slot="popover" class="d-flex flex-column">
      <slot name="content"></slot>
      <MPButton
        color="primary"
        class="mt-2 mr-2 got-it"
        v-if="tooltipButtonText"
        v-text="tooltipButtonText"
      />
    </div>
  </MPPopover>
  <div v-else class="speed-prep-icon">
    <Knife width="24" class="knife-icon" />
  </div>
</template>

<script>
import { Knife } from '@/components/common/MPIcons'

export default {
  name: 'SpeedPrepIconComponent',
  components: {
    Knife,
  },
  props: {
    showTooltip: {
      default: true,
    },
    tooltipButtonText: {
      default: null,
    },
  },
}
</script>

<style lang="scss">
.speed-prep-popover {
  .mp-popover-button {
    height: 1rem;
  }
  .got-it {
    align-self: flex-end;
  }
  .popover {
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px $light-grey;
    background-color: #ffffff;
  }
  .popover-body {
    padding: 1.1875rem 1rem;
    max-width: 286px;
    font-size: 0.875rem;
  }
  .popover-link {
    font-size: 0.875rem;
    line-height: 0.93;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
  .popover-content {
    line-height: 1.5;
    color: $text-gray;
  }
  .popover-used-in {
    font-size: 0.6875rem;
    font-weight: 500;
    letter-spacing: 0.4px;
    color: #404852;
  }
}
.speed-prep-icon {
  display: inline-block;
}

.knife-icon {
  color: $primary;
}
</style>
