import analytics from '@/externalServices/analytics.js'
import { ANALYTICS_EVENTS_HANDLERS } from './analytics.utils'

const actions = {
  async track(context, payload) {
    let handler = ANALYTICS_EVENTS_HANDLERS[payload.eventName]
    if (handler) {
      if (!Array.isArray(handler)) {
        handler = [handler]
      }
      for (const h of handler) {
        payload = await h(context, payload)
      }
    }
    analytics.track(payload.eventName, payload.params)
  },
}

export default {
  namespaced: true,
  actions,
}
