import _ from 'lodash'

const LoadingMixin = {
  methods: {
    setLoading: function (isLoading, longWaitText, longWaitTextDelay) {
      this.$store.dispatch('globals/setLoading', isLoading)
      this.$store.dispatch('globals/setLongWaitText', null)
      if (isLoading && longWaitText) {
        const store = this.$store
        _.delay(
          function () {
            store.dispatch('globals/setLongWaitText', longWaitText)
          },
          longWaitTextDelay ? longWaitTextDelay : 5000
        )
      }
    },
  },
}

export default LoadingMixin
