export const EVENTS = {
  registration: {
    step1: 'registration.step1',
    step2: 'registration.step2',
    step3: 'registration.step3',
    step4: 'registration.step4',
    step5: 'registration.step5',
    step6: 'registration.step6',
    skip: 'registration.skip',
    createAccount: 'registration.createAccount',
  },
  subscription: {
    create: 'subscription.create',
    reactivate: 'subscription.reactivate',
    initCancel: 'subscripiton.iniCancel',
  },
  recipe: {
    favorite: 'recipe.favorite',
  },
  plan: {
    visit: 'plan.visit',
  },
  recipeBox: {
    visit: 'recipeBox.visit',
  },
  groceryList: {
    visit: 'groceryList.visit',
  },
  weekendPrep: {
    visit: 'weekendPrep.visit',
  },
  personalRecipes: {
    visit: 'personalRecipes.visit',
    created: 'personalRecipes.created',
    edited: 'personalRecipes.edited',
  },
}
