<template>
  <div>
    <div
      v-if="!isNotificationsLoaded"
      class="d-flex justify-content-center mt-4 mt-md-0"
    >
      <MPSpinner :withOverlay="false"></MPSpinner>
    </div>
    <div v-if="isNotificationsLoaded">
      <MPAlert
        class="my-3 notification"
        v-if="alertText"
        type="outline"
        :text="alertText"
      ></MPAlert>
      <SettingCard
        v-for="notification in notifications"
        :key="notification.key"
        type="rows-last"
      >
        <template slot="label">
          <div class="mb-2">{{ notification.title }}</div>
        </template>
        <template slot="content">
          {{ notification.description }}
        </template>
        <template slot="actions-content">
          <MPSwitch
            class="ml-3"
            v-model="notification.isActive"
            @change="(value) => toggleNotification(notification, value)"
          />
        </template>
      </SettingCard>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingCard from './SettingCard'

export default {
  components: {
    SettingCard,
  },
  data() {
    return {
      alertText: null,
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'user/notifications',
      isNotificationsLoaded: 'user/isNotificationsLoaded',
    }),
  },
  methods: {
    toggleNotification(notification, isActive) {
      this.$store.dispatch('user/toggleNotification', {
        apiKey: notification.apiKey,
        id: notification.id,
        isActive,
      })
      this.alertText = `
        You have been ${isActive ? 'resubscribed to' : 'unsubscribed from'}
        ${notification.title} emails.`
      const intercomData = notification.getIntercomData(isActive)
      if (intercomData) {
        // notify intercom
        this.$store.dispatch('user/setIntercomUserAttributes', intercomData)
      }
    },
  },
}
</script>

<style lang="scss">
.notification .alert {
  border-left: solid 4px $link-color;
  padding: 1rem;
  text-align: center;
  color: $link-color;
  font-weight: 500;
  background-color: white;
}
</style>
