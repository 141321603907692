export function initFbSdk(options) {
  /* eslint-disable */
  return new Promise((resolve) => {
    window.fbAsyncInit = function () {
      const defaults = { cookie: true, xfbml: true }
      options = { ...defaults, ...options }
      window.FB.init(options)
      resolve()
    }
    ;(function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      const js = d.createElement(s)
      js.id = id
      js.src = '//connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  })
  /* eslint-enable */
}

export function getFbSdk(options) {
  return new Promise((resolve) => {
    if (window.FB) {
      resolve(window.FB)
    } else {
      initFbSdk(options).then(() => {
        resolve(window.FB)
      })
    }
  })
}

export function fbLogin() {
  return new Promise((resolve) => {
    window.FB.login((response) => resolve(response), {
      scope: 'public_profile, email',
    })
  })
}

export function getFbLoginStatus() {
  return new Promise((resolve) => {
    window.FB.getLoginStatus((response) => resolve(response))
  })
}

export function getFbUserInfo(credentials) {
  return new Promise((resolve) => {
    window.FB.api('/me', { ...credentials }, (response) => resolve(response))
  })
}
