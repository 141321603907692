import StickyFill from 'stickyfilljs'

export default {
  inserted(el) {
    StickyFill.addOne(el)
  },
  unbind(el) {
    StickyFill.removeOne(el)
  },
  install(Vue) {
    Vue.directive('StickyFill', this)
  },
}
