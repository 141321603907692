<template>
  <div class="search-results">
    <span
      v-if="data.text"
      class="search-results-text"
      v-html="data.text"
    ></span>
    <span v-if="data.text"> &nbsp; </span>
    <span class="link" v-if="data.showClearFilter" v-on:click="clearFiltering">
      Clear all filters
    </span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    clearFiltering() {
      this.$emit('clearFiltering')
    },
  },
}
</script>

<style lang="scss" scoped>
.search-results {
  margin: 1rem 0;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.search-results-text {
  color: #727e8c;
}
</style>
