/**
 * This class should handle any business logic between UI and Store/API.
 * It MUST NOT contain any platform (web vs mobile) specific logic or data (e.g. references to window).
 * It is used by both web and in the future also mobile apps.
 */
import HomeApi from '@/api/HomeApi'

export default {
  trackPromotionCode: trackPromotionCode,
  handlePromotionCode: handlePromotionCode,
}

function trackPromotionCode(store, trackCode, isValid) {
  HomeApi.trackAffiliateLinkClick(trackCode, isValid).then(
    (data) => {
      store.dispatch('globals/setAffiliateOfferDiscount', data.discount)
      store.dispatch('globals/setAffiliateOfferExpires', data.expiryDays)
      store.dispatch('globals/setAffiliateOfferBanner', data.bannerHeading)
      store.dispatch(
        'globals/setAlwaysDisplayAffiliateOfferBanner',
        data.alwaysDisplayBanner
      )
      store.dispatch('globals/setPromotionBannerVisible', true)
      setTimeout(() => {
        store.dispatch('globals/setPromotionBannerVisible', false)
      }, 3000)
    },
    () => {
      store.dispatch('globals/setAffiliateOfferDiscount', null)
      store.dispatch('globals/setAffiliateOfferExpires', null)
      store.dispatch('globals/setAffiliateOfferBanner', null)
      store.dispatch('globals/setAlwaysDisplayAffiliateOfferBanner', false)
      store.dispatch('globals/setPromotionBannerVisible', true)
    }
  )
}

function handlePromotionCode(store, refCode) {
  HomeApi.checkAffiliateCode(refCode).then(
    (data) => {
      store.dispatch('user/setPromotionInfo', {
        userDiscountApplied: data.discountApplied,
        userDiscountPercent: data.discount,
        userDiscountNotAppliedReason: data.reason,
        userDiscountPopUpMessage: data.popupMessage,
      })
      store.dispatch('globals/setAlertMemberBannerVisible', true)
      store.dispatch('user/refreshPlan', null)
    },
    () => {
      store.dispatch('user/setPromotionInfo', {
        userDiscountApplied: null,
        userDiscountPercent: null,
        userDiscountNotAppliedReason: null,
        userDiscountPopUpMessage: null,
      })
    }
  )
}
