<template>
  <vue-editor
    class="add-custom-recipe-html-editor"
    v-model="htmlText"
    :editorToolbar="customToolbar"
    :editorOptions="editorOptions"
    @text-change="onTextChange"
  ></vue-editor>
</template>

<script>
import { VueEditor } from 'vue2-editor'
export default {
  name: 'AddCustomRecipeHtmlEditor',
  props: {
    value: {},
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      htmlText: '',
      savable: false,
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        // ["blockquote", "code-block"],
        // [{ header: 1 }, { header: 2 }], // custom button values
        // [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript
        // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ direction: "rtl" }], // text direction
        // [{ size: [false, 'large'] }], // custom dropdown
        [{ header: [2, false] }],
        // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        // [{ font: [] }],
        // [{ align: [] }],
        ['clean'], // remove formatting button
      ],
      editorOptions: {
        formats: [
          'bold',
          'header',
          'italic',
          'link',
          'indent',
          'underline',
          'strike',
        ],
        modules: {
          clipboard: {
            matchVisual: false, // https://quilljs.com/docs/modules/clipboard/#matchvisual
          },
        },
      },
    }
  },
  mounted() {
    this.htmlText = this.value
  },
  methods: {
    onTextChange() {
      if (this.savable || this.value === '') {
        this.$emit('input', this.htmlText)
      }
      this.savable = true
    },
  },
}
</script>

<style lang="scss">
.add-custom-recipe-html-editor {
  .ql-toolbar {
    button {
      margin: 0;
      .ql-stroke {
        stroke: $clr-navy;
      }
    }
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid $clr-navy-50;
  }
  .ql-toolbar.ql-snow {
    background-color: $white;
    border: 1px solid $clr-navy-50;
    border-radius: 3px;
  }
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter {
    stroke: $clr-water;
  }
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill {
    fill: $clr-water;
  }
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover {
    color: $clr-water;
  }
  .ql-container.ql-snow {
    background-color: $white;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    margin-top: 1rem;
  }
  .ql-editor {
    height: 334px;
    overflow-y: scroll;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']:before,
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']:before {
    content: 'Title';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item:before,
  .ql-snow .ql-picker.ql-header .ql-picker-label:before {
    content: 'Body';
  }
  .ql-snow .ql-formats {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 2px;
      height: 30px;
      top: 0px;
      right: -10px;
      background-color: $clr-navy-50;
    }
  }
}
</style>
