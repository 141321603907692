<template>
  <toggle-button
    :value="value"
    :sync="true"
    :color="{ checked: '#0ea895', unchecked: '#d9dfe3' }"
    :width="56"
    :height="31"
    @change="change"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(change) {
      this.$emit('change', change.value)
    },
  },
}
</script>

<style lang="scss" scoped></style>
