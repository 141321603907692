import * as types from './../mutation-types'

// initial state
const state = {
  userState: null,
  planChange: null,
  userExpired: false,
  expiresIn: null,
  extendedTrial: false,
  releaseVersion: null,
  paymentLate: false,
  messagesCount: 0,
  creditCardRequired: false,
  extendTrialToken: null,
  extendTrialMessage: null,
}

// getters
const getters = {
  userState: (state) => state.userState,
  planChange: (state) => state.planChange,
  userExpired: (state) => state.userExpired,
  expiresIn: (state) => state.expiresIn,
  extendedTrial: (state) => state.extendedTrial,
  releaseVersion: (state) => state.releaseVersion,
  paymentLate: (state) => state.paymentLate,
  messagesCount: (state) => state.messagesCount,
  creditCardRequired: (state) => state.creditCardRequired,
  extendTrialToken: (state) => state.extendTrialToken,
  extendTrialMessage: (state) => state.extendTrialMessage,
}

// actions
const actions = {
  setUserState({ commit }, value) {
    commit(types.SET_USER_STATE, value)
  },
  setPlanChange({ commit }, value) {
    commit(types.SET_PLAN_CHANGE, value)
  },
  setUserExpired({ commit, dispatch }, value) {
    commit(types.SET_USER_EXPIRED, value)
    if (value) {
      dispatch('globals/setMealPlannerLocked', true, { root: true })
    }
  },
  setExpiresIn({ commit }, value) {
    commit(types.SET_EXPIRES_IN, value)
  },
  setExtendedTrial({ commit }, value) {
    commit(types.SET_EXTENDED_TRIAL, value)
  },
  setReleaseVersion({ commit, dispatch, state }, value) {
    if (state.releaseVersion != null && state.releaseVersion != value) {
      dispatch('globals/showVersionUpdatedModal', {}, { root: true })
    }
    commit(types.SET_RELEASE_VERSION, value)
  },
  setPaymentLate({ commit }, value) {
    commit(types.SET_PAYMENT_LATE, value)
  },
  setMessagesCount({ commit, dispatch, state }, value) {
    if (value > 0 && state.messagesCount != value) {
      dispatch('user/getMessages', null, { root: true })
    }
    commit(types.SET_MESSAGES_COUNT, value)
  },
  setCreditCardRequired({ commit }, value) {
    commit(types.SET_CREDIT_CARD_REQUIRED, value)
  },
  setExtendTrialToken({ commit }, value) {
    commit(types.SET_EXTEND_TRIAL_TOKEN, value)
  },
  setExtendTrialMessage({ commit }, value) {
    commit(types.SET_EXTEND_TRIAL_MESSAGE, value)
  },
}

// mutations
const mutations = {
  [types.SET_USER_STATE](state, value) {
    state.userState = value
  },
  [types.SET_PLAN_CHANGE](state, value) {
    state.planChange = value
  },
  [types.SET_USER_EXPIRED](state, value) {
    state.userExpired = value
  },
  [types.SET_EXPIRES_IN](state, value) {
    state.expiresIn = value
  },
  [types.SET_EXTENDED_TRIAL](state, value) {
    state.extendedTrial = value
  },
  [types.SET_RELEASE_VERSION](state, value) {
    state.releaseVersion = value
  },
  [types.SET_PAYMENT_LATE](state, value) {
    state.paymentLate = value
  },
  [types.SET_MESSAGES_COUNT](state, value) {
    state.messagesCount = value
  },
  [types.SET_CREDIT_CARD_REQUIRED](state, value) {
    state.creditCardRequired = value
  },
  [types.SET_EXTEND_TRIAL_TOKEN](state, value) {
    state.extendTrialToken = value
  },
  [types.SET_EXTEND_TRIAL_MESSAGE](state, value) {
    state.extendTrialMessage = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
