<template>
  <div class="gifting">
    <div class="container py-5">
      <h1 class="text-center text-uppercase">Claim your gift</h1>
    </div>
  </div>
</template>

<script>
import NotificationsMixin from '@/mixins/NotificationsMixin'
import LoadingMixin from '@/mixins/LoadingMixin'
import apiCheckout from '@/api/checkout'
import ServerError from '@/components/common/Notifications/ServerError.vue'

export default {
  mixins: [LoadingMixin, NotificationsMixin],
  async mounted() {
    const giftId = this.$route.params['id']

    try {
      this.setLoading(true)
      const { uid, token } = await apiCheckout.claimGift({
        gift: {
          id: giftId,
        },
      })

      this.$router.replace(`/password/set/${uid}/${token}`)
    } catch (err) {
      console.error(err)
      this.$router.replace('/login')
      this.errorNotification(ServerError, true, 10)
    } finally {
      this.setLoading(false)
    }
  },
}
</script>

<style lang="scss" scoped>
.gifting {
  background: rgba(216, 216, 216, 0.5)
    url('~@/assets/images/custom-recipe-background.png') no-repeat center;
  background-size: cover;
}

.close-icon {
  position: absolute;
  top: 30px;
  right: 15px;
  @include media-breakpoint-up(md) {
    top: 45px;
    right: 30px;
  }
}
</style>
