<template>
  <RecipeBase id="recipe-add" @close="closeRecipe" :show-close-button="false">
    <div slot="header">
      <RecipeHeaderImageUpload>
        <div class="d-md-none container">
          <RecipeTopSection :isCustom="true">
            <template slot="custom-actions">
              <CustomRecipeActions
                @edit="editRecipe(0)"
                @delete="deleteRecipe()"
              />
            </template>
          </RecipeTopSection>
        </div>
      </RecipeHeaderImageUpload>
    </div>
    <div slot="actions">
      <RecipeActions :recipe="null" />
    </div>
    <div slot="left-content">
      <div class="d-none d-md-flex">
        <RecipeTopSection :isCustom="true">
          <template slot="custom-actions">
            <CustomRecipeActions
              @edit="editRecipe(0)"
              @delete="deleteRecipe()"
            />
          </template>
        </RecipeTopSection>
      </div>
      <RecipeSubtitle> PREPARATION STEPS </RecipeSubtitle>
      <RecipeEditContentContainer>
        I'm the preparation steps. Just click “Edit Recipe” to go to the recipe
        upload template to add your own text and edit me. It’s easy.
      </RecipeEditContentContainer>
    </div>
    <div slot="right-content">
      <RecipeSideCard>
        <RecipeSubtitle> INGREDIENTS </RecipeSubtitle>
        <RecipeEditContentContainer>
          I’m your list of ingredients. Click “Edit Recipe” to go to the recipe
          upload template and add your ingredients.
        </RecipeEditContentContainer>
      </RecipeSideCard>
    </div>
    <div slot="footer">
      <AddCustomRecipe />
    </div>
  </RecipeBase>
</template>

<script>
import { mapGetters } from 'vuex'

import RecipeActions from './RecipeActions'
import RecipeSubtitle from './RecipeSubtitle'
import RecipeEditContentContainer from './RecipeEditContentContainer'
import RecipeBase from './RecipeBase'
import RecipeSideCard from './RecipeSideCard'
import RecipeHeaderImageUpload from './RecipeHeaderImageUpload'
import RouterMixin from '@/mixins/RouterMixin'
import AddCustomRecipe from '@/components/addcustomrecipe/AddCustomRecipe'
import RecipeTopSection from './RecipeTopSection'

export default {
  name: 'TheRecipeEdit',
  components: {
    RecipeActions,
    RecipeSubtitle,
    RecipeEditContentContainer,
    RecipeTopSection,
    RecipeBase,
    RecipeSideCard,
    RecipeHeaderImageUpload,
    AddCustomRecipe,
  },
  mixins: [RouterMixin],
  computed: {
    ...mapGetters({
      previousRoute: 'globals/previousRoute',
    }),
  },
  methods: {
    addPersonalRecipe() {
      this.$store
        .dispatch('AddCustomRecipe/initAddCustomRecipeData')
        .then(() => {
          this.$store.commit('AddCustomRecipe/SET_MODAL_VISIBILITY', true)
        })
    },
    closeRecipe() {
      this.goBack()
    },
    scrollToTop() {
      this.$el.scrollIntoView('recipe-add')
    },
  },
  mounted() {
    this.addPersonalRecipe()
    this.scrollToTop()
  },
}
</script>

<style lang="scss" scoped>
.action-text {
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #747e8b;
  cursor: pointer;
}
.recipe-header-line {
  display: inline-block;
  width: 1px;
  height: 21px;
  opacity: 0.3;
  border: solid 0.5px #616161;
  margin: 0 1rem;
}
.servings-text-section {
  font-size: 0.75rem;
  border-bottom: 2px solid #e5e5e5;
  margin-bottom: 1rem;
}
.recipe-info {
  font-size: 0.75rem;
  color: #757575;
}
.credits {
  color: #757575;
  font-size: 0.75rem;
  margin: 1rem 0;
}
</style>
