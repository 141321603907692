<template>
  <a v-bind="$attrs" v-on="$listeners" class="whitelink">
    <slot />
  </a>
</template>

<script>
export default {
  inheritAttrs: false,
}
</script>

<style lang="scss">
.whitelink {
  color: white;
  text-decoration: underline;
  &:hover {
    color: white;
  }
}
</style>
