<template>
  <div class="row page-header">
    <h1 class="col-10 title">{{ title }}</h1>
    <div class="col-2 actions">
      <slot name="actions-mobile" />
    </div>
    <div class="w-100" />
    <div class="col-xs-12 col-md-9" v-if="showPlans">
      <PlansList />
    </div>
    <div class="col">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import PlansList from '@/components/common/MPPlansList'
export default {
  name: 'PageHeader',
  components: {
    PlansList,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    showPlans: {
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.page-header {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08), 0 1px 0 0 $clr-navy-15;
  padding-top: 58px;
}
.title {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: rem(42);
  line-height: 1;
}
</style>
