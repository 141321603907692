<template>
  <transition name="notification">
    <div
      class="mp-notification-container d-flex align-items-center justify-content-center p-2 p-md-0"
      :class="{ full: full }"
    >
      <div
        class="mp-notification d-flex flex-row flex-md-row p-3 py-md-2 px-md-3 align-items-stretch align-items-md-center"
        :class="`mp-notification--${color} ${title ? '' : 'pl-5 pr-5'}`"
      >
        <img
          v-if="image"
          :src="image"
          alt=""
          class="notification-icon mr-md-3"
        />
        <slot v-if="isHtml"></slot>
        <div v-else class="title mr-1 ml-1 flex-fill">{{ title }}</div>
        <div
          class="flex-grow-1 d-flex flex-column flex-sm-row justify-content-end"
        >
          <span
            class="actions-button action rounded mr-sm-1 mb-1 mb-sm-0 col"
            :class="`action--${color}`"
            v-if="isAction && actionText"
            @click="onActionSelect"
          >
            {{ actionText }}
          </span>
          <span
            class="actions-button close-action ml-sm-1 col"
            v-if="isAction && closeActionText"
            @click="onCloseActionSelect"
            >{{ closeActionText }}</span
          >
          <span v-else class="close-button" @click="closeNotification">
            <img :src="require('@/assets/icons/close-add-recipe.svg')" />
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import NotificationsMixin from '@/mixins/NotificationsMixin'

export default {
  name: 'MPNotificationStore',
  mixins: [NotificationsMixin],
  props: {
    color: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    isHtml: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAction: {
      type: Boolean,
      defalut: false,
    },
    actionText: {
      type: String,
      required: false,
    },
    image: {
      default: '',
    },
    closeActionText: {
      type: String,
      required: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onActionSelect() {
      this.$emit('onActionSelect')
    },
    onCloseActionSelect() {
      this.$emit('onCloseActionSelect')
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-notification-container {
  z-index: 110;
  position: fixed;
  top: 46px;
  left: 0;
  max-width: 600px;
  width: 100%;
  margin-left: 50%;
  margin-right: 50%;
  transform: translate(-50%, 50%);

  @include media-breakpoint-up(md) {
    top: 30px;
  }

  @include media-breakpoint-up(lg) {
    left: 156px;
  }

  @include media-breakpoint-down(sm) {
    max-width: 90%;
    top: 0px;
  }

  &.full {
    left: 0 !important;
  }

  &.full-with-navbar {
    top: 1rem !important;
  }

  &.add-lr-padding > div:first-of-type {
    padding: 1rem 1rem !important;
  }
}

.mp-notification {
  position: relative;
  min-height: 45px;
  border-radius: 0;
  z-index: 2;
  font-weight: 500;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &--light {
    background-color: $light-grey;
  }

  &--dark {
    color: white;
    background-color: $dark-grey;
  }

  &--primary {
    color: white;
    background-color: $primary;
  }

  &--navy {
    color: white;
    background-color: $clr-navy;
  }

  &--red {
    color: white;
    background-color: $error;
  }
}

.title {
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
    text-align: left;
  }

  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.actions-button {
  justify-content: center;
  padding: 10px 0;
  min-width: 40px;
  white-space: nowrap;
}

.action {
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;

  &--navy {
    background-color: #ffffff;
    color: $body-color;
  }

  &--light {
    background-color: #ffffff;
    color: #d4d9e0;
  }

  &--dark {
    background-color: #ffffff;
    color: #414a4d;
  }
}

.close-action {
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 3px;

  @include media-breakpoint-up(md) {
    border-radius: 0px;
    border: none;
  }
}

.close-button {
  cursor: pointer;

  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 2px;
    right: 2px;
  }
}

.notification-icon {
  @include media-breakpoint-down(xs) {
    position: absolute;
    top: 7.5px;
    left: 7.5px;
    max-width: 25px;
  }
}

.notification-enter-active,
.notification-leave-active {
  transition: opacity 0.15s linear;
  opacity: 0.5;
}

.notification-enter,
.notification-leave-to,
.fade-leave-active {
  opacity: 0;
}
</style>
