import SHA1 from 'crypto-js/sha1'
import encHex from 'crypto-js/enc-hex'
import environment from '@/environment'
import { events } from '@/utils/mixpanel.events'
import _ from 'lodash'

// these events are fired from frontend, and should not be sent to mixpanel from frontend
// const MIXPANEL_BACKEND_EVENTS = [events.plan.purchased, 'mp account created']

function trackGaEcommercePayment(subscriptionId, plan) {
  // Be careful to send both event to GA Ecommerce
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce
  gaEcommerceTrack('ecommerce:addTransaction', {
    id: subscriptionId,
    revenue: plan.lifetimeValue,
  })
  gaEcommerceTrack('ecommerce:addItem', {
    id: subscriptionId,
    name: plan.name,
    price: plan.lifetimeValue,
    category: plan.plan,
    quantity: '1',
  })
  gaEcommerceTrack('ecommerce:send')
}

function trackFBPayment(plan, discount, user) {
  var fbTrackData = {
    email: user.email,
    discount: discount.discount,
    plan_name: plan.name,
    billing_amount: plan.lifetimeValue,
  }
  if (plan.type == 1) {
    window.fbq('track', 'Monthly Membership Sign-Up', fbTrackData)
  } else if (plan.type == 4) {
    window.fbq('track', 'Quarterly Membership Sign-Up', fbTrackData)
  } else if (plan.type == 12) {
    window.fbq('track', 'Annual Membership Sign-Up', fbTrackData)
  }
}

// visual website optimizer - track revenue
function trackVWOPayment(plan) {
  if (window._vis_opt_revenue_conversion) {
    var _vis_opt_revenue = plan.lifetimeValue
    window._vis_opt_queue = window._vis_opt_queue || []
    window._vis_opt_queue.push(function () {
      window._vis_opt_revenue_conversion(_vis_opt_revenue)
    })
  }
}

function trackImpactRadiusPayment(subscriptionId, orderId, plan, user) {
  var sha1Email = encHex.stringify(SHA1(user.email))
  var data = {
    orderId: orderId,
    customerId: user.id,
    customerEmail: sha1Email,
    currencyCode: 'USD',
    orderPromoCode: '',
    note: 'Initial Payment',
    items: [
      {
        subTotal: plan.amount,
        category: plan.plan,
        sku: plan.name,
        quantity: 1,
      },
    ],
  }
  window.ire('trackConversion', environment.impactRadiusTrackerId, data)
}

function impactRadiusIdentify(id, sha1_email) {
  window.ire('identify', { customerId: id, customerEmail: sha1_email })
}

function impactRadiusRegister(id, conversionId) {
  window.ire('trackConversion', conversionId, { orderId: id })
}

function gaTrackEvent(event, properties) {
  if (event === events.plan.purchased) {
    window.ga(
      'send',
      'event',
      event,
      'buy',
      properties['plan purchased'],
      parseInt(properties['plan price']),
      properties
    )
  } else {
    window.ga('send', 'event', event, 'view', properties)
  }
}

function gaEcommerceTrack(event, properties) {
  window.ga(event, properties)
}

function gaTrackPage(page) {
  window.ga('send', 'pageview', page)
}

function ga() {
  window.ga(...arguments)
}

function gTagTrack(event, properties) {
  if (!window.gtag && !window.dataLayer) return
  let tagProperties

  if (event === events.dashboard.viewFeatured) {
    window.dataLayer.push({
      event: event,
      plan: properties.plan,
    })
    return
  }

  const GTagKeys = environment.GTagKeys

  if (event === events.register.accountCreated) {
    tagProperties = {
      send_to: GTagKeys.created,
    }
  } else if (event === events.plan.purchased) {
    if (properties['plan purchased'] === 'monthly') {
      tagProperties = {
        send_to: GTagKeys.monthly,
        transaction_id: '',
      }
    } else if (properties['plan purchased'] === 'quarterly') {
      tagProperties = {
        send_to: GTagKeys.quarterly,
        transaction_id: '',
      }
    } else if (properties['plan purchased'] === 'annual') {
      tagProperties = {
        send_to: GTagKeys.annual,
        transaction_id: '',
      }
    }
  }

  if (tagProperties) {
    window.gtag('event', 'conversion', tagProperties)

    window.dataLayer.push({
      event: 'transactionSuccess mealPlanner',
      email: properties['user email'],
      transactionAffiliation: 'Meal Planner',
      transactionTotal: properties['plan amount'],
      transactionProducts: [
        {
          sku: 'meal-planner-' + properties['plan purchased'],
          name: `Meal Planner/${_.startCase(properties['plan purchased'])}`,
          category: `Meal Planner/${_.startCase(properties['plan purchased'])}`,
          price: properties['plan amount'],
          quantity: 1,
        },
      ],
    })
  }
}

function bingTrack(event, properties) {
  window.uetq = window.uetq || []
  var tagProperties
  if (event === events.plan.purchased) {
    if (properties['plan purchased'] === 'monthly') {
      tagProperties = { ec: 'Purchase', ea: 'Signup', el: 'Monthly', ev: '100' }
    } else if (properties['plan purchased'] === 'quarterly') {
      tagProperties = {
        ec: 'Purchase',
        ea: 'Signup',
        el: 'Quarterly',
        ev: '100',
      }
    } else if (properties['plan purchased'] === 'annual') {
      tagProperties = { ec: 'Purchase', ea: 'Signup', el: 'Annual', ev: '100' }
    }
  } else if (event === 'mp account created') {
    tagProperties = { ec: 'Lead', ea: 'Signup', el: 'Trial', ev: '0' }
  }

  if (tagProperties) {
    window.uetq.push(tagProperties)
  }
}

// function mixpanelTrack(event, properties) {
//   if (MIXPANEL_BACKEND_EVENTS.indexOf(event) != -1) {
//     //console.log('Event '+event+' will be tracked by backend.')
//     event = `${event} /fe`
//   }
//   window.mixpanel.track(event, properties)
// }

function setUserProperties(properties) {
  window.mixpanel.people.set(properties)
  window.mixpanel.register(properties)
}

// function initMixpanelUTMParameters(mixpanel) {
//   function getQueryParam(url, param) {
//     // Expects a raw URL
//     // eslint-disable-next-line
//     param = param.replace(/[[]/, '[').replace(/[]]/, ']')
//     var regexS = '[?&]' + param + '=([^&#]*)',
//       regex = new RegExp(regexS),
//       results = regex.exec(url)
//     if (
//       results === null ||
//       (results && typeof results[1] !== 'string' && results[1].length)
//     ) {
//       return ''
//     } else {
//       return decodeURIComponent(results[1]).replace(/\W/gi, ' ')
//     }
//   }

//   function campaignParams() {
//     var mxp_campaign_keywords = 'mxp_utm_source mxp_utm_medium mxp_utm_campaign mxp_utm_content mxp_utm_term fokref'.split(
//         ' '
//       ),
//       kw = '',
//       last_touch_params = {},
//       first_touch_params = {
//         mxp_utm_campaign: 'None',
//         mxp_utm_source: 'None',
//         mxp_utm_medium: 'None',
//         mxp_utm_content: 'None',
//         mxp_utm_term: 'None'
//       },
//       utm_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(
//         ' '
//       )
//     var index
//     for (index = 0; index < mxp_campaign_keywords.length; ++index) {
//       kw = getQueryParam(document.URL, mxp_campaign_keywords[index])
//       if (kw.length) {
//         last_touch_params[mxp_campaign_keywords[index] + ' [last touch]'] = kw
//         first_touch_params[mxp_campaign_keywords[index]] = kw
//       }
//     }
//     for (index = 0; index < utm_keywords.length; ++index) {
//       kw = getQueryParam(document.URL, utm_keywords[index])
//       if (kw.length) {
//         last_touch_params['mxp_' + utm_keywords[index] + ' [last touch]'] = kw
//         first_touch_params['mxp_' + utm_keywords[index]] = kw
//       }
//     }
//     mixpanel.people.set(last_touch_params)
//     mixpanel.people.set_once(first_touch_params)
//     mixpanel.register(last_touch_params)
//   }

//   campaignParams()
// }

// remove mix panel from the system
// function logOut() {
//   window.mixpanel.reset()
// }

function setMixpanelDistinctId(id) {
  window.mixpanel.identify(id)
}

function mapToGAEvents(event, properties) {
  switch (event) {
    case events.register.servingPage.nextClicked:
      gaTrackPage('mp-account/serving-size')
      break
    case events.register.allergiesPage.nextClicked:
      gaTrackPage('mp-account/intolerances')
      break
    case events.register.scheduledMeals.nextClicked:
      gaTrackPage('mp-account/scheduled-meals')
      break
    case events.register.saveMealPlan.onlyMail.nextClicked:
      gaTrackPage('mp-account/email')
      break
    case 'mp account created':
      gaTrackPage('mp-account/confirmation')
      gaTrackPage('account-confirmation')
      ga('set', 'custom_dimension_1', properties['date account created'])
      break
    case 'mp login':
      ga('send', {
        hitType: 'event',
        eventCategory: 'account',
        eventAction: 'login',
        eventLabel: properties['date of last login'],
      })
      break
    case 'mp login full page view':
      gaTrackPage('mp-login-full')
      break
    case 'mp premium page view':
      gaTrackPage('mp-premium')
      break
    case events.plan.purchased:
      gaTrackPage('mp-premium-confirm')
      break
    case 'mp upgrade clicked':
      ga('send', {
        hitType: 'event',
        eventCategory: 'mp-upgrade',
        eventAction: 'click',
        eventLabel: properties.location,
      })
      break
    case events.recipe.view:
      var recipeUrl = properties['recipe name'].replace(/ /g, '-').toLowerCase()
      gaTrackPage('mp-recipe/' + recipeUrl)
      break
    case events.recipe.printed:
      ga('send', {
        hitType: 'event',
        eventCategory: 'mp-recipe',
        eventAction: 'print',
        eventLabel: properties['recipe name'],
      })
      break
    case events.recipe.rated:
      ga('send', {
        hitType: 'event',
        eventCategory: 'mp-recipe',
        eventAction: 'rate',
        eventLabel:
          properties['recipe name'] + ' | ' + properties['recipe rating'],
      })
      break
    case events.recipe.favorited:
      ga('send', {
        hitType: 'event',
        eventCategory: 'mp-recipe',
        eventAction: 'favorite',
        eventLabel: properties['recipe name'],
      })
      break
    case 'mp settings view':
      gaTrackPage('mp-settings')
      break
    case 'mp settings billing view':
      gaTrackPage('mp-settings/billing')
      break
    case 'mp settings change plan clicked':
      gaTrackPage('mp-settings/plan-change')
      break
    case 'mp settings upgrade plan clicked':
      ga('send', {
        hitType: 'event',
        eventCategory: 'mp-upgrade',
        eventAction: 'click',
        eventLabel: 'settings',
      })
      break
    case 'mp settings changed plan clicked':
      gaTrackPage('mp-settings/plan-change')
      break
    case 'mp settings change plan successful':
      gaTrackPage('mp-settings/plan-change-confirm')
      break
    case 'mp settings cancel account clicked':
      gaTrackPage('mp-settings/cancel-account')
      break
    case 'mp settings cancel account confirmed':
      gaTrackPage('mp-settings/cancel-account-confirm')
      break
    case events.dashboard.view:
      gaTrackPage('mp-dashboard')
      break
    case 'mp weekend prep view':
      gaTrackPage('mp-weekend-prep')
      break
    case 'mp grocery list view':
      gaTrackPage('mp-grocery-list')
      break
    case 'mp grocery list printed':
      ga('send', {
        hitType: 'event',
        eventCategory: 'grocery-list',
        eventAction: 'print',
      })
      break
    case 'mp favorites view':
      gaTrackPage('mp-favorite-recipes')
      break
    case 'mp light plan modal shown':
      gaTrackPage('mp-light-plan')
      break
    case 'mp light plan modal opt in':
      gaTrackPage('mp-light-plan-confirm')
      break
    case 'mp light plan cancel account clicked':
      gaTrackPage('mp-light-plan-cancel')
      break
    case 'mp help view':
      gaTrackPage('mp-help')
      break
    case events.support.contact:
      gaTrackPage('mp-contact-support')
      break
    case events.foksqueezepage.view:
      gaTrackPage('mp-squeeze')
      break
  }
}

function tagForGoogleOptimize(...args) {
  window.gtag('event', ...args)
}

export default {
  init() {
    try {
      window.ga('create', environment.GAKey, 'auto')
      window.ga('require', 'ecommerce')
      window.fbq('init', environment.fbqKey)
      // remove mixpanel from the system
      // window.mixpanel.init(environment.mixpanelKey, {
      //   api_host: environment.mixpanelProxy,
      //   loaded: function(mixpanel) {
      //     initMixpanelUTMParameters(mixpanel)
      //   }
      // })
    } catch (error) {
      console.error(error)
    }
  },
  register(id, email, traits, data) {
    try {
      // remove mixpanel from the system
      // window.mixpanel.alias(id)
      // window.mixpanel.people.set(traits)
      window.ga('set', 'userId', id)
      impactRadiusRegister(id, environment.impactRadiusConversionId)
      const sha1Email = encHex.stringify(SHA1(email))
      impactRadiusIdentify(id, sha1Email)

      window.fbq('track', 'Free Trial Account Sign-Up', {
        userId: id,
      })

      if (data.affiliateName != '') {
        window.fbq('trackCustom', 'affiliate_name', {
          affiliate_name: data.affiliateName,
        })
      }

      if (data.discount != 0) {
        window.fbq('trackCustom', 'discount_expires_on', {
          discount_expiry_date: data.discountExpiryDate,
          value: data.discount,
        })
      }

      if (window._vis_opt_goal_conversion) {
        window._vis_opt_queue = window._vis_opt_queue || []
        window._vis_opt_queue.push(function () {
          window._vis_opt_goal_conversion(200)
        })
      }
    } catch (error) {
      console.error(error)
    }
  },
  identify(id, email) {
    try {
      // remove mixpanel from the system
      // window.mixpanel.identify(id)

      window.ga('set', 'userId', id)
      const sha1Email = encHex.stringify(SHA1(email))
      impactRadiusIdentify(id, sha1Email)
    } catch (error) {
      console.error(error)
    }
  },
  track(event, properties) {
    try {
      properties = Object.assign({}, properties)
      gTagTrack(event, properties)
      bingTrack(event, properties)
      gaTrackEvent(event, properties)
      // remove mix panel tracking
      // mixpanelTrack(event, properties)
      mapToGAEvents(event, properties)
    } catch (error) {
      console.error(error)
    }
  },
  trackPayment(subscriptionId, orderId, plan, discount, user) {
    try {
      trackImpactRadiusPayment(subscriptionId, orderId, plan, user)
      trackFBPayment(plan, discount, user)
      trackGaEcommercePayment(subscriptionId, plan)
      trackVWOPayment(plan)
    } catch (error) {
      console.error(error)
    }
  },
  setUserProperties: setUserProperties,
  impactRadiusIdentify: impactRadiusIdentify,
  impactRadiusRegister: impactRadiusRegister,
  gaTrackPage: gaTrackPage,
  setMixpanelDistinctId: setMixpanelDistinctId,
  tagForGoogleOptimize,
  // remove mixpanel from the system
  // logOut: logOut
}
