<template>
  <div id="settings-billing">
    <div class="d-flex flex-column justify-content-center mt-4 mt-md-0">
      <!-- Your Plan -->
      <SettingCard type="rows">
        <template slot="label"> Your Plan </template>
        <template slot="content">
          <span class="the-plan-title"> </span>
          <span class="description"> Free plan </span>
        </template>
        <template slot="actions-content">
          <MPButton class="btn btn-outline-success" @click="handleReactivate">
            Reactivate
          </MPButton>
        </template>
      </SettingCard>
      <!-- Schedule -->
      <SettingCard type="rows-last">
        <template slot="label"> Delete </template>
        <template slot="content">
          <span class="description"> Permanently remove your account. </span>
        </template>
        <template slot="actions-content">
          <MPButton class="delete btn btn-outline-danger" @click="handleDelete">
            Delete
          </MPButton>
        </template>
      </SettingCard>
    </div>
  </div>
</template>

<script>
import SettingCard from '@/components/settings/SettingCard.vue'

export default {
  name: 'SettingsBilling',
  components: {
    SettingCard,
  },
  methods: {
    handleReactivate() {
      this.$emit('on-reactivate')
    },
    handleDelete() {
      this.$emit('on-delete')
    },
  },
}
</script>

<style lang="scss" scoped>
.delete {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 1.125rem;
  line-height: 1.5;
}

.plan-not-editable {
  background-color: $clr-navy-5;
  padding: 1rem;
  text-align: left;
  color: $clr-navy-70;
  font-weight: 500;
}

.the-plan-title {
  font-weight: 500;
  display: block;
}

.card-info-wrapper {
  display: flex;
  align-items: center;
}

.card-info {
  color: $clr-navy-70;
  font-size: 0.875rem;
  padding-left: 0.5rem;
}

.plan-upsell {
  display: block;
  color: $clr-navy-70;
  font-size: 0.875rem;
}

.cancel-plan-title {
  font-size: 20px;
  font-weight: bold;
}

.button-upcase {
  text-transform: uppercase;
}

.action-button {
  padding: 0;
  text-align: right;
  svg {
    width: 28px;
    height: auto;
    vertical-align: bottom;
  }
}
</style>
