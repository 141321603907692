<template>
  <h3 class="recipe-subtitle">
    <slot></slot>
  </h3>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.recipe-subtitle {
  margin: 0 0 13px;
  font-size: 24px;
  line-height: 26px;
}
</style>
