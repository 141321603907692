<template>
  <div class="material-input">
    <input
      :id="id"
      :type="getType()"
      :placeholder="placeholder"
      autocomplete="off"
      :value="value || ''"
      :minlength="minlength"
      @input="$emit('input', $event.target.value)"
      required="required"
      data-required="true"
      @blur="$emit('blur', $event)"
      :class="{ 'is-not-empty': !!value }"
      :data-spec="dataSpec"
    />
    <label>{{ label }}</label>
    <a
      v-if="isPassword"
      class="show-password"
      @click.prevent="togglePassword"
    ></a>
    <span v-if="error" class="input-error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPassword: this.type == 'password',
      originalType: this.type,
      showPassword: false,
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword
    },
    getType() {
      if (this.isPassword) {
        return this.showPassword ? 'text' : 'password'
      }
      return this.type
    },
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    error: {
      type: String,
      default: '',
    },
    minlength: {
      type: Number,
      default: 1,
    },
    value: {},
    dataSpec: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.material-input {
  position: relative;
  input {
    width: 100%;
    height: 50px;
    padding-left: 1rem;
    border: solid 1px $clr-navy-50;
    border-radius: 4px;
    ~ label {
      padding-left: 1rem;
      line-height: 50px;
      display: block;
      font-size: $font-size-small;
      color: $clr-navy-50;
      position: absolute;
      top: 0;
      transform: translate3d(0, 0, 0);
      transition: all 0.2s ease-in-out;
      white-space: nowrap;
      pointer-events: none;
    }
    &:valid,
    &:focus,
    &.is-autofill {
      ~ label {
        opacity: 1;
        position: absolute;
        font-size: 11px;
        color: $body-color;
        cursor: initial;
        display: block;
        line-height: 14px !important;
        transform: translate3d(0px, 3px, 0);
        pointer-events: none;
      }
    }
    &.is-not-empty {
      padding-top: 9px;
      ~ label {
        color: $body-color;
        transform: translate3d(0px, 3px, 0);
        line-height: 14px !important;
        position: absolute;
        font-size: 11px;
      }
    }
  }
  input:focus {
    outline: none;
    box-shadow: none;
    border-color: $clr-water;
    color: $body-color;
  }
  .input-error {
    background-color: $error;
    color: #fff;
    font-size: 12px;
    padding: 2px 6px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 100%;
    display: inline-block;
    top: -3px;
    position: relative;
    border-bottom: 1px solid $danger;
    border-left: 1px solid $danger;
    border-right: 1px solid $danger;
    text-align: left;
  }

  .show-password {
    background: url('~@/assets/icons/settings-show-password.svg') center
      no-repeat;
    background-size: 30px;
    cursor: pointer;
    width: 60px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-bottom: 0;
    outline: none;
  }
}
</style>
