<template>
  <div class="text-center">
    <img
      class="mt-3"
      :src="require('@/assets/icons/delete-user-farewell-illustration.svg')"
    />
    <strong class="d-block mt-3">
      Thank you for trying the meal planner.
    </strong>
    <span class="d-block mt-3">
      If you have any feedback you'd like to share about what we could do
      better, we'd love to hear from you.
    </span>
    <MPButton class="mt-3" @click="close" color="red" size="large">
      Goodbye!
    </MPButton>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped></style>
