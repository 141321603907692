<template>
  <div class="smn">
    <div class="smn-dropdown" @click="showMenu = true">
      <span class="smn-text">
        {{ title }}
      </span>
    </div>
    <MPOverlay :show="showMenu" type="light" @click.native="showMenu = false" />
    <div class="smn-dropdown-menu-wrapper">
      <div class="smn-dropdown-menu" v-if="showMenu">
        <slot :onSelect="onSelect"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    onSelect(key) {
      this.showMenu = false
      this.$emit('onSelect', key)
    },
  },
}
</script>

<style lang="scss" scoped>
.smn-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: $body-color;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  background-color: $white;
  border: 1px solid $clr-navy;
  height: 46px;
  padding: 0 15px;
  text-transform: uppercase;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.smn-dropdown:after {
  display: inline-block;
  background-image: url('~@/assets/icons/chevron-right.svg');
  width: 20px;
  height: 20px;
  content: '';
  margin-left: 0;
  vertical-align: middle;
  transform: rotate(90deg);
  margin-top: -5px;
  .mp-overlay-open & {
    transform: rotate(270deg);
    margin-top: 4px;
    margin-right: 4px;
  }
}

.smn-dropdown-menu-wrapper {
  position: relative;
  z-index: 1;
}

.smn-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
}
</style>
