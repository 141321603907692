<template>
  <div class="password-reset-form">
    <MPAlert
      :text="errorMessage"
      type="error"
      :is-dismissable="true"
      @dismiss="dismissError()"
    ></MPAlert>
    <MPAlert
      :text="successMessage"
      type="success"
      :is-dismissable="true"
      @dismiss="dismissError()"
    ></MPAlert>
    <h2>Forgot your password?</h2>
    <p class="text-center">
      Please enter your email below to reset your password or receive your magic
      login link.
    </p>
    <form @submit.prevent="checkEmail">
      <EmailInput v-model="email" />
      <MPButton type="submit" color="primary" size="full-width">
        Reset password
      </MPButton>
    </form>
  </div>
</template>

<script>
import { MPAlert, MPButton } from '@/components/common'
import EmailInput from '@/components/credentials/EmailInput'
import credentialsHandler from '@/handlers/CredentialsHandler'
import LoadingMixin from '@/mixins/LoadingMixin'

export default {
  components: {
    MPAlert,
    MPButton,
    EmailInput,
  },
  name: 'PasswordResetForm',
  mixins: [LoadingMixin],
  data() {
    return {
      email: '',
      successMessage: '',
      errorMessage: '',
      noSignUp: false,
    }
  },
  methods: {
    checkEmail() {
      this.setLoading(true)
      this.successMessage = ''
      this.errorMessage = ''
      credentialsHandler
        .checkEmailExists(this.email)
        .then((emailExists) => {
          this.setLoading(false)
          if (emailExists) {
            this.setLoading(true)
            credentialsHandler
              .resetPassword(this.email)
              .then(() => {
                this.successMessage = `An email has just been sent with instructions on how to reset your password`
                this.setLoading(false)
              })
              .catch(() => {
                this.errorMessage = `An error occured while trying to reset your password. Please <a href="mailto:mealplanner@forksoverknives.com">contact us</a>.`
                this.setLoading(false)
              })
          } else {
            this.errorMessage = `We could not find an account with this email.
              ${
                this.noSignUp
                  ? ''
                  : ` Please <b-link to="login">sign up</b-link> or <a href="mailto:mealplanner@forksoverknives.com">contact us</a>.`
              }`
            this.setLoading(false)
          }
        })
        .catch(() => {
          this.setLoading(false)
        })
    },
    dismissError() {
      this.errorMessage = ''
      this.successMessage = ''
    },
  },
  mounted() {
    const email = this.$routeQuery.get('email')
    this.noSignUp = this.$routeQuery.get('nosignup') === 'true'
    if (email) {
      this.email = decodeURIComponent(email)
    }
  },
}
</script>

<style lang="scss" scoped>
.password-reset-form {
  h2 {
    text-align: center;
  }
  .material-input {
    margin-bottom: 1rem;
  }
}
.forgot-password {
  font-size: 12px;
  font-weight: 400;
  margin-top: 1rem;
  text-align: center;
}
</style>
