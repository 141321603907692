<template>
  <div
    :class="{ 'rotate-arrow active': isDropDownVisible }"
    @click="showDropDown()"
    @mouseleave="hideDropDown()"
    class="c-serving-size__drop-down"
  >
    <!-- Add custom value after apply -->
    <div class="c-serving-size__drop-down__value">
      <span
        class="c-serving-size__drop-down__value--placeholder"
        v-if="!selected"
        >Choose a Serving Size*</span
      >
      <span class="c-serving-size__drop-down__value--selected-value" v-else>{{
        servingText
      }}</span>
    </div>
    <div
      :class="{ show: isDropDownVisible }"
      class="c-serving-size__drop-down__body"
      @mouseover="showDropDown()"
    >
      <div class="servings">
        <div class="servings__text">Servings</div>
        <div class="d-flex justify-content-between align-items-center mr-1">
          <span
            :class="{ disabled: servingsNumber == 0 }"
            @click="decreaseServingsNumber()"
            class="servings__toggle"
            >-</span
          >
          <span class="servings__number">{{ servingsNumber }}</span>
          <span @click="increaseServingsNumber()" class="servings__toggle"
            >+</span
          >
        </div>
      </div>
      <hr />
      <div class="batch-recipe">
        <span class="batch-recipe__header">Batch Recipe</span>
        <div class="batch-recipe__toggle">
          <input
            id="batch-recipe"
            name="batch-recipe"
            type="checkbox"
            v-model="isBatchRecipe"
            @click="applyServings()"
          />
          <label for="batch-recipe"></label>
        </div>
        <div class="batch-recipe__text">
          <span class="batch-recipe__text--body">
            Only allow this recipe to be made in batches of the serving size set
            above.
          </span>
          <a class="batch-recipe__text--link">Learn more.</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'AddCustomRecipeServingSize',
  data() {
    return {
      isDropDownVisible: false,
      servingsNumber: 0,
      isBatchRecipe: false,
      selected: null,
    }
  },
  props: {
    value: {
      required: true,
    },
  },
  computed: {
    servingText() {
      if (this.selected) {
        return this.selected.servingsNumber > 1
          ? `${this.selected.servingsNumber} servings`
          : `${this.selected.servingsNumber} serving`
      }
      return ''
    },
  },
  watch: {
    value: {
      handler: function () {
        if (this.value) {
          this.selected = this.value
          this.servingsNumber = this.value.servingsNumber
          this.isBatchRecipe = this.value.isBatchRecipe
        }
      },
      immediate: true,
    },
  },
  methods: {
    setDropDownVisibility: _.debounce(function (visibility) {
      this.isDropDownVisible = visibility
    }, 100),
    hideDropDown() {
      this.setDropDownVisibility(false)
    },
    showDropDown() {
      this.setDropDownVisibility(true)
    },
    increaseServingsNumber() {
      this.servingsNumber++
      this.applyServings()
    },
    decreaseServingsNumber() {
      if (this.servingsNumber > 0) {
        this.servingsNumber--
      }
      this.applyServings()
    },
    applyServings: _.debounce(function () {
      if (this.servingsNumber == 0) {
        this.selected = null
      } else {
        this.selected = {
          servingsNumber: this.servingsNumber,
          isBatchRecipe: this.isBatchRecipe,
        }
      }
      this.$emit('input', this.selected)
    }, 100),
  },
}
</script>

<style lang="scss" scoped>
.c-serving-size {
  &__drop-down {
    display: flex;
    align-items: center;
    position: relative;
    background-color: $white;
    padding: 10px 15px;
    height: 50px;
    border: 1px solid $clr-navy-50;
    border-radius: 4px;
    width: 100%;

    @include media-breakpoint-up(md) {
      margin: 0 20px;
    }

    cursor: pointer;
    &::after {
      content: '';
      transition: all 0.1s linear;
      transform: rotate(90deg);
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-left-color: $clr-water;
      border-radius: 2px;
      position: absolute;
      right: 15px;
      top: 21px;
    }
    &.rotate-arrow {
      &::after {
        transform: rotate(270deg);
        top: 15px;
      }
    }
    &.invalid {
      border-color: $pale-red;
      animation: animate-border 5s linear 0s infinite alternate;
    }
    &.active {
      border-color: $clr-water;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &__drop-down__value {
    font-size: 1rem;
    letter-spacing: 0.2px;
    position: relative;
  }
  &__drop-down__body {
    cursor: default;
    z-index: 1;
    transition: opacity 0.1s linear;
    display: flex;
    opacity: 0;
    flex-flow: column;
    position: absolute;
    left: -1px;
    top: -1px;
    transform: translateY(49px);
    background-color: $white;
    width: calc(100% + 2px);
    height: 0;
    overflow: hidden;
    border: none;
    padding: 0 10px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    font-size: 12px;
    box-shadow: 0 2px 20px 0 rgba(0, 41, 64, 0.1);

    &.show {
      opacity: 1;
      height: auto;
      padding-bottom: 10px;
      border: 1px solid $clr-water;
      border-top-color: $white;
    }

    .header__text {
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      text-align: center;
      color: $text-gray;
      margin-top: 7px;
      margin-bottom: 1rem;
    }
    .servings {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 13px 0px 13px 5px;
    }
    .servings__text {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.2px;
    }
    .servings__number {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.2px;
      width: 50px;
      text-align: center;
    }
    .servings__toggle {
      display: inline-block;
      width: 24px;
      height: 24px;
      background-color: $clr-water;
      border-radius: 50%;
      color: $white;
      text-align: center;
      line-height: 24px;
      font-size: 14px;
      cursor: pointer;
      user-select: none;
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    .batch-recipe {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 15px 0px 4px 5px;
      flex-wrap: wrap;
      align-items: center;
    }
    .batch-recipe__header {
      font-size: rem(16);
    }
    .batch-recipe__text {
      margin: 5px 15px 0 0;
      font-size: rem(14);
      margin-top: 12px;
    }
    .batch-recipe__text--body {
      color: $text-muted;
      width: 100%;
    }
    .batch-recipe__text--link {
      color: $clr-water;
      font-weight: 500;
    }
    .batch-recipe__toggle {
      min-width: 56px;
      height: 30px;
      input {
        display: none;
        &:checked {
          ~ label {
            background-color: $clr-water;
            &::before {
              left: 50%;
            }
          }
        }
      }
      label {
        margin: 0;
        width: 100%;
        height: 30px;
        background-color: $light-grey;
        border-radius: 15px;
        position: relative;
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          left: 2px;
          top: 2px;
          width: 26px;
          height: 26px;
          background-color: $white;
          border-radius: 50%;
          transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
    }
    hr {
      border-top: 1px solid $clr-navy-15;
      margin: 0;
      width: calc(100% + 20px);
      margin-left: -10px;
    }
  }
}
</style>
