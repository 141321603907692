<template>
  <div>
    <RecipeBase id="recipe" @close="closeRecipe">
      <div slot="header">
        <div class="header-banner">
          <span class="d-md-none">
            Preview Only.
            <router-link to="/reactivate">
              Join the planner for full access
            </router-link>
          </span>
          <!-- <span class="d-none d-md-inline">
            This recipe is only available to members.
            <router-link to="/premium">Upgrade today</router-link> for full
            access and over {{ promotedRecipesNumber }} recipes.
          </span> -->
          <span class="d-none d-md-inline">
            This recipe is only available to members.
            <router-link to="/reactivate">Upgrade today</router-link> for full
            access and over 1450 recipes.
          </span>
        </div>
        <RecipeHeader :image="recipe.image">
          <div class="d-md-none container">
            <RecipeTopSection :isLocked="true" />
          </div>
        </RecipeHeader>
      </div>
      <div slot="left-content">
        <div class="d-none d-md-flex">
          <RecipeTopSection :isLocked="true" />
        </div>
        <div class="mb-5 d-md-none">
          <div
            class="filler filler--content"
            v-for="num in [0, 1, 2, 3]"
            :key="num"
          ></div>
        </div>
        <MPButton
          class="unlock-btn"
          size="full-width"
          color="primary"
          @click="goToPremium()"
        >
          JOIN TO VIEW THE FULL RECIPE
        </MPButton>
        <div class="mb-5 d-none d-md-block" v-for="num in [0, 1, 2]" :key="num">
          <div
            class="filler filler--content"
            v-for="num in [0, 1, 2, 3]"
            :key="num"
          ></div>
        </div>
      </div>
      <div slot="right-content" class="d-none d-md-block">
        <RecipeSideCard>
          <div class="filler"></div>
          <div
            class="filler filler--subtitle"
            v-for="num in [0, 1, 2, 3, 4, 5]"
            :key="num"
          ></div>
        </RecipeSideCard>
      </div>
    </RecipeBase>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RecipeHeader from './RecipeHeader'
import RecipeTopSection from './RecipeTopSection'

import RecipeBase from './RecipeBase'
import RecipeSideCard from './RecipeSideCard'
import RouterMixin from '@/mixins/RouterMixin'

export default {
  components: {
    RecipeHeader,
    RecipeTopSection,
    RecipeBase,
    RecipeSideCard,
  },
  computed: {
    ...mapGetters({
      recipe: 'recipe/recipe',
      promotedRecipesNumber: 'globals/promotedRecipesNumber',
    }),
  },
  mixins: [RouterMixin],
  methods: {
    closeRecipe() {
      this.goBack()
    },
    goToPremium() {
      let eventProperties = {}
      eventProperties.location = window.location.href
      this.$analytics.track('mp upgrade clicked', eventProperties)
      this.$router.push('/reactivate')
    },
  },
}
</script>

<style lang="scss" scoped>
#recipe ::v-deep .header-close {
  top: 50px;
}

.filler {
  height: 1rem;
  background-color: rgba(229, 229, 229, 0.5);
  vertical-align: middle;
  margin-bottom: 1rem;
  &--content:last-child {
    width: 50%;
  }
  &--subtitle:nth-child(even) {
    width: 60%;
  }
}

.header-banner {
  text-align: center;
  background-color: $clr-navy;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  color: white;
  padding: 10px 15px;
}

.header-banner a {
  color: white;
  text-decoration: underline;
}
.unlock-btn {
  margin-top: 30px;
  margin-bottom: 30px;
}
.recipe-right-content .filler:nth-child(1) {
  height: 1.5rem;
  width: 75%;
  margin-bottom: 2rem;
}
</style>
