<template>
  <div class="slider-controls-container">
    <button
      class="slider-button slider-button--prev"
      aria-label="Previous slide"
      :disabled="activeSlide === 1"
      @click="$emit('prev-slide')"
    >
      <ChevronRight />
    </button>
    <span class="slide-numbers">
      <span class="slide-number slide-number--current">{{ activeSlide }}</span>
      <span class="slide-number text-muted">&nbsp;/ {{ slidesCount }}</span>
    </span>
    <button
      class="slider-button slider-button--next"
      aria-label="Next slide"
      :disabled="activeSlide === slidesCount"
      @click="$emit('next-slide')"
    >
      <ChevronRight />
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ChevronRight } from '@/components/common/MPIcons'

export default {
  name: 'SwiperNav',
  components: {
    ChevronRight,
  },
  computed: {
    ...mapGetters({
      creditCardRequired: 'headers/creditCardRequired',
    }),
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  props: {
    activeSlide: {
      type: Number,
      default: 1,
    },
    slidesCount: {
      type: Number,
      default: 1,
    },
  },
}
</script>

<style lang="scss" scoped>
.slider-button {
  $button-size: 39px;
  width: $button-size;
  height: $button-size;
  border-radius: 50%;
  background-color: $white;
  padding: 0;
  color: $body-color;
  border: 1px solid #b7c3c9;
  transition: all 0.2s linear;
  margin-bottom: 0;
  &:disabled {
    color: #b7c3c9;
    background-color: transparent;
  }
  &--prev {
    svg {
      transform: rotate(180deg);
    }
  }
}
.slide-numbers {
  margin: 0 20px;
}

.slide-number--current {
  font-weight: bold;
}
</style>
