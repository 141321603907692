import { render, staticRenderFns } from "./DashboardPlanEmptyItem.vue?vue&type=template&id=2db35d76&scoped=true"
import script from "./DashboardPlanEmptyItem.vue?vue&type=script&lang=js"
export * from "./DashboardPlanEmptyItem.vue?vue&type=script&lang=js"
import style0 from "./DashboardPlanEmptyItem.vue?vue&type=style&index=0&id=2db35d76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db35d76",
  null
  
)

export default component.exports