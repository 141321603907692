<template>
  <div
    class="card"
    :class="{
      'card--add-recipe': !!recipe.addRecipeStatus,
    }"
    @click.stop="onClick(recipe.url, $event)"
  >
    <div class="top-container">
      <div
        class="image-content"
        :class="{ 'image-content--lock': recipe.isLocked }"
        :style="{ 'background-image': `url(${recipe.thumb})` }"
      ></div>
      <span class="badge-new" v-if="showNewBatch"> NEW </span>
      <span
        class="card-badge card-badge--favorite"
        v-if="!recipe.isLocked"
        :class="{
          'card-badge--red-heart': recipe.isFavorite,
          'card-badge--gray-heart': !recipe.isFavorite,
        }"
        @click.stop="onFavoriteToggleClick"
      ></span>
      <span class="lock-badge card-badge--lock" v-if="recipe.isLocked"></span>
      <span class="user-recipe-icon" v-if="recipe.isCustom"></span>
      <span class="badge-bonus" v-if="recipe.isBonusRecipe"></span>
    </div>
    <div class="card-body">
      <div class="card-top mb-md-2">
        <div class="title-container">
          <span class="category d-none d-md-block">
            {{ recipe.category }}
          </span>
          <span class="title">
            {{ recipe.title }}
          </span>
          <span class="sub-title text-truncate" v-if="recipe.subtitle">
            {{ recipe.subtitle }}
          </span>
        </div>
        <span
          class="card-badge card-badge--info d-md-none"
          :class="{
            'card-badge--red-heart': recipe.isFavorite,
            'card-badge--gray-heart': !recipe.isFxavorite,
            'card-badge--disable': recipe.isLocked,
          }"
          @click.stop="onFavoriteToggleClick"
        ></span>
      </div>

      <div class="info">
        <div class="info-left">
          <div class="info-rating-wrapper">
            <span class="star" v-if="recipe.rating && !recipe.isLocked" />
            <span class="info-rating" v-if="recipe.rating && !recipe.isLocked">
              <!-- {{ recipe.rating.toFixed(1) }} -->
              {{ recipe.generalRating }}
            </span>
            <span
              class="info-rating-count"
              v-if="recipe.rating && !recipe.isLocked"
            >
              ({{ recipe.ratingCount }})
            </span>
          </div>
          <span class="category text-truncate mr-2 d-md-none">
            {{ recipe.category }}
          </span>
          <div class="info-right">
            <span class="clock" />
            <span class="prepare-time text-truncate">
              {{ recipe.prepTime }}
            </span>
            <span class="prepare-time prepare-time--small text-truncate">
              {{ recipe.prepTimeSmall }}
            </span>
            <span
              class="pressure-cooker-icon"
              v-if="recipe.pressureCooker"
            ></span>
          </div>
        </div>
        <RecipeActionButton
          :status="recipe.addRecipeStatus"
          @addRecipe="addRecipe"
          @upgrade="upgrade"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RecipeActionButton from './RecipeActionButton.vue'
import _ from 'lodash'
import { EVENTS } from '@/events/analytics'
import { mapGetters } from 'vuex' // TODO: A/B test

export default {
  components: { RecipeActionButton },
  data() {
    return {
      updateFavoriteCancel: () => {},
    }
  },
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    onClick(recipeUrl, event) {
      if (event.target.classList.contains('card-badge')) return
      this.$emit('onCardClick', recipeUrl)
    },
    onFavoriteToggleClick() {
      if (this.recipe.manualToggleFavorite) {
        this.$emit('onFavoriteToggleClick', this.recipe.id)
      } else if (!this.recipe.isLocked) {
        this.$store.commit('RecipeBox/UPDATE_LOCAL_RECIPES', this.recipe.id)
        this.updateFavoriteCancel()
        this.updateFavorite(this.recipe)
      }
    },
    addRecipe() {
      if (this.recipe.isFavorite) {
        this.$intercom.trackEvent('FAVORITE_ADDED')
      }
      this.$emit('addRecipe', this.recipe.id)
    },
    upgrade() {
      this.$emit('upgrade')
    },
  },
  computed: {
    ...mapGetters({
      isActive: 'user/isActive', // TODO: A/B test
    }),
    recipe() {
      return this.data
    },
    showNewBatch() {
      const recipe = this.recipe
      return recipe.isNew && !recipe.isBonusRecipe && !recipe.isCustom
    },
  },
  beforeCreate() {
    this.updateFavorite = _.debounce(function (recipe) {
      this.$store.dispatch('RecipeBox/toggleRecipeFavorite', {
        recipeId: recipe.id,
        cancelExecutor: (c) => (this.updateFavoriteCancel = c),
      })
      const payload = {
        recipe: recipe.title,
        'mark favorite': !recipe.isFavorite,
      }
      this.$posthog.sendEvent(EVENTS.recipe.favorite, payload)
    }, 1000)
  },
}
</script>

<style lang="scss" scoped>
.user-recipe-icon {
  position: absolute;
  top: rem(10);
  left: rem(10);
  display: block;
  width: 30px;
  height: 30px;
  background: $white url('~@/assets/icons/filtering/bookmark.svg') no-repeat
    center;
  border-radius: 3px;
}
.card {
  cursor: pointer;
  flex-direction: row;
  padding: rem(20) 0;
  flex-wrap: wrap;
  @include media-breakpoint-up(md) {
    transition: box-shadow 0.2s;
    transition-delay: 0.1s;
    flex-direction: column;
    margin-top: rem(20);
    padding: 0;
    border-radius: 0;
    border: 1px solid $clr-navy-5;
    height: calc(100% - 19px);
    &:hover {
      box-shadow: 0 15px 50px 0 rgba(145, 152, 160, 0.5);
    }
  }
}

.card--add-recipe {
  @include media-breakpoint-up(md) {
    margin-bottom: 3rem;
  }
}

.top-container {
  position: relative;
  overflow: hidden; // Background image
  width: 100px;
  height: 75px;
  background-color: #f5f6f7;
  @include media-breakpoint-up(md) {
    width: 100%;
    height: 136px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @include media-breakpoint-up(lg) {
    height: 144px;
  }
}

.image-content {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.image-content--lock {
  filter: brightness(60%);
}

.card-badge,
.lock-badge {
  // lock-badge because v-on:click.stop on card-badge will stop opening of recipe
  display: inline-block;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center center;
}

.card-badge--info {
  border: 1px solid #e5e6e7;
  margin-left: 5px;
}
.card-badge--edit {
  position: absolute;
  right: 15px;
  top: 55px;
  display: none;
  box-shadow: 0 15px 50px 0 rgba(145, 152, 160, 0.2);
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      @include media-breakpoint-up(md) {
        background-size: 115%;
      }
    }
  }
}
.card-badge--favorite {
  position: absolute;
  right: rem(10);
  top: rem(10);
  display: none;
  box-shadow: 0 15px 50px 0 rgba(145, 152, 160, 0.2);
  @include media-breakpoint-up(md) {
    display: block;
    &:hover {
      @include media-breakpoint-up(md) {
        background-size: 115%;
      }
    }
  }
}

.card-badge--gray-heart {
  background-image: url('~@/assets/icons/on-image-not-added.svg');
}

.card-badge--red-heart {
  background-image: url('~@/assets/icons/added.svg');
}

.card-badge--lock {
  background-image: url('~@/assets/icons/recipe-card-locked.svg');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include media-breakpoint-up(md) {
    position: absolute;
    right: 1rem;
    top: 1rem;
    transform: none;
    left: auto;
  }
}

.card-badge--disable {
  cursor: default;
}

.badge-new {
  position: absolute;
  font-weight: 500;
  display: inline-block;
  line-height: 1.5rem;
  padding: 0 rem(13);
  color: $white;
  display: none;
  @include media-breakpoint-up(md) {
    background-color: $clr-navy;
    font-size: rem(12);
    font-weight: 700;
    padding: 0 0.75rem;
    top: rem(10);
    left: rem(10);
    display: flex;
  }
}

.badge-bonus {
  position: absolute;
  font-weight: 500;
  top: 0.3rem;
  left: 0.3rem;
  display: inline-block;
  line-height: 1.5rem;
  padding: 0 0.5rem;
  color: white;
  font-size: 0.625rem;
  border-radius: 3px;
  background: #009b9b url('~@/assets/icons/fork.svg') no-repeat center;
  width: 32px;
  height: 32px;
  display: none;
  @include media-breakpoint-up(md) {
    font-size: 0.875rem;
    padding: 0 0.75rem;
    top: 1rem;
    left: 1rem;
    line-height: 32px;
  }
}

.card-body {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1rem;
  flex: 1;
  overflow: hidden; // truncate title on mobile
  width: 100%;
  @include media-breakpoint-up(md) {
    padding: rem(10);
    overflow: visible; // add button
    flex: 1 1 auto;
  }
}

.card-top {
  display: flex;
  flex: 1 1 auto;
}

.title-container {
  height: auto;
  overflow: hidden;
  display: flex; // truncate title on mobile
  flex-direction: column;
  flex: 1;
}

.title {
  flex: 1 0 auto;
  font-size: 1rem;
  line-height: rem(22);
  display: block;

  @include media-breakpoint-up(md) {
    font-size: rem(18);
    letter-spacing: 0.56px;
    flex-grow: 0;
    line-height: rem(24);
  }
}

.sub-title {
  flex: 1 0 auto;
  font-size: 1rem;
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    font-size: rem(14);
    color: $text-muted;
  }
}

.star {
  width: 13px + 4px;
  height: 13px;
  // pre load all images
  background-image: url('~@/assets/icons/ratin-star.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 13px auto, 0, 0, 0;
  cursor: pointer;
  margin-left: rem(8);
}

.clock {
  width: 13px + 4px;
  height: 13px;
  // pre load all images
  background-image: url('~@/assets/icons/clock.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 13px auto, 0, 0, 0;
  cursor: pointer;
}

.info {
  font-size: rem(12);
  color: $text-muted;

  @include media-breakpoint-up(md) {
    font-size: rem(14);
  }
}

.info-left {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  flex: 1 1 auto;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    margin-top: 2rem;
  }
}

.info-rating-wrapper {
  display: flex;
  align-items: center;
  order: 3;
  height: 17px;
  flex: 1;
}

.info-rating {
  line-height: 1rem;
  margin-left: rem(4);
}

.info-rating-count {
  color: $clr-navy-70;
  margin-left: rem(4);
  line-height: 1rem;
}

.category {
  width: 100%;
  order: 1;
  font-size: rem(13);
  color: $body-color;
  margin-bottom: 3px;

  @include media-breakpoint-up(md) {
    order: 0;
    font-size: rem(14);
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1rem;
    margin-bottom: 3px;
  }
}

.info-right {
  display: flex;
  order: 2;
  @include media-breakpoint-up(md) {
    flex: none;
  }
}

.prepare-time {
  font-size: rem(12);
  color: $clr-navy-70;
  line-height: 1rem;
  margin-left: rem(4);

  @include media-breakpoint-up(md) {
    font-size: rem(14);
  }

  @media (max-width: 321px) {
    display: none;
  }

  &--small {
    display: flex;
    @media (min-width: 321px) {
      display: none;
    }
  }
}

.pressure-cooker-icon {
  display: inline-block;
  background: url('~@/assets/icons/pressure-cooker-icon.svg') no-repeat center;
  width: 14px;
  height: 13px;
  margin-left: rem(4);
  @include media-breakpoint-up(md) {
    margin-left: 6px;
  }
}
</style>
