<template>
  <div class="preferences">
    <MPModal
      class="changes-plan-modal"
      :show.sync="showApplyChangesToLatestPlan"
      :showX="true"
      :show-loading="isRestartInProgress"
    >
      <h3 class="d-block d-md-none changes-plan-modal-mobile-title">
        Reset Plan
      </h3>
      <div class="text-center">
        <Warning class="warning-icon" width="46" />
        <h3 class="changes-plan-modal-title">Apply Changes To Latest Plan?</h3>
        <p>
          This will reset the meal plan <b>({{ dashboardLatest.title }})</b> and
          you will lose any modifications you have made.
        </p>
      </div>
      <template slot="dismiss-action">
        <MPButton
          class="modal-button dismiss-button btn btn-grey-outline d-none d-md-block"
          @click="showApplyChangesToLatestPlan = false"
        >
          NO, THANK YOU
        </MPButton>
      </template>
      <template slot="confirm-action">
        <MPButton
          class="modal-button confirm-button btn btn-danger"
          @click="restartDashboardLatest"
        >
          APPLY & RESET LATEST PLAN
        </MPButton>
      </template>
    </MPModal>
    <div
      v-if="!isPreferencesLoaded"
      class="d-flex justify-content-center mt-4 mt-md-0"
    >
      <MPSpinner :withOverlay="false"></MPSpinner>
    </div>
    <div v-if="isPreferencesLoaded">
      <SettingCard type="rows" :isFullWidth="true">
        <template slot="label">
          <span class="item-label"> Servings </span>
        </template>
        <template slot="content">
          <div class="servings-selector-container">
            <MPServingsSelector
              :servings="fullServings"
              @increased="increaseFullServings"
              @decreased="decreaseFullServings"
              type="adult"
              text="Full portions"
            />
            <MPServingsSelector
              :servings="halfServings"
              @increased="increaseHalfServings"
              @decreased="decreaseHalfServings"
              type="child"
              text="½ portions"
            />
          </div>
          <MPAlert
            class="mt-3 notification small"
            v-if="wasPortionsChanged"
            type="outline"
          >
            <span class="green" v-if="canAccessNonSampleMealPlans">
              Changes will be applied to your next plan starting
              {{ nextPlanStartDateTitle }}.
              <br />
            </span>
            <span class="grey">
              {{ alertPlanResetText }}
            </span>
            <a href @click.prevent="showApplyChangesToLatestPlan = true">
              Click here
            </a>
          </MPAlert>
          <div class="info">
            <span>
              Did you know you can change serving sizes for individual recipes?
              <a
                target="_blank"
                href="https://support.forksmealplanner.com/article/zZ1GNW8exD-how-do-i-change-the-number-of-servings-for-a-recipe"
              >
                See our FAQ.
              </a>
            </span>
          </div>
        </template>
      </SettingCard>
      <SettingCard type="rows" :isFullWidth="true">
        <template slot="label">
          <span class="item-label"> Intolerances </span>
        </template>
        <template slot="content">
          <div class="intolerances">
            <MPSelectBox
              class="select-box px-2 py-4"
              v-for="i in intolerances"
              :key="i.key"
              :title="i.title"
              :isSelected="i.isSelected"
              :image="i.image"
              @click.native="selectIntolerance(i.key)"
            ></MPSelectBox>
          </div>
          <MPAlert
            class="mt-3 notification small"
            v-if="wasIntoleranceChanged"
            type="outline"
          >
            <span class="green" v-if="canAccessNonSampleMealPlans">
              Changes will be applied to your next plan starting
              {{ nextPlanStartDateTitle }}.
              <br />
            </span>
            <span class="grey">
              {{ alertPlanResetText }}
            </span>
            <a href @click.prevent="showApplyChangesToLatestPlan = true">
              Click here
            </a>
          </MPAlert>
          <div class="info">
            <span>
              Is a recipe in your plan not suitable for your dietary needs this
              week? <a href @click.prevent="$intercom.show()">Let us know</a>.
            </span>
          </div>
        </template>
      </SettingCard>
      <SettingCard type="rows" :isFullWidth="true">
        <template slot="label">
          <span class="item-label"> Scheduled Meals </span>
        </template>
        <template slot="content">
          <div class="scheduled-meals">
            <MPSelectBox
              class="select-box"
              v-for="meal in scheduledMeals"
              :key="meal.key"
              :title="meal.title"
              :isSelected="meal.isSelected"
              @click.native="toggleScheduledMeal(meal.key)"
              :image="meal.image"
            ></MPSelectBox>
          </div>
          <MPAlert
            class="mt-3 notification small"
            v-if="wasScheduledMealsChanged"
            type="outline"
          >
            <span class="green" v-if="canAccessNonSampleMealPlans">
              Changes will be applied to your next plan starting
              {{ nextPlanStartDateTitle }}.
              <br />
            </span>
            <span class="grey">
              {{ alertPlanResetText }}
            </span>
            <a href @click.prevent="showApplyChangesToLatestPlan = true">
              Click here
            </a>
          </MPAlert>
        </template>
      </SettingCard>
      <SettingCard type="rows" :isFullWidth="true">
        <template slot="label">
          <span class="item-label"> Do you have a Pressure Cooker </span>
        </template>
        <template slot="content">
          <div class="d-flex flex-column flex-md-row pressure">
            <MPSelectBox
              class="select-box"
              title="No, I don't"
              :isSelected="!preferences.usePressureCooker"
              @click.native="toggleUsePressureCooker('usePressureCooker')"
              :image="require('@/assets/icons/pressure-cooker-no.svg')"
            ></MPSelectBox>
            <MPSelectBox
              class="select-box"
              title="Yes, I do"
              :isSelected="preferences.usePressureCooker"
              @click.native="toggleUsePressureCooker('usePressureCooker')"
              :image="require('@/assets/icons/pressure-cooker-yes.svg')"
            ></MPSelectBox>
          </div>
          <MPAlert
            class="mt-3 notification small"
            v-if="wasUsePressureCookerChanged"
            type="outline"
          >
            <span class="green" v-if="canAccessNonSampleMealPlans">
              Changes will be applied to your next plan starting
              {{ nextPlanStartDateTitle }}.
              <br />
            </span>
            <span class="grey">
              {{ alertPlanResetText }}
            </span>
            <a href @click.prevent="showApplyChangesToLatestPlan = true">
              Click here
            </a>
          </MPAlert>
        </template>
      </SettingCard>
      <SettingCard type="rows" v-if="permissions.canAccessSpeedPrep">
        <template slot="label">
          <span class="item-label"> Speed Prep </span>
        </template>
        <template slot="content">
          Toggle the ‘Speed Prep’ guide on or off, that helps you prepare less
          perishable items in advance, in Weekend Prep.
        </template>
        <template slot="actions-content">
          <MPSwitch
            class="m-0"
            v-model="isSpeedPrepEnabled"
            @change="toogleIsSpeedPrepEnabled"
          />
        </template>
      </SettingCard>
      <SettingCard type="rows">
        <template slot="label">
          <span class="item-label"> Show Nutritional Information </span>
        </template>
        <template slot="content">
          The nutritional information we provide is approximate. If you choose
          to integrate Forks Meal Planner with an external nutrition tracker,
          Forks Meal Planner does not guarantee the accuracy, relevance,
          timeliness, or completeness of any information on these external
          websites.
        </template>
        <template slot="actions-content">
          <MPSwitch
            class="m-0"
            v-model="useNutritionalTracking"
            @change="toggleNutritionalTracking"
          />
        </template>
      </SettingCard>
      <SettingCard type="rows">
        <template slot="label">
          <span class="item-label"> Unit of measurement </span>
        </template>
        <template slot="content">
          <div class="row no-gutters amazon-fresh">
            <div class="col-6 col-md-3">
              <MPRadio
                label="Imperial"
                class="radio-btn"
                :isSelected="!useMetricUnits"
                @click="toggleMetricUnits(false)"
              />
            </div>
            <div class="col-6 col-md-9">
              <MPRadio
                label="Metric"
                class="radio-btn"
                :isSelected="useMetricUnits"
                @click="toggleMetricUnits(true)"
              />
            </div>
          </div>
        </template>
      </SettingCard>
      <SettingCard type="rows" v-if="useMetricUnits">
        <template slot="label">
          <span class="item-label"> Temperature Units </span>
        </template>
        <template slot="content">
          <div class="row no-gutters amazon-fresh">
            <div class="col-6 col-md-3">
              <MPRadio
                label="Celsius"
                class="radio-btn"
                :isSelected="useTemperatureCelsius"
                @click="toggleTemperatureCelsius(true)"
              />
            </div>
            <div class="col-6 col-md-9">
              <MPRadio
                label="Fahrenheit"
                class="radio-btn"
                :isSelected="!useTemperatureCelsius"
                @click="toggleTemperatureCelsius(false)"
              />
            </div>
          </div>
        </template>
      </SettingCard>
      <SettingCard type="rows-last" :isFullWidth="true">
        <template slot="label">
          <span class="item-label"> Printing Options </span>
        </template>
        <template slot="content">
          <div class="printing-options">
            <div
              class="printing-option"
              v-for="option in getPrintingOptions(printingOptions)"
              :key="option.key"
              @click="togglePrintingOption(option.key)"
            >
              <MPSelect :isSelected="option.isSelected" />
              <span class="printing-option-label">
                {{ option.label }}
              </span>
            </div>
          </div>
        </template>
      </SettingCard>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingCard from './SettingCard'
import _ from 'lodash'
import { MPServingsSelector } from '@/components/common/'
import { Warning } from '@/components/common/MPIcons'
import { events } from '@/utils/mixpanel.events'

export default {
  components: {
    SettingCard,
    MPServingsSelector,
    Warning,
  },
  data() {
    return {
      isRestartInProgress: false,
      portionsAlertText: null,
      intolerancesAlertText: null,
      scheduledMealsAlertText: null,
      showApplyChangesToLatestPlan: false,
    }
  },
  computed: {
    ...mapGetters({
      fullServings: 'user/fullServings',
      halfServings: 'user/halfServings',
      preferences: 'user/preferences',
      intolerances: 'user/intolerances',
      scheduledMeals: 'user/scheduledMeals',
      numberOfScheduledMeals: 'user/numberOfScheduledMeals',
      printingOptions: 'user/printingOptions',
      wasPortionsChanged: 'user/wasPortionsChanged',
      wasIntoleranceChanged: 'user/wasIntoleranceChanged',
      wasScheduledMealsChanged: 'user/wasScheduledMealsChanged',
      wasUsePressureCookerChanged: 'user/wasUsePressureCookerChanged',
      mealPlanSelectedItem: 'dashboard/mealPlanSelectedItem',
      nextPlanStartDateTitle: 'user/nextPlanStartDateTitle',
      dashboardLatest: 'user/dashboardLatest',
      useNutritionalTracking: 'user/useNutritionalTracking',
      isSpeedPrepEnabled: 'user/isSpeedPrepEnabled',
      useMetricUnits: 'user/useMetricUnits',
      useTemperatureCelsius: 'user/useTemperatureCelsius',
      isPreferencesLoaded: 'user/isPreferencesLoaded',
      permissions: 'user/permissions',
      isFreeUser: 'user/isFree',
    }),
    mealPlanSelectedName() {
      return this.mealPlanSelectedItem && this.mealPlanSelectedItem.label
    },
    canAccessNonSampleMealPlans() {
      return this.permissions.canAccessNonSampleMealPlans
    },
    alertPlanResetText() {
      const planName = this.permissions.canAccessNonSampleMealPlans
        ? 'latest plan'
        : this.dashboardLatest.title
      return `Want to apply these changes to your ${planName} now?`
    },
  },
  methods: {
    getPrintingOptions(options) {
      if (!this.useNutritionalTracking) {
        const nutrition = options.find(
          (option) => option.key === 'printRecipeNutrition'
        )

        if (nutrition.isSelected) {
          this.togglePrintingOption(nutrition.key)
        }

        return options.filter((option) => option.key !== nutrition.key)
      }

      return options
    },
    toggleNutritionalTracking(value) {
      this.$analytics.track(events.nutrition.switch, {
        action: value ? 'ON' : 'OFF',
      })
      this.$store.dispatch('user/setUseNutritionalTracking', value)
    },
    toogleIsSpeedPrepEnabled(value) {
      this.$store.dispatch('user/toggleSpeedPrepVisibility', value)
    },
    toggleMetricUnits(useMetricUnits) {
      this.$analytics.track(
        useMetricUnits ? 'mp metric enabled' : 'mp metric disabled'
      )
      this.$store.dispatch('user/setUseMetricUnits', useMetricUnits)
    },
    toggleTemperatureCelsius(useTemperatureCelsius) {
      this.$analytics.track(
        useTemperatureCelsius
          ? 'mp temperature celsius enabled'
          : 'mp temperature celsius disabled'
      )
      this.$store.dispatch(
        'user/setUseTemperatureCelsius',
        useTemperatureCelsius
      )
    },
    decreaseFullServings() {
      this.$store.dispatch('user/decreaseFullServings')
    },
    increaseFullServings() {
      this.$store.dispatch('user/increaseFullServings')
    },
    decreaseHalfServings() {
      this.$store.dispatch('user/decreaseHalfServings')
    },
    increaseHalfServings() {
      this.$store.dispatch('user/increaseHalfServings')
    },
    selectIntolerance(key) {
      this.$store.dispatch('user/toggleIntolerance', key)
    },
    toggleUsePressureCooker(key) {
      const payload = {
        eventName: events.settings.pressureCooking,
        params: {
          enabled: !this.preferences.usePressureCooker,
        },
      }
      this.$store.dispatch('analytics/track', payload)
      this.$store.dispatch('user/toggleUsePressureCooker', key)
    },
    toggleScheduledMeal(key) {
      const meal = _.filter(this.scheduledMeals, (meal) => meal.key == key)[0]
      if (this.numberOfScheduledMeals == 1 && meal.isSelected) {
        return
      }
      this.$store.dispatch('user/toggleScheduledMeal', key)
      const intercomEvent = meal.getIntercomEvent()
      if (intercomEvent) {
        this.$intercom.trackEvent(intercomEvent)
      }
      this.$analytics.track('mp settings schedule changed', {
        breakfast: this.preferences.breakfastOptIn,
        lunch: this.preferences.lunchOptIn,
        dinner: this.preferences.dinnerOptIn,
        snack: this.preferences.snackOptIn,
        dessert: this.preferences.snackOptIn, // not a typo, snack & dessert are both tracked under snackOptin for now
      })
    },
    togglePrintingOption(key) {
      this.$store.dispatch('user/togglePrintingOption', key)
    },
    restartDashboardLatest() {
      this.isRestartInProgress = true

      this.$store
        .dispatch('user/applyPreferencesAndResetDashboard')
        .then(() => {
          this.showApplyChangesToLatestPlan = false
          this.isRestartInProgress = false
          if (this.canAccessNonSampleMealPlans) {
            this.$router.push('/plan/next')
          } else {
            this.$store
              .dispatch('dashboard/switchToSampleMealPlan', null, {
                root: true,
              })
              .then(() => {
                this.$router.push('/plan')
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
// changes-plan-modal
.changes-plan-modal {
  @include media-breakpoint-up(md) {
    .modal-dialog {
      max-width: 600px;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
    }

    .modal-body {
      width: 600px;
      height: auto;
    }

    .mp-modal-content {
      padding: 3rem 4rem;
    }
  }

  @include media-breakpoint-down(sm) {
    .mp-modal-content {
      padding: 15px;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .warning-icon {
      margin-bottom: 20px;
    }

    .reset-modal-content {
      height: 100vh;
    }
  }
}
</style>

<style lang="scss" scoped>
.grey {
  color: $clr-navy-70;
}

.item-label {
  display: inline-block;
}

.mp-servings-selector {
  margin-right: 15px;
  ::v-deep .text-sm {
    font-size: 1rem;
  }
}

.select-box ::v-deep {
  padding: 24px 8px;

  .title {
    @include media-breakpoint-only(md) {
      font-size: rem(14);
    }
  }
}

.pressure ::v-deep {
  .select-box {
    min-height: rem(108);
    &:first-child .image-container {
      width: rem(90);
    }

    &:last-child {
      padding-left: rem(25);
    }
  }
}

.portions {
  display: flex;
}

.info {
  display: flex;
  color: $clr-navy-70;
  font-size: rem(14);
  margin-top: 15px;
}

.intolerances {
  display: flex;
  flex-direction: column;
  ::v-deep .select-box:first-child {
    img {
      height: 55px;
    }
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.scheduled-meals {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.scheduled-meal-image-container {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.printing-option {
  margin-bottom: -15px;
}

.printing-option {
  display: inline-flex;
  align-items: center;
  height: 30px;
  min-width: 50%;
  margin-bottom: 15px;
  @include media-breakpoint-up(md) {
    min-width: 140px;
  }
}

.printing-option-label {
  display: inline-block;
  margin-left: 10px;
  font-size: 1rem;
  font-weight: normal;
}

.changes-plan-modal-title {
  display: block;
  margin: 20px 0;
  font-size: rem(38);
  line-height: rem(42);
  @include media-breakpoint-down(sm) {
    font-size: rem(20);
    line-height: rem(26);
    margin-top: -3px;
    text-align: center;
  }
}

.changes-plan-modal-mobile-title {
  font-size: rem(20);
  line-height: rem(26);
  margin-top: -3px;
  text-align: center;
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
}

.changes-plan-subtitle {
  margin-bottom: 0.5rem;
}

.servings-selector-container {
  display: flex;
  position: relative;
  text-align: center;
}

.radio-btn {
  color: $body-color;
  font-size: 1rem;
  ::v-deep span {
    margin-left: 2.2rem;
  }
}
</style>
