<template>
  <div class="connect-with-fb" @click="$emit('click', $event)">
    <img :src="require('@/assets/icons/f.svg')" class="fb-icon" />
    <span class="fb-text">
      {{ buttonText }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    isConnected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonText() {
      return this.isConnected ? 'Connected' : 'Connect to Facebook'
    },
  },
}
</script>

<style lang="scss" scoped>
.connect-with-fb {
  display: inline-flex;
  align-items: center;
  height: 50px;
  background: $white;
  border-radius: 0;
  cursor: pointer;
  text-transform: none;
  position: relative;
  border: solid 1px #39579a;
  text-transform: uppercase;

  &:hover {
    border: solid 1px $link-hover-color;
  }
}

.fb-icon {
  width: 26px;
  margin-left: 45px;
}

.fb-text {
  padding: 0 45px 0 15px;
}
</style>
