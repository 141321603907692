<template>
  <div id="add-custom-recipe-ingredients">
    <section class="add-recipe-ingredients">
      <AddCustomRecipeIngredientForm
        :tab-scope="tabScope"
        @add="addRecipeIngredient"
      />
    </section>
    <section class="recipe-ingredients-container">
      <header class="recipe-ingredient-header">Recipe Ingredients</header>
      <main class="recipe-ingredient-main" ref="recipeIngredients">
        <Draggable v-model="recipeIngredientList" v-if="hasIngredients">
          <AddCustomRecipeIngredientItemB
            v-for="(recipeIngredient, index) in recipeIngredientList"
            :key="index"
            :recipe-ingredient="recipeIngredient"
            @edit="showRecipeIngredientEditModal"
            @delete="deleteRecipeIngredient"
          />
        </Draggable>
        <div
          v-else
          class="d-flex justify-content-center align-content-center h-100"
        >
          <div class="align-self-center empty-state">
            Add your ingredients above
          </div>
        </div>
      </main>
    </section>
    <AddCustomRecipeIngredientEditModal
      :show.sync="isEditModalVisible"
      :recipe-ingredient="selectedRecipeIngredient"
      @edit="editRecipeIngredient"
    />
    <small class="disclaimer">* Required Field</small>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AddCustomRecipeIngredientForm from './AddCustomRecipeIngredientForm'
import AddCustomRecipeIngredientItemB from './AddCustomRecipeIngredientItemB'
import AddCustomRecipeIngredientEditModal from './AddCustomRecipeIngredientEditModal'
import Draggable from 'vuedraggable'

export default {
  name: 'AddCustomRecipeIngredients',
  components: {
    Draggable,
    AddCustomRecipeIngredientForm,
    AddCustomRecipeIngredientItemB,
    AddCustomRecipeIngredientEditModal,
  },
  props: {
    tabScope: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedRecipeIngredient: null,
      isEditModalVisible: false,
    }
  },
  computed: {
    recipeIngredientList: {
      get() {
        return this.$store.state.AddCustomRecipe.recipeIngredientList
      },
      set(value) {
        this.$store.commit('AddCustomRecipe/SET_RECIPE_INGREDIENT_LIST', value)
      },
    },
    hasIngredients() {
      return this.recipeIngredientList && this.recipeIngredientList.length > 0
    },
  },
  methods: {
    ...mapActions('AddCustomRecipe', [
      'addRecipeIngredientToArray',
      'editRecipeIngredientInArray',
      'removeRecipeIngredientFromArray',
    ]),
    showRecipeIngredientEditModal(recipeIngredient) {
      this.selectedRecipeIngredient = recipeIngredient
      this.isEditModalVisible = true
    },
    addRecipeIngredient(recipeIngredient) {
      this.addRecipeIngredientToArray(recipeIngredient)
      setTimeout(() => {
        this.$refs.recipeIngredients.scrollTop =
          this.$refs.recipeIngredients.scrollHeight
      }, 10)
    },
    editRecipeIngredient(recipeIngredient) {
      this.editRecipeIngredientInArray(recipeIngredient)
    },
    deleteRecipeIngredient(recipeIngredient) {
      this.removeRecipeIngredientFromArray(recipeIngredient)
    },
  },
}
</script>

<style lang="scss" scoped>
#add-custom-recipe-ingredients {
  padding: 0 30px 10px;
  .cursor--pointer {
    cursor: pointer;
  }

  @include media-breakpoint-down(md) {
    padding: 0 10px 10px 10px;
  }
}
.error-message {
  min-height: 1rem !important;
}
.empty-state {
  color: $clr-navy-50;
  font-size: 0.875rem;
}
.recipe-ingredients-container {
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid $clr-navy-50;
}
.recipe-ingredient-header {
  padding: 1rem;
  font-size: rem(18);
  font-weight: 700;
  text-transform: uppercase;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.recipe-ingredient-main {
  background-color: $white;
  height: 311px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.add-more-ingredients {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: $text-gray;
  margin-left: 1.6rem;
  margin-right: 2.9rem;
  cursor: pointer;
  border: dashed 2px #d5d6d7;
  padding: 10px;

  &__icon {
    color: $white;
    display: flex;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 28px;
    border-radius: 50%;
    background-color: #d8d8d8;
    background-image: url('~@/assets/icons/add-recipe-ingredients.svg');
    background-position: center, center;
    background-repeat: no-repeat;
    background-size: 15px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    transition: background-color 0.1s linear;
  }
  &:hover {
    .add-more-ingredients__icon {
      background-color: $grey;
    }
  }
}
.disclaimer {
  font-size: rem(12);
  color: $text-muted;
  margin-top: 15px;
  display: block;
}
</style>
<style lang="scss">
#add-custom-recipe-ingredients {
  .alert-warning {
    color: $white;
    text-align: center;
    font-size: 0.75rem;
    background-color: $red;
  }
}
</style>
