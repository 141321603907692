<template>
  <div class="loading-section d-flex flex-column p-4">
    <div class="loader-image">
      <img
        :src="require('@/assets/images/loadingpot@2x.png')"
        class="pot-image"
        alt=""
      />
      <img
        :src="require('@/assets/images/loadingcircle@2x.png')"
        class="circle-image"
        alt=""
      />
    </div>
    <p v-if="text" class="loading-text text-center">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'MPWhiskLoadingAnimation',
  props: {
    text: {
      type: String,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-section {
  @include media-breakpoint-up(md) {
    height: 80vh;
  }

  @include media-breakpoint-down(sm) {
    height: 60vh;
  }
}
.loader-image {
  align-self: center;
  position: relative;
  @include media-breakpoint-up(md) {
    width: 400px;
    width: 400px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
.pot-image {
  position: absolute;
  top: 23px;
  left: 50%;
  width: 100px;
  margin-left: -50px;
}
.circle-image {
  position: absolute;
  top: 0;
  left: 50%;
  width: 150px;
  margin-left: -75px;
  animation: rotating 2s linear infinite;
}
.loading-text {
  color: #e5e5e5;
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 2.5rem;
  padding: 11px 0 21px;
  text-align: center;
}
</style>
