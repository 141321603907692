<template>
  <div class="recipe-toggle-disabled">
    <a v-on:click="onClick">
      {{ data.text }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    onClick() {
      this.$store.commit('SET_SHOW_PLAN_DISABLED', !this.data.isShow)
    },
  },
}
</script>

<style lang="scss" scoped>
.recipe-toggle-disabled {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    margin-top: 0rem;
    margin-bottom: 3rem;
  }
}
</style>
