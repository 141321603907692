<template>
  <bModal
    size="lg"
    hide-footer
    hide-header
    centered
    class="dashboard-confirmation-modal"
    v-model="showLocal"
  >
    <span class="title">
      {{ title }}
    </span>
    <span class="description">
      {{ description }}
    </span>
    <div class="buttons-group">
      <button class="modal-button dismiss-button" @click="onDismiss">
        Cancel
      </button>
      <button class="btn btn-danger confirm-button" @click="onConfirm">
        {{ confirmButtonText }}
      </button>
    </div>
  </bModal>
</template>

<script>
export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    confirmButtonText: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      showLocal: false,
    }
  },
  methods: {
    onDismiss() {
      this.showLocal = false
    },
    onConfirm() {
      this.$emit('onConfirm')
    },
  },
  watch: {
    showLocal() {
      this.$emit('update:show', this.showLocal)
    },
    show() {
      this.showLocal = this.show
    },
  },
  mounted() {
    this.showLocal = this.show
  },
}
</script>

<style lang="scss" scoped>
.title {
  display: block;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  color: #404852;
  margin-bottom: 13px;

  @include media-breakpoint-down(sm) {
    margin-top: 20px;
  }
}

.description {
  display: block;
  text-align: center;
  margin-bottom: 1.25rem;
  padding: 0.5rem 2rem;
}

.buttons-group {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(186, 186, 186, 0.5);
}

.modal-button {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.6px;
  margin: 0;
  padding: 0.75rem 1.5rem;
  text-align: center;
  + .modal-button {
    margin-left: 15px;
  }
}

.dismiss-button {
  background-color: #ffffff;
  color: $body-color;
  border: none;
}

.confirm-button {
  margin: 0;
}
</style>

<style lang="scss">
.dashboard-confirmation-modal {
  .modal-dialog {
    height: 100%;
    @include media-breakpoint-down(sm) {
      margin: 0;
      min-width: 100%;
    }
    @include media-breakpoint-up(md) {
      height: auto;
    }
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
    @include media-breakpoint-up(md) {
      height: auto;
      border-radius: 3px;
    }
  }

  .modal-body {
    padding-bottom: 0;
  }
}

.modal-open {
  overflow: hidden !important;
}
</style>
