<template>
  <div>
    <div v-if="!isLoaded" class="d-flex justify-content-center mt-4 mt-md-0">
      <MPSpinner :withOverlay="false"></MPSpinner>
    </div>
    <div v-if="isLoaded">
      <SettingCard type="rows">
        <template slot="label"> Name </template>
        <template slot="content">
          <span
            class="value"
            v-if="!isEditName && name && name.length > 0"
            data-spec="name"
          >
            {{ name }}
          </span>
          <span
            v-if="!isEditName && (!name || name.length == 0)"
            class="value missing-name"
          >
            Enter your name
          </span>
          <MPInput
            type="text"
            placeholder="Name"
            v-model="formName"
            v-if="isEditName"
            data-spec="input-name"
          />
        </template>
        <template slot="actions-content">
          <MPButton
            class="action-button btn btn-link"
            @click="editName"
            v-if="!isEditName"
            data-spec="edit-name"
          >
            <Edit /> {{ name && name.length > 0 ? 'Edit' : 'Add' }}
          </MPButton>
          <MPButton
            color="primary"
            class="confirm-button"
            @click="saveName"
            v-if="isEditName"
            data-spec="save-button"
          >
            Save
          </MPButton>
        </template>
      </SettingCard>

      <SettingCard type="rows">
        <template slot="label"> Email </template>
        <template slot="content">
          <span class="value" v-if="!isEditEmail">
            {{ email }}
          </span>
          <MPInput
            type="text"
            placeholder="Name"
            v-model="formEmail"
            v-if="isEditEmail"
          />
        </template>
        <template slot="actions-content">
          <MPButton
            class="action-button btn btn-link"
            @click="editEmail"
            v-if="!isEditEmail"
          >
            <Edit /> Edit
          </MPButton>
          <MPButton
            color="primary"
            class="confirm-button"
            @click="saveEmail"
            v-if="isEditEmail"
          >
            Save
          </MPButton>
        </template>
      </SettingCard>

      <SettingCard type="rows">
        <template slot="label"> Password </template>
        <template slot="content">
          <span> •••••••••••• </span>
          <CreatePasswordModal :show.sync="showCreatePasswordModal" />
          <ChangePasswordModal :show.sync="showChangePasswordModal" />
        </template>
        <template slot="actions-content">
          <MPButton
            class="action-button btn btn-link"
            @click="showPasswordModal()"
          >
            <Edit /> Edit
          </MPButton>
        </template>
      </SettingCard>

      <SettingCard type="rows">
        <template slot="label"> Photo </template>
        <template slot="content">
          <div class="user-photo">
            <form ref="userPhotoSelectForm">
              <input
                class="user-photo-select"
                ref="userPhotoSelect"
                type="file"
                file-select=""
                accept="image/*"
                required=""
                @change="onUserPhotoSelected"
                data-spec="input-photo"
              />
            </form>
            <div
              class="add-photo"
              :class="{ 'add-photo--no-photo': !doesUserPhotoExist }"
              :style="addPhotoStyle"
              @click="openUserPhotoSelect"
            ></div>
            <span class="user-photo-text">
              Adding a photo helps keep our community personal and friendly.<br />
              <b>Tap on the image</b> to change it!
            </span>
          </div>
        </template>
        <template slot="actions-content">
          <div v-if="userPhotoFile">
            <MPButton
              color="primary"
              @click="saveSelectedUserPhoto"
              data-spec="save-photo"
            >
              Save
            </MPButton>
            <div class="user-photo-remove">
              <span>or </span
              ><a href @click.prevent="removeUserPhotoSelected">remove photo</a>
            </div>
          </div>
          <div v-else>
            <div v-if="photo" class="user-photo-remove">
              <span>or </span
              ><a href @click.prevent="removeUserPhotoSelected">remove photo</a>
            </div>
          </div>
        </template>
      </SettingCard>

      <SettingCard type="rows-lg-last sc--rows" :isFullWidth="true">
        <template slot="label"> Facebook </template>
        <template slot="content">
          <ConnectWithFB @click="connectWithFB" :isConnected="isFBConnected" />
        </template>
      </SettingCard>

      <SettingCard class="d-none" type="rows">
        <template slot="label"> Share </template>
        <template slot="content">
          <span>Refer friends and earn!</span>
        </template>
        <template slot="actions-content">
          <MPButton color="primary" @click="showShareDialog"> Share </MPButton>
        </template>
      </SettingCard>

      <SettingCard
        class="d-lg-none"
        type="rows sc--rows-md-last"
        :isEqualWidth="true"
      >
        <template slot="label"> Log Out </template>
        <template slot="actions-content">
          <MPButton color="primary" @click="logout"> Log out </MPButton>
        </template>
      </SettingCard>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingCard from './SettingCard'
import ConnectWithFB from './ConnectWithFB'
import ChangePasswordModal from './ChangePasswordModal'
import CreatePasswordModal from './CreatePasswordModal'
import { resetState } from '@/store'
import credentialsHandler from '@/handlers/CredentialsHandler'
import { Edit } from '@/components/common/MPIcons'

export default {
  components: {
    SettingCard,
    ConnectWithFB,
    ChangePasswordModal,
    CreatePasswordModal,
    Edit,
  },
  data() {
    return {
      userPhotoFile: null,
      // Email
      formEmail: null,
      isEditEmail: false,
      // Name
      isEditName: false,
      formName: null,
      // Password
      showCreatePasswordModal: false,
      showChangePasswordModal: false,
    }
  },
  computed: {
    ...mapGetters({
      name: 'user/name',
      email: 'user/email',
      photo: 'user/photo',
      hasPassword: 'user/hasPassword',
      isFBConnected: 'user/isFBConnected',
      isLoaded: 'user/isLoaded',
    }),
    addPhotoStyle() {
      if (this.userPhotoFile) {
        const photoUrl = URL.createObjectURL(this.userPhotoFile)
        return { 'background-image': `url(${photoUrl})` }
      } else if (this.photo) {
        return { 'background-image': `url(${encodeURI(this.photo)})` }
      } else return null
    },
    doesUserPhotoExist() {
      return !!(this.userPhotoFile || this.photo)
    },
  },
  methods: {
    editName() {
      this.isEditName = true
      this.formName = this.name
    },
    saveName() {
      this.$store.dispatch('user/changeUserName', this.formName)
      this.isEditName = false
      this.formName = null
    },
    editEmail() {
      this.isEditEmail = true
      this.formEmail = this.email
    },
    saveEmail() {
      this.$store.dispatch('user/changeUserEmail', this.formEmail)
      this.isEditEmail = false
      this.formEmail = null
    },
    onUserPhotoSelected(e) {
      this.userPhotoFile = e.target.files[0]
    },
    openUserPhotoSelect() {
      this.$refs.userPhotoSelect.click()
    },
    removeUserPhotoSelected() {
      this.$refs.userPhotoSelect.value = null
      this.userPhotoFile = null
      this.$store.dispatch('user/removeUserPhoto')
    },
    saveSelectedUserPhoto() {
      this.$store
        .dispatch('user/saveUserPhoto', this.userPhotoFile)
        .then(() => {
          setTimeout(() => {
            // remove the local copy after the remote photo is loaded
            // from uploaded URL, to prevent flicker
            this.$refs.userPhotoSelect.value = null
            this.userPhotoFile = null
          }, 500)
        })
    },
    showPasswordModal() {
      if (this.hasPassword) {
        this.showChangePasswordModal = true
      } else {
        this.showCreatePasswordModal = true
      }
    },
    connectWithFB() {
      if (!this.isFBConnected) {
        this.$store
          .dispatch('user/connectFacebookID')
          .then(() => this.$intercom.trackEvent('FACEBOOK_CONNECT'))
      } else {
        this.disconnectFB()
      }
    },
    disconnectFB() {
      this.$store.dispatch('user/deleteFacebookID')
    },
    logout() {
      resetState()
      this.$router.replace('/login')
    },
  },
  mounted() {
    credentialsHandler.getFbSdk()
  },
}
</script>

<style lang="scss" scoped>
.user-photo {
  display: flex;
}

.add-photo {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  border: 1px solid $clr-navy-15;
  background-color: $clr-navy-15;
  background-size: cover;
  position: relative;
  cursor: pointer;
  &--no-photo:before {
    content: '+';
    color: $white;
    text-align: center;
    width: 100%;
    line-height: 50px;
    display: inline-block;
    font-size: 1.875rem;
  }
}

.user-photo-remove {
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
}

.user-photo-text {
  padding-left: 15px;
  font-size: rem(14);
}

.user-photo-select {
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
}

.missing-name {
  color: $clr-navy-70;
}

.value {
  font-size: rem(18);
}

.action-button {
  padding: 0;
  text-align: right;
  svg {
    width: 28px;
    height: auto;
    vertical-align: bottom;
  }
}

@include media-breakpoint-down(sm) {
  .actions-content {
    .confirm-button {
      max-width: 100px;
      margin-left: 15px;
    }
  }
}
</style>
