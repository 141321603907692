<template>
  <b-modal
    id="confirmation-modal"
    v-model="showModal"
    :hide-footer="true"
    :hide-header="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    centered
  >
    <slot></slot>
  </b-modal>
</template>

<script>
export default {
  name: 'MPConfirmModal',
  props: {
    show: {
      required: true,
    },
  },
  computed: {
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
  },
}
</script>

<style lang="scss">
#confirmation-modal {
  color: $dark-grey;
  .modal-dialog {
    max-width: 696px;
  }
  .modal-content {
    background-color: $white;
    min-height: 216px;
  }
  .modal-body {
    padding: 0;
    margin: 0;
    display: flex;
    flex-flow: column;
    align-content: center;
    justify-content: flex-end;
  }
  .btn {
    font-size: 1rem;
    font-weight: 500;
  }
  p {
    font-size: 1rem;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
  }
  b {
    font-size: 1.25rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
  hr {
    margin-top: 31px;
    margin-bottom: 18px;
    box-shadow: 0 0 4px 0 rgba(186, 186, 186, 0.5);
  }
}
</style>
