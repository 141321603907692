<template>
  <div class="premium-features">
    <div class="premium-features-heading">Premium Membership Includes:</div>
    <div class="premium-feature" v-for="(feature, i) in features" :key="i">
      <i class="premium-feature-icon"></i>
      <span>
        {{ feature }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      promotedRecipesNumber: 'globals/promotedRecipesNumber',
    }),
    features() {
      return [
        'New chef-designed meal plans weekly',
        `${this.promotedRecipesNumber}+ exclusive family-friendly recipes`,
        'VIP Community Group Membership',
        'Unlimited custom plans to build your own!',
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
$tick-width: 24px;
$tick-margin-right: 11px;

.premium-features-heading {
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: 0.56px;
  font-size: 18px;
  text-transform: uppercase;
  @include media-breakpoint-up(md) {
    margin-left: $tick-width + $tick-margin-right;
  }
}

.premium-feature {
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin-top: 16px;
}

.premium-feature-icon {
  width: $tick-width;
  height: $tick-width;
  background-image: url('~@/assets/icons/tick.svg');
  margin-right: $tick-margin-right;
}
</style>
