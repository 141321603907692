<template>
  <div class="recipe-actions">
    <div class="actions w-50" v-if="!recipe"></div>
    <ul class="actions" v-else>
      <li class="action action--time">
        <Clock class="recipe-action-icon icon-clock mr-2" width="16" />
        {{ time }}
      </li>
      <li class="action ml-2">
        <ActionServings
          :recipe="recipe"
          :adjustServings="adjustServings"
          @save="applyServingsChanges"
        />
      </li>
      <li
        v-if="recipe.isCustom"
        class="action ml-sm-3 ml-auto"
        @click="$emit('delete')"
      >
        <Bin class="action-pointer" />
      </li>
      <li
        class="action ml-3"
        :class="{ 'ml-sm-3 ml-auto': !recipe.isCustom }"
        @click="downloadRecipe"
      >
        <Print v-if="!downloadRecipeStatus" class="action-pointer" />
        <MPSpinner
          class="print-spinner"
          v-if="downloadRecipeStatus"
          :with-overlay="false"
          size="small"
          color="primary"
        />
      </li>
      <li
        class="action ml-3"
        v-bind:class="{ disabled: !recipe.nutritionalInfo.hasInfo }"
        v-if="!recipe.isCustom && !useNutritionalTracking"
      >
        <a
          @click="
            () =>
              !recipe.nutritionalInfo.hasInfo
                ? null
                : openNutritionalInfoModal()
          "
        >
          <Info class="recipe-action-icon icon-nutrition" width="25" />
        </a>
      </li>
      <li class="action ml-3">
        <a @click="trackMacros">
          <TrackMacros />
        </a>
      </li>
      <!-- <slot name="custom-actions" /> -->
    </ul>
    <ExportToMyfitnesspalModal :show.sync="showExportModal" />
    <NutritionalInformationModal
      :show.sync="showNutritionalInfo"
      :data="recipe.nutritionalInfo"
      :suggestedServing="recipe.suggestedServing"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoadingMixin from '@/mixins/LoadingMixin'
import { printFile } from '@/services/web/PrintService'
import { EventBus } from '@/events/EventBus.js'
import {
  Clock,
  Print,
  Info,
  TrackMacros,
  Bin,
} from '@/components/common/MPIcons'
import NutritionalInformationModal from './NutritionalInformationModal'
import ExportToMyfitnesspalModal from './ExporToMyfitnesspalModal'
import ActionServings from './actions/ActionServings'
import { events } from '@/utils/mixpanel.events'

export default {
  mixins: [LoadingMixin],
  components: {
    Clock,
    Print,
    Info,
    Bin,
    TrackMacros,
    NutritionalInformationModal,
    ExportToMyfitnesspalModal,
    ActionServings,
  },
  props: {
    recipe: {
      type: Object,
    },
  },
  data() {
    return {
      downloadRecipeStatus: null,
      showExportModal: false,
      showNutritionalInfo: false,
    }
  },
  computed: {
    ...mapGetters({
      adjustServings: 'recipe/adjustServings',
      useNutritionalTracking: 'user/useNutritionalTracking',
      isFreeUser: 'user/isFree',
    }),
    time() {
      return `${this.recipe.time || ''} min`
    },
  },
  methods: {
    openNutritionalInfoModal() {
      this.showNutritionalInfo = true
    },
    async trackMacros() {
      await this.$store.dispatch('recipe/createTokenPublicRecipe')
      this.showExportModal = true
    },
    applyServingsChanges(localServings) {
      this.$intercom.trackEvent('CHANGED_SERVING')
      this.$analytics.track('mp recipe changed servings')
      this.changeServingsStatus = 'loading'
      this.setLoading(true)
      this.$store
        .dispatch('recipe/applyServingsChangesFromForm', [...localServings])
        .then(() => {
          EventBus.$emit('recipeServingsChanged')
          EventBus.$emit('planModified')
          this.setLoading(false)
          this.$root.$emit('bv::hide::popover')
        })
    },
    downloadRecipe() {
      this.downloadRecipeStatus = 'loading'
      const payload = {
        eventName: events.recipe.printed,
        params: {
          recipe_id: this.recipe.id,
          recipe_rating: this.recipe.rating,
        },
      }
      this.$store.dispatch('analytics/track', payload)
      this.$store
        .dispatch('recipe/getPrintFile')
        .then(({ blob, fileUrl, fileName }) => {
          printFile(blob, fileUrl, fileName)
          this.downloadRecipeStatus = null
        })
        .catch(() => {
          this.downloadRecipeStatus = null
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.export-list {
  list-style: none;

  li {
    border-bottom: 1px solid #d9dfe3;
    cursor: pointer;
    padding-left: rem(24);

    &:last-child {
      border-bottom: none;
    }

    .export-type {
      width: rem(24);
      height: rem(24);
      position: absolute;
      left: rem(10);
      top: rem(13);

      &.myfitnesspal {
        background-image: url('~@/assets/images/imgFitnessPal.png');
      }
    }
  }
}

.recipe-actions {
  display: inline-block;
  display: flex;
  border-top: 1px solid $clr-navy-15;
  margin-top: 15px;
  margin-bottom: 1rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 2rem;
  }
}

.actions {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex: 1;
  @include media-breakpoint-up(md) {
    height: 60px;
    flex: none;
  }
  @include media-breakpoint-down(sm) {
    justify-content: flex-start;
  }
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: $link-color;

  &.disabled,
  &.disabled:hover {
    color: #d9dfe3;
  }

  &:hover {
    color: $link-hover-color;
  }
}

.action--time {
  color: $body-color;
}

.action.action-custom {
  margin-left: auto;
}

.icon-clock {
  color: $clr-navy-70;
}

.icon-nutrition {
  margin-top: -2px;
}

.nutritional-info-icon {
  display: flex;
  background-image: url('~@/assets/icons/nutritional-info.svg'),
    url('~@/assets/icons/recipe-nutritional-info-hover.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto, 0;
  cursor: pointer;
  width: 22px;
  height: 40px;
  &:hover {
    background-image: url('~@/assets/icons/recipe-nutritional-info-hover.svg');
  }
  @include media-breakpoint-up(md) {
    height: 60px;
  }
}

.nutritional-info-popover {
  padding: 1rem;
}

.nutritional-note {
  margin-bottom: 0;
}

.action-pointer {
  cursor: pointer;
}

.print-spinner {
  width: 22px;
  height: 24px;
}

.white-link {
  color: $white;
  text-decoration: underline;
  cursor: pointer;
}

.delete-recipe {
  color: $error;
  &:hover {
    color: $error-hover;
  }
}
</style>
