import Vue from 'vue'
import * as types from '../mutation-types'
import camelizeKeys from '../../api/camelizeKeys'
import snakeCaseKeys from '../../api/snakeCaseKeys'
import apiCredentials from '../../api/credential'
import apiSettings from '../../api/settings'
import apiDashboard from '../../api/dashboard'
import OnboardingApi from '../../api/OnboardingApi'
import { EventBus } from '@/events/EventBus.js'
import { resetState } from '@/store'
import credentialsHandler from '@/handlers/CredentialsHandler'
import apiCheckout from '@/api/checkout'

import _ from 'lodash'
import { getCurrentDayTZ } from '@/utils/dates'

// initial state
const state = {
  accessToken: null,
  showCampaignBanner: false,
  permissions: {},
  userData: {},
  userDataInitPromise: null,
  intercomUser: {
    // this is a list of attributes that we currently have, they need to be added manually so leave them commented
    /*
    user_id: null,
    name: null,
    email: null,
    user_hash: null,
    created_at: null,
    soy_intolerance: null,
    gluten_intolerance: null,
    nut_intolerance: null,
    full_servings: null,
    half_servings: null,
    additional_intolerances: null,
    newsletter_opt_in: null,
    subscription: null,
    current_day: null,
    cc_expiry: null,
    cc_last_4: null,
    user_status: null,
    mobile_access: null,
    breakfast_opt_in: null,
    snack_opt_in: null,
    dessert_opt_in: null,
    trial_expiry: null,
    reason_for_cancelling: null,
    feature_newsletter: null,
    unsubscribed_from_emails: null
    */
  },
  billing: {
    subscriptionId: '',
    planExternalName: '',
    period: '',
    nextBillingDate: '',
    currentPrice: 0,
    nextInvoicePrice: 0,
    cardLast4: '',
    cardExpiryMonth: 0,
    cardExpiryYear: 0,
    onChargebee: true,
    isCancelled: false,
    onTrial: false,
    procesorType: 'chargebee',
    isGift: false,
    willRenew: false,
  },
  plan: {
    discount: {},
    plans: [],
    upsellModalData: {
      title: null,
      header: null,
      description: null,
      confirm: null,
      cancel: null,
    },
  },
  profile: {},
  preferences: {},
  onboarding: {},
  notifications: {},
  dashboardLatest: {},
  shareAndEarnClicked: false,
  promotionInfo: {
    userDiscountApplied: null,
    userDiscountPercent: null,
    userDiscountNotAppliedReason: null,
    userDiscountPopUpMessage: null,
  },
  messages: [],
  showUpsellPromoModal: false,
  portalSession: {
    notSetted: true,
  },
  billingLoading: false,
}

// getters
const getters = {
  accessToken: (state) => state.accessToken,
  isLoggedIn: (state) => !!state.accessToken,
  isActive: (state) => state.userData.status == 'active',
  isFree: (state) => state.userData.status == 'free',
  isCancelled: (state) => state.userData.status == 'cancelled',
  isExpired: (state) => state.userData.expired === true,
  defaultMealPlan: (state) => state.userData.defaultMealPlan,
  isTrialingPlan: (state) => state.billing.subscriptionStatus == 'trialing',
  isLoaded: (state) => Object.keys(state.userData).length > 0,
  isBillingLoading: (state) => state.billingLoading,
  isPreferencesLoaded: (state) => Object.keys(state.preferences).length > 0,
  isNotificationsLoaded: (state) => Object.keys(state.notifications).length > 0,
  isOnboardingLoaded: (state) => Object.keys(state.onboarding).length > 0,
  message: (state) => (state.messages.length > 0 ? state.messages[0] : null),
  useAmazonFresh: (state) => state.preferences.canUseAmazonFresh,
  useNutritionalTracking: (state) =>
    state.preferences.canUseNutritionalTracking,
  isSpeedPrepEnabled: (state) => state.preferences.isSpeedPrepEnabled,
  useMetricUnits: (state) => state.userData.useMetricUnits,
  useTemperatureCelsius: (state) => state.preferences.useTemperatureCelsius,
  earnAndShareBannerVisibleMobile: (state) =>
    _.get(
      state.userData,
      'userBehavior.earnAndShareBannerVisibleMobile',
      false
    ),
  permissions: (state) => state.permissions,
  plan: (state) => state.plan,
  planDiscount: (state) => state.plan.discount,
  planPlans: (state) => state.plan.plans,
  promotionInfo: (state) => state.promotionInfo,
  billing: (state) => state.billing,
  onboarding: (state) => state.onboarding,
  showCampaignBanner: (state) => state.showCampaignBanner,
  canShowUpsellPromoModal: (state) => state.plan.canShowUpsellPromoModal,
  showUpsellPromoModal: (state) => state.showUpsellPromoModal,
  upsellModalData: (state) => state.plan.upsellModalData,
  printRecipeImage: (state) => {
    if (state.userData && state.userData.printingPreferences) {
      return state.userData.printingPreferences.printRecipeImage
    }
    return false
  },
  userData: (state) => state.userData,
  userDataInitPromise: (state) => {
    return new Promise((resolve) => {
      let interval = setInterval(
        () => {
          if (state.userDataInitPromise) {
            resolve(state.userDataInitPromise)
            clearInterval(interval)
          }
        },
        200,
        50
      )
    })
  },
  intercomUser: (state) => state.intercomUser,
  isPlanUpgradable: (state) => state.billing && state.billing.isUpgradable,
  mustReactivatePlan: (state) =>
    state.userData && state.userData.userStatus === 'closed',
  willPlanSoonExpire: (state) => {
    if (!state.userData || state.userData.daysToExpiration < 0) return false

    switch (state.userData.userStatus) {
      case 'cancelled':
        return state.userData.daysToExpiration <= 7
      case 'cancelled_trial':
        return state.userData.daysToExpiration <= 7
      case 'active':
        if (state.billing.processorType === 'prepaid')
          return state.userData.daysToExpiration <= 7
        break
      default:
        return false
    }
  },
  name: (state) => {
    return state.userData.name
  },
  email: (state) => {
    return state.userData.email
  },
  id: (state) => {
    return state.userData.id
  },
  photo: (state) => {
    return state.userData.photo
  },
  hasPassword: (state) => {
    return state.profile.hasPassword
  },
  isFBConnected: (state) => {
    return !!state.userData.facebookId
  },
  preferences: (state) => state.preferences,
  isAdmin: (state) => {
    return state.userData.isAdmin
  },
  isAffiliate: (state) => {
    return state.userData.isAffiliate
  },
  subscription: (state) => {
    return state.userData.subscription
  },
  affiliateCode: (state) => {
    return state.userData.affiliateCode
  },
  affiliateName: (state) => {
    return state.userData.affiliateName
  },
  // preferences
  portions: (state) => {
    return [
      {
        key: 'fullServings',
        title: 'Full portions',
        value: state.preferences.fullServings,
        image: require('@/assets/icons/onboarding-adult.svg'),
      },
      {
        key: 'halfServings',
        title: '½ portions',
        value: state.preferences.halfServings,
        image: require('@/assets/icons/onboarding-child.svg'),
      },
    ]
  },
  fullServings: (state) => state.preferences.fullServings,
  halfServings: (state) => state.preferences.halfServings,
  intolerances: (state) => {
    const { soyIntolerance, glutenIntolerance, nutIntolerance } =
      state.preferences
    return [
      {
        key: 'none',
        title: 'None',
        image: require('@/assets/icons/intolerances-none.svg'),
        isSelected: !(soyIntolerance || glutenIntolerance || nutIntolerance),
      },
      {
        key: 'soyIntolerance',
        title: 'Soy',
        image: require('@/assets/icons/onboarding-soy.svg'),
        isSelected: soyIntolerance,
      },
      {
        key: 'glutenIntolerance',
        title: 'Gluten',
        image: require('@/assets/icons/onboarding-gluten.svg'),
        isSelected: glutenIntolerance,
      },
      {
        key: 'nutIntolerance',
        title: 'Nuts',
        image: require('@/assets/icons/onboarding-nuts.svg'),
        isSelected: nutIntolerance,
      },
    ]
  },
  scheduledMeals: (state) => {
    const { breakfastOptIn, lunchOptIn, dinnerOptIn, snackOptIn } =
      state.preferences
    return [
      {
        key: 'breakfastOptIn',
        title: 'Breakfast',
        image: require('@/assets/icons/onboarding-breakfast.svg'),
        isSelected: breakfastOptIn,
        getIntercomEvent: () => {
          return breakfastOptIn ? 'breakfast_opted_in' : 'breakfast_opted_out'
        },
      },
      {
        key: 'lunchOptIn',
        title: 'Lunch',
        image: require('@/assets/icons/onboarding-lunch.svg'),
        isSelected: lunchOptIn,
        getIntercomEvent: () => {
          return lunchOptIn ? 'lunch_opted_in' : 'lunch_opted_out'
        },
      },
      {
        key: 'dinnerOptIn',
        title: 'Dinner',
        image: require('@/assets/icons/onboarding-dinner.svg'),
        isSelected: dinnerOptIn,
        getIntercomEvent: () => {
          return dinnerOptIn ? 'dinner_opted_in' : 'dinner_opted_out'
        },
      },
      {
        key: 'snackOptIn',
        title: 'Snack & Dessert',
        image: require('@/assets/icons/onboarding-dessert.svg'),
        isSelected: snackOptIn,
        getIntercomEvent: () => {
          return snackOptIn
            ? 'snack_and_dessert_opted_in'
            : 'snack_and_dessert_opted_out'
        },
      },
    ]
  },
  numberOfScheduledMeals: (state, getters) => {
    return _.filter(getters.scheduledMeals, (meal) => {
      if (meal.isSelected) return meal
    }).length
  },
  printingOptions: (state) => {
    const p = state.userData.printingPreferences || {}
    return [
      {
        label: 'Photos',
        isSelected: p.printRecipeImage,
        key: 'printRecipeImage',
        apiKey: 'savePrintRecipeImage',
      },
      {
        label: 'Tips',
        isSelected: p.printRecipeTips,
        key: 'printRecipeTips',
        apiKey: 'savePrintRecipeTips',
      },
      {
        label: 'Storage',
        isSelected: p.printRecipeStorageTips,
        key: 'printRecipeStorageTips',
        apiKey: 'savePrintRecipeStorageTips',
      },
      {
        label: 'Freezing',
        isSelected: p.printRecipeFreezingTips,
        key: 'printRecipeFreezingTips',
        apiKey: 'savePrintRecipeFreezingTips',
      },
      {
        label: 'Reheating',
        isSelected: p.printRecipeReheatingTips,
        key: 'printRecipeReheatingTips',
        apiKey: 'savePrintRecipeReheatingTips',
      },
      {
        label: 'QR codes',
        isSelected: p.printRecipeQrCodes,
        key: 'printRecipeQrCodes',
        apiKey: 'savePrintRecipeQrCodes',
      },
      {
        label: 'Notes',
        isSelected: p.printRecipeNotes,
        key: 'printRecipeNotes',
        apiKey: 'savePrintRecipeNotes',
      },
      {
        label: 'Nutrition',
        isSelected: p.printRecipeNutrition,
        key: 'printRecipeNutrition',
        apiKey: 'savePrintRecipeNutrition',
      },
    ]
  },
  notifications: (state, getters) => {
    return [
      {
        title: 'EMAILS FROM FORKS MEAL PLANNER',
        id: 'weeklyEmailNotification',
        description:
          'Receive regular emails from Forks Meal Planner about weekly meal plans, best practices, new features and more.',
        isActive: state.notifications.weeklyEmailNotification,
        apiKey: 'setWeeklyPlanEmail',
        hide: !getters.permissions.canReceiveWeeklyMealPlanEmail,
        getIntercomData: () => {
          return null
        },
      },
    ].filter((notification) => !notification.hide)
  },
  wasPortionsChanged(state) {
    const {
      fullServings,
      previousFullServings,
      halfServings,
      previousHalfServings,
    } = state.preferences

    return (
      fullServings !== previousFullServings ||
      halfServings !== previousHalfServings
    )
  },
  wasIntoleranceChanged(state) {
    const {
      soyIntolerance,
      previousSoyIntolerance,
      glutenIntolerance,
      previousGlutenIntolerance,
      nutIntolerance,
      previousNutIntolerance,
    } = state.preferences

    return (
      soyIntolerance !== previousSoyIntolerance ||
      glutenIntolerance !== previousGlutenIntolerance ||
      nutIntolerance !== previousNutIntolerance
    )
  },
  wasScheduledMealsChanged(state) {
    const {
      breakfastOptIn,
      previousBreakfastOptIn,
      lunchOptIn,
      previousLunchOptIn,
      dinnerOptIn,
      previousDinnerOptIn,
      dessertOptIn,
      previousDessertOptIn,
      snackOptIn,
      previousSnackOptIn,
    } = state.preferences

    return (
      breakfastOptIn !== previousBreakfastOptIn ||
      lunchOptIn !== previousLunchOptIn ||
      dinnerOptIn !== previousDinnerOptIn ||
      dessertOptIn !== previousDessertOptIn ||
      snackOptIn !== previousSnackOptIn
    )
  },
  wasUsePressureCookerChanged(state) {
    const { usePressureCooker, previousUsePressureCooker } = state.preferences

    return usePressureCooker !== previousUsePressureCooker
  },
  nextPlanStartDateTitle(state) {
    return state.dashboardLatest.nextPlanStartDateTitle
  },
  dashboardLatest(state) {
    return state.dashboardLatest
  },
  premiumViewType(state, getters, rootState, rootGetters) {
    const {
      cardLast4,
      cancelAtPeriodEnd,
      subscriptionStatus,
      planId,
      processorType,
      paymentType,
    } = state.billing

    const canReactivatePlan =
      cardLast4 && (cancelAtPeriodEnd || subscriptionStatus === 'canceled')
    const canChangePlan = !!planId && processorType !== 'prepaid'

    if (paymentType === 'inapp') {
      return 'inapp'
    } else if (rootGetters['globals/giftCode']) {
      return 'gift'
    } else if (canReactivatePlan) {
      return 'reactivate'
    } else if (canChangePlan) {
      return 'switch'
    } else return 'upgrade'
  },
  wasShareAndEarnClicked: (state) => state.shareAndEarnClicked,
  showRecipeServingsTip: (state) =>
    _.get(state.userData, 'userBehavior.showRecipeServingsTip', true),
  totalUserServings: (state) =>
    state.preferences.fullServings + state.preferences.halfServings * 0.5,
  portalSession: (state) =>
    state.portalSession.notSetted ? null : state.portalSession,
  subscriptionId: (state) =>
    state.billing && state.billing.subscriptionId
      ? state.billing.subscriptionId
      : null,
  subscriptionState: (state) => state.userData.state,
  isOnChargebee: (state) => state.billing.onChargebeee,
}

// actions
const actions = {
  async refreshUserData({ commit, dispatch }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        commit(types.SET_BILLING_LOADING, true)

        const [
          profile,
          preferences,
          onboarding,
          notifications,
          userData,
          billing,
          dashboardLatest,
          // eslint-disable-next-line no-unused-vars
          _,
        ] = await Promise.all([
          apiSettings.getProfile(),
          apiSettings.getPreferences(),
          OnboardingApi.getOnboardingStatus(),
          apiSettings.getNotifications(),
          apiCredentials.getUserData(),
          apiSettings.getBilling(),
          apiDashboard.getDashboardLatest(),
          dispatch('user/setCancelUrl'),
        ])

        commit(types.SET_PROFILE, profile)
        commit(types.SET_PREFERENCES, preferences)
        commit(types.SET_ONBOARDING, onboarding)
        commit(types.SET_NOTIFICATIONS, notifications)
        commit(types.SET_USER_DATA, userData)
        commit(types.SET_BILLING, billing)
        commit(types.SET_DASHBOARD_LATEST, dashboardLatest)

        commit(types.SET_BILLING_LOADING, false)

        const intercomUser = {
          user_id: userData.id,
          name: userData.name,
          email: userData.email,
          user_hash: userData.intercomHash,
          created_at: userData.createdAt,
          soy_intolerance: userData.soyIntolerance,
          gluten_intolerance: userData.glutenIntolerance,
          nut_intolerance: userData.nutIntolerance,
          full_servings: userData.fullServings,
          half_servings: userData.halfServings,
          additional_intolerances: userData.additionalIntolerances,
          breakfast_opt_in: userData.breakfastOptIn,
          snack_opt_in: userData.snackOptIn,
          dessert_opt_in: userData.snackOptIn,
          newsletter_opt_in: userData.newsletterOptIn,
          subscription: userData.subscription,
          current_day: getCurrentDayTZ(),
          cc_expiry: userData.cardExpiry,
          cc_last_4: userData.cardLast4,
          user_status: userData.userStatus,
        }
        if (userData.trialExpiry) {
          intercomUser.trial_expiry = userData.trialExpiry
        }
        dispatch('setIntercomUserAttributes', intercomUser)

        // I am commenting this because is not longer necessary for now.
        // We need to refactor this later.
        // if (
        //   rootGetters['globals/mealPlan'] &&
        //   dashboardLatest.isSample &&
        //   rootGetters['globals/mealPlan'] !== dashboardLatest.planId
        // ) {
        //   await apiDashboard
        //     .resetDashboard(dashboardLatest.planId, {
        //       user_weekly_plan_id: dashboardLatest.userWeeklyPlanId
        //     })
        //     .then(() =>
        //       dispatch('dashboard/switchToSampleMealPlan', null, {
        //         root: true
        //       })
        //     )
        // }

        resolve([
          profile,
          preferences,
          onboarding,
          notifications,
          userData,
          dashboardLatest,
        ])
      } catch (e) {
        reject(e)
      }
    })
  },
  refreshPlan({ commit }) {
    apiSettings.getPlan().then((plan) => commit(types.SET_PLAN, plan))
  },
  // payload is an object with one or more intercom attributes/values
  setIntercomUserAttributes({ commit }, payload) {
    commit(types.SET_INTERCOM_USER_ATTRIBUTES, payload)
    EventBus.$emit('updateIntercom', payload)
  },
  resetIntercomUser({ commit }) {
    commit(types.RESET_INTERCOM_USER)
  },
  saveUserPhoto({ commit }, userPhotoFile) {
    let formData = new FormData()
    formData.append('photo', userPhotoFile)
    const photoUrl = URL.createObjectURL(userPhotoFile)
    commit(types.SET_USER_PHOTO, photoUrl)

    return apiSettings.changeProfilePhoto(formData).then(({ photo }) => {
      commit(types.SET_USER_PHOTO, photo)
    })
  },
  removeUserPhoto({ commit }) {
    commit(types.SET_USER_PHOTO, null)
    return apiSettings.removeProfilePhoto()
  },
  changeUserName({ commit }, name) {
    commit(types.SET_USER_NAME, name)
    return apiSettings.changeUserName({ name })
  },
  changeUserEmail({ commit }, email) {
    commit(types.SET_USER_EMAIL, email)
    return apiSettings.changeUserEmail({ email })
  },
  changePassword(_, { oldPassword, newPassword }) {
    return apiSettings.changePassword(oldPassword, newPassword)
  },
  createPassword(_, password) {
    return apiSettings.createPassword(password)
  },
  connectFacebookID({ commit }) {
    credentialsHandler.handleFBConnect().then(({ userID }) => {
      return apiSettings.setFacebookID(userID).then(() => {
        commit(types.SET_FACEBOOK_ID, userID)
      })
    })
  },
  deleteFacebookID({ commit }) {
    credentialsHandler.handleFBLogout().then(() => {})
    return apiSettings.deleteFacebookID().then(() => {
      commit(types.SET_FACEBOOK_ID, null)
    })
  },
  // preferences
  changePreferences({ state, dispatch }) {
    const preferences = snakeCaseKeys(
      _.pick(state.preferences, [
        'breakfastOptIn',
        'lunchOptIn',
        'dinnerOptIn',
        'dessertOptIn',
        'fullServings',
        'glutenIntolerance',
        'halfServings',
        'nutIntolerance',
        'snackOptIn',
        'soyIntolerance',
        'usePressureCooker',
      ])
    )
    // Desserts and Snack optins are tied together as one opt-in for now
    preferences.dessert_opt_in = preferences.snack_opt_in
    dispatch('setIntercomUserAttributes', preferences)
    return apiSettings.changePreferences(preferences)
  },
  decreaseFullServings({ commit, state, dispatch }) {
    let { fullServings, halfServings } = state.preferences
    if (fullServings == 0) return
    if (fullServings == 1 && halfServings == 0) {
      commit(types.SET_PREFERENCE, { key: 'halfServings', value: 1 })
    }
    commit(types.SET_PREFERENCE, {
      key: 'fullServings',
      value: fullServings - 1,
    })
    dispatch('changePreferences')
  },
  increaseFullServings({ commit, state, dispatch }) {
    let { fullServings } = state.preferences
    commit(types.SET_PREFERENCE, {
      key: 'fullServings',
      value: fullServings + 1,
    })
    dispatch('changePreferences')
  },
  decreaseHalfServings({ commit, state, dispatch }) {
    let { fullServings, halfServings } = state.preferences
    if (halfServings == 0) return
    if (halfServings == 1 && fullServings == 0) {
      commit(types.SET_PREFERENCE, {
        key: 'fullServings',
        value: 1,
      })
    }
    commit(types.SET_PREFERENCE, {
      key: 'halfServings',
      value: halfServings - 1,
    })

    dispatch('changePreferences')
  },
  increaseHalfServings({ commit, state, dispatch }) {
    let { halfServings } = state.preferences
    commit(types.SET_PREFERENCE, {
      key: 'halfServings',
      value: halfServings + 1,
    })
    dispatch('changePreferences')
  },
  toggleIntolerance({ state, dispatch, commit }, key) {
    if (key === 'none') {
      ;['soyIntolerance', 'nutIntolerance', 'glutenIntolerance'].forEach(
        (key) => {
          commit(types.SET_PREFERENCE, { key, value: false })
        }
      )
    } else {
      const value = !state.preferences[key]
      commit(types.SET_PREFERENCE, { key, value })
    }
    dispatch('changePreferences')
  },
  toggleUsePressureCooker({ state, dispatch, commit }, key) {
    const value = !state.preferences[key]
    commit(types.SET_PREFERENCE, { key, value })
    dispatch('changePreferences')
  },
  toggleScheduledMeal({ state, dispatch, commit }, key) {
    const value = !state.preferences[key]
    commit(types.SET_PREFERENCE, { key, value })
    dispatch('changePreferences')
  },
  togglePrintingOption({ state, dispatch, commit }, key) {
    const value = !state.userData.printingPreferences[key]
    commit(types.SET_PRINTING_OPTION, { key, value })
    dispatch('savePrintingOptions', { key, value })
  },
  savePrintingOptions({ getters }, { key, value }) {
    const apiKey = getters.printingOptions.find((o) => o.key === key).apiKey
    return apiSettings[apiKey](value)
  },
  toggleNotification({ commit }, { apiKey, id = null, isActive }) {
    if (id) {
      commit(types.SET_NOTIFICATION_STATE, { id, value: isActive })
    }
    return apiSettings[apiKey](isActive)
  },
  applyPreferencesAndResetDashboard({ state, dispatch }) {
    const { planId, userWeeklyPlanId } = state.dashboardLatest
    return apiDashboard
      .resetDashboard(planId, { user_weekly_plan_id: userWeeklyPlanId })
      .then(() =>
        dispatch('dashboard/refreshDashboard', undefined, { root: true })
      )
      .then(() => dispatch('refreshUserData'))
  },
  cancelPlan({ dispatch }) {
    return apiSettings.cancelPlan().then(() => dispatch('refreshUserData'))
  },
  deleteUser() {
    return apiCredentials.deleteUser().then(() => {
      resetState()
    })
  },
  changeBillingCard({ dispatch }, stripeTokenId) {
    return apiSettings
      .changeBillingCard(stripeTokenId)
      .then(() => dispatch('refreshUserData'))
  },
  login({ commit, rootGetters }, { email, password }) {
    const aff_ref = rootGetters['globals/affiliateCode']
    const campaign = rootGetters['globals/campaign']
    return apiCredentials
      .login(email, password, aff_ref, campaign)
      .then((response) => {
        let { token, user, portalSession } = response
        commit(types.SET_USER_DATA, user)
        commit(types.SET_ACCESS_TOKEN, token)
        commit(types.SET_PORTAL_SESISON, portalSession)
        return response
      })
  },
  loginWithMagicLink({ commit, rootGetters }, magicLink) {
    const aff_ref = rootGetters['globals/affiliateCode']
    const campaign = rootGetters['globals/campaign']
    return apiCredentials
      .loginWithMagicLink(magicLink, aff_ref, campaign)
      .then((response) => {
        let { token, user } = response
        commit(types.SET_USER_DATA, user)
        commit(types.SET_ACCESS_TOKEN, token)
        return response
      })
  },
  facebookLogin({ commit, rootGetters }, { id, email }) {
    const aff_ref = rootGetters['globals/affiliateCode']
    const campaign = rootGetters['globals/campaign']
    return apiCredentials
      .facebookLogin(id, email, aff_ref, campaign)
      .then((response) => {
        let { token, user } = response
        commit(types.SET_USER_DATA, user)
        commit(types.SET_ACCESS_TOKEN, token)
        return response
      })
  },
  googleLogin({ commit, rootGetters }, { id, email, accessToken }) {
    const aff_ref = rootGetters['globals/affiliateCode']
    const campaign = rootGetters['globals/campaign']
    return apiCredentials
      .googleLogin(id, email, accessToken, aff_ref, campaign)
      .then((response) => {
        let { token, user } = response
        commit(types.SET_USER_DATA, user)
        commit(types.SET_ACCESS_TOKEN, token)
        return response
      })
  },
  appleLogin(
    { commit, rootGetters },
    { id, email, fullName, accessToken, idToken }
  ) {
    const aff_ref = rootGetters['globals/affiliateCode']
    const campaign = rootGetters['globals/campaign']
    return apiCredentials
      .appleLogin(id, email, fullName, accessToken, idToken, aff_ref, campaign)
      .then((response) => {
        let { token, user } = response
        commit(types.SET_USER_DATA, user)
        commit(types.SET_ACCESS_TOKEN, token)
        return response
      })
  },
  setShareAndEarnClicked({ commit }, clicked) {
    commit(types.SET_SHARE_AND_EARN_CLICKED, clicked)
  },
  logout({ commit }) {
    commit(types.SET_ACCESS_TOKEN, '')
    commit(types.RESET_INTERCOM_USER, {})
    commit(`globals/${types.SET_MEAL_PLAN_DATA}`, {}, { root: true })
    apiCredentials.logout()
    EventBus.$emit('logout')
  },
  activateUpsellPromoDiscount({ dispatch }) {
    return apiSettings.activateUpsellPromoDiscount().then(() => {
      return dispatch('refreshPlan')
    })
  },
  deactivateUpsellPromoDiscount() {
    return apiSettings.deactivateUpsellPromoDiscount()
  },
  showUpsellPromoModal({ commit }) {
    commit(types.SET_SHOW_UPSELL_PROMO_MODAL, true)
  },
  hideUpsellPromoModal({ commit }) {
    commit(types.SET_CAN_SHOW_UPSELL_PROMO_MODAL, false)
    commit(types.SET_SHOW_UPSELL_PROMO_MODAL, false)
  },
  finishTour({ commit }, tourName) {
    return OnboardingApi.finishTour(tourName).then(() => {
      return OnboardingApi.getOnboardingStatus().then((onboarding) => {
        commit(types.SET_ONBOARDING, onboarding)
        return onboarding
      })
    })
  },
  finishAllTours({ commit }) {
    return OnboardingApi.finishAllTours().then(() => {
      return OnboardingApi.getOnboardingStatus().then((onboarding) => {
        commit(types.SET_ONBOARDING, onboarding)
        return onboarding
      })
    })
  },
  setPromotionInfo({ commit }, promotionInfo) {
    commit(types.SET_PROMOTION_INFO, promotionInfo)
  },
  setBilling({ commit }, billing) {
    commit(types.SET_BILLING, billing)
  },
  setUseAmazonFresh({ commit }, useAmazonFresh) {
    return apiSettings.setUseAmazonFresh(useAmazonFresh).then(() => {
      commit(types.SET_USE_AMAZON_FRESH, useAmazonFresh)
    })
  },
  async setUseNutritionalTracking({ commit }, useNutritionalTracking) {
    try {
      apiSettings.setUseNutritionalTracking(useNutritionalTracking)

      commit(types.SET_USE_NUTRITIONAL_TRACKING, useNutritionalTracking)
    } catch (err) {
      commit(types.SET_USE_NUTRITIONAL_TRACKING, !useNutritionalTracking)
    }
  },
  setUseMetricUnits({ commit }, useMetricUnits) {
    commit(types.SET_USE_METRIC_UNITS, useMetricUnits)
    return apiSettings.setUseMetricUnits(useMetricUnits)
  },
  setUseTemperatureCelsius({ commit }, useTemperatureCelsius) {
    commit(types.SET_USE_TEMPERATURE_CELSIUS, useTemperatureCelsius)
    return apiSettings.setUseTemperatureCelsius(useTemperatureCelsius)
  },
  setShareAndEarnMobileBannerVisible({ commit }, visible) {
    commit(types.SET_SHARE_AND_EARN_MOBILE_BANNER_VISIBLE, visible)
    return apiSettings.setShareAndEarnMobileBannerVisible(visible)
  },
  async toggleSpeedPrepVisibility({ commit }, value) {
    return apiSettings.toggleSpeedPrepVisibility(value).then(() => {
      commit(types.SET_PREFERENCE, { key: 'isSpeedPrepEnabled', value })
    })
  },
  removeMessage({ commit }) {
    commit(types.REMOVE_MESSAGE)
  },
  getMessages({ commit }) {
    apiCredentials.getUserMessages().then((data) => {
      commit(types.ADD_MESSAGES, data)
    })
  },
  setPortalSession({ commit }, data) {
    commit(types.SET_PORTAL_SESISON, data)
  },
  async refreshBilling({ commit }) {
    commit(types.SET_BILLING_LOADING, true)
    const billing = await apiSettings.getBilling({ sync: true })
    commit(types.SET_BILLING, billing)
    commit(types.SET_BILLING_LOADING, false)
  },
  async updateUserData({ commit, dispatch }, sync = false) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const userData = await apiCredentials.getUserData(sync)
        const [
          profile,
          preferences,
          onboarding,
          notifications,
          billing,
          dashboardLatest,
          // eslint-disable-next-line no-unused-vars
          _,
        ] = await Promise.all([
          apiSettings.getProfile(),
          apiSettings.getPreferences(),
          OnboardingApi.getOnboardingStatus(),
          apiSettings.getNotifications(),
          apiSettings.getBilling(),
          apiDashboard.getDashboardLatest(),
          dispatch('user/setCancelUrl'),
        ])

        commit(types.SET_USER_DATA, userData)
        commit(types.SET_PROFILE, profile)
        commit(types.SET_PREFERENCES, preferences)
        commit(types.SET_ONBOARDING, onboarding)
        commit(types.SET_NOTIFICATIONS, notifications)
        commit(types.SET_BILLING, billing)
        commit(types.SET_DASHBOARD_LATEST, dashboardLatest)

        resolve(
          profile,
          preferences,
          onboarding,
          notifications,
          billing,
          dashboardLatest
        )
      } catch (error) {
        reject(error)
      }
    })
  },
  async setCancelUrl({ commit }) {
    try {
      const data = await apiCheckout.createCancel()
      commit(types.SET_CANCEL_URL, data.url)
    } catch (error) {
      console.error(error.message)
    }
  },
}

// mutations
const mutations = {
  [types.SET_PERMISSIONS](state, permissions) {
    state.permissions = camelizeKeys(permissions)
  },
  [types.SET_PROFILE](state, profile) {
    state.profile = profile
  },
  [types.SET_PREFERENCES](state, preferences) {
    state.preferences = preferences
  },
  [types.SET_ONBOARDING](state, onboarding) {
    state.onboarding = onboarding
  },
  [types.SET_PREFERENCE](state, { key, value }) {
    state.preferences[key] = value
  },
  [types.SET_PRINTING_OPTION](state, { key, value }) {
    state.userData.printingPreferences[key] = value
  },
  [types.SET_USER_DATA](state, userData) {
    state.userData = userData
    state.permissions = userData.permissions
    state.messages.push(...userData.messages)
  },
  [types.ADD_MESSAGE](state, message) {
    state.messages.push(message)
  },
  [types.ADD_MESSAGES](state, messageList) {
    state.messages.push(...messageList)
  },
  [types.REMOVE_MESSAGE](state) {
    state.messages.splice(0, 1)
  },
  [types.SET_USER_DATA_INIT_PROMISE](state, promise) {
    state.userDataInitPromise = promise
  },
  [types.SET_BILLING](state, billing) {
    state.billing = billing
  },
  [types.SET_PLAN](state, plan) {
    state.plan = plan
  },
  [types.SET_USER_PHOTO](state, photoUrl) {
    state.userData.photo = photoUrl
  },
  [types.SET_USER_NAME](state, name) {
    state.userData.name = name
  },
  [types.SET_USER_EMAIL](state, email) {
    state.userData.email = email
  },
  [types.SET_FACEBOOK_ID](state, facebookId) {
    state.userData.facebookId = facebookId
  },
  [types.SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications
  },
  [types.SET_NOTIFICATION_STATE](state, { id, value }) {
    state.notifications[id] = value
  },
  [types.SET_DASHBOARD_LATEST](state, dashboardLatest) {
    state.dashboardLatest = dashboardLatest
  },
  [types.SET_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken
  },
  [types.SET_SHARE_AND_EARN_CLICKED](state, clicked) {
    state.shareAndEarnClicked = clicked
  },
  [types.SET_PROMOTION_INFO](state, promotionInfo) {
    state.promotionInfo = promotionInfo
  },
  [types.SET_CAN_SHOW_UPSELL_PROMO_MODAL](state, show) {
    Vue.set(state.plan, 'canShowUpsellPromoModal', show)
  },
  [types.SET_SHOW_UPSELL_PROMO_MODAL](state, show = true) {
    state.showUpsellPromoModal = show
  },
  [types.SET_INTERCOM_USER_ATTRIBUTES](state, payload) {
    _.forOwn(payload, (value, key) => {
      Vue.set(state.intercomUser, key, value)
    })
  },
  [types.RESET_INTERCOM_USER](state) {
    state.intercomUser = {}
  },
  [types.SET_USE_AMAZON_FRESH](state, useAmazonFresh) {
    Vue.set(state.preferences, 'canUseAmazonFresh', useAmazonFresh)
  },
  [types.SET_USE_NUTRITIONAL_TRACKING](state, useNutritionalTracking) {
    Vue.set(
      state.preferences,
      'canUseNutritionalTracking',
      useNutritionalTracking
    )
  },
  [types.SET_USE_METRIC_UNITS](state, useMetricUnits) {
    Vue.set(state.userData, 'useMetricUnits', useMetricUnits)
  },
  [types.SET_USE_TEMPERATURE_CELSIUS](state, useTemperatureCelsius) {
    Vue.set(state.preferences, 'useTemperatureCelsius', useTemperatureCelsius)
  },
  [types.SET_SHARE_AND_EARN_MOBILE_BANNER_VISIBLE](state, isVisible) {
    Vue.set(
      state.userData.userBehavior,
      'earnAndShareBannerVisibleMobile',
      isVisible
    )
  },
  [types.SET_PORTAL_SESISON](state, data) {
    state.portalSession = data
  },
  [types.SET_BILLING_LOADING](state, isLoading) {
    state.billingLoading = isLoading
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
