import apiCheckout from '@/api/checkout'
import {
  SET_SELECTED_PLAN,
  SET_CHECKOUT_PLANS,
  SET_CHECKOUT_TITLE,
  SET_CHECKOUT_SUBTITLE,
  SET_CHECKOUT_CTA,
  SET_CHECKOUT_RIBBON,
  SET_BADGE_COLOR,
  SET_TITLE_COLOR,
} from '../mutation-types'

const state = {
  plans: [],
  selectedPlan: null,
  ribbon: null,
  title: null,
  subtitle: null,
  cta: null,
  badge: {
    background: '',
    text: '',
  },
  titleColor: null,
}

const getters = {
  plans: (state) => state.plans,
  selectedPlan: (state) => state.selectedPlan,
  ribbon: (state) => state.ribbon,
  title: (state) => state.title,
  subtitle: (state) => state.subtitle,
  cta: (state) => state.cta,
}

const actions = {
  async loadCheckoutPage({ commit }, id = null) {
    const response = await apiCheckout.getCheckout(id)

    const selectedPlan = response.plans.filter(
      (plan) => plan.selectedByDefault === true
    )
    let ribbon = null

    if (response.showRibbon) {
      ribbon = {
        colors: {
          background: response.promoRibbonBackgroundColor,
          text: response.promoRibbonTextColor,
        },
        text: response.promoRibbonContent,
      }
    }

    commit(SET_CHECKOUT_PLANS, response.plans)
    commit(SET_SELECTED_PLAN, selectedPlan[0])
    commit(SET_CHECKOUT_RIBBON, ribbon)
    commit(SET_CHECKOUT_TITLE, response.headline)
    commit(SET_CHECKOUT_SUBTITLE, response.subHeadline)
    commit(SET_CHECKOUT_CTA, response.cta)
    commit(SET_BADGE_COLOR, {
      background: response.plansBadgeBackgroundColor,
      text: response.plansBadgeTextColor,
    })
    commit(SET_TITLE_COLOR, response.plansTitleTextColor)
  },
  setSelectedPlan({ commit }, plan) {
    commit(SET_SELECTED_PLAN, plan)
  },
}

const mutations = {
  [SET_CHECKOUT_PLANS](state, plans) {
    state.plans = plans
  },
  [SET_SELECTED_PLAN](state, currentPlan) {
    state.selectedPlan = currentPlan
  },
  [SET_CHECKOUT_RIBBON](state, ribbon) {
    state.ribbon = ribbon
  },
  [SET_CHECKOUT_TITLE](state, title) {
    state.title = title
  },
  [SET_CHECKOUT_SUBTITLE](state, subtitle) {
    state.subtitle = subtitle
  },
  [SET_CHECKOUT_CTA](state, text) {
    state.cta = text
  },
  [SET_BADGE_COLOR](state, colors) {
    state.badge = colors
  },
  [SET_TITLE_COLOR](state, color) {
    state.titleColor = color
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
