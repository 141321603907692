<template>
  <div id="print-modal">
    <MPModal
      :show.sync="showModal"
      :show-x="true"
      :inverseCloseColor="printClicked"
    >
      <div class="print-modal-content">
        <div class="row">
          <div class="col-md-12 print-container">
            <h3 v-if="printModalMode == 'PRINT'">Select Items to Print</h3>
            <h3 v-if="printModalMode == 'DOWNLOAD'">
              Select Items to Download
            </h3>
            <div class="print-modal-content-options">
              <ul class="options-new">
                <li
                  class="checkmark plan"
                  :class="{ active: localPrintOptions.plan }"
                >
                  <input
                    type="checkbox"
                    id="plan"
                    v-model="localPrintOptions.plan"
                  />
                  <label for="plan">
                    <PlanDashboard class="icon" />
                    <span class="option-text">Plan & Recipes</span>
                  </label>
                </li>
                <li
                  class="checkmark grocery"
                  :class="{ active: localPrintOptions.groceryList }"
                >
                  <input
                    type="checkbox"
                    id="groceryList"
                    v-model="localPrintOptions.groceryList"
                  />
                  <label for="groceryList">
                    <GroceryList class="icon" />
                    <span class="option-text">Grocery List</span>
                  </label>
                </li>
                <li
                  class="checkmark weekend"
                  :class="{ active: localPrintOptions.prep }"
                >
                  <input
                    type="checkbox"
                    id="weekendPrep"
                    v-model="localPrintOptions.prep"
                  />
                  <label for="weekendPrep">
                    <WeekendPrep class="icon" />
                    <span class="option-text">Weekend Prep</span>
                  </label>
                </li>
              </ul>
            </div>

            <div class="print-modal-content-bottom-section">
              <div class="print-recipe-toggle-section">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="`printRecipeImage${printModalMode.toLowerCase()}`"
                    @change="(value) => (printRecipeImage = value)"
                  />
                  <label
                    class="custom-control-label print-recipe-toggle-text"
                    :for="`printRecipeImage${printModalMode.toLowerCase()}`"
                  >
                    Include photos on recipe printouts
                  </label>
                </div>
              </div>
              <div v-if="!printClicked">
                <MPButton
                  color="primary"
                  size="large"
                  @click="printItems()"
                  :disabled="
                    (!localPrintOptions.plan &&
                      !localPrintOptions.groceryList &&
                      !localPrintOptions.prep) ||
                    !!printClicked
                  "
                >
                  <span v-if="printModalMode == 'PRINT'"
                    >Create Printable PDF</span
                  >
                  <span v-if="printModalMode == 'DOWNLOAD'">Download PDF</span>
                </MPButton>
              </div>
            </div>
            <p class="tip small">
              <b>Tip</b>: To get the most from the service, we recommend
              accessing your grocery list, weekend prep and recipes from your
              mobile device or laptop at
              <a href="https://my.forksmealplanner.com/" target="_blank"
                >my.forksmealplanner.com</a
              >.
            </p>
          </div>
        </div>

        <div class="creating-pdf-overlay" :class="{ open: printClicked }">
          <div
            class="creating-pdf-container closed"
            :class="{ closed: !printClicked, open: printClicked }"
          >
            <div class="creating-pdf-content">
              <div
                class="coffee-animation"
                :class="{ 'd-block': printClicked }"
              >
                <svg
                  class="coffee"
                  version="1.1"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0 0 416.501 416.501"
                  style="enable-background: new 0 0 416.501 416.501"
                  xml:space="preserve"
                >
                  <path
                    class="svg-coffee"
                    :class="{ 'svg-coffee-icon': printClicked }"
                    id="coffee"
                    stroke="#000000"
                    fill="white"
                    d="M193.96,68.161c-7.926-20.279-16.908-43.264,1.287-65.26c2.814-3.403,7.858-3.882,11.263-1.065
                    c3.405,2.816,3.882,7.859,1.065,11.264c-12.194,14.743-5.947,30.728,1.287,49.238c7.925,20.278,16.907,43.262-1.287,65.257
                    c-1.582,1.913-3.866,2.901-6.169,2.901c-1.796,0-3.603-0.602-5.095-1.836c-3.404-2.816-3.881-7.859-1.064-11.263
                    C207.44,102.655,201.193,86.67,193.96,68.161z M362.642,249.917l-7.46,43.384c-2.998,17.44-19.67,31.628-37.164,31.628h-20.562
                    l-7.92,50.681c-0.608,3.895-3.963,6.765-7.904,6.765H121.188c-3.941,0-7.296-2.871-7.904-6.765L80.869,168.167
                    c-0.36-2.309,0.307-4.661,1.826-6.437c1.521-1.776,3.74-2.798,6.078-2.798h225.275c2.337,0,4.558,1.022,6.078,2.798
                    c1.52,1.775,2.187,4.127,1.826,6.437l-7.409,47.411h19.525c8.977,0,16.929,3.57,22.393,10.053
                    C361.928,232.117,364.181,240.968,362.642,249.917z M304.701,174.932H98.12l29.915,191.444h146.751l7.908-50.61
                    c0.008-0.054,0.017-0.108,0.025-0.162L304.701,174.932z M344.226,235.942c-2.371-2.814-5.979-4.364-10.157-4.364h-22.025
                    l-12.087,77.351h18.062c9.889,0,19.687-8.398,21.395-18.34l7.46-43.384C347.621,242.855,346.682,238.855,344.226,235.942z
                    M341.435,400.501H61.386c-4.418,0-8,3.582-8,8s3.582,8,8,8h280.05c4.418,0,8-3.582,8-8S345.853,400.501,341.435,400.501z"
                  />
                </svg>
              </div>
              <div
                class="checkmark-animation"
                :class="{ 'd-none': printClicked }"
                hidden
              >
                <svg
                  class="checkmark"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 161.2 161.2"
                  enable-background="new 0 0 161.2 161.2"
                  xml:space="preserve"
                >
                  <circle
                    class="circle"
                    fill="none"
                    stroke="white"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    cx="80.6"
                    cy="80.6"
                    r="62.1"
                  />
                  <polyline
                    class="polyline"
                    fill="none"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    points="113,52.8
                  74.1,108.4 48.2,86.4 "
                  />
                </svg>
              </div>
              <p>We are preparing your printable PDF.</p>

              <p>
                This window will automatically update when your PDF is ready (it
                won’t take long).
              </p>
            </div>
          </div>
        </div>
      </div>
    </MPModal>
  </div>
</template>

<script>
import { saveAs } from 'file-saver'
import { printFile } from '@/services/web/PrintService'
import DashboardApi from '@/api/dashboard'
import { mapGetters } from 'vuex'
import {
  PlanDashboard,
  GroceryList,
  WeekendPrep,
} from '@/components/common/MPIcons'

export default {
  name: 'MPPrintModal',
  components: {
    PlanDashboard,
    GroceryList,
    WeekendPrep,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    printModalMode: {
      type: String,
      required: true,
    },
    printOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localPrintOptions: null,
      printRecipeImage: false,
      printClicked: false,
    }
  },
  computed: {
    ...mapGetters({
      mealPlan: 'globals/mealPlan',
      userWeeklyPlanId: 'globals/userWeeklyPlanId',
      dashboardTitle: 'dashboard/title',
      userPrintRecipeImage: 'user/printRecipeImage',
    }),
    showModal: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      },
    },
  },
  methods: {
    printItems() {
      this.printClicked = true
      let printServices = ''
      let split = ''
      const { plan, groceryList, prep } = this.localPrintOptions
      if (plan) {
        printServices += 'plan'
        split = ','
      }
      if (groceryList) {
        printServices += split + 'grocery_list'
        split = ','
      }
      if (prep) {
        printServices += split + 'weekend_prep'
      }

      let printPDFName = 'Forks Meal Planner - ' + this.dashboardTitle + ' - '
      if ((plan && groceryList) || (plan && prep) || (groceryList && prep)) {
        printPDFName += 'Meal Plan.pdf'
      } else if (plan) {
        printPDFName += 'Plan & Recipes.pdf'
      } else if (groceryList) {
        printPDFName += 'Grocery List.pdf'
      } else if (prep) {
        printPDFName += 'Weekend Prep.pdf'
      }

      if (printServices) {
        const printRecipeImageOption = this.printRecipeImage ? 1 : 0
        DashboardApi.getPdfFiles(
          this.mealPlan,
          this.userWeeklyPlanId,
          printServices,
          printRecipeImageOption,
          (response) => {
            if (response.success) {
              this.onPreparedPdf()
              this.showModal = false
              if (this.printModalMode == 'DOWNLOAD') {
                saveAs(response.blob, printPDFName)
              }
              if (this.printModalMode == 'PRINT') {
                printFile(response.blob, response.fileUrl, printPDFName)
              }
            } else {
              // TODO add error handling
              //$rootScope.$broadcast('serverError', 'There was an error preparing your document.');
              this.showModal = false
            }
            this.printClicked = false
          }
        )
      } else {
        this.printClicked = false
      }

      if (plan) {
        this.$analytics.track('mp plan printed')
      }
      if (groceryList) {
        this.$analytics.track('mp grocery list printed')
      }
      if (prep) {
        this.$analytics.track('mp weekend prep printed')
      }
    },
    onPreparedPdf() {
      // TODO
    },
  },
  watch: {
    printOptions: {
      handler(newValue) {
        this.localPrintOptions = newValue
      },
      immediate: true,
    },
    userPrintRecipeImage: {
      handler(newValue) {
        this.printRecipeImage = newValue
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
#print-modal {
  .modal-dialog {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .mp-modal-content {
    padding: 0;
  }

  .print-container {
    display: flex;
    flex-direction: column;
  }

  .icon {
    height: 53px;
    margin-top: 39px;
    @include media-breakpoint-down(sm) {
      margin: auto 0;
    }
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
    background-color: $clr-navy-3;
    background-image: url('~@/assets/images/print-left-side@2x.png'),
      url('~@/assets/images/print-right-side@2x.png');
    background-size: 181px 532px, 168px 532px;
    background-position: top left, bottom right;
    background-repeat: no-repeat;

    .row {
      padding: 30px;
      @include media-breakpoint-down(sm) {
        min-height: 100vh;
      }
    }

    h3 {
      margin-top: 24px;
      margin-bottom: 30px;
      text-align: center;
      font-size: 28px;

      @include media-breakpoint-down(md) {
        margin-top: 35px;
      }
    }

    .close-reveal-modal {
      top: 13px;
      left: 13px;

      &:hover {
        opacity: 0.7;
      }
    }

    .btn {
      min-width: 345px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        min-width: 0;
        margin-bottom: 25px;
      }
    }

    .tip {
      margin-bottom: 0;
      margin-top: 28px;
      text-align: left;
      padding: 0;
      border-top: 1px solid $clr-navy-15;
      padding-top: 18px;

      a {
        color: $body-color;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-top: auto;
      }
    }

    .print-recipe-toggle-section {
      margin: 20px 0 18px 0;
      text-align: center;

      @include media-breakpoint-down(sm) {
        margin: 28px 0;
        text-align: left;
      }
    }

    .options-new {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      padding: 0;
      margin: 0;
      @include media-breakpoint-down(sm) {
        margin: 0;
        flex-direction: column;
      }

      .checkmark {
        width: calc((100% - 40px) / 3);
        height: 159px;
        background-color: $white;
        border: solid 1px $clr-navy-15;
        list-style: none;
        text-align: center;
        transition: all 0.2s;
        cursor: pointer;

        @include media-breakpoint-down(sm) {
          width: 100%;
          height: auto;
          &.plan {
            .option-text {
              margin-left: 37px;
            }
          }
          &.grocery {
            .option-text {
              margin-left: 45px;
            }
          }
          &.weekend {
            .option-text {
              margin-left: 31px;
            }
          }
        }

        &.active {
          box-shadow: 0 2px 8px 0 rgba(0, 41, 64, 0.2);
          border: solid 2px $primary;
        }
      }
      .checkmark + .checkmark {
        margin-left: 20px;
        @include media-breakpoint-down(sm) {
          margin-left: 0;
          margin-top: 20px;
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked + label:after {
          position: absolute;
          display: block;
          content: '';
          width: 22px;
          height: 22px;
          top: -11px;
          right: -11px;
          border-radius: 50%;
          background: $white url('~@/assets/icons/icon-selected-badge.svg');
        }
      }

      label {
        cursor: pointer;
        position: relative;
        transition: all 0.2s;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
          flex-direction: row;
          padding: 20px 22px;
        }

        .option-text {
          display: block;
          font-weight: bold;
          margin-top: 17px;
          margin-bottom: auto;
          @include media-breakpoint-down(sm) {
            padding: 0 20px;
            margin: auto 0;
          }
        }
      }
    }

    .print-modal-content {
      position: relative;
      min-height: 100vh;
      @include media-breakpoint-up(md) {
        min-height: auto;
      }

      .print-modal-content-options {
        margin-top: 0;
        @include media-breakpoint-down(sm) {
          margin-top: 20px;
        }
      }

      .print-modal-content-bottom-section {
        width: 300px;
        text-align: center;
        margin: auto;
        @include media-breakpoint-down(sm) {
          margin-top: 0;
          width: 100%;
          text-align: left;
        }
      }
    }

    .creating-pdf-overlay {
      bottom: 0;
      width: 100%;
      top: 100%;
      height: 0;
      z-index: 1;
      transition: all 600ms cubic-bezier(0.805, -0.025, 0.165, 1.075);
      position: absolute;
      background-color: $dusty-orange-hover;
      background-size: cover;
      padding: 0;
      overflow: hidden;
      z-index: 5;
      border-radius: 0;

      :before {
        background-color: $white;
        width: 100%;
        height: 100%;
      }

      &.open {
        top: 0;
        height: 100%;
        background-color: $white;
      }

      .creating-pdf-container {
        background-color: $primary;
        border-radius: 0;
        opacity: 1;
        width: calc(100% + 1px);
        height: 0;
        transition: background-color 0.2s ease-in;

        &.open {
          opacity: 0.8;
          height: 100%;
          background-color: $primary;
        }
      }

      .creating-pdf-content {
        text-align: center;
        padding: 0 15px;
      }

      svg {
        margin-top: 55px;
        margin-bottom: 45px;
        height: 100px;

        &.checkmark {
          width: 90px;
        }

        &.coffee {
          width: 100px;
        }
      }

      .coffee-animation {
        .svg-coffee {
          fill: transparent;
          stroke: white;
          stroke-width: 5px;
          stroke-dasharray: 1300;
          stroke-dashoffset: 1300;
        }

        .svg-coffee-icon {
          opacity: 1;
          fill: transparent;
          stroke: white;
          stroke-width: 6px;
          stroke-dasharray: 980;
          stroke-dashoffset: 980;
          animation: dash 3.3s linear infinite;
        }

        .svg-coffee-icon-hide {
          opacity: 0;
          transition: all ease-in 1s;
        }

        @keyframes dash {
          0% {
            stroke-dashoffset: 980;
          }
          10% {
            stroke-dashoffset: 980;
          }
          50% {
            stroke-dashoffset: 0;
          }
          90% {
            stroke-dashoffset: 980;
          }
          100% {
            stroke-dashoffset: 980;
          }
        }
      }

      p {
        color: $white;
        text-align: center;
      }

      .checkmark-animation {
        margin: 0 auto;

        .circle {
          stroke-dasharray: 1000;
          stroke-dashoffset: 0;
          animation: dash-circle 2s ease-in-out;
          -webkit-animation: dash-circle 2s ease-in-out;
        }

        .polyline {
          stroke-dasharray: 1000;
          stroke-dashoffset: 0;
          animation: dash-polyline 2s ease-in-out;
          -webkit-animation: dash-polyline 2s ease-in-out;
        }

        @-webkit-keyframes dash-circle {
          0% {
            stroke-dashoffset: 1000;
          }
          100% {
            stroke-dashoffset: 0;
          }
        }

        @-webkit-keyframes dash-polyline {
          0% {
            stroke-dashoffset: 1100;
          }
          30% {
            stroke-dashoffset: 1100;
          }
          100% {
            stroke-dashoffset: 0;
          }
        }
      }
    }
  }

  // Media query
  @include media-breakpoint-up(md) {
    .modal-dialog {
      padding: 0;
      max-width: 620px;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.print-confirmation-modal-content {
  height: 422px;
}
</style>
