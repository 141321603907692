<template>
  <div class="recipe-notes">
    <ul class="nav nav-pills nav-fill w-100 mb-4">
      <li class="nav-item">
        <span
          class="nav-link"
          @click="tabSelected = 'NOTES'"
          :class="{ active: tabSelected === 'NOTES' }"
        >
          NOTES
        </span>
      </li>
      <li class="nav-item ml-2">
        <span
          class="nav-link"
          @click="tabSelected = 'FEEDBACK'"
          :class="{ active: tabSelected === 'FEEDBACK' }"
        >
          FEEDBACK
        </span>
      </li>
    </ul>
    <div v-if="tabSelected === 'NOTES'">
      <h3>{{ notes.length }} COMMUNITY NOTES:</h3>
      <RecipeAdminNotesPost
        v-for="(note, $index) in notes"
        :key="$index"
        :title="note.title"
        :avatar="note.avatar"
      >
        <p v-html="note.text"></p>
      </RecipeAdminNotesPost>
    </div>
    <div v-if="tabSelected === 'FEEDBACK'">
      <h3>{{ feedbacks.length }} COMMUNITY FEEDBACK:</h3>
      <RecipeAdminNotesPost
        v-for="(feedback, $index) in feedbacks"
        :key="$index"
        :title="feedback.title"
        :avatar="feedback.avatar"
      >
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Flavor</th>
              <th scope="col">Texture</th>
              <th scope="col">Timing</th>
              <th scope="col">Portion size</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{{ feedback.flavorRating }}</th>
              <td>{{ feedback.textureRating }}</td>
              <td>{{ feedback.timingRating }}</td>
              <td>{{ feedback.servingSizeRating }}</td>
            </tr>
          </tbody>
        </table>
        <p v-html="feedback.text"></p>
      </RecipeAdminNotesPost>
    </div>
  </div>
</template>

<script>
import RecipeAdminNotesPost from './RecipeAdminNotesPost'

export default {
  components: { RecipeAdminNotesPost },
  props: {
    notes: {
      type: Array,
      default: () => [],
    },
    feedbacks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabSelected: 'NOTES',
    }
  },
}
</script>

<style lang="scss" scoped>
.recipe-notes {
  padding: 0 15px;
}

.nav-pills .nav-link {
  cursor: pointer;
  &.active {
    background-color: $grey;
  }
}
</style>
