<template>
  <vue-autosuggest
    id="c-auto-suggest__container"
    :get-suggestion-value="getSuggestionValue"
    :inputProps="inputProps"
    :ref="refC"
    :suggestions="filteredSuggestions"
    @selected="selectHandler"
    @keydown.tab="onTab()"
  >
    <template slot-scope="{ suggestion }">
      <div>{{ suggestion.item[showParam] }}</div>
    </template>
  </vue-autosuggest>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import fastLevenshtein from 'fast-levenshtein'
import _ from 'lodash'

export default {
  name: 'MPAutoSuggest',
  components: {
    VueAutosuggest,
  },
  props: {
    suggestions: {
      required: true,
    },
    searchParam: {
      required: true,
    },
    showParam: {
      required: true,
    },
    returnParam: {
      default: null,
    },
    value: {
      type: [Object, String],
    },
    strictMode: {
      type: Boolean,
      required: true,
    },
    placeHolder: {
      type: String,
    },
    clearOnSelect: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      selected: null,
      filteredSuggestions: [],
    }
  },
  computed: {
    inputProps() {
      return {
        onInputChange: this.fetchResults,
        name: 'autosuggest',
        placeholder: this.placeHolder,
        class: 'autosuggest__input m-0',
        disabled: this.disabled,
      }
    },
    refC() {
      return `autosuggest-${this._uid}`
    },
  },
  mounted() {
    this.setInitValue()
  },
  watch: {
    value() {
      this.setInitValue()
    },
    suggestions() {
      this.setInitValue()
    },
  },
  methods: {
    onTab() {
      this.selectItem()
      this.filteredSuggestions = []
    },
    setInitValue() {
      if (this.suggestions && this.value) {
        const isValueTypeOfString = typeof this.value === 'string'
        if (isValueTypeOfString) {
          this.$refs[this.refC].searchInput = this.value
        } else {
          if (this.searchParam in this.value) {
            const item = this.suggestions.find(
              (o) => o[this.searchParam] == this.value[this.searchParam]
            )
            if (item) {
              this.$refs[this.refC].searchInput = item[this.showParam]
              this.selected = item
            }
          } else {
            this.$refs[this.refC].searchInput = ''
          }
        }
      } else {
        this.$refs[this.refC].searchInput = ''
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item[this.showParam]
    },
    fetchResults(text) {
      this.selectItem()
      if (text) {
        const filtered = this.suggestions.filter((item) => {
          return (
            item[this.searchParam].toLowerCase().indexOf(text.toLowerCase()) >
            -1
          )
        })
        let results = filtered.map((e) => ({
          distance: fastLevenshtein.get(text, e[this.searchParam]),
          item: e,
        }))
        results = results.sort(function (a, b) {
          return a.distance - b.distance
        })
        this.filteredSuggestions = [
          {
            data: results.map((r) => r.item),
          },
        ]
      } else {
        this.filteredSuggestions = []
      }
    },
    selectHandler(item) {
      if (item) {
        this.selected = item.item
        this.emitInput(this.selected)
        this.emitSelect(this.selected)
        if (this.clearOnSelect) {
          this.$refs[this.refC].searchInput = ''
        }
      }
    },
    // TODO: Needs refactoring
    selectItem: _.debounce(function () {
      const searchInput = _.get(this.$refs[this.refC], 'searchInput', null)
      if (searchInput) {
        this.selected = this.suggestions.find(
          (o) => o[this.showParam] == searchInput
        )
        if (this.selected) {
          this.emitInput(this.selected)
        } else {
          if (this.strictMode) {
            const options = this.suggestions.filter(
              (o) => o[this.searchParam].indexOf(searchInput) > -1
            )
            if (options.length == 0) {
              this.$refs[this.refC].searchInput = ''
              this.emitInput(null)
            }
          } else {
            this.emitInput(searchInput)
          }
        }
      } else {
        this.emitInput(null)
      }
    }, 100),
    emitInput(selected) {
      return this.emitEvent('input', selected)
    },
    emitSelect(selected) {
      return this.emitEvent('select', selected)
    },
    emitEvent(event, selected) {
      const isSelectedTypeOfString = typeof selected == 'string'
      if (selected && this.returnParam) {
        if (isSelectedTypeOfString) {
          this.$emit(event, selected)
        } else {
          this.$emit(event, selected[this.returnParam])
        }
      } else {
        this.$emit(event, selected)
      }
    },
  },
}
</script>
<style lang="scss">
#c-auto-suggest__container {
  &.invalid {
    .autosuggest__input {
      border: 1px solid $pale-red;
      animation: animate-border 5s linear 0s infinite alternate;
    }
  }
  .autosuggest__input {
    outline: none;
    position: relative;
    display: block;
    width: 100%;
    border: 1px solid $clr-navy-50;
    border-radius: 4px;
    background-color: $white;
    font-size: 14px;
    padding: 10px 16px;
    height: 50px;
    color: $grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus {
      box-shadow: none;
      border-color: $clr-water;
    }
    &::placeholder {
      font-size: 1rem;
      color: $clr-navy-50;
      opacity: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:disabled {
      opacity: 0.5;
      color: $light-grey;
    }
  }
  .autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .autosuggest__results-container {
    position: relative;
    width: 100%;
    color: $grey;
    font-size: 14px;
  }
  .autosuggest__results {
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    max-height: 390px;
    overflow-y: scroll;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background: white;
    padding: 0;
    left: 0;
  }
  .autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  .autosuggest__results .autosuggest__results_item {
    cursor: pointer;
    padding: 12px 14px;
    border-top: 1px solid #dee2e6;
  }
  .autosuggest__results .autosuggest__results_title {
    margin-left: 0;
    padding: 15px 13px 5px;
  }
  .autosuggest__results .autosuggest__results_item:active,
  .autosuggest__results .autosuggest__results_item:hover,
  .autosuggest__results .autosuggest__results_item:focus,
  .autosuggest__results
    .autosuggest__results_item.autosuggest__results_item-highlighted {
    border-top: 1px solid $primary;
    + li {
      border-top: 1px solid $primary;
    }
  }
}
</style>
