<template>
  <div class="no-results">
    <div class="row d-none d-md-flex mt-3">
      <div class="col-4 col-xl-3">
        <div class="card-column"></div>
      </div>
      <div class="col-4 col-xl-3">
        <div class="card-column"></div>
      </div>
      <div class="col-4 col-xl-3">
        <div class="card-column"></div>
      </div>
      <div class="col-xl-3 d-none d-xl-block">
        <div class="card-column"></div>
      </div>
    </div>
    <div class="row">
      <div class="empty-recipe clearfix col-12 d-md-none">
        <div class="empty-recipe-thumb"></div>
        <div class="empty-recipe-line"></div>
        <div class="empty-recipe-line"></div>
      </div>
    </div>
    <div class="card-data">
      <h3>No personal recipes to show</h3>
      <span class="description d-none d-md-block">
        Add your own personal recipes to the recipe box.
      </span>
      <span class="description d-md-none">
        Personal recipes can be added to the recipe box from your desktop.
      </span>
      <MPButton
        class="mt-3 add-personal-recipe"
        size="large"
        color="primary"
        @click="addPersonalRecipe"
      >
        <i class="add-personal-recipe-icon"></i>
        Add personal recipe
      </MPButton>
    </div>
  </div>
</template>

<script>
import { events } from '@/utils/mixpanel.events'

export default {
  methods: {
    addPersonalRecipe() {
      this.$analytics.track(events.recipeBox.add)
      this.$router.push('/recipe/add')
    },
  },
}
</script>

<style lang="scss" scoped>
.no-results {
  position: relative;
}
img {
  display: block;
  margin: 0 auto;
  padding-top: 0.5rem;
}
.description {
  display: block;
  color: #404852;
  margin-top: 0.5rem;
  font-size: rem(14);
}

.card-column {
  border-radius: 5px;
  height: 270px;
  background-color: #d4d9e0;
  background: linear-gradient(
    to bottom,
    rgba(212, 217, 224, 1) 0%,
    rgba(212, 217, 224, 0) 80%
  );
}

.card-data {
  padding: 0 1rem 1rem;
  position: absolute;
  text-align: center;
  width: 100%;
  @include media-breakpoint-up(md) {
    top: 180px;
    padding: 1rem;
  }
}

.add-personal-recipe {
  display: none;
  justify-content: center;
  align-items: center;
  width: auto;
  @include media-breakpoint-up(md) {
    display: inline-flex;
  }
}

.add-personal-recipe-icon {
  display: inline-block;
  background-image: url('~@/assets/icons/personal-recipe-white.svg');
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.empty-recipe {
  width: 100%;
  background-color: white;
  clear: both;
  padding: 1rem 1rem 0;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      to bottom,
      rgba(#f7f7f7, 0) 0%,
      rgba(#f7f7f7, 1) 70%
    );
  }
}

.empty-recipe-thumb {
  width: 80px;
  height: 60px;
  border-radius: 4px;
  background-color: #d4d9e0;
  float: left;
  margin-right: 10px;
}

.empty-recipe-line {
  width: 171px;
  height: 13px;
  background-color: #d4d9e0;
  float: left;
  margin: 5px 0;
}
</style>
