<template>
  <div class="unsubscribe">
    <header class="header">
      <div class="row">
        <div class="col header-content">
          <a class="logo">
            <img
              class="logo-img"
              :src="require('@/assets/icons/forks-meal-planner-logo.svg')"
            />
          </a>

          <ul class="home-menu">
            <li><a href="https://support.forksmealplanner.com/">FAQ</a></li>

            <li><b-link to="/login" id="btn-login">Log in</b-link></li>

            <li>
              <b-link to="/register/" id="btn-join" class="button join-button">
                Get one, now
              </b-link>
            </li>
          </ul>
        </div>
      </div>
    </header>

    <div class="container unsubscribe-container">
      <div class="unsubscribe-panel panel">
        <MPAlert
          class="mt-3 mb-5 notification"
          v-if="alertText"
          type="outline"
          :text="alertText"
        ></MPAlert>

        <div class="row top-content" v-if="!userNotFound">
          <h3 class="col-12 col-md-7 no-padding-left columns">
            Manage Notification Preferences
          </h3>

          <div class="col-12 col-md-5 text-lg-right">
            <span class="email">{{ email }}</span>
          </div>
        </div>

        <hr v-if="!userNotFound" />

        <div class="row vertical-spacing" v-if="!userNotFound">
          <div class="col-9">
            <h4>Weekly meal plans</h4>

            <div class="info">
              An email letting you know which recipes are in your meal plan each
              week.
            </div>
          </div>

          <div class="col-3 text-right">
            <MPSwitch
              class="ml-3"
              :value="weeklyEmails"
              @change="(value) => toggleWeeklyEmails(value)"
            />
          </div>
        </div>

        <!--        <div class="row vertical-spacing" v-if="!userNotFound">-->

        <!--          <div class="col-9">-->

        <!--            <h4>Meal Planner tips & tricks</h4>-->

        <!--            <div class="info">-->

        <!--              Helpful guidance from our team. Learn about important features and-->

        <!--              time-saving techniques.-->

        <!--            </div>-->

        <!--          </div>-->

        <!--          <div class="col-3 text-right">-->

        <!--            <MPSwitch-->

        <!--              class="ml-3"-->

        <!--              :value="featuresEmails"-->

        <!--              @change="value => toggleFeaturesEmails(value)"-->

        <!--            />-->

        <!--          </div>-->

        <!--        </div>-->

        <!--        <div class="row vertical-spacing" v-if="!userNotFound">-->

        <!--          <div class="col-9">-->

        <!--            <h4>Forks Over Knives newsletter</h4>-->

        <!--            <div class="info">-->

        <!--              The main Forks Over Knives newsletter, with information on-->

        <!--              plant-based lifestyle.-->

        <!--            </div>-->

        <!--          </div>-->

        <!--          <div class="col-3 text-right">-->

        <!--            <MPSwitch-->

        <!--              class="ml-3"-->

        <!--              :value="generalEmails"-->

        <!--              @change="value => toggleGeneralEmails(value)"-->

        <!--            />-->

        <!--          </div>-->

        <!--        </div>-->

        <div class="row vertical-spacing bottom-content" v-if="!userNotFound">
          <div class="col-9">
            Want to delete your account?
            <b-link to="/settings">Log In</b-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeApi from '@/api/HomeApi'

export default {
  name: 'Unsubscribe',
  data() {
    return {
      userNotFound: true,
      email: '',
      weeklyEmails: false,
      featuresEmails: false,
      generalEmails: false,
      alertText: '',
      token: null,
      option: '',
    }
  },
  methods: {
    toggleWeeklyEmails(isSubscribed) {
      if (isSubscribed) {
        HomeApi.resubscribeWeeklyEmails(this.token).then(() => {
          this.alertText =
            'You have successfully resubscribed to Weekly Meal Plan emails.'
          this.weeklyEmails = isSubscribed
        })
      } else {
        HomeApi.unsubscribeWeeklyEmails(this.token).then(() => {
          this.alertText =
            'You have been unsubscribed from Weekly Meal Plan emails.'
          this.weeklyEmails = isSubscribed
        })
      }
    },
    toggleFeaturesEmails(isSubscribed) {
      if (isSubscribed) {
        HomeApi.resubscribeFeaturesEmails(this.token).then(() => {
          this.alertText =
            'You have successfully subscribed to Meal planner tips & tricks email.'
          this.featuresEmails = isSubscribed
        })
      } else {
        HomeApi.unsubscribeFeaturesEmails(this.token).then(() => {
          this.alertText =
            'You have been unsubscribed from Meal planner tips & tricks email.'
          this.featuresEmails = isSubscribed
        })
      }
    },
    toggleGeneralEmails(isSubscribed) {
      if (isSubscribed) {
        HomeApi.resubscribeGeneralEmails(this.token).then(() => {
          this.alertText =
            'You have successfully subscribed to Forks Over Knives newsletter email.'
          this.generalEmails = isSubscribed
        })
      } else {
        HomeApi.unsubscribeGeneralEmails(this.token).then(() => {
          this.alertText =
            'You have been unsubscribed from Forks Over Knives newsletter email.'
          this.generalEmails = isSubscribed
        })
      }
    },
  },
  mounted() {
    this.token = this.$route.params.token
    this.option = this.$route.params.option
    HomeApi.subscriptionInfo(this.token).then(
      (data) => {
        this.userNotFound = false
        this.email = data.email
        this.weeklyEmails = data.weeklyEmails
        this.featuresEmails = data.featuresEmails
        this.generalEmails = data.generalEmails

        if (this.option) {
          if (this.option === 'weekly-meal-plan') {
            this.toggleWeeklyEmails(false)
          } else if (
            this.option === 'activecampaign' ||
            this.option === 'general'
          ) {
            this.toggleGeneralEmails(false)
          } else if (this.option === 'intercom') {
            this.toggleFeaturesEmails(false)
          }
        }
      },
      () => {
        // failed to unsubscribe, invalid token
        this.userNotFound = true
        this.alertText =
          'We could not identify a user with this token. Please <a href="/login">log in</a> and set your email notification preferences manually in settings.'
      }
    )
  },
}
</script>

<style lang="scss" scoped>
.unsubscribe {
  .header {
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(116, 126, 139, 0.39);
    position: fixed;
    z-index: 2;
    width: 100%;
    text-align: center;

    .header-content {
      height: 86px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
    }

    .logo {
      max-width: 240px;
      cursor: pointer;
      @include media-breakpoint-up(md) {
        max-width: 309px;
      }
      .logo-img {
        user-select: none;
      }
    }

    .home-menu {
      list-style: none;
      margin: 0;
      display: flex;
      justify-content: space-between;

      li {
        margin-right: 2rem;
        font-weight: 500;
        font-size: 1rem;
        a {
          color: #757575;
        }
        .join-button {
          font-size: 1rem;
          height: 40px;
          padding: 10px 24px;
          color: #ff8e44 !important;
          border: 1px solid #ff8e44;
          background-color: #fff;
          border-radius: 100px;
        }
      }
    }
  }

  .unsubscribe-container {
    padding-top: 8rem;
    @include media-breakpoint-up(lg) {
      max-width: 688px;
    }

    .top-content {
      font-weight: 500;

      .email {
        font-size: 14px;
        line-height: 1.71;
      }
    }

    .vertical-spacing {
      margin-top: 2rem;
    }
  }
}
</style>
