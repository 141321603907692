export const MESSAGE = {
  ERROR: {
    OOPS: `Oops! looks like something went wrong.<a href="mailto:support@forksmealplanner.com">Contact us</a> if you are having trouble.`,
  },
}

export const ErrorMessage = (message, store) => {
  store.dispatch('registration/setRegistrationError', message)
}

export const getMessageDetail = (message, store) => {
  const CUSTOM_MESSAGE = {
    'Email already registered.': `Oops! Looks like this email address is already registered. Please <b-link to="${store.getters['globals/loginURL']}">log in</b-link>`,
    'Email is required.':
      'Oops! Looks like you were unable to log in with that email address. Try again or choose another method.',
    'Apple ID already registered!': `Oops! Looks like this Apple ID is already registered. Please <b-link to="${store.getters['globals/loginURL']}">log in</b-link>`,
    'Apple ID already registered.': `Oops! Looks like this Apple ID is already registered. Please <b-link to="${store.getters['globals/loginURL']}">log in</b-link>`,
    'Apple Id is required.':
      'Oops! Looks like you were unable to log in with Apple. Try again or choose another method.',
    'Invalid User.':
      'Oops! Looks like this user ID does not exist. Try logging in or using another email address.',
    'Account already exists': `Oops! Looks like this account is already registered. Please <b-link to="${store.getters['globals/loginURL']}">log in</b-link>`,
    'Invalid email':
      'Oops! Looks like this email address is invalid. Try again or choose another method.',
    'Facebook ID is already registered': `Oops! Looks like this Facebook account is already registered. Please <b-link to="${store.getters['globals/loginURL']}">log in</b-link>`,
    'Facebook ID is required.':
      'Oops! Looks like you were unable to log in with Facebook. Try again or choose another method.',
    'Google Id is required.':
      'Oops! Looks like you were unable to log in with Google. Try again or choose another method.',
    'Google ID already registered.': `Oops! Looks like this Google account is already registered. Please <b-link to="${store.getters['globals/loginURL']}">log in</b-link>`,
  }
  return CUSTOM_MESSAGE[message] ? CUSTOM_MESSAGE[message] : message
}
