<template>
  <v-tour :name="name" :steps="steps" :callbacks="callbacks" :options="options">
    <template v-slot:default="tour">
      <transition name="fade">
        <v-step
          v-if="tour.steps[tour.currentStep]"
          :key="tour.currentStep"
          :step="tour.steps[tour.currentStep]"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :finish="tour.finish"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
        >
          <div slot="header">
            <a class="close-tour" @click.prevent="skip(tour)">&#215;</a>
          </div>
          <div slot="actions">
            <div
              class="v-step__buttons"
              :class="{ 'flex-row-reverse': tour.isFirst }"
            >
              <!--<button @click.prevent="tour.stop" v-if="!tour.isLast" class="btn btn-light skip">{{ tour.labels.buttonSkip }}</button>-->
              <button
                @click.prevent="tour.previousStep"
                v-if="!tour.isFirst"
                class="btn btn-light previous"
              >
                {{ tour.labels.buttonPrevious }}
              </button>
              <div class="v-step__navigationbuttons">
                <button
                  @click.prevent="tour.nextStep"
                  v-if="!tour.isLast"
                  class="btn btn-primary next"
                >
                  {{ tour.labels.buttonNext }}
                </button>
                <button
                  @click.prevent="tour.finish"
                  v-if="tour.isLast"
                  class="btn btn-primary finish"
                >
                  {{ tour.labels.buttonStop }}
                </button>
              </div>
            </div>
          </div>
        </v-step>
      </transition>
    </template>
  </v-tour>
</template>

<script>
import { DEFAULT_CALLBACKS, DEFAULT_OPTIONS } from 'vue-tour'

export default {
  name: 'MPTour',
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
    },
    options: {
      type: Object,
      default: () => {
        return DEFAULT_OPTIONS
      },
    },
    callbacks: {
      type: Object,
      default: () => {
        return DEFAULT_CALLBACKS
      },
    },
  },
  methods: {
    skip(tour) {
      tour.skip()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-step__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    font-size: 14px;
    margin: 0 4px 0 4px;

    &.next,
    &.finish {
      margin-right: 0;
    }

    &.finish {
      justify-content: flex-end;
      margin-right: 0;
    }

    &.skip,
    &.previous {
      margin-left: 0;
      background-color: $lighter-grey;
      &:hover {
        background-color: $light-grey;
      }
    }
  }

  .v-step__navigationbuttons {
    display: flex;
    justify-content: space-between;
  }
}

.close-tour {
  color: #e5e5e5;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
