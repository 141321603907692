<template>
  <div class="register-select-servings-form mt-4 mx-md-auto mx-2" v-if="show">
    <h2>How many are you cooking for?</h2>
    <p class="mt-3 mt-md-4">
      Help us personalize your plan. You can always change this later.
    </p>
    <div class="servings-selector-container mt-4 mt-md-5">
      <MPServingsSelector
        class="portion"
        :servings="adultServings"
        @increased="adultServingsIncreased()"
        @decreased="adultServingsDecreased()"
        dataSpecInc="adult-increased"
        dataSpecDec="adult-decreased"
      />
      <MPServingsSelector
        class="portion"
        :servings="childServings"
        @increased="childServingsIncreased()"
        @decreased="childServingsDecreased()"
        type="child"
        dataSpecInc="child-increased"
        dataSpecDec="child-decreased"
      />
    </div>
    <ButtonNext class="next-button" @click="next()" data-spec="next-button" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MPServingsSelector } from '@/components/common/'
import ButtonNext from './ButtonNext'
import ExternalServices from '@/externalServices'
import { EVENTS } from '@/events/analytics'

export default {
  name: 'RegisterSelectServingsForm',
  components: {
    MPServingsSelector,
    ButtonNext,
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      adultServings: 'registration/adultServings',
      childServings: 'registration/childServings',
    }),
  },
  methods: {
    adultServingsIncreased() {
      this.$store.dispatch('registration/increaseAdultServings')
    },
    adultServingsDecreased() {
      this.$store.dispatch('registration/decreaseAdultServings')
    },
    childServingsIncreased() {
      this.$store.dispatch('registration/increaseChildServings')
    },
    childServingsDecreased() {
      this.$store.dispatch('registration/decreaseChildServings')
    },
    next() {
      this.$emit('onServingsSelected')
    },
  },
  mounted() {
    setTimeout(() => {
      let adultServings = ExternalServices.ABTests.registrationAdultServings
      if (adultServings) {
        this.$store.dispatch(
          'registration/setAdultServings',
          parseInt(adultServings)
        )
      }
      this.show = true
    }, 250)
    this.$posthog.sendEvent(EVENTS.registration.step2)
  },
}
</script>

<style lang="scss" scoped>
.register-select-servings-form {
  text-align: center;
  max-width: 710px;
  margin: 0 auto;
}

.servings-selector-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.portion {
  flex-direction: column;
  margin: 0;
  & + .portion {
    margin: 12px 0 0;
  }

  ::v-deep .title {
    margin: 0;
    padding: 0;
    font-size: $font-size-small;
  }

  @include media-breakpoint-up(md) {
    max-height: 165px;
    max-width: 165px;
    padding: 2.5rem 1.6rem;
    ::v-deep .title {
      font-size: 1rem;
    }

    & + .portion {
      margin: 0 0 0 24px;
    }
  }
}

.next-button {
  margin-top: 51px;
  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
}
</style>
