import Fraction from 'fraction.js'

export function toFraction(value) {
  // if this is not a decimal number, return back original value
  if (value % 1 == 0) {
    return value.toString()
  }

  value = String(value)
  let number = value.split('.')
  // performs special formatting required by fraction.js, which is to include the repeating number in parentheses
  if (number.length > 1 && number[1].slice(-1) === '3') {
    number = String(value + '(' + 3 + ')')
  } else if (number.length > 1 && number[1].slice(-1) === '6') {
    // covers 0.66
    number = String(value + '(' + 6 + ')')
  } else if (
    number.length > 1 &&
    (number[1].slice(-2) === '67' || number[1].slice(-2) === '65')
  ) {
    // covers 0.67 cases -> 0.6(6)
    number = String(value.slice(0, -1) + '(' + 6 + ')')
  } else {
    number = value
  }

  number = new Fraction(number).toFraction(true).split(' ')
  if (number.length > 1) {
    let integer = number[0]
    let fraction = number[1].split('/')
    return (
      integer +
      ' ' +
      "<span class='fraction'><sup>" +
      fraction[0] +
      '</sup>&frasl;<sub>' +
      fraction[1] +
      '</sub></span>'
    )
  } else {
    if (value < 1) {
      let fraction = number[0].split('/')
      return (
        "<span class='fraction'><sup>" +
        fraction[0] +
        '</sup>&frasl;<sub>' +
        fraction[1] +
        '</sub></span>'
      )
    }
  }
}

export function slugify(text) {
  /* eslint-disable */
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
  /* eslint-enable */
}
// Rounding mechanism from backend
function roundToCeil(amount) {
  var thirds = (Math.round(Math.ceil(amount * 3)) / 3).toFixed(2)
  var quarters = (Math.round(Math.ceil(amount * 4)) / 4).toFixed(2)
  if (Math.abs(amount - thirds) < Math.abs(amount - quarters)) {
    return thirds
  } else {
    return quarters
  }
}

function roundToRound(amount) {
  var thirds = (Math.round(Math.round(amount * 3)) / 3).toFixed(2)
  var quarters = (Math.round(Math.round(amount * 4)) / 4).toFixed(2)
  if (Math.abs(amount - thirds) < Math.abs(amount - quarters)) {
    return thirds
  } else {
    return quarters
  }
}

export function mpRound(amount) {
  if (amount < 0.24) {
    return amount
  }

  var ceil = roundToCeil(amount)
  var rnd = roundToRound(amount)

  if (Math.abs(amount - ceil) > Math.abs(amount - rnd)) {
    if (rnd != 0) {
      return rnd
    } else {
      return amount
    }
  } else {
    return ceil
  }
}

export function formatUpsell(upsell) {
  return {
    header: upsell.headline,
    description: upsell.description,
    confirm: upsell.button_label,
  }
}
