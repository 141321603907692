<template>
  <div
    class="favorite"
    :class="{ 'favorite--favorite': isFavorite }"
    @click="$emit('toggle-favorite')"
  >
    <HeartFilled v-if="isFavorite" class="icon" />
    <Heart v-else class="icon" />
  </div>
</template>

<script>
import { HeartFilled, Heart } from '@/components/common/MPIcons'
export default {
  components: {
    HeartFilled,
    Heart,
  },
  props: {
    isFavorite: {
      type: Boolean,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.favorite {
  display: block;
  width: 33px;
  height: 33px;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid $clr-navy;
  color: $clr-navy-15;
  text-align: center;
  &--favorite,
  &:hover {
    border: 1px solid $body-color;
    background-color: $body-color;
    .icon {
      color: $white;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }

  .icon {
    color: $clr-navy;
    width: 17px;
    height: 100%;
  }
}
</style>
