<template>
  <div class="delete-instruction">
    <CredentialsHeader>
      <template v-slot:left>
        <a
          v-if="showBackToFok"
          href="https://www.forksoverknives.com/"
          class="back-link"
        >
          <ArrowBack />

          <span class="d-lg-none">&nbsp;Back</span>

          <span class="d-none d-lg-inline">
            &nbsp;Back to www.forksoverknives.com
          </span>
        </a>
      </template>

      <template v-slot:center>
        <HeaderLogo />
      </template>

      <template v-slot:right v-if="!noSignUp">
        <div class="header-text d-none d-lg-block">
          <span class="text">
            Don't have an account?
            <b-link @click="goToSignUp()" data-spec="try-free">
              Get one, now
            </b-link>
          </span>
        </div>

        <div class="header-text d-block d-lg-none">
          <b-link @click="goToSignUp()">Sign up</b-link>
        </div>
      </template>
    </CredentialsHeader>

    <div class="container">
      <p class="delete-instruction-content">
        We have received your request to delete your Forks Meal Planner data
        from our system. To completely delete your Forks Meal Planner account,
        go to
        <a href="/settings/billing">
          <span>Settings</span>
          >
          <span>Billing</span>
        </a>
        and click on the
        <span>DELETE</span>
        button.
        <br />
        Please note that if you have an active subscription you'll need to
        cancel it first, in order to be able to delete your account.
      </p>

      <div class="row">
        <div class="col-12">
          <img :src="require('@/assets/images/how_delete.png')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CredentialsHeader from '@/components/credentials/CredentialsHeader'
import HeaderLogo from '@/components/credentials/HeaderLogo'
import { ArrowBack } from '@/components/common/MPIcons'
import { events } from '@/utils/mixpanel.events'
import { mapGetters } from 'vuex'

export default {
  components: {
    CredentialsHeader,
    HeaderLogo,
    ArrowBack,
  },
  computed: {
    ...mapGetters({
      creditCardRequired: 'headers/creditCardRequired',
    }),
  },
  data() {
    return {
      noSignUp: false,
    }
  },
  props: {
    showBackToFok: {
      default: false,
    },
  },
  methods: {
    goToSignUp() {
      this.$analytics.track(events.join.clicked, {
        location: window.location.href,
      })
      this.$analytics.track(events.join.clicked, {
        location: window.location.href,
      })
      this.$router.push('/register/')
    },
    onLoggedIn() {
      let afterLoginURL = this.$routeQuery.get('redirect') || '/plan'
      if (this.creditCardRequired) {
        afterLoginURL = '/reactivate'
      }
      this.$router.replace(afterLoginURL)
    },
  },
  mounted() {
    this.$analytics.track(events.landingPage.view)
    this.noSignUp = this.$routeQuery.get('nosignup') === 'true'
  },
}
</script>

<style lang="scss" scoped>
.delete-instruction {
  @include signup-bg;

  &-content {
    text-align: justify;
    padding: 15% 0;
    font-size: $h3-font-size;

    span {
      color: $link-color;
    }

    a {
      text-decoration: underline;
    }
  }
}
</style>
