<template>
  <MPButton class="a-button" color="white" size="large" @click="handleClick">
    <div class="button-content">
      <img :src="require('@/assets/icons/apple.svg')" class="icon" />
      <span class="text"><slot></slot></span>
    </div>
  </MPButton>
</template>

<script>
import MPButton from '@/components/common/MPButton'
import { events } from '@/utils/mixpanel.events'

export default {
  components: {
    MPButton,
  },
  name: 'MPAppleButton',
  methods: {
    handleClick() {
      this.$analytics.track(events.register.saveMealPlan.socialLogin, {
        social_login: 'apple',
      })
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.a-button {
  max-height: 50px;
  background: $white;
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
  position: relative;
  width: 100%;
  border: solid 1px $black;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  margin-right: 12px;

  @include media-breakpoint-down(sm) {
    margin-left: auto;
  }
}

.text {
  @include media-breakpoint-down(sm) {
    margin-right: auto;
  }
}
</style>
