var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mp-alert"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.text || _vm.$slots.default)?_c('div',{staticClass:"alert fade show",class:{
        'alert-dismissible': _vm.isDismissable,
        'alert-danger': _vm.type == 'error',
        'alert-success': _vm.type == 'success',
        'alert-outline': _vm.type == 'outline',
        'alert-warning': _vm.type == 'warning',
      },attrs:{"role":"alert"}},[(_vm.text)?_c('v-runtime-template',{attrs:{"template":`<span>${_vm.text}</span>`}}):_vm._e(),_vm._t("default"),(_vm.isDismissable)?_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('dismiss')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]):_vm._e()],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }