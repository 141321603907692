<template>
  <div class="signup">
    <CredentialsHeader>
      <template v-slot:left>
        <h1
          v-if="!isInitialRegistrationStep"
          class="registration-back-button"
          @click="goBack()"
        ></h1>
      </template>
      <template v-slot:center>
        <HeaderLogo v-if="isInitialRegistrationStep" />
        <RegistrationSteps
          v-else
          :steps="steps"
          :currentRegistrationStep="currentReverseRegistrationStep"
          @onStepChanged="onStepChanged"
        ></RegistrationSteps>
      </template>
      <template v-slot:right>
        <div class="header-text d-none d-md-block">
          <span class="text">
            Already have an account?
            <b-link @click="goToSignIn()">Sign in</b-link>
          </span>
        </div>
        <div class="header-text d-block d-md-none">
          <b-link @click="goToSignIn()">Sign in</b-link>
        </div>
      </template>
    </CredentialsHeader>
    <div class="credential-wrapper">
      <router-view
        @onServingsSelected="onServingsSelected"
        @onIntolerancesSelected="onIntolerancesSelected"
        @onMealsSelected="onMealsSelected"
      ></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CredentialsHeader from '@/components/credentials/CredentialsHeader'
import HeaderLogo from '@/components/credentials/HeaderLogo'
import RegistrationSteps from '@/components/credentials/register/RegistrationSteps'
import credentialsHandler from '@/handlers/CredentialsHandler'
import LoadingMixin from '@/mixins/LoadingMixin'
import { events } from '@/utils/mixpanel.events'

export default {
  mixins: [LoadingMixin],
  name: 'Register',
  components: {
    CredentialsHeader,
    HeaderLogo,
    RegistrationSteps,
  },
  computed: {
    ...mapGetters({
      steps: 'registration/reverseRegistrationSteps',
      currentReverseRegistrationStep:
        'registration/currentReverseRegistrationStep',
      getReverseRegistrationStepsToResume:
        'registration/getReverseRegistrationStepsToResume',
      adultServings: 'registration/adultServings',
      childServings: 'registration/childServings',
      soyIntolerance: 'registration/soyIntolerance',
      glutenIntolerance: 'registration/glutenIntolerance',
      nutsIntolerance: 'registration/nutsIntolerance',
      breakfastOptin: 'registration/breakfastOptin',
      lunchOptin: 'registration/lunchOptin',
      dinnerOptin: 'registration/dinnerOptin',
      snackOptin: 'registration/snackOptin',
      dessertOptin: 'registration/dessertOptin',
      additionalIntolerances: 'registration/additionalIntolerances',
      email: 'registration/email',
      fullName: 'registration/fullName',
      password: 'registration/password',
      generalEmailsOptin: 'registration/generalEmailsOptin',
      loginURL: 'globals/loginURL',
    }),
    isInitialRegistrationStep() {
      return this.$route.path.match(/(signup\/?$|signup\/1$)/)
    },
  },
  methods: {
    goToSignIn() {
      this.$analytics.track('mp sign in click')
      this.$router.push(this.loginURL)
    },
    goBack() {
      this.$store.dispatch('registration/previousReverseRegistrationStep')
    },
    goToNextStep() {
      this.$store.dispatch('registration/setReverseRegistrationStepDone')
      this.$store.dispatch('registration/nextReverseRegistrationStep')
    },
    onServingsSelected() {
      this.$analytics.track(events.register.servingPage.nextClicked)
      this.goToNextStep()
    },
    onIntolerancesSelected() {
      this.$analytics.track(events.register.allergiesPage.nextClicked)
      this.goToNextStep()
    },
    onMealsSelected() {
      this.setLoading(true)
      credentialsHandler
        .savePreferences(
          this.email,
          this.adultServings,
          this.childServings,
          this.additionalIntolerances,
          this.generalEmailsOptin,
          this.soyIntolerance,
          this.nutsIntolerance,
          this.glutenIntolerance,
          this.breakfastOptin,
          this.lunchOptin,
          this.dinnerOptin,
          this.snackOptin,
          this.dessertOptin
        )
        .then(
          () => {
            credentialsHandler.activatePlan(this.$store, this.email).then(
              () => {
                this.setLoading(false)
                this.goToNextStep()
                this.$router.replace('/welcome')
              },
              (err) => {
                // TODO error handling
                this.$store.dispatch('registration/setRegistrationError', err)
                this.setLoading(false)
              }
            )
          },
          (err) => {
            // TODO error handling
            this.$store.dispatch('registration/setRegistrationError', err)
            this.setLoading(false)
          }
        )
    },
    onStepChanged(stepIndex) {
      this.$store.dispatch('registration/setReverseRegistrationStep', stepIndex)
    },
  },
  watch: {
    currentReverseRegistrationStep: {
      handler: function (value) {
        this.$router.push(`/signup/${this.steps[value - 1].path}`)
      },
      immediate: true,
    },
    $route: {
      handler: function (to) {
        let urlStep = parseInt(to.path.match(/\d+/).join(''))

        if (this.currentRegistrationStep !== urlStep) {
          this.onStepChanged(urlStep)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const stepsToResume = this.getReverseRegistrationStepsToResume
    if (stepsToResume) {
      const goToStep = stepsToResume[0]
      if (goToStep) {
        this.$store.dispatch(
          'registration/setReverseRegistrationStep',
          goToStep.index
        )
      }
    } else {
      this.$analytics.track(events.loginPage)
      this.$analytics.track(events.register.goalsPage.view)
    }
  },
}
</script>

<style lang="scss" scoped>
.header-text {
  font-size: 12px;
  font-weight: 400;
}
.credential-wrapper {
  padding: 64px 20px 20px;
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
}
.registration-back-button {
  width: 16px;
  height: 9px;
  background: url('~@/assets/icons/icon-arrow-back-orange.svg') no-repeat center;
  background-position-y: 20px;
  cursor: pointer;
  padding: 20px;
}
</style>
