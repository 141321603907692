<template>
  <transition name="button">
    <button
      v-show="showButton"
      class="scroll-to-top-button"
      @click="scrollToTop"
    ></button>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showButton: false,
    }
  },
  methods: {
    scrollToTop() {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' })
    },
    onScroll() {
      this.showButton = window.pageYOffset > 0
    },
  },
  mounted() {
    this.scrollListener = window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  },
}
</script>

<style lang="scss" scoped>
.scroll-to-top-button {
  position: fixed;
  display: block;
  background-color: transparent;
  background-repeat: no-repeat;
  border-radius: 50%;
  right: 30px;
  bottom: 110px;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  background-image: url('~@/assets/icons/back-to-top-default.svg'),
    url('~@/assets/icons/back-to-top-hover.svg');
  background-position-x: 0, 40px;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &:hover {
    background-position-x: 40px, 0;
  }
}

.button-enter-active,
.button-leave-active {
  transition: opacity 0.2s;
}
.button-enter,
.button-leave-to {
  opacity: 0;
}
</style>
