<template>
  <button class="toggle-btn container" @click="onToggleClick()">
    <i class="filter-icon mr-2" />
    <span class="toggle-text-large"> Show Filters </span>
    <span v-if="appliedFilters" class="mx-2">|</span>
    <!-- Separate element because of spacing -->
    <span v-if="appliedFilters"> {{ appliedFilters }} Selected </span>
  </button>
</template>

<script>
export default {
  props: {
    appliedFilters: {
      type: Number,
      required: false,
    },
  },
  methods: {
    onToggleClick() {
      this.$emit('toggle-open')
    },
  },
}
</script>

<style lang="scss" scoped>
.toggle-btn {
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #3b3d43;
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
}

.toggle-text-large {
  font-weight: 500;
  font-size: 1rem;
  text-transform: uppercase;
}

.filter-icon {
  display: block;
  width: 15px;
  height: 15px;
  background-image: url('~@/assets/icons/filtering/filter-navy.svg');
  background-position: center;
  background-repeat: no-repeat;
}
</style>
