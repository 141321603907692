import apiDashboard from '@/api/dashboard'
import DashboardBuilder from '@/components/dashboard/DashboardBuilder'

import * as types from '../mutation-types'

import _ from 'lodash'

// initial state
const state = {
  mealPlan: null,
  userWeeklyPlanId: null,
  // dashboard
  dashboardStatus: null,
  dashboard: null,
  // swap
  lastUndoAction: null,
  // dashboard meals
  mealsStatus: null,
  mealsVersion: null,
  meals: null,
  // list
  maxMealsInDashboard: 12, // TODO: load from dashboard modal
}

// getters
const getters = {
  title: (state) => {
    if (state.dashboard) {
      return state.dashboard.title
    }
    return null
  },
  dashboard: (state) => {
    return state.dashboard
  },
  dashboardPlan: (state) => {
    let recipes = state.meals || []
    let listsNumber = state.maxMealsInDashboard

    // Order by day and position in day.
    recipes = DashboardBuilder.recipesReflow(recipes.filter((r) => r.inPlan))
    // Empty plan
    let dashboardPlan = DashboardBuilder.getEmptyDashboardPlan(listsNumber)
    recipes.forEach((recipe) => {
      // Recipe to list item
      let recipeListItem = DashboardBuilder.toDashboardListItem(recipe)
      // Add list item to plan
      DashboardBuilder.addListItemToDashboardPlan(
        recipeListItem,
        recipe.day,
        dashboardPlan
      )
    })

    // Hide "Add Recipe" if day is full.
    dashboardPlan.forEach((list) => {
      if (list.items.length >= state.maxMealsInDashboard) {
        list.actionStatus = 'hidden'
      }
    })
    return dashboardPlan
  },
  recipeDashboardPlan(state, getters, rootState, rootGetters) {
    let dashboardPlan = getters.dashboardPlan.concat(
      DashboardBuilder.getEmptyDashboardPlan(
        state.maxListsInDashboard - getters.dashboardPlan.length
      )
    )
    const currentRecipeId = _.get(rootGetters['recipe/recipe'], 'id')
    dashboardPlan.forEach((list) => {
      list.items = list.items.map((item) => {
        return {
          ...item,
          isSelected: item.recipeId === currentRecipeId,
        }
      })
    })
    return dashboardPlan
  },
  meals: (state) => state.meals,
  planStatus: (state) => !!(state.dashboardStatus || state.mealsStatus),
  lastUndoAction(state) {
    return state.lastUndoAction
  },
}

// actions
const actions = {
  refreshDashboard({ dispatch, commit }, { mealPlan, userWeeklyPlanId }) {
    commit(types.SET_MEAL_PLAN, mealPlan)
    commit(types.SET_USER_WEEKLY_PLAN_ID, userWeeklyPlanId)
    return Promise.all([dispatch('getDashboard'), dispatch('getMeals')])
  },
  getDashboard({ commit, state }) {
    commit(types.SET_DASHBOARD_STATUS, 'LOADING')
    const data = {
      user_weekly_plan_id: state.userWeeklyPlanId,
    }
    return apiDashboard
      .getDashboard({ type: state.mealPlan }, data)
      .then((dashboardPayload) => {
        const dashboard = dashboardPayload.camelized
        commit(types.SET_DASHBOARD_STATUS, null)
        commit(types.RECEIVE_DASHBOARD, dashboard)
        return dashboard
      })
  },
  getMeals({ commit, state }) {
    commit(types.SET_DASHBOARD_MEALS_STATUS, 'LOADING')

    const data = {
      user_weekly_plan_id: state.userWeeklyPlanId,
    }
    return apiDashboard
      .getMeals({ type: state.mealPlan }, data)
      .then((meals) => {
        commit(types.SET_DASHBOARD_MEALS_STATUS, null)
        commit(types.RECEIVE_DASHBOARD_MEALS, meals)

        return meals
      })
  },
  loadSampleMealPlan({ dispatch, rootGetters }) {
    const switchToPlan = rootGetters['dashboard/mealPlanItems'].find(
      (item) => item.isSample
    )
    dispatch('refreshDashboard', {
      mealPlan: switchToPlan.value,
      userWeeklyPlanId: switchToPlan.userWeeklyPlanId,
    })
  },
  removeMeal({ commit, state }, mealId) {
    commit(types.SET_DASHBOARD_MEALS_STATUS, 'LOADING')

    let meals = state.meals.find((meal) => meal.id === mealId)
    let { day, mealType, recipe } = meals

    let options = {
      meal_plan_type: state.mealPlan,
      user_weekly_plan_id: state.userWeeklyPlanId,
      recipe_id: recipe.id,
      day: day,
      meal_type: mealType,
      version: state.mealsVersion,
    }

    return apiDashboard.removeMeal(options).then((meals) => {
      commit(types.RECEIVE_DASHBOARD_MEALS, meals)
      commit(types.SET_DASHBOARD_MEALS_STATUS, null)
      commit(types.SET_LAST_UNDO_ACTION, {
        action: 'addOrSwapMeal',
        actionData: {
          day,
          recipeId: recipe.id,
        },
        description: `Meal removed from Day ${day + 1}`,
      })
      return meals
    })
  },
  addOrSwapMeal({ state, commit }, { recipeId, day, mealType }) {
    commit(types.SET_DASHBOARD_MEALS_STATUS, 'LOADING')

    let mealOnPosition = state.meals.find(
      (meal) => meal.day === day && meal.mealType === mealType
    )

    let nextMealType = state.meals
      .filter((meal) => meal.day === day)
      .map(({ mealType }) => mealType)
      .reduce((maxNumber, number) => Math.max(maxNumber, number + 1), 0)

    let options = {
      meal_plan_type: state.mealPlan,
      user_weekly_plan_id: state.userWeeklyPlanId,
      recipe_id: recipeId,
      day: day,
      meal_type: mealType != null ? mealType : nextMealType,
      replace: !!mealOnPosition,
      version: state.mealsVersion,
    }

    return apiDashboard.addMeal(options).then((meals) => {
      commit(types.RECEIVE_DASHBOARD_MEALS, meals)
      commit(types.SET_DASHBOARD_MEALS_STATUS, null)
      if (!mealOnPosition) {
        commit(types.SET_LAST_UNDO_ACTION, {
          action: 'removeMeal',
          actionData: meals.recipe.id,
          description: `Meal added to Day ${day + 1}`,
        })
      } else {
        commit(types.SET_LAST_UNDO_ACTION, {
          action: 'addOrSwapMeal',
          actionData: {
            recipeId: mealOnPosition.recipe.id,
            day,
            mealType,
          },
          description: `Meal swapped out successfully`,
        })
      }
      return meals
    })
  },
  applyUndo({ state, commit, dispatch }) {
    if (!state.lastUndoAction) return
    const { action, actionData } = state.lastUndoAction
    return dispatch(action, actionData).then(() => {
      commit(types.SET_LAST_UNDO_ACTION, null)
    })
  },
}

// mutations
const mutations = {
  [types.SET_MEAL_PLAN](state, mealPlan) {
    state.mealPlan = mealPlan
  },
  [types.SET_USER_WEEKLY_PLAN_ID](state, userWeeklyPlanId) {
    state.userWeeklyPlanId = userWeeklyPlanId
  },
  [types.SET_DASHBOARD_TITLE](state, title) {
    state.dashboard.title = title
  },
  [types.SET_DASHBOARD_STATUS](state, status) {
    state.dashboardStatus = status
  },
  [types.RECEIVE_DASHBOARD](state, dashboard) {
    state.dashboard = dashboard
  },
  [types.SET_DASHBOARD_MEALS_STATUS](state, status) {
    state.mealsStatus = status
  },
  [types.RECEIVE_DASHBOARD_MEALS](state, meals) {
    state.mealsVersion = meals.version
    state.meals = meals.userRecipes
  },
  [types.SET_LAST_UNDO_ACTION](state, lastUndoAction) {
    state.lastUndoAction = lastUndoAction
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
