import { render, staticRenderFns } from "./UpsellPromoModal.vue?vue&type=template&id=67738e82&scoped=true"
import script from "./UpsellPromoModal.vue?vue&type=script&lang=js"
export * from "./UpsellPromoModal.vue?vue&type=script&lang=js"
import style0 from "./UpsellPromoModal.vue?vue&type=style&index=0&id=67738e82&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67738e82",
  null
  
)

export default component.exports