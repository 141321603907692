<template>
  <countdown class="countdown" :time="time" :transform="transform">
    <template slot-scope="props">
      {{ props.text }}
    </template>
  </countdown>
</template>

<script>
import countdown from '@chenfengyuan/vue-countdown'
import externalServices from './../../externalServices'

export default {
  components: {
    countdown,
  },
  props: {
    date: {
      type: Date,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
  },
  computed: {
    time() {
      return this.date - Date.now()
    },
  },
  methods: {
    transform(props) {
      if (externalServices.ABTests.showBVersionCountdown) {
        props.text = getCountdownTextB(props, this.discount)
      } else {
        props.text = getCountdownText(props)
      }
      return props
    },
  },
}

function getCountdownText({ days, totalHours, hours, totalMinutes, minutes }) {
  const daysText = getTimeText('days', days)
  const hoursText = getTimeText('hours', hours)
  const minutesText = getTimeText('minutes', minutes)

  if (totalHours >= 24 && hours) return `${daysText}, ${hoursText} left`
  else if (totalHours >= 24) return `${daysText} left to claim discount`
  else if (totalHours) return `${hoursText} left to claim discount`
  else if (totalMinutes) return `${minutesText} left to claim discount`
  else return null
}

function getCountdownTextB(
  { days, totalHours, hours, totalMinutes, minutes },
  discount
) {
  const daysText = getTimeText('days', days)
  const minutesText = getMinutesText('minutes', minutes)

  if (totalHours >= 24)
    return `${daysText.replace('day', 'Day')} Left to Save ${discount}%`
  else if (totalHours && totalHours < 24)
    return `${discount}% Off Expires in ${hours}h`
  else if (totalMinutes) return `${discount}% Off Expires in ${minutesText}`
  else return null
}

function getTimeText(key, value) {
  const word = value < 2 ? key.replace(/s$/, '') : key
  return `${value} ${word}`
}

function getMinutesText(key, value) {
  const digits = value < 10 ? `0${value}` : value
  return `0:${digits}m`
}
</script>

<style lang="scss" scoped>
.countdown {
  color: #ff4e4e;
  font-size: 1rem;
}
</style>
