<template>
  <transition name="side-bar">
    <div id="disqus-sidebar" class="disqus-side-bar" v-if="isSideBarVisible">
      <header>
        <img
          :src="sideBarUser.avatar"
          :alt="sideBarUser.name"
          class="author-image"
        />
        <div v-text="sideBarUser.name" class="author-name"></div>
        <div class="sidebar-close-button" @click="closeSideBar()">×</div>
      </header>
      <main v-if="sideBarPosts">
        <div v-for="(thread, i) in sideBarPosts" :key="i">
          <a :href="thread[0].recipeUrl" target="_blank" class="thread-name">
            {{ thread[0].threadName }}
          </a>
          <DisqusPostItem v-for="(post, i) in thread" :key="i" :post="post" />
          <hr />
        </div>
      </main>
      <div v-else class="text-center">
        <div class="spinner-border text-success mt-5" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <footer v-if="sideBarPage && sideBarPosts" class="mt-2">
        <div class="d-flex justify-content-center">
          <a
            tabindex="-1"
            v-if="sideBarPage.hasPrev"
            @click="loadPage(sideBarUser, sideBarPage.prev)"
          >
            Previous
          </a>
          <span v-if="sideBarPage.hasPrev && sideBarPage.hasNext">
            &nbsp;|&nbsp;
          </span>
          <a
            tabindex="-1"
            v-if="sideBarPage.hasNext"
            @click="loadPage(sideBarUser, sideBarPage.next)"
          >
            Next
          </a>
        </div>
      </footer>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import DisqusPostItem from '@/components/recipe/disqus/DisqusPostItem'

export default {
  name: 'DisqusSideBar',
  components: { DisqusPostItem },
  computed: {
    ...mapState('Disqus', ['sideBarUser', 'isSideBarVisible', 'sideBarPage']),
    ...mapGetters('Disqus', ['sideBarPosts']),
  },
  methods: {
    ...mapActions('Disqus', ['closeSideBar', 'loadUserPosts']),
    loadPage(user, cursor) {
      this.loadUserPosts({ user, cursor })
    },
  },
}
</script>

<style lang="scss" scoped>
.side-bar-enter-active,
.side-bar-leave-active {
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.side-bar-enter,
.side-bar-leave-to {
  transform: translate(100%, 0);
}
.page-control {
  color: $orange;
}
.disqus-side-bar {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: $white;
  z-index: 1;
  box-shadow: 0 -2px 4px 0 rgba(129, 129, 129, 0.5);
  @include media-breakpoint-up(sm) {
    width: 600px;
  }
}
header {
  background-color: $lighter-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  .author-name {
    flex-grow: 1;
    font-size: 1rem;
    font-weight: bold;
  }
  .sidebar-close-button {
    font-size: 2rem;
    font-weight: 400;
    cursor: pointer;
  }
}
main {
  padding: 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 88%;
}
.thread-name {
  font-size: 1.125rem;
  font-weight: 500;
}
</style>

<style lang="scss">
#disqus-sidebar {
  .author-image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 1.4375rem;
  }
}
</style>
