var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dashboardPlan",attrs:{"id":"dashboard-plan"}},[_vm._l((_vm.planLocalDisplayed),function(list,listIndex){return _c('div',{key:listIndex,staticClass:"list",on:{"mousedown":_vm.onMouseDown,"touchstart":_vm.onTouchStart}},[_c('span',{staticClass:"list-title"},[_vm._v(" DAY "+_vm._s(listIndex + 1)+" ")]),_c('div',{staticClass:"draggable-wrapper",class:{ 'draggable-wrapper--dragging': _vm.movingId }},[_c('Draggable',{staticClass:"draggable",attrs:{"options":{
          group: 'list',
          animation: 150,
          delay: _vm.isTouchDevice ? 500 : 0,
          draggable: '.draggable-item',
        },"data-id":listIndex,"force-fallback":true,"scroll-sensitivity":200},on:{"end":_vm.onEnd,"start":_vm.onStart},model:{value:(list.items),callback:function ($$v) {_vm.$set(list, "items", $$v)},expression:"list.items"}},_vm._l((list.items),function(item,itemIndex){return _c(item.componentName,{key:item.id,tag:"component",class:{ 'draggable-item': item.isDraggable },attrs:{"data":item},on:{"onOpenItemClick":_vm.onOpenItemClick,"onSwapOutItemClick":_vm.onSwapOutItemClick,"onRemoveItemClick":_vm.onRemoveItemClick,"addRecipe":function($event){return _vm.addRecipe(listIndex, itemIndex)}}})}),1),(
          !list.items.length ||
          (list.items.length === 1 &&
            _vm.movingId &&
            _vm.movingItemListId === listIndex)
        )?_c('div',{staticClass:"drop-text",on:{"click":function($event){return _vm.addRecipe(listIndex, list.items.length)}}},[_vm._m(0,true)]):_vm._e(),(list.actionStatus !== 'hidden')?_c('a',{staticClass:"add-recipe",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.addRecipe(listIndex, list.items.length)}}},[_vm._v(" + ADD RECIPE ")]):_vm._e()],1)])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAddList),expression:"showAddList"}],staticClass:"add-list-wrapper"},[(_vm.planLocal[_vm.addListNumberIndex])?_c('Draggable',{staticClass:"add-list",attrs:{"group":"list","animation":150,"data-id":_vm.addListNumberIndex},on:{"end":_vm.onEnd,"start":_vm.onStart},model:{value:(_vm.planLocal[_vm.addListNumberIndex].items),callback:function ($$v) {_vm.$set(_vm.planLocal[_vm.addListNumberIndex], "items", $$v)},expression:"planLocal[addListNumberIndex].items"}}):_vm._e(),_c('span',{staticClass:"add-list-text",class:{ 'add-list-text--on-dragging': _vm.movingId },on:{"click":_vm.addDay}},[_c('span',[_vm._v("Drag and drop a recipe here, or "),_c('br'),_c('span',{staticClass:"add-day-text"},[_vm._v("+ add day "+_vm._s(_vm.addListNumber))])])])],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v(" Drag and drop a recipe here, or "),_c('u',[_vm._v("add")]),_vm._v(" a recipe. ")])
}]

export { render, staticRenderFns }