<template>
  <div class="register-select-meals-form">
    <h1>
      Which meals would you<br />
      like to schedule?
    </h1>
    <p class="mt-3 mt-md-4">
      You can always change this later, so pick as many as you want to start
      with.
    </p>
    <div class="meals-form-container mt-4 mt-md-5">
      <MPSelectBox
        class="meals-option py-3 py-md-5"
        title="Breakfast"
        :image="require('@/assets/icons/onboarding-breakfast.svg')"
        :isSelected="breakfastOptin"
        @click="breakfastClicked()"
      ></MPSelectBox>
      <MPSelectBox
        class="meals-option py-3 py-md-5"
        title="Lunch"
        :image="require('@/assets/icons/onboarding-lunch.svg')"
        :isSelected="lunchOptin"
        @click="lunchClicked()"
      ></MPSelectBox>
      <MPSelectBox
        class="meals-option py-3 py-md-5"
        title="Dinner"
        :image="require('@/assets/icons/onboarding-dinner.svg')"
        :isSelected="dinnerOptin"
        @click="dinnerClicked()"
      ></MPSelectBox>
      <MPSelectBox
        class="meals-option py-3 py-md-5"
        title="Snack & Desserts"
        :image="require('@/assets/icons/onboarding-dessert.svg')"
        :isSelected="snackOptin"
        @click="snackClicked()"
      ></MPSelectBox>
    </div>
    <ButtonNext class="next-button my-4 my-md-5" @click="next()" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonNext from './ButtonNext'
import { MPSelectBox } from '@/components/common'

export default {
  name: 'RegisterSelectAllMealsForm',
  components: {
    MPSelectBox,
    ButtonNext,
  },
  computed: {
    ...mapGetters({
      breakfastOptin: 'registration/breakfastOptin',
      lunchOptin: 'registration/lunchOptin',
      dinnerOptin: 'registration/dinnerOptin',
      snackOptin: 'registration/snackOptin',
    }),
  },
  methods: {
    next() {
      this.$analytics.track('VWO test', {
        data: `
          ${this.breakfastOptin},
          ${this.lunchOptin},
          ${this.dinnerOptin},
          ${this.snackOptin}
        `,
      })
      this.$emit('onMealsSelected')
    },
    breakfastClicked() {
      if (this.breakfastOptin && this.numberOfSelectedMeals() == 1) {
        return
      }
      this.$store.dispatch(
        'registration/setBreakfastOptin',
        !this.breakfastOptin
      )
    },
    lunchClicked() {
      if (this.lunchOptin && this.numberOfSelectedMeals() == 1) {
        return
      }
      this.$store.dispatch('registration/setLunchOptin', !this.lunchOptin)
    },
    dinnerClicked() {
      if (this.dinnerOptin && this.numberOfSelectedMeals() == 1) {
        return
      }
      this.$store.dispatch('registration/setDinnerOptin', !this.dinnerOptin)
    },
    snackClicked() {
      if (this.snackOptin && this.numberOfSelectedMeals() == 1) {
        return
      }
      const newValue = !this.snackOptin
      this.$store.dispatch('registration/setSnackOptin', newValue)
      this.$store.dispatch('registration/setDessertOptin', newValue)
    },
    numberOfSelectedMeals() {
      let count = 0
      count += 1 & this.breakfastOptin
      count += 1 & this.lunchOptin
      count += 1 & this.dinnerOptin
      count += 1 & this.snackOptin
      return count
    },
  },
  mounted() {
    this.$analytics.setUserProperties({ VWOTest: 'Meal types options' })
  },
}
</script>

<style lang="scss" scoped>
.register-select-meals-form {
  text-align: center;
  min-width: 696px;
  max-width: 960px;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    min-width: 100%;
    max-width: 100%;
  }

  .meals-form-container {
    margin-top: 0.5rem;
    position: relative;
    display: flex;
    text-align: center;
    align-items: center;

    .meals-option {
      @include media-breakpoint-down(sm) {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  .next-button {
    max-width: 10rem;
  }
}
</style>
