<template>
  <div class="upgrade">
    <Sidebar />
    <div class="premium-close-group">
      <a :href="fokLandingUrl" class="premium-close" aria-label="Close"> × </a>
    </div>
    <section class="upgrade-container">
      <div class="upgrade-content container">
        <h1 class="premium-title" v-html="title"></h1>
        <div class="upgrade-cards-container">
          <span class="login-form-title">{{ loginFormTitle }}</span>
          <span class="login-form-title-mobile">
            {{ loginFormTitleMobile }}
          </span>
          <LoginForm :title="null" @onLoggedIn="onLoggedIn"></LoginForm>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Sidebar from '@/components/subscriptions/Sidebar'
import LoginForm from '@/components/credentials/login/LoginForm.vue'
import env from '@/environment'
import { mapGetters } from 'vuex'

export default {
  components: {
    Sidebar,
    LoginForm,
  },
  data() {
    return {
      title: 'Join Forks Meal Planner and Make Mealtime a Breeze!',
      loginFormTitle: 'Please log into your account to continue',
      loginFormTitleMobile:
        'Start by logging in to your Forks Meal Planner account to upgrade.',
    }
  },
  computed: {
    ...mapGetters({
      isActive: 'user/isActive',
    }),
    fokLandingUrl() {
      return env.fokLandingUrl
    },
  },
  methods: {
    onLoggedIn() {
      let redirectUrl = this.isActive ? '/plan' : '/reactivate'
      let afterLoginURL = this.$routeQuery.get('redirect') || redirectUrl
      this.$router.replace(afterLoginURL)
    },
    onDismiss() {},
  },
  mounted() {
    this.$analytics.track('mp become premium page view')
  },
}
</script>

<style lang="scss" scoped>
.upgrade {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.upgrade-container {
  flex: 1;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  background-image: url('~@/assets/images/premium-page-background.jpg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: auto 1200px;
  order: 1;
  display: flex;
  @include media-breakpoint-up(md) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem + 1rem; // 1rem for title line-heigh space
  }
  @include media-breakpoint-up(lg) {
    order: 2;
  }
}

.upgrade-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: 740px;
  }
}

.premium-title {
  text-align: center;
  display: block;
  color: #fff;
}

.upgrade-cards-container {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 1rem;
  margin-top: 20px;
  @include media-breakpoint-up(md) {
    margin-top: 40px;
    padding: 2rem;
  }
}

.login-form-title-mobile {
  display: block;
  text-align: center;
  font-weight: 500;
  padding-bottom: 1rem;
  font-size: 0.875rem;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.login-form-title {
  display: none;
  text-align: center;
  font-weight: 500;
  padding-bottom: 1.5rem;
  font-size: 1rem;
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.premium-close-group {
  position: absolute;
  top: 0;
  right: 0;
  @include media-breakpoint-up(md) {
    padding: 5px 25px 55px 55px;
    border-bottom-left-radius: 50%;
  }
}

.premium-close {
  font-size: 36px;
  padding: 0 15px;
  color: #ffffff;
  @include media-breakpoint-up(md) {
    padding: 15px;
    font-size: 42px;
  }
}
</style>

<style lang="scss">
.upgrade {
  .error-card {
    a {
      color: #ff4e4e;
      text-decoration: underline;
    }
  }
}
</style>
