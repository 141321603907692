<template>
  <div>
    <!-- Promotion/Affiliate offer EXPIRED banner -->
    <MPNotification
      v-if="showBanner && bannerType === 'error'"
      type="error"
      class="full"
      :title="bannerMessage"
      :showCloseButton="true"
      @onCloseActionSelect="closeBanner"
    />
    <!-- BANNER: user successfully applied a discount from the offer -->
    <MPNotification
      v-if="showBanner && bannerType === 'offer'"
      type="offer"
      :image="require('@/assets/icons/check-green.svg')"
      class="full-with-navbar add-lr-padding"
      :showCloseButton="true"
      @onCloseActionSelect="closeBanner"
    >
      <span>{{ bannerMessage }}.</span>
    </MPNotification>
  </div>
</template>

<script>
import LoadingMixin from '@/mixins/LoadingMixin'
import HomeApi from '@/api/HomeApi'

export default {
  mixins: [LoadingMixin],
  props: {
    isLoggedIn: { required: true, type: Boolean },
  },
  data() {
    return {
      showBanner: false,
      bannerType: null,
      bannerMessage: null,
    }
  },
  mounted() {
    this.checkPromoOfferCode()
  },
  methods: {
    async checkPromoOfferCode() {
      const PROMO_OFFER_CODE_KEY = 'offer'
      let promoOfferCode = this.$routeQuery.get(PROMO_OFFER_CODE_KEY)
      if (!promoOfferCode) {
        return
      }

      if (this.isLoggedIn) {
        this.setLoading(true)
        const request = await HomeApi.checkPromoOfferCode(promoOfferCode)
        this.setLoading(false)
        this.showBanner = true
        this.bannerType = request.success ? 'offer' : 'error'
        this.bannerMessage = request.message
        this.$store.dispatch('user/refreshPlan')
      }
    },
    closeBanner() {
      this.showBanner = false
      this.bannerType = null
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'premium') {
        this.checkPromoOfferCode()
      }
    },
  },
}
</script>
