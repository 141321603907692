<template>
  <div class="login">
    <CredentialsHeader>
      <template v-slot:left>
        <a
          v-if="showBackToFok"
          href="https://www.forksoverknives.com/"
          class="back-link"
        >
          <ArrowBack />

          <span class="d-lg-none">&nbsp;Back</span>

          <span class="d-none d-lg-inline">
            &nbsp;Back to www.forksoverknives.com
          </span>
        </a>
      </template>

      <template v-slot:center>
        <HeaderLogo />
      </template>

      <template v-slot:right v-if="!noSignUp">
        <div class="header-text d-none d-lg-block">
          <span class="text">
            Don't have an account?
            <b-link @click="goToSignUp()" data-spec="try-free">
              Get one now
            </b-link>
          </span>
        </div>

        <div class="header-text d-block d-lg-none">
          <b-link @click="goToSignUp()">Sign up</b-link>
        </div>
      </template>
    </CredentialsHeader>

    <div class="credential-wrapper">
      <LoginForm @onLoggedIn="onLoggedIn"></LoginForm>
    </div>
  </div>
</template>

<script>
import CredentialsHeader from '@/components/credentials/CredentialsHeader'
import HeaderLogo from '@/components/credentials/HeaderLogo'
import LoginForm from '@/components/credentials/login/LoginForm.vue'
import { events } from '@/utils/mixpanel.events'
import { ArrowBack } from '@/components/common/MPIcons'
import { mapGetters } from 'vuex'

export default {
  components: {
    CredentialsHeader,
    HeaderLogo,
    LoginForm,
    ArrowBack,
  },
  computed: {
    ...mapGetters({
      creditCardRequired: 'headers/creditCardRequired',
    }),
  },
  data() {
    return {
      noSignUp: false,
    }
  },
  props: {
    showBackToFok: {
      default: false,
    },
  },
  methods: {
    goToSignUp() {
      this.$analytics.track(events.join.clicked, {
        location: window.location.href,
      })
      this.$analytics.track(events.join.clicked, {
        location: window.location.href,
      })
      this.$router.replace('/register/')
    },
    onLoggedIn() {
      const queryParameters = {}
      if (this.$route.query['promotional']) {
        queryParameters['promotional'] = this.$route.query['promotional']
      }
      let afterLoginURL = this.$routeQuery.get('redirect') || '/plan'
      if (this.creditCardRequired) {
        afterLoginURL = '/reactivate'
      }

      this.$router.replace({ path: afterLoginURL, query: queryParameters })
    },
  },
  mounted() {
    this.$analytics.track(events.landingPage.view)
    this.noSignUp = this.$routeQuery.get('nosignup') === 'true'
  },
}
</script>

<style lang="scss" scoped>
.login {
  @include signup-bg;
}

.header-text {
  font-size: $h4-font-size;
}

.credential-wrapper {
  padding: 51px 20px 20px;
  min-height: calc(100vh - #{$signup-top-section-height});

  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
}

.sign-in-header-logo {
  display: flex;
  width: 290px;

  img {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    width: 180px;
  }
}

.back-link {
  color: $body-color;

  &:hover {
    color: $text-muted;
  }
}
</style>
