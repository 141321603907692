import { mapActions } from 'vuex'
import svgError from '@/assets/icons/error.svg'

const NotificationsMixin = {
  methods: {
    ...mapActions({
      createActionNotification: 'notifications/setActionNotification',
      createInfoNotification: 'notifications/setInfoNotification',
      closeNotification: 'notifications/clearNotification',
      cancelNotificationClose: 'notifications/cancelTimeout',
    }),
    errorNotification(component, isFull = false, closeAfter = 5) {
      this.createInfoNotification({
        icon: svgError,
        color: 'red',
        closeAfter,
        html: component,
        full: isFull,
      })
    },
  },
}

export default NotificationsMixin
