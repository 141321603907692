<template>
  <div class="header-card row justify-content-between">
    <MPNotification
      class="full full-with-navbar"
      v-if="undoAction"
      :title="undoAction.description"
      type="action"
      actionText="Undo"
      closeActionText="View my plan"
      @onActionSelect="undoActionApply"
      @onCloseActionSelect="goToPlan"
    />
    <div class="col-12 title-wrapper">
      <h1
        class="header-title"
        v-if="recipe.title && !isLoading"
        v-html="recipe.title"
      />
      <RecipeLoadingItem class="header-title" v-if="isLoading" />
      <div @click="$emit('edit')">
        <Edit
          class="edit-recipe ml-1"
          v-if="recipe.isCustom && !isLoading && !isLocked"
        />
      </div>
      <RecipeFavorite
        class="ml-auto"
        :is-favorite="recipe.isFavorite"
        @toggle-favorite="toggleFavorite"
        v-if="!isLocked"
      />
    </div>
    <div class="w-100" />
    <span
      class="col header-subtitle"
      v-if="recipe.subtitle"
      v-html="recipe.subtitle"
    ></span>
    <div class="header-actions col-12" v-if="!isLocked">
      <div class="row align-items-end">
        <div class="col rating-comments-wrapper">
          <div class="rating-wrapper">
            <RatingStars
              :user-rating="recipe.rating"
              :community-rating="recipe.communityRating"
              @rate="rateRecipe"
            />
          </div>
          <RecipeCommentCount
            :comments-number="numberOfRecipeComments"
            @click.native="goToComments()"
            v-if="!isCustom"
          />
        </div>
        <div class="add-btn-wrapper col" v-if="!isLoading">
          <button
            class="btn btn-primary btn-lg add-btn"
            @click="showAddToPlanModal = true"
            v-if="recipe"
          >
            <Plus /> Add to plan
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 recipe-actions" v-if="!isLocked">
      <RecipeActions :recipe="recipe" @delete="$emit('delete')" />
    </div>
    <RecipeFeedbackModal :show.sync="showRecipeFeedbackModal" />
    <AddToPlanModal
      :show.sync="showAddToPlanModal"
      @undo-action="setUndoAction"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AddToPlanModal from './AddToPlanModal'
import RecipeFavorite from './RecipeFavorite'
import RecipeFeedbackModal from './RecipeFeedbackModal'
import RatingStars from './RatingStars'
import RecipeCommentCount from './RecipeCommentCount'
import RecipeActions from './RecipeActions'
import RecipeLoadingItem from './RecipeLoadingItem'

import { Plus, Edit } from '@/components/common/MPIcons'
import EVENTS from '@/events/analytics'

export default {
  components: {
    AddToPlanModal,
    RecipeFavorite,
    RecipeFeedbackModal,
    RatingStars,
    RecipeCommentCount,
    RecipeActions,
    RecipeLoadingItem,
    Plus,
    Edit,
  },
  props: {
    isLocked: {
      default: false,
    },
    isLoading: {
      default: false,
    },
    isCustom: {
      default: false,
    },
  },
  data() {
    return {
      showRecipeFeedbackModal: false,
      showAddToPlanModal: false,
      undoAction: null,
      undoActionTimeout: null,
    }
  },
  computed: {
    ...mapGetters({
      recipe: 'recipe/recipe',
      numberOfRecipeComments: 'Disqus/commentsCount',
    }),
  },
  methods: {
    toggleFavorite() {
      this.$store.dispatch('recipe/toggleFavorite')
      this.$intercom.trackEvent('FAVORITED')
      const payload = {
        recipe: this.recipe.title,
        action: this.recipe.isFavorite ? 'favorite' : 'unfavorite',
      }
      this.$posthog.sendEvent(EVENTS.recipe.favorite, payload)
    },
    rateRecipe(rate) {
      this.showRecipeFeedbackModal = true
      this.$store.dispatch('recipe/rateRecipe', rate)
    },
    goToComments() {
      document.getElementById('disqus-comments').scrollIntoView()
    },
    setUndoAction(undoAction) {
      clearTimeout(this.undoActionTimeout)
      this.undoAction = undoAction
      this.undoActionTimeout = setTimeout(() => {
        this.undoAction = null
      }, 40000)
    },
    undoActionApply() {
      const { dispatchAction, actionData } = this.undoAction
      this.$store.dispatch(dispatchAction, actionData)
      this.undoAction = null
    },
    goToPlan() {
      this.$router.push('/plan')
    },
  },
}
</script>

<style lang="scss" scoped>
.header-card {
  background-color: $white;
}
.title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.header-title {
  font-size: 36px;
  line-height: 42px;
  min-height: 42px;
  font-weight: bold;
  width: fit-content;
  @include media-breakpoint-down(md) {
    font-size: 30px;
    line-height: 38px;
    min-height: 38px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 26px;
    line-height: 32px;
    min-height: 32px;
  }
}

.header-subtitle {
  font-size: 22px;
  line-height: 28px;
  @include media-breakpoint-down(md) {
    font-size: 20px;
    line-height: 24px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

.header-actions {
  margin: 1rem 0 0.4rem;
  min-height: 50px;
}

.rating-comments-wrapper {
  @include media-breakpoint-down(xs) {
    padding-right: 0;
  }
}

.rating-wrapper {
  float: left;
  margin-right: 15px;

  @include media-breakpoint-down(sm) {
    float: none;
  }

  @include media-breakpoint-down(xs) {
    margin-right: 0;
  }
}

.add-btn-wrapper {
  text-align: right;

  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }
}

.add-btn {
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    padding-left: 9px;
    padding-right: 9px;
  }

  @include media-breakpoint-down(xs) {
    padding-left: 7px;
    padding-right: 7px;
    font-size: rem(16);
  }
}

.edit-recipe {
  padding: 0;
  margin: 0;
  width: 2rem;
  height: auto;
  cursor: pointer;
  color: $link-color;
  svg {
    vertical-align: sub;
  }
}
</style>
