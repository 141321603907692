import apiRecipes from '@/api/recipes'
import * as types from '@/store/mutation-types'
import _ from 'lodash'
import IngredientHelpers from '@/IngredientHelpers'
import moment from 'moment'
import listItems from '@/components/recipebox/listItemsBuilder'

const state = {
  recipeDetails: null,
  unitList: null,
  categoryList: null,
  ingredientList: null,
  prepDescriptionList: null,
  publicUrl: null,
  copiedUrl: null,
}

const getters = {
  recipe: (state) => buildRecipe(state.recipeDetails),
  recipeViewType: (state) => {
    if (!state.recipeDetails) return 'loading'
    const { isLocked, isCustom } = state.recipeDetails
    if (isLocked) return 'locked'
    if (isCustom) return 'custom'
    return 'recipe'
  },
  adjustServings: (state) => buildAdjustServings(state.recipeDetails),
  unitList: (state) => {
    return _.uniqBy(state.unitList, function (unit) {
      return unit.name
    })
  },
  ingredientList: (state) => state.ingredientList,
  prepDescriptionList: (state) => state.prepDescriptionList,
  getPublicUrl: (state) => state.publicUrl,
  getCopiedUrl: (state) => state.copiedUrl,
}

const actions = {
  loadRecipe({ commit, dispatch }, { recipeId, token }) {
    commit(types.SET_RECIPE_DETAILS, null)
    return dispatch('updateRecipe', { recipeId, token })
  },
  updateRecipe({ commit, dispatch, rootGetters }, { recipeId, token }) {
    const {
      'globals/mealPlan': mealPlan,
      'globals/userWeeklyPlanId': userWeeklyPlanId,
    } = rootGetters

    return apiRecipes
      .getRecipeDetails({ recipeId, token }, mealPlan, userWeeklyPlanId)
      .then((recipeDetails) => {
        commit(types.SET_RECIPE_DETAILS, recipeDetails)
        if (!recipeDetails.isCustom) {
          rootGetters['user/userDataInitPromise'].then(() => {
            dispatch('Disqus/initDisqus', state.recipeDetails, { root: true })
          })
        }
      })
  },
  toggleFavorite({ state, commit }) {
    const { id, favorite } = state.recipeDetails
    commit(types.SET_RECIPE_FAVORITE, !favorite)
    commit(
      `RecipeBox/${types.SET_LOCAL_FAVORITE}`,
      { recipeId: id, isFavorite: !favorite },
      { root: true }
    )
    return apiRecipes.updateFavorite(id, favorite)
  },
  setFavorite({ state, commit }, favorite) {
    const { id } = state.recipeDetails
    commit(types.SET_RECIPE_FAVORITE, favorite)
    return apiRecipes.updateFavorite(id, favorite)
  },
  setCopiedUrl({ commit }, copiedUrl) {
    commit(types.SET_COPIED_URL, copiedUrl)
  },
  rateRecipe({ state, commit }, rating) {
    const { id } = state.recipeDetails
    commit(types.SET_RECIPE_RATING, rating)
    return apiRecipes.updateRecipeRating(id, rating)
  },
  recipeFeedback({ state }, data) {
    const { id } = state.recipeDetails
    return apiRecipes.recipeFeedback(id, data)
  },
  applyServingsChangesFromForm({ state, dispatch }, formData) {
    if (state.recipeDetails.mealPlan) {
      const servingsData = state.recipeDetails.mealPlan.days.map(
        (day, index) => ({
          user_recipe_id: day.id,
          servings: formData[index] || day.servings,
        })
      )
      return dispatch('changeQuantity', servingsData)
    } else {
      return dispatch('getRecipeByServings', formData[0])
    }
  },
  getRecipeByServings({ state, commit }, servings) {
    const { id } = state.recipeDetails
    return apiRecipes
      .getByServings(id, servings)
      .then((recipeDetails) => commit(types.SET_RECIPE_DETAILS, recipeDetails))
  },
  changeQuantity({ state, dispatch }, servingsData) {
    const { id } = state.recipeDetails
    return apiRecipes.changeQuantity(servingsData).then(({ version }) => {
      dispatch('dashboard/setMealsVersion', version, { root: true })
      dispatch('updateRecipe', { recipeId: id })
    })
  },
  getPrintFile({ state, rootGetters }) {
    const data = {
      recipeId: state.recipeDetails.id,
      mealPlan: rootGetters['globals/mealPlan'],
      userWeeklyPlanId: rootGetters['globals/userWeeklyPlanId'],
      servings: state.recipeDetails.servings,
      recipePrintImage: rootGetters['user/printRecipeImage'],
    }

    const fileName = `${state.recipeDetails.title}.pdf`
    return apiRecipes.getRecipePrint(data).then(({ blob, fileUrl }) => {
      return {
        blob,
        fileUrl,
        fileName,
      }
    })
  },
  saveNote({ state }, note) {
    const { id } = state.recipeDetails
    state.recipeDetails.recipeNotes = [{ ...note }]
    return apiRecipes.setRecipeNotes(id, note)
  },
  async initSystemData({ dispatch }) {
    await dispatch('getIngredientList')
    await dispatch('getUnitList')
    await dispatch('getCategoryList')
    await dispatch('getPrepDescriptionList')
  },
  async getUnitList({ commit, state }) {
    const backup = state.unitList
    commit(types.SET_UNIT_LIST.PENDING)
    try {
      const res = await apiRecipes.getUnitList()
      commit(types.SET_UNIT_LIST.SUCCESS, res)
      return res
    } catch (err) {
      commit(types.SET_UNIT_LIST.ERROR, backup)
      return err
    }
  },
  async getCategoryList({ commit, state }) {
    const backup = state.categoryList
    commit(types.SET_CATEGORY_LIST.PENDING)
    try {
      const res = await apiRecipes.getCategoryList()
      commit(types.SET_CATEGORY_LIST.SUCCESS, res)
      return res
    } catch (err) {
      commit(types.SET_CATEGORY_LIST.ERROR, backup)
      return err
    }
  },
  async getIngredientList({ commit, state }) {
    const backup = state.ingredientList
    commit(types.SET_INGREDIENT_LIST.PENDING)
    try {
      const res = await apiRecipes.getIngredientList()
      commit(types.SET_INGREDIENT_LIST.SUCCESS, res)
      return res
    } catch (err) {
      commit(types.SET_INGREDIENT_LIST.ERROR, backup)
      return err
    }
  },
  async dismissServingsInfo() {
    await apiRecipes.dismissServingsInfo()
  },
  async addBonusRecipe({ dispatch }, id) {
    try {
      const res = await apiRecipes.addBonusRecipe(id)
      await dispatch('RecipeBox/refresh', null, { root: true })
      return res
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  async getPrepDescriptionList({ commit, state }) {
    const backup = state.prepDescriptionList
    commit(types.SET_PREP_DESCRIPTION_LIST.PENDING)
    try {
      const res = await apiRecipes.getPrepDescriptionList()
      commit(types.SET_PREP_DESCRIPTION_LIST.SUCCESS, res)
      return res
    } catch (err) {
      commit(types.SET_PREP_DESCRIPTION_LIST.ERROR, backup)
      throw err
    }
  },
  async createTokenPublicRecipe({ commit, state }) {
    commit(types.SET_CREATE_PUBLIC_RECIPE.PENDING)

    try {
      const { id, servings } = state.recipeDetails
      const res = await apiRecipes.createTokenPublicRecipe(id, servings)

      commit(types.SET_CREATE_PUBLIC_RECIPE.SUCCESS, res)
      return res
    } catch (err) {
      commit(types.SET_CREATE_PUBLIC_RECIPE.ERROR)
      // throw err
    }
  },
}

const mutations = {
  [types.SET_RECIPE_DETAILS](state, recipeDetails) {
    state.recipeDetails = recipeDetails
  },
  [types.SET_RECIPE_FAVORITE](state, status) {
    state.recipeDetails.favorite = status
  },
  [types.SET_RECIPE_RATING](state, rating) {
    state.recipeDetails.rating = rating
  },
  [types.SET_UNIT_LIST.SUCCESS](state, payload) {
    let unitList = []
    const searchTextMapper = ['searchableText', 'metricSearchableText']
    searchTextMapper.forEach((search) => {
      payload.forEach((element) => {
        const searchableText = element[search].split(',')
        const organizedUnits = searchableText.map((m) => {
          return {
            id: element.id,
            name: m,
            isMetric: search === 'metricSearchableText',
          }
        })
        unitList = unitList.concat(organizedUnits)
      })
    })
    state.unitList = unitList
  },
  [types.SET_UNIT_LIST.PENDING](state) {
    state.unitList = []
  },
  [types.SET_UNIT_LIST.ERROR](state, backup) {
    state.unitList = backup
  },
  [types.SET_CATEGORY_LIST.SUCCESS](state, payload) {
    state.categoryList = payload
  },
  [types.SET_CATEGORY_LIST.PENDING](state) {
    state.categoryList = []
  },
  [types.SET_CATEGORY_LIST.ERROR](state, backup) {
    state.categoryList = backup
  },
  [types.SET_INGREDIENT_LIST.SUCCESS](state, payload) {
    state.ingredientList = payload
  },
  [types.SET_INGREDIENT_LIST.PENDING](state) {
    state.ingredientList = []
  },
  [types.SET_INGREDIENT_LIST.ERROR](state, backup) {
    state.ingredientList = backup
  },
  [types.SET_PREP_DESCRIPTION_LIST.SUCCESS](state, payload) {
    state.prepDescriptionList = payload
  },
  [types.SET_PREP_DESCRIPTION_LIST.PENDING](state) {
    state.prepDescriptionList = null
  },
  [types.SET_PREP_DESCRIPTION_LIST.ERROR](state, backup) {
    state.prepDescriptionList = backup
  },
  [types.SET_CREATE_PUBLIC_RECIPE.SUCCESS](state, payload) {
    state.publicUrl = payload
  },
  [types.SET_CREATE_PUBLIC_RECIPE.PENDING](state) {
    state.publicUrl = null
  },
  [types.SET_CREATE_PUBLIC_RECIPE.ERROR](state) {
    state.publicUrl = null
  },
  [types.SET_COPIED_URL](state, payload) {
    state.copiedUrl = payload
  },
}

function buildRecipe(r) {
  if (!r) return {}
  if (r.isLocked)
    return {
      title: r.title,
      subtitle: r.subtitle,
      image: r.thumbXlarge,
    }

  const recipe = {
    id: r.id,
    title: r.title,
    description: r.content,
    servingsText: r.servingsText,
    subtitle: r.subtitle,
    image: r.thumbLarge,
    rating: r.rating,
    communityRating: listItems.getCommunityRating(r.communityRating),
    isFavorite: r.favorite,
    equipment: r.equipment,
    time: r.defaultPrepTime,
    iconTime: getIconTime(r.defaultPrepTime % 60 || 60),
    tips: getTips(r),
    stepsGroups: getStepsGroups(r.steps),
    ingredientsGroup: r.ingredients.reduce(buildIngredientGroups, {}),
    suggestedServing: r.suggestedServing,
    nutritionalInfo: getNutritionalInfo(r.nutritionalInfo),
    credits: r.credits,
    servings: IngredientHelpers.toFraction(r.servings),
    suggestedServingForPrint: getSuggestedServingForPrint(
      r.suggestedServingForPrint
    ),
    isCustom: r.isCustom,
    titleSlug: slugify(r.title),
    url: getRecipeUrl(r),
    hasIngredients: hasIngredients(r),
    notes: r.recipeNotes.length ? r.recipeNotes : [{ text: '', public: false }],
    adminNotes: r.recipeNotes.map((note) => ({
      avatar: note.userAvatar,
      title: `${note.userName} (${moment
        .unix(note.addedAt)
        .format('MMMM DD yy')})`,
      text: note.text,
    })),
    feedbacks: (r.recipeFeedbacks || []).map((f) => ({
      avatar: f.userAvatar,
      title: `${f.userName} (${moment
        .unix(f.dateCreated)
        .format('MMMM DD yy')})`,
      text: f.comment,
      flavorRating: f.flavorRating,
      servingSizeRating: f.servingSizeRating,
      textureRating: f.textureRating,
      timingRating: f.timingRating,
    })),
    // tips
    freezeTips: r.freezeTips,
    reheatTips: r.reheatTips,
    storageTip: r.storageTip,
    numberOfServings2: r.numberOfServings2,
    placeholderImage: r.thumbMedium,
  }

  return recipe
}

function buildAdjustServings(r) {
  if (!r) return {}
  const {
    mealPlan,
    isBatchRecipe,
    minimumBatchServings,
    servings,
    batchMakesQty,
    batchMakesUnit,
  } = r

  // Adjust servings for non batch meal plan recipes
  if (!!mealPlan && !isBatchRecipe) {
    return {
      title: 'Adjust servings',
      servings: mealPlan.days.map((meal) => ({
        label: `Day ${meal.day}`,
        canDecrease: meal.servings > minimumBatchServings,
        value: meal.servings,
        servingsFraction: IngredientHelpers.toFraction(meal.servings),
      })),
      minimumBatchServings: minimumBatchServings,
    }
    // Adjust servings for batch meal plan recipes
  } else if (!!mealPlan && !!isBatchRecipe) {
    const servingsValue = roundToNearestBatchServing(
      mealPlan.days[0].servings,
      minimumBatchServings
    )
    const days = _.uniq(mealPlan.days.map((day) => day.day)).sort((day) => day)
    const daysString = days.join(', ').replace(/,([^,]*)$/, ' & $1')
    const label = `Day${days.length > 1 ? 's' : ''} ${daysString}`

    return {
      title: 'Adjust total servings',
      servings: [
        {
          label: label,
          canDecrease: servingsValue > minimumBatchServings,
          value: servingsValue,
          servingsFraction: IngredientHelpers.toFraction(servingsValue),
        },
      ],
      description: `
        Note: This is a batch recipe which increases in multiples of ${minimumBatchServings} servings (${batchMakesQty} ${batchMakesUnit}).
      `,
      minimumBatchServings: minimumBatchServings,
    }
    // Adjust servings for non meal plan recipes
  } else if (!r.mealPlan) {
    return {
      title: 'Adjust servings',
      servings: [
        {
          label: 'Servings',
          canDecrease: servings > minimumBatchServings,
          value: servings,
          servingsFraction: IngredientHelpers.toFraction(servings),
        },
      ],
      minimumBatchServings: minimumBatchServings,
    }
  } else {
    return {}
  }
}

function roundToNearestBatchServing(servings, minimumServings) {
  if (!servings) return 0
  return (
    Math.ceil(parseFloat(servings) / parseFloat(minimumServings)) *
    parseFloat(minimumServings)
  )
}

function hasIngredients({ ingredients }) {
  return ingredients && ingredients.length > 0 ? true : false
}

function slugify(value) {
  return value.toLowerCase().replace(/\s{1,}/g, '-')
}

function getRecipeUrl({ id, title }) {
  return `/recipe/${id}/${slugify(title)}`
}

function getSuggestedServingForPrint(serving) {
  return serving ? `Suggested adult serving: ${serving}` : null
}

function getNutritionalInfo(nutritionalInfo) {
  let res = {
    data: [],
    hasInfo: !!nutritionalInfo,
    message: 'Nutritional Information Unavailable',
  }

  if (nutritionalInfo) {
    res.data = nutritionalInfo.split(';').map((item) => {
      const data = item.split(':')
      return {
        label: formatNutritionalInfoItem(data[0]),
        text: formatNutritionalInfoItem(data[1]),
      }
    })
  }

  return res
}

function formatNutritionalInfoItem(data) {
  return (data || '').toString().trimStart().trimEnd()
}

function getIconTime(time) {
  return Math.max(10, Math.ceil(time / 5) * 5)
}

function getStepsGroups(steps) {
  const othr = steps
    .filter((step) => step.type === 'othr')
    .map((step, index) => buildStep(step, index + 1))

  const prep = steps
    .filter((step) => step.type === 'prep')
    .map((step, index) => buildStep(step, othr.length + index + 1))

  const cook = steps
    .filter((step) => step.type === 'cook')
    .map((step, index) =>
      buildStep(step, othr.length + prep.length + index + 1)
    )

  return [
    {
      title: null,
      items: othr,
    },
    {
      title: 'GET READY',
      items: prep,
    },
    {
      title: 'BRING IT TOGETHER',
      items: cook,
    },
  ].filter((group) => group.items.length)
}

function getTips(recipe) {
  let ingredientsTipsAll = recipe.ingredients
    .filter((ingredient) => ingredient.ingredient.tip)
    .map(
      ({ ingredient }) =>
        `<strong>${_.startCase(ingredient.singularName)}:</strong> ${
          ingredient.tip
        }`
    )
  return [recipe.tips, ..._.uniq(ingredientsTipsAll)].filter((i) => i)
}

function buildStep(step, stepNumber) {
  return {
    number: stepNumber,
    title: step.heading,
    description: step.parsedDescriptions,
    isSpeedPrep: step.isSpeedPrep,
  }
}

function buildIngredientGroups(groups, ingredient) {
  const groupName = ingredient.grouppingIn
  if (!groups[groupName]) groups[groupName] = []
  groups[groupName].push({
    title: getIngredientTitle(ingredient),
    description: getIngredientDescription(ingredient),
    isSpeedPrep: ingredient.isSpeedPrep,
  })
  return groups
}

function getIngredientTitle(ingredient) {
  const quantities = IngredientHelpers.getCompleteQuantities(
    0,
    ingredient.quantities
  )
  const name = IngredientHelpers.getCompleteName(ingredient)
  return `${quantities} ${name}`
}

function getIngredientDescription(ingredient) {
  const volume = IngredientHelpers.getVolume(ingredient)

  return [ingredient.prepDescription || '', volume ? `(≈${volume})` : ''].join(
    ' '
  )
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
