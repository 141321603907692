<template>
  <transition name="overlay">
    <div
      class="mp-overlay"
      :class="`mp-overlay--${type}`"
      v-if="show"
      @click="$emit('click', $event)"
    ></div>
  </transition>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    disableScroll() {
      document.body.classList.add('mp-overlay-open')
    },
    enableScroll() {
      document.body.classList.remove('mp-overlay-open')
    },
  },
  watch: {
    show() {
      this.show ? this.disableScroll() : this.enableScroll()
    },
  },
  destroyed() {
    this.enableScroll()
  },
}
</script>

<style lang="scss" scoped>
.mp-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #212529;
}

.mp-overlay--dark {
  opacity: 0.5;
  z-index: 1;
}

.mp-overlay--dim {
  color: #25272b;
  opacity: 0.9;
}

.mp-overlay--light {
  opacity: 0.2;
  z-index: 1;
}

.mp-overlay-enter-active,
.mp-overlay-leave-active {
  transition: opacity 0.5s;
}
.mp-overlay-enter,
.mp-overlay-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.mp-overlay-open {
  overflow: hidden;
}
</style>
