<template>
  <MPModal class="text-center" :show.sync="showModal" :show-x="true">
    <form @submit.prevent="submitFeedback">
      <img
        class="d-none d-md-block m-auto"
        src="@/assets/images/recipe-feedback-form.svg"
      />
      <h4 class="mt-3">Recipe Feedback Form</h4>
      <span
        v-if="recipe.rating <= 4"
        class="form-description"
        v-html="descriptions[recipe.rating - 1]"
      ></span>
      <!-- VRuntimeTemplate is not working -->
      <span class="form-description" v-if="recipe.rating == 5">
        Awesome! Want to
        <a href @click.prevent="addToFavorites">favorite</a> this recipe?
      </span>
      <div class="mt-2 mx-auto d-inline-block">
        <RatingStars
          :user-rating="recipe.rating"
          :community-rating="recipe.communityRating"
          @rate="rateRecipe"
        />
      </div>
      <div class="form-group mt-2">
        <div
          class="mt-2"
          v-for="(item, feedbackIndex) in feedbackItems"
          :key="feedbackIndex"
        >
          <h5>{{ item.title }}</h5>
          <div class="options">
            <MPButton
              class="option"
              :class="{
                'option--selected': option.isSelected,
                'option--focus-out': option.isFocusOut,
              }"
              v-for="(option, optionIndex) in item.options"
              :key="optionIndex"
              @click="selectOption(feedbackIndex, optionIndex)"
            >
              <i
                class="option-icon"
                :style="{ backgroundImage: `url(${option.image})` }"
              ></i>
              <span class="option-text">
                {{ option.text }}
              </span>
            </MPButton>
          </div>
        </div>
      </div>
      <div class="form-group">
        <textarea
          class="form-control comment-textarea"
          v-model="comment"
          type="text"
          placeholder="Send additional comments to the Forks Meal Planner team (optional)."
        ></textarea>
      </div>
      <MPButton
        class="feedback-submit"
        type="submit"
        color="primary"
        size="normal"
      >
        Send Feedback
      </MPButton>
    </form>
  </MPModal>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import RatingStars from './RatingStars'
import { events } from '@/utils/mixpanel.events'

export default {
  components: { RatingStars },
  data: () => ({
    feedbackItems: _.clone(feedbackItems),
    comment: '',
    descriptions: [
      'Eeesh. We’re sorry. Let us know why below.',
      'Sorry you didn’t love it. Could you let us know why?',
      'Thank you for the feedback. How could we improve?',
      'We’re so glad you enjoyed it!',
    ],
  }),
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      recipe: 'recipe/recipe',
    }),
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
  },
  methods: {
    addToFavorites() {
      this.$store.dispatch('recipe/setFavorite', true)
    },
    rateRecipe(rate) {
      this.$store.dispatch('recipe/rateRecipe', rate)
    },
    submitFeedback() {
      let data = {
        comment: this.comment,
      }
      this.feedbackItems.forEach((item) => {
        let selected = item.options.find(({ isSelected }) => isSelected)
        selected = selected || { value: '' }
        data[item.key] = selected.value
      })
      this.$store
        .dispatch('recipe/recipeFeedback', data)
        .then(() => (this.showModal = false))
      const payload = {
        eventName: events.recipe.sendFeedback,
        params: {
          recipe_id: this.recipe.id,
          recipe_rating: this.recipe.rating,
          flavor: data.flavor_rating,
          texture: data.texture_rating,
          timing: data.timing_rating,
          portion_size: data.serving_size_rating,
        },
      }
      this.$store.dispatch('analytics/track', payload)
    },
    selectOption(feedbackItemKey, optionKey) {
      this.feedbackItems[feedbackItemKey].options.forEach((option, key) => {
        option.isFocusOut = optionKey !== key
        option.isSelected = optionKey === key
      })
    },
  },
}

const feedbackItems = [
  {
    title: 'Flavor',
    key: 'flavor_rating',
    options: [
      {
        image: require('@/assets/icons/feedback-flavor-disliked.svg'),
        text: 'Disliked it',
        value: '-1',
        isSelected: false,
        isFocusOut: false,
      },
      {
        image: require('@/assets/icons/feedback-flavor-ok.svg'),
        text: 'Ok',
        value: '0',
        isSelected: false,
        isFocusOut: false,
      },
      {
        image: require('@/assets/icons/feedback-flavor-loved-it.svg'),
        text: 'Loved it',
        value: '1',
        isSelected: false,
        isFocusOut: false,
      },
    ],
  },
  {
    title: 'Texture',
    key: 'texture_rating',
    options: [
      {
        image: require('@/assets/icons/feedback-flavor-disliked.svg'),
        text: 'Disliked it',
        value: '-1',
        isSelected: false,
        isFocusOut: false,
      },
      {
        image: require('@/assets/icons/feedback-flavor-ok.svg'),
        text: 'Ok',
        value: '0',
        isSelected: false,
        isFocusOut: false,
      },
      {
        image: require('@/assets/icons/feedback-flavor-loved-it.svg'),
        text: 'Loved it',
        value: '1',
        isSelected: false,
        isFocusOut: false,
      },
    ],
  },
  {
    title: 'Timing',
    key: 'timing_rating',
    options: [
      {
        image: require('@/assets/icons/feedback-timing-longer.svg'),
        text: 'Took longer',
        value: '-1',
        isSelected: false,
        isFocusOut: false,
      },
      {
        image: require('@/assets/icons/feedback-timing-about-right.svg'),
        text: 'About right',
        value: '0',
        isSelected: false,
        isFocusOut: false,
      },
      {
        image: require('@/assets/icons/feedback-timing-faster.svg'),
        text: 'Went faster',
        value: '1',
        isSelected: false,
        isFocusOut: false,
      },
    ],
  },
  {
    title: 'Portion Size',
    key: 'serving_size_rating',
    options: [
      {
        image: require('@/assets/icons/feedback-serving-too-small.svg'),
        text: 'Too small',
        value: '-1',
        isSelected: false,
        isFocusOut: false,
      },
      {
        image: require('@/assets/icons/feedback-serving-just-right.svg'),
        text: 'Just right',
        value: '0',
        isSelected: false,
        isFocusOut: false,
      },
      {
        image: require('@/assets/icons/feedback-serving-too-much.svg'),
        text: 'Too much',
        value: '1',
        isSelected: false,
        isFocusOut: false,
      },
    ],
  },
]
</script>

<style lang="scss" scoped>
.form-group {
  border-top: 1px solid #e5e5e5;
}

.form-description {
  display: block;
  color: #747e8b;
  font-size: 14px;
  font-weight: 500;
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.options {
  display: flex;
  flex-direction: row;
}

.option {
  text-align: center;
  flex: 1;
  border-radius: 3px;
  background-color: #fff;
  border: solid 1px #e5e5e5;
  cursor: pointer;
  height: 50px;
  line-height: 0.5rem;
  & + & {
    margin-left: 20px;
  }
  &:hover {
    background-color: #f5f5f5;
  }
  &--focus-out {
    opacity: 0.5;
  }
  &--selected {
    border: solid 1px $primary;
    background-color: $clr-navy-5;
  }
}

.option-icon {
  user-select: none;
  display: inline-block;
  height: 20px;
  width: 100%;
  background-size: auto 20px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 5px;
}

.option-text {
  font-size: 12px;
}

.comment-textarea {
  margin-top: 1rem;
  width: 100%;
  &::placeholder {
    color: #cccccc;
    font-size: 12px;
    font-weight: normal;
  }
}

.feedback-submit {
  width: auto;
  padding: 0 2rem;
}
</style>
