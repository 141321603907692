import moment from 'moment-timezone'

const DEFAULT_TIMEZONE = 'America/Los_Angeles'

export function getCurrentDayTZ() {
  return moment().tz(DEFAULT_TIMEZONE).format('dddd')
}

export function getCurrentDate() {
  return moment.tz(moment(), DEFAULT_TIMEZONE).format('MM/DD/YY')
}
