<template>
  <transition name="notification">
    <div
      class="mp-notification-container d-flex align-items-center justify-content-center p-2 p-md-0"
    >
      <div
        class="mp-notification d-flex flex-row flex-md-row p-3 py-md-2 px-md-3 align-items-stretch align-items-md-center"
        :class="`mp-notification--${type} ${title ? '' : 'pl-5 pr-5'}`"
      >
        <img
          v-if="image"
          :src="image"
          alt=""
          class="mr-md-3 mb-2 mb-md-0 mr-1"
        />
        <div v-if="title" class="title">{{ title }}</div>
        <slot v-else></slot>
        <div
          v-if="actionText || closeActionText || showCloseButton"
          class="row mt-2 mx-1 mt-md-0 ml-md-3 align-items-center"
        >
          <span
            class="actions-button action rounded mr-1 col"
            :class="`action--${type}`"
            v-if="actionText"
            @click="onActionSelect"
            >{{ actionText }}</span
          >
          <span
            class="actions-button close-action ml-1 col"
            v-if="closeActionText"
            @click="onCloseActionSelect"
            >{{ closeActionText }}</span
          >
          <span
            class="close-button"
            v-if="showCloseButton"
            @click="onCloseActionSelect"
            ><img :src="require('@/assets/icons/close-add-recipe.svg')"
          /></span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MPNotification',
  props: {
    type: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    actionText: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    closeActionText: {
      type: String,
      required: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onActionSelect() {
      this.$emit('onActionSelect')
    },
    onCloseActionSelect() {
      this.$emit('onCloseActionSelect')
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-notification-container {
  z-index: 110;
  position: fixed;
  top: 46px;
  left: 0;
  max-width: 600px;
  width: 100%;
  margin-left: 50%;
  margin-right: 50%;
  transform: translate(-50%, 50%);

  @include media-breakpoint-up(md) {
    top: 30px;
  }
  @include media-breakpoint-up(lg) {
    left: 156px;
  }

  &.full {
    left: 0 !important;
  }
  &.full-with-navbar {
    top: 1rem !important;
  }
  &.add-lr-padding > div:first-of-type {
    padding: 1rem 1rem !important;
  }
}
.mp-notification {
  position: relative;
  min-height: 45px;
  border-radius: 0;
  z-index: 2;
  font-weight: 500;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(md) {
    max-width: 80%;
  }

  &--action {
    color: white;
    background-color: $clr-navy;
  }
  &--info {
    background-color: $light-grey;
  }
  &--dark {
    color: white;
    background-color: $dark-grey;
  }
  &--offer {
    color: white;
    background-color: $clr-navy;
  }
  &--error {
    color: white;
    background-color: $error;
  }
}

.title {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include media-breakpoint-up(md) {
    justify-content: flex-start;
    text-align: left;
  }
}
.actions-button {
  justify-content: center;
  padding: 12px 0;
  white-space: nowrap;
  @include media-breakpoint-up(md) {
    padding: 6px 12px;
  }
}
.action {
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  &--action {
    background-color: #ffffff;
    color: $body-color;
  }
  &--info {
    background-color: #ffffff;
    color: #d4d9e0;
  }
  &--dark {
    background-color: #ffffff;
    color: #414a4d;
  }
}
.close-action {
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 3px;
  @include media-breakpoint-up(md) {
    border-radius: 0px;
    border: none;
  }
}

.close-button {
  cursor: pointer;
  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 2px;
    right: 2px;
  }
}

.notification-enter-active,
.notification-leave-active {
  transition: opacity 0.15s linear;
  opacity: 0.5;
}

.notification-enter,
.notification-leave-to,
.fade-leave-active {
  opacity: 0;
}
</style>
