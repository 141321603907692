<template>
  <div class="mp-refresh-page-modal">
    <b-modal
      v-if="visible"
      :ref="modalId"
      :id="modalId"
      dialog-class="refresh-page-modal"
      hide-footer
      centered
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      :hide-header="true"
    >
      <div class="row">
        <div class="col">
          <img class="refresh-header-image" :src="icon" />

          <p class="body-text topmargin" v-html="text"></p>

          <div class="row topmargin">
            <div class="col-12">
              <button @click="reloadPage()" class="button refresh-page-button">
                <span class="d-none d-md-block button-action-text"
                  >Click here to refresh page</span
                >
                <span class="d-inline-block d-md-none refresh-icon"></span>
                <span class="d-inline-block d-md-none button-action-text"
                  >Tap here to refresh</span
                >
              </button>
            </div>

            <!-- Hidden for now -->
            <div class="col-12">
              <a
                class="whitelink nounderline help-text"
                target="_blank"
                :href="helpLink"
                >{{ helpText }}</a
              >
            </div>

            <div class="col-12 mt-2">
              <span class="help-text"
                >Popup keeps appearing?
                <a class="whitelink" @click="openIntercom()"
                  >Contact us</a
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'MPRefreshPageModal',
  props: {
    modalId: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    helpLink: {
      type: String,
      required: true,
    },
    helpText: {
      type: String,
      required: true,
    },
  },
  methods: {
    openIntercom() {
      this.$intercom.show()
    },
    reloadPage() {
      window.location.reload(true)
    },
  },
  watch: {
    visible(isVisible) {
      if (isVisible) {
        this.$nextTick(() => this.$refs[this.modalId].show())
      }
    },
  },
}
</script>

<style lang="scss">
.mp-refresh-page-modal {
  .modal-dialog {
    max-width: 440px;
  }
  .modal-content {
    text-align: center;
    padding: 0;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    border: none;

    @include media-breakpoint-down(sm) {
      padding: 10px;
    }
  }
  .modal-body {
    padding: 3rem;
  }
}

#meal-plan-changed-refresh-page-modal {
  .modal-content {
    background-color: $dusty-orange !important;
  }

  .button-action-text {
    color: $dusty-orange;
  }

  .refresh-icon {
    background: url(~@/assets/icons/refresh-icon-orange.svg) center no-repeat;
  }
}

#version-updated-refresh-page-modal {
  .modal-content {
    background-color: $action-green !important;
  }

  .button-action-text {
    color: $action-green;
  }

  .refresh-icon {
    background: url(~@/assets/icons/refresh-icon-green.svg) center no-repeat;
  }
}

.refresh-page-modal {
  text-align: center;
  width: 360px;
  height: 390px;
  padding: 40px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;

  &.open {
    z-index: 1010;
  }

  hr {
    margin-top: 15px;
    color: #e5e5e5;
  }

  h5 {
    color: #404852;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
  }

  h6 {
    color: #404852;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }

  button {
    text-transform: none !important;
  }

  textarea {
    font-size: 14px;
    padding: 5px;
  }

  .modal-content {
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .refresh-header-image {
    margin: 0 auto;
    width: 182px;
    height: 80px;
  }

  .body-text {
    font-weight: 500;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    text-align: center;
    color: #ffffff;
  }

  .topmargin {
    margin-top: 20px;
  }

  .decrease-padding {
    padding-left: 8px;
    padding-right: 8px;
  }

  .refresh-page-button {
    border-radius: 3px;
    background-color: #ffffff;
    box-shadow: 0 6px 0 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 18px 0px;

    &:hover {
      background-color: #fafafa;
    }

    &:active {
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
      transform: translateY(4px);
    }
  }

  .refresh-icon {
    margin: 0 5px;
    width: 19px;
    height: 22px;
    vertical-align: middle;
  }

  .button-action-text {
    font-size: 16px;
    line-height: 0.81;
    text-transform: none;
    vertical-align: middle;
  }

  .nounderline {
    border-bottom: none !important;
  }

  .whitelink {
    color: #ffffff !important;
    font-weight: bold;
    padding-bottom: 2px;
    border-bottom: 1px solid #ffffff;
    cursor: pointer;
  }

  .help-text {
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: #ffffff;
  }

  @include media-breakpoint-only(sm) {
    padding: 30px;
    width: 320px !important;
    max-width: 320px !important;
    height: auto !important;
    margin: 0 auto;
    left: 0;
    right: 0;

    .modal-content {
      max-height: 100%;
      overflow-y: initial;
    }

    .decrease-padding {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
</style>
