<template>
  <bModal
    hide-footer
    hide-header
    centered
    :no-close-on-backdrop="showLoading || !closeOnBackdrop"
    :no-close-on-esc="showLoading || !closeOnEsc"
    v-model="showModal"
    class="mp-modal"
    :static="true"
    :class="[
      {
        'mp-modal--loading': showLoading,
        'mp-modal--full-screen-on-mobile': fullScreenOnMobile,
      },
    ]"
  >
    <MPSpinner v-if="showLoading" />
    <div class="x-icon-wrapper" v-if="showX">
      <span
        class="x-icon close-reveal-modal"
        :class="{ 'x-icon--white': inverseCloseColor }"
        @click="closeModal"
      >
        <Close />
      </span>
    </div>
    <div
      class="mp-modal-content"
      :class="[contentPaddingClass, 'color-' + bgColor]"
    >
      <slot></slot>
      <div class="actions-group" v-if="showActionsGroup">
        <div class="dismiss-action" v-if="showDismissAction">
          <slot name="dismiss-action"></slot>
        </div>
        <div class="confirm-action" v-if="showConfirmAction">
          <slot name="confirm-action"></slot>
        </div>
      </div>
    </div>
  </bModal>
</template>

<script>
import MPSpinner from './MPSpinner.vue'
import { Close } from '@/components/common/MPIcons'

export default {
  components: {
    MPSpinner,
    Close,
  },
  props: {
    bgColor: {
      type: String,
      default: 'grey',
    },
    show: {
      type: Boolean,
      default: false,
    },
    showX: {
      type: Boolean,
      default: false,
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    closeOnBackdrop: {
      type: Boolean,
      default: true,
    },
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
    contentPaddingClass: {
      type: String,
      default: '',
    },
    fullScreenOnMobile: {
      type: Boolean,
      default: true,
    },
    inverseCloseColor: {
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
    showActionsGroup() {
      return this.showDismissAction || this.showConfirmAction
    },
    showDismissAction() {
      return !!this.$slots['dismiss-action']
    },
    showConfirmAction() {
      return !!this.$slots['confirm-action']
    },
  },
  methods: {
    closeModal() {
      if (!this.showLoading) this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-modal--loading {
  position: relative;
}

.x-icon-wrapper {
  position: relative;
}

.x-icon {
  position: absolute;
  color: $body-color;
  cursor: pointer;
  line-height: 1;
  top: 1rem;
  right: 1rem;
  z-index: 6;
  @include media-breakpoint-down(sm) {
    left: 0;
    right: auto;
  }
}

.x-icon--white {
  color: $white;
}

.mp-modal-content {
  padding: 0.5rem;
  @include media-breakpoint-up(md) {
    padding: 1rem;
  }
}

.color-grey {
  background: $clr-navy-5;
}

.color-white {
  background: $white;
}

.actions-group {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.dismiss-action {
  margin-bottom: 1rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.confirm-action {
  flex: 1;
}

.dismiss-action + .confirm-action {
  flex: none;
}
</style>

<style lang="scss">
.mp-modal {
  .modal-content {
    height: auto;
    border-radius: 3px;
  }

  .modal-body {
    padding: 0;
  }
}

.mp-modal--full-screen-on-mobile {
  .modal-content {
    border: 0;
    border-radius: 0;
    padding: 0;
    @include media-breakpoint-down(sm) {
      background: $clr-navy-5;
      min-height: 100vh;
    }
  }
  .modal-dialog {
    @include media-breakpoint-down(sm) {
      margin: 0;
      min-width: 100%;
    }
  }
}

.modal-open {
  overflow: hidden !important;
}
</style>
