<template>
  <div class="sv">
    <MPTour
      name="SettingsTour"
      :steps="tourSteps"
      :callbacks="tourCallbacks"
      :options="getTourOptions()"
    ></MPTour>
    <div class="container-fluid d-none d-md-block">
      <PageHeader title="Account Settings" :showPlans="false" />
    </div>
    <div class="container mobile-header-container d-sm-block d-md-none">
      <div class="row">
        <div class="col-12">
          <h1 class="title-mobile">Settings</h1>
        </div>
        <div class="w-100" />
        <div class="col-12">
          <SettingsMobileNavigation
            class="mobile-navigation settings-step-1-selector-mobile"
            :title="currentNavItem.title"
            @onSelect="navigateTo"
            sticky-fill
          >
            <template slot-scope="{ onSelect }">
              <SettingsMobileNavigationItem
                v-for="navItem in navItems"
                :key="navItem.componentName"
                @click.native="onSelect(navItem.routeName)"
              >
                {{ navItem.title }}
              </SettingsMobileNavigationItem>
            </template>
          </SettingsMobileNavigation>
        </div>
      </div>
    </div>
    <div class="container-fluid settings-container settings-container">
      <div class="row settings-content-row">
        <div class="col">
          <div class="settings-panel">
            <div class="tabs nav nav-pills nav-fill">
              <router-link
                class="nav-item tab"
                :class="navItem.customClass"
                active-class="tab--selected"
                v-for="navItem in navItems"
                :key="navItem.componentName"
                :to="{ name: navItem.routeName }"
                exact
              >
                {{ navItem.title }}
              </router-link>
            </div>
            <div
              class="settings-panel-content"
              :class="`settings-panel-content--${currentNavItem.routeName}`"
            >
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
      <div class="d-md-none mt-4">
        <div class="settings-bottom-navbar-title">Are you looking for:</div>
        <div
          class="row my-3 settings-bottom-navbar-item align-items-center"
          v-for="(navItem, index) in bottomNavItems"
          :key="index"
          @click="navigateTo(navItem.routeName)"
        >
          <div class="col">{{ navItem.bottomMenuText }}</div>
          <div class="col-2 text-right">
            <img src="@/assets/icons/arrow-right.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid settings-container settings-container--footer">
      <div class="row settings-content-row">
        <div class="col-12">
          <div class="footer-content">
            <span class="footer-text">
              <b class="text-uppercase mr-3">Questions?</b> Visit our
              <a target="_blank" href="https://support.forksmealplanner.com/">
                Help & FAQ
              </a>
              or <span class="link" @click="openIntercom()">Contact us</span>.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DetectDeviceMixin from '@/mixins/DetectDeviceMixin'
import PageHeader from '@/components/common/PageHeader'
import MPTour from '@/components/tour/MPTour'
import { events } from '@/utils/mixpanel.events'
import SettingsMobileNavigation from './SettingsMobileNavigation'
import SettingsMobileNavigationItem from './SettingsMobileNavigationItem'

export default {
  mixins: [DetectDeviceMixin],
  components: {
    PageHeader,
    MPTour,
    SettingsMobileNavigation,
    SettingsMobileNavigationItem,
  },
  data() {
    return {
      currentRouteName: 'SettingsProfile',
      allNavItems: [
        {
          title: 'Profile',
          routeName: 'SettingsProfile',
          bottomMenuText: 'Profile & Account',
          customClass: '',
        },
        {
          title: 'Preferences',
          routeName: 'SettingsPreferences',
          bottomMenuText: 'Meal Preferences',
          customClass: 'settings-step-1-selector',
        },
        {
          title: 'Billing',
          routeName: 'SettingsBilling',
          bottomMenuText: 'Billing & Subscriptions',
          customClass: '',
        },
        {
          title: 'Notifications',
          routeName: 'SettingsNotifications',
          bottomMenuText: 'Notification Preferences',
          customClass: '',
        },
        {
          title: 'Affiliate',
          routeName: 'SettingsAffiliate',
          bottomMenuText: 'Affiliate Preferences',
          customClass: '',
        },
      ],
      tourCallbacks: {
        onStop: this.onStopTourCallback,
        onStart: this.onStartTourCallback,
        onSkip: this.onSkipTourCallback,
        onFinish: this.onFinishTourCallback,
      },
    }
  },
  computed: {
    ...mapGetters({
      backgroundImage: 'dashboard/backgroundImage',
      isAffiliate: 'user/isAffiliate',
      isOnboardingLoaded: 'user/isOnboardingLoaded',
      onboarding: 'user/onboarding',
    }),
    currentNavItem() {
      return this.navItems.find(
        ({ routeName }) => this.$route.name === routeName
      )
    },
    navItems() {
      return this.allNavItems.filter(({ routeName }) => {
        return routeName !== 'SettingsAffiliate' || this.isAffiliate
      })
    },
    bottomNavItems() {
      return this.navItems.filter(({ routeName }) => {
        return routeName != this.$route.name
      })
    },
    tourSteps() {
      const desktopStep1 = {
        target: '.settings-step-1-selector',
        content:
          '<h5>Personalize your meals</h5>You can adjust all general meal and printing <b>Preferences</b>  here.<br/> Once changes are selected, you may update the settings immediately or allow them to go into effect for the following week.',
        params: {
          enableScrolling: false,
          placement: 'bottom',
          boundariesElement: 'viewport',
          modifiers: {
            preventOverflow: {
              // without this setting, popper is constrained within the navbar
              escapeWithReference: true,
            },
          },
        },
        before: () =>
          this.trackStep({
            eventName: events.tooltour.click,
            params: { title: 'Personalize your meals', step: '1', type: 'web' },
          }),
      }

      const mobileStep1 = {
        target: '.settings-step-1-selector-mobile',
        content:
          '<h5>Personalize your meals</h5>Select <b>Preferences</b> to adjust all general meal and printing preferences.<br/><br/> Once changes are selected, you may update the settings immediately or allow them to go into effect for the following week.',
      }

      if (this.isMobile().any || this.isTabletResolution()) {
        return [mobileStep1]
      }

      return [desktopStep1]
    },
  },
  methods: {
    openIntercom() {
      this.$intercom.show()
    },
    navigateTo(path) {
      this.$router.push({ name: path })
    },
    getTourOptions() {
      if (this.tourSteps.length > 1) {
        return {}
      }

      return {
        labels: { buttonStop: 'Got it!' },
      }
    },
    onStopTourCallback() {
      if (this.isMobile().any && !this.isTabletResolution()) {
        this.$store.dispatch('user/finishTour', 'settings_tour_done_mobile', {
          root: true,
        })
      } else {
        this.$store.dispatch('user/finishTour', 'settings_tour_done', {
          root: true,
        })
      }
    },
    onStartTourCallback() {
      this.trackStep({
        eventName: events.tooltour.lifecycle,
        params: { eventType: 'Settings Tour Started', type: 'web' },
      })
    },
    onSkipTourCallback() {
      this.trackStep({
        eventName: events.tooltour.lifecycle,
        params: { eventType: 'Settings Tour Skipped', type: 'web' },
      })
    },
    onFinishTourCallback() {
      this.trackStep({
        eventName: events.tooltour.lifecycle,
        params: { eventType: 'Settings Tour Finished', type: 'web' },
      })
    },
    trackStep(event) {
      this.$store.dispatch('analytics/track', event)
    },
  },
  watch: {
    isOnboardingLoaded: {
      handler: function (isOnboardingLoaded) {
        if (this.isMealPlannerLocked) {
          // don't show onboarding when meal planner is locked (trial is expired)
          return
        }
        Vue.nextTick().then(() => {
          if (isOnboardingLoaded) {
            if (this.isMobile().any && !this.isTabletResolution()) {
              if (!this.onboarding.settingsTourDoneMobile) {
                this.$tours['SettingsTour'].start()
              }
            } else {
              if (!this.onboarding.settingsTourDone) {
                this.$tours['SettingsTour'].start()
              }
            }
          }
        })
      },
      immediate: true,
    },
  },
  mounted() {
    this.$analytics.track('mp settings view')
  },
}
</script>

<style lang="scss" scoped>
.sv {
  ::v-deep .page-header {
    padding-bottom: 35px;
    padding-top: 56px;
  }
  padding-bottom: 80px;
}
.settings-container {
  margin-top: 15px;
  @include media-breakpoint-down(sm) {
    background-color: white;
  }
}

.settings-content-row {
  max-width: 900px;
}
.footer-content {
  background-color: $clr-navy-3;
  padding: 15px;
}
.mobile-header-container {
  padding-bottom: 15px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.08), 0 1px 0 0 $clr-navy-15;
}

.mobile-navigation {
  top: 0;
  position: sticky;
  z-index: 3;
}

.title {
  margin: 46px 0 50px;
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}

.title-mobile {
  text-transform: uppercase;
  margin-top: 15px;
}

.tabs {
  display: none;
  width: 100%;
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;

  font-weight: 500;
  background-color: $clr-navy-5;
  border-bottom: solid 1px $clr-navy-15;
  border-right: solid 1px $clr-navy-15;
  cursor: pointer;
  text-transform: uppercase;
  color: $body-color;

  &:hover {
    color: $link-hover-color;
  }

  &:last-child {
    border-right: none;
  }

  &--selected {
    background-color: $white;
    color: $link-color;
    border-bottom: solid 1px $white;
  }
}

.settings-panel {
  border-radius: 0;
  border: none;
  background-color: $white;
  @include media-breakpoint-up(md) {
    border: solid 1px $clr-navy-15;
  }
}

.settings-panel-content {
  margin: 0 auto;
  @include media-breakpoint-up(md) {
    padding: 0 15px 0;
  }
  &--settings-preferences {
    max-width: 100%;
  }
}

.footer-text {
  padding: 20px 0 40px;
  @include media-breakpoint-up(md) {
    padding: 50px 0 80px;
  }
}
.settings-bottom-navbar-title {
  color: $clr-navy-70;
}
.settings-bottom-navbar-item {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
</style>
