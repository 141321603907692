<template>
  <div class="add-new-recipe-modal-container">
    <b-modal
      :centered="true"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :static="true"
      id="add-new-recipe-modal"
      ref="addNewRecipeModal"
      :visible="showModal"
      @shown="onShown()"
      lazy
    >
      <div class="text-right w-100" slot="modal-header">
        <span @click="closeModal()" class="close-icon">
          <Close />
        </span>
      </div>
      <div class="modal-title">{{ titleText }}</div>
      <div class="steps">
        <AddCustomRecipeStepIcon
          :active="step == 0"
          :disabled="stepsDone < 0 && !editMode"
          :number="1"
          text="Recipe Info"
          @click="switchTab(0)"
        />
        <div class="step-line"></div>
        <AddCustomRecipeStepIcon
          :active="step == 1"
          :disabled="stepsDone < 1 && !editMode"
          :number="2"
          text="Ingredients"
          @click="switchTab(1)"
        />
        <div class="step-line"></div>
        <AddCustomRecipeStepIcon
          :active="step == 2"
          :disabled="stepsDone < 2 && !editMode"
          :number="3"
          text="Preparation Steps"
          @click="switchTab(2)"
        />
      </div>
      <!--
          Acceptable components:
          - AddCustomRecipeInfo
          - AddCustomRecipeIngredients
          - AddCustomRecipeSteps
        -->
      <keep-alive>
        <transition mode="out-in">
          <component :is="components[step]" :tabScope="scopedTabs[step]" />
        </transition>
      </keep-alive>
      <!-- Save, back, next button -->
      <div class="row navigation-control justify-content-between mt-2">
        <div class="save-container">
          <MPButton
            @click="saveAndExit()"
            size="medium"
            class="btn btn-link save-button"
            v-if="step < 2"
            :disabled="hasErrors"
          >
            <i class="save-icon"></i>
            Save and Exit
          </MPButton>
        </div>
        <div class="d-flex justify-content-end action-container">
          <MPButton
            @click="previousTab()"
            color="grey-outline"
            class="mr-1 px-5"
            v-if="step > 0"
            :disabled="hasErrors"
          >
            Back
          </MPButton>
          <MPButton
            @click="nextTab()"
            color="primary next-button"
            v-if="step < 2"
            class="btn-next text-nowrap"
            :disabled="hasErrors"
          >
            {{ nextText }}
          </MPButton>
          <MPButton
            color="primary"
            class="px-5 ml-1"
            v-else
            @click="done()"
            :disabled="hasErrors"
          >
            Done
          </MPButton>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import AddCustomRecipeInfo from './AddCustomRecipeInfo'
import AddCustomRecipeIngredients from './AddCustomRecipeIngredients'
import AddCustomRecipeSteps from './AddCustomRecipeSteps'
import RouterMixin from '@/mixins/RouterMixin'
import AddCustomRecipeStepIcon from '@/components/addcustomrecipe/AddCustomRecipeStepIcon'
import sync from 'css-animation-sync'
import { Close } from '@/components/common/MPIcons'
import { events } from '@/utils/mixpanel.events'
import { EVENTS as postEvents } from '@/events/analytics'

export default {
  name: 'AddNewRecipeView',
  components: {
    AddCustomRecipeInfo,
    AddCustomRecipeIngredients,
    AddCustomRecipeSteps,
    AddCustomRecipeStepIcon,
    Close,
  },
  mixins: [RouterMixin],
  mounted() {
    sync('animate-border')
  },
  data() {
    return {
      stepsDone: 0,
      scopedTabs: ['RecipeInfo', 'Ingredients', 'Steps'],
      components: [
        'AddCustomRecipeInfo',
        'AddCustomRecipeIngredients',
        'AddCustomRecipeSteps',
      ],
    }
  },
  computed: {
    ...mapState('AddCustomRecipe', [
      'editMode',
      'dataChanged',
      'userGeneratedRecipe',
      'showModal',
    ]),
    titleText() {
      return this.editMode ? 'EDIT PERSONAL RECIPE' : 'ADD PERSONAL RECIPE'
    },
    nextText() {
      return `Next: ${this.scopedTabs[this.step + 1]}`
    },
    step: {
      get() {
        return this.$store.state.AddCustomRecipe.step
      },
      set(value) {
        this.$store.commit('AddCustomRecipe/SET_STEP', value)
      },
    },
    hasErrors() {
      return this.errors.items.length > 0
    },
  },
  methods: {
    ...mapActions('AddCustomRecipe', [
      'setCustomRecipeStrategy',
      'syncRecipeIngredients',
      'setModalVisible',
      'setConfirmModalVisible',
    ]),
    triggerAnalitics(step) {
      if (step === 1) {
        this.$store.dispatch('analytics/track', {
          eventName: events.personalRecipe.step1,
        })
      } else if (step === 2) {
        this.$store.dispatch('analytics/track', {
          eventName: events.personalRecipe.step2,
        })
      }
    },
    onShown() {
      this.stepsDone = 0
    },
    validateTab(tabIndex) {
      return this.$validator
        .validate(`${this.scopedTabs[tabIndex]}.*`)
        .then((result) => {
          return result
        })
    },
    validateAll() {
      return Promise.all([
        this.validateTab(0),
        this.validateTab(1),
        this.validateTab(2),
      ]).then((res) => {
        return res
      })
    },
    saveDataOnCurrentTab(currentStep) {
      if (currentStep == 1) {
        this.syncRecipeIngredients().then(() => {
          this.$store.commit('AddCustomRecipe/SET_DATA_CHANGED', false)
        })
      } else {
        this.setCustomRecipeStrategy().then(() => {
          this.$store.commit('AddCustomRecipe/SET_DATA_CHANGED', false)
        })
      }
    },
    switchTab(tabNumber) {
      if (this.editMode || this.stepsDone >= tabNumber) {
        if (this.step != tabNumber) {
          this.validateTab(this.step).then((res) => {
            if (res) {
              // this.saveDataOnCurrentTab(this.step)
              this.step = tabNumber
            }
          })
        }
      }
    },
    nextTab() {
      this.validateTab(this.step).then((res) => {
        if (res) {
          // this.saveDataOnCurrentTab(this.step)
          this.step++
          this.triggerAnalitics(this.step)
          if (this.stepsDone < 2) {
            this.stepsDone++
          }
        }
      })
    },
    previousTab() {
      this.validateTab(this.step).then((res) => {
        if (res) {
          // this.saveDataOnCurrentTab(this.step)
          this.step--
        }
      })
    },
    done() {
      this.$store.dispatch('analytics/track', {
        eventName: events.personalRecipe.done,
      })
      if (this.editMode) {
        this.$posthog.sendEvent(postEvents.personalRecipes.edited)
      } else {
        this.$posthog.sendEvent(postEvents.personalRecipes.created)
      }
      this.validateAll().then((res) => {
        if (res.includes(false)) {
          this.step = res.indexOf(false)
        } else {
          this.setModalVisible(false)
          Promise.resolve()
            .then(() => {
              return this.setCustomRecipeStrategy()
            })
            .then(() => {
              return this.syncRecipeIngredients()
            })
            .then(() => {
              this.onAddCustomRecipeReturnTo(true, this.userGeneratedRecipe)
            })
        }
      })
    },
    saveAndExit() {
      this.$store.dispatch('analytics/track', {
        eventName: events.personalRecipe.saveAndExit,
      })

      this.done()
    },
    closeModal() {
      if (this.dataChanged) {
        this.setConfirmModalVisible(true)
        this.validateTab(this.step).then((res) => {
          this.$emit('setIsFormValid', res)
        })
      } else {
        this.setModalVisible(false)
        this.onAddCustomRecipeReturnTo(false, this.userGeneratedRecipe)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-enter {
  opacity: 0.3;
}
.v-enter-active {
  transition: opacity 0.5s;
}
.v-enter-to {
  opacity: 1;
}
.steps {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: top;
  padding: 42px 0 35px;
  user-select: none;
  .step-line {
    width: 48px;
    height: 2px;
    background-color: $clr-navy-15;
    margin-top: 12px;
  }
}
.close-icon {
  display: inline-block;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.overlay {
  position: absolute;
  top: -33px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(#000, 0.1);
  border-radius: 4px;
}
.modal-title {
  font-size: rem(38);
  font-weight: 700;
  letter-spacing: 0.8px;
  text-align: center;
  color: $body-color;
  line-height: 1;
  margin-bottom: 0;
}
.navigation-control {
  margin: 0;
  padding: 0px 30px 25px 30px;

  @include media-breakpoint-down(xs) {
    display: flex;
    padding: 0px 10px 25px;
  }
}
.save-container {
  @include media-breakpoint-down(xs) {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}
.action-container {
  @include media-breakpoint-down(xs) {
    flex-direction: column;
    width: 100%;

    button {
      flex: 1;

      &:first-child {
        margin-bottom: rem(20);
      }
    }
  }
}
.save-button {
  padding: 0;
  font-size: rem(18);
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  padding-top: 15px;
  font-weight: bold;
}
.save-icon {
  display: inline-block;
  background-image: url('~@/assets/icons/save.svg');
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  margin-right: 3px;
  margin-top: -3px;
}

.next-button {
  text-transform: none;
  width: 245px;

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}
</style>
<style lang="scss">
@keyframes animate-border {
  0% {
    border-color: #dee2e6;
  }
  50% {
    border-color: $pale-red;
  }
  100% {
    border-color: #dee2e6;
  }
}
#add-new-recipe-modal {
  @include media-breakpoint-down(xs) {
    padding: 0;
    width: 105%;
  }
  .error-message {
    font-size: 12px;
    letter-spacing: 0.2px;
    color: $pale-red;
    min-height: 21px;
    vertical-align: middle;
    padding-top: 2px;
    padding-left: 2px;
  }
  .modal-dialog {
    min-width: 100%;
    @include media-breakpoint-up(md) {
      min-width: 95%;
    }
    @include media-breakpoint-up(lg) {
      min-width: 752px;
    }
    @include media-breakpoint-down(xs) {
      margin: 0;
    }
  }
  .modal-content {
    min-height: 585px;

    @include media-breakpoint-down(xs) {
      padding: 0 10px;
    }
  }
  .modal-content {
    background-color: $clr-navy-3;
    border: none;
  }
  .modal-body {
    padding: 0;
  }
  .modal-header {
    padding-bottom: 0;
  }
}
</style>
