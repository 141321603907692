/**
 * This class should handle any business logic between UI and Store/API.
 * It MUST NOT contain any platform (web vs mobile) specific logic or data (e.g. references to window).
 * It is used by both web and in the future also mobile apps.
 */

import apiCredentials from '@/api/credential'
import env from '@/environment'
import AnalyticsService from '@/externalServices/analytics'
import { getFbSdk } from '@/handlers/FBHandler'
import moment from 'moment'
import { events } from '@/utils/mixpanel.events'
import ProfitWell from '@/externalServices/ProfitWell'

function handlePostLogin(store, response, email, location) {
  AnalyticsService.identify(response.user.id, email)
  AnalyticsService.track('mp login', {
    'date of last login': response.user.last_login,
    location,
  })
  ProfitWell.start(email)

  return store.dispatch('user/refreshUserData', null, { root: true })
}

function handleLogin(store, location, email, password) {
  return new Promise((resolve, reject) => {
    store
      .dispatch('user/login', { email, password })
      .then((response) =>
        resolve(handlePostLogin(store, response, email, location))
      )
      .catch((err) => reject(err.response && err.response.data))
  })
}

function handleMagicLinkLogin(store, location, magicLink) {
  return new Promise((resolve, reject) => {
    store
      .dispatch('user/loginWithMagicLink', magicLink)
      .then((response) =>
        resolve(handlePostLogin(store, response, response.user.email, location))
      )
      .catch((err) => reject(err.response && err.response.data))
  })
}

function handleFBLogout() {
  const { appId, version } = env.facebook
  return new Promise((resolve, reject) => {
    return getFbSdk({ appId, version }).then((FB) => {
      FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          FB.logout(() => resolve())
        } else reject(response)
      })
    })
  })
}

function handleFBConnect() {
  const { appId, version } = env.facebook
  return new Promise((resolve, reject) => {
    return getFbSdk({ appId, version }).then((FB) =>
      FB.login(
        ({ authResponse }) => {
          if (authResponse) {
            return resolve(authResponse)
          } else {
            return reject('User cancelled login or did not fully authorize.')
          }
        },
        { scope: 'public_profile, email' }
      )
    )
  })
}

function handleFBLogin(store, location) {
  const { appId, version } = env.facebook
  return new Promise((resolve, reject) => {
    getFbSdk({ appId, version }).then((FB) => {
      FB.login(
        function (response) {
          if (response.authResponse) {
            return FB.api(
              '/me',
              { fields: 'id,name,email,picture' },
              function (response) {
                let { id, email } = response

                store
                  .dispatch('user/facebookLogin', { id, email })
                  .then((response) =>
                    resolve(handlePostLogin(store, response, email, location))
                  )
                  .catch((err) => {
                    reject(err.response && err.response.data)
                  })
              }
            )
          } else {
            return reject('Facebook authorization was cancelled')
          }
        },
        { scope: 'public_profile, email' }
      )
    })
  })
}

function handleGoogleLogin(store, location, res) {
  return new Promise((resolve, reject) => {
    const userProfile = res.getBasicProfile()
    const auth = res.getAuthResponse(true)
    const email = userProfile.getEmail()
    const id = userProfile.getId()
    const accessToken = auth ? auth.id_token : ''
    store
      .dispatch('user/googleLogin', { id, email, accessToken })
      .then((response) =>
        resolve(handlePostLogin(store, response, email, location))
      )
      .catch((err) => {
        reject(err.response.data)
      })
  })
}

function handleAppleLogin(store, location, result) {
  const appleUser = result.user
  return new Promise((resolve, reject) => {
    const email = appleUser.email
    const id = result.additionalUserInfo.profile.sub
    const fullName = appleUser.displayName
    const accessToken = result.credential.accessToken
    const idToken = result.credential.idToken
    store
      .dispatch('user/appleLogin', {
        id,
        email,
        fullName,
        accessToken,
        idToken,
      })
      .then((response) =>
        resolve(handlePostLogin(store, response, email, location))
      )
      .catch((err) => {
        reject(
          err.response && err.response.data ? err.response.data : 'Server error'
        )
      })
  })
}

/**
 * Function to fire register track event.
 * @param {Object} store
 * @param {String} method
 * @param {Object} data
 * @param {String} name
 * @param {String} email
 * @param {Object} preferences
 */
async function handleRegistration(store, method, params) {
  const action = {
    google: 'registration/googleRegister',
    apple: 'registration/appleRegister',
    facebook: 'registration/facebookRegister',
    email: 'registration/register',
  }

  const { name, email, fullName } = params

  try {
    const data = await store.dispatch(action[method], params)

    if (method !== 'email') {
      store.dispatch('registration/setUserEmail', email)
      store.dispatch(
        'registration/setUserFullName',
        (name || fullName).split(' ')[0]
      )
    }

    const { affiliateCode, impactPartnerId, campaign } = store.state.globals

    const userTraits = {
      method,
      'date account created': moment().format('DD/MM/YYYY'),
      name: name || fullName,
      $email: email,
      affiliate: affiliateCode,
      'impact partner id': impactPartnerId,
      campaign,
      'newsletter opt in': store.state.registration.userData.generalEmailsOptin,
      ...getPreferences(store),
    }

    AnalyticsService.register(data.userId, email, userTraits, data)
    AnalyticsService.track(events.register.accountCreated, userTraits)
    AnalyticsService.track(events.register.completed, userTraits)
    ProfitWell.start(email)

    return data
  } catch (err) {
    throw err.response
      ? err.response.data
      : { detail: 'Something went wrong. Please try again.' }
  }
}

async function handleEmailRegistration(
  store,
  email,
  fullName,
  password,
  generalEmailsOptin
) {
  const data = await handleRegistration(store, 'email', {
    email,
    fullName,
    password,
    generalEmailsOptin,
  })

  return data
}

async function handleGoogleRegistration(store, res) {
  const userProfile = res.getBasicProfile()
  const email = userProfile.getEmail()
  const name = userProfile.getName()
  const photoUrl = userProfile.getImageUrl()
  const id = userProfile.getId()

  await handleRegistration(store, 'google', {
    id,
    email,
    name,
    photoUrl,
  })

  return res
}

async function handleAppleRegistration(store, result) {
  const appleUser = result.user
  const email = appleUser.email
  const name = appleUser.displayName || ''
  const photoUrl = null
  const id = result.additionalUserInfo.profile.sub

  await handleRegistration(store, 'apple', {
    id,
    email,
    name,
    photoUrl,
  })

  return appleUser
}

async function handleFBRegistration(store, response) {
  const { id, email, name } = response
  const photoUrl = response.picture.data.url

  await handleRegistration(store, 'facebook', {
    id,
    email,
    name,
    photoUrl,
  })

  return response
}

function getPreferences(store) {
  const {
    adultServings: adult_servings,
    childServings: child_servings,
    soyIntolerance: soy_intolerance,
    nutsIntolerance: nuts_intolerance,
    glutenIntolerance: gluten_intolerance,
    breakfastOptin: breakfast_option,
    dinnerOptin: dinner_option,
    snackOptin: snack_option,
    dessertOptin: dessert_option,
    userGoals: goals,
  } = store.state.registration

  return {
    adult_servings,
    child_servings,
    soy_intolerance,
    nuts_intolerance,
    gluten_intolerance,
    breakfast_option,
    dinner_option,
    snack_option,
    dessert_option,
    ...Object.assign({}, goals),
  }
}

function activatePlan(store, email) {
  return new Promise((resolve, reject) => {
    store
      .dispatch('registration/activatePlan', email)
      .then((response) => {
        store
          .dispatch('user/refreshUserData', null, { root: true })
          .then(() => {
            resolve(response)
          })
      })
      .catch((err) => reject(err.response.data))
  })
}

function savePreferences(
  email,
  fullServings,
  halfServings,
  additionalIntolerances,
  generalEmailsOptin,
  soyIntolerance,
  nutsIntolerance,
  glutenIntolerance,
  breakfastOptin,
  lunchOptin,
  dinnerOptin,
  snackOptin,
  dessertOptin,
  pressureCookerOptin
) {
  return new Promise((resolve, reject) => {
    return apiCredentials
      .savePreferences(
        email,
        fullServings,
        halfServings,
        additionalIntolerances,
        generalEmailsOptin,
        soyIntolerance,
        nutsIntolerance,
        glutenIntolerance,
        breakfastOptin,
        lunchOptin,
        dinnerOptin,
        snackOptin,
        dessertOptin,
        pressureCookerOptin
      )
      .then((response) => {
        AnalyticsService.track(events.trial.started, {
          soy: soyIntolerance,
          gluten: glutenIntolerance,
          nut: nutsIntolerance,
          full_servings: fullServings,
          half_servings: halfServings,
          breakfast: breakfastOptin,
          lunch: lunchOptin,
          dinner: dinnerOptin,
          snack: snackOptin,
          dessert: dessertOptin,
          newsletter_opt_in: generalEmailsOptin,
        })

        return resolve(response)
      })
      .catch((err) => reject(err.response.data))
  })
}

function canCreateAccount(email) {
  return new Promise((resolve, reject) => {
    return apiCredentials
      .canCreateAccount(email)
      .then((response) => resolve(response))
      .catch((err) => reject(err.response.data))
  })
}

function checkEmailExists(email) {
  return apiCredentials.checkEmailExists(email).then((response) => {
    return response.emailExists
  })
}

function verifyEmail(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return regex.test(email)
}

function verifyFacebookId(facebook_id) {
  return apiCredentials.verifyFacebookId(facebook_id)
}

function resetPassword(email) {
  return apiCredentials.resetPassword(email).then((response) => {
    return response
  })
}

function changePassword(uid, token, password) {
  return apiCredentials
    .changePassword(uid, token, password)
    .then((response) => {
      return response
    })
}

export default {
  handleLogin,
  handleMagicLinkLogin,
  handleFBLogin,
  handleFBLogout,
  handleFBConnect,
  handleFBRegistration,
  handleGoogleLogin,
  handleAppleLogin,
  handleGoogleRegistration,
  handleAppleRegistration,
  handleEmailRegistration,
  activatePlan,
  savePreferences,
  checkEmailExists,
  resetPassword,
  changePassword,
  canCreateAccount,
  verifyEmail,
  verifyFacebookId,
  getFbSdk: () => {
    const { appId, version } = env.facebook
    getFbSdk({ appId, version })
  },
}
