<template>
  <div class="recipe-serving-size-info col-12">
    <div class="row">
      <div
        class="d-flex col-12 col-md-8 align-items-center justify-content-center justify-content-md-start"
      >
        <span class="info-icon mr-md-3 d-none d-md-inline">i</span>
        <div class="info-text col-md-10 mt-2 mt-md-0 text-center text-md-left">
          Please note that ingredients have been scaled to match the serving
          size entered in your meal plan (<span v-html="servingsComputed"></span
          >).
        </div>
      </div>
      <div class="col-12 col-md-4 row no-gutters mt-2 mt-md-0">
        <MPButton
          class="col mr-1 col-md mr-md-2"
          color="white"
          size="sm"
          @click="$emit('ok')"
        >
          GOT IT
        </MPButton>
        <MPButton
          class="col ml-1"
          color="white-outline"
          size="sm"
          @click="$emit('dismiss')"
        >
          NEVER SHOW AGAIN
        </MPButton>
      </div>
    </div>
  </div>
</template>

<script>
import { toFraction } from '@/utils/conversions'

export default {
  name: 'RecipeServingSizeInfo',
  props: {
    servings: {
      required: true,
    },
  },
  computed: {
    servingsComputed() {
      if (!this.servings) return

      const fraction = toFraction(this.servings)
      return `${fraction} serving${this.servings > 1 ? 's' : ''}`
    },
  },
}
</script>

<style lang="scss" scoped>
.recipe-serving-size-info {
  background: $clr-navy;
  padding: 14px 18px;
  color: $white;
  align-items: center;
}
.info-icon {
  border-radius: 50%;
  color: $clr-navy;
  display: block;
  width: 24px;
  height: 24px;
  background: $white;
  text-align: center;
  line-height: 24px;
}
.btn-white {
  color: $clr-navy;
  border: none;
}
.btn-white-outline {
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: $white;
  }
}
</style>
