<template>
  <MPMaterialInput
    :id="id"
    :error="error"
    type="email"
    v-model="inputValue"
    label="Email"
    @blur="$emit('blur', $event)"
    :data-spec="dataSpec"
  />
</template>

<script>
import { MPMaterialInput } from '@/components/common'

export default {
  name: 'EmailInput',
  components: {
    MPMaterialInput,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: 'email-input',
    },
    error: {
      type: String,
      default: '',
    },
    dataSpec: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
    }
  },
  watch: {
    inputValue() {
      this.$emit('input', this.inputValue)
    },
    value: {
      handler: function (value) {
        this.inputValue = value
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
#email-input {
  animation-fill-mode: both;
}

@keyframes emailAnimation {
  to {
  }
}

#email-input:-webkit-autofill {
  animation-name: emailAnimation;
}
</style>
