<template>
  <div class="password-reset">
    <CredentialsHeader>
      <template v-slot:center>
        <div class="sign-in-header-logo">
          <img src="@/assets/images/fok-mp-new-logo.svg" />
        </div>
      </template>

      <template v-slot:right v-if="!noSignUp">
        <div class="header-text d-none d-sm-block">
          <span class="text">
            Don't have an account?
            <b-link @click="goToSignUp()">Get one, now</b-link>
          </span>
        </div>

        <div class="header-text d-block d-sm-none">
          <b-link @click="goToSignUp()"> Sign up </b-link>
        </div>
      </template>
    </CredentialsHeader>

    <div class="credential-wrapper">
      <router-view @onLoggedIn="onLoggedIn()"></router-view>
    </div>
  </div>
</template>

<script>
import CredentialsHeader from '@/components/credentials/CredentialsHeader'
import { events } from '@/utils/mixpanel.events'

export default {
  name: 'PasswordReset',
  components: {
    CredentialsHeader,
  },
  data() {
    return {
      noSignUp: false,
    }
  },
  methods: {
    goToSignUp() {
      this.$analytics.track(events.join.clicked, {
        location: window.location.href,
      })
      this.$router.replace('/register/')
    },
    onLoggedIn() {
      this.$router.replace('/plan')
    },
  },
  mounted() {
    this.noSignUp = this.$routeQuery.get('nosignup') === 'true'
  },
}
</script>

<style lang="scss" scoped>
.password-reset {
  @include signup-bg;
}
.header-text {
  font-size: $h4-font-size;
}
.credential-wrapper {
  max-width: 510px;
  margin: 0 auto;
  padding: 64px 20px 20px;
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
}
.sign-in-header-logo {
  display: flex;
  width: 290px;

  img {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    width: 180px;
  }
}
</style>
