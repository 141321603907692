import _ from 'lodash'
import Fraction from 'fraction.js'

export default {
  getCompleteQuantities: getCompleteQuantities,
  getCompleteName: getCompleteName,
  toFraction: toFraction,
  getVolume: getVolume,
}

function getCompleteQuantities(minimumUnit, quantities, isCustom) {
  let quantitiesString = ''
  if (!quantities) return quantitiesString
  let first = true
  if (minimumUnit > 0) {
    quantities.forEach(function (i, index, qty) {
      let quantity = qty[index]
      qty = quantity.quantity
      if (qty > 0) {
        if (!first) quantitiesString += ' + '
        first = false
        let finalQty = toFraction(qty)
        let fraction = qty - Math.floor(qty)
        if (minimumUnit == 1.0) {
          finalQty = Math.ceil(qty)
        } else if (
          fraction > 0 &&
          fraction < minimumUnit &&
          minimumUnit > 0 &&
          minimumUnit < 1
        ) {
          finalQty = toFraction(Math.floor(qty) + minimumUnit)
        } else if (
          fraction > minimumUnit &&
          minimumUnit > 0 &&
          minimumUnit < 1
        ) {
          finalQty = Math.ceil(qty)
        }

        quantitiesString += '' + finalQty
        if (quantity.unit) {
          quantitiesString += ' ' + quantity.unit
          // if (!(qty.length > index+1 && qty[index+1].unit == quantity.unit)) {
          //   quantitiesString += ' ' + quantity.unit;
          // }
        }
      }
    })
  } else {
    quantities.forEach(function (i, index, qty) {
      let quantity = qty[index]
      if (quantity.quantity > 0 && quantity.quantity % 1 == 0) {
        if (!first) quantitiesString += ' + '
        quantitiesString += '' + quantity.quantity
        first = false
      } else if (quantity.quantity > 0) {
        if (!first) quantitiesString += ' + '
        quantitiesString += toFraction(quantity.quantity)
        first = false
      } else {
        if (!isCustom && quantity.available_quantity_id) {
          quantitiesString += '0'
        }
      }
      if (quantity.unit) {
        if (quantity.available_quantity_unit_name && quantity.quantity === 0) {
          quantitiesString += ' ' + quantity.available_quantity_unit_name
        } else {
          quantitiesString += ' ' + quantity.unit
        }
      }
    })
  }
  return quantitiesString
}

function getCompleteName({ ingredient, quantities, attribute, unit }) {
  const { pluralName, singularName } = ingredient
  let quantity = quantities.length
    ? _.last(quantities).quantity
    : ingredient.quantity
  let name = quantity > 1 || !!unit ? pluralName : singularName
  return [attribute, name].filter((i) => i).join(' ') // filter will remove undefined items
}

function toFraction(value) {
  // if this is not a decimal number, return back original value
  if (value % 1 == 0) {
    return value.toString()
  }

  value = String(value)
  let number = value.split('.')
  // performs special formatting required by fraction.js, which is to include the repeating number in parentheses
  if (number.length > 1 && number[1].slice(-1) === '3') {
    number = String(value + '(' + 3 + ')')
  } else if (number.length > 1 && number[1].slice(-1) === '6') {
    // covers 0.66
    number = String(value + '(' + 6 + ')')
  } else if (
    number.length > 1 &&
    (number[1].slice(-2) === '67' || number[1].slice(-2) === '65')
  ) {
    // covers 0.67 cases -> 0.6(6)
    number = String(value.slice(0, -1) + '(' + 6 + ')')
  } else {
    number = value
  }

  number = new Fraction(number).toFraction(true).split(' ')
  if (number.length > 1) {
    let integer = number[0]
    let fraction = number[1].split('/')
    return (
      integer +
      ' ' +
      "<span class='fraction'><sup>" +
      fraction[0] +
      '</sup>&frasl;<sub>' +
      fraction[1] +
      '</sub></span>'
    )
  } else {
    if (value < 1) {
      let fraction = number[0].split('/')
      return (
        "<span class='fraction'><sup>" +
        fraction[0] +
        '</sup>&frasl;<sub>' +
        fraction[1] +
        '</sub></span>'
      )
    }
  }
}

function getVolume(ingredient = {}) {
  const convertedVolume = ingredient.convertedVolume || {}
  return [
    convertedVolume.volume ? toFraction(convertedVolume.volume) : null,
    convertedVolume.unit,
    ingredient.volumeDescription,
  ]
    .filter((i) => i)
    .join(' ')
}
