import { render, staticRenderFns } from "./RecipeOverlay.vue?vue&type=template&id=4ed12bfa&scoped=true"
import script from "./RecipeOverlay.vue?vue&type=script&lang=js"
export * from "./RecipeOverlay.vue?vue&type=script&lang=js"
import style0 from "./RecipeOverlay.vue?vue&type=style&index=0&id=4ed12bfa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed12bfa",
  null
  
)

export default component.exports