import moment from 'moment'
import registrationStepRutes from '@/components/credentials/register/routes'
import reverseRegistrationStepRutes from '@/components/credentials/register/reverseSignupRoutes'
import apiCredentials from '@/api/credential'
import apiCheckout from '@/api/checkout'
import AnalyticsServices from '@/externalServices/analytics'
import ProfitWell from '@/externalServices/ProfitWell'
import { events } from '@/utils/mixpanel.events'
import * as types from './../mutation-types'

// initial state
const state = {
  adultServings: 1,
  childServings: 0,
  hasIntolerances: false,
  soyIntolerance: false,
  glutenIntolerance: false,
  nutsIntolerance: false,
  additionalIntolerances: '',
  breakfastOptin: true,
  dinnerOptin: true,
  lunchOptin: true,
  snackOptin: true,
  dessertOptin: true,
  userData: {
    email: '',
    fullName: '',
    password: '',
    generalEmailsOptin: true,
  },
  registrationSteps: registrationStepRutes,
  reverseRegistrationSteps: reverseRegistrationStepRutes,
  currentRegistrationStep: 1,
  currentReverseRegistrationStep: 1,
  registrationError: '',
  userGoals: {
    // keep typo until we're able to alias the property in Mixpanel
    use_goal_improve_health: false,
    user_goal_save_time_and_money: false,
    user_goal_manage_weight: false,
  },
  paymentInfo: {},
  registrationType: 'email',
  facebookUserInfo: null,
  googleUserInfo: null,
  appleUserInfo: null,
}

// getters
const getters = {
  registrationType: (state) => state.registrationType,
  facebookUserInfo: (state) => state.facebookUserInfo,
  googleUserInfo: (state) => state.googleUserInfo,
  appleUserInfo: (state) => state.appleUserInfo,
  paymentInfo: (state) => state.paymentInfo,
  registrationError: (state) => state.registrationError,
  adultServings: (state) => state.adultServings,
  childServings: (state) => state.childServings,
  hasIntolerances: (state) => state.hasIntolerances,
  soyIntolerance: (state) => state.soyIntolerance,
  glutenIntolerance: (state) => state.glutenIntolerance,
  nutsIntolerance: (state) => state.nutsIntolerance,
  additionalIntolerances: (state) => state.additionalIntolerances,
  breakfastOptin: (state) => state.breakfastOptin,
  lunchOptin: (state) => state.lunchOptin,
  dinnerOptin: (state) => state.dinnerOptin,
  dessertOptin: (state) => state.dessertOptin,
  snackOptin: (state) => state.snackOptin,
  email: (state) => state.userData.email,
  fullName: (state) => state.userData.fullName,
  password: (state) => state.userData.password,
  generalEmailsOptin: (state) => state.userData.generalEmailsOptin,
  registrationSteps: (state) => state.registrationSteps,
  currentRegistrationStep: (state) => state.currentRegistrationStep,
  getRegistrationStepsToResume: (state) =>
    state.registrationSteps.filter((step) => !step.done),
  reverseRegistrationSteps: (state) => state.reverseRegistrationSteps,
  currentReverseRegistrationStep: (state) =>
    state.currentReverseRegistrationStep,
  getReverseRegistrationStepsToResume: (state) =>
    state.reverseRegistrationSteps.filter((step) => !step.done),
  userGoals: (state) => state.userGoals,
  userInfo: (state, _, rootGetters) => ({
    email: state.userData.email,
    password: state.userData.password,
    full_name: state.userData.fullName,
    general_emails: state.userData.generalEmailsOptin,
    aff_ref: rootGetters.globals.affiliateCode,
    irpid: rootGetters.globals.impactPartnerId,
    mbsy: rootGetters.globals.ambassadorId,
    campaign: rootGetters.globals.campaign,
    abgroup: rootGetters.globals.abGroup,
    gift_code: rootGetters.globals.giftCode,
  }),
  userPreferences: (state) => ({
    full_servings: state.adultServings,
    half_servings: state.childServings,
    additional_intolerances: state.additionalIntolerances,
    soy_intolerance: state.soyIntolerance,
    nut_intolerance: state.nutsIntolerance,
    gluten_intolerance: state.glutenIntolerance,
    breakfast_opt_in: state.breakfastOptin,
    lunch_opt_in: state.lunchOptin,
    dinner_opt_in: state.dinnerOptin,
    snack_opt_in: state.snackOptin,
    dessert_opt_in: state.dessertOptin,
  }),
  userPayment: (state) => {
    const { token, plan, user_agent, browser_ip } = state.paymentInfo

    return {
      token,
      plan,
      user_agent,
      browser_ip,
    }
  },
}

// actions
const actions = {
  setAdultServings({ commit, state }, adultServings) {
    let { childServings } = state
    if (adultServings == 1 && childServings == 0) {
      commit(types.CHILD_SERVINGS, 1)
    }
    commit(types.ADULT_SERVINGS, adultServings)
  },
  decreaseAdultServings({ commit, state }) {
    let { adultServings, childServings } = state
    if (adultServings == 0) return
    if (adultServings == 1 && childServings == 0) {
      commit(types.CHILD_SERVINGS, 1)
    }
    commit(types.ADULT_SERVINGS, adultServings - 1)
  },
  increaseAdultServings({ commit, state }) {
    let { adultServings } = state
    commit(types.ADULT_SERVINGS, adultServings + 1)
  },
  decreaseChildServings({ commit, state }) {
    let { adultServings, childServings } = state
    if (childServings == 0) return
    if (childServings == 1 && adultServings == 0) {
      commit(types.ADULT_SERVINGS, 1)
    }
    commit(types.CHILD_SERVINGS, childServings - 1)
  },
  increaseChildServings({ commit, state }) {
    let { childServings } = state
    commit(types.CHILD_SERVINGS, childServings + 1)
  },
  setHasIntolerances({ commit }, hasIntolerances) {
    commit(types.HAS_INTOLERANCES, hasIntolerances)
  },
  setSoyIntolerance({ commit }, value) {
    commit(types.SOY_INTOLERANCE, value)
  },
  setNutsIntolerance({ commit }, value) {
    commit(types.NUTS_INTOLERANCE, value)
  },
  setGlutenIntolerance({ commit }, value) {
    commit(types.GLUTEN_INTOLERANCE, value)
  },
  setAdditionalIntolerances({ commit }, value) {
    commit(types.ADDITIONAL_INTOLERANCES, value)
  },
  setBreakfastOptin({ commit }, value) {
    commit(types.BREAKFAST_OPTIN, value)
  },
  setLunchOptin({ commit }, value) {
    commit(types.LUNCH_OPTIN, value)
  },
  setDinnerOptin({ commit }, value) {
    commit(types.DINNER_OPTIN, value)
  },
  setSnackOptin({ commit }, value) {
    commit(types.SNACK_OPTIN, value)
  },
  setDessertOptin({ commit }, value) {
    commit(types.DESSERT_OPTIN, value)
  },
  setUserEmail({ commit }, value) {
    commit(types.USER_EMAIL, value)
  },
  setUserFullName({ commit }, value) {
    commit(types.USER_FULL_NAME, value)
  },
  setUserPassword({ commit }, value) {
    commit(types.USER_PASSWORD, value)
  },
  setUserGeneralEmailsOptin({ commit }, value) {
    commit(types.USER_GENERAL_EMAILS_OPTIN, value)
  },
  nextRegistrationStep({ commit, state }) {
    commit(types.CURRENT_REGISTRATION_STEP, state.currentRegistrationStep + 1)
  },
  previousRegistrationStep({ commit, state }) {
    commit(types.CURRENT_REGISTRATION_STEP, state.currentRegistrationStep - 1)
  },
  setRegistrationStep({ commit }, step) {
    commit(types.CURRENT_REGISTRATION_STEP, step)
  },
  nextReverseRegistrationStep({ commit, state }) {
    commit(
      types.CURRENT_REVERSE_REGISTRATION_STEP,
      state.currentReverseRegistrationStep + 1
    )
  },
  previousReverseRegistrationStep({ commit, state }) {
    commit(
      types.CURRENT_REVERSE_REGISTRATION_STEP,
      state.currentReverseRegistrationStep - 1
    )
  },
  setReverseRegistrationStep({ commit }, step) {
    commit(types.CURRENT_REVERSE_REGISTRATION_STEP, step)
  },
  setRegistrationStepDone({ commit, state }) {
    commit(types.REGISTRATION_STEP_DONE, state.currentRegistrationStep)
  },
  setReverseRegistrationStepDone({ commit, state }) {
    commit(
      types.REVERSE_REGISTRATION_STEP_DONE,
      state.currentReverseRegistrationStep
    )
  },
  resetRegistrationStep({ commit }) {
    commit(types.CURRENT_REGISTRATION_STEP, 1)
  },
  resetReverseRegistrationStep({ commit }) {
    commit(types.CURRENT_REVERSE_REGISTRATION_STEP, 1)
  },
  setRegistrationError({ commit }, error) {
    commit(types.REGISTRATION_ERROR, error)
  },
  register(
    { commit, getters },
    { email, fullName, password, generalEmailsOptin }
  ) {
    const payload = {
      ...getters['userInfo'],
      email,
      full_name: fullName,
      password,
      general_emails: generalEmailsOptin,
      preferences: getters['userPreferences'],
      payment: getters['userPayment'],
    }
    return apiCredentials.register(payload).then((response) => {
      if (response.subscription) {
        let { token, user } = response.subscription
        commit(`user/${types.SET_USER_DATA}`, user, { root: true })
        commit(`user/${types.SET_ACCESS_TOKEN}`, token, { root: true })
      }
      return response
    })
  },
  registerWithSubscription({ commit, dispatch }, { hostedPageId, planId }) {
    const payload = {
      plan_id: planId,
      hosted_page_id: hostedPageId,
    }

    return apiCheckout.createSubscriptionUser(payload).then((data) => {
      const { token, user } = data
      commit(`user/${types.SET_USER_DATA}`, user, { root: true })
      commit(`user/${types.SET_ACCESS_TOKEN}`, token, { root: true })
      dispatch('trackOnRegister')

      return data
    })
  },
  linkUserWithSubscription({ commit, dispatch }, hostedPageId) {
    const payload = {
      hosted_page_id: hostedPageId,
    }

    return apiCheckout.createSubscriptionUser(payload).then((data) => {
      const { token, user } = data
      commit(`user/${types.SET_USER_DATA}`, user, { root: true })
      commit(`user/${types.SET_ACCESS_TOKEN}`, token, { root: true })
      dispatch('trackOnRegister')

      return data
    })
  },
  googleRegister({ commit, rootGetters }, { id, email, name, photoUrl }) {
    const affiliateRefCode = rootGetters['globals/affiliateCode']
    const impactPartnerId = rootGetters['globals/impactPartnerId']
    const ambassadorId = rootGetters['globals/ambassadorId']
    const campaign = rootGetters['globals/campaign']
    const abGroup = rootGetters['globals/abGroup']
    const giftCode = rootGetters['globals/giftCode']
    const adultServings = rootGetters['registration/adultServings']
    const childServings = rootGetters['registration/childServings']
    const soyIntolerance = rootGetters['registration/soyIntolerance']
    const glutenIntolerance = rootGetters['registration/glutenIntolerance']
    const nutsIntolerance = rootGetters['registration/nutsIntolerance']
    const breakfastOptin = rootGetters['registration/breakfastOptin']
    const lunchOptin = rootGetters['registration/lunchOptin']
    const dinnerOptin = rootGetters['registration/dinnerOptin']
    const snackOptin = rootGetters['registration/snackOptin']
    const dessertOptin = rootGetters['registration/dessertOptin']
    const additionalIntolerances =
      rootGetters['registration/additionalIntolerances']
    const { token, plan, user_agent, browser_ip } =
      rootGetters['registration/paymentInfo']

    return apiCredentials
      .googleRegister(
        email,
        id,
        name,
        photoUrl,
        affiliateRefCode,
        impactPartnerId,
        ambassadorId,
        campaign,
        abGroup,
        giftCode,
        adultServings,
        childServings,
        additionalIntolerances,
        soyIntolerance,
        nutsIntolerance,
        glutenIntolerance,
        breakfastOptin,
        lunchOptin,
        dinnerOptin,
        snackOptin,
        dessertOptin,
        // payment info
        token,
        plan,
        user_agent,
        browser_ip
      )
      .then((response) => {
        if (response.subscription) {
          let { token, user } = response.subscription
          commit(`user/${types.SET_USER_DATA}`, user, { root: true })
          commit(`user/${types.SET_ACCESS_TOKEN}`, token, { root: true })
        }
        return response
      })
  },
  appleRegister({ commit, rootGetters }, { id, email, name, photoUrl }) {
    const affiliateRefCode = rootGetters['globals/affiliateCode']
    const impactPartnerId = rootGetters['globals/impactPartnerId']
    const ambassadorId = rootGetters['globals/ambassadorId']
    const campaign = rootGetters['globals/campaign']
    const abGroup = rootGetters['globals/abGroup']
    const giftCode = rootGetters['globals/giftCode']
    const adultServings = rootGetters['registration/adultServings']
    const childServings = rootGetters['registration/childServings']
    const soyIntolerance = rootGetters['registration/soyIntolerance']
    const glutenIntolerance = rootGetters['registration/glutenIntolerance']
    const nutsIntolerance = rootGetters['registration/nutsIntolerance']
    const breakfastOptin = rootGetters['registration/breakfastOptin']
    const lunchOptin = rootGetters['registration/lunchOptin']
    const dinnerOptin = rootGetters['registration/dinnerOptin']
    const snackOptin = rootGetters['registration/snackOptin']
    const dessertOptin = rootGetters['registration/dessertOptin']
    const additionalIntolerances =
      rootGetters['registration/additionalIntolerances']
    const { token, plan, user_agent, browser_ip } =
      rootGetters['registration/paymentInfo']
    return apiCredentials
      .appleRegister(
        email,
        id,
        name,
        photoUrl,
        affiliateRefCode,
        impactPartnerId,
        ambassadorId,
        campaign,
        abGroup,
        giftCode,
        adultServings,
        childServings,
        additionalIntolerances,
        soyIntolerance,
        nutsIntolerance,
        glutenIntolerance,
        breakfastOptin,
        lunchOptin,
        dinnerOptin,
        snackOptin,
        dessertOptin,
        // payment info
        token,
        plan,
        user_agent,
        browser_ip
      )
      .then((response) => {
        if (response.subscription) {
          let { token, user } = response.subscription
          commit(`user/${types.SET_USER_DATA}`, user, { root: true })
          commit(`user/${types.SET_ACCESS_TOKEN}`, token, { root: true })
        }
        return response
      })
  },
  facebookRegister({ commit, rootGetters }, { id, email, name, photoUrl }) {
    const affiliateRefCode = rootGetters['globals/affiliateCode']
    const impactPartnerId = rootGetters['globals/impactPartnerId']
    const ambassadorId = rootGetters['globals/ambassadorId']
    const campaign = rootGetters['globals/campaign']
    const abGroup = rootGetters['globals/abGroup']
    const giftCode = rootGetters['globals/giftCode']
    const adultServings = rootGetters['registration/adultServings']
    const childServings = rootGetters['registration/childServings']
    const soyIntolerance = rootGetters['registration/soyIntolerance']
    const glutenIntolerance = rootGetters['registration/glutenIntolerance']
    const nutsIntolerance = rootGetters['registration/nutsIntolerance']
    const breakfastOptin = rootGetters['registration/breakfastOptin']
    const lunchOptin = rootGetters['registration/lunchOptin']
    const dinnerOptin = rootGetters['registration/dinnerOptin']
    const snackOptin = rootGetters['registration/snackOptin']
    const dessertOptin = rootGetters['registration/dessertOptin']
    const additionalIntolerances =
      rootGetters['registration/additionalIntolerances']
    const { token, plan, user_agent, browser_ip } =
      rootGetters['registration/paymentInfo']
    return apiCredentials
      .facebookRegister(
        email,
        id,
        name,
        photoUrl,
        affiliateRefCode,
        impactPartnerId,
        ambassadorId,
        campaign,
        abGroup,
        giftCode,
        adultServings,
        childServings,
        additionalIntolerances,
        soyIntolerance,
        nutsIntolerance,
        glutenIntolerance,
        breakfastOptin,
        lunchOptin,
        dinnerOptin,
        snackOptin,
        dessertOptin,
        // payment info
        token,
        plan,
        user_agent,
        browser_ip
      )
      .then((response) => {
        if (response.subscription) {
          let { token, user } = response.subscription
          commit(`user/${types.SET_USER_DATA}`, user, { root: true })
          commit(`user/${types.SET_ACCESS_TOKEN}`, token, { root: true })
        }
        return response
      })
  },
  activatePlan({ commit, rootGetters }, email) {
    const giftCode = rootGetters['globals/giftCode']
    return apiCredentials.activatePlan(email, giftCode).then((response) => {
      let { token, user } = response
      commit(`user/${types.SET_USER_DATA}`, user, { root: true })
      commit(`user/${types.SET_ACCESS_TOKEN}`, token, { root: true })
      return response
    })
  },
  setRegistrationSteps({ commit }, registrationSteps) {
    commit(types.SET_REGISTRATION_STEPS, registrationSteps)
  },
  setUserGoals({ commit }, userGoals) {
    commit(types.SET_USER_GOALS, userGoals)
  },
  setPaymentInfo({ commit }, paymentInfo) {
    commit(types.SET_PAYMENT_INFO, paymentInfo)
  },
  setRegistrationType({ commit }, registrationType) {
    commit(types.SET_REGISTRATION_TYPE, registrationType)
  },
  setFacebookUserInfo({ commit }, facebookUserInfo) {
    commit(types.SET_FACEBOOK_USER_INFO, facebookUserInfo)
  },
  setGoogleUserInfo({ commit }, googleUserInfo) {
    commit(types.SET_GOOGLE_USER_INFO, googleUserInfo)
  },
  setAppleUserInfo({ commit }, appleUserInfo) {
    commit(types.SET_APPLE_USER_INFO, appleUserInfo)
  },
  trackOnRegister({ getters }, userId) {
    const { aff_ref, full_name, email, irpid, campaign, general_emails } =
      getters['userInfo']
    const userTraits = {
      method: 'email',
      'date account created': moment().format('DD/MM/YYYY'),
      name: full_name,
      $email: getters['email'],
      affiliate: aff_ref,
      'impact partner id': irpid,
      campaign,
      'newsletter opt in': general_emails,
      ...getters['userPreferences'],
    }

    AnalyticsServices.register(userId, email, userTraits, {})
    AnalyticsServices.track(events.register.accountCreated, userTraits)
    AnalyticsServices.track(events.register.completed, userTraits)
    ProfitWell.start(email)
  },
}

// mutations
const mutations = {
  [types.SET_REGISTRATION_TYPE](state, registrationType) {
    state.registrationType = registrationType
  },
  [types.SET_FACEBOOK_USER_INFO](state, facebookUserInfo) {
    state.facebookUserInfo = facebookUserInfo
  },
  [types.SET_GOOGLE_USER_INFO](state, googleUserInfo) {
    state.googleUserInfo = googleUserInfo
  },
  [types.SET_APPLE_USER_INFO](state, appleUserInfo) {
    state.appleUserInfo = appleUserInfo
  },
  [types.SET_PAYMENT_INFO](state, paymentInfo) {
    state.paymentInfo = paymentInfo
  },
  [types.SET_USER_GOALS](state, userGoals) {
    state.userGoals = userGoals
  },
  [types.SET_REGISTRATION_STEPS](state, registrationSteps) {
    state.registrationSteps = registrationSteps
  },
  [types.ADULT_SERVINGS](state, servings) {
    state.adultServings = servings
  },
  [types.CHILD_SERVINGS](state, servings) {
    state.childServings = servings
  },
  [types.HAS_INTOLERANCES](state, hasIntolerances) {
    state.hasIntolerances = hasIntolerances
  },
  [types.SOY_INTOLERANCE](state, value) {
    state.soyIntolerance = value
  },
  [types.NUTS_INTOLERANCE](state, value) {
    state.nutsIntolerance = value
  },
  [types.GLUTEN_INTOLERANCE](state, value) {
    state.glutenIntolerance = value
  },
  [types.ADDITIONAL_INTOLERANCES](state, value) {
    state.additionalIntolerances = value
  },
  [types.BREAKFAST_OPTIN](state, value) {
    state.breakfastOptin = value
  },
  [types.LUNCH_OPTIN](state, value) {
    state.lunchOptin = value
  },
  [types.DINNER_OPTIN](state, value) {
    state.dinnerOptin = value
  },
  [types.SNACK_OPTIN](state, value) {
    state.snackOptin = value
  },
  [types.DESSERT_OPTIN](state, value) {
    state.dessertOptin = value
  },
  [types.USER_EMAIL](state, value) {
    state.userData.email = value
  },
  [types.USER_FULL_NAME](state, value) {
    state.userData.fullName = value
  },
  [types.USER_PASSWORD](state, value) {
    state.userData.password = value
  },
  [types.USER_GENERAL_EMAILS_OPTIN](state, value) {
    state.userData.generalEmailsOptin = value
  },
  [types.REGISTRATION_STEP_DONE](state, step) {
    state.registrationSteps[step - 1].done = true
  },
  [types.REVERSE_REGISTRATION_STEP_DONE](state, step) {
    state.reverseRegistrationSteps[step - 1].done = true
  },
  [types.CURRENT_REGISTRATION_STEP](state, step) {
    state.currentRegistrationStep = step
  },
  [types.CURRENT_REVERSE_REGISTRATION_STEP](state, step) {
    state.currentReverseRegistrationStep = step
  },
  [types.REGISTRATION_ERROR](state, error) {
    state.registrationError = error
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
