<template>
  <div class="mp-spinner" :class="{ overlay: withOverlay }">
    <MPNotification
      v-if="longWaitText"
      type="offer"
      :title="longWaitText"
      class="full full-with-navbar"
    />
    <div class="loader" :class="`loader--${color} loader--${size}`"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'large',
    },
    withOverlay: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: mapGetters('globals', ['longWaitText']),
}
</script>

<style lang="scss" scoped>
@keyframes spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(#000, 0.5);
  z-index: 9999;
}

.wait-text {
  color: $white;
  font-size: 1rem;
  margin-top: 1rem;
  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
}

.loader {
  border-radius: 50%;
  text-indent: -9999em;
  border-style: solid;
  border-width: 5px;
  transform: translateZ(0);
  animation: spinner-animation 1.1s infinite linear;
  z-index: 10000;

  &--large {
    width: 80px;
    height: 80px;
  }

  &--small {
    width: 20px;
    height: 20px;
  }

  &--orange {
    border-color: rgba(255, 142, 68, 0.2);
    border-top-color: $orange;
  }
  &--white {
    border-color: rgba(255, 255, 255, 0.2);
    border-top-color: white;
  }
  &--primary {
    border-color: rgba(255, 255, 255, 0.2);
    border-top-color: $primary;
  }
}
</style>
