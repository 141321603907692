<template>
  <div class="add-to-plan">
    <div class="list" v-for="(list, listIndex) in plan" :key="listIndex">
      <h4 class="list-day">DAY {{ listIndex + 1 }}</h4>
      <div class="list-items">
        <div
          class="list-item recipe"
          :class="{
            'list-item--remove': item.isSelected,
            'list-item--switch': !item.isSelected,
          }"
          v-for="(item, itemIndex) in list.items"
          :key="itemIndex"
          @click="onItemClick(item, listIndex)"
        >
          <i class="list-item-icon"></i>
          <span class="list-item-title">
            {{ item.title }}
          </span>
        </div>
        <div
          class="list-item list-item--add"
          v-if="list.actionStatus !== 'hidden'"
          @click="onAddDayClick(list.items.length, listIndex)"
        >
          + Add to day {{ listIndex + 1 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    plan: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onItemClick(item, listIndex) {
      const { isSelected, mealType, id } = item
      if (isSelected) {
        this.$emit('remove-meal', id)
      } else {
        this.$emit('add-or-swap-meal', mealType, listIndex)
      }
    },
    onAddDayClick(mealType, listIndex) {
      this.$emit('add-or-swap-meal', mealType, listIndex)
    },
  },
  getEmitKey(isSelected) {
    return isSelected ? 'remove-meal' : 'add-or-swap-meal'
  },
}
</script>

<style lang="scss" scoped>
.list-day {
  font-weight: bold;
  font-size: $h4-font-size;
  display: block;
  user-select: none;
  background-color: $clr-navy-5;
  padding: 10px 0 10px 1rem;
  line-height: 1.13;
  letter-spacing: 0.5px;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

.list-items {
  margin-bottom: 10px;
}

.list-item-icon {
  width: 22px;
  height: 22px;
  background-position: center;
  background-repeat: no-repeat;
  border-width: 0;
  margin-right: 0.5rem;
  flex: 0 0 22px;
}

.list-item {
  min-height: 35px;
  padding: 0.2rem 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  &--add {
    color: $primary;
    font-size: 1.2rem;
    justify-content: center;
  }
  &--switch {
    .list-item-icon {
      border-color: $dark-grey;
      background-image: url('~@/assets/icons/swapwater.png');
    }
    &:hover {
      color: $link-hover-color;
      .list-item-icon {
        border-color: $link-hover-color;
      }
    }
  }
  &--remove {
    .list-item-icon {
      background-image: url('~@/assets/icons/binwater.png');
    }
    &:hover {
      color: $error;
      .list-item-icon {
        background-color: $error;
        border-color: $error;
        background-image: url('~@/assets/icons/delete-active.svg');
      }
    }
  }

  &.recipe {
    padding: 0.7rem 0;
  }
}
</style>
