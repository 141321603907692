import _ from 'lodash'

export function buildUnitTestRegex(unitList, parameterName) {
  const allUnits = unitList.reduce((accumulator, currentValue) => {
    if (currentValue[parameterName].indexOf(',') > -1) {
      accumulator = accumulator.concat(currentValue[parameterName].split(','))
    } else {
      if (currentValue[parameterName]) {
        accumulator.push(currentValue[parameterName])
      }
    }
    return accumulator
  }, [])
  const uniqUnits = _.uniq(allUnits)
  const joinedUnits = `\\b(${uniqUnits.join('|')})\\b`
  const joinedUnitsRegex = new RegExp(joinedUnits)
  return joinedUnitsRegex
}
export function testIfUnitInValue(unitRegularExpression, value) {
  return unitRegularExpression.test(value)
}

export function testIfNumberInValue(value) {
  return /[0-9]+/.test(value)
}
