<template>
  <div class="empty-item">
    <div class="content">
      <div class="thumb"></div>
      <div class="title-container">
        <span class="title">No meal scheduled</span>
      </div>
      <div class="options" @click="addRecipe">+</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    addRecipe() {
      this.$emit('addRecipe')
    },
  },
}
</script>

<style lang="scss" scoped>
.empty-item {
  display: flex;
  padding: 0 15px;
  background-color: #fff;
}

.content {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f5;
}

.empty-item:last-child {
  .content {
    border-bottom: none;
  }
}

.thumb {
  height: 55px;
  width: 80px;
  border-radius: 3px;
  background-color: #f2f2f5;
}

.title-container {
  flex: 1;
}

.title {
  height: 1rem;
  width: 70%;
  min-width: 120px;
  margin: 0 15px;
  color: #757575;
}

.options {
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 50%;
  border: 1px solid #ff8e44;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #ff8e44;
  cursor: pointer;
}
</style>
