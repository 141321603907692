import Analytics from '../externalServices/analytics'

export const experiments = {
  register: 'MP - Experiments - Register',
}

const activatedExperiments = {}

export function startExperiment(experiment) {
  if (!activatedExperiments[experiment]) {
    activatedExperiments[experiment] = true
    Analytics.tagForGoogleOptimize(experiment)
    Analytics.tagForGoogleOptimize('optimize.callback', {
      callback: trackExperimentStarted,
    })
    Analytics.tagForGoogleOptimize('optimize.callback', {
      callback: trackExperimentStarted,
      remove: true,
    })
  }
}

function trackExperimentStarted(variant, name) {
  Analytics.track('$experiment_started', {
    'Experiment name': name,
    'Variant name': variant,
  })
}
