<template>
  <div class="mp-alert">
    <transition name="fade">
      <div
        class="alert fade show"
        :class="{
          'alert-dismissible': isDismissable,
          'alert-danger': type == 'error',
          'alert-success': type == 'success',
          'alert-outline': type == 'outline',
          'alert-warning': type == 'warning',
        }"
        role="alert"
        v-if="text || $slots.default"
      >
        <v-runtime-template
          v-if="text"
          :template="`<span>${text}</span>`"
        ></v-runtime-template>
        <slot></slot>
        <button
          v-if="isDismissable"
          @click.stop="$emit('dismiss')"
          type="button"
          class="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import VRuntimeTemplate from 'v-runtime-template'

export default {
  name: 'MPAlert',
  components: { VRuntimeTemplate },
  props: {
    text: {
      type: String,
      required: false,
      default: null,
      validator: (prop) => typeof prop === 'string' || prop === null,
    },
    isDismissable: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'error',
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.mp-alert {
  text-align: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
button:focus {
  outline: 0;
}

.alert-outline {
  border: 1px solid rgb(216, 216, 218);
}
.alert-dismissible {
  padding-right: 2rem;
  .close {
    padding: 0.75rem;
  }
}
</style>
