export const events = {
  loginPage: {
    view: 'MP - Login Page - View',
  },
  landingPage: {
    view: 'MP - Login Page - View',
    getStarted: {
      clicked: 'MP - Landing - Get Started Clicked',
    },
  },
  join: {
    clicked: 'MP - Join - Clicked',
  },
  register: {
    goalsPage: {
      nextClicked: 'MP - Register - Goals Page - Next Clicked',
      goalsChosen: 'MP - Register - Goals Page - Goals Chosen',
      view: 'MP - Register - Goals Page - View',
    },
    servingPage: {
      nextClicked: 'MP - Register - Servings Page - Next Clicked',
    },
    allergiesPage: {
      nextClicked: 'MP - Register - Allergies Page - Next Clicked',
    },
    scheduledMeals: {
      nextClicked: 'MP - Register - Scheduled Meals - Next Clicked',
    },
    completed: 'MP - Register - Completed',
    saveMealPlan: {
      onlyMail: {
        nextClicked:
          'MP - Register - Save Your Meal Plan - Only Email Next Clicked',
      },
      allFields: {
        nextClicked:
          'MP - Register - Save Your Meal Plan - All Fields Next Clicked',
      },
      socialLogin: 'MP - Register - Save Your Meal Plan - Social Login',
    },
    showMeMyMealPlan: 'MP - Register - Show Me My Meal Plan',
    accountCreated: 'MP - Register - Account Created',
    socialWithEmail: 'MP - Register - Social with email',
  },
  account: {
    showPlan: {
      clicked: 'MP - Account - Show Plan - Clicked',
    },
  },
  dashboard: {
    view: 'MP - Dashboard - View',
    recipe: {
      moved: 'MP - Dashboard - Recipe moved',
      added: 'MP - Dashboard - Recipe Added',
      swapped: 'MP - Dashboard - Recipe Swapped',
      removed: 'MP - Dashboard - Recipe Removed',
    },
    customPlan: {
      created: 'MP - Custom Plan - Created',
    },
    viewFeatured: 'View Featured',
  },
  trial: {
    started: 'MP - Trial Started',
    extended: 'MP - Trial Extended',
  },
  tooltour: {
    click: 'MP - Tooltour - Click',
    lifecycle: 'MP - Tooltour - Lifecycle',
  },
  nutrition: {
    switch: 'Switch - Nutritional Tracking',
  },
  settings: {
    pressureCooking: 'MP - Settings - Pressure Cooker',
  },
  groceryList: {
    view: 'MP - Grocery List - View',
    buyAmazonFresh: 'MP - Grocery List - Buy on Amazon Fresh',
    checkItem: 'MP - Grocery List - Checked item',
  },
  recipeBox: {
    add: 'MP - Recipe Box - Add personal recipe',
  },
  personalRecipe: {
    step1: 'MP - Personal Recipe - Step 1 completed',
    step2: 'MP - Personal Recipe - Step 2 completed',
    done: 'MP - Personal Recipe - Done',
    saveAndExit: 'MP - Personal Recipe - Save and Exit',
    delete: 'MP - Personal Recipe - Deleted',
  },
  recipe: {
    boxView: 'MP - Recipe Box - View',
    view: 'MP - Recipe - View',
    addedFrom: {
      recipeBox: ' MP - Recipe - Added from recipe box ',
    },
    rated: ' MP - Recipe - Rated',
    sendFeedback: 'MP - Recipe - Send Feedback',
    favorited: ' MP - Recipe - Favorited',
    printed: 'MP - Recipe - Printed',
  },
  support: {
    contact: 'MP - Support Contact',
  },
  weekendPrep: {
    view: 'MP - Weekend Prep - View',
    changeSpeedPrepItem: 'MP - Weekend Prep - Change Speed Prep item',
    savePrepNote: 'MP - Weekend Prep - Save Prep Note',
  },
  foksqueezepage: {
    view: 'MP - FOK Squeeze Page - View',
  },
  plan: {
    purchased: 'MP - Plan Purchased',
  },
  filters: {
    included: 'MP - Filters - Include',
    exclude: 'MP - Filters - Exclude',
    remove: 'MP - Filters - Remove',
    check: 'MP - Filters - Item Check',
    value: 'MP - Filters - By Value',
    clear: 'MP - Filters - Clear All',
  },
}
