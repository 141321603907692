import { render, staticRenderFns } from "./MPNotification.vue?vue&type=template&id=6cc55272&scoped=true"
import script from "./MPNotification.vue?vue&type=script&lang=js"
export * from "./MPNotification.vue?vue&type=script&lang=js"
import style0 from "./MPNotification.vue?vue&type=style&index=0&id=6cc55272&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cc55272",
  null
  
)

export default component.exports