<template>
  <div class="recipe-ingredient-item d-flex">
    <div class="flex-grow-1">
      <span
        v-if="recipeIngredientComputed.quantityFraction"
        v-html="recipeIngredientComputed.quantityFraction"
      >
      </span>
      <span v-if="recipeIngredientComputed.unit">
        {{ recipeIngredientComputed.unit }}
      </span>
      <span v-if="recipeIngredient.ingredient">
        {{ recipeIngredientComputed.ingredient
        }}<span v-if="recipeIngredientComputed.prepDescription">,</span>
      </span>
      <span v-if="recipeIngredientComputed.prepDescription">
        {{ recipeIngredientComputed.prepDescription }}
      </span>
    </div>
    <div class="flex-shrink-0 item-controls">
      <span class="delete-icon" @click="setShowDeleteConfirmModal(true)"></span>
      <span @click="edit()" class="edit-icon ml-3"></span>
    </div>
    <MPConfirmModal
      class="recipe-ingredient-delete-confirm-modal"
      :show="showDeleteConfirmModal"
    >
      <div class="text-center">
        <b>
          Are you sure you want to remove <br />
          {{ recipeIngredientComputed.ingredient }} from the recipe?
        </b>
        <div class="d-flex px-4 my-4 justify-content-center">
          <MPButton color="grey-outline" @click="no()" class="mr-4">
            Cancel
          </MPButton>
          <MPButton color="danger" @click="yes()"> Delete </MPButton>
        </div>
      </div>
    </MPConfirmModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { toFraction } from '@/utils/conversions'
import _ from 'lodash'

export default {
  name: 'AddCustomRecipeIngredientItemB',
  props: {
    recipeIngredient: {
      required: true,
    },
  },
  data() {
    return { showDeleteConfirmModal: false }
  },
  computed: {
    ...mapGetters('recipe', [
      'unitList',
      'ingredientList',
      'prepDescriptionList',
    ]),
    recipeIngredientComputed() {
      const recipeIngredient = this.recipeIngredient
      const id = recipeIngredient.id
      const quantity = recipeIngredient.quantity
      const quantityFraction = recipeIngredient.quantity
        ? toFraction(recipeIngredient.quantity)
        : null
      const unit =
        typeof recipeIngredient.unit == 'string'
          ? recipeIngredient.unit
          : _.get(recipeIngredient.unit, 'name', '')
      const ingredient = _.get(
        recipeIngredient.ingredient,
        quantity > 1 ? 'pluralName' : 'singularName',
        recipeIngredient.ingredient
      )
      const prepDescription = _.get(
        recipeIngredient.prepDescription,
        'text',
        recipeIngredient.prepDescription
      )
      const returnObject = {
        id,
        quantity,
        unit,
        ingredient,
        prepDescription,
        quantityFraction,
      }
      return returnObject
    },
  },
  methods: {
    edit() {
      this.$emit('edit', Object.assign({}, this.recipeIngredient))
    },
    yes() {
      this.$emit('delete', this.recipeIngredient)
      this.setShowDeleteConfirmModal(false)
    },
    no() {
      this.setShowDeleteConfirmModal(false)
    },
    setShowDeleteConfirmModal(value) {
      this.showDeleteConfirmModal = value
    },
  },
}
</script>

<style lang="scss" scoped>
.recipe-ingredient-item {
  border-bottom: 1px solid $light-grey;
  padding: 0.8rem 1rem 0.8rem 2rem;
  position: relative;
  color: $dark-grey;
  font-size: 0.875rem;
  &::before {
    position: absolute;
    left: 5px;
    top: 10px;
    width: 16px;
    height: 22px;
    content: '';
    background: url('~@/assets/icons/add-recipe-move.svg');
    cursor: ns-resize;
  }
  .edit-icon,
  .delete-icon {
    display: inline-block;
  }
}
.item-controls {
  height: 19px;
}
.delete-icon {
  display: none;
  cursor: pointer;
  background: url('~@/assets/icons/bin.svg');
  background-size: auto, 0;
  width: 24px;
  height: 24px;
  background-size: contain;
}
.edit-icon {
  display: none;
  cursor: pointer;
  background: url('~@/assets/icons/edit.svg');
  background-size: auto, 0;
  width: 24px;
  height: 24px;
  background-size: contain;
}
</style>
<style lang="scss">
.recipe-ingredient-delete-confirm-modal {
  .modal-content {
    min-height: 180px !important;
  }
  .modal-dialog {
    min-width: 696px !important;
  }
  b {
    font-size: rem(20);
    line-height: 1.2;
    color: $body-color;
  }
  .btn {
    min-width: 175px;
    text-transform: uppercase;
  }
}
</style>
