<template>
  <keep-alive>
    <component :is="currentComponent" />
  </keep-alive>
</template>

<script>
import { mapGetters } from 'vuex'
import TheRecipe from '@/components/recipe/TheRecipe'
import TheCustomRecipe from '@/components/recipe/TheCustomRecipe'
import TheRecipeLocked from '@/components/recipe/TheRecipeLocked'
import TheRecipeLoading from '@/components/recipe/TheRecipeLoading'

export default {
  name: 'RecipeView',
  components: { TheRecipe, TheCustomRecipe, TheRecipeLocked, TheRecipeLoading },
  computed: {
    ...mapGetters({
      recipeViewType: 'recipe/recipeViewType',
    }),
    currentComponent() {
      switch (this.recipeViewType) {
        case 'recipe':
          return 'TheRecipe'
        case 'custom':
          return 'TheCustomRecipe'
        case 'locked':
          return 'TheRecipeLocked'
        case 'loading':
        default:
          return 'TheRecipeLoading'
      }
    },
  },
}
</script>

<style></style>
