<template>
  <section class="side-navbar">
    <section class="navbar-container">
      <h1 class="navbar-logo">
        <a href="#">Forks Meal Planner</a>
      </h1>
      <div class="navbar-wrapper">
        <aside class="navbar-nav-wrapper">
          <nav class="navbar-nav">
            <ul>
              <li class="onboarding-step-1-selector">
                <router-link
                  to="/plan"
                  title="Plan Dashboard"
                  class="ico ico-dashboard"
                  id="menu-nav-plan"
                >
                  <Lock
                    width="24"
                    v-if="isMealPlannerLocked || isDashboardLocked"
                    class="nav-icon nav-icon--lock"
                  />
                  <PlanDashboard
                    v-else
                    width="26"
                    class="nav-icon nav-icon--dashboard"
                  />
                  Plan Dashboard
                </router-link>
              </li>

              <li class="grocery-list-onboarding-step-1-selector">
                <router-link
                  to="/grocery-list"
                  title="Grocery List"
                  class="ico ico-grocery"
                  id="menu-nav-grocery-list"
                >
                  <Lock
                    width="24"
                    v-if="isMealPlannerLocked || isDashboardLocked"
                    class="nav-icon nav-icon--lock"
                  />
                  <GroceryList
                    v-else
                    width="21"
                    class="nav-icon nav-icon--grocery-list"
                  />
                  Grocery List
                </router-link>
              </li>

              <li class="weekend-prep-onboarding-step-1-selector">
                <router-link
                  to="/prep-ahead"
                  title="Weekend Prep"
                  id="menu-nav-weekend-prep"
                  class="ico-weekend-prep"
                >
                  <Lock
                    width="24"
                    v-if="isMealPlannerLocked || isDashboardLocked"
                    class="nav-icon nav-icon--lock"
                  />
                  <WeekendPrep
                    v-else
                    width="29"
                    class="nav-icon nav-icon--weekend-prep"
                  />
                  Weekend Prep
                </router-link>
              </li>

              <li class="recipe-box-onboarding-step-1-selector">
                <router-link
                  to="/recipe-box"
                  title="Recipe Box"
                  id="menu-nav-recipe-box"
                  class="ico-recipe-box"
                  :disabled="isMealPlannerLocked"
                >
                  <Lock
                    width="24"
                    v-if="isMealPlannerLocked || isDashboardLocked"
                    class="nav-icon nav-icon--lock"
                  />
                  <RecipeBox
                    v-else
                    width="31"
                    class="nav-icon nav-icon--recipe-box"
                  />
                  Recipe Box
                </router-link>
              </li>
              <li class="personal-onboarding-step-1-selector">
                <router-link
                  to="/personal-recipes"
                  title="Personal Recipes"
                  class="ico-recipe-box"
                  id="menu-nav-personal"
                >
                  <Lock
                    width="24"
                    v-if="isMealPlannerLocked || isDashboardLocked"
                    class="nav-icon nav-icon--lock"
                  />
                  <ChefHat
                    width="24"
                    height="24"
                    v-else
                    class="nav-icon nav-icon--personal"
                  />
                  Personal Recipes
                </router-link>
              </li>
            </ul>
          </nav>
        </aside>
        <div
          class="navbar-upgrade-button onboarding-step-5-selector"
          v-if="isFreeUser"
        >
          <button
            class="btn-primary"
            @click="goToPremium({ tag: 'navbar' })"
            data-spec="upgrade"
          >
            <span
              class="d-none d-md-block d-lg-none"
              v-html="getUpgradeButtonTextTablet()"
            ></span>
            <span
              class="d-md-none d-lg-block"
              v-html="getUpgradeButtonTextDesktop()"
            ></span>
          </button>
        </div>
        <div
          class="d-none d-md-block d-lg-none navbar-trial-content-text"
          v-if="isFreeUser"
        >
          <div class="text" @click="goToPremium({ tag: 'navbar' })">
            <span
              v-if="
                textTrialExpiresIn && !userDiscount.discount && !userExpired
              "
              >{{ getSubtitleDesktop() }}</span
            >
            <span v-if="!userDiscount.discount && userExpired"
              >Join from $1.85/week</span
            >
          </div>
        </div>
        <div class="text-center d-none d-lg-block" v-if="isFreeUser">
          <DiscountCountdownText
            v-if="showDiscountCountdown() && userDiscount.discount"
            :date="new Date(userDiscount.expiryDate)"
            :discount="userDiscount.discount"
          />
        </div>
      </div>

      <footer class="text-white footer-menu">
        <div class="col'12 px-4">
          <div class="user-title text-truncate">
            <b>
              <span class="footer-menu-welcome" data-spec="welcome">
                Welcome
              </span>
              <span class="footer-menu-username" data-spec="user-name">
                {{ userName }}
              </span>
            </b>
          </div>
          <div class="user-subtitle">
            <span
              class="d-none d-md-block d-lg-none text-truncate"
              v-html="userEmail"
            ></span>
            <div
              class="d-md-none d-lg-block text-truncate"
              @click="goToPremium({ tag: 'navbar' })"
            >
              <span
                v-if="getSubtitleDesktop()"
                v-html="getSubtitleDesktop()"
              ></span>
            </div>
          </div>
        </div>
      </footer>
      <div class="navbar-footer-bottom">
        <b-link
          to="/settings/profile"
          title="Settings"
          id="menu-nav-user-account"
          class="text-white collapsed-menu py-1 d-flex onboarding-step-4-selector"
          data-spec="settings"
        >
          <SettingsWheel width="24" />
          <span class="pl-2"> Settings </span>
        </b-link>
        <a
          href="https://support.forksmealplanner.com/"
          id="menu-nav-user-help"
          class="text-white py-1 d-flex"
          target="_blank"
          @click="onHelpAndFAQClick()"
        >
          <QuestionMark width="24" />
          <span class="pl-2"> Help & FAQs </span>
        </a>
        <b-link
          to=""
          title="Logout"
          id="menu-nav-user-logout"
          @click.prevent="logout()"
          class="text-white py-1 d-flex"
        >
          <Logout width="24" />
          <span class="pl-2" data-spec="log-out"> Log Out </span>
        </b-link>
      </div>
    </section>
  </section>
</template>

<script>
import menuItemsData from '@/components/recipebox/menuitems/menuItemsData'
import DiscountCountdownText from '@/components/navigation/DiscountCountdownText'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { resetState } from '@/store'
import _ from 'lodash'
import ExternalServices from '@/externalServices'
import {
  QuestionMark,
  Logout,
  PlanDashboard,
  GroceryList,
  WeekendPrep,
  RecipeBox,
  ChefHat,
  Lock,
  SettingsWheel,
} from '@/components/common/MPIcons'

export default {
  name: 'SideNavbar',
  components: {
    QuestionMark,
    Logout,
    PlanDashboard,
    GroceryList,
    WeekendPrep,
    RecipeBox,
    Lock,
    ChefHat,
    DiscountCountdownText,
    SettingsWheel,
  },
  computed: {
    ...mapGetters({
      isActiveUser: 'user/isActive',
      isFreeUser: 'user/isFree',
      userName: 'user/name',
      userEmail: 'user/email',
      isMealPlannerLocked: 'globals/isMealPlannerLocked',
      isDashboardLocked: 'dashboard/isDashboardLocked',
      userDiscount: 'user/planDiscount',
      userExpired: 'headers/userExpired',
      trialExpiresIn: 'headers/expiresIn',
      filterByTypeSelected: 'RecipeBox/filterByTypeSelected',
    }),
    textTrialExpiresIn() {
      if (!this.trialExpiresIn) {
        return null
      }
      return `${this.trialExpiresIn} day${this.trialExpiresIn > 1 ? 's' : ''}`
    },
  },
  data() {
    return {
      toThursday: {
        hours: 0,
        minutes: 0,
        milliseconds: 0,
        unixTimestamp: 0,
      },
      ABTestRecipeBoxIcon: false,
      filterByTypeItems: menuItemsData
        .getFilterByTypeItems()
        .filter((menuData) => menuData.filterAction != 'is-personal'),
    }
  },
  methods: {
    calcThursday() {
      var thursday = moment().day('Thursday').hour(0).minute(0).second(0)
      var now = moment()

      this.toThursday.hours = thursday.diff(now, 'hours')
      this.toThursday.minutes = thursday.diff(now, 'minutes')
      this.toThursday.milliseconds = thursday.diff(now, 'milliseconds')
      this.toThursday.unixTimestamp = thursday.unix() * 1000
    },
    isRouteActive(routeName) {
      return this.$route == routeName
    },
    getUpgradeButtonTextTablet() {
      const discount = _.get(this.userDiscount, 'discount', null)
      return discount ? this.userDiscount.promoButtonCtaLabel : 'UPGRADE'
    },
    getUpgradeButtonTextDesktop() {
      const discount = _.get(this.userDiscount, 'discount', null)
      return discount ? this.userDiscount.promoButtonCtaLabel : 'UPGRADE'
    },
    showDiscountCountdown() {
      const expiryDate = this.userDiscount
        ? new Date(this.userDiscount.expiryDate)
        : null
      if (!expiryDate) {
        return false
      }
      return expiryDate.getTime() - Date.now() <= 3 * 24 * 60 * 60 * 1000 // 2 days
    },
    goToPremium(query) {
      if (this.isActiveUser) return
      const eventProperties = {}
      eventProperties.location = 'SideNavbar'
      this.$analytics.track('mp upgrade clicked', eventProperties)
      this.$router.push({ path: '/reactivate', query: query })
    },
    getSubtitleDesktop() {
      const textTrialExpiresIn = this.textTrialExpiresIn
      if (this.isActiveUser || (!this.userExpired && !textTrialExpiresIn)) {
        return this.userEmail
      } else if (
        !this.userDiscount.discount &&
        this.userExpired &&
        this.isFreeUser
      ) {
        return 'Join from $1.85/week'
      } else if (!this.userExpired && textTrialExpiresIn && this.isFreeUser) {
        return textTrialExpiresIn + ' left in trial'
      } else {
        return ''
      }
    },
    logout() {
      resetState()
      this.$router.replace('/login')
    },
    onHelpAndFAQClick() {
      this.$analytics.track('mp help view')
    },
    filterAndRedirect(filterItem) {
      const { trackName } = filterItem
      if (trackName) this.$analytics.track(trackName)
      if (
        !this.filterByTypeSelected.filterAction ||
        this.filterByTypeSelected.filterAction !== filterItem.filterAction
      ) {
        this.$store.dispatch('RecipeBox/setFilterByTypeSelected', filterItem)
      }

      this.$router.replace('/recipe-box')
    },
  },
  filters: {
    initials: function (input) {
      if (!input) {
        return ''
      }
      var matches = input.match(/\b(\w)/g)
      return matches.join('').slice(0, 2).toUpperCase()
    },
  },
  mounted() {
    this.calcThursday()

    setTimeout(() => {
      this.ABTestRecipeBoxIcon = ExternalServices.ABTests.ABTestRecipeBoxIcon
    }, 200)
  },
}
</script>

<style lang="scss" scoped>
.side-navbar {
  width: $navbar-width-large;
  background-color: $clr-navy-70;
  z-index: 15;
  transition: width 0.25s linear 0s;
  flex-direction: column;

  @include media-breakpoint-only(md) {
    width: $navbar-width-medium;
  }

  hr {
    width: 100%;
    margin: 0;
    border-top: solid 1px $clr-navy-70;
  }

  .navbar-container {
    background-color: $clr-navy;
    position: fixed;
    width: 312px;
    z-index: 15;
    display: flex;
    flex-direction: column;
    height: 100%;
    @include media-breakpoint-only(md) {
      width: $navbar-width-medium;
    }
  }

  .navbar-logo {
    display: block;
    padding: 0;
    width: 100%;
    height: 138px;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-color: transparent;
    background-image: url('~@/assets/images/fok-mp-new-logo-white.svg');
    background-position: center 35px;
    border-bottom: solid 1px $clr-navy-70;
    background-size: 270px auto;

    a {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .navbar-wrapper {
    flex: 1;
    padding: 0 21px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-only(md) {
      padding: 1rem 0 0 0;
    }

    > .navbar-upgrade-button {
      padding: 0;

      > button {
        width: 100%;
      }
    }
  }

  .navbar-nav-wrapper {
    margin-bottom: 1rem;
  }

  .navbar-nav {
    ul {
      margin: 0;
    }

    li {
      margin: 23px 0;
      list-style: none;
    }

    a {
      display: block;
      font-size: $h4-font-size;
      font-weight: bold;
      line-height: 1.33;
      color: $white;
      text-decoration: none;

      &.router-link-active {
        color: $clr-water;
      }

      &:hover {
        color: $clr-water;
      }

      &.router-link-active:hover {
        opacity: 1;
      }
    }

    .share-earn,
    .help-link {
      font-size: 16px;
    }
  }

  // ====== Icons
  .ico {
    &.router-link-active,
    &:hover,
    &.router-link-active path {
      color: $link-color;
      fill: $clr-water;
    }
  }

  .nav-icon {
    &--dashboard {
      margin-right: 8px;
      margin-top: -3px;
    }

    &--grocery-list {
      margin-right: 13px;
      margin-top: -3px;
    }

    &--weekend-prep {
      margin-right: 6px;
      margin-top: -3px;
    }

    &--recipe-box {
      margin-right: 6px;
      margin-top: -5px;
    }

    &--personal {
      margin-right: 6px;
      margin-left: 3px;
      margin-top: -5px;
    }

    &--share {
      margin-top: -3px;
    }

    &--lock {
      margin-top: -5px;
    }
  }

  .ico-lock {
    background-image: url('~@/assets/icons/navbar/menu-locked.svg');
    background-repeat: no-repeat;
    background-position: 5px 0%;
    background-size: 15px 17px;
    @include media-breakpoint-only(md) {
      background-position: center 0%;
      background-size: 20px 23px;
    }

    &.router-link-active,
    &:hover {
      background-image: url('~@/assets/icons/navbar/menu-locked-hover.svg');
    }
  }

  .share-and-earn-and-help {
    min-height: 50px;
  }

  .share-earn-help-nav {
    display: grid;
    grid-row-gap: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .ico-heart {
    user-select: none;
  }
}

.footer-menu {
  padding: 0.625rem 0;
  cursor: default;

  &-icon {
    padding-left: 2.5rem;
    padding-top: 0.5rem;
  }

  &-welcome {
    text-transform: uppercase;
  }

  &-username {
    color: $clr-water;
  }
}

.user-title {
  max-width: 85%;
}

.navbar-footer-bottom {
  bottom: 0;
  border-top: solid 1px $clr-navy-70;
  background-color: $clr-navy;
  font-weight: 700;
  padding: 10px 21px;

  > a:hover {
    color: $clr-water !important;
  }
}
</style>
