<template>
  <aside class="features-section d-lg-flex">
    <div class="container features-section-container">
      <div class="features-section-content">
        <img
          class="premium-v3-logo"
          :src="require('@/assets/images/fok-mp-new-logo.svg')"
        />
        <div class="row justify-content-center">
          <div class="col-auto col-lg-12 mt-3 mt-lg-0">
            <MembershipFeatures />
          </div>
        </div>
        <div class="row quote-container no-gutters">
          <Testimonial />
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import MembershipFeatures from './MembershipFeatures'
import Testimonial from './Testimonial'

export default {
  components: {
    MembershipFeatures,
    Testimonial,
  },
  data() {
    return {
      quote: `“Without this plan I would not be successful at following a whole-food, plant-based diet. I'm now much more confident putting a dish together... I don't want to eat any other way!”`,
    }
  },
}
</script>

<style lang="scss" scoped>
$tick-width: 24px;
$tick-margin-right: 11px;
.premium-v3-logo {
  margin-bottom: 37px;
  margin-left: $tick-width + $tick-margin-right;
  width: 280px;
}

.features-section {
  display: none;
  flex-direction: column;
  order: 2;
  @include media-breakpoint-up(lg) {
    order: 1;
  }
}

.features-section-container {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  padding-left: 10px;
  @include media-breakpoint-up(lg) {
    padding-left: 20px;
  }
  @include media-breakpoint-up(xl) {
    padding-left: 55px;
  }
}

.features-section-content {
  width: 410px;
  padding: 35px 19px 33px 26px;
  background-color: rgba(255, 255, 255, 0.95);
  @media (max-width: 1089px) {
    width: 365px;
    padding: 35px 10px 33px 15px;
  }
}

.quote-container {
  border-top: solid 1px $clr-navy-50;
  margin-top: 34px;
  padding-top: 25px;
}
</style>
