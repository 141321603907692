<template>
  <div @click="$emit('click')" class="action-text">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RecipeHeaderActionButton',
}
</script>

<style lang="scss" scoped>
.action-text {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #747e8b;
  cursor: pointer;
  &:hover {
    img {
      filter: invert(80%) saturate(30);
    }
  }
}
</style>
