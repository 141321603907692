<template>
  <div id="add-recipe">
    <PremiumContentLock
      eventName="mp add recipe box roadblock view"
      type="full"
      v-if="isMealPlannerLocked || isDashboardLocked"
    />
    <TheRecipeBox
      @on-recipe-select="onRecipeSelect"
      @add-recipe="addRecipe"
      @upgrade="goToPremium"
      :is-add-recipe-box="true"
      :show-search-button="false"
    />
  </div>
</template>

<script>
import TheRecipeBox from '@/components/recipebox/TheRecipeBox'
import { mapGetters } from 'vuex'
import LoadingMixin from '@/mixins/LoadingMixin'
import PremiumContentLock from '@/components/overlays/PremiumContentLock'
import { events } from '@/utils/mixpanel.events'
export default {
  mixins: [LoadingMixin],
  components: {
    TheRecipeBox: {
      extends: TheRecipeBox,
      data() {
        return {
          showMobileSearch: true,
          previousScrollTop: 0,
        }
      },
      methods: {
        toggleOpenMobileSearch() {
          this.closeAddRecipeBoxModal()
        },
      },
    },
    PremiumContentLock,
  },
  computed: {
    ...mapGetters({
      isMealPlannerLocked: 'globals/isMealPlannerLocked',
      isDashboardLocked: 'dashboard/isDashboardLocked',
      mealPlanType: 'dashboard/getMealPlanTypeName',
    }),
  },
  methods: {
    closeAddRecipeBoxModal() {
      this.$router.push({
        name: 'plan',
        params: {
          scrollTop: this.previousScrollTop,
        },
      })
    },
    onRecipeSelect(recipeUrl) {
      const urlParts = recipeUrl.split('/')
      this.$router.push({
        name: 'AddRecipeRecipe',
        params: { recipeId: urlParts[2], recipeName: urlParts[3] },
      })
    },
    addRecipe(recipeId) {
      const payload = {
        eventName: events.dashboard.recipe.added,
        params: {
          recipe_id: recipeId,
        },
      }
      this.$store.dispatch('analytics/track', payload)
      const day = parseInt(this.$route.params.day)
      const mealType = parseInt(this.$route.params.mealType)
      this.$store
        .dispatch('dashboard/queueAddOrSwapMeal', {
          recipeId,
          day,
          mealType,
        })
        .then(() => {
          this.closeAddRecipeBoxModal()
        })
    },
    goToPremium() {
      this.$analytics.track('mp upgrade clicked', { location: 'AddRecipeBox' })
      this.$router.push({ path: '/reactivate' })
    },
  },
  mounted() {
    this.previousScrollTop = this.$route.params.previousScrollTop
    this.$store.dispatch('RecipeBox/refresh')
  },
}
</script>
