<template>
  <div class="registration-steps">
    <ul class="steps-indicator">
      <li v-for="step in steps" :key="step.path">
        <span
          v-show="step.index <= 5"
          class="indicator-bullet"
          :class="{
            active:
              currentRegistrationStep === step.index ||
              (totalStep - step.index === 1 && currentRegistrationStep >= 5),
            done: step.done,
          }"
          @click="stepClicked(step)"
        ></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RegistrationSteps',
  computed: {},
  data() {
    return {
      totalStep: 6,
    }
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentRegistrationStep: {
      type: Number,
      required: true,
    },
  },
  methods: {
    stepClicked(step) {
      if (!step.done) {
        return
      }
      this.$router.push(`${step.path}`)
      this.$emit('onStepChanged', step.index)
    },
  },
}
</script>

<style lang="scss" scoped>
.registration-steps {
  display: flex;
}
.steps-indicator {
  list-style: none;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: center;
  li {
    width: 2rem !important;
    @include media-breakpoint-up(md) {
      width: 47px !important;
    }
  }
}

.indicator-bullet {
  display: block;
  background: url('~@/assets/icons/onboarding-inactive-dot.svg') center
    no-repeat;
  width: 12px;
  height: 12px;
  left: calc(50% - 7px);
  transition: 0.25s;
  border-radius: 50%;
  margin: 0 auto;

  &.done {
    background: url('~@/assets/icons/onboarding-complete-dot.svg') center
      no-repeat;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  &.active {
    background: url('~@/assets/icons/onboarding-active-dot.svg') center
      no-repeat;
    width: 12px;
    height: 12px;
  }
}
</style>
