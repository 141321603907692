<template>
  <div>
    <span
      >There is already an account with this credentials, please go to
      <MPWhiteLink href @click.prevent="goToSignIn()"
        >Sign In</MPWhiteLink
      ></span
    >
  </div>
</template>

<script>
import { MPWhiteLink } from '@/components/common'

export default {
  components: { MPWhiteLink },
  methods: {
    goToSignIn() {
      this.$analytics.track('mp sign in click')
      this.$router.push(this.loginURL)
    },
  },
}
</script>
