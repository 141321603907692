import api from '.'
import camelizeKeys from './camelizeKeys'

const RECIPES = 'recipes'
const ADD_CUSTOM_RECIPE = `${RECIPES}/add-custom-recipe/`
const EDIT_CUSTOM_RECIPE = `${RECIPES}/edit-custom-recipe/`
const ADD_CUSTOM_RECIPE_INGREDIENTS = `${RECIPES}/add-custom-recipe-ingredient/`
const EDIT_CUSTOM_RECIPE_INGREDIENTS = `${RECIPES}/edit-custom-recipe-ingredient/`
const REORDER_CUSTOM_RECIPE_INGREDIENTS = `${RECIPES}/reorder-custom-recipe-ingredients/`
const ADD_CUSTOM_INGREDIENT = `${RECIPES}/add-user-custom-ingredient/`
const ADD_OR_UPDATE_RECIPE_IMAGE = `${RECIPES}/upload-user-custom-recipe-image/`
const ADD_CUSTOM_PREP_DESCRIPTION = `${RECIPES}/add-user-custom-prep-description/`
const ADD_CUSTOM_UNIT = `${RECIPES}/add-custom-unit/`

const backEndCustomRecipeMapping = {
  recipeTitle: 'title',
  recipePrepTime: 'default_prep_time',
  recipeServing: 'number_of_servings_2',
  category: 'category',
  recipeImage: 'image',
  recipePrepDescription: 'content',
  batchRecipe: 'batch_recipe',
}

// Helper functions
function setPayloadForCustomRecipe(formData) {
  const payload = new FormData()
  const formDataKeys = Object.keys(formData)
  formDataKeys.forEach((key) => {
    if (formData[key] != null) {
      if (
        key == 'recipeImage' &&
        formData[key] &&
        typeof formData[key] != 'string'
      ) {
        payload.append(
          backEndCustomRecipeMapping[key],
          formData[key],
          formData[key].name
        )
      } else {
        payload.append(backEndCustomRecipeMapping[key], formData[key])
      }
    }
  })
  return payload
}

export default {
  async addCustomRecipe(formData) {
    const payload = setPayloadForCustomRecipe(formData)
    const res = await api.post(ADD_CUSTOM_RECIPE, payload)
    return camelizeKeys(res.data)
  },
  async updateCustomRecipe(formData, userCustomRecipeId) {
    const payload = setPayloadForCustomRecipe(formData)
    const res = await api.post(
      `${EDIT_CUSTOM_RECIPE}${userCustomRecipeId}/`,
      payload
    )
    return camelizeKeys(res.data)
  },
  async removeCustomRecipe(userCustomRecipeId) {
    const res = await api.delete(
      `${EDIT_CUSTOM_RECIPE}${userCustomRecipeId}/`,
      null
    )
    return camelizeKeys(res.data)
  },
  async getCustomRecipe(userCustomRecipeId) {
    const res = await api.get(`${EDIT_CUSTOM_RECIPE}${userCustomRecipeId}/`)
    return camelizeKeys(res.data)
  },
  async addRecipeIngredient({
    userCustomRecipeId,
    ingredient,
    unit,
    quantity,
    prepDescription,
    order,
    isMetric,
  }) {
    const payload = {
      ingredient: ingredient,
      unit: unit,
      quantity: quantity,
      prep_description: prepDescription,
      order: order,
      is_metric: isMetric,
    }
    const res = await api.post(
      `${ADD_CUSTOM_RECIPE_INGREDIENTS}${userCustomRecipeId}/`,
      payload
    )
    return camelizeKeys(res.data)
  },
  async editRecipeIngredient({
    id,
    ingredient,
    unit,
    quantity,
    prepDescription,
    order,
    isMetric,
  }) {
    const payload = {
      ingredient: ingredient,
      unit: unit,
      quantity: quantity,
      prep_description: prepDescription,
      order: order,
      is_metric: isMetric,
    }
    const res = await api.post(
      `${EDIT_CUSTOM_RECIPE_INGREDIENTS}${id}/`,
      payload
    )
    return camelizeKeys(res.data)
  },
  async deleteRecipeIngredient(recipeIngredientId) {
    const res = await api.delete(
      `${EDIT_CUSTOM_RECIPE_INGREDIENTS}${recipeIngredientId}/`
    )
    return camelizeKeys(res.data)
  },
  async reorderCustomRecipeIngredients(payload) {
    const res = await api.post(REORDER_CUSTOM_RECIPE_INGREDIENTS, {
      ingredients: payload,
    })
    return camelizeKeys(res.data)
  },
  async addUserCustomIngredient(data) {
    const payload = {
      singular_name: data,
    }
    const res = await api.post(ADD_CUSTOM_INGREDIENT, payload)
    return camelizeKeys(res.data)
  },
  async addOrUpdateRecipeImage(image, recipeId) {
    const formData = new FormData()
    formData.append('user_custom_recipe_id', recipeId)
    formData.append('image', image)
    const res = await api.post(ADD_OR_UPDATE_RECIPE_IMAGE, formData)
    return camelizeKeys(res.data)
  },
  async addUserCustomPrepDescription(data) {
    const payload = {
      text: data,
    }
    const results = await api.post(ADD_CUSTOM_PREP_DESCRIPTION, payload)
    return camelizeKeys(results.data)
  },
  async addUserCustomUnit(data) {
    const payload = {
      singular_name: data,
    }
    const results = await api.post(ADD_CUSTOM_UNIT, payload)
    return camelizeKeys(results.data)
  },
}
