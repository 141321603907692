import RegisterGoalsForm from '@/components/credentials/register/RegisterGoalsForm'
import RegisterSelectIntolerancesForm from '@/components/credentials/register/RegisterSelectIntolerancesForm'
import RegisterSelectServingsForm from '@/components/credentials/register/RegisterSelectServingsForm'
import RegisterSelectABTest from '@/components/credentials/register/RegisterSelectABTest'
import RegisterCredentialsForm from '@/components/credentials/register/RegisterCredentialsForm'
import RegisterCheckout from './RegisterCheckout.vue'

export default [
  RegisterGoalsForm,
  RegisterSelectServingsForm,
  RegisterSelectIntolerancesForm,
  RegisterSelectABTest,
  RegisterCredentialsForm,
  RegisterCheckout,
].map((routeComponent, index) => ({
  alias: !index ? '/' : undefined, // this makes it the default route for /register
  path: (index + 1).toString(),
  index: index + 1,
  component: routeComponent,
  done: false,
}))
