import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import globals from './modules/globals'
import dashboard from './modules/dashboard'
import user from './modules/user'
import AddCustomRecipe from './modules/AddCustomRecipe'
import RecipeBox from './modules/RecipeBox'
import registration from './modules/registration'
import GroceryList from './modules/GroceryList'
import LocalDashboard from './modules/LocalDashboard'
import recipe from './modules/recipe'
import headers from './modules/headers'
import WeekendPrep from './modules/WeekendPrep'
import Disqus from './modules/DisqusModule'
import Affiliate from './modules/Affiliate'
import analytics from './modules/analytics/analytics'
import notifications from './modules/notifications'
import checkout from './modules/checkout'

Vue.use(Vuex)

const modules = {
  globals,
  dashboard,
  user,
  AddCustomRecipe,
  RecipeBox,
  registration,
  GroceryList,
  LocalDashboard,
  recipe,
  headers,
  WeekendPrep,
  Disqus,
  Affiliate,
  analytics,
  notifications,
  checkout,
}

const store = new Vuex.Store({
  modules,
  getters: {
    count: (store) => store.count,
  },
  plugins: [
    createPersistedState({
      paths: [
        'user.accessToken',
        'globals.mealPlan',
        'globals.userWeeklyPlanId',
        'user.userData.isAffiliate',
      ],
    }),
  ],
})
export default store

export function resetState() {
  store.dispatch('user/logout')
}
