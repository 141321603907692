<template>
  <div class="recipe-base">
    <span
      class="header-close"
      @click="$emit('close')"
      v-if="showCloseButton && !showModal"
    />
    <div class="recipe-header">
      <slot name="header" />
    </div>
    <div class="d-flex flex-column align-items-center">
      <div class="recipe-content container">
        <div class="recipe-actions row">
          <slot name="actions" />
        </div>
        <div class="row">
          <aside class="recipe-left-content col-12 col-md-8 col-lg-7">
            <slot name="left-content" />
          </aside>
          <aside class="recipe-right-content col-12 col-md-4 col-lg-5">
            <div>
              <slot name="top-right-content" />
            </div>
            <div class="recipe-right-content-inner">
              <slot name="right-content" />
            </div>
          </aside>
        </div>
      </div>
      <div class="container">
        <div class="recipe-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'RecipeBase',
  computed: {
    ...mapState('AddCustomRecipe', ['showModal']),
  },
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.recipe-base {
  position: relative;
  font-size: 1rem;
}
.header-close {
  position: absolute;
  cursor: pointer;
  top: 30px;
  left: 15px;
  width: 33px;
  height: 33px;
  background-color: transparent;
  background-image: url('~@/assets/icons/close-light.svg');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  background-size: 33px;
  z-index: 1;

  @include media-breakpoint-up(md) {
    top: 40px;
    right: 60px;
    width: 50px;
    left: auto;
    height: 50px;
    background-size: 50px;
    pointer-events: auto;
  }
}
.recipe-header {
  @include media-breakpoint-up(md) {
    margin-bottom: 20px;
  }
}
.recipe-content {
  margin-bottom: 0;
  background: #ffffff;
}
.recipe-actions > div {
  width: 100%;
}
.recipe-footer {
  background-color: $white;
  padding-bottom: 40px;
  @include media-breakpoint-up(md) {
    background-color: transparent;
  }
}

.recipe-left-content {
  order: 2;
  @include media-breakpoint-up(md) {
    order: 1;
  }
  @include media-breakpoint-down(sm) {
    background-color: $clr-navy-3;
  }
}

.recipe-right-content {
  order: 1;
  padding: 0;

  .side-card + .side-card {
    margin-top: 30px;
    &:before {
      content: '';
      display: block;
      width: 60px;
      height: 4px;
      background-color: $body-color;
      margin-bottom: 24px;
    }
  }
  @include media-breakpoint-up(md) {
    order: 2;
    padding-right: 15px;
  }
}
.recipe-right-content-inner {
  background-color: $clr-navy-3;
  background-image: url('~@/assets/images/almonds.svg');
  background-repeat: no-repeat;
  background-position: top right;
  border-top: 1px solid $clr-navy-5;
  padding: 20px 15px 12px;
  @include media-breakpoint-up(md) {
    order: 2;
    padding: 22px 9px 22px 24px;
    border: 1px solid $clr-navy-5;
  }
  @include media-breakpoint-down(md) {
    background-position: top right -30px;
  }
}
</style>
