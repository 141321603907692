<template>
  <div
    class="select-box"
    :class="{ 'select-box--selected': isSelected }"
    :data-spec="dataSpec"
    @click="$emit('click')"
  >
    <div v-if="image" class="image-container">
      <img :src="image" />
    </div>
    <span class="title">{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    image: {
      type: String,
    },
    dataSpec: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.select-box {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid 1px $clr-navy-15;
  border-radius: 4px;
  margin-top: 0.5rem;
  padding: 0.5rem 1.5rem;
  flex: 1;
  @include media-breakpoint-up(md) {
    padding: 1rem 2rem;
    margin-top: 0;
    flex-direction: column;
  }
  + .select-box {
    margin-top: 1rem;
    @include media-breakpoint-up(md) {
      margin-top: 0;
      margin-left: 1rem;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  margin-bottom: 0.5rem;
  @include media-breakpoint-up(md) {
    min-height: 80px;
    width: auto;
  }
}

.select-box--selected {
  border: solid 2px $clr-water;
  border-radius: 4px;
  position: relative;
  &::before {
    content: '';
    background: $white url('~@/assets/icons/icon-selected-badge.svg') center
      no-repeat;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: absolute;
    right: -11px;
    top: -11px;
    @include media-breakpoint-up(md) {
      width: 22px;
      height: 22px;
      right: -11px;
      top: -11px;
    }
  }
}

.title {
  flex: 1 1 auto;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-left: 1rem;
  padding-right: 30px;
  width: 100%; // IE 11 text wrap in flex-box
  @include media-breakpoint-up(md) {
    margin: 0;
    padding: 0;
  }
}
</style>
