<template>
  <div class="mobile-header container" :style="headerStyle">
    <img :src="iconImage" v-if="iconImage" />
    <span class="title" v-if="title">
      {{ title }}
    </span>
    <button
      class="btn btn-primary upgrade d-lg-none"
      @click="onUpgradeClick"
      v-show="!isActiveUser"
    >
      <span>Upgrade</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    backgroundImage: {
      type: String,
      required: false,
    },
    iconImage: {
      type: String,
      required: false,
    },
    isActiveUser: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    headerStyle() {
      return this.backgroundImage
        ? { backgroundImage: `url(${this.backgroundImage})` }
        : {}
    },
  },
  methods: {
    onUpgradeClick() {
      this.$emit('upgrade')
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-header {
  height: 129px;
  width: 100%;

  background-position: center center;
  background-color: #3b3d43;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.title {
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0.75rem;
  color: white;
}
</style>
