<template>
  <bDropdown
    id="wrap-mp-dropdown"
    :variant="variant"
    :text="selected ? selected[textField] : placeHolder"
    size="lg"
    :class="{ 'none-selected': !selected }"
  >
    <b-dropdown-item @click="selectItem(null)" class="dropdown-custom-item">
      None
    </b-dropdown-item>
    <b-dropdown-item
      v-for="item in options"
      :key="item[valueField]"
      @click="selectItem(item)"
      class="dropdown-custom-item"
    >
      {{ item[textField] }}
    </b-dropdown-item>
  </bDropdown>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'AddCustomRecipeDropDown',
  props: {
    placeHolder: {
      type: String,
    },
    valueField: {
      type: String,
    },
    textField: {
      type: String,
    },
    value: {
      type: Number,
    },
    options: {
      required: true,
    },
    variant: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selected: null,
      isDropDownVisible: true,
    }
  },
  watch: {
    options: {
      handler: function () {
        if (this.options) {
          this.selected = this.options.find(
            (s) => s[this.valueField] === this.value
          )
        }
      },
      immediate: true,
    },
  },
  methods: {
    setDropDownVisibility: _.debounce(function (visibility) {
      this.isDropDownVisible = visibility
    }, 100),
    onDropdownShown() {},
    onDropdownHidden() {},
    hideDropDown() {
      this.setDropDownVisibility(false)
    },
    toggleDropDown() {
      this.isDropDownVisible = !this.isDropDownVisible
    },
    selectItem(item) {
      this.selected = item
      if (item) {
        this.$emit('input', item[this.valueField])
      } else {
        this.$emit('input', null)
      }
    },
  },
}
</script>

<style lang="scss">
#wrap-mp-dropdown {
  &.dropdown {
    width: 100%;
    &.invalid {
      .btn {
        border: 1px solid $pale-red;
        animation: animate-border 5s linear 0s infinite alternate;
      }
    }
  }
  &.none-selected {
    .btn {
      letter-spacing: 0.2px;
    }
  }
  .btn {
    width: 100%;
    height: 40px;
    text-align: left;
    font-size: 1rem;
    margin: 0;
    border-radius: 4px;
    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid $clr-water;
    }
    &[aria-expanded='true'] {
      border: 1px solid $clr-water;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &::after {
        transform: rotate(270deg);
        top: 15px;
      }
    }
    &-default {
      background-color: $white;
      border: 1px solid $clr-navy-50;
    }
    &-lg {
      height: 50px;
    }
    &-sm {
      height: 30px;
    }
    &::after {
      transition: all 0.1s linear;
      transform: rotate(90deg);
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-left-color: $clr-water;
      border-radius: 2px;
      position: absolute;
      right: 15px;
      top: 21px;
    }
  }
  .dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    top: -2px !important;
    border: 1px solid $primary;
    border-top: 0;
    padding: 0;
  }
  .dropdown-custom-item {
    padding: 12px 14px;
    font-size: 14px;
    border-top: solid 0.5px $light-grey;
    &:hover {
      background-color: #ffffff;
      border-top: solid 0.5px $clr-navy;
      + a {
        border-top: solid 0.5px $clr-navy;
      }
    }
    &:active {
      background-color: $white;
      color: $grey;
    }
  }
}
</style>
