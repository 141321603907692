import DetectDevice from '@/utils/DetectDevice'

const DetectDeviceMixin = {
  data() {
    return {
      isTouchDevice: DetectDevice.isTouchDevice(),
    }
  },
  methods: {
    // PENDING: replace when only mobile resolution is needed
    isMobile: DetectDevice.isMobile,
    isTabletResolution: () => {
      return screen.width >= 740 && screen.width <= 1024
    },
    isMobileResolution: () => {
      return screen.width < 740
    },
  },
}

export default DetectDeviceMixin
