<template>
  <div>
    <transition name="fade">
      <bAlert :show="warning.show" variant="warning">
        <b>Warning:</b> {{ warning.message }}
      </bAlert>
    </transition>
    <div class="row no-gutters" @keydown="onKeyDown">
      <div class="col-6 col-sm-3 col-md-2 pr-2 pr-md-0">
        <MPInput
          placeholder="Quantity"
          type="text"
          size="lg"
          v-model="quantityFraction"
          :name="`quantity`"
          :data-vv-scope="tabScope"
          v-validate="quantityValidation"
          :class="{ invalid: hasQuantityErrors }"
          data-vv-as="Quantity"
          ref="quantityInput"
        />
        <div class="error-message">
          <span v-if="hasQuantityErrors">{{ quantityError }}</span>
        </div>
      </div>
      <div class="col-6 col-sm-3 col-md-2 ml-md-2">
        <MPAutoSuggest
          class="select-unit"
          :strict-mode="false"
          :suggestions="unitList"
          place-holder="Unit"
          search-param="name"
          show-param="name"
          v-model="unit"
          :name="`unit`"
          :data-vv-scope="tabScope"
          v-validate:unit="unitValidation"
          :class="{ invalid: hasUnitErrors }"
          data-vv-as="Unit"
        />
        <div class="error-message">
          <span v-if="hasUnitErrors">{{ unitError }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-4 col-md-3 ml-md-2">
        <MPAutoSuggest
          :strict-mode="false"
          :suggestions="ingredientList"
          place-holder="Ingredient*"
          search-param="singularName"
          show-param="singularName"
          v-model="ingredient"
          :name="`ingredient`"
          :data-vv-scope="tabScope"
          v-validate:ingredient="ingredientValidation"
          :class="{ invalid: hasIngredientErrors }"
          data-vv-as="Ingredient"
        />
        <div class="error-message">
          <span v-if="hasIngredientErrors">{{ ingredientError }}</span>
        </div>
      </div>
      <div class="col-9 col-md ml-md-2">
        <MPAutoSuggest
          :strict-mode="false"
          :suggestions="prepDescriptionList"
          place-holder="Preparation style"
          search-param="text"
          show-param="text"
          v-model="prepDescription"
          :name="`prepDescription`"
          :data-vv-scope="tabScope"
          v-validate:prepDescription="prepDescriptionValidation"
          :class="{ invalid: hasPrepDescriptionErrors }"
          class="text-ellipsis"
          data-vv-as="Preparation style"
        />
        <div class="error-message">
          <span v-if="hasPrepDescriptionErrors">
            {{ prepDescriptionError }}
          </span>
        </div>
      </div>
      <div class="col ml-2 d-inline-flex ingredient-controls-wrapper">
        <MPButton
          size="lg"
          class="add-button"
          @click="addRecipeIngredient()"
          :disabled="hasErrors || !this.ingredient"
        />
        <img
          class="ml-2 mb-4 cursor--pointer align-self-center"
          src="@/assets/icons/close.svg"
          @click="clear()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddEditRecipeIngredientsMixin from './AddEditRecipeIngredientsMixin'

export default {
  name: 'AddCustomRecipeIngredientForm',
  mixins: [AddEditRecipeIngredientsMixin],
  props: {
    tabScope: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$refs.quantityInput.$el.focus()
  },
  methods: {
    addRecipeIngredient() {
      return this.$validator.validate(`${this.tabScope}.*`).then((result) => {
        if (result) {
          this.$emit(
            'add',
            this.createRecipeIngredientObject(this.unit && this.unit.isMetric)
          )
          this.clear()
          this.$refs.quantityInput.$el.focus()
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.add-button {
  background-image: url('~@/assets/icons/add-servings.svg');
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center 6px;
  background-repeat: no-repeat;
}
.cursor--pointer {
  cursor: pointer;
}
.ingredient-controls-wrapper {
  max-width: 60px;
}
</style>
