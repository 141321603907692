<template>
  <BackgroundModal :show.sync="showModal" :show-x="true">
    <template v-slot:leftColumn>
      <div class="export-modal-empty d-none d-sm-none d-md-block d-lg-block" />
    </template>
    <template v-slot:rightColumn>
      <section class="export-modal-view-container">
        <div
          class="export-modal-view-content container"
          ref="export-modalViewContent"
        >
          <h3 class="export-modal-title">{{ title }}</h3>
          <h4 class="export-modal-subtitle text-center">
            {{ content }}
          </h4>
          <div class="text-center">
            <button class="btn btn-primary btn-lg add-btn" @click="handleClick">
              {{ buttonText }}
            </button>
            <p v-bind:class="{ 'font-weight-bold': isFreeUser }">
              {{ downParagraph }}
            </p>
          </div>
        </div>
      </section>
    </template>
  </BackgroundModal>
</template>

<script>
import { mapGetters } from 'vuex'
import BackgroundModal from '@/components/common/BackgroundModal'

import copy from 'copy-to-clipboard'

export default {
  components: {
    BackgroundModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  name: 'ExportToMyfitnesspalModal',
  computed: {
    ...mapGetters({
      publicUrl: 'recipe/getPublicUrl',
      copiedUrl: 'recipe/getCopiedUrl',
      isFreeUser: 'user/isFree',
    }),
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
    title() {
      return this.isFreeUser ? 'Tracking your macros?' : 'Exporting recipe'
    },
    content() {
      return this.isFreeUser
        ? `Upgrade to a premium subscription to export recipes to MyFitnessPal`
        : `This recipe has been copied to clipboard and is ready to be imported
          to your nutrition tracker.`
    },
    buttonText() {
      return this.isFreeUser ? 'UPGRADE NOW' : 'GO TO MYFITNESSPAL'
    },
    downParagraph() {
      return this.isFreeUser
        ? 'Cancel anytime'
        : 'You will be redirected to MyFitnessPal'
    },
  },
  methods: {
    handleClick() {
      if (!this.isFreeUser) {
        window.open('https://www.myfitnesspal.com/recipe_parser')
      } else {
        this.$router.push('/reactivate')
      }

      this.showModal = false
    },
    copyUrl() {
      if (!this.isFreeUser && this.publicUrl) {
        copy(this.publicUrl)
        this.$store.dispatch('recipe/setCopiedUrl', this.publicUrl)
      }
    },
  },
  watch: {
    publicUrl: {
      handler: function () {
        if (this.copiedUrl != this.publicUrl) {
          this.copyUrl()
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.export-modal-empty {
  min-width: rem(350);
}

.export-modal-view-container {
  flex: 1;
  padding-top: rem(20);
  order: 1;
  display: flex;
  flex-direction: column;
  padding-top: 47vw;
  @media (orientation: landscape) {
    padding-top: 10vw;
  }
  @media (max-width: 555px) {
    padding: 44vw 0;
  }

  @include media-breakpoint-up(md) {
    padding-top: 0;
    margin-top: 19vw;

    &.extra {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: 0;
    padding-top: rem(70);
    order: 2;
    flex-direction: row;
    align-content: flex-end;
  }

  .add-btn {
    min-width: 80%;
    padding: rem(15) 0;
    margin-bottom: rem(10);
  }
}

.export-modal-view-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  @include media-breakpoint-up(md) {
    max-width: rem(690);
  }
}

.export-modal-title {
  font-weight: bold;
  font-size: rem(22);
  line-height: rem(26);
  text-align: center;
  color: $clr-navy;
  text-transform: none;
  @include media-breakpoint-up(md) {
    line-height: 1.04;
    font-size: rem(35);
  }
  @include media-breakpoint-up(lg) {
    line-height: 1.08;
  }
}

.export-modal-subtitle {
  font-weight: lighter;
  font-size: rem(19);
  line-height: rem(26);
  margin: rem(14) 0 rem(25);
  color: $clr-navy;
  text-transform: none;
  @include media-breakpoint-up(md) {
    line-height: 1.04;
    text-align: center;
    font-size: rem(29);
    margin: rem(20) 0 rem(30);
  }
  @include media-breakpoint-up(lg) {
    line-height: rem(46);
  }
}

.export-modal-close-group {
  position: absolute;
  left: rem(17);
  top: rem(30);
  @media (orientation: landscape) {
    left: auto;
    top: rem(20);
    right: rem(20);
  }
  @include media-breakpoint-up(lg) {
    left: auto;
    top: 0;
    right: 0;
    padding: rem(27) rem(27) 0 0;
    border-bottom-left-radius: 50%;
  }
}
</style>
