<template>
  <div class="plan-dropdown bootstrap">
    <MPPopover
      :show-on-hover="true"
      :show-on-click="true"
      :hide-on-click="false"
      :hide-on-mouse-leave="true"
      :dim-background="false"
      :placement="placement"
    >
      <div slot="button" class="select-button" :class="{ active: isActive }">
        <span class="selected-plan-label">
          <Calendar
            width="24"
            class="plan-icon plan-icon--calendar"
            v-if="showCalendarIcon"
          />
          {{ buttonText }}

          <Lock width="24" class="plan-icon plan-icon--lock" v-if="isLocked" />
        </span>
      </div>
      <div class="options" slot="popover">
        <div class="no-plans" v-if="showNoPlansMessage()">
          <p>Coming soon!</p>
        </div>
        <ul class="plans-list">
          <li
            class="plans-list-item"
            :class="{ active: selectedItem == item }"
            v-for="item in items"
            :key="item.id"
            @click="selectMealPlan(item)"
          >
            <span class="plans-list-item-label">{{
              limitedItemName(item.label)
            }}</span>

            <i
              class="plans-list-item-icon"
              :class="{
                'icon-is-locked': item.isLocked,
              }"
            ></i>
            <span v-if="item.isNext" class="preview">Preview</span>
            <Star v-if="displayCurrentPlanIcon(item)" class="icon-this-week" />
            <a
              href="#"
              class="icon-delete"
              v-if="item.isCustom"
              @click.stop.prevent="openDeleteCustomPlanModal(item)"
            >
              <Bin />
            </a>
          </li>
        </ul>
        <template v-if="hasCustomPlansPermissions() && !isMealPlannerLocked">
          <div class="no-custom" v-if="showNoCustomPlansMessage()">
            <p>You haven’t saved any custom plans</p>
            <a href @click.prevent="openCreateCustomPlanModal()">
              + CREATE YOUR FIRST
            </a>
          </div>
          <div class="has-custom" v-else-if="isCustomTypeSelected()">
            <a href @click.prevent="openCreateCustomPlanModal()">
              + CREATE CUSTOM PLAN
            </a>
          </div>
        </template>
        <template v-else-if="isCustomTypeSelected()">
          <div class="custom-plans-upgrade">
            <p>Reactivate your membership to create custom meal plans</p>
            <button class="button btn btn-primary" @click="goToPremium()">
              Reactivate Membership
            </button>
          </div>
        </template>
      </div>
    </MPPopover>

    <DashboardConfirmationModal
      v-if="selectedPlanToDelete"
      :show.sync="showDeleteCustomPlanModal"
      :title="`Are you sure you want to delete '${selectedPlanToDelete.label}'?`"
      description="Deleting will permanently remove this custom meal plan from your meal planner."
      confirmButtonText="Delete Plan"
      @onConfirm="deleteCustomPlan"
    />
    <TextEntryModal
      :show.sync="showCreateCustomPlanModal"
      title="What would you like to name this plan?"
      description=""
      confirmButtonText="Save"
      defaultValue=""
      placeholder="Your plan name"
      @onConfirm="createCustomPlan"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MPPopover, TextEntryModal } from '@/components/common'
import { Lock, Calendar, Bin, Star } from '@/components/common/MPIcons'
import Stickyfill from 'stickyfilljs'
import DashboardConfirmationModal from '@/components/dashboard/DashboardConfirmationModal.vue'
import { events } from '@/utils/mixpanel.events'
import { categories } from '../constants'
export default {
  components: {
    MPPopover,
    DashboardConfirmationModal,
    TextEntryModal,
    Lock,
    Calendar,
    Bin,
    Star,
  },
  props: {
    buttonText: {
      type: String,
      default: 'Loading plans...',
    },
    isLocked: {
      default: false,
    },
    isActive: {
      default: false,
    },
    showCalendarIcon: {
      default: false,
    },
    type: {
      default: 'weekly',
    },
  },
  data() {
    return {
      placement: 'bottom',
      onWindowResize: null,
      showDeleteCustomPlanModal: false,
      showCreateCustomPlanModal: false,
      selectedPlanToDelete: null,
      selectedItemLabel: 'Loading plans...',
    }
  },
  computed: {
    ...mapGetters({
      selectedItem: 'dashboard/mealPlanSelectedItem',
      allPlans: 'dashboard/mealPlanItems',
      permissions: 'user/permissions',
      isMealPlannerLocked: 'globals/isMealPlannerLocked',
    }),
    items() {
      if (this.type != categories.promotional) {
        return this.allPlans.filter(
          (p) =>
            p.isCustom === this.isCustomTypeSelected() &&
            p.category != categories.promotional
        )
      } else {
        return this.allPlans.filter((p) => p.category == categories.promotional)
      }
    },
  },
  methods: {
    isCustomTypeSelected() {
      return this.type === 'custom'
    },
    selectMealPlan({ value, title, userWeeklyPlanId }) {
      if (this.type === categories.promotional) {
        this.$store.dispatch('analytics/track', {
          eventName: events.dashboard.viewFeatured,
          params: {
            plan: title,
          },
        })
      }
      this.$root.$emit('bv::hide::popover')
      this.$store.dispatch(
        'globals/setMealPlanData',
        { mealPlan: value, userWeeklyPlanId: userWeeklyPlanId },
        { root: true }
      )
      this.$store.dispatch('dashboard/refreshDashboard')
      if (this.$route.name != 'plan') {
        this.$router.push('/plan')
      }
    },
    displayCurrentPlanIcon(item) {
      if (this.permissions.canAccessNonSampleMealPlans) {
        return item.isCurrent
      }
      return item.isSample
    },
    showNoPlansMessage() {
      return !this.isCustomTypeSelected() && !this.items.length
    },
    showNoCustomPlansMessage() {
      return this.isCustomTypeSelected() && !this.items.length
    },
    hasCustomPlansPermissions() {
      return this.permissions.canAccessCustomMealPlans
    },
    goToPremium() {
      const eventProperties = {}
      eventProperties.location = 'PlanDropdown'
      this.$analytics.track('mp upgrade clicked', eventProperties)
      this.$router.push('/reactivate')
    },
    isDashboardPage() {
      return window.location.href.indexOf('/plan') !== -1
    },
    deleteCustomPlan() {
      this.$store.dispatch(
        'dashboard/deleteUserMealPlan',
        this.selectedPlanToDelete.userWeeklyPlanId
      )
      this.showDeleteCustomPlanModal = false
      this.selectedPlanToDelete = null
    },
    openDeleteCustomPlanModal(plan) {
      this.$root.$emit('bv::hide::popover')
      this.selectedPlanToDelete = plan
      this.showDeleteCustomPlanModal = true
    },
    createCustomPlan(planName) {
      const payload = {
        eventName: events.dashboard.customPlan.created,
      }
      this.$store.dispatch('analytics/track', payload)
      this.$store.dispatch('dashboard/createCustomPlan', planName)
      this.showCreateCustomPlanModal = false
      this.$router.push('/plan')
    },
    openCreateCustomPlanModal() {
      this.$root.$emit('bv::hide::popover')
      this.showCreateCustomPlanModal = true
    },
    limitedItemName(itemName) {
      const maxLength = 45
      if (itemName.length > maxLength) {
        return itemName.substring(0, maxLength) + '...'
      } else {
        return itemName
      }
    },
  },
  mounted() {
    this.onWindowResize = window.addEventListener('resize', () => {
      let width = window.innerWidth
      let isTabletSize = 740 < width && width < 1025
      this.placement = isTabletSize ? 'rightbottom' : 'bottom'
    })

    let stickyEls = document.querySelectorAll('mobile-plans-navigation')
    Stickyfill.add(stickyEls)
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  },
}
</script>

<style>
mobile-plans-navigation {
  display: block;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 3;
}
</style>

<style lang="scss" scoped>
.plan-dropdown {
}
.select-button {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0;
  background-repeat: no-repeat;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  padding-bottom: 4px;
  padding-top: 1px;
  border-bottom: solid 4px transparent;
  &.active {
    color: $link-color;
    border-bottom: solid 4px $link-color;
  }
  &:hover {
    color: $link-hover-color;
    border-bottom: solid 4px $link-hover-color;
    .plan-icon {
      color: $link-hover-color;
    }
  }
}

.plan-icon {
  color: $link-color;
}

.plan-icon--calendar {
  margin-top: -3px;
}

.sample-icon {
  display: block;
  background-image: url('~@/assets/icons/sample-plan/sample-plan-icon.svg');
  background-repeat: no-repeat;
  background-position: center 15px;
  height: 40px;
}

.short-title {
  display: block;
  text-align: center;
  font-size: 8px;
  padding-top: 13px;
  font-weight: 700;
  line-height: 1;
}

.short-sub-title {
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
}

.options {
  width: 244px;
}

.plans-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background-color: white;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #d7d7d7;
  }
}

.plans-list-item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px 16px;
  font-size: 1rem;
  cursor: pointer;
  line-height: 1.5;

  &:hover,
  &.active {
    background-color: $clr-navy-15;
  }

  .preview {
    margin: 0 0 0 0.5rem;
    background-color: $clr-navy-50;
    height: 17px;
    padding: 0 5px;
    font-size: 12px;
    color: $white;
  }
  .icon-this-week {
    width: 17px;
    height: 17px;
    margin-left: auto;
    color: $link-color;
  }
  .icon-is-locked {
    background-image: url('~@/assets/icons/locked-recipe.svg');
    width: 17px;
    height: 15px;
    margin-left: auto;
  }
  .icon-delete {
    margin-left: auto;
    color: $body-color;
    display: inline-block;
  }

  .plans-list-item-label {
    max-width: 85%;
  }
}

.plans-list-item-icon {
  background-repeat: no-repeat;
  background-position: center;
}

.no-plans,
.no-custom,
.has-custom {
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  p {
    color: #747e8b;
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
  }
}
.no-plans {
  padding-bottom: unset;
}

.has-custom {
  align-items: flex-start;
}

.custom-plans-upgrade {
  text-align: center;
  p {
    padding: 1rem 2rem;
  }
  .btn {
    font-size: 14px;
  }
}
</style>
