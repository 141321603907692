<template>
  <MPModal
    class="upsell-promo-modal"
    :show.sync="showModal"
    :closeOnBackdrop="false"
    :closeOnEsc="false"
  >
    <div class="upsell-promo-header"></div>
    <div class="text-center mx-md-4">
      <span class="title-tag mb-3">{{ title }}</span>
      <h2 class="title">{{ header }}</h2>
      <p class="mt-3">{{ description }}</p>
      <button
        class="btn btn-primary mt-2"
        @click="activateDiscount"
        data-spec="get-my-discount"
      >
        {{ confirm }}
      </button>
      <span class="d-inline-block mt-3 dismiss-link" @click="close">
        {{ cancel }}
      </span>
    </div>
  </MPModal>
</template>

<script>
import LoadingMixin from '@/mixins/LoadingMixin'
import DefaultPromo from '@/services/web/upsellPromoDefault'

export default {
  mixins: [LoadingMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    promo: {
      type: Object,
      default: function () {
        return {
          title: null,
          header: null,
          description: null,
          confirm: null,
          cancel: null,
        }
      },
    },
  },
  computed: {
    showModal: {
      get() {
        return this.visible
      },
      set(value) {
        if (value) this.$analytics.track('mp flash popup appeared')
        this.$emit('update:visible', value)
      },
    },
    title() {
      return this.promo.title ? this.promo.title : DefaultPromo.title
    },
    header() {
      return this.promo.header ? this.promo.header : DefaultPromo.header
    },
    description() {
      return this.promo.description
        ? this.promo.description
        : DefaultPromo.description
    },
    confirm() {
      return this.promo.confirm ? this.promo.confirm : DefaultPromo.confirm
    },
    cancel() {
      return this.promo.cancel ? this.promo.cancel : DefaultPromo.cancel
    },
  },
  methods: {
    activateDiscount() {
      this.setLoading(true)
      this.$store.dispatch('user/activateUpsellPromoDiscount').then(
        () => {
          this.$store.dispatch('user/hideUpsellPromoModal')
          this.setLoading(false)
          this.$analytics.track('mp flash popup applied')
        },
        () => {
          this.$store.dispatch('user/hideUpsellPromoModal')
          this.setLoading(false)
        }
      )
    },
    close() {
      this.$store.dispatch('user/hideUpsellPromoModal')
      this.$store.dispatch('user/deactivateUpsellPromoDiscount')
      this.$analytics.track('mp flash popup rejected')
    },
  },
}
</script>

<style lang="scss" scoped>
.upsell-promo-header {
  height: 170px;
  background-image: url('~@/assets/images/upsell-promo-header-mobile@2x.png');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  margin: -16px -16px 0;
  @include media-breakpoint-down(sm) {
    background-image: url('~@/assets/images/upsell-promo-header@2x.jpg');
  }
}

.title-tag {
  font-weight: bold;
  display: inline-block;
  margin-top: 1rem;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.56px;
}

.title {
  font-size: 42px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  font-weight: bold;
}

.btn {
  white-space: nowrap;
  border-radius: 0;
  padding: 0;
  height: 50px;
  font-size: 0.875rem;
  margin: 0;
  letter-spacing: 0.5px;
  width: 100%;
}

.dismiss-link {
  color: rgba(#002940, 0.7);
  text-decoration: none;
  cursor: pointer;
}
</style>
