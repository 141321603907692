import Vue from 'vue'
import apiDashboard from '@/api/dashboard'
import DashboardBuilder from '@/components/dashboard/DashboardBuilder'

import * as types from '../mutation-types'
import * as status from '../status-types'

import _ from 'lodash'

// commented swap time
// const SWAP_NOTIFICATION_DISPLAY_TIME = 10000
// let swapActionTimeout

// initial state
const state = {
  queueStatus: null,
  // dashboard
  dashboardStatus: null,
  dashboard: null,
  dashboardPlanLoadingItems: [],
  // dashboard meals
  mealsStatus: null,
  mealsVersion: null,
  meals: null,
  // swap
  swapAction: null,
  lastUndoAction: null,
  // list
  minListsInDashboard: 5,
  listsNumberInDashboard: 5,
  maxListsInDashboard: 7,
  maxMealsInDashboard: 12,
  // user
  isUserActive: false,
  // meal plans
  mealPlans: {},
  mealPlanSelectedType: 'weekly',
  saveAsCustomPlanModalVisible: false,
  renameCustomPlanModalVisible: false,
  deleteCustomPlanModalVisible: false,
  showTrialBanner: true,
  mealPlanTypeName: 'sample',
}

// getters
const getters = {
  isDashboardLocked: (state) => state.dashboard && state.dashboard.isLocked,
  showTrialBanner: (state) => state.showTrialBanner,
  showSwapNotification: (state) => {
    return !!state.swapAction
  },
  backgroundImage: (state) => {
    if (state.dashboard) {
      return state.dashboard.coverPhoto
    }
    return null
  },
  title: (state) => {
    if (state.dashboard) {
      return state.dashboard.title
    }
    return 'Loading meal plan...'
  },
  description: (state) => {
    let numbers
    if (state.meals) {
      numbers = DashboardBuilder.getRecipesTypesNumbers(state.meals)
    }
    return DashboardBuilder.getDescription(numbers)
  },
  planStatus: (state) => {
    return state.queueStatus ? status.UPDATING : null
  },
  dashboard: (state) => {
    return state.dashboard
  },
  saveAsCustomPlanModalVisible: (state) => {
    return state.saveAsCustomPlanModalVisible
  },
  renameCustomPlanModalVisible: (state) => {
    return state.renameCustomPlanModalVisible
  },
  dashboardPlan: (state, getters) => {
    let recipes = state.meals
    let listsNumber = getters.listsNumInDashboardPlan
    if (recipes) {
      // Order by day and position in day.
      recipes = DashboardBuilder.recipesReflow(recipes.filter((r) => r.inPlan))
      // Empty plan
      let dashboardPlan = DashboardBuilder.getEmptyDashboardPlan(listsNumber)
      recipes.forEach((recipe) => {
        // Recipe to list item
        let recipeListItem = DashboardBuilder.toDashboardListItem(recipe)
        // Add list item to plan
        DashboardBuilder.addListItemToDashboardPlan(
          recipeListItem,
          recipe.day,
          dashboardPlan
        )
      })

      // Fill empty slots between meals in the day with empty list items.
      dashboardPlan.forEach((list) => {
        list.items = list.items.reduce((newItems, item) => {
          while (newItems.length < item.mealType) {
            let emptyListItem = DashboardBuilder.getDashboardEmptyListItem()
            newItems.push(emptyListItem)
          }
          newItems.push(item)
          return newItems
        }, [])
      })

      // Hide "Add Recipe" if day is full.
      dashboardPlan.forEach((list) => {
        if (list.items.length >= state.maxMealsInDashboard) {
          list.actionStatus = 'hidden'
        }
      })

      // Add for on-boarding class.
      dashboardPlan.some((list) => {
        if (list.items.length) {
          list.items[0].isFirst = true
          return true
        }
      })

      // Add loading items to plan
      state.dashboardPlanLoadingItems.forEach(({ day, mealType }) => {
        const emptyListItem = DashboardBuilder.getDashboardLoadingListItem()
        dashboardPlan[day].items[mealType] = emptyListItem
      })

      return dashboardPlan
    } else return DashboardBuilder.getLoadingDashboardPlan(listsNumber)
  },
  recipeDashboardPlan(state, getters, rootState, rootGetters) {
    let dashboardPlan = getters.dashboardPlan.concat(
      DashboardBuilder.getEmptyDashboardPlan(
        state.maxListsInDashboard - getters.dashboardPlan.length
      )
    )
    const currentRecipeId = _.get(rootGetters['recipe/recipe'], 'id')
    dashboardPlan.forEach((list) => {
      list.items = list.items.map((item) => {
        return {
          ...item,
          isSelected: item.recipeId === currentRecipeId,
        }
      })
    })
    return dashboardPlan
  },
  listsNumInDashboardPlan: (state) => {
    let meals = state.meals
    if (!meals) return state.listsNumberInDashboard
    let allDays = meals
      .concat(state.dashboardPlanLoadingItems)
      .map(({ day }) => day)
      .filter((day) => Number.isInteger(day))
      .map((day) => ++day)
      .concat([state.listsNumberInDashboard])

    // Could cause issues because it is not going thru mutation.
    state.listsNumberInDashboard = Math.max(...allDays)
    return state.listsNumberInDashboard
  },
  maxListsInDashboard: (state) => {
    return state.maxListsInDashboard
  },
  meals: (state) => state.meals,
  // Meal Plan
  mealPlanSelectedItem: (state, getters, rootState, rootGetters) => {
    return (
      getters.mealPlanItems.find((plan) => {
        const planId = rootGetters['globals/userWeeklyPlanId']
        const mealPlan = rootGetters['globals/mealPlan']

        return (
          plan.userWeeklyPlanId == planId ||
          (!planId && plan.value == mealPlan) ||
          (!planId && plan.isCurrent && mealPlan === 'current') ||
          (!planId && plan.isNext && mealPlan === 'next') ||
          (!planId && plan.isSample && mealPlan === 'sample')
        )
      }) || DashboardBuilder.getMealPlansEmptyItem()
    )
  },
  mealPlanSelectedType: (state) => state.mealPlanSelectedType,
  mealPlanItems: (state) => {
    let mealPlans = _.get(state, 'mealPlans.mealPlans', []).map(
      DashboardBuilder.getMealPlansItem
    )
    let featuredPlans = _.get(state, 'mealPlans.promotionalPlans', []).map(
      DashboardBuilder.getMealPlansItem
    )
    let customMealPlans = _.get(state, 'mealPlans.customMealPlans', []).map(
      DashboardBuilder.getMealPlansCustomItem
    )

    return [].concat(mealPlans).concat(featuredPlans).concat(customMealPlans)
  },
  promotionalPlans: (state) => {
    let mealPlans = _.get(state, 'mealPlans.mealPlans', []).map(
      DashboardBuilder.getMealPlansItem
    )
    let featuredPlans = _.get(state, 'mealPlans.promotionalPlans', []).map(
      DashboardBuilder.getMealPlansItem
    )

    return [].concat(mealPlans).concat(featuredPlans)
  },
  mealPlanItemsFiltered: (state, getters, rootState, rootGetters) => {
    const permissions = rootGetters['user/permissions']
    let showCustom = state.mealPlanSelectedType === 'custom'
    return getters.mealPlanItems.filter(
      (item) =>
        item.isCustom === showCustom &&
        ((permissions.canAccessCustomMealPlans && item.isCustom) ||
          !item.isCustom)
    )
  },
  mealPlanItemsFilteredAndGrouped: (state, getters) => {
    return _.groupBy(getters.mealPlanItemsFiltered, (item) => item.group)
  },
  mealPlans(state) {
    return _.get(state, 'mealPlans.mealPlans', [])
  },
  lastUndoAction(state) {
    return state.lastUndoAction
  },
  mealPlanWeeklyItems: (state, getters) => {
    return getters.mealPlanItems.filter((item) => !item.isCustom)
  },
  mealPlanCustomItems: (state, getters, rootState, rootGetters) => {
    const permissions = rootGetters['user/permissions']
    return getters.mealPlanItems.filter(
      (item) => item.isCustom && permissions.canAccessCustomMealPlans
    )
  },
  getMealPlanTypeName: (state) => {
    return state.mealPlanTypeName
  },
}

let dashboardCancel = () => {}
let getMealsCancel = () => {}

let mealsUpdatePromise

// actions
const actions = {
  refreshDashboard({ state, commit, dispatch }) {
    commit(types.SET_LISTS_NUMBER_IN_DASHBOARD, state.minListsInDashboard)
    return Promise.all([dispatch('getDashboard'), dispatch('getMeals')])
  },
  getDashboard({ commit, dispatch, rootGetters }) {
    dashboardCancel()
    commit(types.SET_DASHBOARD_STATUS, status.LOADING)
    if (rootGetters['globals/previewParams']) {
      return apiDashboard
        .getDashboardPreview(
          rootGetters['globals/previewParams'],
          {},
          (c) => (dashboardCancel = c)
        )
        .then((dashboard) => {
          commit(types.SET_DASHBOARD_STATUS, null)
          commit(types.RECEIVE_DASHBOARD, dashboard)
          // TK: I don't think we need to update the user weekly plan id,
          // user is not supposed to click around to other pages meal planner in preview mode
          //dispatch('globals/setUserWeeklyPlanId', dashboard.userWeeklyPlanId, {root:true})

          return dashboard
        })
        .catch((cancelled) => cancelled) // Prevent logging uncaught error in console when promise is cancelled.
    } else {
      const userWeeklyPlanIdExist = rootGetters['globals/userWeeklyPlanId']
      const data = {
        user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
      }
      return apiDashboard
        .getDashboard(
          { type: rootGetters['globals/mealPlan'] },
          data,
          (c) => (dashboardCancel = c)
        )
        .then((dashboardPayload) => {
          const dashboard = dashboardPayload.camelized
          commit(types.SET_DASHBOARD_STATUS, null)
          commit(types.RECEIVE_DASHBOARD, dashboard)
          dispatch('globals/setUserWeeklyPlanId', dashboard.userWeeklyPlanId, {
            root: true,
          })
          if (!userWeeklyPlanIdExist) {
            dispatch('updateWeeklyPlanIdInDropdown', {
              mealPlan: dashboard.id,
              userWeeklyPlanId: dashboard.userWeeklyPlanId,
            })
          }

          return dashboard
        })
        .catch((cancelled) => cancelled) // Prevent logging uncaught error in console when promise is cancelled.
    }
  },
  getMeals({ commit, rootGetters }) {
    getMealsCancel()
    commit(types.SET_DASHBOARD_MEALS_STATUS, status.LOADING)

    if (rootGetters['globals/previewParams']) {
      return apiDashboard
        .getMealsPreview(
          rootGetters['globals/previewParams'],
          {},
          (c) => (getMealsCancel = c)
        )
        .then((meals) => {
          commit(types.SET_DASHBOARD_MEALS_STATUS, null)
          commit(types.RECEIVE_DASHBOARD_MEALS, meals)

          return meals
        })
        .catch((cancelled) => cancelled) // Prevent logging uncaught error in console when promise is cancelled.
    } else {
      const data = {
        user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
      }
      return apiDashboard
        .getMeals(
          { type: rootGetters['globals/mealPlan'] },
          data,
          (c) => (getMealsCancel = c)
        )
        .then((meals) => {
          commit(types.SET_DASHBOARD_MEALS_STATUS, null)
          commit(types.RECEIVE_DASHBOARD_MEALS, meals)

          return meals
        })
        .catch((cancelled) => cancelled) // Prevent logging uncaught error in console when promise is cancelled.
    }
  },
  queueMoveMeal({ dispatch }, newPosition) {
    return dispatch('queueUpdate', { action: 'moveMeal', data: newPosition })
  },
  queueRemoveMeal({ dispatch }, mealId) {
    return dispatch('queueUpdate', { action: 'removeMeal', data: mealId })
  },
  queueAddOrSwapMeal({ dispatch, commit }, { recipeId, day, mealType }) {
    const loadingItemId = Date.now()
    commit(types.ADD_DASHBOARD_PLAN_LOADING_ITEM, {
      id: loadingItemId,
      day,
      mealType,
    })

    let addOrSwapPromise = dispatch('queueUpdate', {
      action: 'addOrSwapMeal',
      data: { recipeId, day, mealType },
    })

    let RMItem = () => {
      commit(types.REMOVE_DASHBOARD_PLAN_LOADING_ITEM, loadingItemId)
    }

    addOrSwapPromise.then(RMItem, RMItem)

    return addOrSwapPromise
  },
  queueResetDashboard({ dispatch }) {
    return dispatch('queueUpdate', {
      action: 'resetDashboard',
      lockQueue: true,
    })
  },
  queueClearDashboard({ dispatch }) {
    return dispatch('queueUpdate', {
      action: 'clearDashboard',
      lockQueue: true,
    })
  },
  queueSwapAllRecipeInstancesFromPlan({ dispatch, state }) {
    return dispatch('queueUpdate', {
      action: 'swapAllRecipeInstancesFromPlan',
      data: state.swapAction,
      lockQueue: true,
    })
  },
  queueUpdate({ state, dispatch, commit }, { action, data, lockQueue }) {
    // Should never happen.
    if ([status.FULL, status.LOCKED].includes(state.queueStatus))
      return Promise.reject()
    if (state.swapAction) dispatch('clearSwapAction')

    if (mealsUpdatePromise) {
      commit(types.SET_DASHBOARD_QUEUE_STATUS, status.FULL)
      return mealsUpdatePromise.then(() => {
        let promise = dispatch(action, data)
        promise.then(() => commit(types.SET_DASHBOARD_QUEUE_STATUS, null))
        return promise
      })
    }

    if (lockQueue) commit(types.SET_DASHBOARD_QUEUE_STATUS, status.LOCKED)
    mealsUpdatePromise = dispatch(action, data)
    mealsUpdatePromise.then(() => {
      mealsUpdatePromise = null
      if (lockQueue) commit(types.SET_DASHBOARD_QUEUE_STATUS, null)
    })
    return mealsUpdatePromise
  },
  resetDashboard({ commit, dispatch, rootGetters }) {
    commit(types.SET_DASHBOARD_QUEUE_STATUS, status.DISABLED)
    const options = {
      user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
    }
    return apiDashboard
      .resetDashboard(rootGetters['globals/mealPlan'], options)
      .then(() => dispatch('getMeals'))
  },
  applyUndo({ state, commit, dispatch }) {
    if (!state.lastUndoAction) return
    const { action, actionData } = state.lastUndoAction
    return dispatch(action, actionData).then(() => {
      commit(types.SET_LAST_UNDO_ACTION, null)
    })
  },
  clearDashboard({ commit, dispatch, rootGetters }) {
    commit(types.SET_DASHBOARD_QUEUE_STATUS, status.DISABLED)
    const options = {
      user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
    }
    return apiDashboard
      .clearDashboard(rootGetters['globals/mealPlan'], options)
      .then(() => dispatch('getMeals'))
  },
  switchToSampleMealPlan({ state, dispatch }) {
    const switchToPlan = state.mealPlans.mealPlans.find((item) => item.isSample)
    dispatch(
      'globals/setMealPlanData',
      {
        mealPlan: switchToPlan.id,
        userWeeklyPlanId: switchToPlan.userWeeklyPlanId,
      },
      { root: true }
    )
    return dispatch('refreshDashboard')
  },
  deleteUserMealPlan(
    { state, commit, dispatch, rootGetters },
    userWeeklyPlanId
  ) {
    if (!userWeeklyPlanId) {
      userWeeklyPlanId = rootGetters['globals/userWeeklyPlanId']
    }
    commit(types.SET_DASHBOARD_QUEUE_STATUS, status.DISABLED)
    return apiDashboard
      .deleteUserMealPlan(userWeeklyPlanId)
      .then(() => {
        // if this is the currently selected plan, switch to current meal plan
        if (userWeeklyPlanId == rootGetters['globals/userWeeklyPlanId']) {
          const permissions = rootGetters['user/permissions']
          let switchToPlan
          if (permissions.canAccessNonSampleMealPlans) {
            switchToPlan = state.mealPlans.mealPlans.find(
              (item) => item.isCurrent
            )
          } else {
            switchToPlan = state.mealPlans.mealPlans.find(
              (item) => item.isSample
            )
          }
          dispatch(
            'globals/setMealPlanData',
            {
              mealPlan: switchToPlan.id,
              userWeeklyPlanId: switchToPlan.userWeeklyPlanId,
            },
            { root: true }
          )
          dispatch('refreshDashboard')
        }
      })
      .then(() => dispatch('refreshMealPlans'))
      .then(() => commit(types.SET_DASHBOARD_QUEUE_STATUS, null))
  },
  saveAsCustomPlan({ commit, dispatch, rootGetters }, name) {
    commit(types.SET_DASHBOARD_QUEUE_STATUS, status.DISABLED)
    return apiDashboard
      .saveAsCustomPlan({
        name: name,
        user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
      })
      .then((data) => {
        commit(types.SET_DASHBOARD_TITLE, data.title)
        dispatch('globals/setMealPlan', 'custom', { root: true })
        dispatch('globals/setUserWeeklyPlanId', data.id, { root: true })
      })
      .then(() =>
        Promise.all([
          dispatch('refreshDashboard'),
          dispatch('refreshMealPlans'),
        ])
      )
      .then(() => commit(types.SET_DASHBOARD_QUEUE_STATUS, null))
  },
  createCustomPlan({ commit, dispatch }, name) {
    commit(types.SET_DASHBOARD_QUEUE_STATUS, status.DISABLED)
    return apiDashboard
      .saveAsCustomPlan({
        name: name,
      })
      .then((data) => {
        commit(types.SET_DASHBOARD_TITLE, data.title)
        dispatch('globals/setMealPlan', 'custom', { root: true })
        dispatch('globals/setUserWeeklyPlanId', data.id, { root: true })
      })
      .then(() =>
        Promise.all([
          dispatch('refreshDashboard'),
          dispatch('refreshMealPlans'),
        ])
      )
      .then(() => commit(types.SET_DASHBOARD_QUEUE_STATUS, null))
  },
  renameCustomPlan({ commit, dispatch, rootGetters }, name) {
    commit(types.SET_DASHBOARD_QUEUE_STATUS, status.DISABLED)
    return apiDashboard
      .renameCustomPlan({
        name: name,
        user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
      })
      .then((data) => {
        commit(types.SET_DASHBOARD_TITLE, data.title)
        dispatch('globals/setMealPlan', 'custom', { root: true })
        dispatch('globals/setUserWeeklyPlanId', data.id, { root: true })
      })
      .then(() => dispatch('getMeals'))
      .then(() => dispatch('refreshMealPlans'))
      .then(() => commit(types.SET_DASHBOARD_QUEUE_STATUS, null))
  },
  moveMeal({ commit, state }, newPosition) {
    let movedMeal = state.meals.find((meal) => meal.id === newPosition.id)
    let toMeal = state.meals.find(
      ({ day, mealType }) =>
        day === newPosition.list && mealType === newPosition.index
    )
    let toId = _.get(toMeal, 'id', null)

    let options = {
      src_user_recipe_id: newPosition.id,
      src_day: movedMeal.day,
      src_meal_type: movedMeal.mealType,

      dest_user_recipe_id: toId,
      dest_day: newPosition.list,
      dest_meal_type: newPosition.index,

      version: state.mealsVersion,
    }

    return apiDashboard.moveMeal(options).then((meals) => {
      commit(types.RECEIVE_DASHBOARD_MEALS, meals)
      return meals
    })
  },
  removeMeal({ commit, state, rootGetters }, mealId) {
    let meals = state.meals.find((meal) => meal.id === mealId)
    let { day, mealType, recipe } = meals

    let options = {
      meal_plan_type: rootGetters['globals/mealPlan'],
      user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
      recipe_id: recipe.id,
      day: day,
      meal_type: mealType,
      version: state.mealsVersion,
    }

    return apiDashboard.removeMeal(options).then((meals) => {
      commit(types.RECEIVE_DASHBOARD_MEALS, meals)
      commit(types.SET_LAST_UNDO_ACTION, {
        action: 'queueAddOrSwapMeal',
        actionData: {
          day,
          recipeId: recipe.id,
        },
        description: `Meal removed from Day ${day + 1}`,
      })
      return meals
    })
  },
  closeNotification({ dispatch }) {
    dispatch('notifications/clearNotification', null, { root: true })
  },
  async setSwapAction({ commit, dispatch }, { idOld, idNew }) {
    const icon = await import('@/assets/icons/dashboard-swap.svg')

    const onAction = async () => {
      dispatch('queueSwapAllRecipeInstancesFromPlan')
      dispatch('notifications/clearNotification', null, { root: true })
    }
    const onCloseAction = () =>
      dispatch('notifications/clearNotification', null, { root: true })
    const action = {
      action: onAction,
      closeAction: onCloseAction,
      actionText: 'YES',
      closeActionText: 'NO',
    }
    dispatch(
      'notifications/setActionNotification',
      {
        action,
        icon: icon.default,
        text: 'Swap out all instances of this recipe?',
        color: 'navy',
      },
      { root: true }
    )

    commit(types.SET_SWAP_ACTION, { idOld, idNew })
  },
  clearSwapAction({ commit }) {
    // commented swap time
    // clearTimeout(swapActionTimeout)
    commit(types.SET_SWAP_ACTION, null)
  },
  swapAllRecipeInstancesFromPlan(
    { commit, state, dispatch, rootGetters },
    { idOld, idNew }
  ) {
    let options = {
      meal_plan_type: rootGetters['globals/mealPlan'],
      user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
      recipe_id_new: idNew,
      recipe_id_old: idOld,
      meal_type: -1,
      version: state.mealsVersion,
    }

    dispatch('clearSwapAction')
    return apiDashboard
      .swapAllRecipeInstancesFromPlan(options)
      .then((meals) => {
        commit(types.RECEIVE_DASHBOARD_MEALS, meals)
        return meals
      })
  },
  addOrSwapMeal(
    { state, commit, dispatch, rootGetters },
    { recipeId, day, mealType }
  ) {
    let mealOnPosition = state.meals.find(
      (meal) => meal.day === day && meal.mealType === mealType
    )

    let nextMealType = state.meals
      .filter((meal) => meal.day === day)
      .map(({ mealType }) => mealType)
      .reduce((maxNumber, number) => Math.max(maxNumber, number + 1), 0)

    let options = {
      meal_plan_type: rootGetters['globals/mealPlan'],
      user_weekly_plan_id: rootGetters['globals/userWeeklyPlanId'],
      recipe_id: recipeId,
      day: day,
      meal_type: mealType != null ? mealType : nextMealType,
      replace: !!mealOnPosition,
      version: state.mealsVersion,
    }

    return apiDashboard.addMeal(options).then((meals) => {
      commit(types.RECEIVE_DASHBOARD_MEALS, meals)
      if (!mealOnPosition) {
        commit(types.SET_LAST_UNDO_ACTION, {
          action: 'queueRemoveMeal',
          actionData: meals.recipe.id,
          description: `Meal added to Day ${day + 1}`,
        })
      } else {
        commit(types.SET_LAST_UNDO_ACTION, {
          action: 'queueAddOrSwapMeal',
          actionData: {
            recipeId: mealOnPosition.recipe.id,
            day,
            mealType,
          },
          description: `Meal swapped out successfully`,
        })
      }

      if (meals.canSwap) {
        dispatch('setSwapAction', {
          idOld: mealOnPosition.recipe.id,
          idNew: recipeId,
        })
      }
      return meals
    })
  },
  refreshMealPlans({ commit }) {
    return apiDashboard
      .getMealPlans()
      .then((mealPlans) => commit(types.SET_MEAL_PLANS, mealPlans))
  },
  showSaveAsCustomPlanModal({ commit }, visible) {
    commit(types.SAVE_AS_CUSTOM_PLAN_MODAL_VISIBLE, visible)
  },
  showRenameCustomPlanModal({ commit }, visible) {
    commit(types.RENAME_CUSTOM_PLAN_MODAL_VISIBLE, visible)
  },
  showDeleteCustomPlanModal({ commit }, visible) {
    commit(types.DELETE_CUSTOM_PLAN_MODAL_VISIBLE, visible)
  },
  updateWeeklyPlanIdInDropdown({ commit }, payload) {
    commit(types.UPDATE_WEEKLY_PLAN_ID_IN_DROPDOWN, payload)
  },
  setLoadingState({ commit }) {
    commit(types.SET_DASHBOARD_MEALS_STATUS, status.LOADING)
    commit(types.RECEIVE_DASHBOARD_MEALS, { version: null, userRecipes: null })
  },
  setShowTrialBanner({ commit }, show) {
    commit(types.SET_SHOW_TRIAL_BANNER, show)
  },
  setMealsVersion({ commit }, version) {
    commit(types.SET_MEALS_VERSION, version)
  },
  setMealPlanTypeName({ commit }, mealPlan) {
    commit(types.SET_MEAL_PLAN_TYPE_NAME, mealPlan)
  },
}

// mutations
const mutations = {
  [types.SET_DASHBOARD_TITLE](state, title) {
    state.dashboard.title = title
  },
  [types.SET_SWAP_ACTION](state, swapAction) {
    state.swapAction = swapAction
  },
  [types.SET_DASHBOARD_STATUS](state, status) {
    state.dashboardStatus = status
  },
  [types.RECEIVE_DASHBOARD](state, dashboard) {
    state.dashboard = dashboard
  },
  [types.SET_DASHBOARD_MEALS_STATUS](state, status) {
    state.mealsStatus = status
  },
  [types.RECEIVE_DASHBOARD_MEALS](state, meals) {
    state.mealsVersion = meals.version
    state.meals = meals.userRecipes
  },
  [types.SET_MEALS_VERSION](state, version) {
    state.mealsVersion = version
  },
  [types.SET_MIN_LISTS_IN_DASHBOARD](state, minListsInDashboard) {
    state.minListsInDashboard = minListsInDashboard
  },
  [types.SET_LISTS_NUMBER_IN_DASHBOARD](state, listsNumberInDashboard) {
    state.listsNumberInDashboard = listsNumberInDashboard
  },
  [types.SET_DASHBOARD_QUEUE_STATUS](state, status) {
    state.queueStatus = status
  },
  [types.SET_IS_USER_ACTIVE](state, status) {
    state.isUserActive = status
  },
  [types.SET_MEAL_PLANS](state, mealPlans) {
    state.mealPlans = mealPlans
  },
  [types.MEAL_PLAN_SELECTED_TYPE](state, type) {
    state.mealPlanSelectedType = type
  },
  [types.SAVE_AS_CUSTOM_PLAN_MODAL_VISIBLE](state, visible) {
    state.saveAsCustomPlanModalVisible = visible
  },
  [types.RENAME_CUSTOM_PLAN_MODAL_VISIBLE](state, visible) {
    state.renameCustomPlanModalVisible = visible
  },
  [types.DELETE_CUSTOM_PLAN_MODAL_VISIBLE](state, visible) {
    state.deleteCustomPlanModalVisible = visible
  },
  [types.UPDATE_WEEKLY_PLAN_ID_IN_DROPDOWN](state, payload) {
    Vue.set(
      state.mealPlans,
      'mealPlans',
      _.map(state.mealPlans.mealPlans, (mealPlan) => {
        if (mealPlan.id == payload.mealPlan) {
          mealPlan.userWeeklyPlanId = payload.userWeeklyPlanId
        }
        return mealPlan
      })
    )
  },
  [types.ADD_DASHBOARD_PLAN_LOADING_ITEM](state, item) {
    state.dashboardPlanLoadingItems.push(item)
  },
  [types.REMOVE_DASHBOARD_PLAN_LOADING_ITEM](state, id) {
    state.dashboardPlanLoadingItems.some((item, key) => {
      if (item.id === id) {
        state.dashboardPlanLoadingItems.splice(key, 1)
      }
    })
  },
  [types.SET_SHOW_TRIAL_BANNER](state, show) {
    state.showTrialBanner = show
  },
  [types.SET_LAST_UNDO_ACTION](state, lastUndoAction) {
    state.lastUndoAction = lastUndoAction
  },
  [types.SET_MEAL_PLAN_TYPE_NAME](state, mealPlan) {
    state.mealPlanTypeName = mealPlan
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
