<template>
  <div>
    <!-- Promotion/Affiliate offer EXPIRED banner -->
    <MPNotification
      v-if="!isLoggedIn && promotionBannerVisible && affiliateOfferDiscount < 0"
      type="error"
      class="full"
      :title="getOfferErrorTitle"
    />
    <!-- Promotion/Affiliate offer VALID banner -->
    <MPNotification
      v-if="
        !isLoggedIn &&
        promotionBannerVisible &&
        (affiliateOfferDiscount > 0 || alwaysDisplayOfferBanner)
      "
      type="offer"
      class="full"
      :title="getOfferTitle"
    />
    <!-- BANNER: promotion is for new users only -->
    <MPNotification
      v-if="
        alertMemberBannerVisible && promotionInfo.userDiscountApplied === false
      "
      type="error"
      :image="require('@/assets/icons/error.svg')"
      class="full-with-navbar add-lr-padding"
      :showCloseButton="true"
      @onCloseActionSelect="setAlertMemberBannerVisible(false)"
    >
      <span
        >{{ getOfferErrorTitle }} Please
        <MPWhiteLink href @click.prevent="onContact">contact</MPWhiteLink>
        our support team if you have questions.</span
      >
    </MPNotification>
    <!-- BANNER: user successfully applied a discount from the offer -->
    <MPNotification
      v-if="
        alertMemberBannerVisible &&
        !!promotionInfo.userDiscountApplied &&
        promotionInfo.userDiscountPercent > 0
      "
      type="offer"
      :image="require('@/assets/icons/check-green.svg')"
      class="full-with-navbar add-lr-padding"
      :showCloseButton="true"
      @onCloseActionSelect="setAlertMemberBannerVisible(false)"
    >
      <span v-if="promotionInfo.userDiscountPopUpMessage">
        {{ promotionInfo.userDiscountPopUpMessage }}
      </span>
      <span v-else
        >Discount of {{ promotionInfo.userDiscountPercent }}% was successfully
        applied to your account.</span
      >
    </MPNotification>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import environment from '@/environment'
import PromotionsHandler from '@/handlers/PromotionsHandler'
import { reasons } from './affiliate-offer-helper'

import { MPWhiteLink } from '@/components/common'

export default {
  components: {
    MPWhiteLink,
  },
  props: {
    isLoggedIn: { required: true, type: Boolean },
  },
  computed: {
    ...mapGetters({
      affiliateCode: 'globals/affiliateCode',
      alertMemberBannerVisible: 'globals/alertMemberBannerVisible',
      alwaysDisplayOfferBanner: 'globals/alwaysDisplayOfferBanner',
      promotionBannerVisible: 'globals/promotionBannerVisible',
      promotionInfo: 'user/promotionInfo',
      affiliateOfferDiscount: 'globals/affiliateOfferDiscount',
      affiliateOfferExpires: 'globals/affiliateOfferExpires',
      affiliateOfferBanner: 'globals/affiliateOfferBanner',
    }),
    getOfferTitle() {
      if (this.affiliateOfferBanner) {
        return this.affiliateOfferBanner
      }

      return `An additional ${this.affiliateOfferDiscount}% LIFETIME discount has been applied to any plan! This offer expires in ${this.affiliateOfferExpires} days.`
    },
    getOfferErrorTitle() {
      const errors = {
        [reasons.notEligible]: 'This promotions is for new users only.',
        [reasons.alreadyPremium]:
          'This promotion is for non-premium users only.',
        [reasons.notFound]: 'Promotion is invalid.',
        [reasons.offerCapReached]:
          'No more spots available for this promotion.',
        [reasons.inactive]: 'Promotion is no longer valid.',
      }
      return (
        errors[this.promotionInfo.userDiscountNotAppliedReason] ||
        'Sorry, this promotion is not available for you.'
      )
    },
  },
  mounted() {
    this.checkAffiliate()
  },
  methods: {
    checkAffiliate() {
      const cookieAffiliateRef = this.$cookie.get('new-ref', {
        domain: environment.cookieDomain,
      })
      if (cookieAffiliateRef) {
        this.$cookie.delete('new-ref', { domain: environment.cookieDomain })
      }
      let affiliateCode = this.$routeQuery.get('ref') || cookieAffiliateRef
      if (!affiliateCode) {
        return
      }
      const storedAffiliateRef = this.$cookie.get('ref', {
        domain: environment.cookieDomain,
      })
      if (affiliateCode.indexOf('delete') != -1) {
        this.$cookie.delete('ref', { domain: environment.cookieDomain })
        this.$store.dispatch('globals/setAffiliateCode', null, { root: true })
        this.$store.dispatch('globals/setAffiliateOfferDiscount', null, {
          root: true,
        })
        this.$store.dispatch('globals/setAffiliateOfferExpires', null, {
          root: true,
        })
        this.$store.dispatch('globals/setAffiliateOfferBanner', null, {
          root: true,
        })
        this.$store.dispatch(
          'globals/setAlwaysDisplayAffiliateOfferBanner',
          false,
          { root: true }
        )
        affiliateCode = null
      } else {
        var now = new Date()
        var exp = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate())
        this.$cookie.set('ref', affiliateCode, {
          expires: exp,
          domain: environment.cookieDomain,
        })
        this.$store.dispatch('globals/setAffiliateCode', affiliateCode, {
          root: true,
        })
        PromotionsHandler.trackPromotionCode(
          this.$store,
          affiliateCode,
          this.affiliateCode,
          !storedAffiliateRef || storedAffiliateRef === affiliateCode
        )
        if (this.isLoggedIn) {
          PromotionsHandler.handlePromotionCode(this.$store, this.affiliateCode)
        }
      }
    },
    setAlertMemberBannerVisible(visible) {
      this.$store.dispatch('globals/setAlertMemberBannerVisible', visible, {
        root: true,
      })
    },
    onContact() {
      this.$emit('contact')
    },
  },
}
</script>
