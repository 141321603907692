<template>
  <MPButton
    :color="color"
    :size="size"
    :disabled="disabled"
    @click="$emit('click')"
    :data-spec="dataSpec"
  >
    <span class="btn-description">{{ text }}</span>
  </MPButton>
</template>

<script>
import { MPButton } from '@/components/common'

export default {
  name: 'ButtonNext',
  components: {
    MPButton,
  },
  props: {
    text: {
      type: String,
      default: 'Next',
    },
    size: {
      type: String,
      default: 'large-register',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    dataSpec: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-description {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: uppercase;
}
</style>
