<template>
  <div class="recipe-edit-content">
    <div class="d-none d-md-flex justify-content-center">
      <MPButton color="primary" class="edit-button" @click="onClick">
        <Edit class="mr-2" />
        Edit Recipe
      </MPButton>
    </div>
    <div class="recipe-edit-content-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { Edit } from '@/components/common/MPIcons'
export default {
  name: 'RecipeEditContentContainer',
  components: {
    Edit,
  },
  methods: {
    onClick(event) {
      this.$emit('clickHandler', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.recipe-edit-content-container {
  border: dashed 2px #d5d6d7;
  padding: 35px 20px;
  @include media-breakpoint-up(md) {
    min-height: 492px;
  }
}
.edit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 175px;
  position: relative;
  top: 25px;
}
</style>
