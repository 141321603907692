<template>
  <div class="comments-count">
    <span class="comments-count-text">{{ commentsNumber }} comments</span>
  </div>
</template>

<script>
export default {
  props: {
    commentsNumber: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss" scoped>
.comments-count {
  @include media-breakpoint-down(sm) {
    margin-top: 5px;
  }
}
.comments-count-text {
  color: $link-color;
  display: inline-block;
  &:hover {
    color: $link-hover-color;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
}
</style>
