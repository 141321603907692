import apiGroceryList from '@/api/groceryListApi'
import * as types from '../mutation-types'
import _ from 'lodash'
import { toFraction } from '@/utils/conversions'

let aisleSortingTimeout, recipeSortingTimeout

const initialState = {
  isPending: false,
  shoppingListByAisleRaw: null,
  shoppingListByRecipeRaw: null,
  shoppingListByAisleProcessed: null,
  shoppingListByRecipeProcessed: null,
  shoppingListTips: null,
  unitList: null,
  aisleList: null,
  customInput: {
    unitId: -1,
    aisleId: -1,
    quantity: 0,
  },
  currentAisleClick: '',
  toast: {
    message: '',
    isError: false,
    show: false,
    undoId: -1,
  },
  disablePrintingButton: false,
  disableAmazonFreshButton: false,
  selectedIngredientForEdit: {
    name: '',
    isCustom: false,
    quantities: [],
  },
}

// getters
const getters = {
  getSelectedIngredientForEdit: (state) => state.selectedIngredientForEdit,
  getShoppingListByAisle: (state) => {
    const items = _.filter(state.shoppingListByAisleProcessed, function (a) {
      return a.title !== 'Pantry Items'
    })
    return items && items.length > 0 ? items : null
  },
  getPantryItems: (state) => {
    const items = _.filter(state.shoppingListByAisleProcessed, function (a) {
      return a.title === 'Pantry Items'
    })
    return items && items.length > 0 ? items[0] : null
  },
  getShoppingListByRecipe: (state) => {
    return state.shoppingListByRecipeProcessed
  },
  getShoppingListTips: (state) => {
    return state.shoppingListTips
  },
  getAisleList: (state) => {
    return state.aisleList
  },
  getUnitList: (state) => {
    return state.unitList
  },
  getCurrentAisle: (state) => {
    return state.currentAisleClick
  },
}

function groceryListEndpointsStrategy(previewParams) {
  if (previewParams)
    return {
      getShoppingListByAisle: apiGroceryList.getPreviewShoppingListByAisle,
      getShoppingListByRecipe: apiGroceryList.getPreviewShoppingListByRecipes,
      getShoppingListTips: apiGroceryList.getPreviewShoppingListTips,
      getAisleList: () => Promise.resolve([]),
      getUnitList: () => Promise.resolve([]),
    }
  else
    return {
      getShoppingListByAisle: apiGroceryList.getShoppingListByAisle,
      getShoppingListByRecipe: apiGroceryList.getShoppingListByRecipes,
      getShoppingListTips: apiGroceryList.getShoppingListTips,
      getAisleList: apiGroceryList.getAisleList,
      getUnitList: apiGroceryList.getUnitList,
    }
}

// actions
const actions = {
  refreshGroceryList({ dispatch, commit }) {
    commit(types.SET_GROCERY_LIST_PENDING, true)
    const returnPromise = Promise.all([
      dispatch('getShoppingListByAisle'),
      dispatch('getShoppingListByRecipe'),
      dispatch('getShoppingListTips'),
      dispatch('getAisleList'),
      dispatch('getUnitList'),
    ])
    returnPromise.then(() => {
      setTimeout(() => {
        commit(types.SET_GROCERY_LIST_PENDING, false)
      }, 2000)
    })
    return returnPromise
  },
  setSelectedIngredientForEdit({ commit }, ingredient) {
    commit(types.SET_SELECTED_INGREDIENT_FOR_EDIT, ingredient)
  },
  buyOnAmazonFresh({ state, dispatch }, { mealPlan, userWeeklyPlanId }) {
    state.disableAmazonFreshButton = true
    return apiGroceryList
      .buyOnAmazonFresh(mealPlan, userWeeklyPlanId)
      .then((res) => {
        state.disableAmazonFreshButton = false
        return res
      })
      .catch((err) => {
        state.disableAmazonFreshButton = false
        dispatch('showToast', {
          message: 'Failed to generate Amazon Fresh shopping list',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  printFileByAisle({ state, rootGetters }) {
    state.disablePrintingButton = true
    getPrintFile(
      rootGetters['globals/mealPlan'],
      rootGetters['globals/userWeeklyPlanId'],
      true
    ).then((res) => {
      downloadPdf(res, 'grocery-list-by-aisle.pdf')
      state.disablePrintingButton = false
    })
  },
  printFileByRecipe({ state, rootGetters }) {
    state.disablePrintingButton = true
    getPrintFile(
      rootGetters['globals/mealPlan'],
      rootGetters['globals/userWeeklyPlanId'],
      false
    ).then((res) => {
      downloadPdf(res, 'grocery-list-by-recipe.pdf')
      state.disablePrintingButton = false
    })
  },
  getShoppingListByAisle({ commit, state, rootGetters }) {
    const backup = state.shoppingListByAisleRaw
    return groceryListEndpointsStrategy(rootGetters['globals/previewParams'])
      .getShoppingListByAisle(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        rootGetters['globals/previewParams']
      )
      .then((res) => {
        commit(types.GET_SHOPPING_DATA_BY_AISLE.SUCCESS, res)
        commit(types.SORT_BY_AISLE)
        commit(types.SORT_BY_AISLE_EXCLUDED, { timeout: 0 })
        return res
      })
      .catch((err) => {
        commit(types.GET_SHOPPING_DATA_BY_AISLE.ERROR, backup)
        return err
      })
  },
  getShoppingListByRecipe({ commit, state, rootGetters }) {
    const backup = state.shoppingListByRecipeRaw
    return groceryListEndpointsStrategy(rootGetters['globals/previewParams'])
      .getShoppingListByRecipe(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        rootGetters['globals/previewParams']
      )
      .then((res) => {
        commit(types.GET_SHOPPING_DATA_BY_RECIPE.SUCCESS, res)
        commit(types.SORT_BY_RECIPE)
        commit(types.SORT_BY_RECIPE_EXCLUDED, { timeout: 0 })
        return res
      })
      .catch((err) => {
        commit(types.GET_SHOPPING_DATA_BY_RECIPE.ERROR, backup)
        return err
      })
  },
  getShoppingListTips({ commit, state, rootGetters }) {
    const backup = state.shoppingListTips
    return groceryListEndpointsStrategy(rootGetters['globals/previewParams'])
      .getShoppingListTips(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        rootGetters['globals/previewParams']
      )
      .then((res) => {
        commit(types.GET_SHOPPING_DATA_TIPS.SUCCESS, res)
        return res
      })
      .catch((err) => {
        commit(types.GET_SHOPPING_DATA_TIPS.ERROR, backup)
        return err
      })
  },
  getAisleList({ commit, state, rootGetters }) {
    const backup = state.aisleList
    return groceryListEndpointsStrategy(rootGetters['globals/previewParams'])
      .getAisleList()
      .then((res) => {
        commit(types.GET_AISLE_LIST.SUCCESS, res)
        return res
      })
      .catch((err) => {
        commit(types.GET_AISLE_LIST.ERROR, backup)
        return err
      })
  },
  getUnitList({ commit, state, rootGetters }) {
    const backup = state.unitList
    return groceryListEndpointsStrategy(rootGetters['globals/previewParams'])
      .getUnitList()
      .then((res) => {
        commit(types.GET_UNIT_LIST.SUCCESS, res)
        return res
      })
      .catch((err) => {
        commit(types.GET_UNIT_LIST.ERROR, backup)
        return err
      })
  },
  manipulateAvailableIngredient({ dispatch }, payload) {
    if (payload.availableQuantityId) {
      if (payload.quantity == 0) {
        dispatch('removeAvailableIngredient', {
          availableQuantityId: payload.availableQuantityId,
        })
        return true
      } else {
        dispatch('updateAvailableIngredient', {
          availableQuantityId: payload.availableQuantityId,
          quantity: payload.quantity,
        })
        return true
      }
    } else {
      if (payload.quantity > 0) {
        dispatch('addAvailableIngredient', {
          ingredientId: payload.ingredient.id,
          quantity: payload.quantity,
          unitId: payload.unitId,
        })
        return true
      }
    }
    return false
  },
  addAvailableIngredient({ dispatch, rootGetters }, payload) {
    apiGroceryList
      .addAvailableIngredient(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        payload.ingredientId,
        payload.quantity,
        payload.unitId
      )
      .then((res) => {
        dispatch('getShoppingListByAisle')
        dispatch('ingredientUpdated')
        return res
      })
      .catch((err) => {
        dispatch('showToast', {
          message: 'Error adding available ingredient',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  updateAvailableIngredient({ dispatch, rootGetters }, payload) {
    apiGroceryList
      .updateAvailableIngredient(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        payload.availableQuantityId,
        payload.quantity
      )
      .then((res) => {
        dispatch('getShoppingListByAisle')
        dispatch('ingredientUpdated')
        return res
      })
      .catch((err) => {
        dispatch('showToast', {
          message: 'Error updating available ingredient',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  removeAvailableIngredient({ dispatch, rootGetters }, payload) {
    apiGroceryList
      .removeAvailableIngredient(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        payload.availableQuantityId
      )
      .then((res) => {
        dispatch('getShoppingListByAisle')
        return res
      })
      .catch((err) => {
        dispatch('showToast', {
          message: 'Error removing available ingredient',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  addCustomShoppingListItem({ state, dispatch, rootGetters }, payload) {
    apiGroceryList
      .addCustomShoppingListItem(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        payload.ingredientName,
        state.customInput.unitId,
        state.customInput.aisleId,
        state.customInput.quantity
      )
      .then((res) => {
        dispatch('getShoppingListByAisle')
        dispatch('showToast', {
          message: `${payload.ingredientName} added`,
          isError: false,
          undoId: res.customShoppingListItemId,
        })
        return res
      })
      .catch((err) => {
        dispatch('showToast', {
          message: 'Error adding custom item',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  updateCustomShoppingListItem({ commit, dispatch, rootGetters }, payload) {
    return apiGroceryList
      .updateCustomShoppingListItem(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        payload.itemId,
        payload.name,
        payload.quantity,
        payload.aisleId,
        payload.itemIsExcluded,
        payload.unitId
      )
      .then((res) => {
        if (payload.name) {
          dispatch('getShoppingListByAisle')
        } else {
          commit(types.SORT_BY_AISLE)
          commit(types.SORT_BY_AISLE_EXCLUDED, { timeout: 3500 })
        }
        return res
      })
      .catch((err) => {
        dispatch('showToast', {
          message: 'Error updating custom item',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  removeCustomShoppingListItem({ state, dispatch, rootGetters }, payload) {
    apiGroceryList
      .removeCustomShoppingListItem(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        payload.id
      )
      .then((res) => {
        dispatch('getShoppingListByAisle')
        state.toast.show = false
        state.toast.undoId = -1
        return res
      })
      .catch((err) => {
        dispatch('showToast', {
          message: 'Error removing custom item',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  addAisleItemToShoppingList({ commit, dispatch, rootGetters }, payload) {
    apiGroceryList
      .addAisleItemToShoppingList(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        payload.ids,
        payload.cids,
        payload.id
      )
      .then((res) => {
        commit(types.SORT_BY_AISLE)
        commit(types.SORT_BY_AISLE_EXCLUDED, { timeout: 3500 })
        dispatch('getShoppingListByRecipe')
        return res
      })
      .catch((err) => {
        dispatch('showToast', {
          message: 'Error adding aisle ingredient to grocery list',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  removeAisleItemFromShoppingList({ commit, dispatch, rootGetters }, payload) {
    apiGroceryList
      .removeAisleItemFromShoppingList(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        payload.ids,
        payload.cids,
        payload.id
      )
      .then((res) => {
        commit(types.SORT_BY_AISLE)
        commit(types.SORT_BY_AISLE_EXCLUDED, { timeout: 3500 })
        dispatch('getShoppingListByRecipe')
        return res
      })
      .catch((err) => {
        dispatch('showToast', {
          message: 'Error removing aisle ingredient from grocery list',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  addRecipeItemToShoppingList({ commit, dispatch, rootGetters }, payload) {
    return apiGroceryList
      .addRecipeItemToShoppingList(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        payload.ids,
        payload.cids,
        payload.id,
        payload.recipeId
      )
      .then((res) => {
        commit(types.SORT_BY_RECIPE)
        commit(types.SORT_BY_RECIPE_EXCLUDED, { timeout: 3500 })
        dispatch('getShoppingListByAisle')
        return res
      })
      .catch((err) => {
        dispatch('showToast', {
          message: 'Error adding recipe ingredient to grocery list',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  removeRecipeItemFromShoppingList({ commit, dispatch, rootGetters }, payload) {
    return apiGroceryList
      .removeRecipeItemFromShoppingList(
        rootGetters['globals/mealPlan'],
        rootGetters['globals/userWeeklyPlanId'],
        payload.ids,
        payload.cids,
        payload.id,
        payload.recipeId
      )
      .then((res) => {
        commit(types.SORT_BY_RECIPE)
        commit(types.SORT_BY_RECIPE_EXCLUDED, { timeout: 3500 })
        dispatch('getShoppingListByAisle')
        return res
      })
      .catch((err) => {
        dispatch('showToast', {
          message: 'Error removing recipe ingredient to grocery list',
          isError: true,
          undoId: -1,
        })
        return err
      })
  },
  ingredientUpdated({ dispatch }) {
    dispatch('showToast', {
      message: 'Ingredient updated',
      isError: false,
      undoId: -1,
    })
  },
  showToast({ state, dispatch }, payload) {
    state.toast.show = true
    state.toast.message = payload.message
    state.toast.isError = payload.isError
    state.toast.undoId = payload.undoId
    setTimeout(() => {
      dispatch('hideToast')
    }, 3000)
  },
  hideToast({ state }) {
    state.toast.show = false
    state.toast.message = ''
    state.toast.isError = false
    state.toast.undoId = -1
  },
}
// mutations
const mutations = {
  [types.SET_GROCERY_LIST_PENDING](state, payload) {
    state.isPending = payload
  },
  [types.CLEAR_GROCERY_LIST_DATA](state) {
    Object.assign(state, {}, initialState)
  },
  [types.SET_SELECTED_INGREDIENT_FOR_EDIT](state, payload) {
    state.selectedIngredientForEdit = payload
  },
  [types.SORT_BY_AISLE_EXCLUDED](state, payload) {
    if (aisleSortingTimeout) {
      clearTimeout(aisleSortingTimeout)
    }
    aisleSortingTimeout = setTimeout(() => {
      const newProcessedShoppingListByAisle = []
      state.shoppingListByAisleRaw.forEach(function (item) {
        newProcessedShoppingListByAisle.push({
          order: item.order,
          title: item.title,
          ingredients: {
            excluded: item.ingredients.filter((o) => o.isExcluded === true),
            included: item.ingredients.filter((o) => o.isExcluded === false),
            length: item.ingredients.length,
          },
        })
      })
      state.shoppingListByAisleProcessed =
        newProcessedShoppingListByAisle.length > 0
          ? newProcessedShoppingListByAisle
          : null
    }, payload.timeout)
  },
  [types.SORT_BY_RECIPE_EXCLUDED](state, payload) {
    if (recipeSortingTimeout) {
      clearTimeout(recipeSortingTimeout)
    }
    recipeSortingTimeout = setTimeout(() => {
      const newProcessedShoppingListByRecipe = []
      state.shoppingListByRecipeRaw.forEach(function (item) {
        newProcessedShoppingListByRecipe.push({
          order: item.order,
          title: item.title,
          subtitle: item.subtitle,
          id: item.id,
          thumb: item.thumb,
          image: item.image,
          url: item.url,
          ingredients: {
            excluded: item.ingredients.filter((o) => o.isExcluded === true),
            included: item.ingredients.filter((o) => o.isExcluded === false),
            length: item.ingredients.length,
          },
        })
      })
      state.shoppingListByRecipeProcessed =
        newProcessedShoppingListByRecipe.length > 0
          ? newProcessedShoppingListByRecipe
          : null
    }, payload.timeout)
  },
  [types.SORT_BY_AISLE](state) {
    state.shoppingListByAisleRaw.forEach((aisle) => {
      aisle.ingredients = _.orderBy(
        aisle.ingredients,
        ['isExcluded', 'isCustom', 'order'],
        ['asc', 'asc', 'asc']
      )
    })
  },
  [types.SORT_BY_RECIPE](state) {
    state.shoppingListByRecipeRaw.forEach((recipe) => {
      recipe.ingredients = _.orderBy(
        recipe.ingredients,
        ['isExcluded', 'order'],
        ['asc', 'asc']
      )
    })
  },
  [types.GET_SHOPPING_DATA_BY_AISLE.SUCCESS](state, payload) {
    const newShoppingListByAisle = []
    payload.forEach((element) => {
      updateIngredients(element)
      newShoppingListByAisle.push(element)
    })
    state.shoppingListByAisleRaw = newShoppingListByAisle
  },
  [types.GET_SHOPPING_DATA_BY_AISLE.ERROR](state, backup) {
    state.shoppingListByAisleRaw = backup
  },
  [types.GET_SHOPPING_DATA_BY_RECIPE.SUCCESS](state, payload) {
    const newShoppingListByRecipe = []
    payload.forEach((element) => {
      if (element.title !== 'My items') {
        updateIngredients(element)
        newShoppingListByRecipe.push(element)
      }
    })
    state.shoppingListByRecipeRaw = newShoppingListByRecipe
  },
  [types.GET_SHOPPING_DATA_BY_RECIPE.ERROR](state, backup) {
    state.shoppingListByRecipeRaw = backup
  },
  [types.GET_SHOPPING_DATA_TIPS.SUCCESS](state, payload) {
    state.shoppingListTips = payload
  },
  [types.GET_SHOPPING_DATA_TIPS.ERROR](state, backup) {
    state.shoppingListTips = backup
  },
  [types.GET_AISLE_LIST.SUCCESS](state, payload) {
    state.aisleList = _.orderBy(payload, ['name'], ['asc'])
  },
  [types.GET_AISLE_LIST.ERROR](state, backup) {
    state.aisleList = backup
  },
  [types.GET_UNIT_LIST.SUCCESS](state, payload) {
    state.unitList = _.orderBy(payload, ['name'], ['asc'])
  },
  [types.GET_UNIT_LIST.ERROR](state, backup) {
    state.unitList = backup
  },
  [types.SET_UNIT_ID](state, unitId) {
    state.customInput.unitId = unitId
  },
  [types.SET_AISLE_ID](state, aisleId) {
    state.customInput.aisleId = aisleId
  },
  [types.SET_QUANTITY](state, quantity) {
    state.customInput.quantity = quantity
  },
  [types.SET_CURRENT_AISLE_CLICK](state, aisleClick) {
    if (aisleClick == 'My items') {
      state.currentAisleClick = null
    } else {
      state.currentAisleClick = _.filter(state.aisleList, function (o) {
        return o.name == aisleClick
      })[0]
    }
  },
}

export default {
  state: JSON.parse(JSON.stringify(initialState)),
  getters,
  actions,
  mutations,
}

// Helpers
function getPrintFile(mealPlan, userWeeklyPlanId, isAisle) {
  return isAisle
    ? apiGroceryList.printFileByAisle(mealPlan, userWeeklyPlanId)
    : apiGroceryList.printFileByRecipe(mealPlan, userWeeklyPlanId)
}
function downloadPdf(res, filename) {
  let blob = new Blob([res], { type: 'application/pdf' })
  let fileUrl = window.URL.createObjectURL(blob)
  if (typeof window.chrome !== 'undefined') {
    // Chrome version
    let iframe = document.createElement('iframe')
    document.body.appendChild(iframe)
    iframe.style.display = 'none'
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus()
        iframe.contentWindow.print()
      }, 1)
    }
    iframe.src = fileUrl
  } else if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE version
    window.navigator.msSaveBlob(blob, filename)
  } else {
    let a = document.createElement('a')
    a.style.display = 'none'
    a.href = fileUrl
    a.download = filename
    document.body.appendChild(a)
    a.click()
    setTimeout(function () {
      document.body.removeChild(a)
      window.URL.revokeObjectURL(a.href)
    }, 1000)
  }
}
function getCompleteIngredientQuantities(minimumUnit, quantities, isCustom) {
  let quantitiesString = ''
  if (!quantities) return quantitiesString
  let first = true
  if (minimumUnit > 0) {
    quantities.forEach(function (i, index, qty) {
      let quantity = qty[index]
      qty = quantity.quantity
      if (qty > 0) {
        if (!first) quantitiesString += ' + '
        first = false
        let finalQty = toFraction(qty)
        let fraction = qty - Math.floor(qty)
        if (minimumUnit == 1.0) {
          finalQty = Math.ceil(qty)
        } else if (
          fraction > 0 &&
          fraction < minimumUnit &&
          minimumUnit > 0 &&
          minimumUnit < 1
        ) {
          finalQty = toFraction(Math.floor(qty) + minimumUnit)
        } else if (
          fraction > minimumUnit &&
          minimumUnit > 0 &&
          minimumUnit < 1
        ) {
          finalQty = Math.ceil(qty)
        }

        quantitiesString += '' + finalQty
        if (quantity.unit) {
          quantitiesString += ' ' + quantity.unit
        }
      }
    })
  } else {
    quantities.forEach(function (i, index, qty) {
      let quantity = qty[index]
      if (quantity.quantity > 0 && quantity.quantity % 1 == 0) {
        if (!first) quantitiesString += ' + '
        quantitiesString += '' + quantity.quantity
        first = false
      } else if (quantity.quantity > 0) {
        if (!first) quantitiesString += ' + '
        quantitiesString += toFraction(quantity.quantity)
        first = false
      } else {
        if (!isCustom && quantity.availableQuantityId) {
          quantitiesString += '0'
        }
      }
      if (quantity.unit) {
        if (quantity.availableQuantityUnitName && quantity.quantity === 0) {
          quantitiesString += ' ' + quantity.availableQuantityUnitName
        } else {
          quantitiesString += ' ' + quantity.unit
        }
      }
    })
  }
  return quantitiesString
}

function getCompleteIngredientName(ingredient = {}, quantities = [], unit) {
  let quantity = quantities.length
    ? _.last(quantities).quantity
    : ingredient.quantity
  let name =
    quantity > 1 || !!unit
      ? ingredient.pluralName
      : quantity == 0
      ? ingredient.pluralName
      : ingredient.singularName
  return [unit, ingredient.attribute, name].filter((i) => i).join(' ') // filter will remove undefined items
}

function getRecipeUrl(recipe) {
  return (
    '/recipe/' +
    recipe.id +
    '/' +
    recipe.title.replace(/\s+/g, '-').toLowerCase()
  )
}

function updateIngredients(element) {
  if (element.id) {
    element.url = getRecipeUrl(element)
  }
  element.ingredients.map(function (ingredient) {
    ingredient.completeIngredientName = getCompleteIngredientName(
      ingredient,
      ingredient.quantities,
      ingredient.unit
    )
    ingredient.completeQuantities = getCompleteIngredientQuantities(
      0,
      ingredient.quantities,
      ingredient.isCustom
    )
    if (ingredient.recipeHints) {
      ingredient.recipeHints.forEach(function (hint) {
        // don't round hints quantities
        let minimumUnit = 0
        hint.url = getRecipeUrl(hint)
        hint.completeQuantities = getCompleteIngredientQuantities(
          minimumUnit,
          hint.quantities
        )
        hint.completeIngredientName = getCompleteIngredientName(
          hint,
          hint.quantities,
          ingredient.unit
        )
        if (hint.seeTip) {
          ingredient.seeTip = true
        }
      })
    }
    ingredient.completeActualQuantity = ''
    ingredient.completeActualName = ''
    ingredient.completeOptionalQuantity = ''
    if (ingredient.quantityAct) {
      if (Array.isArray(ingredient.quantityAct)) {
        ingredient.completeActualQuantity = getCompleteIngredientQuantities(
          0,
          ingredient.quantityAct
        )
        ingredient.completeActualName = getCompleteIngredientName(
          ingredient,
          ingredient.quantityAct,
          ingredient.unit
        )
      } else {
        ingredient.completeActualQuantity = toFraction(ingredient.quantityAct)
        ingredient.completeActualQuantity += ingredient.quantities[0].unit
          ? ' ' + ingredient.quantities[0].unit
          : ''
        ingredient.completeActualName =
          ingredient.quantityAct > 1
            ? ingredient.pluralName
            : ingredient.singularName
      }
    }
    if (ingredient.quantityOptional) {
      ingredient.optional = false
      if (Array.isArray(ingredient.quantityOptional)) {
        if (ingredient.quantityOptional[0].quantity > 0) {
          ingredient.optional = true
          ingredient.completeOptionalQuantity = getCompleteIngredientQuantities(
            0,
            ingredient.quantityOptional
          )
        }
      } else {
        if (ingredient.quantityOptional > 0) {
          ingredient.optional = true
          ingredient.completeOptionalQuantity = toFraction(
            ingredient.quantityOptional
          )
          ingredient.completeOptionalQuantity += ingredient.quantities[0].unit
            ? ' ' + ingredient.quantities[0].unit
            : ''
        }
      }
      ingredient.completeOptionalQuantity =
        ingredient.completeOptionalQuantity.trim()
    }
    ingredient.quantities.forEach(function (qty) {
      if (qty.availableQuantity) {
        qty.availableQuantityComplete = toFraction(qty.availableQuantity)
        qty.availableQuantityComplete += ' '
        qty.availableQuantityComplete +=
          qty.availableQuantity > 1
            ? qty.availableQuantityUnitPluralName || qty.unit
            : qty.availableQuantityUnitName || qty.unit
        qty.availableQuantityComplete = qty.availableQuantityComplete.trim()
      }
    })
    return ingredient
  })
}
