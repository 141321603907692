<template>
  <div class="horizontal-divider my-3 my-md-4">{{ text }}</div>
</template>

<script>
export default {
  name: 'MPHorizontalDivider',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.horizontal-divider {
  color: $clr-navy-50;
  display: inline-block;
  font-size: $font-size-small;
  position: relative;
  text-align: center;
  width: 100%;

  &:before,
  &:after {
    background-color: $clr-navy-15;
    content: '';
    display: inline-block;
    position: absolute;
    top: 8px;
    height: 1px;
    width: 42%;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}
</style>
