<template>
  <MPModal
    id="change-password-modal"
    :show.sync="showModal"
    :show-x="true"
    contentPaddingClass="p-3"
  >
    <form class="text-center">
      <h4>Change Password</h4>
      <b-alert
        :show="hasErrors"
        v-text="errorMessage"
        variant="danger"
        class="alert-box"
      ></b-alert>
      <MPMaterialInput
        class="mt-3"
        type="password"
        label="Enter old password"
        v-model="formOldPassword"
      />
      <MPMaterialInput
        class="mt-3"
        type="password"
        label="Enter new password"
        v-model="formNewPassword"
      />
      <MPButton
        class="mt-3"
        color="primary"
        size="large"
        @click="changePassword"
        :disabled="!formOldPassword || !formNewPassword"
      >
        Change Password
      </MPButton>
    </form>
  </MPModal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formOldPassword: null,
      formNewPassword: null,
      errorMessage: null,
    }
  },
  computed: {
    ...mapGetters({
      email: 'user/email',
    }),
    showModal: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      },
    },
    hasErrors() {
      return this.errorMessage ? true : false
    },
  },
  methods: {
    changePassword() {
      this.$store
        .dispatch('user/changePassword', {
          oldPassword: this.formOldPassword,
          newPassword: this.formNewPassword,
        })
        .then(() => {
          this.$analytics.track('mp changed password', {
            email: this.email,
          })
          this.showModal = false
        })
        .catch((error) => {
          this.errorMessage = error.response.data.detail
        })
    },
  },
  watch: {
    showModal() {
      this.formOldPassword = null
      this.formNewPassword = null
      this.errorMessage = null
    },
  },
}
</script>
<style lang="scss">
#change-password-modal {
  .modal-content {
    @include media-breakpoint-up(md) {
      left: 52px;
    }
    @include media-breakpoint-up(lg) {
      left: 156px;
    }
  }
  .mp-modal-content {
    padding: 2rem 3.325rem 3rem 3.325rem;
  }
  .x-icon {
    right: 10px;
    top: 5px;
  }
  .alert-box {
    font-size: 13px;
    color: $clr-navy-50;
    font-weight: 400;
    border-color: $clr-navy-15;
    padding: 10px;
    margin-top: 1rem;
  }
}
</style>
