<template>
  <div id="onboarding-tutorial">
    <div>
      <div
        v-html="`<style>.navbar-container {filter: blur(4px);}</style>`"
      ></div>
      <div
        class="background-wrapper"
        :style="{ 'background-image': `url('${backgroundImage}')` }"
      ></div>
    </div>
    <MPOverlay :show="true" type="dark" class="onboarding-overlay" />
    <div
      class="container-wrapper"
      :class="`container-wrapper--${currentStepIndex}`"
    >
      <div class="container" :class="`container--${currentStepIndex}`">
        <SkipTutorial
          class="skip-tutorial"
          @skip-tutorial="skipTutorial"
          v-if="currentStepIndex !== 5"
        />
        <MPStepsDots
          class="steps-dots"
          :steps="steps"
          :current-step-index="currentStepIndex"
          @on-step-select="onStepChanged"
          v-if="currentStepIndex > 0 && currentStepIndex < 5"
        />
        <div class="text-center row">
          <div class="col">
            <MPAlert
              class="onboarding-alert"
              v-if="currentStepIndex > 0 && currentStepIndex < 5"
            >
              <h3 class="onboarding-alert-title" v-html="alert.title"></h3>
              <span v-html="alert.description"></span>
            </MPAlert>
          </div>
        </div>
        <div class="footer-wrapper">
          <div
            class="footer"
            v-if="currentStepIndex > 0 && currentStepIndex < 5"
          >
            <ButtonNext :color="'grey-orange'" @click="next" />
          </div>
        </div>
        <div class="step-1" v-if="!currentStepIndex">
          <h1>Welcome to the Meal Planner!</h1>
          <h5 class="text-center mt-3">
            Here's a quick guide showing you how<br />
            to make the most of the service.
          </h5>
          <ButtonNext class="next-button mt-4" @click="startTutorial" />
        </div>
        <DashboardPlan
          class="w-100 dashboard-plan"
          v-if="[1, 3, 4].indexOf(currentStepIndex) > -1"
          :plan="dashboardPlan"
          :max-lists="maxLists"
          @onSwapOutItemClick="onSwapOutItemClick"
          @onRemoveItemClick="onRemoveItemClick"
          @onMoveTo="onMoveTo"
        />
        <div class="row recipe-box-container" v-if="currentStepIndex === 2">
          <div
            class="col-md-4 recipe-box-col"
            v-for="(recipeCard, $index) in recipeCards"
            :key="$index"
          >
            <RecipeCard
              @onCardClick="() => addRecipe(recipeCard.id)"
              @addRecipe="addRecipe"
              @onFavoriteToggleClick="addRecipe"
              class="recipe-card"
              :data="recipeCard"
            />
            <div class="recipe-card-thumbnail-wrapper">
              <div class="recipe-card-thumbnail"></div>
            </div>
            <div class="recipe-card-popover-box-group" v-if="$index === 0">
              <MPPopoverBox
                @click="() => addRecipe(recipeCard.id)"
                :offset="16"
                position="bottom-right"
                class="recipe-card-popover-box d-md-none"
              >
                {{ isMobile().any ? 'Tap' : 'Click' }} here to swap<br />
                'BBQ Sweet Potato Tacos' into your plan
              </MPPopoverBox>
              <MPPopoverBox
                @click="() => addRecipe(recipeCard.id)"
                :offset="16"
                position="center-bottom"
                class="recipe-card-popover-box d-none d-md-block"
              >
                {{ isMobile().any ? 'Tap' : 'Click' }} here to swap<br />
                'BBQ Sweet Potato Tacos' into your plan
              </MPPopoverBox>
            </div>
          </div>
        </div>
        <div class="step-5" v-if="currentStepIndex === 5">
          <span class="step-5-title">Congratulations!</span>
          <span class="step-5-subtitle"> You have the basics down. </span>
          <img
            class="step-5-img"
            :src="
              require('@/assets/images/onboarding-tutorial/whisk-animation-white-small.gif')
            "
          />
          <span class="step-5-description"> Loading your meal plan... </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonNext from '@/components/credentials/register/ButtonNext'
import SkipTutorial from '@/components/onboarding/SkipTutorial'
import DashboardPlan from '@/components/dashboard/DashboardPlan'
import DashboardBuilder from '@/components/dashboard/DashboardBuilder'
import listItems from '@/components/recipebox/listItemsBuilder'
import RecipeCard from '@/components/recipebox/RecipeCard'
import {
  getDashboardItem,
  getRecipes,
  getRecipeBoxItems,
} from '@/components/onboarding/OnboardingData'
import camelizeKeys from '@/api/camelizeKeys'
import DetectDevice from '@/utils/DetectDevice'
import DetectDeviceMixin from '@/mixins/DetectDeviceMixin'

const stepsTrackEventsNames = [
  'mp onboarding tutorial load',
  'mp onboarding tutorial start',
  ...[1, 2, 3, 4].map((step) => `mp onboarding tutorial step ${step}`),
]

export default {
  components: { ButtonNext, SkipTutorial, DashboardPlan, RecipeCard },
  mixins: [DetectDeviceMixin],
  data() {
    return {
      currentStepIndex: 0,
      lastStep: 0,
      dashboardPlan: [],
      maxLists: 1,
      recipeCards: getRecipeBoxItems().map((item) =>
        listItems.getRecipeCard(item, true, 'recipe-')
      ),
      swapRecipeId: 101,
    }
  },
  computed: {
    ...mapGetters({
      backgroundImage: 'dashboard/backgroundImage',
      promotedRecipesNumber: 'globals/promotedRecipesNumber',
    }),
    steps() {
      return [{ hide: true }, {}, {}, {}, {}].map((item, index) => ({
        ...item,
        done: index <= this.lastStep,
      }))
    },
    alert() {
      switch (this.currentStepIndex) {
        case 1:
          return {
            title: 'Your meal plans are fully customizable.',
            description:
              'It’s easy to swap out a recipe, try it for yourself, below.',
          }
        case 2:
          return {
            title: 'Now pick a recipe to swap.',
            description: `There are over
            ${this.promotedRecipesNumber} recipes, with more added weekly.`,
          }
        case 3:
          return {
            title: 'Rearrange recipes with drag and drop',
            description: 'It’s easy to organize your meals to suit your needs.',
          }
        case 4:
          return {
            title: `Remove recipes in just a ${
              this.isMobile().any ? 'tap' : 'click'
            }!`,
            description:
              'Quickly adjust the meals you’re planning for the week.',
          }
        default:
          return {}
      }
    },
  },
  methods: {
    next() {
      this.currentStepIndex++
      this.lastStep = this.currentStepIndex
    },
    onStepChanged(stepIndex) {
      if (this.steps[stepIndex].done) {
        this.currentStepIndex = stepIndex
      }
    },
    skipTutorial() {
      this.$analytics.track('mp onboarding tutorial skip')
      this.$router.replace('/plan')
    },
    startTutorial() {
      this.next()
    },
    onSwapOutItemClick() {
      this.next()
    },
    addRecipe(recipeId) {
      this.swapRecipeId = recipeId
      this.next()
    },
    onRemoveItemClick() {
      this.next()
    },
    onMoveTo() {
      this.next()
    },
    onComplete() {
      this.$analytics.track('mp onboarding tutorial complete')
      this.$router.replace('/plan')
    },
    startOnCompleteTimeout() {
      setTimeout(() => {
        this.onComplete()
      }, 3000)
    },
    finishTooltipOnboarding() {
      this.$store.dispatch('user/finishAllTours')
    },
  },
  mounted() {
    this.finishTooltipOnboarding()
    this.$analytics.track('mp onboarding tutorial load')
  },
  watch: {
    currentStepIndex: {
      handler: function (currentStepIndex) {
        if (Number.isInteger(currentStepIndex)) {
          this.$analytics.track(stepsTrackEventsNames[currentStepIndex])
        }

        if (currentStepIndex === 1) {
          this.dashboardPlan = getDashboardPlanSwap()
        } else if (currentStepIndex === 3) {
          this.dashboardPlan = getDashboardPlanDrag(this.swapRecipeId)
        } else if (currentStepIndex === 4) {
          this.dashboardPlan = getDashboardPlanRemove(
            this.dashboardPlan,
            this.swapRecipeId
          )
        } else if (currentStepIndex === 5) {
          this.startOnCompleteTimeout()
        }
      },
      immediate: true,
    },
  },
}

function getDashboardPlanSwap() {
  let dashboardItem = DashboardBuilder.toDashboardListItem({
    recipe: camelizeKeys(getDashboardItem()),
  })

  dashboardItem.optionsDescription = `${
    DetectDevice.isMobile().any ? 'Tap' : 'Click'
  } swap out a recipe`
  dashboardItem.swapOutDescription = `Now choose ‘Swap Out’`
  dashboardItem.isDraggable = false
  dashboardItem.isRemoveDisabled = true

  return [
    {
      items: [
        dashboardItem,
        ...[0, 1].map(DashboardBuilder.getDashboardExampleListItem),
      ],
      actionStatus: 'hidden',
    },
    {
      items: [0, 1, 2, 3, 4].map(DashboardBuilder.getDashboardExampleListItem),
      actionStatus: 'hidden',
    },
  ]
}

function getDashboardPlanDrag(swapRecipeId) {
  let dashboardItem = DashboardBuilder.toDashboardListItem({
    id: 'MOVE_ITEM',
    recipe: camelizeKeys(getRecipes().find((r) => r.id === swapRecipeId)),
  })

  dashboardItem.popoverDescription = DetectDevice.isMobile().any
    ? `Tap and hold, then drag and drop to any position below`
    : `Click here and drag and drop<br /> to any position below`
  dashboardItem.optionsDisabled = true

  return [
    {
      items: [
        dashboardItem,
        ...[0, 1].map(() => {
          const item = DashboardBuilder.getDashboardExampleListItem()
          item.isDraggable = true
          return item
        }),
      ],
      actionStatus: 'hidden',
    },
    {
      items: [0, 1, 2, 3, 4].map(() => {
        const item = DashboardBuilder.getDashboardExampleListItem()
        item.isDraggable = true
        return item
      }),
      actionStatus: 'hidden',
    },
  ]
}

function getDashboardPlanRemove(plan, swapRecipeId) {
  let dashboardItem = DashboardBuilder.toDashboardListItem({
    recipe: camelizeKeys(getRecipes().find((r) => r.id === swapRecipeId)),
  })

  dashboardItem.optionsDescriptionPosition = 'top-right'
  dashboardItem.optionsDescription = `${
    DetectDevice.isMobile().any ? 'Tap' : 'Click'
  } here to<br /> remove a recipe`
  dashboardItem.removeDescription = `Now choose ‘Remove’`
  dashboardItem.popoverDescription = null
  dashboardItem.isSwapOutDisabled = true
  dashboardItem.isDraggable = false

  const newPlan = plan.map((day) => {
    return {
      items: day.items.map((item) => {
        if (item.componentName === 'dashboard-plan-item') {
          return dashboardItem
        } else {
          item.isDraggable = false
          return item
        }
      }),
      actionStatus: 'hidden',
    }
  })

  if (newPlan[0].items[0].recipeId === swapRecipeId) {
    arrayMove(newPlan[0].items, 0, 1)
  }

  return newPlan
}

function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr // for testing
}
</script>

<style lang="scss" scoped>
$dashboardWidth: 666px;

#onboarding-tutorial {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  min-height: 100vh;
  @include media-breakpoint-up(md) {
    background-color: transparent;
  }
}

.onboarding-overlay {
  z-index: 15;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

$blurPX: 8px;
.background-wrapper {
  z-index: -1;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  filter: blur($blurPX);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  transform: scale(1.2);
}

.skip-tutorial {
  position: absolute;
  z-index: 1;
  right: 0;
  padding: 8px 15px;
  @include media-breakpoint-up(md) {
    padding: 15px;
  }
}

.steps-dots {
  margin: 12.5px;
  @include media-breakpoint-up(md) {
    margin: 19.5px;
  }
}

.onboarding-alert {
  margin: 10px 15px 0;
  display: block;
  @include media-breakpoint-up(md) {
    margin: 10px auto 0;
    max-width: $dashboardWidth - 100px;
  }
  & ::v-deep .alert {
    color: #414a4d;
    background-color: rgba($orange, 0.1);
    border: none;
  }
}

.onboarding-alert-title {
  font-weight: 500;
}

.next-button {
  max-width: 440px;
}

.onboarding-content {
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
}

.recipe-card-thumbnail-wrapper {
  position: relative;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.recipe-card-thumbnail {
  position: absolute;
  width: 100%;
  height: 220px;
  border-radius: 4px;
  background-color: #f1f1f3;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff);
}

.recipe-box-col:first-child {
  .recipe-card {
    @include media-breakpoint-up(md) {
      border: solid 2px $green;
    }
  }
}

.recipe-card-popover-box-group {
  text-align: center;
  position: relative;
  z-index: 1;
  top: -16px;
  @include media-breakpoint-up(md) {
    top: -25px;
  }
}

.recipe-card-popover-box {
  padding: 1rem;
  width: 250px;
}

.container-wrapper {
  display: flex;
  min-height: 100vh;
  @include media-breakpoint-up(md) {
    padding: 0 15px;
    min-height: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 20px;
    z-index: 16;
  }
  &--2 {
    @include media-breakpoint-up(md) {
      position: fixed;
    }
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $white;
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  @include media-breakpoint-up(md) {
    padding: 0 35px;
    height: auto;
    max-width: $dashboardWidth;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &--0 {
    padding: 0 15px;
    background-image: none,
      url('~@/assets/images/onboarding-tutorial/group.png');
    background-size: 250px, 220px;
    background-repeat: no-repeat;
    background-position: left top, bottom right;
    @include media-breakpoint-up(md) {
      background-image: url('~@/assets/images/onboarding-tutorial/bowls.png'),
        url('~@/assets/images/onboarding-tutorial/group.png');
    }
  }
  &--2 {
    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: 835px;
      height: 100%;
    }
  }
}

.step-1 {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    min-height: 60vh;
  }
}

.step-5 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include media-breakpoint-up(md) {
    min-height: 70vh;
  }
}

.step-5-title {
  color: $green;
  font-size: rem(23);
  font-weight: 500;
  @include media-breakpoint-up(md) {
    font-size: rem(44);
  }
}

.step-5-subtitle {
  font-weight: 500;
  font-size: rem(16);
  @include media-breakpoint-up(md) {
    font-size: rem(24);
  }
}

.step-5-img {
  margin-top: 50px;
  width: 104px;
  @include media-breakpoint-up(md) {
    margin-top: 90px;
  }
}

.step-5-description {
  display: block;
  text-align: center;
  color: $grey;
  margin-top: 20px;
  font-size: rem(14);
}

.recipe-box-container {
  margin: 4px 0 0;
}

.recipe-box-col {
  border-bottom: solid 1px #e5e6e7;
  @include media-breakpoint-up(md) {
    border-bottom: none;
  }
}

.footer-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
}

.footer {
  padding: 0 15px 15px;
  margin: 0 auto;
  bottom: 0;
  width: 100%;
  max-width: $dashboardWidth;
  text-align: center;
  @include media-breakpoint-up(md) {
    padding: 0 50px 15px;
  }
}
</style>
