<template>
  <RecipeBase @close="closeRecipe">
    <div slot="header">
      <RecipeHeader>
        <div class="d-md-none container">
          <RecipeTopSection :isLoading="true" />
        </div>
      </RecipeHeader>
    </div>
    <div slot="left-content">
      <div class="d-none d-md-flex">
        <RecipeTopSection :isLoading="true" />
      </div>
      <RecipeLoadingItem
        class="content-loading-item"
        v-for="num in [0, 1, 2, 3, 4, 5, 6]"
        :key="num"
      />
    </div>
    <div class="d-none d-md-block" slot="right-content">
      <RecipeSideCard>
        <RecipeSubtitle>
          <RecipeLoadingItem class="subtitle-loading-item" />
        </RecipeSubtitle>
        <RecipeLoadingItem v-for="num in [0, 1, 2, 3, 4]" :key="num" />
      </RecipeSideCard>
    </div>
  </RecipeBase>
</template>

<script>
import RecipeHeader from './RecipeHeader'
import RecipeTopSection from './RecipeTopSection'
import RecipeSubtitle from './RecipeSubtitle'
import RecipeLoadingItem from './RecipeLoadingItem'

import RecipeBase from './RecipeBase'
import RecipeSideCard from './RecipeSideCard'
import RouterMixin from '@/mixins/RouterMixin'

export default {
  components: {
    RecipeHeader,
    RecipeTopSection,
    RecipeSubtitle,
    RecipeLoadingItem,
    RecipeBase,
    RecipeSideCard,
  },
  mixins: [RouterMixin],
  methods: {
    closeRecipe() {
      this.goBack()
    },
  },
}
</script>

<style lang="scss" scoped>
.content-loading-item:nth-child(even) {
  width: 80%;
}

.subtitle-loading-item {
  height: 40px;
}
.recipe-right-content {
  .subtitle-loading-item,
  .loading-item:nth-child(2) {
    width: 75%;
  }
}
</style>
