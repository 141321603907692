import * as types from '../mutation-types'
import api from '@/api/AddCustomRecipe'
import _ from 'lodash'
import Vue from 'vue'

const initialState = {
  userGeneratedRecipe: null,
  formData: null,
  recipeIngredientList: null,
  editMode: true,
  loading: false,
  showModal: false,
  showConfirmModal: false,
  dataChanged: false,
  step: 0,
  deletedRecipeIngredients: null,
  message: null,
}

const getters = {
  message: (state) => state.message,
  editMode: (state) => state.editMode,
}

const actions = {
  initAddCustomRecipeData({ dispatch, commit }, payload) {
    commit(types.CLEAR_ADD_CUSTOM_RECIPE)
    commit(types.SET_EDIT_MODE, false)
    if (payload && 'userCustomRecipeId' in payload) {
      commit(types.SET_STEP, payload.step ? payload.step : 0)
      return dispatch('getCustomRecipe', payload.userCustomRecipeId)
    }
    return Promise.resolve()
  },
  setCustomRecipeStrategy({ dispatch, state }) {
    if (state.dataChanged) {
      if (state.userGeneratedRecipe && state.userGeneratedRecipe.id) {
        return dispatch('updateCustomRecipe', state.formData)
      }
      return dispatch('addCustomRecipe', state.formData)
    }
    return Promise.resolve()
  },
  async syncRecipeIngredients({ dispatch, state, commit }) {
    if (state.dataChanged) {
      const recipeIngredients = state.recipeIngredientList
      const deletedRecipeIngredients = state.deletedRecipeIngredients
      commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, true)
      if (recipeIngredients) {
        for (let index = 0; index < recipeIngredients.length; index++) {
          const ingredient = recipeIngredients[index]
          Object.assign(ingredient, { order: index })
          await dispatch('setRecipeIngredientStrategy', ingredient)
        }
      }
      if (deletedRecipeIngredients) {
        for (let index = 0; index < deletedRecipeIngredients.length; index++) {
          const ingredient = deletedRecipeIngredients[index]
          await dispatch('removeRecipeIngredient', ingredient)
        }
      }
      commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
    }
    return Promise.resolve()
  },
  async setRecipeIngredientStrategy({ dispatch }, ingredient) {
    if (ingredient.ingredient) {
      if (typeof ingredient.ingredient == 'string') {
        const res = await dispatch(
          'addUserCustomIngredient',
          ingredient.ingredient
        )
        Object.assign(ingredient, { ingredient: res })
        await dispatch('recipe/getIngredientList', null, { root: true })
      }
      if (typeof ingredient.prepDescription == 'string') {
        const res = await dispatch(
          'addUserCustomPrepDescription',
          ingredient.prepDescription
        )
        Object.assign(ingredient, { prepDescription: res })
        await dispatch('recipe/getPrepDescriptionList', null, { root: true })
      }
      if (typeof ingredient.unit == 'string') {
        const res = await dispatch('addUserCustomUnit', ingredient.unit)
        Object.assign(ingredient, { unit: res })
        await dispatch('recipe/getUnitList', null, { root: true })
      }
      if ('id' in ingredient) {
        await dispatch('editRecipeIngredient', ingredient)
      } else {
        if ('ingredient' in ingredient) {
          await dispatch('addRecipeIngredient', ingredient)
        }
      }
    }
  },
  getCustomRecipe({ commit }, userCustomRecipeId) {
    commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, true)
    return api
      .getCustomRecipe(userCustomRecipeId)
      .then((res) => {
        commit(types.UPDATE_USER_GENERATED_RECIPE, res)
        commit(types.UPDATE_FORM_DATA, {
          userCustomRecipeId: res.id,
          recipeTitle: res.title,
          recipePrepTime: res.defaultPrepTime,
          recipeServing: res.numberOfServings2,
          category: res.category,
          recipeImage: res.image,
          recipePrepDescription: res.content,
          batchRecipe: res.isBatchRecipe,
        })
        commit(types.SET_RECIPE_INGREDIENT_LIST_FROM_BE, res.ingredients)
        commit(types.SET_EDIT_MODE, true)
        commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
        commit(types.SET_DATA_CHANGED, false)
        return Promise.resolve(res)
      })
      .catch((err) => {
        commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
        return Promise.reject(err)
      })
  },
  addCustomRecipe({ commit, dispatch }, payload) {
    commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, true)
    return api
      .addCustomRecipe(payload)
      .then((res) => {
        commit(types.UPDATE_FORM_DATA, { userCustomRecipeId: res.id })
        commit(types.UPDATE_USER_GENERATED_RECIPE, res)
        commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
        dispatch('RecipeBox/refresh', null, { root: true })
        return Promise.resolve(res)
      })
      .catch((err) => {
        commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
        return Promise.reject(err)
      })
  },
  updateCustomRecipe({ commit }, payload) {
    commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, true)
    return api
      .updateCustomRecipe(payload, payload.userCustomRecipeId)
      .then((res) => {
        commit(types.UPDATE_USER_GENERATED_RECIPE, res)
        commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
        return Promise.resolve(res)
      })
      .catch((err) => {
        commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
        return Promise.reject(err)
      })
  },
  deleteCustomRecipe({ commit }, userCustomRecipeId) {
    commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, true)
    return api
      .removeCustomRecipe(userCustomRecipeId)
      .then((res) => {
        commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
        return Promise.resolve(res)
      })
      .catch((err) => {
        commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
        return Promise.reject(err)
      })
  },
  addRecipeIngredient({ state, commit }, recipeIngredient) {
    const payload = {
      userCustomRecipeId: state.userGeneratedRecipe.id,
      ingredient: _.get(recipeIngredient, 'ingredient.id', null),
      unit: _.get(recipeIngredient, 'unit.id', null),
      quantity: recipeIngredient.quantity,
      prepDescription: _.get(recipeIngredient, 'prepDescription.id', null),
      order: recipeIngredient.order,
      isMetric: _.get(recipeIngredient, 'isMetric', false),
    }
    return api
      .addRecipeIngredient(payload)
      .then((res) => {
        commit(types.UPDATE_USER_GENERATED_RECIPE, res)
        commit(types.SET_RECIPE_INGREDIENT_LIST_FROM_BE, res.ingredients)
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  editRecipeIngredient({ commit }, recipeIngredient) {
    const payload = {
      id: recipeIngredient.id,
      ingredient: _.get(recipeIngredient, 'ingredient.id', null),
      unit: _.get(recipeIngredient, 'unit.id', null),
      quantity: recipeIngredient.quantity,
      prepDescription: _.get(recipeIngredient, 'prepDescription.id', null),
      order: recipeIngredient.order,
      isMetric: _.get(recipeIngredient, 'isMetric', false),
    }
    return api
      .editRecipeIngredient(payload)
      .then((res) => {
        commit(types.UPDATE_USER_GENERATED_RECIPE, res)
        commit(types.SET_RECIPE_INGREDIENT_LIST_FROM_BE, res.ingredients)
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  removeRecipeIngredient({ commit }, { id }) {
    commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, true)
    return api
      .deleteRecipeIngredient(id)
      .then((res) => {
        commit(types.UPDATE_USER_GENERATED_RECIPE, res)
        commit(types.SET_RECIPE_INGREDIENT_LIST_FROM_BE, res.ingredients)
        commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
        return Promise.resolve(res)
      })
      .catch((err) => {
        commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
        return Promise.reject(err)
      })
  },
  reorderCustomRecipeIngredients({ commit }, payload) {
    commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, true)
    return api
      .reorderCustomRecipeIngredients(payload)
      .then((res) => {
        commit(types.UPDATE_USER_GENERATED_RECIPE, res)
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  // eslint-disable-next-line
  addUserCustomIngredient({}, payload) {
    return api
      .addUserCustomIngredient(payload)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  // eslint-disable-next-line
  addUserCustomPrepDescription({}, payload) {
    return api
      .addUserCustomPrepDescription(payload)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  // eslint-disable-next-line
  addUserCustomUnit({}, payload) {
    return api
      .addUserCustomUnit(payload)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return Promise.reject(err)
      })
  },
  clearAddCustomRecipe({ commit }) {
    commit(types.CLEAR_ADD_CUSTOM_RECIPE)
  },
  addRecipeIngredientToArray({ commit }, recipeIngredient) {
    commit(types.ADD_RECIPE_INGREDIENT, recipeIngredient)
    commit(types.SET_DATA_CHANGED, true)
  },
  editRecipeIngredientInArray({ commit }, payload) {
    commit(types.EDIT_RECIPE_INGREDIENT, payload)
    commit(types.SET_DATA_CHANGED, true)
  },
  removeRecipeIngredientFromArray({ commit }, recipeIngredient) {
    commit(types.REMOVE_RECIPE_INGREDIENT, recipeIngredient)
    commit(types.SET_DATA_CHANGED, true)
  },
  addOrUpdateRecipeImage({ commit }, { image, recipeId }) {
    if (image === '' || (typeof image != 'string' && image)) {
      commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, true)
      return api
        .addOrUpdateRecipeImage(image, recipeId)
        .then((res) => {
          commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
          return Promise.resolve(res)
        })
        .catch((err) => {
          commit(types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING, false)
          return Promise.reject(err)
        })
    }
    return Promise.resolve()
  },
  setEditMode({ commit }, edit) {
    commit(types.SET_EDIT_MODE, edit)
  },
  setModalVisible({ commit }, isVisible) {
    commit(types.SET_MODAL_VISIBILITY, isVisible)
  },
  setConfirmModalVisible({ commit }, isVisible) {
    commit(types.SET_CONFIRM_MODAL_VISIBILITY, isVisible)
  },
  updateUserGeneratedRecipeData({ dispatch, state }) {
    if (state.userGeneratedRecipe && state.userGeneratedRecipe.id) {
      dispatch(
        'recipe/loadRecipe',
        { recipeId: state.userGeneratedRecipe.id },
        {
          root: true,
        }
      )
    }
    dispatch('RecipeBox/refresh', null, { root: true })
  },
}

const mutations = {
  [types.CLEAR_ADD_CUSTOM_RECIPE](state) {
    Object.assign(state, initialState)
  },
  [types.SET_ADD_CUSTOM_RECIPE_STATUS_LOADING](state, status) {
    state.loading = status
  },
  [types.UPDATE_FORM_DATA](state, payload) {
    state.dataChanged = true
    state.formData = Object.assign({}, state.formData, payload)
  },
  [types.UPDATE_USER_GENERATED_RECIPE](state, payload) {
    state.userGeneratedRecipe = Object.assign(
      {},
      state.userGeneratedRecipe,
      payload
    )
  },
  [types.SET_RECIPE_INGREDIENT_LIST_FROM_BE](state, recipeIngredientList) {
    state.recipeIngredientList = recipeIngredientList.map(
      (ingredient, index) => {
        let obj = {
          id: ingredient.id,
          order: ingredient.order,
          ingredient: ingredient.ingredient,
          unit: ingredient.unit2,
          quantity: ingredient.quantity2,
          prepDescription: ingredient.prepDescr,
          userCustomRecipeId: ingredient.recipe,
          index: index,
          isMetric: ingredient.useMetricUnit,
        }
        return obj
      }
    )
  },
  [types.SET_RECIPE_INGREDIENT_LIST](state, recipeIngredientList) {
    state.recipeIngredientList = recipeIngredientList
    state.dataChanged = true
  },
  [types.ADD_RECIPE_INGREDIENT](state, recipeIngredient) {
    state.recipeIngredientList = state.recipeIngredientList || []
    recipeIngredient = {
      ...recipeIngredient,
      index: findMaxIndex(state.recipeIngredientList) + 1,
    }
    state.recipeIngredientList.push(recipeIngredient)
  },
  [types.EDIT_RECIPE_INGREDIENT](state, recipeIngredient) {
    const index = _.findIndex(
      state.recipeIngredientList,
      (e) => e.index == recipeIngredient.index
    )
    Vue.set(state.recipeIngredientList, index, recipeIngredient)
  },
  [types.REMOVE_RECIPE_INGREDIENT](state, recipeIngredient) {
    const index = state.recipeIngredientList.indexOf(recipeIngredient)
    if (index != -1) {
      Vue.delete(state.recipeIngredientList, index)
      if ('id' in recipeIngredient) {
        state.deletedRecipeIngredients = state.deletedRecipeIngredients || []
        state.deletedRecipeIngredients.push(recipeIngredient)
      }
    }
  },
  [types.SET_EDIT_MODE](state, mode) {
    state.editMode = mode
  },
  [types.SET_MODAL_VISIBILITY](state, isVisible) {
    state.showModal = isVisible
  },
  [types.SET_CONFIRM_MODAL_VISIBILITY](state, isVisible) {
    state.showConfirmModal = isVisible
  },
  [types.SET_DATA_CHANGED](state, changed) {
    state.dataChanged = changed
  },
  [types.SET_STEP](state, step) {
    state.step = step
  },
  [types.SET_INFO_MESSAGE](state, message) {
    state.message = message
  },
}

//helpers
function findMaxIndex(recipeIngredientList) {
  let maxIndex = 0
  recipeIngredientList.forEach((recipeIngredient) => {
    if (maxIndex < recipeIngredient.index) {
      maxIndex = recipeIngredient.index
    }
  })
  return maxIndex
}
export default {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters,
  actions,
  mutations,
}
