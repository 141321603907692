import RegisterSelectIntolerancesForm from '@/components/credentials/register/RegisterSelectIntolerancesForm'
import RegisterSelectServingsForm from '@/components/credentials/register/RegisterSelectServingsForm'
import RegisterSelectAllMealsForm from '@/components/credentials/register/RegisterSelectAllMealsForm'

export default [
  {
    alias: '/', // this makes it the default route for /signup
    path: '1',
    index: 1,
    component: RegisterSelectServingsForm,
    done: false,
  },
  {
    path: '2',
    index: 2,
    component: RegisterSelectIntolerancesForm,
    done: false,
  },
  {
    path: '3',
    index: 3,
    component: RegisterSelectAllMealsForm,
    done: false,
  },
]
