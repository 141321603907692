<template>
  <div>
    <MPCloseIcon
      @click="onDismiss"
      :size="18"
      class="close-icon"
      aria-label="Close"
    />
    <RegisterCheckout v-if="!isLoading" @onCheckout="onCheckout" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import ServerError from '@/components/common/Notifications/ServerError.vue'
import MPCloseIcon from '@/components/common/MPCloseIcon.vue'
import apiCheckout from '@/api/checkout'
import RegisterCheckout from '@/components/credentials/register/RegisterCheckout.vue'
import ChargebeeMixin from '@/mixins/ChargebeeMixin'
import LoadingMixin from '@/mixins/LoadingMixin'
import NotificationsMixin from '@/mixins/NotificationsMixin'
import { EVENTS } from '@/events/analytics'
import environment from '@/environment'

export default {
  mixins: [ChargebeeMixin, LoadingMixin, NotificationsMixin],
  components: {
    RegisterCheckout,
    MPCloseIcon,
  },
  computed: {
    ...mapGetters({
      isLoading: 'globals/loading',
      selectedPlan: 'checkout/selectedPlan',
      subscriptionState: 'user/subscriptionState',
      billing: 'user/billing',
      generalPlan: 'globals/generalPlan',
    }),
  },
  methods: {
    ...mapActions({
      loadCheckout: 'checkout/loadCheckoutPage',
      updateUser: 'user/updateUserData',
      isMealPlannerLocked: 'globals/setIsMealPlannerLocked',
      mealPlanData: 'globals/setMealPlanData',
      refreshMealPlans: 'dashboard/refreshMealPlans',
      refreshDashboard: 'dashboard/refreshDashboard',
      linkWithSub: 'registration/linkUserWithSubscription',
    }),
    upgrade(data) {
      this.updateSubscription(data, {
        onSuccess: async (hostedPageId) => {
          this.setLoading(true)
          this.$posthog.sendEvent(EVENTS.subscription.reactivate, {
            'subscription plan id': this.selectedPlan.chargebeeFrequency,
            price: parseFloat(this.selectedPlan.price),
          })
          await this.linkWithSub(hostedPageId)
          await this.updateUser(true)
          await Promise.all([
            this.isMealPlannerLocked(false),
            this.mealPlanData({ mealPlan: 'current' }),
            this.refreshMealPlans({}),
            this.refreshDashboard({}),
          ])
          this.setLoading(false)
          this.$router.replace('/plan')
        },
        onError: () => {
          this.setLoading(false)
          this.createInfoNotification({
            text: 'something went wrong',
            closeAfter: 5,
            color: 'red',
            full: true,
          })
        },
      })
    },
    onCheckout() {
      if (this.billing.isGift) {
        this.setPaymentMethod()
      } else {
        this.reactivate()
      }
    },
    reactivate() {
      const chargeItems = []

      chargeItems.push({
        item_price_id: this.selectedPlan.chargebeeFrequency,
        quantity: 1,
      })

      this.selectedPlan.chargebeeAddons.forEach((addon) => {
        chargeItems.push({
          item_price_id: addon,
        })
      })

      this.selectedPlan.chargebeeCharges.forEach((charge) => {
        chargeItems.push({
          item_price_id: charge,
          charge_on_option: 'immediately',
        })
      })

      this.upgrade({
        plan_id: this.selectedPlan.id,
        subscription_items: chargeItems,
        coupon_ids: [...this.selectedPlan.chargebeeCoupon],
      })
    },
    setPaymentMethod() {
      this.changePaymentMethod({
        onSourceAdded: async () => {
          this.setLoading(true)
          try {
            await apiCheckout.createSubscriptionNoPortal({
              frequency: this.selectedPlan.chargebeeFrequency,
            })
            this.chargebee.logout()
            this.chargebee.closeAll()
            await this.updateUser(true)
            this.$router.replace('/plan')
            this.createInfoNotification({
              text: 'Plan upgraded sucessfully',
              closeAfter: 10,
              color: 'primary',
              full: true,
            })
          } catch (error) {
            this.errorNotification(ServerError, true)
            console.error(error)
          } finally {
            this.setLoading(false)
          }
        },
      })
    },
    onDismiss() {
      this.$router.back()
    },
  },
  async mounted() {
    try {
      this.setLoading(true)
      const tempPlanId = environment.tempPlanId

      if (tempPlanId) {
        await this.loadCheckout(tempPlanId)
      } else {
        await this.loadCheckout()
      }
    } catch (error) {
    } finally {
      this.setLoading(false)
    }
  },
  async mounted() {
    try {
      this.setLoading(true)
      const tempPlanId = environment.tempPlanId

      if (this.generalPlan) {
        await this.loadCheckout(this.generalPlan)
      } else if (tempPlanId) {
        await this.loadCheckout(tempPlanId)
      } else {
        await this.loadCheckout()
      }
    } catch (error) {
      this.$router.replace('/plan')
    } finally {
      this.setLoading(false)
    }
  },
}
</script>

<style lang="scss" scoped>
.close-icon {
  position: absolute;
  top: 30px;
  right: 15px;
  @include media-breakpoint-up(md) {
    top: 45px;
    right: 30px;
  }
}
</style>
