<template>
  <div class="mp-popover-box" :class="cssClass" v-on="$listeners">
    <div class="popover-box-arrow" :style="arrowStyle"></div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  props: {
    position: {
      type: String,
      default: 'bottom-right',
    },
    color: {
      type: String,
      default: 'red',
    },
    offset: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    cssClass() {
      return `mp-popover-box--${this.position} mp-popover-box--${this.color}`
    },
    arrowStyle() {
      let styles = {}
      switch (this.position) {
        case 'top-right':
        case 'bottom-right':
          styles.right = `${this.offset}px`
          break
        case 'bottom-left':
          styles.left = `${this.offset}px`
          break
        case 'center-left':
        default:
      }
      return styles
    },
  },
}
</script>

<style lang="scss" scoped>
.mp-popover-box {
  position: absolute;
  border-radius: 5px;
  &--top-right {
    margin-bottom: 7px;
    bottom: 100%;
    right: 0;
    .popover-box-arrow {
      right: 0;
      bottom: -5px;
    }
  }
  &--bottom-right {
    margin-top: 7px;
    top: 100%;
    right: 0;
    .popover-box-arrow {
      right: 0;
      top: -5px;
    }
  }
  &--bottom-left {
    margin-top: 7px;
    top: 100%;
    left: 0;
    .popover-box-arrow {
      left: 0;
      top: -5px;
    }
  }
  &--center-left {
    margin-right: 5px;
    top: 50%;
    transform: translate(0, -50%);
    right: 100%;
    .popover-box-arrow {
      top: 50%;
      transform: translate(0, -50%) rotate(45deg);
      right: -5px;
    }
  }
  &--center-bottom {
    margin-top: 7px;
    top: 100%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    .popover-box-arrow {
      left: 50%;
      transform: translate(-50%, 0) rotate(45deg);
      top: -5px;
    }
  }
  &--red {
    background-color: #fa6e6a;
    color: #fff;
  }
}

.popover-box-arrow {
  position: absolute;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: #fa6e6a;
}
</style>
