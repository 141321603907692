<template>
  <div class="credentials-header">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="center">
      <slot name="center"></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CredentialsHeader',
}
</script>

<style lang="scss" scoped>
.credentials-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: $signup-top-section-height;
  width: 100%;
  @include media-breakpoint-down(sm) {
    height: 4rem;
  }

  .left {
    align-self: center;
    display: flex;
    justify-content: flex-start;
    min-width: 30%;
    padding-left: 2rem;
    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
    }
  }
  .center {
    display: flex;
    justify-content: center;
    min-width: 40%;
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-self: center;
    padding-right: 2rem;
    padding-left: 2rem;
    min-width: 30%;
    @include media-breakpoint-down(sm) {
      padding-right: 20px;
      padding-left: 0;
    }
  }
}
</style>
