<template>
  <button
    v-on:click="onBtnClick"
    class="search-btn"
    :class="{ 'search-btn--with-banner': bannerVisible }"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      trialBannerShown: 'globals/trialBannerShown',
      showTrialBanner: 'dashboard/showTrialBanner',
      isDashboardLocked: 'dashboard/isDashboardLocked',
      campaign: 'globals/campaign',
      isFreeUser: 'user/isFree',
    }),
    bannerVisible() {
      return (
        this.showTrialBanner &&
        !this.isDashboardLocked &&
        !this.campaign &&
        this.isFreeUser
      )
    },
  },
  methods: {
    onBtnClick() {
      this.$emit('onClick')
    },
  },
}
</script>

<style lang="scss" scoped>
.search-btn {
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  position: fixed;
  bottom: 54px;
  right: 10px;
  z-index: 1;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  background-color: $clr-navy;
  background-image: url('~@/assets/icons/search-white.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 19px;
  padding: 0;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .search-btn--with-banner {
    bottom: 150px;
    left: 10px;
  }
}
</style>
