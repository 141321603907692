export default {
  props: {
    value: {
      type: [File, String, Blob],
    },
  },
  data() {
    return {
      imageBase: null,
      info: '',
    }
  },
  mounted() {
    if (this.value && typeof this.value != 'string') {
      this.getBase64(this.value)
    }
  },
  computed: {
    image() {
      return typeof this.value == 'string' ? this.value : this.imageBase
    },
  },
  methods: {
    onChange(e) {
      const file = e.target.files[0]
      this.handleImage(file)
    },
    createToBlobIfNotExist() {
      if (!HTMLCanvasElement.prototype.toBlob) {
        Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
          value: function (callback, type, quality) {
            var dataURL = this.toDataURL(type, quality).split(',')[1]
            setTimeout(function () {
              var binStr = atob(dataURL),
                len = binStr.length,
                arr = new Uint8Array(len)
              for (var i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i)
              }
              callback(new Blob([arr], { type: type || 'image/png' }))
            })
          },
        })
      }
    },
    resizeImage(file) {
      this.createToBlobIfNotExist()
      return new Promise((resolve, reject) => {
        const width = 1500
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (event) => {
          const img = new Image()
          img.src = event.target.result
          img.onload = () => {
            if (img.width > width) {
              const elem = document.createElement('canvas')
              const scaleFactor = width / img.width
              elem.width = width
              elem.height = img.height * scaleFactor
              const ctx = elem.getContext('2d')
              ctx.drawImage(img, 0, 0, width, img.height * scaleFactor)
              ctx.canvas.toBlob(
                (blob) => {
                  const newBlob = new Blob([blob], { type: 'image/jpeg' })
                  newBlob.name = file.name
                  resolve(newBlob)
                },
                'image/jpeg',
                0.95
              )
            } else {
              resolve(file)
            }
          }
        }
        reader.onerror = (error) => reject(error)
      })
    },
    handleImage(file) {
      if (file && file.type.indexOf('image') > -1) {
        this.resizeImage(file)
          .then((res) => {
            this.$emit('input', res)
            this.info = ''
            this.getBase64(res)
          })
          .catch(() => {
            this.info = 'There was a problem with image upload'
          })
      } else {
        if (file) {
          this.info = 'Please select image'
        }
      }
    },
    getBase64(file) {
      var reader = new FileReader()
      reader.onload = (e) => {
        this.imageBase = e.target.result
      }
      reader.readAsDataURL(file)
    },
    onDrop: function (e) {
      e.stopPropagation()
      e.preventDefault()
      let file = e.dataTransfer.files[0]
      this.handleImage(file)
    },
    deleteFile() {
      this.imageBase = null
      this.$emit('input', '')
    },
  },
}
