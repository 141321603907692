<template>
  <div class="add-post-text-area position-relative" @click="enableScrolling">
    <div class="d-flex">
      <textarea
        v-auto-resize
        v-model="text"
        class="disqus-custom-textarea"
        :placeholder="placeHolder"
        @focus="onFocus()"
        @blur="onBlur()"
        v-focus
        rows="1"
        @keydown="enableScrolling"
      />
    </div>
    <div class="post-options" v-if="areOptionsVisible">
      <button
        class="post-comment btn btn-primary m-0"
        @click="onActionClick()"
        v-text="actionText"
        :disabled="required && !text"
      ></button>
      <button class="btn btn-link m-0" @click="onCancel()">Cancel</button>
    </div>
    <i
      v-if="showIcon"
      class="edit-icon position-absolute"
      @click="onFocus()"
    ></i>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'PostEditor',
  props: {
    value: { type: String },
    placeHolder: { type: String, default: '' },
    showOptions: { type: Boolean, default: false },
    actionText: { type: String, required: true },
    showIcon: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    removeTextOnSubmit: { type: Boolean, default: false },
    allowAutoScroll: { type: Boolean, default: false },
  },
  data() {
    return {
      text: null,
      areOptionsVisible: false,
      canScroll: false,
    }
  },
  watch: {
    value: {
      handler: function (value) {
        this.text = value
      },
      immediate: true,
    },
    showOptions: {
      handler: function (value) {
        this.areOptionsVisible = value
      },
      immediate: true,
    },
  },
  methods: {
    enableScrolling() {
      this.canScroll = true
    },
    onFocus() {
      this.areOptionsVisible = true
    },
    onBlur: _.debounce(function () {
      if (this.text) {
        return
      }
      this.areOptionsVisible = false
      this.$emit('onCancelClick')
      this.text = this.value
      this.canScroll = false
    }, 500),
    onActionClick() {
      this.$emit('input', this.text)
      this.$emit('onActionClick')
      this.areOptionsVisible = false
      if (this.removeTextOnSubmit) {
        this.text = null
      }
    },
    onCancel() {
      this.text = this.value
      this.areOptionsVisible = false
      this.$emit('onCancelClick')
    },
  },
  directives: {
    autoResize: {
      inserted: function (el) {
        expandTextArea(el)
      },
      update: function (el, binding, vnode) {
        expandTextArea(el)
        if (vnode.context.allowAutoScroll && vnode.context.canScroll) {
          keepTextAreaInView(el)
        }
      },
    },
    focus: {
      inserted: function (el, binding, vnode) {
        if (vnode.context.areOptionsVisible) {
          el.focus()
        }
      },
    },
  },
}

// Expand/shrink textarea according to entered text
function expandTextArea(el) {
  const scrollHeight = el.scrollHeight
  el.style.height = '24px'
  if (scrollHeight) {
    el.style.height = el.scrollHeight + 'px'
  }
}
function keepTextAreaInView(el) {
  el.parentElement.parentElement.scrollIntoView(false)
  document.body.scrollTop = document.body.scrollTop + 100
  document.documentElement.scrollTop = document.documentElement.scrollTop + 100
}
</script>

<style lang="scss" scoped>
.edit-icon {
  background: url('~@/assets/icons/edit-note-normal.svg') no-repeat center,
    url('~@/assets/icons/edit-note-hover.svg') no-repeat center;
  background-size: 24px 24px, 0;
  right: 18px;
  top: 18px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.add-post-text-area {
  &:hover {
    .edit-icon {
      background-size: 0, 18px 18px;
    }
  }
}
.add-post-text-area {
  flex-grow: 1;
  border-radius: 5px;
  background-color: $white;
  border: solid 1px #d5d6d7;
  &:focus {
    cursor: default;
    border-color: $light-grey;
  }
}
.disqus-custom-textarea {
  border: none;
  margin: 15px 50px 15px 15px;
  vertical-align: middle;
  width: 100%;
  resize: none;
  letter-spacing: 0.4px;
  font-size: 16px;
  text-overflow: ellipsis;
  outline: none;
  font-weight: 400;
  overflow: hidden;
  &::placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $clr-navy-70;
    font-weight: 100;
  }
  &:-moz-placeholder {
    text-overflow: ellipsis;
  }
}
.post-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  font-weight: bold;
  color: $orange;
  font-size: 14px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-content: center;
  }
  .post-comment {
    width: 200px;
    outline: none;
    text-align: center;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      display: block;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  .cancel-comment {
    padding: 0;
    margin: 0 0 0 20px;
    border: none;
    background: none;
    outline: none;
    font-weight: bold;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      margin: 10px 0 0 0;
    }
  }
}
</style>
