import Vue from 'vue'
import VueI18n from 'vue-i18n'
import InfiniteScroll from 'vue-infinite-scroll'
import VueIntercom from 'vue-intercom'
import VueCookie from 'vue-cookie'
import * as Sentry from '@sentry/browser'
import * as SentryIntegrations from '@sentry/integrations'

import api from './api'
import { SET_ACCESS_TOKEN, SET_CAMPAIGN } from '@/store/mutation-types'

import App from './App.vue'
import router from './router'
import store from './store'
import messages from './messages'
import Stickyfill from '@/directives/Stickyfill'
import ToggleButton from 'vue-js-toggle-button'
import './styles/meal-planner-bootstrap.scss'
import BootstrapVue from 'bootstrap-vue'
import VeeValidate from 'vee-validate'

import * as Common from './components/common'
import env from '@/environment'
import LoadScript from 'vue-plugin-load-script'
import VShowSlide from 'v-show-slide'
import VueTour from 'vue-tour'
import 'vue-tour/dist/vue-tour.css'
import './styles/tour.scss'

import {
  default as ExternalServices,
  Analytics,
  AnalyticsPlugin,
} from './externalServices'
import { handleError } from './api/ErrorHandler'
import RouteQueryPlugin from './plugins/RouteQueryPlugin'
import FirebasePlugin from './plugins/FirebasePlugin'
import PosthogInstance from '@/externalServices/PostHog'

// Setup Sentry logging ASAP
if (env.useSentry) {
  Sentry.init({
    dsn: `https://${env.sentry.projectId}@sentry.io/${env.sentry.orgId}`,
    integrations: [
      new SentryIntegrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
    beforeSend(event) {
      return event
    },
  })
}

Object.keys(Common).forEach((key) => {
  Vue.component(key, Common[key])
})

Vue.use(ToggleButton)
Vue.use(LoadScript)
Vue.use(VueIntercom, { appId: env.intercom })
Vue.use(InfiniteScroll)
Vue.use(Stickyfill)
Vue.use(BootstrapVue)
Vue.use(VeeValidate)
Vue.use(AnalyticsPlugin)
Vue.use(PosthogInstance)
Vue.use(RouteQueryPlugin)
Vue.use(FirebasePlugin)
Vue.use(VShowSlide)
Vue.use(VueTour)
Vue.use(VueCookie)

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
})

api.setupResponseInterceptors()
api.setupRequestInterceptors()
api.addErrorCallback(handleError)
api.setAccessToken(store.getters[`user/accessToken`])
store.subscribe(({ type, payload }) => {
  switch (type) {
    case `globals/${SET_CAMPAIGN}`:
      i18n.locale = payload ? payload : 'en'
      break
    case `user/${SET_ACCESS_TOKEN}`:
      api.setAccessToken(payload)
      break
  }
})

Vue.config.productionTip = false

Analytics.init()
ExternalServices.loadFullStory()
ExternalServices.loadProfitWell()

const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
bootup(app)

function bootup(app) {
  app.$intercom.boot()
}
