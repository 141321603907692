<template>
  <div class="mp-input-error invalid-feedback">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MPInputError',
}
</script>

<style lang="scss" scoped>
.mp-input-error {
  color: #cc0000;
}
</style>
