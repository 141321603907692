import api from './'
import camelizeKeys from './camelizeKeys'
import environment from '@/environment'

const BASE_URL = 'recipes'
const RECIPES_BASE_URL = `${BASE_URL}/list/`
const PERSONAL_RECIPES_URL = `${BASE_URL}/personals/`
const RECIPES_FILTERS_URL = `${BASE_URL}/filters/`
// const RECIPES_ALL_URL = `${BASE_URL}/non-paginated-archive/`
const RECIPES_URL = `${BASE_URL}/paginated-archive/`
const RECIPES_ARCHIVE_URL = `${BASE_URL}/archive/`
const RECIPES_CATEGORY_URL = `${BASE_URL}/archive/category/`
// old recipe filters
// const RECIPES_RECIPE_BOX_FILTERS_URL = `${BASE_URL}/archive/recipe-box-filters/`
const RECIPES_BY_SERVINGS_URL = `${BASE_URL}/by-servings/`
const RECIPES_CHANGE_QUANTITY_URL = `${BASE_URL}/change-quantity/`
const RECIPES_NOTES_URL = `${BASE_URL}/recipe-notes/`
// const RECIPES_FILTERED_URL = `${BASE_URL}/suggest-recipes-by-ingredients-or-text/`
const GET_RECIPE_FAVORITE_URL = (recipeId) =>
  `${BASE_URL}/${recipeId}/favorite/`
const GET_RECIPE_UNFAVORITE_URL = (recipeId) =>
  `${BASE_URL}/${recipeId}/unfavorite/`
const GET_RECIPE_URL = `${BASE_URL}/`
const BASE_RECIPE_CURRENT_URL = (recipeId, token) =>
  `${BASE_URL}/${recipeId}/current/${token}/`
const GET_RECIPE_RATING_URL = (recipeId, rating) =>
  `${BASE_URL}/recipe-rating/${recipeId}/${rating}`
const RECIPE_FEEDBACK_URL = (recipeId) =>
  `${BASE_URL}/recipe-feedback/${recipeId}`
const GET_RECIPE_PRINT_URL = (recipeId, mealPlan, servings, recipePrintImage) =>
  `${environment.printBaseUrl}${BASE_URL}/${recipeId}/print/${mealPlan}/${servings}/${recipePrintImage}/`

const UNIT_LIST = `${BASE_URL}/unit-list/`
const INGREDIENT_LIST = `${BASE_URL}/ingredient-list/`
const DISMISS_SERVINGS_INFO = `${BASE_URL}/dismiss-recipe-servings-tip/`

const ADD_BONUS_RECIPE = `${BASE_URL}/add-bonus-recipe`
const PREP_DESCRIPTION_LIST = `${BASE_URL}/prep-description-list/`

const CREATE_PUBLIC_RECIPE_URL = (recipeId, servings) =>
  `${BASE_URL}/get-public-token/${recipeId}/${servings}/`

export default {
  getRecipes(options, cancelExecutor) {
    return api.get(RECIPES_URL, options, cancelExecutor).then((res) => {
      return res.data.results
    })
  },
  /**
   * Recipe List Endpoint
   *
   * /recipes/list/
   */
  getAllRecipes(options, cancelExecutor) {
    return api.get(RECIPES_BASE_URL, options, cancelExecutor).then((res) => {
      const result = {
        count: res.data.results.count,
        next: res.data.results.next,
        recipes: res.data.results.results,
        primaryResults: res.data.primary_results,
        isAlternativeResult: res.data.is_alternative_result,
        primaryLabel: res.data.primary_label,
        generalLabel: res.data.general_label,
        primaryAsGeneral: res.data.primary_results_as_general,
      }

      if (options.page === 1) {
        ;(result.primaryCount = res.data.primary_count),
          (result.generalCount = res.data.general_count)
      }

      return result
    })
  },
  getPersonalRecipes(options, cancelExecutor) {
    return api
      .get(PERSONAL_RECIPES_URL, options, cancelExecutor)
      .then(({ data }) => {
        data.results.results = [
          ...data.results.results,
          ...data.primary_results,
        ]
        return data.results
      })
  },
  /**
   * Recipe Filters Endpoint
   *
   * /recipe/filters/
   */
  getRecipeFilters(options, cancelExecutor) {
    return api.get(RECIPES_FILTERS_URL, options, cancelExecutor).then((res) => {
      return res.data
    })
  },
  getFilteredRecipes(options) {
    return api.get(RECIPES_BASE_URL, options).then((res) => {
      return { recipes: res.data.results }
    })
  },
  getCategories(options) {
    return api.get(RECIPES_CATEGORY_URL, options).then((res) => {
      return res.data
    })
  },
  // old recipe filters
  // getRecipeBoxFilters(options) {
  //   return api.get(RECIPES_RECIPE_BOX_FILTERS_URL, options).then(res => {
  //     return res.data
  //   })
  // },
  getAvailablePlans(options = {}) {
    return api.get(RECIPES_ARCHIVE_URL, options).then((res) => {
      return res.data.available_plans.map((plan) => ({
        id: plan.id,
        date: plan.start_date,
        isSample: plan.is_sample,
        name: plan.name,
      }))
    })
  },
  getPlanRecipes(planId) {
    return api.get(RECIPES_ARCHIVE_URL + planId).then((res) => {
      return res.data.recipes.map((recipe) => recipe.recipe)
    })
  },
  getByServings(recipeId, servings) {
    const url = `${RECIPES_BY_SERVINGS_URL}${recipeId}/${servings}`
    return api.get(url).then((res) => camelizeKeys(res.data))
  },
  changeQuantity(servingsData) {
    return api
      .post(RECIPES_CHANGE_QUANTITY_URL, servingsData)
      .then((res) => camelizeKeys(res.data))
  },
  updateFavorite(recipeId, isFavorite, cancelExecutor) {
    let url = !isFavorite
      ? GET_RECIPE_FAVORITE_URL(recipeId)
      : GET_RECIPE_UNFAVORITE_URL(recipeId)
    return api.post(url, undefined, cancelExecutor).then((res) => {
      return res.data.results
    })
  },
  getRecipeDetails({ recipeId, token }, mealPlan, userWeeklyPlanId) {
    if (token) {
      return api
        .get(BASE_RECIPE_CURRENT_URL(recipeId, token), {
          meal_plan_type: 'current',
          user_weekly_plan_id: userWeeklyPlanId,
        })
        .then((res) => camelizeKeys(res.data))
    } else {
      return api
        .get(GET_RECIPE_URL + recipeId, {
          meal_plan_type: mealPlan,
          user_weekly_plan_id: userWeeklyPlanId,
        })
        .then((res) => camelizeKeys(res.data))
    }
  },
  updateRecipeRating(recipeId, rating) {
    return api.post(GET_RECIPE_RATING_URL(recipeId, rating))
  },
  recipeFeedback(recipeId, feedback) {
    return api.post(RECIPE_FEEDBACK_URL(recipeId), feedback)
  },
  getRecipePrint({
    recipeId,
    mealPlan,
    userWeeklyPlanId,
    servings,
    recipePrintImage,
  }) {
    const data = { user_weekly_plan_id: userWeeklyPlanId }
    return api
      .get(
        GET_RECIPE_PRINT_URL(recipeId, mealPlan, servings, +recipePrintImage),
        data,
        () => {},
        'arraybuffer'
      )
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL || window.webkitURL
        const fileUrl = url.createObjectURL(blob)
        return {
          success: true,
          fileUrl: fileUrl,
          blob: blob,
        }
      })
  },
  setRecipeNotes(recipeId, notes) {
    return api.post(RECIPES_NOTES_URL + recipeId, notes)
  },
  async getUnitList() {
    const res = await api.get(UNIT_LIST)
    return camelizeKeys(res.data)
  },
  async getCategoryList() {
    const res = await api.get(RECIPES_CATEGORY_URL)
    return camelizeKeys(res.data)
  },
  async getIngredientList() {
    const res = await api.get(INGREDIENT_LIST)
    return camelizeKeys(res.data)
  },
  async dismissServingsInfo() {
    return await api.post(DISMISS_SERVINGS_INFO)
  },
  async addBonusRecipe(id) {
    return await api.get(`${ADD_BONUS_RECIPE}/${id}/`)
  },
  async getPrepDescriptionList() {
    const res = await api.get(PREP_DESCRIPTION_LIST)
    return camelizeKeys(res.data)
  },
  async createTokenPublicRecipe(recipeId, servings) {
    const res = await api.post(CREATE_PUBLIC_RECIPE_URL(recipeId, servings))

    return res.data
  },
}
