<template>
  <div id="AddCustomRecipe">
    <AddCustomRecipeModal @setIsFormValid="setIsFormValid" />
    <AddCustomRecipeConfirmModal :show="showConfirmModal">
      <div class="text-center">
        <div class="mt-4" v-if="isFormValid">
          <b>You haven’t saved your changes.</b>
          <p class="mt-2">
            Before you exit, do you want to save or discard your changes?
          </p>
        </div>
        <div v-else>
          <b>You haven’t completed the required fields.</b>
          <p class="mt-2">
            Do you want to keep editing or discard your changes?
          </p>
        </div>
        <hr />
        <div class="row no-gutters px-4 mb-4">
          <MPButton
            class="col"
            color="grey-outline"
            size="lg"
            @click="cancelKeepEditing()"
            v-text="keepEditingText"
          />
          <MPButton
            class="col mx-3"
            color="grey-outline"
            @click="discardExit()"
            size="lg"
          >
            Discard and Exit
          </MPButton>
          <MPButton
            class="col"
            color="red"
            @click="saveExit()"
            size="lg"
            :disabled="!isFormValid"
          >
            Save and Exit
          </MPButton>
        </div>
      </div>
    </AddCustomRecipeConfirmModal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LoadingMixin from './../../mixins/LoadingMixin'
import AddCustomRecipeModal from './AddCustomRecipeModal'
import AddCustomRecipeConfirmModal from './AddCustomRecipeConfirmModal'
import RouterMixin from '@/mixins/RouterMixin'

export default {
  name: 'AddCustomRecipe',
  components: {
    AddCustomRecipeModal,
    AddCustomRecipeConfirmModal,
  },
  data() {
    return {
      isFormValid: false,
    }
  },
  mixins: [LoadingMixin, RouterMixin],
  computed: {
    ...mapState('AddCustomRecipe', [
      'loading',
      'userGeneratedRecipe',
      'showConfirmModal',
    ]),
    keepEditingText() {
      return this.isFormValid ? 'Cancel, Keep Editing' : 'Keep Editing'
    },
  },
  watch: {
    loading: {
      handler: function () {
        this.setLoading(this.loading)
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('AddCustomRecipe', [
      'initAddCustomRecipeData',
      'setCustomRecipeStrategy',
      'syncRecipeIngredients',
      'setModalVisible',
      'setConfirmModalVisible',
    ]),
    setIsFormValid(result) {
      this.isFormValid = result
    },
    cancelKeepEditing() {
      this.setConfirmModalVisible(false)
    },
    discardExit() {
      this.setConfirmModalVisible(false)
      this.setModalVisible(false)
      this.onAddCustomRecipeReturnTo(false, this.userGeneratedRecipe)
    },
    saveExit() {
      this.setConfirmModalVisible(false)
      this.setModalVisible(false)
      Promise.resolve()
        .then(() => {
          return this.setCustomRecipeStrategy()
        })
        .then(() => {
          return this.syncRecipeIngredients()
        })
        .then(() => {
          this.onAddCustomRecipeReturnTo(true, this.userGeneratedRecipe)
        })
    },
  },
}
</script>

<style lang="scss"></style>
