export const FILTERS_TYPE = {
  FLAVOR_PROFILE: 'flavor_profile',
  FLAVOR_TYPE: 'flavor_type',
  TEMPERATURE_TYPE: 'temperature_type',
  MEALTYPE: 'meal_type',
  INTOLERANCES: 'intolerances',
  INGREDIENTS: 'ingredients',
  KID_APPROVED: 'kidApproved',
  PREP_TIME: 'prepTime',
  NO_WEEKEND_PREP: 'noWeekendPrep',
  ONLY_FAVORITES: 'onlyFavorites',
  CALORIES: 'calories',
  RATING: 'byRating',
}

export const SUB_MEAL_TYPES = ['Cold', 'Hot', 'Sweet', 'Savory']

export const COMPONENT_TYPES = {
  RATING_MAX: 5,
  RATING_INTERVAL: 1,
  RATING_DEFAULT: 0,
  CALORIES_MIN: 300,
  CALORIES_MAX: 700,
  CALORIES_DEFAULT: 700,
  CALORIES_INTERVAL: 50,
  PREP_MIN: 10,
  PREP_MAX: 50,
  PREP_DEFAULT: 50,
  PREP_INTERVAL: 5,
}

export default {
  FILTERS_TYPE,
  SUB_MEAL_TYPES,
  COMPONENT_TYPES,
}
