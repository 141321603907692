<template>
  <div class="header-logo">
    <img src="@/assets/images/fok-mp-new-logo.svg" />
  </div>
</template>

<script>
export default {
  name: 'HeaderLogo',
}
</script>

<style lang="scss" scoped>
.header-logo {
  display: flex;
  width: 250px;

  img {
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    width: 180px;
  }
}
</style>
