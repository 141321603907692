<template>
  <div
    :id="`dashboard-plan-item--${data.id}`"
    class="dashboard-plan-item"
    :class="{ 'dashboard-plan-item--disabled': !data.isDraggable }"
    :data-id="data.id"
  >
    <div class="content">
      <!-- Use for dragging styles-->
      <div class="item-wrapper row">
        <div class="image-wrapper col-md-5 col-xs-4" @click="onOpenItemClick">
          <span
            class="thumb"
            :style="{ backgroundImage: 'url(' + data.thumb + ')' }"
          >
          </span>
        </div>
        <div class="col col-md-7 col-xs-8">
          <div class="meal-type h4">{{ data.mealTypeText }}</div>
          <div class="reschedule">
            <MPPopover v-if="data.rescheduleDescription" color="white">
              <Notification class="reschedule-icon" slot="button" />
              <div
                class="reschedule-popover"
                slot="popover"
                v-html="data.rescheduleDescription"
              ></div>
            </MPPopover>
          </div>
          <a :href="recipeLink" class="title" @click.prevent="onOpenItemClick">
            {{ data.title }}
          </a>
          <span class="subtitle" v-if="data.subtitle">
            {{ data.subtitle }}
          </span>
          <div class="recipe-additional-info row">
            <span v-if="!data.isLeftover">
              <Clock class="additional-info-icon ico-clock" width="13" />&nbsp;
              {{ data.defaultPrepTime }} min
            </span>
            <span v-else> Leftovers </span>
            <span v-if="data.communityRating && data.ratingCount >= 3">
              <Star class="additional-info-icon ico-star" width="24" />&nbsp;{{
                data.communityRating.toFixed(1)
              }}
            </span>
          </div>
          <div class="options-group">
            <div class="options-description" v-if="data.optionsDescription">
              <MPPopoverBox
                @click="showOptions = true"
                :offset="16"
                v-if="!showOptions"
                :position="optionsDescriptionPosition"
              >
                <div
                  class="options-description-popover"
                  v-html="data.optionsDescription"
                ></div>
              </MPPopoverBox>
            </div>
            <MPPopover
              :disabled="data.optionsDisabled"
              offset="-47"
              :show.sync="showOptions"
            >
              <span
                slot="button"
                class="options"
                :class="{ 'onboarding-step-2-selector': data.isFirst }"
              ></span>
              <ul class="options-list" slot="popover">
                <div>
                  <li
                    class="options-item"
                    :class="{
                      'options-item--disabled': data.isSwapOutDisabled,
                    }"
                    @click="!data.isSwapOutDisabled && onSwapOutItemClick()"
                  >
                    <i class="options-icon swap-icon"></i>
                    <span class="options-text"> Swap out </span>
                    <MPPopoverBox
                      class="options-item-popover"
                      position="center-left"
                      v-if="data.swapOutDescription"
                    >
                      <div v-html="data.swapOutDescription"></div>
                    </MPPopoverBox>
                  </li>
                  <li
                    class="options-item"
                    :class="{
                      'options-item--disabled': data.isRemoveDisabled,
                    }"
                    @click="!data.isRemoveDisabled && onRemoveItemClick()"
                  >
                    <i class="options-icon remove-icon"></i>
                    <span class="options-text"> Remove </span>
                    <MPPopoverBox
                      class="options-item-popover"
                      position="center-left"
                      v-if="data.removeDescription"
                    >
                      <div v-html="data.removeDescription"></div>
                    </MPPopoverBox>
                  </li>
                </div>
              </ul>
            </MPPopover>
          </div>
        </div>
      </div>
      <MPPopoverBox
        class="description-item-popover"
        position="bottom-left"
        v-if="data.popoverDescription"
        :offset="37.5"
      >
        <div class="p-1" v-html="data.popoverDescription"></div>
      </MPPopoverBox>
    </div>
  </div>
</template>

<script>
import { MPPopover } from '@/components/common'
import { Star, Clock, Notification } from '@/components/common/MPIcons'
import _ from 'lodash'

export default {
  components: {
    MPPopover,
    Star,
    Clock,
    Notification,
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showOptions: false,
    }
  },
  computed: {
    optionsDescriptionPosition() {
      return _.get(this, 'data.optionsDescriptionPosition', 'bottom-right')
    },
    recipeLink() {
      return `/recipe/${this.data.recipeId}/${this.data.title
        .replace(/ /g, '-')
        .toLowerCase()}`
    },
  },
  methods: {
    onOpenItemClick() {
      this.$emit('onOpenItemClick', this.data.id)
    },
    onRemoveItemClick() {
      this.$emit('onRemoveItemClick', this.data.id)
    },
    onSwapOutItemClick() {
      this.$emit('onSwapOutItemClick', this.data.id)
    },
  },
  watch: {
    showOptions(showOptions) {
      this.showOptionsDescription = !showOptions
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin noSelect {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -ms-user-select: none;
  user-select: none;
}

.dashboard-plan-item {
  background-color: white;
  @include noSelect;
}

.sortable-chosen {
  .description-item-popover {
    display: none;
  }
}

.content {
  position: relative;
  padding: 15px 0;
  border-bottom: 1px solid $light-grey;
  align-items: center;

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.item-wrapper {
  padding: 0 15px 0 15px;
}

.dashboard-plan-item--disabled {
  .middle {
    cursor: default;
  }
}

.meal-type {
  line-height: 1.38;
  letter-spacing: 0.27px;

  @include media-breakpoint-down(sm) {
    font-size: 13px;
  }
}

.title {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  font-size: $h3-font-size;
  line-height: 1.14;
  letter-spacing: 0.78px;
  color: $body-color;

  &:hover {
    color: $link-hover-color;
  }

  @include media-breakpoint-down(md) {
    font-size: $h5-font-size-mobile;
  }

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.subtitle {
  line-height: 1.29;
  letter-spacing: 0.5px;
  color: $clr-navy-70;
  margin-top: 10px;
  display: flex;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.recipe-additional-info {
  position: absolute;
  bottom: 0;
  padding-left: 15px;
  font-size: $h4-font-size;
  color: $clr-navy-70;

  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
  }
  svg {
    color: $clr-navy-50;
  }
}

.image-wrapper {
  padding: 0;

  @include media-breakpoint-down(md) {
    max-width: 250px;
  }

  @include media-breakpoint-down(sm) {
    max-width: 150px;
  }

  @include media-breakpoint-down(xs) {
    max-width: 120px;
  }
}

.thumb {
  padding-top: 75%;
  border-radius: 0;
  display: flex;
  align-items: flex-start;
  background-color: #f2f2f5;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: ns-resize;
  height: 100%;
}

.dashboard-plan-item--disabled {
  .thumb {
    cursor: default;
  }
}

.thumb-tag {
  position: absolute;
  right: 30px;
  top: 15px;
  height: 30px;
  width: 30px;
  background-color: $clr-navy;
  border-radius: 50%;
  display: inline-block;
}

.ico-heart {
  position: absolute;
  color: $white;
  right: 8px;
  top: 8px;
}

.additional-info-icon {
  color: $clr-navy-50;
  margin-top: -4px;
}
.ico-star {
  margin-left: 14px;
  margin-right: -2px;
  @include media-breakpoint-down(sm) {
    margin-left: 10px;
  }
}

.reschedule {
  margin-right: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.reschedule-icon {
  color: $primary;
}

.reschedule-popover {
  background-color: $white;
  padding: 0.5rem;
  border-radius: 5px;
  min-width: 200px;
}

.options-group {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.options {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: solid 1px $clr-navy-15;
  cursor: pointer;
  z-index: 1;
  background: url('~@/assets/icons/dots.svg') no-repeat center;
}

.options-description {
  display: block;
  position: absolute;
  top: -(40px - 32px) / 2;
  left: -(40px - 32px) / 2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 78, 78, 0.1);
}

.options-description-popover {
  text-align: center;
  padding: 0.5rem 0;
  width: 150px;
}

.options-list {
  margin: 0;
  list-style: none;
  padding: 0.5rem 0;
}

.options-item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem 1rem;
  cursor: pointer;
  min-width: 135px; // IE11/Edge fix
  position: relative;
  &:hover {
    background-color: #f8f8f8;
  }
  &--disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: #fff;
    }
  }
}

.options-item-popover {
  width: 120px;
  text-align: center;
  padding: 0.5rem;
  margin-right: 8px;
}

.options-text {
  flex: 1; // IE fix
  font-size: 0.875rem;
}

.options-icon {
  width: 12px;
  height: 12px;
  background-position: no-repeat center;
  margin-right: 15px;
  background-repeat: no-repeat;
}

.swap-icon {
  background-image: url('~@/assets/icons/dashboard-swap.svg');
  background-size: cover;
}

.remove-icon {
  background-image: url('~@/assets/icons/dashboard-remove.svg');
  background-size: contain;
  height: 11px;
}

.description-item-popover {
  padding: 0.5rem;
  width: 240px;
  text-align: center;
}
</style>

<style lang="scss">
.dashboard-plan-item {
  &.sortable-ghost {
    opacity: 0;
  }
  &.sortable-drag {
    box-shadow: 0px 0px 5px 0px #757575;
    z-index: 1;
    position: relative;
  }

  .reschedule {
    .popover {
      border: 1px solid $primary;
      .arrow {
        &::before {
          border-bottom-color: $primary;
        }
        &::after {
          border-bottom-color: #fff;
        }
      }
    }
  }
}
</style>
