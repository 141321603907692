<template>
  <div id="disqus-comments" class="position-relative mt-md-4">
    <div v-if="showPosts">
      <h3 class="font-weight-bold" v-text="commentsTitle"></h3>
      <DisqusAddPost />
      <div v-if="posts && posts.length" class="mt-5">
        <transition-group name="list" tag="div">
          <DisqusPostItem v-for="post in posts" :key="post.id" :post="post" />
        </transition-group>
      </div>
      <div v-else class="no-comments mt-3 mt-md-2">
        No comments have been added yet, add one!
      </div>
    </div>
    <DisqusOverlay v-if="showOverlay">
      <div v-if="showLocked" class="text-center">
        <img src="@/assets/icons/baseline-lock-24-px.svg" alt="lock" />
        <div class="lock-text">
          To view and add recipe comments you’ll need to upgrade to a premium
          plan.
        </div>
        <MPButton
          class="upgrade-button font-weight-bold"
          color="primary"
          size="lg"
          @click="goToPremium()"
        >
          UPGRADE TODAY
        </MPButton>
      </div>
      <div v-else class="text-center">
        <div class="spinner-border text-success mt-5" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="loading-text">Loading...</div>
      </div>
    </DisqusOverlay>
    <MPNotification
      v-if="disqusNotification.message"
      :type="disqusNotification.type"
      :title="disqusNotification.message"
      :action-text="disqusNotification.actionText"
      @onActionSelect="disqusNotification.action"
      class="full full-with-navbar"
    />
  </div>
</template>

<script>
import DisqusPostItem from '@/components/recipe/disqus/DisqusPostItem'
import DisqusAddPost from '@/components/recipe/disqus/DisqusAddPost'
import DisqusOverlay from '@/components/recipe/disqus/DisqusOverlay'
import LoadingMixin from '@/mixins/LoadingMixin'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'DisqusComments',
  components: {
    DisqusPostItem,
    DisqusAddPost,
    DisqusOverlay,
  },
  mixins: [LoadingMixin],
  computed: {
    ...mapGetters('Disqus', ['posts', 'loading']),
    ...mapGetters('user', ['permissions']),
    commentsTitle() {
      if (this.posts) {
        if (this.posts.length > 1) {
          return `Comments (${this.posts.length})`
        }
        return `Comment (${this.posts.length})`
      }
      return `Comments (0)`
    },
    showPosts() {
      return this.permissions.canUseDisqusCommentingPlatform && this.posts
    },
    showOverlay() {
      return !this.permissions.canUseDisqusCommentingPlatform || !this.posts
    },
    showLocked() {
      return !this.permissions.canUseDisqusCommentingPlatform
    },
    ...mapState('Disqus', [
      'notificationInfoMessage',
      'notificationErrorMessage',
      'lastRecipeId',
    ]),
    disqusNotification() {
      if (this.notificationInfoMessage || this.notificationErrorMessage) {
        const lastRecipeId = this.lastRecipeId
        return {
          message:
            this.notificationInfoMessage || this.notificationErrorMessage,
          type: this.notificationErrorMessage ? 'error' : 'action',
          actionText: lastRecipeId ? 'View' : '',
          action: function () {
            const url = `disuqs-post-${lastRecipeId}`
            document.getElementById(url).scrollIntoView()
          },
        }
      }
      return {
        message: '',
        type: 'action',
        actionText: '',
        action: null,
      }
    },
  },
  watch: {
    loading: {
      handler: function (value) {
        this.setLoading(value)
      },
      immediate: true,
    },
  },
  methods: {
    goToPremium() {
      const eventProperties = {}
      eventProperties.location = 'DisqusComments'
      this.$analytics.track('mp upgrade clicked', eventProperties)
      this.$router.push('/reactivate')
    },
  },
}
</script>
<style lang="scss" scoped>
.no-comments {
  font-size: 0.875rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: $grey;
  text-align: center;
}
.loading-text {
  margin-top: 1rem;
  font-weight: 500;
  color: $link-color;
}
.upgrade-button {
  width: 200px;
}
.lock-text {
  font-size: 1rem;
  letter-spacing: 0.4px;
  text-align: center;
  font-weight: 600;
  margin: 12px 0 24px;
  line-height: 1.5;
  width: 334px;
}
</style>

<style lang="scss">
#disqus-comments {
  width: 100%;
  padding: 0 15px;
  hr {
    margin-bottom: 2rem;
  }
  .author-image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 1.4375rem;
    &--child {
      width: 48px;
      height: 48px;
      margin-left: -10px;
    }
    &--dummy {
      background-color: #dde3e6;
    }
  }
  .list-enter-active,
  .list-leave-active {
    transition: opacity 0.5s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 200px 0;
    background-image: linear-gradient(
      to bottom,
      rgba(249, 249, 251, 0.4),
      #f7f7f7 79%,
      #f7f7f7
    );
  }
}
</style>
