export function getDashboardItem() {
  return { ...recipes[0] }
}

export function getRecipes() {
  return recipes.map((r) => ({ ...r }))
}

export function getRecipeBoxItems() {
  return [{ ...recipes[1] }, { ...recipes[2] }, { ...recipes[3] }].map(
    (recipe) => {
      recipe.addRecipeStatus = 'enabled'
      recipe.categoryColor = 'yellow'
      recipe.manualToggleFavorite = true
      return recipe
    }
  )
}

const recipes = [
  {
    id: 101,
    thumb_medium: require('@/assets/images/onboarding-tutorial/veggie_fajitas_01_thumbnail_medium.jpg'),
    thumb_small: require('@/assets/images/onboarding-tutorial/veggie_fajitas_01_thumbnail_medium.jpg'),
    title: 'Veggie Fajitas',
    subtitle: 'with salsa & avocado',
    rating: 4.310087173100872,
    category: 'Lunches and Dinners',
    default_prep_time: 35,
    rating_count: 3,
  },
  {
    id: 1438,
    thumb_medium: require('@/assets/images/onboarding-tutorial/0866_BBQ_sweet_potato_tacos_01_thumbnail_medium.jpg'),
    thumb_small: require('@/assets/images/onboarding-tutorial/0866_BBQ_sweet_potato_tacos_01_thumbnail_medium.jpg'),
    title: 'BBQ Sweet Potato Tacos',
    subtitle: 'with pickled onions',
    rating: 4.743589743589744,
    category: 'Lunches and Dinners',
    default_prep_time: 30,
    rating_count: 3,
  },
  {
    id: 912,
    thumb_medium: require('@/assets/images/onboarding-tutorial/kale_caesae_salad_02_thumbnail_medium.jpg'),
    thumb_small: require('@/assets/images/onboarding-tutorial/kale_caesae_salad_02_thumbnail_medium.jpg'),
    title: 'Kale Caesar Salad',
    subtitle: '',
    rating: 4.191082802547771,
    category: 'Lunches and Dinners',
    default_prep_time: 20,
    rating_count: 3,
  },
  {
    id: 643,
    thumb_medium: require('@/assets/images/onboarding-tutorial/green_goddess_bowl_thumbnail_medium.jpg'),
    thumb_small: require('@/assets/images/onboarding-tutorial/green_goddess_bowl_thumbnail_medium.jpg'),
    title: 'Quinoa Green Goddess Bowl',
    subtitle: '',
    rating: 4.5,
    category: 'Lunches and Dinners',
    default_prep_time: 20,
    rating_count: 3,
  },
]
