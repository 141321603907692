import api from './'
import camelizeKeys from './camelizeKeys'

const ONBOARDING_STATUS_URL = 'dashboard/onboarding-status/'
const FINISH_TOUR_URL = 'dashboard/finish-tour/'
const FINISH_ALL_TOURS_URL = 'dashboard/finish-all-tours/'

export default {
  getOnboardingStatus() {
    return api.get(ONBOARDING_STATUS_URL).then((res) => camelizeKeys(res.data))
  },
  finishTour(tourName) {
    return api.post(FINISH_TOUR_URL, { tour_name: tourName })
  },
  finishAllTours() {
    return api.post(FINISH_ALL_TOURS_URL)
  },
}
